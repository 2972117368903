import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Table,
  Tabs,
  Form,
  Spin,
} from 'antd';
import moment from "moment";
import { getAllTagUsers, getTagUsers, untagUsers } from "../../../redux/tags/thunks/tagsThunks";
import { formatDate } from "../../../utils/formatDate";
import { getMediaFileFromServer } from "../../../utils/getMediaFileFromServer";
import { handleLoading } from "../../../utils/handleLoading";
import { goBack } from "../../../utils/goBack";

import DeleteIcon from '../../../assets/img/delete.svg';
import Button from "../../../components/Button/Button";
import Icon from "../../../components/Icon/Icon";
import PopUp from "../../../components/PopUp/PopUp";

const { TabPane } = Tabs;

class AdminGroupUsers extends Component {
  constructor(props) {
    super(props);

    this.usersColumns = [
      {
        title: 'Name Surname',
        dataIndex: 'nameSurname',
        key: 'nameSurname',
        render: (text) => (
          <div className="flex-container align-middle">
            <span className="radius-circle clip flex-container align-middle align-center user_ava">
              {
                text[0] ?
                  <img src={getMediaFileFromServer(text[0])} alt="" />
                  :
                  <div className="text text-15 font-heavy font-spacing-02 color-blue">
                    {text[1].charAt(0)}
                  </div>
              }
            </span>
            <span className="text-16 font-semibold color-grey">
              {text[1]}
            </span>
          </div>
        ),
        sorter: (a, b) => a.nameSurname[1].localeCompare(b.nameSurname[1]),
      },
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user ',
        sorter: (a, b) => a.user.localeCompare(b.user),
      },
      {
        title: 'Reg date',
        dataIndex: 'regDate',
        key: 'regDate',
        sorter: (a, b) => moment(a.regDate).unix() - moment(b.regDate).unix(),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (payload) => {
          if (payload.role !== "ADMIN") {
            const onboardedClassName = payload.text === "ONBOARDED" ? "imp_tag_lightgreen" : "";
            return <div className={`imp_tag font-uppercase color-grey ${onboardedClassName}`}>{payload.text}</div>;
          }
        },
        sorter: (a, b) => a.status.text.localeCompare(a.status.text),
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (userID) => (
          <div className="flex-container align-middle icon_btn_group">
            <div className="icon_btn">
              <Button
                title={<Icon type="bin" style={{ fontSize: "19px" }} />}
                size="lg"
                theme="lightblue"
                className="radius-10"
                onClick={() => this.openUserDeleteModal(userID)}
              />
            </div>
          </div>
        )
      },
    ];

    this.state = {
      selectedRowKeysInbox: [],
      selectedRowKeysSent: [],
      deletingUserID: null,
      messageWindow: false,
      userDeleteModal: false
    }
  }

  handleTabChange = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  handleMessageWindowActive = () => {
    this.setState({
      messageWindow: true
    })
  };

  openUserDeleteModal = (userID) => {
    this.setState({
      userDeleteModal: true,
      deletingUserID: userID,
    })
  };

  closeUserDeleteModal = () => {
    this.setState({
      userDeleteModal: false,
      deletingUserID: null,
    })
  };

  deleteTagUser = () => {
    const { match, untagUsers } = this.props;
    const { groupID } = match.params;
    const { deletingUserID } = this.state;
    // we are setting users to an array, because there is ability to delete multiple users from API, but with a design you can delete only 1 user per action
    untagUsers({ tag: groupID, users: [deletingUserID] });
  };

  getUserData = () => {
    const { tagUsersList } = this.props;
    let returningTagUsersList = [];

    if (tagUsersList) {
      tagUsersList.map((element) => {
        return returningTagUsersList.push({
          key: element.id,
          nameSurname: [element.avatar, `${element.first_name} ${element.last_name}`],
          user: element.role,
          regDate: formatDate(element.created),
          status: {
            role: element.role,
            text: parseInt(element.activated) === 0 ? "INACTIVE" : parseInt(element.onboarded) === 0 ? "NOT ONBOARDED" : "ONBOARDED",
          },
          persuedOpp: 0,
          actions: element.id,
        });
      });
    }

    return returningTagUsersList;
  };

  infiniteScroll = () => {
    if ((Math.ceil(window.innerHeight + window.scrollY)) >= document.body.offsetHeight) {
      const { tagUsersCount, currentPage } = this.props;
      const roundedCount = Math.ceil(tagUsersCount / 10) * 10;
      const checkingNumber = currentPage * 10;

      if (roundedCount >= checkingNumber) { // for avoiding of calling endless searchUsers() thunk
        const { getTagUsers, match, currentPage } = this.props;
        const { groupID } = match.params;
        getTagUsers(groupID, currentPage);
      }
    }
  };

  componentDidMount() {
    const { match, getTagUsers } = this.props;
    const { groupID } = match.params;
    getTagUsers(groupID);

    window.addEventListener("scroll", this.infiniteScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading, errorMessage } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;

    if (!errorText && !loadingState && prevProps.loading.state && loadingType === "UntagUsers") {
      this.closeUserDeleteModal();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.infiniteScroll);
  }

  render() {
    const usersData = this.getUserData();

    const { loading, tagUsersCount, match } = this.props;
    const { groupID } = match.params;

    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="flex-container align-middle">
                <Button
                  title={<Icon type="key-left" size={16} />}
                  size="md"
                  theme="lightblue"
                  className="radius-10 back_btn"
                  onClick={goBack}
                />
                <div className="page_title visible-first custom-headline text text-24 text-height-48 font-heavy color-grey">
                  <h1>Users</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="column small-12 text-right">
            <Button
              title={'View All'}
              size="lg"
              theme="lightblue"
              mode="bordered"
              className="radius-8 mb_10"
              type="button"
              disabled={usersData.length === tagUsersCount}
              onClick={() => {
                if (usersData.length !== tagUsersCount) {
                  const { getAllTagUsers } = this.props;

                  getAllTagUsers(groupID);
                  window.removeEventListener("scroll", this.infiniteScroll);
                }
              }}
            />
          </div>
          <div className="column small-12">
            <Spin spinning={handleLoading(loading, "GetTagUsers") || handleLoading(loading, "GetAllTagUsers")}>
              <Tabs
                onChange={this.handleTabChange}
                className="c-table tab_default contact_tab without_nav"
              >
                <TabPane tab={null} key="tagUsers">
                  <Table
                    columns={this.usersColumns}
                    dataSource={usersData}
                    pagination={false}
                    className="c-table c-table-bordered users_table allow_row_selection"
                  />
                </TabPane>
              </Tabs>
            </Spin>
          </div>
        </div>

        <PopUp
          className="relative_parent"
          visible={this.state.userDeleteModal}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div className="popup_icon">
            <img src={DeleteIcon} alt="" />
          </div>
          <div className="text-center">
            <div className="text text-28 text-height-30 font-heavy font-spacing-02 color-grey font-uppercase mb_35">
              Do you want to delete this user?
            </div>

            <div className="btn_group">
              <Button
                title="Cancel"
                size="lg"
                theme="lightblue"
                mode="bordered"
                className="radius-8"
                style={{ fontSize: 18 }}
                onClick={this.closeUserDeleteModal}
              />
              <Button
                title="Delete"
                type="button"
                prefix={<Icon type="bin" style={{ fontSize: "18px", marginRight: 12 }} />}
                size="lg"
                theme="lightred"
                mode="bordered"
                className="radius-8"
                style={{ fontSize: 18 }}
                onClick={this.deleteTagUser}
                loading={handleLoading(loading, "UntagUsers")}
              />
            </div>
          </div>
        </PopUp>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.tags.loading,
  errorMessage: state.tags.errorMessage,
  tagUsersList: state.tags.tagUsersList,
  tagUsersCount: state.tags.tagUsersCount,
  currentPage: state.tags.currentPage,
});

const mapDispatchToProps = dispatch => ({
  getTagUsers: (tagID, currentPage) => dispatch(getTagUsers(tagID, currentPage)),
  getAllTagUsers: (tagID) => dispatch(getAllTagUsers(tagID)),
  untagUsers: (params) => dispatch(untagUsers(params)),
});

const AdminGroupUsersScreen = Form.create({ name: 'adminGroupUsers' })(AdminGroupUsers);

export default connect(mapStateToProps, mapDispatchToProps)(AdminGroupUsersScreen);
