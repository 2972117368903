import {
  LOGIN_USER_START, LOGIN_USER_SUCCESS, LOGIN_USER_FAIL
} from "../../constants";

export const loginUserStart = () => ({
  type: LOGIN_USER_START
});

export const loginUserSuccess = payload => ({
  type: LOGIN_USER_SUCCESS,
  payload
});

export const loginUserFail = payload => ({
  type: LOGIN_USER_FAIL,
  payload
});