import CommonUtils from "../../../utils/CommonUtils";
import {
  loginUserStart, loginUserSuccess, loginUserFail
} from "../actions/loginActions";
import history from "../../../history";

export const loginUser = loginData => ((dispatch, getState) => {
  dispatch(loginUserStart());
  const loginApiEndpoint = loginData["id_token"] ? "shared/loginWithGoogle" : loginData['code'] ? "shared/loginWithGoogle2FA" : "shared/login";

  CommonUtils.apiCall("post", "public", loginApiEndpoint, loginData, null)
    .then((response) => {
      if (response.data.success) {
        const {id, token, role} = response.data.success;
        localStorage.setItem("xID", id);
        localStorage.setItem("xTOKEN", token);
        localStorage.setItem("role", role);
        dispatch(loginUserSuccess({id, token, role}));
        history.push("/opportunities");
      }
      else if (response.data.error) {
        // Need to change error response, for avoiding such statement check
        const responseData = response.data.error;
        const errorMessage = responseData.password ? responseData.password : responseData.email ? responseData.email : responseData;
        dispatch(loginUserFail(errorMessage));
      }
      else {
        dispatch(loginUserFail("Something went wrong, please try again"));
      }
    })
    .catch((error) => {
      dispatch(loginUserFail("Something went wrong, please try again"));
    })
});