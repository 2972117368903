import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Collapse, Input, Upload, Checkbox } from "antd";

import { updateProfile } from "../../redux/user/thunks/updateProfileThunk";
import { getMediaFileFromServer } from "../../utils/getMediaFileFromServer";
import { handleLoading } from "../../utils/handleLoading";

import history from "../../history";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import PopUp from "../../components/PopUp/PopUp";
import SettingsPassword from "../Settings/SettingsPassword";
import "./style.scss";

const { Panel } = Collapse;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

class AccountSettingsUi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: ""
    }
  }

  handleChange = info => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  getCollapsePanelHeaderJSX = (title, value, hideIcon) => (
    <div className="flex-container align-middle align-justify">
      <div className="flex-container align-middle clip">
        <div className="flex-container align-middle text text-14 font-medium font-spacing-04 font-uppercase color-grey-800 collapse_label collapse_label-fix">
          {title}
        </div>
        <div className="text text-20 text-height-27 font-bold color-grey text-nowrap">{value}</div>
      </div>

      {
        !hideIcon ?
          <div className="collapse_icon">
            <Icon type="edit" size={18} className="color-grey" />
          </div>
          : null
      }
    </div>
  );

  getInputInitialValues = () => {
    const { userInfo } = this.props;
    let initialValues = {
      random_id: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: ""
    };

    if (userInfo) {
      initialValues = {
        random_id: userInfo.random_id ? userInfo.random_id : "",
        first_name: userInfo.first_name ? userInfo.first_name : "",
        last_name: userInfo.last_name ? userInfo.last_name : "",
        email: userInfo.email ? userInfo.email : "",
        phone: userInfo.phone ? userInfo.phone : ""
      };
    }

    return initialValues;
  };

  hideModal = () => {
    const { modalHide, userInfo } = this.props;
    const { avatar } = userInfo;
    const imageUrl = avatar ? getMediaFileFromServer(avatar) : require("../../assets/img/no_profile.png");

    this.setState({ imageUrl }, () => {
      modalHide();
      this.props.form.resetFields();
    });
  };

  onSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { updateProfile } = this.props;
        const { imageUrl } = this.state;
        const params = {
          first_name: values.first_name,
          last_name: values.last_name,
        };

        if (imageUrl) {
          params.photo = imageUrl;
        }
        updateProfile(params);
      }
    })
  };

  fakeCustomRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  isAdmin = () => {
    const { role } = this.props;
    return role === "ADMIN";
  };

  isOnboarded = () => {
    const { userInfo } = this.props;

    if (userInfo && parseInt(userInfo.onboarded) === 1) {
      return true;
    }

    return false;
  };

  isOnboardingFormFilled = () => {
    const { onboardingData } = this.props;
    return onboardingData && onboardingData.other_person_interest != null && onboardingData.other_person_interest !== "";
  };

  handleUpgradeButtonOnClick = () => {
    const { modalHide } = this.props;
    modalHide();
    history.push("/onboarding1");
  };

  onCheckboxChange = (checked, notificationType) => {
    const { updateProfile } = this.props;
    const param = {
      [notificationType]: checked ? 1 : 0
    };
    updateProfile(param);
  };

  isNotificationCheckboxChecked = type => {
    const { userInfo } = this.props;

    if (userInfo) {
      return parseInt(userInfo[type]) === 1;
    }

    return false;
  };

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, loading } = this.props;
    const errorText = errorMessage.message;
    const loadingType = loading.type;
    const loadingState = loading.state;

    if (!errorText && loadingType === "InitLoad" && !loadingState && prevProps.loading.state) {
      const { avatar } = this.props.userInfo;
      const imageUrl = avatar ? getMediaFileFromServer(avatar) : require("../../assets/img/no_profile.png");

      this.setState({ imageUrl });
    }
  }

  render() {
    const { popupVisible, loading } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { imageUrl } = this.state;
    const {
      random_id,
      first_name,
      last_name,
      email,
      phone,
    } = this.getInputInitialValues();

    return (
      <PopUp
        className="relative_parent"
        visible={popupVisible}
        onCancel={this.hideModal}
        innerClassNames="shadow-layout bg-white radius-10 relative xl base fluid_sm"
      >
        <div onClick={this.hideModal} className="popup_close transform_back color-grey-700">
          <Icon type="cancel" />
        </div>
        <div className="account_settings">
          <div className="row align-justify">
            <div className="column small-12 large-6">
              <div className="account_settings_left">
                <div className="account_dragger">
                  <Upload
                    showUploadList={false}
                    listType="picture-card"
                    customRequest={this.fakeCustomRequest}
                    onChange={this.handleChange}
                  >
                    <div className="account_dragger_img">
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                      <div className="text font-semibold color-white account_dragger_label">
                        Drag here
                      </div>
                    </div>
                    <Button
                      title={<b>Upload an image</b>}
                      size="lg"
                      theme="lightblue"
                      style={{ fontSize: 16 }}
                      prefix={<Icon type="upload" size={18} style={{ marginRight: 6 }} />}
                      className="btn-link uploader_trigger"
                      type="button"
                    />
                  </Upload>
                </div>
                <Collapse className="mb_50">
                  <Panel
                    key="member_id"
                    disabled
                    header={this.getCollapsePanelHeaderJSX("Member ID", random_id, true)}
                  />
                  <Panel
                    key="1"
                    header={this.getCollapsePanelHeaderJSX("Name", first_name)}
                  >
                    <div className="row">
                      <div className="column small-12 medeium-9 large-6">
                        <div className="form-item">
                          <Form.Item>
                            {getFieldDecorator("first_name", {
                              initialValue: first_name,
                              rules: [{ required: true, message: "Please fill out this field" }],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </Panel>
                  <Panel
                    key="2"
                    header={this.getCollapsePanelHeaderJSX("Surname", last_name)}
                  >
                    <div className="row">
                      <div className="column small-12 medeium-9 large-6">
                        <div className="form-item">
                          <Form.Item>
                            {getFieldDecorator("last_name", {
                              initialValue: last_name,
                              rules: [{ required: true, message: "Please fill out this field" }],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </Panel>
                  <Panel
                    key="3"
                    disabled
                    header={this.getCollapsePanelHeaderJSX("Email", email, true)}
                  />
                  <Panel
                    key="4"
                    disabled
                    header={this.getCollapsePanelHeaderJSX("Phone", phone, true)}
                  />
                  <SettingsPassword key="password" />
                </Collapse>

                <div className="notification_settings mb_50">
                  <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 title">
                    E-mail notifications
                  </div>

                  <div className="checkbox_group">
                    <Checkbox
                      checked={this.isNotificationCheckboxChecked("notify_reports")}
                      onChange={e => this.onCheckboxChange(e.target.checked, "notify_reports")}
                    >
                      Notify me about new investment opportunites
                    </Checkbox>
                    <Checkbox
                      checked={this.isNotificationCheckboxChecked("notify_investments")}
                      onChange={e => this.onCheckboxChange(e.target.checked, "notify_investments")}
                    >
                      Notify me about new reports in portfolio companies
                    </Checkbox>
                    <Checkbox
                      checked={this.isNotificationCheckboxChecked("email_message")}
                      onChange={e => this.onCheckboxChange(e.target.checked, "email_message")}
                    >
                      Notify me about new messages
                    </Checkbox>
                  </div>
                </div>

                <Form.Item className="btn_group mb_0">
                  <Button
                    title="Cancel"
                    size="lg"
                    theme="default"
                    className="btn-link font-uppercase"
                    type="button"
                    onClick={this.hideModal}
                  />
                  <Button
                    title="Save changes"
                    size="lg"
                    theme="blue"
                    className="btn-shadow radius-8"
                    type="submit"
                    onClick={this.onSubmit}
                    loading={handleLoading(loading, "UpdateProfile")}
                  />
                </Form.Item>
              </div>
            </div>

            {
              (!this.isAdmin() && !this.isOnboarded()) &&
              <div className="column small-12 large-5 fluid_sm">
                <div className="account_settings_right">
                  <div className="onboard_card radius-10">
                    {
                      this.isOnboardingFormFilled() ?
                      <div className="text text-20 text-height-30 font-medium text-center">
                        Your Onboarding application was successfully submitted.  We will be in touch with you shortly.
                      </div> :
                      <>
                        <div className="text text-20 text-height-30 font-medium text-center mb_45">
                          Only “Onboarded users” can apply to see investment opportunities details.<br/>
                          <b>Please complete form to be upgraded to “Onboarded user” status</b>
                        </div>
                        <div className="onboard_card_bottom">
                          <Button
                            title="Upgrade to onboarded"
                            size="lg"
                            theme="lightblue"
                            mode="bordered"
                            prefix={<Icon type="up" size={15} style={{marginRight: 8}} />}
                            className="fluid-x radius-8"
                            type="submit"
                            onClick={this.handleUpgradeButtonOnClick}
                            style={{ lineHeight: 'initial' }}
                          />
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </PopUp>
    )
  }
}

const mapStateToProps = state => ({
  errorMessage: state.user.errorMessage,
  loading: state.user.loading,
  userInfo: state.user.userInfo,
  role: state.user.role,
  onboardingData: state.registration.onboardingData,
});

const mapDispatchToProps = dispatch => ({
  updateProfile: params => dispatch(updateProfile(params))
});

const AccountSettings = Form.create({ name: "account_settings" })(AccountSettingsUi);

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
