import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, message, Spin, Tabs } from "antd";

import { getBusinesses } from "../../redux/business/thunks/businessThunk";
import { setActiveBusinessTabKey, resetBusinessFilter } from "../../redux/business/actions/businessActions";
import { handleErrorMessage } from "../../utils/handleErrorMessage";
import { handleLoading } from "../../utils/handleLoading";

import FilterComponent from "./Filter";
import TabPaneTitle from "./TabPaneTitle";
import TabPaneContent from "../../components/TabPaneContent/TabPaneContent";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";

const { TabPane } = Tabs;

class Opportunities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterCategory: ['a10', 'c12'],
      filterIndustry: ['a10', 'c12'],
      filterTransactionStatus: ['a10', 'c12'],
      filterCountry: ['a10', 'c12'],
      filterProvince: ['a10', 'c12'],
      filterInvsetType: ['a10', 'c12'],
      filterTotalInvest: [20, 50],
      filterMinInvest: [10, 50],
    }
  }

  handleTabsPaneOnChange = (activeTabKey) => {
    const { setActiveBusinessTabKey } = this.props;
    setActiveBusinessTabKey(activeTabKey);
  };

  infiniteScroll = () => {
    if ((Math.ceil(window.innerHeight + window.scrollY)) >= document.body.offsetHeight) {
      const { businessesCount, currentPage } = this.props;
      const roundedCount = Math.ceil(businessesCount / 10) * 10;
      const checkingNumber = currentPage * 10;

      if (roundedCount >= checkingNumber) { // for avoiding of calling endless getBusinesses() thunk
        const { getBusinesses } = this.props;
        getBusinesses();
      }
    }
  };

  getTabPanes = () => {
    const {
      likedBusinessItemsCount, pursuingBusinessItemsCount,
      closedBusinessItemsCount, newBusinessTypeItemsCount,
      notInterestedBusinessItems, notInterestedBusinessItemsCount,
      ignoredBusinessTypeItems, ignoredBusinessTypeItemsCount,
    } = this.props;
    const businesses = this.props.businesses || [];
    const savedBusinesses = this.props.savedBusinesses || [];
    const pursuingBusinesses = this.props.pursuingBusinesses || [];
    const closedBusinesses = this.props.closedBusinesses || [];
    const newBusinessTypeItems = this.props.newBusinessTypeItems || [];

    return [
      {
        key: 'all',
        data: businesses,
        tab: <TabPaneTitle title='All' />,
      },
      {
        key: 'closed',
        data: closedBusinesses,
        tab: <TabPaneTitle title="Invested" length={closedBusinessItemsCount} />,
      },
      {
        key: 'not_reviewed',
        data: ignoredBusinessTypeItems,
        tab: <TabPaneTitle title="Not reviewed" length={ignoredBusinessTypeItemsCount} />,
      },
      {
        key: 'interested',
        data: savedBusinesses,
        tab: <TabPaneTitle title="Interested" length={likedBusinessItemsCount} />,
      },
      {
        key: 'liked',
        data: newBusinessTypeItems,
        tab: <TabPaneTitle title="My liked" length={newBusinessTypeItemsCount} />,
      },
      {
        key: 'pursuing',
        data: pursuingBusinesses,
        tab: <TabPaneTitle title="I'm pursuing" length={pursuingBusinessItemsCount} />,
      },
      {
        key: 'not_interested',
        data: notInterestedBusinessItems,
        tab: <TabPaneTitle title="Not interested" length={notInterestedBusinessItemsCount} />,
      },
    ];
  };

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage } = this.props;
    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "InitLoad")) {
      message.error(errorMessage.message);
    }
  }

  componentWillUnmount() {
    this.props.resetBusinessFilter();
    window.removeEventListener("scroll", this.infiniteScroll);
  }

  componentDidMount() {
    this.props.getBusinesses(0);
    window.addEventListener("scroll", this.infiniteScroll);
  }

  render() {
    const { role, loading, userLoading, activeTabKey } = this.props;
    const businesses = this.props.businesses || [];

    return (
      <div className="row screen_wrapper">
        <div className="column small-12 page_top">
          <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
          <h1>Primary Deals</h1>
          </div>
        </div>
        <div className="column small-12" >
          <div className="relative">
            <FilterComponent />
            {
              role === "ADMIN" &&
                <div className="add_opport" >
                  <Link to="/admin-project" >
                    <Button
                      title="Add New Opportunity"
                      prefix={<Icon type="plus" size={18} style={{marginRight: 10}}/>}
                      size="lg"
                      theme="blue"
                      className="btn-shadow radius-8 add_message sm_circle"
                      type="button"
                    />
                  </Link>
                </div>
            }
          </div>

          <Spin spinning={handleLoading(loading, "GetBusiness") || handleLoading(userLoading, "InitLoad")} >
            {
              role === "ADMIN"
                ? (
                  <div className="mt_15">
                    <TabPaneContent isAdmin type="all" businesses={businesses} />
                  </div>
                ) : (
                  <div className="column small-12">
                    <Tabs
                      onChange={this.handleTabsPaneOnChange}
                      defaultActiveKey={activeTabKey}
                      className="tab_default tab_sticky"
                    >
                      {this.getTabPanes().map(({ key, data, tab }) => (
                        <TabPane tab={tab} key={key} >
                          <TabPaneContent type={key} businesses={data} />
                        </TabPane>
                      ))}
                    </Tabs>
                  </div>
                )
            }
          </Spin>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.business.loading,
  userLoading: state.user.loading,
  errorMessage: state.user.errorMessage,
  role: state.user.role,
  businesses: state.business.businesses,
  savedBusinesses: state.business.savedBusinesses,
  notInterestedBusinessItems: state.business.notInterestedBusinessItems,
  pursuingBusinesses: state.business.pursuingBusinesses,
  closedBusinesses: state.business.closedBusinesses,
  newBusinessTypeItems: state.business.newBusinessTypeItems,
  ignoredBusinessTypeItems: state.business.ignoredBusinessTypeItems,
  businessesCount: state.business.businessesCount,
  currentPage: state.business.currentPage,
  likedBusinessItemsCount: state.business.likedBusinessItemsCount,
  notInterestedBusinessItemsCount: state.business.notInterestedBusinessItemsCount,
  pursuingBusinessItemsCount: state.business.pursuingBusinessItemsCount,
  closedBusinessItemsCount: state.business.closedBusinessItemsCount,
  newBusinessTypeItemsCount: state.business.newBusinessTypeItemsCount,
  ignoredBusinessTypeItemsCount: state.business.ignoredBusinessTypeItemsCount,
  activeTabKey: state.business.activeTabKey,
});

const mapDispatchToProps = dispatch => ({
  getBusinesses: (hardCodeOffset) => dispatch(getBusinesses(hardCodeOffset)),
  setActiveBusinessTabKey: (activeTabKey) => dispatch(setActiveBusinessTabKey(activeTabKey)),
  resetBusinessFilter: () => dispatch(resetBusinessFilter()),
});

const OpportunitiesScreen = Form.create({ name: 'filter' })(Opportunities);

export default connect(mapStateToProps, mapDispatchToProps)(OpportunitiesScreen);
