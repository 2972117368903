import React, { Component } from "react";
import { connect } from "react-redux";
import AnalyticItem from "../AnalyticItem/AnalyticItem";

import { formatNumber } from "../../utils/formatNumber";
import { getCurrencySymbol } from "../../utils/getCurrencySymbol";

class AnalyticItemsWrapper extends Component {
  getAnalyticItems = () => {
    const {
      closedReports, pegateBusinessesCount,
      otherBusinessesCount, selectedCurrency,
    } = this.props;
    let analyticItem = {
      totalPortfolioInvestments: 0,
      totalInvested: `${getCurrencySymbol(selectedCurrency)} 0`,
      currentFMW: `${getCurrencySymbol(selectedCurrency)} 0`,
      dividents: `${getCurrencySymbol(selectedCurrency)} 0`,
      pegateBusinessesCount: 0,
      otherBusinessesCount: 0,
      IRR: '0%',
    };

    if (closedReports && closedReports.length) {
      let totalInvested = 0;
      let currentFMW = 0;
      let dividents = 0;
      let irr = 0;
      closedReports.map(element => {
        totalInvested += parseInt(element.final_amount);
        currentFMW += parseInt(element.fmv);
        irr += element.irr;

        element.reports.map(reportsItem => {
          dividents += parseInt(reportsItem.dividend);

          return dividents;
        });

        return irr;
      });

      analyticItem.totalPortfolioInvestments = closedReports.length;
      analyticItem.totalInvested = `${getCurrencySymbol(selectedCurrency)} ${formatNumber(totalInvested)}`;
      analyticItem.currentFMW = `${getCurrencySymbol(selectedCurrency)} ${formatNumber(currentFMW)}`;
      analyticItem.dividents = `${getCurrencySymbol(selectedCurrency)} ${formatNumber(dividents)}`;
      analyticItem.pegateBusinessesCount = pegateBusinessesCount;
      analyticItem.otherBusinessesCount = otherBusinessesCount;
      analyticItem.IRR = `${parseFloat(irr.toFixed(2))}%`;
    }

    return analyticItem;
  };

  render() {
    const {
      totalPortfolioInvestments,
      totalInvested,
      currentFMW,
    } = this.getAnalyticItems();

    return (
      <div className="row portfolio_section">
        <div className="column small-6 medium-4 large-4">
          <AnalyticItem title="Total portfolio investments" desc={totalPortfolioInvestments} />
        </div>
        <div className="column small-6 medium-4 large-4">
          <AnalyticItem title="Total invested" desc={totalInvested} />
        </div>
        <div className="column small-6 medium-4 large-4">
          <AnalyticItem title="Current FMV" desc={currentFMW} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  reports: state.reports.reports,
  closedReports: state.reports.closedReports,
  pegateBusinessesCount: state.reports.pegateBusinessesCount,
  otherBusinessesCount: state.reports.otherBusinessesCount,
  selectedCurrency: state.reports.selectedCurrency,
  irrSum: state.reports.irrSum,
});

export default connect(mapStateToProps)(AnalyticItemsWrapper);
