import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Select,
  Form,
  Table,
  message,
} from 'antd';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { addStats } from "../../../redux/reports/thunks/reportsThunk";
import { handleLoading } from "../../../utils/handleLoading";
import { formatNumber } from "../../../utils/formatNumber";
import { isQuarterOptionDisabled } from "../../../utils/isQuarterOptionDisabled";

import Icon from "../../../components/Icon/Icon";
import PopUp from "../../../components/PopUp/PopUp";
import NumericInput from "../../../components/NumericInput/NumericInput";
import Button from '../../../components/Button/Button';
import './style.scss';

const getBreakPointMonths = (year) => [`Jan ${year}`, `Mar ${year}`, `Jun ${year}`, `Sep ${year}`];

let yearsList = [];
const currentYear = new Date().getFullYear();

for (let i = currentYear - 4; i < currentYear; i++) {
  yearsList.push(i);
}

yearsList.push(currentYear);

for (let i = currentYear + 1; i < currentYear + 5; i++) {
  yearsList.push(i);
}

class AdminCharts extends Component {
  constructor(props) {
    super(props);

    this.reportsTableColumns = [
      {
        title: 'year',
        dataIndex: 'year',
        key: 'year',
      },
      {
        title: 'quarter',
        dataIndex: 'quarter',
        key: 'quarter',
      },
      {
        title: 'distribution / investment',
        dataIndex: 'dividend',
        key: 'dividend',
        render: (text) => <span>{formatNumber(text)}</span>,
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (payload) =>
          <div className="icon_btn">
            <Button
              title={<Icon type="pen" style={{ fontSize: "17px" }}/>}
              size="lg"
              theme="blue"
              className="radius-10"
              onClick={() => this.toggleWaterfallModal(true, true, payload)}
            />
          </div>
      },
    ];

    this.state = {
      waterfallChartOptions: {
        type: 'column',
        animation: true,
        xAxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            },
          },
          tickmarkPlacement: 'between',
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            }
          }
        },
        series: [],
        legend: {
          align: 'left',
          verticalAlign: 'bottom',
          labelFormatter: function () {
            return this.name.includes('distribution') ? 'distribution' : this.name;
          },
          itemStyle: {
            fontStyle: 'normal',
            fontVariant: '16px/1',
            fontFamily: '"Poppins",Arial,sans-serif',
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: '.04em',
            color: '#8F9CA4',
          },
        },
        plotOptions: {
          series: {
            events: {
              legendItemClick: function () {
                return false;
              },
            },
            states: {
              inactive: {
                opacity: 1
              },
            },
            point: {
              events: {
                mouseOver: this.setHoverData.bind(this)
              }
            },
            marker: {
              symbol: '<span class="text text-14 font-medium font-spacing-04">O</span>'
            }
          }
        },
        tooltip: {
          backgroundColor: '#3d3d3d',
          borderRadius: 8,
          borderWidth: 0,
          padding: 22,
          shadow: false,
          formatter: function () {
            return '<div class="chart_tooltip text">' +
              '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
              '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + formatNumber(this.y) + '</span></div>' +
              '</div>';
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 767
            },
            chartOptions: {
              xAxis: {
                labels: {
                  style: {
                    fontSize: '12px'
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontSize: '12px',
                  }
                }
              },
              tooltip: {
                padding: 12
              },
            }
          }]
        },
      },
      performanceChartOptions: {
        type: 'column',
        animation: true,
        xAxis: {
          categories: getBreakPointMonths(currentYear),
          labels: {
            autoRotation: false,
            overflow: 'allow',
            style: {
              fontSize: '12px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            },
          },
          tickmarkPlacement: 'between',
        },
        yAxis: {
          type: 'logarithmic',
          allowDecimals: true,
          labels: {
            style: {
              fontSize: '12px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            }
          }
        },
        series: [],
        legend: {
          align: 'left',
          verticalAlign: 'bottom',
          labelFormat: '<span class="text text-14 font-medium font-uppercase font-spacing-04" style="color:{color}">{name}</span>'
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: this.setHoverData.bind(this)
              }
            },
            marker: {
              symbol: '<span class="text text-14 font-medium font-spacing-04">O</span>'
            }
          }
        },
        tooltip: {
          backgroundColor: '#3d3d3d',
          borderRadius: 8,
          borderWidth: 0,
          padding: 22,
          shadow: false,
          formatter: function () {
            return '<div class="chart_tooltip text">' +
              '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
              '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + formatNumber(this.y) + '</span></div>' +
              '</div>';
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 767
            },
            chartOptions: {
              xAxis: {
                labels: {
                  rotation: -45,
                  style: {
                    fontSize: '12px'
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontSize: '12px',
                  }
                }
              },
              tooltip: {
                padding: 12
              },
            }
          }]
        }
      },
      indexChartOptions: {
        type: 'column',
        animation: true,
        credits: {
          enabled: false
        },
        xAxis: {
          categories: getBreakPointMonths(currentYear),
          labels: {
            autoRotation: false,
            overflow: 'allow',
            style: {
              fontSize: '12px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            },
          },
          tickmarkPlacement: 'between',
        },
        yAxis: {
          type: 'logarithmic',
          allowDecimals: true,
          labels: {
            style: {
              fontSize: '12px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            }
          }
        },
        series: [],
        legend: {
          align: 'left',
          verticalAlign: 'bottom',
          labelFormat: '<span class="text text-14 font-medium font-uppercase font-spacing-04" style="color:{color}">{name}</span>'
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: this.setHoverData.bind(this)
              }
            },
            marker: {
              symbol: '<span class="text text-14 font-medium font-spacing-04">O</span>'
            }
          }
        },
        tooltip: {
          backgroundColor: '#3d3d3d',
          borderRadius: 8,
          borderWidth: 0,
          padding: 22,
          shadow: false,
          formatter: function () {
            return '<div class="chart_tooltip text">' +
              '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
              '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + this.y + '</span></div>' +
              '</div>';
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 767
            },
            chartOptions: {
              xAxis: {
                labels: {
                  rotation: -45,
                  style: {
                    fontSize: '12px'
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontSize: '12px',
                  }
                }
              },
              tooltip: {
                padding: 12
              },
            }
          }]
        }
      },
      hoverData: null,
      popupVisible: false,
      isEditMode: false,
      year: '',
      quarter: '',
      dividend: '',
      performanceActiveQuarter: 'total',
      performanceActivePeriod: currentYear,
      indexActiveQuarter: 'total',
      indexActivePeriod: currentYear,
    }
  }

  getPerformanceChartData = () => {
    const { newReports } = this.props;
    const { performanceActivePeriod, performanceActiveQuarter } = this.state;
    const loopingData = ['revenue', 'gross_profit', 'net_profit', 'ebitda'];
    let series = [
      {
        name: "Revenue", color: "#F5C65B", type: 'spline',
        data: []
      },
      {
        name: "Gross profit", color: "#FF551F", type: 'spline',
        data: []
      },
      {
        name: "Net profit", color: "#36C1A3", type: 'spline',
        data: []
      },
      {
        name: "EBidta", color: "#3665C1", type: 'spline',
        data: []
      },
    ];

    newReports && newReports.reports && newReports.reports.map((element) => {
      if (performanceActiveQuarter !== 'total') {
        if (parseInt(element.year) === parseInt(performanceActivePeriod) && parseInt(element.quarter) === parseInt(performanceActiveQuarter)) {
          Object.keys(element).map(keyItem => {
            return loopingData.map(loopingDataItem => {
              if (keyItem === loopingDataItem) {
                switch (keyItem) {
                  case 'revenue':
                    series[0].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  case 'gross_profit':
                    series[1].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  case 'net_profit':
                    series[2].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  case 'ebitda':
                    series[3].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  default:
                }
              }

              return "";
            });
          });
        }
      } else {
        if (parseInt(element.year) === parseInt(performanceActivePeriod)) {
          Object.keys(element).map(keyItem => {
            return loopingData.map(loopingDataItem => {
              if (keyItem === loopingDataItem) {
                switch (keyItem) {
                  case 'revenue':
                    series[0].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  case 'gross_profit':
                    series[1].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  case 'net_profit':
                    series[2].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  case 'ebitda':
                    series[3].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  default:
                }
              }

              return "";
            });
          });
        }
      }

      return "";
    });

    this.setState(prevState => {
      let performanceChartOptions = Object.assign({}, prevState.performanceChartOptions);
      performanceChartOptions.series = series;
      performanceChartOptions.xAxis.categories = getBreakPointMonths(performanceActivePeriod);
      return { performanceChartOptions }
    });
  };

  getIndexChartData = () => {
    const { newReports } = this.props;
    const { indexActivePeriod, indexActiveQuarter } = this.state;
    const loopingData = ['gross_profit', 'net_profit', 'ebitda'];

    let series = [
      {
        name: "GP%", color: "#F5C65B", type: 'column',
        data: []
      },
      {
        name: "NP%", color: "#FF551F", type: 'column',
        data: []
      },
      {
        name: "EBitda%", color: "#36C1A3", type: 'column',
        data: []
      },
    ];

    newReports && newReports.reports && newReports.reports.map((element) => {
      if (indexActiveQuarter !== 'total') {
        if (parseInt(element.year) === parseInt(indexActivePeriod) && parseInt(element.quarter) === parseInt(indexActiveQuarter)) {
          Object.keys(element).map((keyItem) => {
            return loopingData.map((loopingDataItem) => {
              if (keyItem === loopingDataItem) {
                switch (keyItem) {
                  case 'gross_profit':
                    series[0].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem] / element.revenue,
                    });
                    break;
                  case 'net_profit':
                    series[1].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem] / element.revenue,
                    });
                    break;
                  case 'ebitda':
                    series[2].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem] / element.revenue,
                    });
                    break;
                  default:
                }
              }

              return "";
            });
          });
        }
      } else {
        if (parseInt(element.year) === parseInt(indexActivePeriod)) {
          Object.keys(element).map((keyItem) => {
            return loopingData.map((loopingDataItem) => {
              if (keyItem === loopingDataItem) {
                switch (keyItem) {
                  case 'gross_profit':
                    series[0].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem] / element.revenue,
                    });
                    break;
                  case 'net_profit':
                    series[1].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem] / element.revenue,
                    });
                    break;
                  case 'ebitda':
                    series[2].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem] / element.revenue,
                    });
                    break;
                  default:
                }
              }

              return "";
            });
          });
        }
      }

      return "";
    });

    this.setState(prevState => {
      let indexChartOptions = Object.assign({}, prevState.indexChartOptions);
      indexChartOptions.series = series;
      indexChartOptions.xAxis.categories = getBreakPointMonths(indexActivePeriod);
      return { indexChartOptions };
    });
  };

  getValidYearsOfWaterfallTable = () => {
    const { portfolioReports, businessID } = this.props;
    let allYears = [];

    const foundReport = portfolioReports.find((element) => parseInt(element.id) === parseInt(businessID));

    foundReport && foundReport.reports && foundReport.reports.map((element) => {
      return allYears.push(element.year);
    });

    const seen = new Set();
    // removing repeating elements from array, saving only 1 item per repeating
    const filteredArr = allYears.filter(el => {
      const duplicate = seen.has(el);
      seen.add(el);
      return !duplicate;
    });
    return filteredArr;
  };

  getWaterfallChartData = () => {
    const { reports, businessID, currency } = this.props;
    const foundReport = reports.find((item) => parseInt(item.id) === parseInt(businessID));
    const finalDate = foundReport ? foundReport.final_date : "0000-01-01";
    const finalYear = finalDate ? moment(finalDate).year() : 0;
    let series = [
      {
        name: "investment", color: "#F5C65B", type: 'column', showInLegend: true,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "investment q1", color: "#F5C65B", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "distribution", color: "#36C1A3", type: 'column', showInLegend: true,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "investment q2", color: "#F5C65B", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "distribution q2", color: "#36C1A3", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "investment q3", color: "#F5C65B", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "distribution q3", color: "#36C1A3", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "investment q4", color: "#F5C65B", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "distribution q4", color: "#36C1A3", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "subtotal", color: "#FF551F", type: 'column', showInLegend: true,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill({ y: null, x: null }),
      },
    ];
    let categories = [];
    let subtotalSum = 0;
    const quarter = finalDate ? Math.ceil(finalDate.split("-")[1] / 3) : '';
    

    if (this.getValidYearsOfWaterfallTable().length) {
      this.getValidYearsOfWaterfallTable().map((validYearItem, yearIndex) => {
        let currentYearInitialInvestment = null;
        let currentYearInvestmentQ1 = null;
        let currentYearInvestmentQ2 = null;
        let currentYearInvestmentQ3 = null;
        let currentYearInvestmentQ4 = null;
        let currentYearDistributionQ1 = null;
        let currentYearDistributionQ2 = null;
        let currentYearDistributionQ3 = null;
        let currentYearDistributionQ4 = null;
        foundReport && foundReport.reports && foundReport.reports.map((element, index) => {
          if (parseInt(validYearItem) === parseInt(element.year)) {
            if (yearIndex === 0) {
              currentYearInitialInvestment = foundReport ? foundReport.final_amount * -1 : 0;
            }
            if (element.dividend < 0) {
              switch (parseInt(element.quarter)) {
                case 1:
                  currentYearInvestmentQ1 += element.dividend;
                  break;
                case 2:
                  currentYearInvestmentQ2 += element.dividend;
                  break;
                case 3:
                  currentYearInvestmentQ3 += element.dividend;
                  break;
                case 4:
                  currentYearInvestmentQ4 += element.dividend;
                  break;
                default:
              }
            }
            else {
              switch (parseInt(element.quarter)) {
                case 1:
                  currentYearDistributionQ1 += element.dividend;
                  break;
                case 2:
                  currentYearDistributionQ2 += element.dividend;
                  break;
                case 3:
                  currentYearDistributionQ3 += element.dividend;
                  break;
                case 4:
                  currentYearDistributionQ4 += element.dividend;
                  break;
                default:
              }
            }
          }

          return "";
        });
        let currentYearSubTotal = (currentYearDistributionQ1 + currentYearDistributionQ2 + currentYearDistributionQ3 + currentYearDistributionQ4) - Math.abs(currentYearInitialInvestment + currentYearInvestmentQ1 + currentYearInvestmentQ2 + currentYearInvestmentQ3 + currentYearInvestmentQ4);
        series[0].data[yearIndex] = {
          y: currentYearInitialInvestment,
          x: yearIndex,
        };
        series[1].data[yearIndex] = {
          y: currentYearInvestmentQ1,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[2].data[yearIndex] = {
          y: currentYearDistributionQ1,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[3].data[yearIndex] = {
          y: currentYearInvestmentQ2,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[4].data[yearIndex] = {
          y: currentYearDistributionQ2,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[5].data[yearIndex] = {
          y: currentYearInvestmentQ3,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[6].data[yearIndex] = {
          y: currentYearDistributionQ3,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[7].data[yearIndex] = {
          y: currentYearInvestmentQ4,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[8].data[yearIndex] = {
          y: currentYearDistributionQ4,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        if (currentYearDistributionQ2) {
          series[2].showInLegend = false;
          series[4].showInLegend = true;
          series[6].showInLegend = false;
          series[8].showInLegend = false;
        }
        if (currentYearDistributionQ3) {
          series[2].showInLegend = false;
          series[4].showInLegend = false;
          series[6].showInLegend = true;
          series[8].showInLegend = false;
        }
        if (currentYearDistributionQ4) {
          series[2].showInLegend = false;
          series[4].showInLegend = false;
          series[6].showInLegend = false;
          series[8].showInLegend = true;
        }
        subtotalSum += currentYearSubTotal;
        if (yearIndex === 0) {
          categories.push(finalYear);
          if (!this.getValidYearsOfWaterfallTable().includes(finalYear)) {
            categories.push(validYearItem);
          }
        } else {
          categories.push(validYearItem);
        }

        return "";
      });
      series[9].data[this.getValidYearsOfWaterfallTable().length] = {
        y: subtotalSum,
        x: this.getValidYearsOfWaterfallTable().length,
      };
      categories.push('Subtotal');
    } else {
      series[0].data[0] = {
        y: foundReport ? foundReport.final_amount * -1 : 0,
        x: 0,
      };
      series[1].data[0] = { y: 0, x: 0 };
      series[2].data[0] = { y: 0, x: 0 };
      series[3].data[0] = { y: 0, x: 0 };
      series[4].data[0] = { y: 0, x: 0 };
      series[5].data[0] = { y: 0, x: 0 };
      series[6].data[0] = { y: 0, x: 0 };
      series[7].data[0] = { y: 0, x: 0 };
      series[8].data[0] = { y: 0, x: 0 };
      series[9].data[0] = { y: 0, x: 0 };
      categories.push(new Date(foundReport ? foundReport.closed : '0000-01-01').getFullYear());
    }

    
    const filteredSeries = [];
    for (let i = 0; i < series.length; i++) {
      const item = series[i];
      const filtered = item.data.filter((element) => {
        return element.y != null;
      });
      item.data = filtered.length > 0 ? filtered : null;
      if (item.data != null) filteredSeries.push(item);
    }

    let datasetCountList = [];
    filteredSeries.map((item) => datasetCountList.push(item.data.length));

    let maxDatasetCount = 0;
    for (let i = 0; i < datasetCountList.length; i++) {
      if (datasetCountList[i] > maxDatasetCount) {
        maxDatasetCount = datasetCountList[i];
      }
    }
    for (let i = 0; i <= maxDatasetCount - 2; i++) {
      filteredSeries[filteredSeries.length - 1].data.unshift(null);
    }

    this.setState(prevState => {
      let waterfallChartOptions = Object.assign({}, prevState.waterfallChartOptions);
      waterfallChartOptions.series = filteredSeries;
      waterfallChartOptions.xAxis.categories = categories;
      waterfallChartOptions.tooltip.formatter = function () {
        const tooltipTitle = this.series.name === 'distribution' ? 'distribution q1' :
        this.series.name === "investment" || this.series.name === "subtotal" ? this.series.name + " q" + quarter : this.series.name;

        return '<div class="chart_tooltip text">' +
          '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + tooltipTitle + '</div><br/>' +
          '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + currency + ' ' + formatNumber(Math.round(this.y)) + '</span></div>' +
          '</div>';
      };
      return { waterfallChartOptions };
    });
  };

  handlePerformanceActivePeriodOnChange = (value) => {
    this.setState({
      performanceActivePeriod: value,
    }, () => {
      this.getPerformanceChartData();
    });
  };

  handleIndexActivePeriodOnChange = (value) => {
    this.setState({
      indexActivePeriod: value,
    }, () => {
      this.getIndexChartData();
    });
  };

  setHoverData = (e) => {
    // The chart is not updated because `chartOptions` has not changed.
    this.setState({hoverData: e.target.category})
  };

  toggleQuarter = (chartType, quarter) => {
    this.setState({
      [chartType]: quarter,
    }, () => {
      if (chartType === 'performanceActiveQuarter') {
        this.getPerformanceChartData();
      }
      else {
        this.getIndexChartData();
      }
    });
  };

  getQuarterBtnColor = (stateName, quarter) => {
    return this.state[stateName] === quarter ? 'blue' : 'grey';
  };

  getWaterfallTableSource = () => {
    const { portfolioReports, businessID } = this.props;
    let returningWaterfallData = [];
    const foundReport = portfolioReports && portfolioReports.find((element) => parseInt(element.id) === parseInt(businessID));

    if (foundReport) {
      let tableData = {};

      foundReport.reports && foundReport.reports && foundReport.reports.map((element) => {
        tableData.year = element.year;
        tableData.quarter = element.quarter;
        tableData.dividend = element.dividend;
        returningWaterfallData.push({
          ...tableData,
          action: {
            ...tableData,
          },
        });

        return "";
      });
    }

    return returningWaterfallData;
  };

  toggleWaterfallModal = (status, isEditMode, inputData) => {
    this.setState({
      popupVisible: status,
      isEditMode,
      year: inputData ? inputData.year : '',
      quarter: inputData ? inputData.quarter : '',
      dividend: inputData ? inputData.dividend : '',
    }, () => {
      if (status) {
        window.scroll(0, 0);
      }
    });
  };

  handleQuarterOptionsDisabled = (quarter) => {
    let { portfolioReports, businessID } = this.props;
    portfolioReports = portfolioReports ? portfolioReports : [];
    const foundReport = portfolioReports.find((element) => parseInt(element.id) === parseInt(businessID));
    let reports = foundReport && foundReport.reports ? foundReport.reports : [];
    const inputtedYear = this.props.form.getFieldValue("year");

    return isQuarterOptionDisabled(reports, inputtedYear, quarter);
  };

  onYearOnChange = () => {
    this.props.form.setFieldsValue({ quarter: '' });
  };

  getWaterfallPerformanceYearsList = () => {
    const { portfolioReports, businessID } = this.props;
    const yearsList = [];
    const foundReport = portfolioReports && portfolioReports.find((item) => parseInt(item.id) === parseInt(businessID));
    const finalDate = foundReport ? foundReport.final_date : "0000-01-01";
    const finalYear = finalDate ? moment(finalDate).year() : 0;

    for (let i = finalYear; i <= finalYear + 10; i++) {
      yearsList.push(i);
    }

    return yearsList;
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { addStats, businessID, currency, investor } = this.props;
        const params = {
          year: values.year,
          quarter: values.quarter,
          dividend: values.dividend,
          business: businessID,
          investor,
        };
        addStats(params, currency);
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { loading, errorMessage } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;

    if (!errorText && !loadingState && prevProps.loading.state && loadingType === "AddStats") {
      message.success('Report has been added successfully');
      this.toggleWaterfallModal(false);
    }

    if (loadingType === 'GetReport' && !loadingState && prevProps.loading.state && !errorText) {
      this.getPerformanceChartData();
      this.getIndexChartData();
      this.getWaterfallChartData();
    }

    if (loadingType === 'AddStats' && !loadingState && prevProps.loading.state && !errorText) {
      this.getWaterfallChartData();
    }
  }

  render() {
    const { form, loading, currency } = this.props;
    const { getFieldDecorator } = form;

    const {
      waterfallChartOptions, popupVisible, isEditMode,
      year, quarter, dividend,
    } = this.state;
    const waterfallTableSource = this.getWaterfallTableSource();

    return (
      <>
        <div className="row">
          <div className="column small-12">
            <div className="row portfolio_section">
              <div className="column small-12">
                <div className="bg-white radius-10 shadow-layout portfolio_card expand_sm">
                  <div className="text text-22 font-heavy color-grey portfolio_card__title">
                    Waterfall table
                  </div>

                  <div className="chart_wrapper">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={waterfallChartOptions}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row portfolio_section" />

            <div className="row">
              <div className="column small-12 medium-9 large-6">
                <div className="text text-22 font-heavy color-grey mb_25">
                  Waterfall Performance
                </div>
                <Table
                  dataSource={waterfallTableSource}
                  columns={this.reportsTableColumns}
                  className="c-table c-table-contacts c-table-sm c-table-expand mb_35"
                  pagination={{ hideOnSinglePage: true }}
                />
                <Button
                  title="Add Distribution / Investment"
                  prefix={<Icon type="plus" size={12} style={{ marginRight: 13 }} />}
                  size="lg"
                  theme="lightblue"
                  className="btn-bordered radius-8"
                  type="button"
                  onClick={() => this.toggleWaterfallModal(true)}
                />
              </div>
            </div>
          </div>
        </div>

        {
          popupVisible ?
            <PopUp
              visible
              className="relative_parent"
              innerClassNames="shadow-layout bg-white radius-10 relative x-lg base"
            >
              <Form onSubmit={this.onSubmit} >
                <div className="popup_close color-grey-700" onClick={() => this.toggleWaterfallModal(false)}>
                  <Icon type="cancel" size={24}/>
                </div>
                <div>
                  <div className="flex-container align-center text-center">
                    <div className="text text-28 text-height-33 font-heavy font-spacing-02 color-grey font-uppercase mb_35">
                      { !isEditMode ? "Add Distribution / Investment" : "Edit Distribution / Investment" } ({currency})
                    </div>
                  </div>
                  <div>
                    <div className="form-item mb_50">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        YEAR
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator('year', {
                          initialValue: year,
                          rules: [{required: true, message: 'Please fill out this field'}],
                        })(
                          <Select
                            onChange={this.onYearOnChange}
                            disabled={isEditMode}
                            className="short_input"
                          >
                            {
                              this.getWaterfallPerformanceYearsList().map((item, index) => (
                                <Select.Option key={index} value={item} >{item}</Select.Option>
                              ))
                            }
                          </Select>
                        )}
                      </Form.Item>
                    </div>

                    <div className="mb_30">
                      <div className="row data_table data_table-5">
                        <div className="small-12 text text-22 font-heavy color-grey mb_35">
                          Waterfall table
                        </div>
                        <div className="small-5 quarter-count-wrapper">
                          <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_10 nowrap">
                            Quarter count
                          </div>
                          <div className="form-item">
                            <Form.Item className="mb_0">
                              {getFieldDecorator('quarter', {
                                initialValue: quarter,
                                rules: [{required: true, message: 'Please fill out this field'}],
                              })(
                                <Select disabled={isEditMode} >
                                  {
                                    new Array(4).fill(null).map((element, index) => (
                                      <Select.Option
                                        value={index + 1}
                                        disabled={this.handleQuarterOptionsDisabled(index + 1)}
                                      >
                                        { index + 1 }
                                      </Select.Option>
                                    ))
                                  }
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="small-5">
                          <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_10">
                            distribution/investment
                          </div>
                          <div className="form-item">
                            <Form.Item className="mb_0">
                              {getFieldDecorator('dividend', {
                                initialValue: dividend,
                                rules: [{required: true, message: 'Please fill out this field'}],
                              })(
                                <NumericInput className="fluid-x" />
                              )}
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn_group medium-flex-container align-middle align-right">
                    <Form.Item className="mb_0">
                      <Button
                        title="Cancel"
                        size="lg"
                        theme="default"
                        className="btn-link radius-8 font-uppercase"
                        type="button"
                        onClick={() => this.toggleWaterfallModal(false)}
                      />
                    </Form.Item>
                    <Form.Item className="mb_0">
                      <Button
                        title="Save"
                        type="submit"
                        size="xl"
                        theme="blue"
                        prefix={<Icon type="checked" size={18} style={{marginRight: 11}}/>}
                        className="radius-8"
                        style={{fontSize: 18}}
                        loading={handleLoading(loading, "AddStats")}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </PopUp>
            : null
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.reports.loading,
  errorMessage: state.reports.errorMessage,
  portfolioReports: state.reports.reports,
  newReports: state.reports.newReports,
  reports: state.reports.reports,
  opportunityGroups: state.dictionaries.opportunityGroups,
});

const mapDispatchToProps = dispatch => ({
  addStats: (params, currency) => dispatch(addStats(params, currency)),
});

const AdminChartsScreen = Form.create({name: 'PortfolioInner'})(AdminCharts);

export default connect(mapStateToProps, mapDispatchToProps)(AdminChartsScreen);
