import { Tooltip } from "antd";
import React from "react";
import Icon from "../Icon/Icon";

export const ToolTip = ({ className = '', placement, title, type = 'question' }) => {
  return (
    <Tooltip
      placement={placement}
      title={title}
      className={className}

      >
      &nbsp;
      <span className="align-self-top">
        <Icon type={type} />
      </span>
    </Tooltip>
  )
}