import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, Route, Switch} from "react-router-dom";

import SignIn from "./noAuth/SignIn/SignIn";
import Activation from "./noAuth/Activation/Activation";
import Individual1 from "./noAuth/Registration/Individual1";
import Individual2 from "./noAuth/Registration/Individual2";
import Entity1 from "./noAuth/Registration/Entity1";
import Entity2 from "./noAuth/Registration/Entity2";
import Entity3 from "./noAuth/Registration/Entity3";
import PrivateRoutes from "./PrivateRoutes";
import RecoverPasswordScreen from "./screens/RecoverPassword/RecoverPassword";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

class PublicRoutes extends Component {

  isLoggedIn = () => {
    return localStorage.getItem("xID") && localStorage.getItem("xTOKEN");
  };

  render() {
    return (
      this.isLoggedIn() ?
        <Switch>
          <Route path="/" component={ PrivateRoutes } />
        </Switch>
      :
      <div className="flex-container log_out_layout fluid-y">
        <div className="log_out_leftside hide-md" style={{backgroundImage: `url(${require("./assets/img/bg.jpg")})`}}>
          <Link to="/">
            <img src={require("../src/assets/img/pegate-logo.png")} alt=""/>
          </Link>
          <div className="site_text">
            <div className="site_text__title text text-28 text-height-40 font-heavy font-uppercase color-white font-spacing-02">
              YOUR GATEWAY TO
              Private Equity
            </div>
            <div className="site_text__desc text text-height-24 font-medium color-white">
              We provide transparency in private equity investments.
            </div>
          </div>
        </div>
        <div className="log_out_main fluid-x">
          <ScrollToTop>
            <Switch>
              <Route path="/" component={ SignIn } exact />
              <Route path="/signin" component={ SignIn } exact />
              <Route path="/signin/2fa" component={ () => <SignIn isAdmin /> } exact />
              <Route path="/activate/:code" component={ Activation } exact />
              <Route path="/personal-information" component={ Individual1 } exact />
              <Route path="/investor-confirmation" component={ Individual2 } exact />
              <Route path="/entity-information" component={ Entity1 } exact />
              <Route path="/entity-personal-information" component={ Entity2 } exact />
              <Route path="/entity-investor-confirmation" component={ Entity3 } exact />
              <Route path="/recover/:code" component={RecoverPasswordScreen} />
            </Switch>
          </ScrollToTop>
        </div>
      </div>
    );
  }
}

// mapStateToProps needs for re-rendering this component when xID and xTOKEN are changed
const mapStateToProps = state => ({
  xID: state.user.xID,
  xTOKEN: state.user.xTOKEN
});

export default connect(mapStateToProps)(PublicRoutes);