import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, message, Upload } from 'antd';
import TextArea from "antd/es/input/TextArea";

import history from "../../history";
import { getMessage } from "../../redux/messages/thunks/messagesThunks";
import { downloadDataRoomItem } from "../../redux/dataRoom/thunks/dataRoomThunks";
import { addMessage } from "../../redux/messages/thunks/messagesThunks";
import { formatDate } from "../../utils/formatDate";
import { getMediaFileFromServer } from "../../utils/getMediaFileFromServer";
import { handleLoading } from "../../utils/handleLoading";
import { handleErrorMessage } from "../../utils/handleErrorMessage";
import { getDataRoomIconFromFileExtension } from "../../utils/getDataRoomIconFromFileExtension";
import { priorityColorsDetector } from "../../utils/priorityColorsDetector";

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import FileCard from "../../components/FileCard/FileCard";
import StringToJSX from "../../components/StringToJSX/StringToJSX";
import './style.scss';

class ContactCenterInner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      fileList: []
    }
  }

  goBackButtonOnClick = () => {
    history.push("/contact-center");
  };

  getCurrentMessage = () => {
    const {currentOpenedMessage} = this.props;
    let currentMessageData = {
      priority: "",
      first_name: "",
      last_name:  "",
      created:  "",
      body: "",
      subject: "",
      business: "",
      business_name: "",
      avatar: "",
      files: []
    };

    if (currentOpenedMessage) {
      return {
        priority: currentOpenedMessage.priority ? currentOpenedMessage.priority : "",
        first_name: currentOpenedMessage.first_name ? currentOpenedMessage.first_name : "",
        last_name: currentOpenedMessage.last_name ? currentOpenedMessage.last_name : "",
        created: currentOpenedMessage.created ? currentOpenedMessage.created : "",
        body: currentOpenedMessage.body ? currentOpenedMessage.body : "",
        subject: currentOpenedMessage.subject ? currentOpenedMessage.subject : "",
        business: currentOpenedMessage.business ? currentOpenedMessage.business : 0,
        business_name: currentOpenedMessage.business_name ? currentOpenedMessage.business_name : "",
        avatar: currentOpenedMessage.avatar ? currentOpenedMessage.avatar : "",
        files: currentOpenedMessage.files ? currentOpenedMessage.files : []
      }
    }

    return currentMessageData;
  };

  handleFileDownload = fileItem => {
    const {downloadDataRoomItem} = this.props;
    const {
      token,
      year,
      month,
      day,
      id,
      name
    } = fileItem;

    downloadDataRoomItem(token, year, month, day, id, name);
  };

  fakeCustomRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  draggerOnChange = info => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done" || info.file.status === "removed") {
      const { fileList } = info;
      this.setState({ fileList });
    }
  };

  handleFileCardOnClick = (fileItem) => {
    const currentMessage = this.getCurrentMessage();
    const { business } = currentMessage;

    if (fileItem.type === 'FILE') {
      this.handleFileDownload(fileItem);
    } else {
      localStorage.setItem('routes', JSON.stringify([
        {
          path: "/data-room",
          breadcrumbName: "Data Room",
        },
        {
          path: `/data-room/0/${fileItem.name}/true/${business}`,
          breadcrumbName: fileItem.name,
        },
      ]));
      history.push(`/data-room/0/${fileItem.name}/true/${business}`);
    }
  };

  changeBackSlashNToBr = (input) => {
    return input.replaceAll('\n', '<br/>');
  };

  onSubmit = event => {
    event.preventDefault();
    const { form } = this.props;

    form.validateFields((err, values) => {
      const { addMessage, currentOpenedMessage } = this.props;
      const { fileList } = this.state;
      const { business, subject, priority, from } = currentOpenedMessage;
      addMessage(from, business, subject, values.message, priority, fileList, 'USER');
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { loading, errorMessage } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;

    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "AddMessage")) {
      message.error(errorText);
      return;
    }

    if (!errorText && !loadingState && prevProps.loading.state && loadingType === "AddMessage") {
      message.success("Message was added");
      this.props.form.resetFields();
      return;
    }
  }

  componentDidMount() {
    const { getMessage } = this.props;
    const { id } = this.props.match.params;

    getMessage(id);
  }

  render() {
    const { message } = this.state;
    const { form, loading } = this.props;
    const { getFieldDecorator } = form;
    const currentMessage = this.getCurrentMessage();
    const {
      priority,
      first_name,
      last_name,
      created,
      body,
      subject,
      business_name,
      avatar,
      files
    } = currentMessage;
    const currentMessagePriorityColor = priorityColorsDetector(priority.toLowerCase());

    const props = {
      customRequest: this.fakeCustomRequest,
      onChange: this.draggerOnChange,
      showUploadList: {
        showDownloadIcon: false,
        showRemoveIcon: true,
        removeIcon: <Icon type="cancel" />
      }
    };

    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="flex-container align-middle">
                <Button
                  title={<Icon type="key-left" size={16} />}
                  size="md"
                  theme="lightblue"
                  className="radius-10 back_btn"
                  onClick={this.goBackButtonOnClick}
                />
                <div className="page_title visible-first custom-headline text text-24 text-height-48 font-heavy color-grey">
                  <h1>{subject}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="column small-12">
            <div className="flex-container align-middle message_tags">
              {
                business_name ? (
                  <div className="text text-12 color-grey-800 font-medium font-uppercase m-fluid-x invest_tag">
                    {business_name}
                  </div>
                ) : null
              }
              <div className={`text text-14 color-grey font-medium font-uppercase imp_tag imp_tag_${currentMessagePriorityColor}`}>
                {priority}
              </div>
            </div>

            <div className="flex-container align-middle message_info">
              <div className="radius-circle clip message_info__pic">
                <img src={avatar ? getMediaFileFromServer(avatar) : require("../../assets/img/no_profile.png")} alt=""/>
              </div>
              <div className="text text-20 font-bold color-grey message_info__name">
                {`${first_name} ${last_name}`}
              </div>
              <div className="text text-14 font-medium color-grey-800 message_info__date">
                {formatDate(created)}
              </div>
            </div>
          </div>
          <div className="column small-12 large-10">
            <div className="text text-height-30 font-medium color-grey message_text">
              <StringToJSX text={this.changeBackSlashNToBr(body)} />
            </div>

            {
              files &&
                <div className="flex-container flex-wrap align-middle message_files">
                  {
                    files.map(fileItem => {
                      const fileIcon = getDataRoomIconFromFileExtension(fileItem.name);
                      return (
                        <FileCard
                          key={fileItem.id}
                          id={fileItem.id}
                          isPrivate={false}
                          type={fileItem.type === 'FILE' ? 'file' : 'folder'}
                          title={fileItem.name}
                          desc=""
                          showFileActions={false}
                          onClick={() => this.handleFileCardOnClick(fileItem)}
                          className="md"
                          img={
                            fileItem.type === 'FILE'
                              ? <img alt="" src={require(`../../assets/img/${fileIcon}`)} />
                              : <Icon type="folder-file" size={27} />
                          }
                          fileSrc={{
                            year: fileItem.year,
                            month: fileItem.month,
                            day: fileItem.day,
                            token: fileItem.token,
                          }}
                        />
                      )
                    })
                  }
                </div>
            }
          </div>

          <div className="column small-12">
            <Form
              onSubmit={this.onSubmit}
              className="bg-white radius-10 shadow-component message_reply"
            >
              <Form.Item className="mb_0 message_input message_reply_input">
                {getFieldDecorator('message', {
                  initialValue: message,
                  rules: [{required: false, message: 'Please fill out this field'}],
                })(
                  <TextArea placeholder="Reply to message..." />
                )}
              </Form.Item>

              <div className="message_files" />
              <div className="message_window_submit fix_sm btn_group">
                <Button
                  title="Send Message"
                  prefix={<Icon type="send" style={{ marginRight: 8 }} />}
                  size="lg"
                  theme="blue"
                  className="btn-shadow radius-8 sm_circle"
                  type="submit"
                  loading={handleLoading(loading, "AddMessage")}
                />
                <Upload {...props} >
                  <Button
                    title="Attach a file"
                    prefix={<Icon type="attach" size={16} style={{ marginRight: 7 }} />}
                    size="lg"
                    theme="default"
                    className="radius-8 btn-link sm_hide_label"
                    style={{fontSize: 16}}
                  />
                </Upload>
              </div>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

const ContactCenterInnerForm = Form.create({ name: 'Entity3' })(ContactCenterInner);

const mapStateToProps = state => ({
  loading: state.messages.loading,
  errorMessage: state.messages.errorMessage,
  currentOpenedMessage: state.messages.currentOpenedMessage,
});

const mapDispatchToProps = dispatch => ({
  getMessage: id => dispatch(getMessage(id)),
  downloadDataRoomItem: (token, year, month, day, id, fileName) => dispatch(downloadDataRoomItem(token, year, month, day, id, fileName)),
  addMessage: (to, business, subject, body, priority, files, type) => dispatch(addMessage(to, business, subject, body, priority, files, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactCenterInnerForm);