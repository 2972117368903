import React, { Component } from "react";
import { connect } from "react-redux";
import { getOnboarding, setOnboardingData } from "../../redux/registration/thunks/registrationThunks";
import { clearOptions, getOptions, setOptions } from "../../redux/dictionaries/thunks/dictionariesOptionsThunk";

import { Form, Radio, message } from "antd";
import OnboardingHeader from "./OnboardingHeader";
import OnboardingFooter from "./OnboardingFooter";


class OnboardingStep6 extends Component {

  getInitialValues = () => {
    const {onboardingData} = this.props;
    let returningData = {
      // going_to_sell: "",
      liquidity_level: "",
      risk_level: "",
      reward_level: "",
    };

    if (onboardingData) {
      returningData = {
        // going_to_sell: onboardingData.going_to_sell
        liquidity_level: onboardingData.liquidity_level,
        risk_level: onboardingData.risk_level,
        reward_level: onboardingData.reward_level,
      };
    }

    return returningData;
  };

  setOnboardingInfo = (type, callback) => { // type can be prev, next or save
    if (type !== 'prev') {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          !values.liquidity_level && delete values.liquidity_level;
          // !values.risk_level && delete values.risk_level;
          !values.reward_level && delete values.reward_level;
          callback(values);
        }
      });
    }
    else {
      const values = this.props.form.getFieldsValue();
      !values.liquidity_level && delete values.liquidity_level;
      // !values.risk_level && delete values.risk_level;
      !values.reward_level && delete values.reward_level;
      callback(values);
    }
  };

  onSubmit = event => {
    event.preventDefault();
    const { setOnboardingData } = this.props;

    this.setOnboardingInfo('save',(values) => {
      setOnboardingData(values);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const {loading, errorMessage} = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    // const errorType = errorMessage.type;
    const errorText = errorMessage.message;

    if (!errorText && !loadingState && prevProps.loading.state) {
      if (loadingType === "SetOnboardingData") {
        message.success("Saved");
      }
    }
  }

  componentDidMount() {
    window.scroll(0, 0);
    const {getOnboarding} = this.props;

    getOnboarding();
    // getOptions(5);
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const {
      // going_to_sell,
      liquidity_level,
      reward_level,
    } = this.getInitialValues();

    return (
      <OnboardingHeader step={4} >
        <div className="row">
          <div className="column small-12 x-large-10">
            <Form onSubmit={this.onSubmit} >
              <div className="form_card bg-white radius-10 shadow-layout mb_45">
                <div className="large-11 mb_50">
                  {/*
                    chka API-um
                    <div className="form-item mb_35">
                      <div className="form-item-label text text-height-22 font-semibold color-grey mb_10 large-8">
                        What is your preferred time horizon & liquidity of your investible Assets (allocate 100 points to items below) *
                      </div>
                      <Form.Item>
                        {getFieldDecorator("timeHorizon", {
                          initialValue: this.state.timeHorizon,
                          rules: [{required: true, message: "Please fill out this field"}],
                          onChange: this.handleRadioChange
                        })(
                          <Radio.Group name="timeHorizon">
                            <Radio value={1}>Short Term (1 year or less)</Radio>
                            <Radio value={2}>Medium Term (1-3 years)</Radio>
                            <Radio value={3}>Long Term (3 years or more)</Radio>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </div>
                  */}

                  {/*<div className="form-item mb_35">
                    <div className="form-item-label text text-height-22 font-semibold color-grey mb_10 large-8">
                      Is it reasonably likely that you may need to sell a PE Gate Investment in order to satisfy another
                      financial obligation or commitment? Investments in private businesses provide very limited liquidity,
                      as there is no public market on which such securities may be sold. If you may need to sell
                      your investments in a short timeframe, PE Gate Investments are unlikely to be suitable for you. *
                    </div>
                    <Form.Item>
                      {getFieldDecorator("going_to_sell", {
                        initialValue: going_to_sell,
                        rules: [{required: false, message: "Please fill out this field"}]
                      })(
                        <Radio.Group name="reasonOfHorizon">
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>*/}
                </div>

                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey label-with-icon-wrapper">
                    <span className="label-icon-text">What is your preferred time horizon & liquidity - 1 for Short Term and 5 for Long Term</span>
                    {/*<Tooltip
                      placement="right"
                      title="Risk Level for PE Gate Investments - Short Term 1 - Long Term 5
                        Investments in private businesses are inherently more risky than
                        investing in Guaranteed Investment Certificates (GICs)"
                    >
                      <div className="align-self-top label-icon">
                        <Icon type="question" />
                      </div>
                    </Tooltip>*/}
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("liquidity_level", {
                      initialValue: liquidity_level,
                      rules: [{required: false, message: "Please fill out this field"}]
                    })(
                      <Radio.Group className="fluid-x" >
                        {
                          Array(5).fill(0).map((optionItem, index) => (
                            <Radio key={index + 1} value={index + 1}> {index + 1} </Radio>
                          ))
                        }
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>

                {/*<div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Risk Level for PE Gate Investments - Short Term 1 - Long Term 5
                  </div>
                  <div className="form-item-hint text text-12 font-medium font-spacing-02 color-grey-800 mb_10">
                    Investments in private businesses are inherently more risky than investing in Guaranteed Investment Certificates (GICs)
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("risk_level", {
                      initialValue: risk_level,
                      rules: [{required: false, message: "Please fill out this field"}]
                    })(
                      <Radio.Group className="fluid-x" >
                        {
                          Array(5).fill(0).map((optionItem, index) => (
                            <Radio key={index + 1} value={index + 1}> {index + 1} </Radio>
                          ))
                        }
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>*/}

                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey label-with-icon-wrapper">
                    <span className="label-icon-text">
                      If investing in a private business, select your preference in terms of risk/reward 1 for Low risk/reward and
                      5 for High risk/reward. Investing in private businesses is inherently more risky than
                      investing in Guaranteed Investment Certificates (GICs)
                    </span>
                    {/*<Tooltip
                      placement="right"
                      title={
                        <>
                          Risk Level for PE Gate Investments - Low risk/reward 1,  High risk/reward 5
                          <br/>
                          Investments in private businesses are inherently more risky than investing in Guaranteed Investment Certificates (GICs)
                        </>
                      }
                    >
                      <div className="align-self-top label-icon">
                        <Icon type="question" />
                      </div>
                    </Tooltip>*/}
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("reward_level", {
                      initialValue: reward_level,
                      rules: [{required: false, message: "Please fill out this field"}]
                    })(
                      <Radio.Group className="fluid-x" >
                        {
                          Array(5).fill(0).map((optionItem, index) => (
                            <Radio key={index + 1} value={index + 1}> {index + 1} </Radio>
                          ))
                        }
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>

                {/*
                chka API-um
                <div className="text text-20 text-height-30 font-medium color-grey mb_65">
                  <p>
                    Risk Level for PE Gate Investments – Business Stage <br/>
                    Investments in private businesses are inherently more risky than investing in Guaranteed Investment Certificates (GICs).
                  </p>
                </div>

                <div className="form-item mb_35">
                  <div className="form-item-label text text-height-22 font-semibold color-grey mb_10 large-8">
                    Is it reasonably likely that you may need to sell a PE Gate Investment in order to satisfy another
                    financial obligation or commitment? Investments in private businesses provide very limited liquidity,
                    as there is no public market on which such securities may be sold. If you may need to sell
                    your investments in a short timeframe, PE Gate Investments are unlikely to be suitable for you. *
                  </div>
                  <Form.Item>
                    {getFieldDecorator("portfolioType", {
                      initialValue: this.state.portfolioType,
                      rules: [{required: true, message: "Please fill out this field"}],
                      onChange: this.handleRadioChange
                    })(
                      <Radio.Group name="portfolioType" className="radio-group-block">
                        <Radio value={1}>Low/Medium: Public market securities; highly liquid</Radio>
                        <Radio value={2}>Medium: Existing profitable private business, owner/management staying</Radio>
                        <Radio value={3}>Medium/High: Existing profitable business, owner/management leaving</Radio>
                        <Radio value={4}>High: Proven start-up; existing unprofitable business, management staying</Radio>
                        <Radio value={5}>Very High: Start-up, venture capital or existing unprofitable business, management leaving</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>

                <div className="form-item mb_35">
                  <div className="form-item-label text text-height-22 font-semibold color-grey mb_10 large-8">
                    If investing in a private business, allocate 10 points to each form of investment to reflect
                    your preference of risk/reward. *
                  </div>
                  <Form.Item>
                    {getFieldDecorator("privateBusinessRisk", {
                      initialValue: this.state.privateBusinessRisk,
                      rules: [{required: true, message: "Please fill out this field"}],
                      onChange: this.handleRadioChange
                    })(
                      <Radio.Group name="privateBusinessRisk" className="radio-group-block">
                        <Radio value={1}>Low/Medium: Debt or convertible debt in a private business</Radio>
                        <Radio value={2}>Medium: Preferred shares and convertible bonds in a private business</Radio>
                        <Radio value={3}>Medium/High: Common shares in an existing business</Radio>
                        <Radio value={4}>High: Common shares in a proven start-up</Radio>
                        <Radio value={5}>Very High: Common shares in an unproven start-up</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>*/}
              </div>

              <OnboardingFooter
                step={4}
                setOnboardingInfo={this.setOnboardingInfo}
              />
            </Form>
          </div>
        </div>
      </OnboardingHeader>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.registration.loading,
  errorMessage: state.registration.errorMessage,
  opportunityGroups: state.dictionaries.opportunityGroups,
  onboardingData: state.registration.onboardingData
});

const mapDispatchToProps = dispatch => ({
  setOnboardingData: params => dispatch(setOnboardingData(params)),
  getOnboarding: () => dispatch(getOnboarding()),
  setOptions: params => dispatch(setOptions(params)),
  getOptions: optionGroup => dispatch(getOptions(optionGroup)),
  clearOptions: optionGroup => dispatch(clearOptions(optionGroup))
});

const OnboardingStep6Screen = Form.create({ name: "OnboardingStep6" })(OnboardingStep6);

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingStep6Screen);