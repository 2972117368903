import produce from 'immer';
import {
  SEND_RECOVERY_LINK_START, SEND_RECOVERY_LINK_SUCCESS, SEND_RECOVERY_LINK_FAIL,
  RECOVER_PASSWORD_START, RECOVER_PASSWORD_SUCCESS, RECOVER_PASSWORD_FAIL,
  LOGIN_USER_START, LOGIN_USER_SUCCESS, LOGIN_USER_FAIL,
  LOGOUT_START, LOGOUT_SUCCESS, LOGOUT_FAIL,
  GET_PROFILE_START, GET_PROFILE_SUCCESS, GET_PROFILE_FAIL,
  UPDATE_PROFILE_START, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL,
  INIT_LOAD_START, INIT_LOAD_SUCCESS, INIT_LOAD_FAIL,
  CHANGE_PASSWORD_START, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL
} from "../constants";

const initialState = {
  loading: {
    type: "",
    state: false
  },
  errorMessage: {
    type: "",
    message: ""
  },
  xID: localStorage.getItem("xID"),
  xTOKEN: localStorage.getItem("xTOKEN"),
  role: localStorage.getItem("role"),
  userInfo: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_LOAD_START:
      return produce(state, draft => {
        draft.loading.type = "InitLoad";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case INIT_LOAD_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        action.payload.hasOwnProperty("userInfo") && (draft.userInfo = action.payload.userInfo);
      });
    case INIT_LOAD_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "InitLoad";
        draft.errorMessage.message = action.payload;
      });
    case SEND_RECOVERY_LINK_START:
      return produce(state, draft => {
        draft.loading.type = "SendRecoveryLink";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEND_RECOVERY_LINK_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case SEND_RECOVERY_LINK_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SendRecoveryLink";
        draft.errorMessage.message = action.payload;
      });
    case RECOVER_PASSWORD_START:
      return produce(state, draft => {
        draft.loading.type = "RecoverPassword";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case RECOVER_PASSWORD_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case RECOVER_PASSWORD_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "RecoverPassword";
        draft.errorMessage.message = action.payload;
      });
    case LOGIN_USER_START:
      return produce(state, draft => {
        draft.loading.type = "LoginUser";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case LOGIN_USER_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.xID = action.payload.id;
        draft.xTOKEN = action.payload.token;
        draft.role = action.payload.role;
      });
    case LOGIN_USER_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "LoginUser";
        draft.errorMessage.message = action.payload;
      });
    case LOGOUT_START:
      return produce(state, draft => {
        draft.loading.type = "Logout";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case LOGOUT_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case LOGOUT_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "Logout";
        draft.errorMessage.message = action.payload;
      });
    case GET_PROFILE_START:
      return produce(state, draft => {
        draft.loading.type = "GetProfile";
        draft.loading.state = true;
      });
    case GET_PROFILE_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        action.payload.hasOwnProperty("userInfo") && (draft.userInfo = action.payload.userInfo);
      });
    case GET_PROFILE_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case UPDATE_PROFILE_START:
      return produce(state, draft => {
        draft.loading.type = "UpdateProfile";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case UPDATE_PROFILE_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.userInfo = action.payload.userInfo;
      });
    case UPDATE_PROFILE_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "UpdateProfile";
        draft.errorMessage.message = action.payload;
      });
    case CHANGE_PASSWORD_START:
      return produce(state, draft => {
        draft.loading.type = "ChangePassword";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case CHANGE_PASSWORD_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.xTOKEN = action.payload.newXToken;
      });
    case CHANGE_PASSWORD_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "ChangePassword";
        draft.errorMessage.message = action.payload;
      });
    default:
      return state;
  }
};

export default userReducer;