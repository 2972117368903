import { SET_MESSAGES_TABLE_PAGINATION, SET_MESSAGES_CURRENT_TAB } from "../../constants";

export const setMessagesTablePagination = payload => ({
  type: SET_MESSAGES_TABLE_PAGINATION,
  payload
});

export const setMessagesCurrentTab = payload => ({
  type: SET_MESSAGES_CURRENT_TAB,
  payload
});