import { Table } from 'antd';
import React, { Component } from "react";
import { connect } from "react-redux";
import { formatNumber } from '../../utils/formatNumber';


let columns = [
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Project Name",
    dataIndex: "business_name",
    key: "business_name",
  },
  {
    title: "Market",
    dataIndex: "market",
    key: "market",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
];

class InvestmentTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    }
  }

  render() {
    const { transactions, transactionsCount } = this.props;

    const transactionsList = transactions?.map((transaction) => ({
      id: transaction.id,
      action: transaction.action,
      amount: formatNumber(transaction.amount) + " " + transaction.business.currency,
      business_name: transaction.business.name,
      market: transaction.market,
      date: transaction.created,
      price: transaction.market === "INTERNAL" ? formatNumber(transaction.price) + " " + transaction.business.currency : '',
      quantity: formatNumber(transaction.quantity) + " shares",
    }));

    const { currentPage } = this.state;

    return (
      <div>
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={transactionsList}
          className={"c-table c-table-bordered"}
          pagination={{
            hideOnSinglePage: true,
            current: currentPage,
            total: transactionsCount,
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  transactions: state.reports.transactions,
  transactionsCount: state.reports.transactionsCount,
});

export default connect(mapStateToProps)(InvestmentTab);
