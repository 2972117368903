import {
  SEND_RECOVERY_LINK_START, SEND_RECOVERY_LINK_SUCCESS, SEND_RECOVERY_LINK_FAIL,
  RECOVER_PASSWORD_START, RECOVER_PASSWORD_SUCCESS, RECOVER_PASSWORD_FAIL
} from "../../constants";

export const sendRecoveryLinkStart = () => ({
  type: SEND_RECOVERY_LINK_START
});

export const sendRecoveryLinkSuccess = payload => ({
  type: SEND_RECOVERY_LINK_SUCCESS,
  payload
});

export const sendRecoveryLinkFail = payload => ({
  type: SEND_RECOVERY_LINK_FAIL,
  payload
});

export const recoverPasswordStart = () => ({
  type: RECOVER_PASSWORD_START
});

export const recoverPasswordSuccess = payload => ({
  type: RECOVER_PASSWORD_SUCCESS,
  payload
});

export const recoverPasswordFail = payload => ({
  type: RECOVER_PASSWORD_FAIL,
  payload
});