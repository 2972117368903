import React, {Component} from "react";
import {connect} from "react-redux";
import {getStates, getCities} from "../../redux/dictionaries/thunks/dictionariesThunks";
import {setOnboardingData, getOnboarding} from "../../redux/registration/thunks/registrationThunks";
import {filterOption} from "../../utils/filterOption";

import "./style.scss";

import moment from "moment";
import {Form, Input, Select, DatePicker, message, Radio, AutoComplete} from "antd";
import OnboardingHeader from "./OnboardingHeader";
import OnboardingFooter from "./OnboardingFooter";
import {findDictionaryByID} from "../../utils/findDictionaryByID";
// import Button from "../../components/Button/Button";
// import Icon from "../../components/Icon/Icon";

const { Option } = Select;

class OnboardingStep1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusType: 1,
      zip: "",
      birthCountry: "",
      birthDate: "",
      streetAddress: "",
      city: "",
      stateProvince: "",
      employment: "",
      // occupation: "",
      businessName: "",
      businessType: "",
      businessPhoneNumber: "",
      businessAddress: "",
      localDependentsList: [],
    }
  }

  handleOnChange = (value, type) => {
    this.setState({
      [type]: value
    })
  };

  handleCountryOnSelect = (countryID) => {
    const { getStates, form } = this.props;
    getStates(countryID);

    form.setFieldsValue({
      state: "",
      city: "",
    });
  };

  handleStateOnChange = () => {
    const { form } = this.props;

    if (form.getFieldValue('city')) {
      form.setFieldsValue({
        city: ""
      });
    }
  };

  setOnboardingInfo = (type, callback) => { // type can be prev, next or save
    if (type !== 'prev') {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          let myBirthday = null;

          if (values.birthday) {
            values.birthday = values.birthday.format("YYYY-MM-DD");
            // we are setting values.birthday with a tmp variable, because dependents has a birthday_{index} property and it makes confusing at a code
            myBirthday = values.birthday;
            delete values.birthday;
          }

          values.spouse_name = values.spouse_name ? values.spouse_name : '';

          if (!values.spouse_country) {
            delete values.spouse_country;
          }

          if (!values.spouse_citizenship) {
            delete values.spouse_citizenship;
          }

          /*let dependentsNameCounter = 0;
          let dependentsRelationshipCounter = 0;
          let dependentsBirthdaysCounter = 0;

          if (values.dependents === 1) {
            // here is why we have used myBirthday tmp variable
            Object.entries(values).map((element, index) => {
              const fieldName = element[0].split("_")[0]; // [0] returns object key, [1] returns object value
              switch (fieldName) {
                case "name":
                  values[`dependents[${dependentsNameCounter++}][name]`] = element[1];
                  break;
                case "relationship":
                  values[`dependents[${dependentsRelationshipCounter++}][relationship]`] = element[1];
                  break;
                case "birthday":
                  values[`dependents[${dependentsBirthdaysCounter++}][birthday]`] = element[1].format("YYYY-MM-DD");
                  break;
                default:
              }
            });
          }
          else {
            values.dependents = [];
          }

          if (parseInt(values.provide_dependents_info) === 0) {
            values.dependents = [];
          }*/

          // we are setting values.birthday with a tmp variable, because dependents has a birthday_{index} property and it makes confusing at a code
          values.birthday = myBirthday;

          callback(values);
        }
      });
    }
    else {
      const values = this.props.form.getFieldValue();
      let myBirthday = null;

      if (values.birthday) {
        values.birthday = values.birthday.format("YYYY-MM-DD");
        // we are setting values.birthday with a tmp variable, because dependents has a birthday_{index} property and it makes confusing at a code
        myBirthday = values.birthday;
        delete values.birthday;
      }

      values.spouse_name = values.spouse_name ? values.spouse_name : '';

      if (!values.spouse_country) {
        delete values.spouse_country;
      }

      if (!values.spouse_citizenship) {
        delete values.spouse_citizenship;
      }

      /*let dependentsNameCounter = 0;
      let dependentsRelationshipCounter = 0;
      let dependentsBirthdaysCounter = 0;

      if (values.dependents === 1) {
        // here is why we have used myBirthday tmp variable
        Object.entries(values).map((element, index) => {
          const fieldName = element[0].split("_")[0]; // [0] returns object key, [1] returns object value
          switch (fieldName) {
            case "name":
              values[`dependents[${dependentsNameCounter++}][name]`] = element[1];
              break;
            case "relationship":
              values[`dependents[${dependentsRelationshipCounter++}][relationship]`] = element[1];
              break;
            case "birthday":
              values[`dependents[${dependentsBirthdaysCounter++}][birthday]`] = element[1].format("YYYY-MM-DD");
              break;
            default:
          }
        });
      }
      else {
        values.dependents = [];
      }

      if (parseInt(values.provide_dependents_info) === 0) {
        values.dependents = [];
      }*/

      // we are setting values.birthday with a tmp variable, because dependents has a birthday_{index} property and it makes confusing at a code
      values.birthday = myBirthday;

      callback(values);
    }
  };

  onSubmit = event => {
    event.preventDefault();
    const { setOnboardingData } = this.props;

    this.setOnboardingInfo('save',(mutatedValues) => {
      setOnboardingData(mutatedValues);
    });
  };

  remove = index => {
    const oldLocalDependentsList = this.state.localDependentsList;
    oldLocalDependentsList.splice(index, 1);

    this.setState({
      localDependentsList: oldLocalDependentsList
    })
  };

  add = () => {
    let { localDependentsList } = this.state;
    const oldLocalDependentsList = localDependentsList ? localDependentsList : [];
    oldLocalDependentsList.push({
      name: "",
      relationship: "",
      birthday: moment()
    });

    this.setState({
      localDependentsList: oldLocalDependentsList
    })
  };

  getInitialValues = () => {
    const {onboardingData} = this.props;
    let returningInitialValues = {
      zip: "",
      country: "",
      birth_country: "",
      birthday: "",
      street_address: "",
      state: "",
      city: "",
      employment: "",
      // occupation: "",
      business_name: "",
      // business_type: "",
      // business_phone: "",
      // business_address: "",
      green_card: "",
      married: "",
      spouse_name: "",
      spouse_country: "",
      spouse_citizenship: "",
      dependents: "",
      provide_dependents_info: "",
      dependentsList: [],
    };

    if (onboardingData && onboardingData.length !== 0) {
      /*returningInitialValues = {
        zip: onboardingData.zip,
        birth_country: onboardingData.birth_country,
        birthday: onboardingData.birthday ? moment(onboardingData.birthday) : "",
        street_address: onboardingData.street_address,
        state: onboardingData.state,
        city: onboardingData.city,
        employment: onboardingData.employment,
        // occupation: onboardingData.occupation,
        business_name: onboardingData.business_name,
        // business_type: onboardingData.business_type,
        // business_phone: onboardingData.business_phone,
        // business_address: onboardingData.business_address,
        married: onboardingData.married,
        spouse_name: onboardingData.spouse_name,
        spouse_country: onboardingData.spouse_country,
        spouse_citizenship: onboardingData.spouse_citizenship,
      };*/

      returningInitialValues.zip = onboardingData.zip;
      returningInitialValues.country = onboardingData.country;
      returningInitialValues.birth_country = onboardingData.birth_country;
      returningInitialValues.birthday = onboardingData.birthday ? moment(onboardingData.birthday) : "";
      returningInitialValues.street_address = onboardingData.street_address;
      returningInitialValues.state = onboardingData.state;
      returningInitialValues.city = onboardingData.city;
      returningInitialValues.employment = onboardingData.employment;
      // returningInitialValues.occupation = onboardingData.occupation;
      returningInitialValues.business_name = onboardingData.business_name;
      // returningInitialValues.business_type = onboardingData.business_type;
      // returningInitialValues.business_phone = onboardingData.business_phone;
      // returningInitialValues.business_address = onboardingData.business_address;
      returningInitialValues.green_card = onboardingData.green_card;
      returningInitialValues.married = onboardingData.married;
      returningInitialValues.spouse_name = onboardingData.spouse_name;
      returningInitialValues.spouse_country = onboardingData.spouse_country;
      returningInitialValues.spouse_citizenship = onboardingData.spouse_citizenship;

      if (onboardingData.dependents.length !== 0) {
        returningInitialValues.dependents = 1;
        returningInitialValues.provide_dependents_info = 1;
        onboardingData.dependents.map((element, index) => {
          return returningInitialValues.dependentsList.push({
            [`name_${index}`]: element.name,
            [`relationship_${index}`]: element.relationship,
            [`birthday_${index}`]: moment(element.birthday)
          });
        });
      }
      else {
        returningInitialValues.dependents = 0;
        returningInitialValues.provide_dependents_info = 0;
      }
    }

    return returningInitialValues;
  };

  disabledDate = current => {
    if (current) {
      if (current >= moment().add(-18, "years").endOf("year")) {
        return true;
      }
    }
    return false;
  };

  getCityAutoComplete = () => {
    const { cities } = this.props;
    const array = [];
    cities && cities.map(item => array.push(item.name));
    return array;
  };

  getStateAutoComplete = () => {
    const { states } = this.props;
    const array = [];
    states && states.map(item => array.push(item.name));
    return array;
  };

  componentDidUpdate(prevProps, prevState) {
    const {loading, errorMessage, getStates, onboardingData, userInfo, states} = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    // const errorType = errorMessage.type;
    const errorText = errorMessage.message;

    if (!errorText && !loadingState && prevProps.loading.state) {
      switch (loadingType) {
        case "GetOnboarding":
          const { state } = this.getInitialValues();
          if (state) {
            // getCities(state);
          }
          break;
        case "SetOnboardingData":
          message.success("Saved");
          break;
        default: break;
      }
    }

    if (userInfo && !states && userInfo.country) {
      getStates(userInfo.country);
    }

    if (prevProps.onboardingData !== onboardingData) { // onboarding data is filled
      this.setState({
        localDependentsList: onboardingData.dependents
      });
    }
  }

  componentDidMount() {
    window.scroll(0, 0);
    const { getOnboarding } = this.props;
    getOnboarding();
  }

  render() {
    let { form, countries, opportunityGroups } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    // const { localDependentsList } = this.state;
    countries = countries ? countries : [];
    //states = statesCore ? statesCore : [];
    //cities = citiesCore ? citiesCore : [];
    const employmentStatusOptions = findDictionaryByID(29, opportunityGroups);
    const {
      zip,
      country,
      birth_country,
      birthday,
      street_address,
      state,
      city,
      employment,
      business_name,
      green_card,
      married,
      spouse_name,
      spouse_country,
      spouse_citizenship,
    } = this.getInitialValues();
    // const dataSource = this.getCityAutoComplete();
    const statesDataSource = this.getStateAutoComplete();

    // const formItems = localDependentsList && localDependentsList.map((element, index) => (
    //   <div key={index} className="column small-12 medium-6 dynamic-field">
    //     {
    //       localDependentsList.length > 1 &&
    //         <Button
    //           title={<Icon type="minus" size={16} />}
    //           size="md"
    //           theme="lightblue"
    //           className="radius-10 remove_btn"
    //           onClick={() => this.remove(index)}
    //         />
    //     }
    //     <div className="form-item">
    //       <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
    //         Name
    //       </div>
    //       <Form.Item className="mb_0">
    //         {getFieldDecorator(`name_${index}`, {
    //           initialValue: localDependentsList[index].name,
    //           rules: [{required: false, message: "Please fill out this field"}]
    //         })(
    //           <Input className="small-12 medium-10" />
    //         )}
    //       </Form.Item>
    //     </div>
    //     <div className="form-item">
    //       <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
    //         Relationship
    //       </div>
    //       <Form.Item>
    //         {getFieldDecorator(`relationship_${index}`, {
    //           initialValue: localDependentsList[index].relationship,
    //           rules: [{required: false, message: "Please input passenger's name or delete this field."}]
    //         })(
    //           <Input className="small-12 medium-10" />
    //         )}
    //       </Form.Item>
    //     </div>
    //     <div className="form-item">
    //       <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
    //         Date of Birth
    //       </div>
    //       <Form.Item>
    //         {getFieldDecorator(`birthday_${index}`, {
    //           initialValue: moment(localDependentsList[index].birthday),
    //           rules: [{required: false, message: "Please input passenger's name or delete this field."}]
    //         })(
    //           <DatePicker className="small-12 medium-10" />
    //         )}
    //       </Form.Item>
    //     </div>
    //   </div>
    // ));

    return (
      <OnboardingHeader step={1} >
        <div className="row">
          <div className="column small-12 x-large-10">
            <Form onSubmit={this.onSubmit} >
              <div className="form_card bg-white radius-10 shadow-layout mb_45">
                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Country of Legal Residence *
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("country", {
                      initialValue: country,
                      rules: [{required: true, message: "Please fill out this field"}]
                    })(
                      <Select
                        showSearch
                        filterOption={filterOption}
                        className="fluid-x"
                        onSelect={this.handleCountryOnSelect}
                      >
                        {
                          countries.map((element, index) => (
                            <Option key={index} value={element.id}>{element.name}</Option>
                          ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Province / State *
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("state", {
                      initialValue: state,
                      rules: [{required: true, message: "Please fill out this field"}],
                    })(
                      <AutoComplete
                        onChange={this.handleStateOnChange}
                        dataSource={statesDataSource}
                        filterOption={(inputValue, option) =>
                          isNaN(inputValue) ? option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : null
                        }
                      />
                    )}
                  </Form.Item>
                </div>

                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    City *
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("city", {
                      initialValue: city,
                      rules: [{required: true, message: "Please fill out this field"}],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </div>

                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Street Address *
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("street_address", {
                      initialValue: street_address,
                      rules: [{required: true, message: "Please fill out this field"}],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </div>

                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Postal Code / Zip *
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("zip", {
                      initialValue: zip,
                      rules: [{required: true, message: "Please fill out this field"}]
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </div>

                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Country of birth *
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("birth_country", {
                      initialValue: birth_country,
                      rules: [{required: true, message: "Please fill out this field"}]
                    })(
                      <Select
                        showSearch
                        filterOption={filterOption}
                        className="fluid-x"
                      >
                        {
                          countries.map((element, index) => (
                            <Option key={index} value={element.id}>{element.name}</Option>
                          ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Are you a US citizen or Green Card holder? *
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("green_card", {
                      initialValue: green_card,
                      rules: [{required: true, message: "Please fill out this field"}],
                    })(
                      <Radio.Group>
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>

                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Date of Birth*
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("birthday", {
                      initialValue: birthday,
                      rules: [
                        {required: true, message: "Please fill out this field"},
                        {
                          validator: async (rule, value) => {
                            if (value.isAfter(moment().subtract(18, 'years'), 'year')) {
                              return Promise.reject('To register you must be at least 18 years old');
                            }
                          },
                        },
                      ],
                    })(
                      <DatePicker
                        defaultPickerValue={moment().subtract(18, 'years')}
                        placeholder="YYYY-MM-DD"
                        // disabledDate={this.disabledDate}
                        showToday={false}
                      />
                    )}
                  </Form.Item>
                </div>

                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    What is your employment status? *
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("employment", {
                      initialValue: employment,
                      rules: [{required: true, message: "Please fill out this field"}],
                    })(
                      <Select className="fluid-x">
                        {
                          employmentStatusOptions.map((element, index) => (
                            <Option key={index} value={element.id}>{element.name}</Option>
                          ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </div>

                {/*<div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Occupation *
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("occupation", {
                      initialValue: occupation,
                      rules: [{required: true, message: "Please fill out this field"}],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </div>*/}

                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Name of Business/Employer
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("business_name", {
                      initialValue: business_name,
                      rules: [{required: false, message: "Please fill out this field"}],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </div>

                {/*<div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Type of business *
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("business_type", {
                      initialValue: business_type,
                      rules: [{required: true, message: "Please fill out this field"}],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </div>*/}

                {/*<div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Business phone number
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("business_phone", {
                      initialValue: business_phone,
                      rules: [{required: false, message: "Please fill out this field"}],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </div>*/}

                {/*<div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Business address
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("business_address", {
                      initialValue: business_address,
                      rules: [{required: false, message: "Please fill out this field"}],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </div>*/}

                {/*From non onboarded registration*/}

                {/* from Step2 */}
                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
                    Are you married? *
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("married", {
                      initialValue: married,
                      rules: [{required: true, message: "Please fill out this field"}]
                    })(
                      <Radio.Group name="married">
                        <Radio value={1}>Married</Radio>
                        <Radio value={0}>Not Married</Radio>
                        <Radio value={2}>Prefer not to answer</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>

                {
                  getFieldValue("married") === 1 &&
                  <div>
                    <div className="form-item">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        Spouse's Name
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("spouse_name", {
                          initialValue: spouse_name,
                          rules: [{required: false, message: "Please fill out this field"}],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                      <div className="form-item-hint text text-12 font-medium font-spacing-02 color-grey-800">Optional</div>
                    </div>
                    <div className="form-item">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        Spouse's Country of Birth
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("spouse_country", {
                          initialValue: spouse_country,
                          rules: [{required: false, message: "Please fill out this field"}],
                        })(
                          <Select
                            showSearch
                            filterOption={filterOption}
                            className="fluid-x"
                          >
                            {
                              countries.map((element, index) => (
                                <Option key={index} value={element.id}>{element.name}</Option>
                              ))
                            }
                          </Select>
                        )}
                      </Form.Item>
                      <div className="form-item-hint text text-12 font-medium font-spacing-02 color-grey-800">Optional</div>
                    </div>
                    <div className="form-item">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        Spouse's Citizenship
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("spouse_citizenship", {
                          initialValue: spouse_citizenship,
                          rules: [{required: false, message: "Please fill out this field"}],
                        })(
                          <Select
                            showSearch
                            filterOption={filterOption}
                            className="fluid-x"
                          >
                            {
                              countries.map((element, index) => (
                                <Option key={index} value={element.id}>{element.name}</Option>
                              ))
                            }
                          </Select>
                        )}
                      </Form.Item>
                      <div className="form-item-hint text text-12 font-medium font-spacing-02 color-grey-800">Optional</div>
                    </div>
                  </div>
                }

                {/*<div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
                    Do you have any dependents? *
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("dependents", {
                      initialValue: dependents,
                      rules: [{required: true, message: "Please fill out this field"}]
                    })(
                      <Radio.Group>
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>

                {
                  getFieldValue("dependents") === 1 &&
                  <div className="form_toggle_field">
                    <div className="form-item">
                      <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
                        Do you want to provide information about your dependents? *
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("provide_dependents_info", {
                          initialValue: provide_dependents_info,
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <Radio.Group name="provideDependentsInfo">
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </div>

                    {
                      getFieldValue("provide_dependents_info") === 1 &&
                      <>
                        <div className="row">
                          {formItems}
                        </div>
                        <Form.Item>
                          <Button
                            title="Add Dependent"
                            prefix={<Icon type="plus" size={12} style={{marginRight: 15}} />}
                            size="xl"
                            theme="default"
                            onClick={this.add}
                            className="btn-bordered radius-8"
                            type="button"
                          />
                        </Form.Item>
                      </>
                    }
                  </div>
                }*/}
              </div>
              <OnboardingFooter
                step={1}
                setOnboardingInfo={this.setOnboardingInfo}
              />
            </Form>
          </div>
        </div>
      </OnboardingHeader>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.registration.loading,
  errorMessage: state.registration.errorMessage,
  userInfo: state.user.userInfo,
  countries: state.dictionaries.countries,
  states: state.dictionaries.states,
  cities: state.dictionaries.cities,
  opportunityGroups: state.dictionaries.opportunityGroups,
  onboardingData: state.registration.onboardingData
});

const mapDispatchToProps = dispatch => ({
  getStates: countryID => dispatch(getStates(countryID)),
  getCities: stateID => dispatch(getCities(stateID)),
  setOnboardingData: params => dispatch(setOnboardingData(params)),
  getOnboarding: () => dispatch(getOnboarding())
});

const OnboardingScreenStep1 = Form.create({ name: "OnboardingStep1" })(OnboardingStep1);

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingScreenStep1);
