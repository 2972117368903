
import React, { Component } from "react";
import { connect } from "react-redux";
import { DatePicker, Form } from "antd";

import Button from "../../../../components/Button/Button";
import Icon from "../../../../components/Icon/Icon";
import { handleLoading } from "../../../../utils/handleLoading";
import NumericInput from "../../../../components/NumericInput/NumericInput";
import moment from "moment";
import { convertToUTC } from "../../../../utils/convertToUTC";

class InternalOffer extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  disabledDates = (current) =>{
    let customDate = moment().format("YYYY-MM-DD");
    return current && current < moment(customDate, "YYYY-MM-DD");
  }
  
  onSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      const start = convertToUTC(values.start)
      const end = convertToUTC(values.end)
      if (!err) this.props.submitInternalPublish({ ...values, start, end });
    });
  };
  shouldComponentUpdate(nextProps) {
    if (this.props.modalVisible || (this.props.offeredSuccessQty !== nextProps.offeredSuccessQty)) return true;
    else return false;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let { loading, offeredSuccessQty, sharesQty } = this.props;
    return (
      <>
        <div className="popup_close color-grey-700" onClick={() => this.props.changePopUpView('internalModalVisible')}>
          <Icon type="cancel" />
        </div>
        <Form onSubmit={this.onSubmit}>
          <div className="flex-container align-center text-center">
            <div className="text text-28 text-height-33 font-heavy font-spacing-02 color-grey font-uppercase large-7 mb_35">
              Publish for Co-Shareholders
            </div>
          </div>
          <div>
            {/* <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Bid Increments
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("step", {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <NumericInput className="fluid-x" />
                )}
              </Form.Item>
            </div> */}
            {/* <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Operating Company (%)
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator('operating_company_percent', {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <NumericInput className="fluid-x" />
                )}
              </Form.Item>
            </div>
            <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Carried Interest
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("carried_interest", {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <NumericInput className="fluid-x" />
                )}
              </Form.Item>
            </div> */}
            {/* <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                PE-GATE Fees
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("pegate_fees", {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <NumericInput className="fluid-x" />
                )}
              </Form.Item>
            </div>
            <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Legal Fees
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("legal_fees", {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <NumericInput className="fluid-x" />
                )}
              </Form.Item>
            </div> */}
            {/* <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                PE-GATE General Partner
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("pegate_general_partner", {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <Input className="fluid-x" />
                )}
              </Form.Item>
            </div> */}
            {/* <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                PE-GATE Involvement
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("pegate_involvement", {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <Input className="fluid-x" />
                )}
              </Form.Item>
            </div> */}
            {/* <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Portfolio Company
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator('portfolio_company', {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <Input className="fluid-x" />
                )}
              </Form.Item>
            </div> */}
            {/* <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                CEO Manager
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator('ceo_manager', {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <Input className="fluid-x" />
                )}
              </Form.Item>
            </div> */}
            {/* <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Non PE-GATE Ownership
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator('non_pegate_ownership', {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <Input className="fluid-x" />
                )}
              </Form.Item>
            </div> */}
            {/* <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Deposit
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator('deposit', {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <NumericInput className="fluid-x" />
                )}
              </Form.Item>
            </div> */}
            <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Minimum Investor Bid
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator('min', {
                  initialValue: offeredSuccessQty,
                  rules: [
                    { required: true, message: "Please fill out this field" },
                    {
                      validator: async (rule, value) => {
                        if (parseFloat(value) > sharesQty) {
                          return Promise.reject('Minimum Investor Bid can\'t be greater than number of Shares to sell');
                        }
                      },
                    },
                  ]
                })(
                  <NumericInput className="fluid-x" />
                )}
              </Form.Item>
            </div>

            <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Start Date
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("start", {
                  initialValue: null,
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <DatePicker dropdownClassName='publish-picker' showTime onChange={this.calcEndTime} disabledDate={this.disabledDates} />
                )}
              </Form.Item>
            </div>
            <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                End Date
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("end", {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <DatePicker dropdownClassName='publish-picker' showTime disabledDate={this.disabledDates} />
                )}
              </Form.Item>
            </div>
          </div>
          <div className="btn_group medium-flex-container align-middle align-right">
            <Form.Item className="mb_0">
              <Button
                title="Cancel"
                size="lg"
                theme="default"
                className="btn-link radius-8 font-uppercase"
                type="button"
                onClick={() => this.props.changePopUpView('internalModalVisible')}
              />
            </Form.Item>
            <Form.Item className="mb_0">
              <Button
                title="Save"
                type="submit"
                size="xl"
                theme="blue"
                prefix={<Icon type="checked" size={18} style={{ marginRight: 11 }} />}
                className="radius-8"
                style={{ fontSize: 18 }}
                loading={handleLoading(loading, "InternalPublish")}
              />
            </Form.Item>
          </div>
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.offers.loading,
  errorMessage: state.business.errorMessage,
});

const InternalOfferScreen = Form.create({ name: "offerPublish" })(InternalOffer);

export default connect(mapStateToProps)(InternalOfferScreen);

