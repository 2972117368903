import {
  updateProfileStart, updateProfileSuccess, updateProfileFail
} from "../actions/updateProfileActions";
import CommonUtils from "../../../utils/CommonUtils";

export const updateProfile = params => ((dispatch, getState) => {
  dispatch(updateProfileStart());

  const {xID, xTOKEN, role} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  const userType = role.toLowerCase();

  CommonUtils.apiCall("post", "private", `${userType}/updateProfile`, params, config)
    .then(updateProfileResponse => {
      if (updateProfileResponse.data.success) {
        CommonUtils.apiCall("get", "private", `${userType}/getProfile`, {}, config)
          .then(getProfileResponse => {
            if (getProfileResponse.data.success) {
              dispatch(updateProfileSuccess({
                userInfo: getProfileResponse.data.success
              }));
            }
            else {
              dispatch(updateProfileFail("Error"));
            }
          })
          .catch(() => {
            dispatch(updateProfileFail("Error"));
          })
      }
      else {
        dispatch(updateProfileFail("Error"));
      }
    })
    .catch(() => {
      dispatch(updateProfileFail("Error"));
    })
});
