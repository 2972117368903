import React, {Component} from "react";
import {connect} from "react-redux";
import {
  AutoComplete,
  Checkbox,
  Form,
  Input,
  Radio,
  Row,
  Select,
} from 'antd';

import history from "../../history";
import { filterOption } from "../../utils/filterOption";
import { findDictionaryByID } from "../../utils/findDictionaryByID";
import { sortOptionsAlphabetically } from "../../utils/sortOptionsAlphabetically";
import { fillUserRegistrationInfo, resetUserRegistrationInfo } from "../../redux/registration/actions/registrationActions";
import { getCities, getDictionaries, getStates } from "../../redux/dictionaries/thunks/dictionariesThunks";

import RegistrationSteps from "../../layouts/RegistrationSteps/RegistrationSteps";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";

const { Option } = Select;

class Entity1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentEntityType: null
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const {fillUserRegistrationInfo, opportunityGroups} = this.props;
        const industriesOptions = sortOptionsAlphabetically(findDictionaryByID(5, opportunityGroups), true);
        const investmentSizesOptions = sortOptionsAlphabetically(findDictionaryByID(36, opportunityGroups));

        if (values.statusType) {
          delete values.statusType;
        }

        let lastSelectedListOptionId = 0;
        if (values.investor_sectors) {
          values.investor_sectors.map((element, index) => {
            const selectedCurrentOption = industriesOptions.find(item => parseInt(item.id) === parseInt(element));

            values[`options[${index}][id]`] = selectedCurrentOption.id;
            values[`options[${index}][value]`] = 0;
            lastSelectedListOptionId = index;

            return "";
          });
        }
        if (values.investor_sizes) {
          values.investor_sizes.map((element, index) => {
            const selectedCurrentOption = investmentSizesOptions.find(item => parseInt(item.id) === parseInt(element));

            values[`options[${lastSelectedListOptionId + index + 1}][id]`] = selectedCurrentOption.id;
            values[`options[${lastSelectedListOptionId + index + 1}][value]`] = 0;

            return "";
          });
        }

        fillUserRegistrationInfo(values);
        history.push("/entity-personal-information");
      }
    })
  };

  handleCountryOnChange = (value) => {
    const {getStates} = this.props;
    getStates(value);
    this.props.form.setFieldsValue({
      entity_state: "",
      entity_city: ""
    })
  };

  handleStateOnChange = (value) => {
    const {getCities} = this.props;
    getCities(value);
    this.props.form.setFieldsValue({
      entity_city: ""
    })
  };

  userStatusTypeOnChange = () => {
    const {userInfo, resetUserRegistrationInfo} = this.props;

    if (Object.keys(userInfo).length) {
      resetUserRegistrationInfo();
    }
    history.push("/personal-information");
  };

  getFormInitialValues = () => {
    const {userInfo} = this.props;
    return {
      entity_name: userInfo && userInfo.entity_name ? userInfo.entity_name : null,
      entity_address: userInfo && userInfo.entity_address ? userInfo.entity_address : null,
      entity_country: userInfo && userInfo.entity_country ? userInfo.entity_country : null,
      entity_state: userInfo && userInfo.entity_state ? userInfo.entity_state : null,
      entity_city: userInfo && userInfo.entity_city ? userInfo.entity_city : null,
      entity_zip: userInfo && userInfo.entity_zip ? userInfo.entity_zip : null,
      entity_id: userInfo && userInfo.entity_id ? userInfo.entity_id : null,
      entity_type: userInfo && userInfo.entity_type ? userInfo.entity_type : null,
      investor_sectors: userInfo && userInfo.investor_sectors ? userInfo.investor_sectors : null,
      investor_sizes: userInfo && userInfo.investor_sizes ? userInfo.investor_sizes : null
    };
  };

  entityTypeRadioOnChange = (event) => {
    const {value} = event.target;

    this.setState({
      currentEntityType: value
    })
  };

  getCityAutoComplete = () => {
    const { cities } = this.props;
    const array = [];
    cities && cities.map(item => array.push(item.name));
    return array;
  };

  componentDidMount() {
    window.scroll(0, 0);
    const {loadDictionaries, opportunityGroups, countries} = this.props;

    if (!opportunityGroups && !countries) {
      loadDictionaries();
    }
  }

  render() {
    const { opportunityGroups } = this.props;
    const { getFieldDecorator } = this.props.form;
    const {path} = this.props.match;
    const countries = this.props.countries ? this.props.countries : [];
    const states = this.props.states ? this.props.states : [];
    const entityTypesOptions = findDictionaryByID(35, opportunityGroups);
    const initialValues = this.getFormInitialValues();
    const dataSource = this.getCityAutoComplete();
    const industriesOptions = sortOptionsAlphabetically(findDictionaryByID(5, opportunityGroups), true);
    const investmentSizesOptions = sortOptionsAlphabetically(findDictionaryByID(36, opportunityGroups));

    return (
      <>
        <RegistrationSteps regEntity path={path} />
        <div className="row">
          <div className="column small-12 x-large-10">
          <Form onSubmit={this.onSubmit} className="sign_out_form_card bg-white radius-10 shadow-layout" >
            <div className="mb_45">
              <div className="form-item mb_40">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  SELECT YOUR STATUS
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator('statusType', {
                    initialValue: 2,
                    rules: [{required: false, message: 'Please fill out this field'}],
                  })(
                    <Radio.Group onChange={this.userStatusTypeOnChange}>
                      <div className="row">
                        <div className="column small-12 large-6">
                          <div className="radio-template">
                            <Radio value={1} id="1">Individual</Radio>
                            <Icon type="user" className="color-grey-800 pined-icon"/>
                          </div>
                        </div>
                        <div className="column small-12 large-6">
                          <div className="radio-template">
                            <Radio value={2} id="2">Fund / Entity</Radio>
                            <Icon type="portfolio" className="color-grey-800 pined-icon"/>
                          </div>
                        </div>
                      </div>
                    </Radio.Group>
                  )}
                </Form.Item>
              </div>
  
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  FUND / ENTITY NAME
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator('entity_name', {
                    initialValue: initialValues.entity_name,
                    rules: [{required: true, message: 'Please fill out this field'}],
                  })(
                    <Input />
                  )}
                </Form.Item>
              </div>
  
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  COUNTRY
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator('entity_country', {
                    initialValue: initialValues.entity_country,
                    rules: [{required: true, message: 'Please fill out this field'}],
                  })(
                    <Select
                      showSearch
                      filterOption={filterOption}
                      className="fluid-x"
                      onChange={this.handleCountryOnChange}
                    >
                      { countries.map((element, index) => <Option key={index} value={element.id} >{element.name}</Option>) }
                    </Select>
                  )}
                </Form.Item>
              </div>
  
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  PROVINCE / STATE
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator('entity_state', {
                    initialValue: initialValues.entity_state,
                    rules: [{required: true, message: 'Please fill out this field'}],
                  })(
                    <Select
                      showSearch
                      filterOption={filterOption}
                      className="fluid-x"
                      onChange={this.handleStateOnChange}
                    >
                      { states.map((element, index) => <Option key={index} value={element.id}>{element.name}</Option>) }
                    </Select>
                  )}
                </Form.Item>
              </div>
  
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  CITY
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator('entity_city', {
                    initialValue: initialValues.entity_city,
                    rules: [{required: true, message: 'Please fill out this field'}],
                  })(
                    <AutoComplete
                      dataSource={dataSource}
                      filterOption={(inputValue, option) =>
                        isNaN(inputValue) ? option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : null
                      }
                    />
                  )}
                </Form.Item>
              </div>
  
              <div className="form-item" >
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  Sector Preferences: Please indicate which of the following industry sectors is of interest to you
                  when making private equity investments (check all that apply): *
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator("investor_sectors", {
                    initialValue: initialValues.investor_sectors,
                    rules: [{required: true, message: "Please fill out this field"}]
                  })(
                    <Checkbox.Group className="columns-2" >
                      <Row className="flex-container flex-dir-column" >
                        {industriesOptions.slice(0, industriesOptions.length / 2 + 1).map((item, index) => (
                          <Checkbox key={index} value={item.id}> {item.name} </Checkbox>
                        ))}
                      </Row>
                      <Row className="flex-container flex-dir-column" >
                        {industriesOptions.slice(industriesOptions.length / 2 + 1, industriesOptions.length).map((item, index) => (
                          <Checkbox key={index} value={item.id}> {item.name} </Checkbox>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  )}
                </Form.Item>
              </div>
  
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  What size investments are you interested in (check all that apply): *
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator("investor_sizes", {
                    initialValue: initialValues.investor_sizes,
                    rules: [{required: true, message: "Please fill out this field"}]
                  })(
                    <Checkbox.Group className="columns-2" >
                      <Row className="flex-container flex-dir-column" >
                        {investmentSizesOptions.slice(0, investmentSizesOptions.length / 2 + 1).map((item, index) => (
                          <Checkbox key={index} value={item.id}> {item.name} </Checkbox>
                        ))}
                      </Row>
                      <Row className="flex-container flex-dir-column" >
                        {investmentSizesOptions.slice(investmentSizesOptions.length / 2 + 1, investmentSizesOptions.length).map((item, index) => (
                          <Checkbox key={index} value={item.id}> {item.name} </Checkbox>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  )}
                </Form.Item>
              </div>
  
              <div className="form-item-entity mb_40">
                <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
                  IS THE FUND / ENTITY A
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator('entity_type', {
                    initialValue: initialValues.entity_type,
                    rules: [{required: true, message: 'Please fill out this field'}],
                  })(
                    <Radio.Group>
                      {
                        entityTypesOptions.map(element => (
                          <Radio
                            key={element.id}
                            value={element.id}
                            onChange={this.entityTypeRadioOnChange}
                          >
                            {element.name}
                          </Radio>
                        ))
                      }
                    </Radio.Group>
                  )}
                </Form.Item>
              </div>
            </div>
            <div className="text-center form_submit">
              <Form.Item className="mb_0">
                <Button
                  title="Continue"
                  size="xl"
                  theme="blue"
                  className="btn-shadow radius-8"
                  type="submit"
                />
              </Form.Item>
            </div>
          </Form>
          </div>
        </div>
      </>
    );
  }
}

const Entity1Screen = Form.create({ name: 'Entity1' })(Entity1);

const mapStateToProps = state => ({
  opportunityGroups: state.dictionaries.opportunityGroups,
  countries: state.dictionaries.countries,
  states: state.dictionaries.states,
  cities: state.dictionaries.cities,
  userInfo: state.registration.userInfo
});

const mapDispatchToProps = dispatch => ({
  fillUserRegistrationInfo: userInfo => dispatch(fillUserRegistrationInfo(userInfo)),
  getStates: countryID => dispatch(getStates(countryID)),
  getCities: stateID => dispatch(getCities(stateID)),
  resetUserRegistrationInfo: () => dispatch(resetUserRegistrationInfo()),
  loadDictionaries: () => dispatch(getDictionaries())
});

export default connect(mapStateToProps, mapDispatchToProps)(Entity1Screen);