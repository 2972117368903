export const reorderCountriesDictionary = (countryList) => {
  // 231 id is US, 230 - UK, 38 - Canada
  let priorityCountriesList = [];
  for (let i = 0; i < countryList.length; i++) {
    let obj = countryList[i];

    if (parseInt(obj.id) === 231 || parseInt(obj.id) === 230 || parseInt(obj.id) === 38) {
      priorityCountriesList.push(obj);
      countryList.splice(i, 1);
      i--; // The simple solution is to modify the country list is to decrement the index variable so it does not skip the next item in the array.
    }
  }
  countryList.unshift(priorityCountriesList[1]); // by default we are getting US object with 2 index, but when we unshift it works like stack
  countryList.unshift(priorityCountriesList[2]);
  countryList.unshift(priorityCountriesList[0]);

  return countryList;
};