import React, {Component, createRef} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {
  Form,
  Input,
  message,
  Spin,
} from "antd";
import GoogleLogin from "react-google-login";
import ReCAPTCHA from "react-google-recaptcha";

import { CAPTCHA_CLIENT_ID, GOOGLE_CLIENT_ID } from "../../utils/constants";
import { handleLoading } from "../../utils/handleLoading";
import { loginUser } from "../../redux/user/thunks/loginThunk";
import { sendRecoveryLink } from "../../redux/user/thunks/recoverPasswordThunk";

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.submitCount = 0;
    this.captchaRef = createRef();
    this.state = {
      showRecoverySuccessText: false,
      captchaToken: null,
    };
  }

  onSubmit = (event) => {
    event.preventDefault();

    if (this.submitCount === 0) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.submitCount++;
          const { loginUser } = this.props;
          const { captchaToken } = this.state;
          values.captcha = captchaToken;
          loginUser(values);
        }
      });
    }
  };

  handleRecoveryLinkOnClick = () => {
    const {form, sendRecoveryLink} = this.props;
    const emailInputValue = form.getFieldValue("email");
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!emailInputValue) {
      form.setFields({
        email: {
          value: "",
          errors: [new Error("Please fill out this field")]
        }
      })
    }
    else if (!emailRegex.test(emailInputValue))  {
      form.setFields({
        email: {
          value: emailInputValue,
          errors: [new Error("Invalid email")]
        }
      })
    }
    else {
      sendRecoveryLink(emailInputValue);
    }
  };

  handleGoogleResponseSuccess = (response) => {
    if (response && response.tokenId) {
      const { loginUser } = this.props;
      const { tokenId } = response;
      loginUser({
        'id_token': tokenId,
      });
    }
  };

  handleGoogleResponseFail = (error) => {
    if (error.error === 'popup_closed_by_user') {
      message.error('Some error occurred with Google! Please try again later');
    }
  };

  captchaOnChange = (value) => this.setState({ captchaToken: value });

  componentDidUpdate(prevProps, prevState) {
    const {errorMessage, loading} = this.props;
    const errorType = errorMessage.type;
    const errorText = errorMessage.message;
    const loadingType = loading.type;
    const loadingState = loading.state;

    if (errorType === "LoginUser" && prevProps.errorMessage.message !== errorText && errorText) {
      message.error(errorText);
      this.setState({
        captchaToken: null,
      }, () => {
        this.submitCount = 0;
        this.captchaRef.current.reset();
      });
    }

    if (!errorText && loadingType === "SendRecoveryLink" && !loadingState && prevProps.loading.state) {
      this.setState({ showRecoverySuccessText: true }, () => {
        setTimeout(() => {
          this.setState({ showRecoverySuccessText: false });
        }, 5000);
      });
    }
  }

  render() {
    const { loading, isAdmin } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { showRecoverySuccessText, captchaToken } = this.state;
    const email = getFieldValue('email');
    const password = getFieldValue('password');

    return (
      <>
        <div className="flex-container align-middle align-justify log_out_header">
          <Link to="/" className="hide-lg show-md">
            <img src={require("../../assets/img/pegate.png")} alt=""/>
          </Link>
          <div className="hide-md large-10 text-center custom-headline text text-34 font-heavy color-grey">
            <h1>Login to your account</h1>
          </div>
          {
            !isAdmin ? (
              <Button
                title="Register"
                prefix={<Icon type="add-user" style={{fontSize: 22, marginRight: 12}} />}
                size="lg"
                theme="white"
                className="btn-link"
                linkTo="/personal-information"
                style={{fontSize: 16}}
              />
            ) : null
          }
        </div>

        <div className="row">
          <div className="column small-12">
            <div className="hide-lg show-md text-center custom-headline text text-34 font-heavy color-grey log_out_title">
              <h1>Login to your account</h1>
            </div>
          </div>
  
          <div className="column small-12 x-large-10">
            <Form
              name="log-in"
              onSubmit={this.onSubmit}
              className="sign_out_form_card login_form bg-white radius-10 shadow-layout"
            >
              <div className="mb_45">
                {
                  !isAdmin ? (
                    <div className="mb_45 text-center form-item-label text text-height-20 font-semibold color-grey">
                      New Users must register and Verify email to Login
                    </div>
                  ) : null
                }
  
                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    EMAIL
                  </div>
                  <Form.Item>
                    {getFieldDecorator('email', {
                      initialValue: "",
                      rules: [{required: true, message: 'Please fill out this field'}],
                    })(
                      <Input
                        type="email"
                        suffix={<Icon type="letter" className="color-grey-800" style={{fontSize: 18}} />}
                      />
                    )}
                  </Form.Item>
                </div>
  
                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    PASSWORD
                  </div>
                  <Form.Item>
                    {getFieldDecorator('password', {
                      initialValue: "",
                      rules: [{required: true, message: 'Please fill out this field'}],
                    })(
                      <Input.Password />
                    )}
                  </Form.Item>
                </div>
  
                {
                  isAdmin
                    ? (
                      <div className="form-item">
                        <div className="form-item-label text text-height-20 font-semibold color-grey">
                          ONE TIME PASSWORD
                        </div>
                        <Form.Item>
                          {getFieldDecorator('code', {
                            initialValue: "",
                            rules: [{required: true, message: 'Please fill out this field'}],
                          })(
                            <Input autoComplete='off' />
                          )}
                        </Form.Item>
                      </div>
                    ) : null
                }
  
              </div>
              <div className="text-center form_submit">
                {
                  email && password.length >= 8 ?
                    <div className="flex-container align-center mb_25" >
                      <ReCAPTCHA
                        ref={this.captchaRef}
                        sitekey={CAPTCHA_CLIENT_ID}
                        onChange={this.captchaOnChange}
                      />
                    </div>
                  : null
                }
                <Form.Item className="mb_0">
                  <Button
                    title="Login"
                    prefix={<Icon type="login" style={{fontSize: 18, marginRight: 10}} />}
                    size="xl"
                    theme="blue"
                    className="btn-shadow radius-8"
                    type="submit"
                    loading={handleLoading(loading, "LoginUser")}
                    disabled={!captchaToken}
                  />
                </Form.Item>
                {
                  !isAdmin ? (
                    <div className="mb_25">
                      <GoogleLogin
                        clientId={GOOGLE_CLIENT_ID}
                        buttonText="Login with Google"
                        onSuccess={this.handleGoogleResponseSuccess}
                        onFailure={this.handleGoogleResponseFail}
                        cookiePolicy={'single_host_origin'}
                      />
                    </div>
                  ) : null
                }
                <Spin spinning={handleLoading(loading, 'SendRecoveryLink')} >
                  <div
                    className="text font-semibold color-grey-800"
                    onClick={this.handleRecoveryLinkOnClick}
                    style={{cursor: "pointer"}}
                  >
                    Forgot password?
                  </div>
                </Spin>
                {
                  showRecoverySuccessText
                    ? (
                      <div className="form-item-hint color-green">
                        <p>Please, check your email to recover your password</p>
                      </div>
                    )
                    : null
                }
              </div>
            </Form>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  errorMessage: state.user.errorMessage,
  loading: state.user.loading
});

const mapDispatchToProps = dispatch => ({
  loginUser: userData => dispatch(loginUser(userData)),
  sendRecoveryLink: email => dispatch(sendRecoveryLink(email))
});

const SignInScreen = Form.create({ name: 'login' })(SignIn);

export default connect(mapStateToProps, mapDispatchToProps)(SignInScreen);
