import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Select, Slider } from "antd";

import { findDictionaryByID } from "../../utils/findDictionaryByID";
import { filterOption } from "../../utils/filterOption";
import { handleLoading } from "../../utils/handleLoading";
import { getStates } from "../../redux/dictionaries/thunks/dictionariesThunks";

import Button from "../../components/Button/Button";
import Filter from "../../layouts/Filter/Filter";
import { searchOffers } from "../../redux/business/thunks/offersThunk";
import { clearOfferFilters, clearOffers, setOfferFilters } from "../../redux/business/actions/offersAction";

const { Option } = Select;

class FilterComponent extends Component {
  onSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { searchOffers, clearOffers, offerFilters } = this.props;
        if (values?.filterMinInvest?.length) {
          values.minInvestmentFrom = values.filterMinInvest[0];
          values.minInvestmentTo = values.filterMinInvest[1];
          delete values.filterMinInvest;
        }
        setOfferFilters({ ...offerFilters, ...values});
        clearOffers();
        searchOffers({ offerFilters: { ...offerFilters, ...values}}, 0);
      }
    })
  };

  handleCountryOnChange = (values) => {
    if (values[0]) {
      const { getStates } = this.props;
      getStates(values[0]);
    }
    this.props.form.setFieldsValue({ state: [] });
  };

  handleResetAllFilters = () => {
    const { form, searchOffers, clearOffers, clearOfferFilters } = this.props;
    clearOfferFilters()
    clearOffers()
    searchOffers({}, 0);
    form.resetFields();
  };

  render() {
    const { form, opportunityGroups, loading } = this.props;
    const { getFieldDecorator } = form;
    const countries = this.props.countries ? this.props.countries : [];
    const states = this.props.states ? this.props.states : [];
    const industries = findDictionaryByID(5, opportunityGroups);
    const transactionStatuses = findDictionaryByID(4, opportunityGroups);
    const investmentTypes = findDictionaryByID(3, opportunityGroups);

    return (
      <Filter>
        <div className="row">
          <div className="column small-12 xx-large-11 xxx-large-9">
            <Form onSubmit={this.onSubmit} className="row expanded">
              <div className="column small-12 large-4 x-large-4 form-item form-item-white">
                <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                  INDUSTRY
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator("industry", {
                    rules: [{ required: false, message: "Please fill out this field" }],
                  })(
                    <Select
                      mode="multiple"
                      showSearch
                      filterOption={filterOption}
                      allowClear
                      style={{ width: "100%" }}
                    >
                      {industries.map((element) => <Option key={element.id} value={element.id}>{element.name}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="column small-12 large-4 x-large-4 form-item form-item-white">
                <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                  TRANSACTION STATUS
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator("transaction_status", {
                    rules: [{ required: false, message: "Please fill out this field" }],
                  })(
                    <Select
                      mode="multiple"
                      showSearch
                      filterOption={filterOption}
                      allowClear
                      style={{ width: "100%" }}
                    >
                      {transactionStatuses.map((element) => <Option key={element.id} value={element.id}>{element.name}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="column small-12 large-4 x-large-4 form-item form-item-white">
                <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                  COUNTRY
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator("country", {
                    rules: [{ required: false, message: "Please fill out this field" }],
                  })(
                    <Select
                      mode="multiple"
                      showSearch
                      filterOption={filterOption}
                      allowClear
                      style={{ width: '100%' }}
                      onChange={this.handleCountryOnChange}
                    >
                      {countries.map((element) => <Option key={element.id} value={element.id}>{element.name}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="column small-12 large-4 x-large-4 form-item form-item-white">
                <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                  PROVINCE / STATE
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator("state", {
                    rules: [{ required: false, message: 'Please fill out this field' }],
                  })(
                    <Select
                      mode="multiple"
                      showSearch
                      filterOption={filterOption}
                      allowClear
                      style={{ width: "Please fill out this field" }}
                    >
                      {states.map((element) => <Option key={element.id} value={element.id}>{element.name}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="column small-12 large-4 x-large-4 form-item form-item-white">
                <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                  INVESTMENT TYPE
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator("investment_type", {
                    rules: [{ required: false, message: "Please fill out this field" }],
                  })(
                    <Select
                      mode="multiple"
                      showSearch
                      filterOption={filterOption}
                      allowClear
                      style={{ width: "100%" }}
                    >
                      {investmentTypes.map((element) => <Option key={element.id} value={element.id}>{element.name}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="column small-12 large-4 x-large-4 form-item form-item-white">
                <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                  MINIMUM INVESTMENT
                </div>
                <div className="range_slider_wrap">
                  <Form.Item className="mb_0">
                    {getFieldDecorator("filterMinInvest", {
                      rules: [{ required: false, message: "Please fill out this field" }],
                    })(
                      <Slider range min={10000} max={500000} />
                    )}
                  </Form.Item>
                  <div className="flex-container align-middle align-justify">
                    <div className="text text-10 font-medium font-spacing-02 color-grey-900">
                      min $10K
                    </div>
                    <div className="text text-10 font-medium font-spacing-02 color-grey-900">
                      max $500K
                    </div>
                  </div>
                </div>
              </div>
              <div className="column small-12">
                <div className="flex-container align-middle filter_submit">
                  <Form.Item className="mb_0">
                    <Button
                      title="Apply filter"
                      size="lg"
                      theme="blue"
                      className="btn-shadow radius-8"
                      type="submit"
                      loading={handleLoading(loading, "GetBusiness")}
                    />
                  </Form.Item>
                  <Form.Item className="mb_0">
                    <Button
                      title="Reset all filters"
                      size="lg"
                      theme="default"
                      className="btn-link font-uppercase"
                      type="button"
                      onClick={this.handleResetAllFilters}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Filter>
    );
  }
}

const OffersFilterComponentScreen = Form.create()(FilterComponent);

const mapStateToProps = state => ({
  loading: state.offers.loading,
  opportunityGroups: state.dictionaries.opportunityGroups,
  countries: state.dictionaries.countries,
  offerFilters: state.offers.offerFilters,
  states: state.dictionaries.states,
});

const mapDispatchToProps = dispatch => ({
  getStates: countryID => dispatch(getStates(countryID)),
  searchOffers: (params) => dispatch(searchOffers(params)),
  clearOffers: (params) => dispatch(clearOffers(params)),
  setOfferFilters: () => dispatch(setOfferFilters()),
  clearOfferFilters: () => dispatch(clearOfferFilters())
});

export default connect(mapStateToProps, mapDispatchToProps)(OffersFilterComponentScreen);