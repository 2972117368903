import React, {Component} from "react";
import {connect} from "react-redux";
import {getDictionaries} from "../../redux/dictionaries/thunks/dictionariesThunks";
import {resetUserRegistrationInfo} from "../../redux/registration/actions/registrationActions";

import PersonalInformationForm from "./PersonalInformationForm";
import RegistrationSteps from "../../layouts/RegistrationSteps/RegistrationSteps";
import RegistrationSuccess from "./RegistrationSuccess";

class Individual1 extends Component {
  getFormInitialValues = () => {
    const {userInfo} = this.props;
    return {
      prefix: userInfo && userInfo.prefix ? userInfo.prefix : null,
      first_name: userInfo && userInfo.first_name ? userInfo.first_name : null,
      last_name: userInfo && userInfo.last_name ? userInfo.last_name : null,
      middle_name: userInfo && userInfo.middle_name ? userInfo.middle_name : null,
      email: userInfo && userInfo.email ? userInfo.email : null,
      linkedin: userInfo && userInfo.linkedin ? userInfo.linkedin : null,
      phone: userInfo && userInfo.phone ? userInfo.phone : null,
      password: userInfo && userInfo.password ? userInfo.password : null
    };
  };

  componentDidMount() {
    window.scroll(0, 0);
    const {loadDictionaries, opportunityGroups, countries} = this.props;

    if (!opportunityGroups && !countries) {
      loadDictionaries();
    }
  }

  render() {
    const { match, isRegisteredSuccessfully } = this.props;
    const {path} = match;
    const getFormInitialValues = this.getFormInitialValues();

    if (isRegisteredSuccessfully) {
      return (
        <RegistrationSuccess from="registration" />
      );
    }
    return (
      <>
        <RegistrationSteps regEntity={false} path={path} />
        <PersonalInformationForm
          showStatusType
          statusType={1}
          from="individual1"
          isEntity={0}
          initialValues={getFormInitialValues}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.registration.userInfo,
  opportunityGroups: state.dictionaries.opportunityGroups,
  isRegisteredSuccessfully: state.registration.isRegisteredSuccessfully,
  countries: state.dictionaries.countries
});

const mapDispatchToProps = dispatch => ({
  resetUserRegistrationInfo: () => dispatch(resetUserRegistrationInfo()),
  loadDictionaries: () => dispatch(getDictionaries())
});

export default connect(mapStateToProps, mapDispatchToProps)(Individual1);