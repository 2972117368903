import {
  setOptionsStart, setOptionsSuccess, setOptionsFail,
  getOptionsStart, getOptionsSuccess, getOptionsFail,
  clearOptionsStart, clearOptionsSuccess, clearOptionsFail
} from "../actions/dictionariesOptionsActions";
import CommonUtils from "../../../utils/CommonUtils";

export const setOptions = params => ((dispatch, getState) => {
  dispatch(setOptionsStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "shared/setOptions", params, config)
    .then(response => {
      if (response.data.success) {
        dispatch(setOptionsSuccess());
      }
      else {
        dispatch(setOptionsFail(response.data.error));
      }
    })
    .catch(() => dispatch(setOptionsFail("Error")));
});

export const getOptions = (optionGroup, type = "private") => ((dispatch, getState) => {
  dispatch(getOptionsStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  let apiCalls = [];

  if (Array.isArray(optionGroup)) {
    optionGroup.map(element => {
      return apiCalls.push(
        CommonUtils.apiCall("get", type, `shared/getOptions/${element}`, {}, config)
      )
    });
  }
  else {
    apiCalls = [
      CommonUtils.apiCall("get", type, `shared/getOptions/${optionGroup}`, {}, config)
    ]
  }

  Promise.all(apiCalls)
    .then(responses => {
      let optionsList = [];
      for (let i = 0; i < responses.length; i++) {
        if (Array.isArray(responses[i].data)) {
          optionsList.push({
            type: Array.isArray(optionGroup) ? optionGroup[i] : optionGroup,
            options: responses[i].data
          });
        }
        else {
          dispatch(getOptionsFail("Error"));
          break;
        }
      }
      dispatch(getOptionsSuccess({optionsList}))
    })
    .catch(() => dispatch(getOptionsFail("Error")));
});

export const clearOptions = optgroup => ((dispatch, getState) => {
  dispatch(clearOptionsStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "shared/clearOptions", {optgroup}, config)
    .then(response => {
      if (response.data.success) {
        dispatch(clearOptionsSuccess());
      }
      else {
        dispatch(clearOptionsFail("Error"));
      }
    })
    .catch(() => dispatch(clearOptionsFail("Error")));
});