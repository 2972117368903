export const sortOptionsAlphabetically = (options, hasOtherOption = false) => {
  if (Array.isArray(options) && options.length === 0) {
    return [];
  }

  options.sort((a, b) => {
    const textA = a.name.toLowerCase();
    const textB = b.name.toLowerCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  if (hasOtherOption) {
    const indexOfOtherOption = options.findIndex((option) => option.name.toLowerCase() === 'other');
    options.push(options.splice(indexOfOtherOption, 1)[0]);
  }

  return options;
};
