import React, { Component } from "react";
import { connect } from "react-redux";
import { registerUser } from "../../redux/registration/thunks/registrationThunks";

import { filterOption } from "../../utils/filterOption";
import { handleLoading } from "../../utils/handleLoading";
import { findDictionaryByID } from "../../utils/findDictionaryByID";
import { CAPTCHA_CLIENT_ID } from "../../utils/constants";

import ReCAPTCHA from "react-google-recaptcha";
import {
  Checkbox,
  Form,
  Input,
  Select,
} from "antd";
import Button from "../../components/Button/Button";

const { Option } = Select;

class CitizenshipForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOtherInterest: false,
      isIndividualCategoriesDisabled: false,
      isTermsOfUseSelected: false,
      isPrivacyPolicySelected: false,
      captchaToken: null,
    }
  }

  otherInterestOnChange = (event) => {
    this.setState({
      isOtherInterest: event.target.checked
    })
  };

  handleIndividualCategoriesOnChange = (values) => {
    if (values.length >= 1) {
      this.props.form.setFieldsValue({
        other_individual_category: false,
      })
    }
  };

  handleOtherIndividualCategoriesOnChange = (event) => {
    const { checked } = event.target;

    this.setState({
      isIndividualCategoriesDisabled: checked,
    }, () => {
      if (checked) {
        this.props.form.setFieldsValue({
          individual_categories: [],
        });
      }
    });
  };

  toggleCondition = (state) => {
    this.setState(prevState => ({
      [state]: !prevState[state],
    }));
  };

  captchaOnChange = (value) => this.setState({ captchaToken: value });

  onSubmit = (event) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const {registerUser, userInfo, isEntity} = this.props;
        const { captchaToken } = this.state;

        values.country = values.country ? values.country : 0;
        values.citizenship = values.country ? values.country : 0;
        values.individual_categories = values.individual_categories ? values.individual_categories : [];
        values.other_individual_category = values.other_individual_category ? 1 : 0;
        values.opportunity_types = values.opportunity_types ? values.opportunity_types : [];
        values.other_opportunity_type = values.other_opportunity_type ? values.other_opportunity_type : "";
        values.captcha = captchaToken;

        const param = {
          ...userInfo,
          ...values,
          is_entity: isEntity
        };

        registerUser("investor", param);
      }
    })
  };

  render() {
    const { loading } = this.props;
    const {getFieldDecorator, getFieldValue} = this.props.form;
    const {
      isTermsOfUseSelected,
      isPrivacyPolicySelected,
      isIndividualCategoriesDisabled,
      captchaToken,
    } = this.state;

    const {opportunityGroups, initialValues} = this.props;
    const individualCategoriesOptions = findDictionaryByID(1, opportunityGroups);
    const opportunityTypesOptions = findDictionaryByID(2, opportunityGroups);
    const countries = this.props.countries ? this.props.countries : [];

    return (
      <div className="row">
        <div className="column small-12 x-large-10">
          <Form
            onSubmit={this.onSubmit}
            className="sign_out_form_card bg-white radius-10 shadow-layout"
          >
            <div className="mb_45">
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  Country of Legal Residence
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator("country", {
                    initialValue: initialValues.country,
                    rules: [{required: true, message: "Please fill out this field"}],
                  })(
                    <Select
                      showSearch
                      filterOption={filterOption}
                      className="fluid-x"
                    >
                      { countries.map((element, index) => <Option key={index} value={element.id}>{element.name}</Option>) }
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="large-11 mb_50">
                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    SELECT THE CATEGORIES THAT APPLY TO YOU
                  </div>
                  <div className="form-item-hint text text-12 font-medium font-spacing-02 color-grey-800">
                    PE Gate investments are exclusive to accredited investors
                  </div>
                </div>
                <div className="form-item form-item-lg mb_15">
                  <Form.Item className="mb_0">
                    {getFieldDecorator("individual_categories", {
                      initialValue: initialValues.individual_categories,
                      rules: [{required: !isIndividualCategoriesDisabled, message: "Please fill out this field"}], // if checkbox is not disabled, then it's required
                    })(
                      <Checkbox.Group
                        disabled={isIndividualCategoriesDisabled}
                        onChange={this.handleIndividualCategoriesOnChange}
                        options={individualCategoriesOptions.map(element => ({label: element.name, value: element.id}))}
                      />
                    )}
                  </Form.Item>
                </div>
                <div className="form-item form-item-lg">
                  <Form.Item className="mb_0">
                    {getFieldDecorator("other_individual_category", {
                      initialValue: initialValues.other_individual_category || false,
                      valuePropName: "checked",
                      rules: [{required: false, message: "Please fill out this field"}],
                    })(
                      <Checkbox
                        onChange={this.handleOtherIndividualCategoriesOnChange}
                        disabled={getFieldValue("individual_categories").length >= 1}
                        className="flex-container align-top"
                      >
                        I do not qualify as an accredited investor, but I'm interested in learning more or being involved in managing businesses
                      </Checkbox>
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="large-11 mb_30">
                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    WHAT TYPE OF OPPORTUNITIES ARE YOU INTERESTED IN:
                  </div>
                  <div className="form-item-hint text text-12 font-medium font-spacing-02 color-grey-800">Optional</div>
                </div>
                <div className="form-item form-item-lg mb_15">
                  <Form.Item className="mb_0">
                    {getFieldDecorator("opportunity_types", {
                      initialValue: initialValues.opportunity_types,
                      rules: [{required: false, message: "Please fill out this field"}],
                    })(
                      <Checkbox.Group
                        options={opportunityTypesOptions.map(element => ({label: element.name, value: element.id}))}
                      />
                    )}
                  </Form.Item>
                </div>
                <div className="form-item form-item-lg">
                  <div className="mb_0">
                    <Checkbox
                      className="flex-container align-top"
                      onChange={this.otherInterestOnChange}
                    >
                      Other
                    </Checkbox>
                  </div>
                </div>
              </div>

              {
                this.state.isOtherInterest ?
                  <div className="form-item">
                    <div className="form-item-label text text-height-20 font-semibold color-grey">
                      TYPE HERE
                    </div>
                    <Form.Item className="mb_0">
                      {getFieldDecorator("other_opportunity_type", {
                        initialValue: initialValues.other_opportunity_type,
                        rules: [{required: true, message: "Please fill out this field"}],
                      })(
                        <Input/>
                      )}
                    </Form.Item>
                  </div>
                : null
              }
            </div>

            <div className="form-item form-item-lg" >
              <div className="mb_0">
                <Checkbox
                  className="flex-container align-top"
                  onChange={() => this.toggleCondition("isTermsOfUseSelected")}
                >
                  I agree in to the <a target="_blank" rel="noopener noreferrer" href="https://www.pe-gate.com/terms-of-use">Terms of Use</a> of PE Gate Partners
                </Checkbox>
              </div>
            </div>

            <div className="form-item form-item-lg">
              <div className="mb_0">
                <Checkbox
                  className="flex-container align-top"
                  onChange={() => this.toggleCondition('isPrivacyPolicySelected')}
                >
                  I agree in to the <a target="_blank" rel="noopener noreferrer" href="https://www.pe-gate.com/privacy-policy">Privacy Policy</a> of PE Gate Partners
                </Checkbox>
              </div>
            </div>

            {
              isTermsOfUseSelected && isPrivacyPolicySelected ?
                <div className="flex-container align-center mb_25" >
                  <ReCAPTCHA sitekey={CAPTCHA_CLIENT_ID} onChange={this.captchaOnChange} />
                </div>
              : null
            }

            <div className="text-center form_submit">
              <Form.Item className="mb_0">
                <Button
                  title="Continue"
                  size="xl"
                  theme="blue"
                  className="btn-shadow radius-8"
                  type="submit"
                  loading={handleLoading(loading, "RegisterUser")}
                  disabled={!(isTermsOfUseSelected && isPrivacyPolicySelected && captchaToken)}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

const CitizenshipFormScreen = Form.create()(CitizenshipForm);

const mapStateToProps = state => ({
  loading: state.registration.loading,
  opportunityGroups: state.dictionaries.opportunityGroups,
  countries: state.dictionaries.countries,
  userInfo: state.registration.userInfo
});

const mapDispatchToProps = dispatch => ({
  registerUser: (userType, param) => dispatch(registerUser(userType, param))
});

export default connect(mapStateToProps, mapDispatchToProps)(CitizenshipFormScreen);