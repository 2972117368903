import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {resetUserRegistrationInfo} from "../../redux/registration/actions/registrationActions";

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import WizardStep from "../../components/WizardStep/WizardStep";
import "./style.scss";

class RegistrationSteps extends Component {

  getRegistrationStepsData = () => {
    const isEntity = this.props.regEntity;
    if (isEntity) {
      return [
        {
          text: "Entity information",
          link: "/entity-information",
        },
        {
          text: "Personal information",
          link: "/entity-personal-information",
        },
      ];
    }

    return [
      {
        text: "Personal information",
        link: "/personal-information",
      }
    ];
  };

  isStepActive = () => {
    const {path} = this.props;
    const registrationStepsData = this.getRegistrationStepsData();
    const activeStepsList = [];
    registrationStepsData.forEach((stepItem) => {
      activeStepsList.push(stepItem.link === path);
    });

    return activeStepsList;
  };

  isStepDone = () => {
    const {path} = this.props;
    const isEntity = this.props.regEntity;
    const registrationStepsData = this.getRegistrationStepsData();
    let isStepDoneArr = [];

    if (!isEntity) {
      if (path === registrationStepsData[0].link) {
        isStepDoneArr = [null, false];
      }
    }
    else {
      if (path === registrationStepsData[0].link) {
        isStepDoneArr = [null, false, false];
      }
      if (path === registrationStepsData[1].link) {
        isStepDoneArr = [true, null, false];
      }
    }

    return isStepDoneArr;
  };

  getLogicOfStepInfo = (userInfo) => {
    let logicOfStepInfoArr = [];

    if (userInfo && userInfo.first_name && userInfo.last_name && userInfo.email && userInfo.phone && userInfo.password &&
      userInfo.country && userInfo.citizenship &&
      userInfo.entity_name && userInfo.entity_address && userInfo.entity_country && userInfo.entity_state &&
      userInfo.entity_city && userInfo.entity_zip && userInfo.entity_id && userInfo.entity_type) { //ete bolor step-eri data-ner@ kan
      logicOfStepInfoArr = [true, true, true];
    }
    else if (userInfo && userInfo.first_name && userInfo.last_name && userInfo.email && userInfo.phone && userInfo.password &&
      userInfo.entity_name && userInfo.entity_address && userInfo.entity_country && userInfo.entity_state &&
      userInfo.entity_city && userInfo.entity_zip && userInfo.entity_id && userInfo.entity_type
    ) {
      // if first and second steps data exists
      logicOfStepInfoArr = [true, true, false];
    }
    else if (userInfo && userInfo.entity_name && userInfo.entity_address && userInfo.entity_country && userInfo.entity_state &&
      userInfo.entity_city && userInfo.entity_zip && userInfo.entity_id && userInfo.entity_type) {
      // if only first step data exists
      logicOfStepInfoArr = [null, false, false];
    }

    return logicOfStepInfoArr;
  };

  isStepInfoFilled = () => {
    const {userInfo, path} = this.props;
    const isEntity = this.props.regEntity;
    let isStepInfoFilledArr = [];

    if (!isEntity) {
      switch (path) {
        case "/personal-information":
          if (userInfo && userInfo.first_name && userInfo.last_name && userInfo.email && userInfo.phone && userInfo.password &&
              userInfo.country && userInfo.citizenship) {
            isStepInfoFilledArr = [true, true];
          }
          else if (userInfo && userInfo.first_name && userInfo.last_name && userInfo.email && userInfo.phone && userInfo.password){
            isStepInfoFilledArr = [null, false];
          }
          break;
        case "/investor-confirmation":
          isStepInfoFilledArr = [true, true];
          break;
        default:
      }
    }
    else {
      switch (path) {
        case "/entity-information":
          isStepInfoFilledArr = this.getLogicOfStepInfo(userInfo);
          break;
        case "/entity-personal-information":
          isStepInfoFilledArr = this.getLogicOfStepInfo(userInfo);
          break;
        case "/entity-investor-confirmation":
          if (userInfo && userInfo.country && userInfo.citizenship && userInfo.green_card) {
            isStepInfoFilledArr = [true, true, false];
          }
          break;
        default:
      }
    }

    return isStepInfoFilledArr;
  };

  handleLoginOnClick = () => {
    const {userInfo, resetUserRegistrationInfo} = this.props;

    if (Object.keys(userInfo).length) {
      resetUserRegistrationInfo();
    }
  };

  render() {
    const {regEntity} = this.props;
    const isStepActive = this.isStepActive();
    const isStepDone = this.isStepDone();
    const isStepInfoFilled = this.isStepInfoFilled();

    return (
      <div className="row">
        <div className="flex-container align-middle align-justify log_out_header log-in-wrapper">
          <Link to="/" className="hide-lg show-md">
            <img src={require("../../assets/img/pegate.png")} alt=""/>
          </Link>
          <div className="hide-md large-10 text-center custom-headline text text-34 font-heavy color-grey">
            <h1>
              Registration
            </h1>
          </div>
          <Button
            title="Login"
            prefix={<Icon type="login" style={{fontSize: 26, marginRight: 12}} />}
            size="lg"
            theme="white"
            className="btn-link"
            linkTo="/signin"
            style={{fontSize: 16}}
            onClick={this.handleLoginOnClick}
          />
        </div>
        <div className="column small-12">
          <div className="hide-lg show-md text-center custom-headline text text-34 font-heavy color-grey">
            <h1>
              Registration
            </h1>
          </div>
        </div>
        <div className="column small-12 x-large-10">
          <div className="flex-container align-middle align-center wizard_steps wizard-steps-wrapper">
            {
              !regEntity ? (
                <div className="large-3 text-center text text-24 font-semibold color-grey">
                  <p className="m_0" >{this.getRegistrationStepsData()[0].text}</p>
                </div>
              ) : (
                this.getRegistrationStepsData().map((stepItem, stepIndex) => (
                  <Link
                    to={stepItem.link}
                    className={isStepActive[stepIndex] ? "active" : isStepInfoFilled[stepIndex] ? "done" : isStepDone[stepIndex] ? "done" : "disabled" }
                  >
                    <WizardStep
                      number={stepIndex + 1}
                      name={stepItem.text}
                      className={isStepActive[stepIndex] ? "active" : isStepInfoFilled[stepIndex] ? "done" : isStepDone[stepIndex] ? "done" : "disabled"}
                    />
                  </Link>
                ))
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.registration.userInfo
});

const mapDispatchToProps = dispatch => ({
  resetUserRegistrationInfo: () => dispatch(resetUserRegistrationInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationSteps);