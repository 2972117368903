import React, {Component} from "react";
import {connect} from "react-redux";
import {message} from 'antd';

import CitizenshipForm from "./CitizenshipForm";
import RegistrationSuccess from "./RegistrationSuccess";
import RegistrationSteps from "../../layouts/RegistrationSteps/RegistrationSteps";

class Entity3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      citizenship: '',
      otherCitizenship: '',
      citizenOrGreenCard: 2,
      category1: false,
      category2: false,
      category3: false,
      category4: false,
      interest1: false,
      interest2: false,
      interest3: false,
      interest4: false,
      isOtherInterest: false,
      otherInterest: '',
    }
  }

  getFormInitialValues = () => {
    const {userInfo} = this.props;
    return {
      country: userInfo.country ? userInfo.country : "",
      citizenship: userInfo.citizenship ? userInfo.citizenship : "",
      going_to_sell: userInfo.going_to_sell ? userInfo.going_to_sell : 0,
      individual_categories: userInfo.individual_categories ? userInfo.individual_categories : [],
      other_individual_category: userInfo.other_individual_category ? 1 : 0,
      opportunity_types: userInfo.opportunity_types ? userInfo.opportunity_types : [],
      other_opportunity_type: userInfo.other_opportunity_type ? userInfo.other_opportunity_type : ""
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const {errorMessage} = this.props;

    if (errorMessage.type === "RegisterUser" && (prevProps.errorMessage.message !== errorMessage.message && errorMessage.message)) {
      message.error(errorMessage.message);
    }
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const {isRegisteredSuccessfully} = this.props;
    const { path } = this.props.match;
    const getFormInitialValues = this.getFormInitialValues();

    if (isRegisteredSuccessfully) {
      return (
        <RegistrationSuccess from="registration" />
      );
    }
    else {
      return (
        <>
          <RegistrationSteps regEntity path={path} />
          <CitizenshipForm isEntity={1} initialValues={getFormInitialValues} />
        </>
      );
    }
  }
}

const mapStateToProps = state => ({
  errorMessage: state.registration.errorMessage,
  isRegisteredSuccessfully: state.registration.isRegisteredSuccessfully,
  userInfo: state.registration.userInfo
});

export default connect(mapStateToProps)(Entity3);