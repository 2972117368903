import {
  GET_DICTIONARIES_START, GET_DICTIONARIES_SUCCESS, GET_DICTIONARIES_FAIL,
  GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_START, GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_SUCCESS, GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_FAIL,
  GET_COUNTRIES_START, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAIL,
  GET_STATES_START, GET_STATES_SUCCESS, GET_STATES_FAIL,
  REMOVE_STATES,
  GET_CITIES_START, GET_CITIES_SUCCESS, GET_CITIES_FAIL
} from "../../constants";

export const getDictionariesStart = () => ({
  type: GET_DICTIONARIES_START
});

export const getDictionariesSuccess = (payload) => ({
  type: GET_DICTIONARIES_SUCCESS,
  payload
});

export const getDictionariesFail = (payload) => ({
  type: GET_DICTIONARIES_FAIL,
  payload
});

export const getOpportunityGroupsWithOptionsStart = () => ({
  type: GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_START,
});

export const getOpportunityGroupsWithOptionsSuccess = (payload) => ({
  type: GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_SUCCESS,
  payload
});

export const getOpportunityGroupsWithOptionsFail = (payload) => ({
  type: GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_FAIL,
  payload
});

export const getCountriesStart = () => ({
  type: GET_COUNTRIES_START
});

export const getCountriesSuccess = (payload) => ({
  type: GET_COUNTRIES_SUCCESS,
  payload
});

export const getCountriesFail = (payload) => ({
  type: GET_COUNTRIES_FAIL,
  payload
});

export const getStatesStart = () => ({
  type: GET_STATES_START
});

export const getStatesSuccess = (payload) => ({
  type: GET_STATES_SUCCESS,
  payload
});

export const getStatesFail = (payload) => ({
  type: GET_STATES_FAIL,
  payload
});

export const removeStates = () => ({
  type: REMOVE_STATES
});

export const getCitiesStart = () => ({
  type: GET_CITIES_START
});

export const getCitiesSuccess = (payload) => ({
  type: GET_CITIES_SUCCESS,
  payload
});

export const getCitiesFail = (payload) => ({
  type: GET_CITIES_FAIL,
  payload
});