import {
  updatePermissionsStart, updatePermissionsSuccess, updatePermissionsFail,
  updateSettingsStart, updateSettingsSuccess, updateSettingsFail,
  setOnboardedStart, setOnboardedSuccess, setOnboardedFail,
  unsetOnboardedStart, unsetOnboardedSuccess, unsetOnboardedFail,
  sendActivationEmailStart, sendActivationEmailSuccess, sendActivationEmailFail,
  createAdminStart, createAdminSuccess, createAdminFail,
} from "../actions/userPermissionsActions";
import CommonUtils from "../../../utils/CommonUtils";
import {searchUsersFail} from "../actions/searchUsersActions";

export const updatePermissions = params => ((dispatch, getState) => {
  dispatch(updatePermissionsStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "admin/updatePermissions", params, config)
    .then(response => {
      if (response.data.success) {
        dispatch(updatePermissionsSuccess());
      }
      else {
        dispatch(updatePermissionsFail(response.data.error));
      }
    })
    .catch(() => dispatch(updatePermissionsFail("Error")));
});

export const updateSettings = params => ((dispatch, getState) => {
  dispatch(updateSettingsStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "admin/updateSettings", params, config)
    .then(response => {
      if (response.data.success) {
        dispatch(updateSettingsSuccess());
      } else {
        dispatch(updateSettingsFail(response.data.error));
      }
    })
    .catch(() => dispatch(updateSettingsFail("Error")));
});

export const setOnboarded = id => ((dispatch, getState) => {
  dispatch(setOnboardedStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "admin/setOnboarded", { id }, config)
    .then(response => {
      if (response.data.success) {
        dispatch(setOnboardedSuccess());
      }
      else {
        dispatch(setOnboardedFail(response.data.error));
      }
    })
    .catch(() => dispatch(setOnboardedFail("Error")));
});

export const unsetOnboarded = id => ((dispatch, getState) => {
  dispatch(unsetOnboardedStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "admin/unsetOnboarded", { id }, config)
    .then(response => {
      if (response.data.success) {
        dispatch(unsetOnboardedSuccess());
      }
      else {
        dispatch(unsetOnboardedFail(response.data.error));
      }
    })
    .catch(() => dispatch(unsetOnboardedFail("Error")));
});

export const sendActivationEmail = id => ((dispatch, getState) => {
  dispatch(sendActivationEmailStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "admin/sendActivationEmail", { id }, config)
    .then(response => {
      if (response.data.success) {
        dispatch(sendActivationEmailSuccess());
      }
      else {
        dispatch(sendActivationEmailFail(response.data.error));
      }
    })
    .catch(() => dispatch(sendActivationEmailFail("Error")));
});

export const createAdmin = (params) => ((dispatch, getState) => {
  dispatch(createAdminStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "admin/createAdmin", params, config)
    .then(createAdminResponse => {
      if (createAdminResponse.data.success) {
        const { currentPage } = getState().users.pagination;
        CommonUtils.apiCall("get", "private", "admin/searchUsers/0/0?cnt", {}, config)
          .then(usersCountResponse => {
            if (usersCountResponse.data !== null && parseInt(usersCountResponse.data) === 0) {
              dispatch(createAdminSuccess({
                usersList: [],
                totalPages: 0,
                totalUsers: 0,
              }));
            }
            else if (usersCountResponse.data !== null && !usersCountResponse.data.error) {
              const perPage = 10;
              const offset = (currentPage - 1) * perPage;
              const totalPages = Math.ceil(usersCountResponse.data / perPage);

              CommonUtils.apiCall("get", "private", `admin/searchUsers/${offset}/${perPage}`, {}, config)
                .then(response => {
                  if (Array.isArray(response.data)) {
                    dispatch(createAdminSuccess({
                      usersList: response.data,
                      totalPages,
                      totalUsers: usersCountResponse.data,
                    }))
                  }
                  else {
                    dispatch(searchUsersFail(response.data.error));
                  }
                })
                .catch(() => dispatch(searchUsersFail("Error")));
            }
            else {
              dispatch(createAdminFail("Error"));
            }
          })
          .catch(() => dispatch(createAdminFail("Error")));
      } else {
        dispatch(createAdminFail("Error"))
      }
    })
    .catch(() => dispatch(createAdminFail("Error")));
});
