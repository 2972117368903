import React, {Component} from 'react';
import { Input,Select  } from 'antd';
import Icon from '../components/Icon/Icon';

const { Option } = Select;

export default class Inputs extends Component {
  handleChange = (value) => {
    
  }

  render() {
    return (
      <>
      <div className="form-item">
        <Input placeholder="Basic usage" />
      </div>
      <div className="form-item">
        <div className="form-item-label text text-height-20 font-semibold color-grey">
          EMAIL
        </div>
        <Input placeholder="default size" suffix={<Icon type="letter" className="color-grey-800" />} />
        <div className="form-item-hint text text-12 font-medium font-spacing-02 color-grey-800">Optional</div>
      </div>

      <div className="form-item">
        <Select defaultValue="lucy" className="fluid-x" onChange={this.handleChange}>
          <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
          <Option value="disabled" disabled>
            Disabled
          </Option>
          <Option value="Yiminghe">yiminghe</Option>
        </Select>
      </div>
      </>
    );
  }
}
