import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Checkbox,
  Form,
  message,
  Select,
} from "antd";
import ReactToPrint from "react-to-print";

import { getStates } from "../../../redux/dictionaries/thunks/dictionariesThunks";
import { getUser } from "../../../redux/users/thunks/getUserThunk";
import { removeUserData } from "../../../redux/users/actions/getUserActions";
import {
  updatePermissions,
  setOnboarded,
  unsetOnboarded,
  updateSettings,
} from "../../../redux/users/thunks/userPermissionsThunks";
import { getMediaFileFromServer } from "../../../utils/getMediaFileFromServer";
import { formatDate } from "../../../utils/formatDate";
import { findCountryFromDictionary, findValueFromDictionary } from "../../../utils/findValueFromDictionary";
import { findDictionaryByID } from "../../../utils/findDictionaryByID";
import { formatNumber } from "../../../utils/formatNumber";
import { goBack } from "../../../utils/goBack";

import Button from "../../../components/Button/Button";
import Icon from "../../../components/Icon/Icon";
import PopUp from "../../../components/PopUp/PopUp";
import ListItem from "../../../components/ListItem/ListItem";
import "./style.scss";

const { Option } = Select;
const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

class AdminUserInner extends Component {
  constructor(props) {
    super(props);

    this.prevSelectedAccesses = {};
    this.state = {
      popupVisible: false,
      popupSuccessVisible: false,
      popupNdaVisible: false,
      popupOnboardingVisible: false,
      ndaLogged: false,
      openToCommitment: false,
      dealClosed: false,
      amountPopupVisible: false,
      privacyPopupVisible: false,
      regDataPopupVisible: false,
      investAmount: "500 000"
    }
  }

  showSuccessModal = () => {
    this.setState({
      popupSuccessVisible: true,
      popupNdaVisible: false,
      ndaLogged: true
    });
  };

  hideAmountModal = () => {
    this.setState({
      amountPopupVisible: false
    });
  };

  hideSuccessModal = () => {
    this.setState({
      popupSuccessVisible: false
    });
  };

  hideThankModal = () => {
    this.setState({
      popupVisible: false
    });
  };

  hidePrivacyModal = () => {
    this.setState({
      privacyPopupVisible: false
    });
  };

  toggleRegDataModal = () => {
    this.setState((prevState) => ({ regDataPopupVisible: !prevState.regDataPopupVisible }));
  };

  getCurrentUserRegData = () => {
    const { currentUser, countries, opportunityGroups, states } = this.props;
    let returningData = {
      first_name: {
        title: "First name",
        value: "",
      },
      last_name: {
        title: "Last name",
        value: "",
      },
      middle_name: {
        title: "Middle name",
        value: "",
      },
      email: {
        title: "Email address",
        value: "",
      },
      linkedin: {
        title: "LinkedIn profile link",
        value: "",
        isLink: true,
      },
      phone: {
        title: "Mobile phone number",
        value: "",
      },
    };
    const entityTypesOptions = findDictionaryByID(35, opportunityGroups);

    if (currentUser) {
      returningData.first_name.value = currentUser.first_name ? currentUser.first_name : "";
      returningData.last_name.value = currentUser.last_name ? currentUser.last_name : "";
      returningData.middle_name.value = currentUser.middle_name ? currentUser.middle_name : "";
      returningData.email.value = currentUser.email ? currentUser.email : "";
      returningData.linkedin.value = currentUser.linkedin ? currentUser.linkedin : "";
      returningData.phone.value = currentUser.phone ? currentUser.phone : "";

      if (parseInt(currentUser.is_entity) === 1) {
        const investorSectorsSelectedOptions = this.getRegistrationOptions(5);
        const investmentSizesSelectedOptions = this.getRegistrationOptions(36);

        returningData.entity_name = { title: "Fund / Entity name", value: currentUser.entity_name ? currentUser.entity_name : "" };
        returningData.entity_address = { title: "Investor Entity Address", value: currentUser.entity_address ? currentUser.entity_address : "" };
        returningData.entity_address = { title: "Country", value: currentUser.entity_country ? findValueFromDictionary(currentUser.entity_country, countries) : "" };
        returningData.entity_state = { title: "Province / State", value: currentUser.entity_state ? findValueFromDictionary(currentUser.entity_state, states) : "" };
        returningData.entity_city = { title: "City", value: currentUser.entity_city ? currentUser.entity_city : "" };
        returningData.entity_type = { title: "Is the Fund / Entity a", value: currentUser.entity_type ? findValueFromDictionary(currentUser.entity_type, entityTypesOptions) : "" };
        returningData.investor_sectors = {
          title: `
            Sector Preferences: Please indicate which of the following
            industry sectors is of interest to you when making private equity investments
          `,
          value: investorSectorsSelectedOptions.join(','),
        };
        returningData.investor_sizes = {
          title: `What size investments are you interested in`,
          value: investmentSizesSelectedOptions.join(','),
        };
      }
    }

    return returningData;
  };

  getCurrentUserData = () => {
    const { currentUser, opportunityGroups, countries } = this.props;
    let returningUserData = {
      id: "",
      prefix: "",
      first_name: "",
      last_name: "",
      avatar: "",
      phone: "",
      email: "",
      role: "",
      access_investments: "",
      access_portfolio: "",
      access_messages: "",
      access_files: "",
      access_accounts: "",
      notify_investments: "",
      notify_reports: "",
      onboarded: "",
      random_id: "",
      isOnboardingDataFilled: false,
      onboardingData: {
        country: {
          title: "Country of Legal Residence",
          value: "",
        },
        state: {
          title: "Province / State",
          value: "",
        },
        city: {
          title: "City",
          value: "",
        },
        street_address: {
          title: "Street Address",
          value: "",
        },
        zip: {
          title: "Postal Code / Zip",
          value: "",
        },
        birth_country: {
          title: "Birth Country",
          value: "",
        },
        green_card: {
          title: "Are you a US citizen or Green Card holder?",
          value: "",
        },
        birthday: {
          title: "Date of Birth",
          value: "",
        },
        employment: {
          title: "What is your employment status?",
          value: "",
        },
        business_name: {
          title: "Name of Business/Employer",
          value: "",
        },
        married: {
          title: "Are you married?",
          value: "",
        },
        spouse_name: {
          title: "Spouse's Name",
          value: "",
        },
        spouse_country: {
          title: "Spouse's Country of Birth",
          value: "",
        },
        spouse_citizenship: {
          title: "Spouse's Citizenship",
          value: "",
        },
        insider: {
          title: "Are you an insider?",
          value: "",
        },
        voting_companies: {
          title: "Do you own, either by yourself or as part of a group, 10% or more of the voting rights of a publicly traded company?",
          value: "",
        },
        officer_companies: {
          title: "Are you a Director or Senior Officer of any publicly traded company?",
          value: "",
        },
        asset_source_other: {
          title: "Source of investment assets",
          value: "",
        },
        investment_amount: {
          title: "Total amount you might consider investing in opportunities presented by PE Gate (in USD)",
          value: "",
        },
        invested_businesses: {
          title: "Have you ever invested in a private business",
          value: "",
        },
        number_of_businesses: {
          title: "How many businesses?",
          value: "",
        },
        past_board_role: {
          title: "Have you held a board role in any of your investments?",
          value: "",
        },
        future_board_role: {
          title: "Are you interested in an operating or board role in private companies?",
          value: "",
        },
        investing_pb_knowledge: {
          title: "How would you rate your knowledge of investing in private businesses",
          value: "",
        },
        investing_fm_knowledge: {
          title: "How would you rate your knowledge of Investing in financial markets",
          value: "",
        },
        liquidity_level: {
          title: "What is your preferred time horizon & liquidity of your investable Assets - Short Term 1 - Long Term 5",
          value: "",
        },
        reward_level: {
          title: "If investing in a private business, select your preference in terms of risk",
          value: "",
        },
        signature_crime: {
          title: "Signature Crime",
          value: "",
        },
        signature_sanctions: {
          title: "Signature Sanctions",
          value: "",
        },
        politic: {
          title: "Politically Exposed Persons",
          value: "",
        },
        other_person_authorization: {
          title: "Will any other person have trading authorization on this account?",
          value: "",
        },
        other_person_guarantee: {
          title: "Will any other person guarantee this account?",
          value: "",
        },
        other_person_interest: {
          title: "Will any other person have a financial interest in this account?",
          value: "",
        },
        final_comments: {
          title: "Any questions or comments",
          value: "",
        },
        final_name: {
          title: "Your name",
          value: "",
        },
        final_signature: {
          title: "Signature",
          value: "",
        },
        final_date: {
          title: "Date",
          value: "",
        },
        investment_assets: {
          title: 'Source of investment assets',
          value: '',
        },
        sector_preferences: {
          title: 'Sector Preferences: Please indicate which of the following industry sectors is of interest to you when making private equity investments',
          value: '',
        },
        other_asset_classes: {
          title: 'In which other asset classes do you have experience in investing in',
          value: '',
        },
        certification: {
          title: 'I certify that I am',
          value: '',
        },
      }
    };

    if (currentUser) {
      returningUserData.id = currentUser.id ? currentUser.id : "";
      returningUserData.prefix = currentUser.prefix ? currentUser.prefix : "";
      returningUserData.first_name = currentUser.first_name ? currentUser.first_name : "";
      returningUserData.last_name = currentUser.last_name ? currentUser.last_name : "";
      returningUserData.avatar = currentUser.avatar ? currentUser.avatar : "";
      returningUserData.phone = currentUser.phone ? currentUser.phone : "";
      returningUserData.email = currentUser.email ? currentUser.email : "";
      returningUserData.role = currentUser.role ? currentUser.role : "";
      returningUserData.access_investments = parseInt(currentUser.access_investments) === 1;
      returningUserData.access_portfolio = parseInt(currentUser.access_portfolio) === 1;
      returningUserData.access_messages = parseInt(currentUser.access_messages) === 1;
      returningUserData.access_files = parseInt(currentUser.access_files) === 1;
      returningUserData.access_accounts = parseInt(currentUser.access_accounts) === 1;
      returningUserData.write_investments = parseInt(currentUser.write_investments) === 1;
      returningUserData.write_portfolio = parseInt(currentUser.write_portfolio) === 1;
      returningUserData.write_messages = parseInt(currentUser.write_messages) === 1;
      returningUserData.write_files = parseInt(currentUser.write_files) === 1;
      returningUserData.write_accounts = parseInt(currentUser.write_accounts) === 1;
      returningUserData.notify_investments = parseInt(currentUser.notify_investments) === 1;
      returningUserData.notify_reports = parseInt(currentUser.notify_reports) === 1;
      returningUserData.onboarded = parseInt(currentUser.onboarded) === 1;
      returningUserData.random_id = currentUser.random_id ? currentUser.random_id : "";

      if (currentUser.other_person_interest != null) {
        const onboardingOptions3 = this.getRegistrationOptions(3);
        const onboardingOptions5 = this.getRegistrationOptions(5);
        const onboardingOptions26 = this.getRegistrationOptions(26);
        const onboardingOptions33 = this.getRegistrationOptions(33);
        const onboardingOptions33Ids = this.getRegistrationOptions(33, true);

        returningUserData.isOnboardingDataFilled = true;
        returningUserData.onboardingData.country.value = currentUser.country ? findCountryFromDictionary(currentUser.country, countries).name : "";
        returningUserData.onboardingData.country.isRed = currentUser.country ? findCountryFromDictionary(currentUser.country, countries).red_residence === 1 : false;
        returningUserData.onboardingData.state.value = currentUser.state ? currentUser.state : "";
        returningUserData.onboardingData.city.value = currentUser.city ? currentUser.city : "";
        returningUserData.onboardingData.zip.value = currentUser.zip ? currentUser.zip : "";
        returningUserData.onboardingData.street_address.value = currentUser.street_address ? currentUser.street_address : "";
        returningUserData.onboardingData.birthday.value = currentUser.birthday ? currentUser.birthday : "";
        returningUserData.onboardingData.birth_country.value = currentUser.birth_country ? findCountryFromDictionary(currentUser.birth_country, countries).name : "";
        returningUserData.onboardingData.birth_country.isRed = currentUser.birth_country ? findCountryFromDictionary(currentUser.birth_country, countries).red_birth === 1 : "";
        returningUserData.onboardingData.green_card.value = parseInt(currentUser.green_card) === 1 ? "Yes" : "No";
        returningUserData.onboardingData.green_card.isRed = parseInt(currentUser.green_card) === 1;
        returningUserData.onboardingData.employment.value = currentUser.employment ? findValueFromDictionary(currentUser.employment, findDictionaryByID(29, opportunityGroups)) : "";
        returningUserData.onboardingData.business_name.value = currentUser.business_name ? currentUser.business_name : "";
        returningUserData.onboardingData.married.value = parseInt(currentUser.married) === 1 ? "Yes" : "No";
        returningUserData.onboardingData.spouse_name.value = currentUser.spouse_name ? currentUser.spouse_name : "";
        returningUserData.onboardingData.spouse_country.value = currentUser.spouse_country ? findValueFromDictionary(currentUser.spouse_country, countries) : "";
        returningUserData.onboardingData.spouse_citizenship.value = currentUser.spouse_citizenship ? findValueFromDictionary(currentUser.spouse_citizenship, countries) : "";
        returningUserData.onboardingData.insider.value = parseInt(currentUser.insider) === 1 ? "Yes" : "No";
        returningUserData.onboardingData.insider.isRed = parseInt(currentUser.insider) === 1;
        returningUserData.onboardingData.voting_companies.value = currentUser.voting_companies ? currentUser.voting_companies : "No";
        returningUserData.onboardingData.voting_companies.isRed = !!currentUser.voting_companies;
        returningUserData.onboardingData.officer_companies.value = currentUser.officer_companies ? currentUser.officer_companies : "No";
        returningUserData.onboardingData.officer_companies.isRed = !!currentUser.officer_companies;
        returningUserData.onboardingData.asset_source_other.value = currentUser.asset_source_other ? currentUser.asset_source_other : "";
        returningUserData.onboardingData.liquidity_level.value = currentUser.liquidity_level ? currentUser.liquidity_level : "";
        returningUserData.onboardingData.liquidity_level.isRed = +currentUser.liquidity_level === 1;
        returningUserData.onboardingData.reward_level.value = currentUser.reward_level ? currentUser.reward_level : "";
        returningUserData.onboardingData.reward_level.isRed = +currentUser.reward_level === 1;
        returningUserData.onboardingData.investment_amount.value = currentUser.investment_amount ? formatNumber(currentUser.investment_amount) : "";
        returningUserData.onboardingData.invested_businesses.value = parseInt(currentUser.invested_businesses) === 1 ? "Yes" : "No";
        returningUserData.onboardingData.number_of_businesses.value = currentUser.number_of_businesses ? currentUser.number_of_businesses : "";
        returningUserData.onboardingData.past_board_role.value = parseInt(currentUser.past_board_role) === 1 ? "Yes" : "No";
        returningUserData.onboardingData.future_board_role.value = parseInt(currentUser.future_board_role) === 1 ? "Yes" : "No";
        returningUserData.onboardingData.investing_pb_knowledge.value = currentUser.investing_pb_knowledge ? findValueFromDictionary(currentUser.investing_pb_knowledge, findDictionaryByID(27, opportunityGroups)) : "";
        returningUserData.onboardingData.investing_pb_knowledge.isRed = !currentUser.investing_pb_knowledge || currentUser.investing_pb_knowledge === 153;
        returningUserData.onboardingData.investing_fm_knowledge.value = currentUser.investing_fm_knowledge ? findValueFromDictionary(currentUser.investing_fm_knowledge, findDictionaryByID(28, opportunityGroups)) : "";
        returningUserData.onboardingData.investing_fm_knowledge.isRed = !currentUser.investing_fm_knowledge || currentUser.investing_fm_knowledge === 157;
        returningUserData.onboardingData.signature_crime = currentUser.signature_crime ? {
          isLink: true,
          link: currentUser.signature_crime,
          text: "See signature",
          title: "Signature Crime",
        } : "";
        returningUserData.onboardingData.signature_sanctions = currentUser.signature_sanctions ? {
          isLink: true,
          link: currentUser.signature_sanctions,
          text: "See signature",
          title: "Signature Sanctions"
        } : "";
        returningUserData.onboardingData.politic.value = parseInt(currentUser.politic) === 1 ? "Yes" : "No";
        returningUserData.onboardingData.politic.isRed = parseInt(currentUser.politic) === 1;
        returningUserData.onboardingData.other_person_authorization.value = parseInt(currentUser.other_person_authorization) === 1 ? "Yes" : "No";
        returningUserData.onboardingData.other_person_guarantee.value = parseInt(currentUser.other_person_guarantee) === 1 ? "Yes" : "No";
        returningUserData.onboardingData.other_person_interest.value = parseInt(currentUser.other_person_interest) === 1 ? "Yes" : "No";
        returningUserData.onboardingData.investment_assets.value = onboardingOptions3.join(', ');
        returningUserData.onboardingData.sector_preferences.value = onboardingOptions5.join(', ');
        returningUserData.onboardingData.other_asset_classes.value = onboardingOptions26.join(', ');
        returningUserData.onboardingData.certification.value = onboardingOptions33.join(', ');
        returningUserData.onboardingData.certification.isRed = onboardingOptions33Ids.includes(179);
        returningUserData.onboardingData.final_comments.value = currentUser.final_comments ? currentUser.final_comments : "";
        returningUserData.onboardingData.final_name.value = currentUser.final_name ? currentUser.final_name : "";
        returningUserData.onboardingData.final_signature = currentUser.final_signature ? {
          isLink: true,
          link: currentUser.final_signature,
          text: "See signature",
          title: "Signature",
        } : "";
        returningUserData.onboardingData.final_date.value = currentUser.final_date ? currentUser.final_date : "";
      }
    }
    return returningUserData;
  };

  getCurrentUserBusinessesData = () => {
    const { currentUserBusinesses } = this.props;
    let returningData = [];

    if (currentUserBusinesses) {
      currentUserBusinesses.map(element => {
        return returningData.push({
          id: element.id ? element.id : "",
          name: element.name ? element.name : "",
          description: element.description ? element.description : "",
          deadline: element.deadline ? formatDate(element.deadline) : "",
          photo: element.photo ? getMediaFileFromServer(element.photo) : require("../../../assets/img/joel-filipe-RFDP7_80v5A-unsplash.png"),
          interested: element.interested,
          nda_signed: element.nda_signed,
          ready_to_start: element.ready_to_start,
          ready_to_commit: element.ready_to_commit,
          ready_to_close: element.ready_to_close,
          closed: element.closed,
        });
      });
    }

    return returningData;
  };

  getIsAccessButtonDisabled = () => {
    const { xID } = this.props;
    const { id } = this.getCurrentUserData();

    if (parseInt(xID) !== 1 && parseInt(id) === 1) {
      return true;
    }
    return parseInt(xID) === parseInt(id);
  };

  handleAccessOnClick = () => {
    const { form } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        const { match, updatePermissions } = this.props;
        const { id } = match.params;

        const params = {
          id,
          access_investments: values.access_investments ? 1 : 0,
          write_investments: values.write_investments ? 1 : 0,
          access_portfolio: values.access_portfolio ? 1 : 0,
          write_portfolio: values.write_portfolio ? 1 : 0,
          access_messages: values.access_messages ? 1 : 0,
          write_messages: values.write_messages ? 1 : 0,
          access_files: values.access_files ? 1 : 0,
          write_files: values.write_files ? 1 : 0,
          access_accounts: values.access_accounts ? 1 : 0,
          write_accounts: values.write_accounts ? 1 : 0,
        };

        updatePermissions(params);
      }
    });
  };

  handleStatusOnClick = () => {
    const { form } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        const { match, setOnboarded, unsetOnboarded } = this.props;
        const { id } = match.params;
        values.onboarded ? setOnboarded(id) : unsetOnboarded(id);
      }
    });
  };

  handleNotificationsOnClick = () => {
    const { form } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        const { match, updateSettings } = this.props;
        const { id } = match.params;

        const params = {
          id,
          notify_investments: values.notify_investments ? 1 : 0,
          notify_reports: values.notify_reports ? 1 : 0,
        };

        updateSettings(params);
      }
    });
  };

  toggleOnboardingDataPopup = status => {
    this.setState({
      popupOnboardingVisible: status
    })
  };

  getOpportunityStatusLabel = (opportunityItem) => {
    const {
      interested, nda_signed,
      ready_to_start, ready_to_commit,
      ready_to_close, closed
    } = opportunityItem;

    if (closed) {
      return "CLOSED";
    }
    if (ready_to_close) {
      return "READY TO CLOSE";
    }
    if (ready_to_commit) {
      return "READY TO COMMIT";
    }
    if (ready_to_start) {
      return "READY TO START";
    }
    if (nda_signed) {
      return "NDA SIGNED";
    }
    if (interested) {
      return "INTERESTED";
    }
    if (!interested) {
      return "NOT INTERESTED";
    }
  };

  onSubmit = (event) => {
    event.preventDefault();
  };

  getRegistrationOptions = (optionsID, isOnlyIDs) => {
    const { currentUser, opportunityGroups } = this.props;
    const individualCategoriesOptions = findDictionaryByID(optionsID, opportunityGroups);
    const selectedOptions = [];

    if (currentUser && currentUser.options && currentUser.options[optionsID] && Object.keys(currentUser.options).length) {
      Object.keys(currentUser.options[optionsID]).map((key) => {
        if (isOnlyIDs) {
          selectedOptions.push(currentUser.options[optionsID][key].option);
        } else {
          selectedOptions.push(findValueFromDictionary(currentUser.options[optionsID][key].option, individualCategoriesOptions))
        }

        return "";
      });
    }

    return selectedOptions;
  };

  onCertainAccessChange = () => {
    const { form } = this.props;
    const isChooseAllSelected = form.getFieldValue('choose_all');
    if (isChooseAllSelected) {
      form.setFieldsValue({ choose_all: false });
    }
  };

  onChooseAllChange = (event) => {
    const { form } = this.props;
    const { checked } = event.target;
    if (checked) {
      this.prevSelectedAccesses = {
        access_investments: form.getFieldValue('access_investments'),
        write_investments: form.getFieldValue('write_investments'),
        access_portfolio: form.getFieldValue('access_portfolio'),
        write_portfolio: form.getFieldValue('write_portfolio'),
        access_messages: form.getFieldValue('access_messages'),
        write_messages: form.getFieldValue('write_messages'),
        access_files: form.getFieldValue('access_files'),
        write_files: form.getFieldValue('write_files'),
        access_accounts: form.getFieldValue('access_accounts'),
        write_accounts: form.getFieldValue('write_accounts'),
      };
    }
    form.setFieldsValue({
      access_investments: checked || this.prevSelectedAccesses.access_investments,
      write_investments: checked || this.prevSelectedAccesses.write_investments,
      access_portfolio: checked || this.prevSelectedAccesses.access_portfolio,
      write_portfolio: checked || this.prevSelectedAccesses.write_portfolio,
      access_messages: checked || this.prevSelectedAccesses.access_messages,
      write_messages: checked || this.prevSelectedAccesses.write_messages,
      access_files: checked || this.prevSelectedAccesses.access_files,
      write_files: checked || this.prevSelectedAccesses.write_files,
      access_accounts: checked || this.prevSelectedAccesses.access_accounts,
      write_accounts: checked || this.prevSelectedAccesses.write_accounts,
    });
  };

  getChooseAllInitialValue = () => {
    const { form, role } = this.props;
    let chosenFieldsLength = 0;
    const checkFieldNames = [
      'access_investments', 'write_investments', 'access_portfolio', 'write_portfolio',
      'access_messages', 'write_messages', 'access_files', 'write_files'
    ];
    if (role === 'ADMIN') {
      checkFieldNames.push('access_accounts', 'write_accounts');
    }
    checkFieldNames.forEach((fieldName) => {
      if (form.getFieldValue(fieldName)) {
        chosenFieldsLength++;
      }
    });
    return checkFieldNames.length === chosenFieldsLength;
  };

  contentPrintButton = (refType) => (
    <ReactToPrint
      trigger={() => (
        <Button
          title="Print"
          prefix={<Icon type="tools-and-utensils" style={{fontSize: "18px", marginRight: 8}}/>}
          size="lg"
          theme="blue"
          className="radius-10 m_0"
          type="button"
        />
      )}
      pageStyle={`
        @page :left {
          margin-left: 1cm;
        }
        @page :right {
          margin-right: 1cm;
        }
      `}
      content={() => this[refType]}
    />
  );

  componentWillUnmount() {
    const { removeUserData } = this.props;
    removeUserData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { loading, errorMessage, currentUser, getStates } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;

    if (!errorText && !loadingState && prevProps.loading.state) {
      switch (loadingType) {
        case "UpdatePermissions":
          message.success("Access changed successfully");
          break;
        case "SetOnboarded":
        case "UnsetOnboarded":
          message.success("Onboarding status changed successfully");
          break;
        case "GetUser":
          if (currentUser && currentUser.is_entity === 1 && currentUser.entity_country) {
            getStates(currentUser.entity_country);
          }
          break;
        default:
      }
    }
  }

  componentDidMount() {
    const { match, getUser } = this.props;
    const userID = match.params.id;

    getUser(userID);
  }

  render() {
    const { form, currentUser } = this.props;
    const { popupOnboardingVisible, regDataPopupVisible } = this.state;
    const { getFieldDecorator } = form;
    const {
      id,
      prefix,
      first_name,
      last_name,
      avatar,
      email,
      role,
      access_investments,
      access_portfolio,
      access_messages,
      access_files,
      access_accounts,
      write_investments,
      write_portfolio,
      write_messages,
      write_files,
      write_accounts,
      notify_investments,
      notify_reports,
      onboarded,
      random_id,
      isOnboardingDataFilled,
      onboardingData
    } = this.getCurrentUserData();
    const currentUserRegData = this.getCurrentUserRegData();
    const currentBusinessesData = this.getCurrentUserBusinessesData();
    const userAvatar = avatar ? getMediaFileFromServer(avatar) : "";

    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="flex-container align-middle">
                <Button
                  title={<Icon type="key-left" size={16} />}
                  size="md"
                  theme="lightblue"
                  className="radius-10 back_btn"
                  onClick={goBack}
                />
                <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                  <h1>{prefix} {first_name} {last_name}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-justify">
          <div className="column small-12 medium-6">
            <div className="medium-flex-container align-middle align-justify user_panel mb_25">
              {
                userAvatar ?
                  <div className="radius-circle clip user_panel__ava" >
                    <img src={userAvatar} className="fluid-x" alt=""/>
                  </div>
                :
                  <span
                    style={{ width: "137px", height: "137px" }}
                    className="radius-circle clip flex-container align-middle align-center user_ava"
                  >
                    <div
                      className="text font-heavy font-spacing-02 color-blue"
                      style={{ fontSize: "36px" }}
                    >
                      {first_name.charAt(0)}
                    </div>
                  </span>
              }

              <div className="flex-container flex-dir-column" >
                <div>
                  <Button
                    type="button"
                    title="Get registration data"
                    size="sm"
                    theme="lightblue"
                    mode="bordered"
                    prefix={<Icon type="download" style={{marginRight: 10}} />}
                    className="radius-8 mb_10"
                    style={{ float: 'right' }}
                    onClick={this.toggleRegDataModal}
                  />
                </div>
                <div className="flex-container align-middle">
                  <div className="icon_btn user_panel_action">
                    <Button
                      title={<Icon type="letter" style={{ fontSize: "17px" }} />}
                      size="lg"
                      theme="lightblue"
                      className="radius-10 m_0"
                      linkTo={`/contact-center/${id}/${first_name} ${last_name}/user`}
                    />
                  </div>
                  <div className="user_panel_action">
                    <Button
                      type="button"
                      title="Get onboarding data"
                      size="lg"
                      theme="lightblue"
                      mode="bordered"
                      prefix={<Icon type="download" style={{marginRight: 10}} />}
                      className="radius-8"
                      style={{ fontSize: 18 }}
                      onClick={this.toggleOnboardingDataPopup}
                      disabled={!isOnboardingDataFilled}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="fluid-x project_info mb_50">
              <div className="project_data">
                <div className="medium-flex-container align-justify project_data_row dark lg">
                  <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 project_data_col">
                    Name
                  </div>
                  <div className="text text-18 font-bold color-grey project_data_col">
                    { first_name }
                  </div>
                </div>
                <div className="medium-flex-container align-justify project_data_row dark lg">
                  <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 project_data_col">
                    Surname
                  </div>
                  <div className="text text-18 font-bold color-grey project_data_col">
                    { last_name }
                  </div>
                </div>
                <div className="medium-flex-container align-justify project_data_row dark lg">
                  <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 project_data_col">
                    User ID
                  </div>
                  <div className="text text-18 font-bold color-grey project_data_col">
                    { random_id }
                  </div>
                </div>
                <div className="medium-flex-container align-justify project_data_row dark lg">
                  <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 project_data_col">
                    Email Addresss
                  </div>
                  <div className="text text-18 font-bold color-grey project_data_col">
                    { email }
                  </div>
                </div>
                <div className="medium-flex-container align-justify project_data_row dark lg">
                  <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 project_data_col">
                    Status
                  </div>
                  <div className="text text-18 font-bold color-grey project_data_col">
                    { onboarded ? "Onboarded" : "Not onboarded" }
                  </div>
                </div>
              </div>
            </div>

            {
              currentBusinessesData.length ?
                <div>
                  <div className="text text-22 font-bold color-grey mb_30">
                    Opportunities with statuses
                  </div>
                  <div className="row">
                    {
                      currentBusinessesData.map((element, index) => (
                        <div key={index} className="column small-12 large-6">
                          <ListItem
                            id={element.id}
                            saved={element.saved}
                            href={`/project/${element.id}`}
                            title={element.name}
                            desc={element.description}
                            img={element.photo}
                            tag={this.getOpportunityStatusLabel(element)}
                            footer={
                              <>
                                <div className="font-medium">Deadline &nbsp;</div>
                                <div className="font-bold">
                                  {element.deadline}
                                </div>
                              </>
                            }
                          />
                        </div>
                      ))
                    }
                  </div>
                </div>
              :
                null
            }
          </div>
          <div className="column small-12 medium-6 large-5">
            <div className="bg-white radius-10 shadow-layout-active user_access">
              <div className="text text-22 font-bold color-grey mb_25">
                Choose accessibility for investor
              </div>

              <div className="user_access_group relative separator">
                <div className="flex-container" >
                  <div className="view-items-wrapper" >
                    <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_25">
                      View
                    </div>
                    <div className="form-item mb_15">
                      <Form.Item className="mb_0">
                        {getFieldDecorator("access_investments", { // also write_investments
                          valuePropName: "checked",
                          initialValue: access_investments,
                          onChange: this.onCertainAccessChange,
                          rules: [{required: false, message: "Please fill out this field"}]
                        })(
                          <Checkbox>Investment Opportunities</Checkbox>
                        )}
                      </Form.Item>
                    </div>
                    <div className="form-item mb_15">
                      <Form.Item className="mb_0">
                        {getFieldDecorator("access_portfolio", { // also write_portfolio
                          valuePropName: "checked",
                          initialValue: access_portfolio,
                          onChange: this.onCertainAccessChange,
                          rules: [{required: false, message: "Please fill out this field"}]
                        })(
                          <Checkbox>My Portfolio</Checkbox>
                        )}
                      </Form.Item>
                    </div>
                    <div className="form-item mb_15">
                      <Form.Item className="mb_0">
                        {getFieldDecorator("access_messages", { // also write_messages
                          valuePropName: "checked",
                          initialValue: access_messages,
                          onChange: this.onCertainAccessChange,
                          rules: [{required: false, message: "Please fill out this field"}]
                        })(
                          <Checkbox>Contact Center</Checkbox>
                        )}
                      </Form.Item>
                    </div>
                    <div className="form-item mb_15">
                      <Form.Item className="mb_0">
                        {getFieldDecorator("access_files", { // also write_files
                          valuePropName: "checked",
                          initialValue: access_files,
                          onChange: this.onCertainAccessChange,
                          rules: [{required: false, message: "Please fill out this field"}]
                        })(
                          <Checkbox>Data Room</Checkbox>
                        )}
                      </Form.Item>
                    </div>
                    {
                      role === 'ADMIN' &&
                        <div className="form-item mb_15">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("access_accounts", {
                              valuePropName: "checked",
                              initialValue: access_accounts,
                              onChange: this.onCertainAccessChange,
                              rules: [{required: false, message: "Please fill out this field"}]
                            })(
                              <Checkbox>Users</Checkbox>
                            )}
                          </Form.Item>
                        </div>
                    }
                  </div>

                  <div className="edit-items-wrapper" >
                    <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_25">
                      Edit
                    </div>
                    <div className="form-item mb_15">
                      <Form.Item className="mb_0">
                        {getFieldDecorator("write_investments", { // also write_investments
                          valuePropName: "checked",
                          initialValue: write_investments,
                          onChange: this.onCertainAccessChange,
                          rules: [{required: false, message: "Please fill out this field"}]
                        })(
                          <Checkbox>Investment Opportunities</Checkbox>
                        )}
                      </Form.Item>
                    </div>
                    <div className="form-item mb_15">
                      <Form.Item className="mb_0">
                        {getFieldDecorator("write_portfolio", { // write_portfolio
                          valuePropName: "checked",
                          initialValue: write_portfolio,
                          onChange: this.onCertainAccessChange,
                          rules: [{required: false, message: "Please fill out this field"}]
                        })(
                          <Checkbox>My Portfolio</Checkbox>
                        )}
                      </Form.Item>
                    </div>
                    <div className="form-item mb_15">
                      <Form.Item className="mb_0">
                        {getFieldDecorator("write_messages", { // also write_messages
                          valuePropName: "checked",
                          initialValue: write_messages,
                          onChange: this.onCertainAccessChange,
                          rules: [{required: false, message: "Please fill out this field"}]
                        })(
                          <Checkbox>Contact Center</Checkbox>
                        )}
                      </Form.Item>
                    </div>
                    <div className="form-item mb_15">
                      <Form.Item className="mb_0">
                        {getFieldDecorator("write_files", { // also write_files
                          valuePropName: "checked",
                          initialValue: write_files,
                          onChange: this.onCertainAccessChange,
                          rules: [{required: false, message: "Please fill out this field"}]
                        })(
                          <Checkbox>Data Room</Checkbox>
                        )}
                      </Form.Item>
                    </div>
                    {
                      role === 'ADMIN' &&
                        <div className="form-item mb_15">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("write_accounts", {
                              valuePropName: "checked",
                              initialValue: write_accounts,
                              onChange: this.onCertainAccessChange,
                              rules: [{required: false, message: "Please fill out this field"}]
                            })(
                              <Checkbox>Users</Checkbox>
                            )}
                          </Form.Item>
                        </div>
                    }
                  </div>
                </div>

                <div className="form-item">
                  <Form.Item className="mb_0">
                    {getFieldDecorator("choose_all", {
                      valuePropName: "checked",
                      initialValue: this.getChooseAllInitialValue(),
                      onChange: this.onChooseAllChange,
                      rules: [{required: false, message: "Please fill out this field"}]
                    })(
                      <Checkbox>Choose all</Checkbox>
                    )}
                  </Form.Item>
                </div>

                <div>
                  <Form.Item className="mb_0 mt_20">
                    <Button
                      title="Update Access"
                      prefix={<Icon type="update" style={{fontSize: "18px", marginRight: 8}}/>}
                      size="lg"
                      theme="blue"
                      className="radius-10 m_0"
                      type="button"
                      disabled={this.getIsAccessButtonDisabled()}
                      onClick={this.handleAccessOnClick}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="user_access_group relative separator">
                <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_25">
                  Status
                </div>

                <div>
                  <div className="form-item mb_15">
                    <Form.Item className="mb_0">
                      {getFieldDecorator("onboarded", {
                        valuePropName: "checked",
                        initialValue: onboarded,
                        rules: [{required: false, message: "Please fill out this field"}]
                      })(
                        <Checkbox disabled={currentUser?.is_entity === 1 ? false : !isOnboardingDataFilled} >
                          Onboarded
                        </Checkbox>
                      )}
                    </Form.Item>
                  </div>

                  <Form.Item className="mb_0 mt_30">
                    <Button
                      title="Update Access"
                      prefix={<Icon type="update" style={{fontSize: "18px", marginRight: 8}}/>}
                      size="lg"
                      theme="blue"
                      className="radius-10 m_0"
                      type="button"
                      onClick={this.handleStatusOnClick}
                      disabled={currentUser?.is_entity === 1 ? false : !isOnboardingDataFilled}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="user_access_group relative">
                <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_25">
                  Notifications
                </div>

                <div>
                  <div className="flex-container" >
                    <div className="investment-items-wrapper" >
                      <div className="form-item mb_15">
                        <Form.Item className="mb_0">
                          {getFieldDecorator("notify_investments", {
                            valuePropName: "checked",
                            initialValue: notify_investments,
                            rules: [{required: false, message: "Please fill out this field"}]
                          })(
                            <Checkbox>Notify Investments</Checkbox>
                          )}
                        </Form.Item>
                      </div>
                    </div>

                    <div className="report-items-wrapper" >
                      <div className="form-item mb_15">
                        <Form.Item className="mb_0">
                          {getFieldDecorator("notify_reports", {
                            valuePropName: "checked",
                            initialValue: notify_reports,
                            rules: [{required: false, message: "Please fill out this field"}]
                          })(
                            <Checkbox>Notify Reports</Checkbox>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <Form.Item className="mb_0 mt_30">
                    <Button
                      title="Update Access"
                      prefix={<Icon type="update" style={{fontSize: "18px", marginRight: 8}}/>}
                      size="lg"
                      theme="blue"
                      className="radius-10 m_0"
                      type="button"
                      onClick={this.handleNotificationsOnClick}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="bg-white mt_15 radius-10 flex-container align-middle align-justify project_direct_card">
              <div className="flex-container align-middle project_direct_card__title">
                <Icon type="note" size={32} style={{marginRight: 16}} className="color-grey" />
                <div className="text text-18 font-heavy color-grey">
                  Notes
                </div>
              </div>
              <Button
                title='View'
                suffix={
                  <Icon
                    type="key-right"
                    size={14}
                    style={{ marginLeft: 15, fontWeight: 'bold' }}
                  />
                }
                size='lg'
                theme='lightblue'
                style={{ padding: '0 18px' }}
                className='btn-bordered radius-8'
                linkTo={`/notes/${id}`}
              />
            </div>
            <div className="bg-white mt_15 radius-10 flex-container align-middle align-justify project_direct_card">
              <div className="flex-container align-middle project_direct_card__title">
                <Icon type="folders" style={{fontSize: "32px", marginRight: 8}}/>
                <div className="text text-18 font-heavy color-grey">
                  Data room
                </div>
              </div>
              <Button
                title='View'
                suffix={
                  <Icon
                    type="key-right"
                    size={14}
                    style={{ marginLeft: 15, fontWeight: 'bold' }}
                  />
                }
                size='lg'
                theme='lightblue'
                style={{ padding: '0 18px' }}
                className='btn-bordered radius-8'
                linkTo={`/data-room-shared/${id}`}
              />
            </div>
          </div>
        </div>

        {
          popupOnboardingVisible && // for avoiding extra scroll
            <PopUp
              className="relative_parent printing_popup"
              visible={true}
              onCancel={() => this.toggleOnboardingDataPopup(false)}
              innerClassNames="shadow-layout bg-white radius-10 relative lg base"
            >
              <div onClick={() => this.toggleOnboardingDataPopup(false)} className="popup_close color-grey-700">
                <Icon type="cancel"/>
              </div>
              <div>
                <div className="custom-headline flex-container align-justify align-middle text text-22 font-bold color-grey project_info__title">
                  <h2>Onboarding form data</h2>
                  {this.contentPrintButton('onboardingDataRef')}
                </div>

                <div ref={(el) => (this.onboardingDataRef = el)} className="project_data">
                  {
                    Object.entries(onboardingData).map(element => (
                      <div className="medium-flex-container align-justify project_data_row">
                        <div className="text font-medium color-grey project_data_col project_data_col_title">
                          {element[1].title}
                        </div>
                        <div className="text font-bold color-grey project_data_col project_data_col_description">
                          {
                            element[1].isLink ?
                              <a target="_blank" rel="noopener noreferrer" href={getMediaFileFromServer(element[1].link)}>{element[1].text}</a>
                              :
                              <span className={element[1].isRed ? 'unacceptable_col' : ''} >
                                  {element[1].value}
                                {element[1].isRed ? <Icon type='flag-triangle' className='color-red red-flag-icon' size={16} /> : null}
                                </span>
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </PopUp>
        }

        {
          regDataPopupVisible &&
            <PopUp
              className="relative_parent printing_popup"
              visible={true}
              onCancel={this.toggleRegDataModal}
              innerClassNames="shadow-layout bg-white radius-10 relative lg base"
            >
              <div onClick={this.toggleRegDataModal} className="popup_close color-grey-700">
                <Icon type="cancel"/>
              </div>
              <div>
                <div className="custom-headline flex-container align-justify align-middle text text-22 font-bold color-grey project_info__title">
                  <h2>Registration form data</h2>
                  {this.contentPrintButton('registrationDataRef')}
                </div>

                <div ref={(el) => (this.registrationDataRef = el)} className="project_data">
                  {
                    Object.keys(currentUserRegData).map(element => (
                      <div className="medium-flex-container align-justify project_data_row">
                        <div className="text font-medium color-grey project_data_col project_data_col_title">
                          {currentUserRegData[element].title}
                        </div>
                        {
                          !currentUserRegData[element].isLink ?
                            <div className="text font-bold color-grey project_data_col project_data_col_description">
                              <span className={currentUserRegData[element].isRed ? 'unacceptable_col' : ''} >
                                {currentUserRegData[element].value}
                                {currentUserRegData[element].isRed ? <Icon type='flag-triangle' className='color-red red-flag-icon' size={16}/> : null}
                              </span>
                            </div>
                          :
                            <a target="_blank" rel="noopener noreferrer" href={currentUserRegData[element].value}>{currentUserRegData[element].value}</a>
                        }

                      </div>
                    ))
                  }
                </div>
              </div>
            </PopUp>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  xID: state.user.xID,
  loading: state.users.loading,
  errorMessage: state.users.errorMessage,
  currentUser: state.users.currentUser,
  currentUserBusinesses: state.users.currentUserBusinesses,
  opportunityGroups: state.dictionaries.opportunityGroups,
  countries: state.dictionaries.countries,
  states: state.dictionaries.states,
});

const mapDispatchToProps = dispatch => ({
  getUser: userID => dispatch(getUser(userID)),
  removeUserData: () => dispatch(removeUserData()),
  updatePermissions: params => dispatch(updatePermissions(params)),
  updateSettings: params => dispatch(updateSettings(params)),
  setOnboarded: id => dispatch(setOnboarded(id)),
  unsetOnboarded: id => dispatch(unsetOnboarded(id)),
  getStates: countryID => dispatch(getStates(countryID)),
});

const AdminUserInnerScreen = Form.create({name: "adminUserInner"})(AdminUserInner);

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserInnerScreen);
