import produce from 'immer';
import {
  ADD_NOTE_START, ADD_NOTE_SUCCESS, ADD_NOTE_FAIL,
  UPDATE_NOTE_START, UPDATE_NOTE_SUCCESS, UPDATE_NOTE_FAIL,
  REMOVE_NOTE_START, REMOVE_NOTE_SUCCESS, REMOVE_NOTE_FAIL,
  GET_NOTES_START, GET_NOTES_SUCCESS, GET_NOTES_FAIL,
} from '../constants';

const initialState = {
  loading: { type: "", state: false },
  errorMessage: { type: "", message: "" },
  currentPage: 1,
  notesCount: 0,
  notes: [],
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTE_START:
      return produce(state, draft => {
        draft.loading.type = "AddNote";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ADD_NOTE_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentPage = action.payload.currentPage;
        draft.notesCount = action.payload.notesCount;
        draft.notes = action.payload.notes;
      });
    case ADD_NOTE_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "AddNote";
        draft.errorMessage.message = action.payload;
      });
    case UPDATE_NOTE_START:
      return produce(state, draft => {
        draft.loading.type = "UpdateNote";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case UPDATE_NOTE_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentPage = action.payload.currentPage;
        draft.notesCount = action.payload.notesCount;
        draft.notes = action.payload.notes;
      });
    case UPDATE_NOTE_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "UpdateNote";
        draft.errorMessage.message = action.payload;
      });
    case REMOVE_NOTE_START:
      return produce(state, draft => {
        draft.loading.type = "RemoveNote";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case REMOVE_NOTE_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentPage = action.payload.currentPage;
        draft.notesCount = action.payload.notesCount;
        draft.notes = action.payload.notes;
      });
    case REMOVE_NOTE_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "RemoveNote";
        draft.errorMessage.message = action.payload;
      });
    case GET_NOTES_START:
      return produce(state, draft => {
        draft.loading.type = "GetNotes";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_NOTES_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentPage = action.payload.currentPage;
        draft.notesCount = action.payload.notesCount;
        draft.notes = action.payload.notes;
      });
    case GET_NOTES_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetNotes";
        draft.errorMessage.message = action.payload;
      });
    default:
      return state;
  }
};

export default notesReducer;
