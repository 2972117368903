import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Form, Input, Row, Select, Slider } from 'antd';

import Filter from "../../../layouts/Filter/Filter";
import Button from "../../../components/Button/Button";
import { filterOption } from "../../../utils/filterOption";
import { handleLoading } from "../../../utils/handleLoading";
import { searchUsers } from "../../../redux/users/thunks/searchUsersThunk";
import { getOptions } from '../../../redux/dictionaries/thunks/dictionariesOptionsThunk';
import { setUsersTablePagination } from '../../../redux/users/actions/tableActions';
import { sortOptionsAlphabetically } from '../../../utils/sortOptionsAlphabetically';
import { getIntShortVersion } from '../../../utils/getIntShortVersion';

const { Option } = Select;

const sliderOptions = {
  range: true,
  min: 50000,
  step: 50000,
  max: 1000000,
  tipFormatter: function(value) {
    return getIntShortVersion(value);
  }
}

class UsersFilter extends Component {
  constructor(props) {
    super(props);
    this.FILTER_ITEMS = [
      {
        key: 'first_name',
        title: 'First name',
        type: 'input',
        Component: () => <Input autoComplete='off' />,
      },
      {
        key: 'last_name',
        title: 'Last name',
        type: 'input',
        Component: () => <Input autoComplete='off' />,
      },
      {
        key: 'country',
        title: 'Country',
        type: 'select',
        Component: ({ options }) => (
          <Select
            showSearch
            filterOption={filterOption}
            allowClear
            style={{ width: "100%" }}
          >
            {options.map((element) => <Option key={element.id} value={element.id}>{element.name}</Option>)}
          </Select>
        ),
      },
      {
        key: 'future_board_role',
        title: '',
        type: 'checkbox',
        valuePropName: 'checked',
        Component: () => (
          <Checkbox
            className="flex-container align-middle"
          >
            Interested in an operating or board role in private companies.
          </Checkbox>
        ),
      },
      {
        key: 'investment',
        title: 'Total amount considering to invest.',
        type: 'slider',
        initialValue: [],
        Component: () => (
          <Slider {...sliderOptions} />
        ),
      },
      {
        key: 'options',
        title: 'Sector preferences.',
        type: 'checkboxGroup',
        Component: ({ options }) => (
          <Checkbox.Group className="columns-2" >
            <Row className="flex-container flex-dir-column" >
              {options.slice(0, options.length / 2 + 1).map((option, index) => (
                <Checkbox key={index} value={option.id} disabled={!option.enabled}> {option.name} </Checkbox>
              ))}
            </Row>
            <Row className="flex-container flex-dir-column" >
              {options.slice(options.length / 2 + 1, options.length).map((option, index) => (
                <Checkbox key={index} value={option.id} disabled={!option.enabled}> {option.name} </Checkbox>
              ))}
            </Row>
          </Checkbox.Group>
        ),
      },
    ];
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { searchUsers, setUsersTablePagination } = this.props;
        let investment_to = '';
        if(values.investment) {
          investment_to = values.investment[1] === sliderOptions.max ? '' : values.investment[1];
        }
        
        const filters = {
          first_name: values.first_name || '',
          last_name: values.last_name || '',
          country: values.country || '',
          investment_from: values.investment ? values.investment[0] : '',
          investment_to: investment_to,
          future_board_role: values.future_board_role ? 1 : 0,
          options: values.options || '',
        };
        searchUsers(1, filters);
        setUsersTablePagination(1);
      }
    });
  };

  handleResetAllFilters = () => {
    const { form, searchUsers } = this.props;
    const filters = {
      first_name: '',
      last_name: '',
      country: '',
      investment_from: '',
      investment_to: '',
      future_board_role: '',
      options: '',
    };

    searchUsers(1, filters);
    setUsersTablePagination(1);
    form.resetFields();
  };

  componentDidMount() {
    const { getOptions } = this.props;
    getOptions(5, 'public');
  }

  render() {
    const { form, countries, loading, optionsList } = this.props;
    const { getFieldDecorator } = form;

    const sortedOptions = sortOptionsAlphabetically(optionsList ? optionsList[0].options : [], true)

    return (
      <Filter>
        <div className='row' >
          <div className='column small-12 xx-large-11 xxx-large-9' >
            <Form onSubmit={this.onSubmit} className='row expanded' >
              {
                this.FILTER_ITEMS.map(({ key, title, Component, type, ...rest }) => (
                  <div key={key} className={`column small-12 ${key !== 'options' ? 'large-4' : ''} form-item form-item-white`} >
                    <div className='form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04' >
                      {title}
                    </div>
                    <Form.Item className='mb_0' >
                      {getFieldDecorator(key, {
                        ...rest,
                        rules: [{ required: false, message: 'Please fill out this field' }],
                      })(
                        Component(
                          key === 'country'
                          ? { options: countries || [] }
                          : key === 'options'
                          ? { options: sortedOptions }
                          : null,
                        )
                      )}
                    </Form.Item>
                    {type === 'slider'
                      ? <div className="flex-container align-middle align-justify">
                        <div className="text text-10 font-medium font-spacing-02 color-grey-900">
                          $50K
                        </div>
                        <div className="text text-10 font-medium font-spacing-02 color-grey-900">
                          $1M+
                        </div>
                      </div>
                      : ''}
                  </div>
                ))
              }
              <div className='column small-12' >
                <div className='flex-container align-middle filter_submit' >
                  <Form.Item className='mb_0' >
                    <Button
                      title='Apply filter'
                      size='lg'
                      theme='blue'
                      className='btn-shadow radius-8'
                      type='submit'
                      loading={handleLoading(loading, 'SearchUsers')}
                    />
                  </Form.Item>
                  <Form.Item className='mb_0'>
                    <Button
                      title='Reset all filters'
                      size='lg'
                      theme='default'
                      className='btn-link font-uppercase'
                      type='button'
                      onClick={this.handleResetAllFilters}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Filter>
    );
  }
}

const UsersFilterComponent = Form.create()(UsersFilter);

const mapStateToProps = (state) => ({
  loading: state.users.loading,
  pagination: state.users.pagination,
  countries: state.dictionaries.countries,
  optionsList: state.dictionaries.optionsList,
});

const mapDispatchToProps = (dispatch) => ({
  searchUsers: (currentPage, filter) => dispatch(searchUsers(currentPage, filter)),
  getOptions: (optionGroup, type) => dispatch(getOptions(optionGroup, type)),
  setUsersTablePagination: (currentPage) => dispatch(setUsersTablePagination(currentPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersFilterComponent);
