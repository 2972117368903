import { combineReducers } from "redux";
import registrationReducer from "./reducers/registrationReducer";
import userReducer from "./reducers/userReducer";
import usersReducer from "./reducers/usersReducer";
import tagsReducer from "./reducers/tagsReducer";
import notesReducer from "./reducers/notesReducer";
import businessReducer from "./reducers/businessReducer";
import uiReducer from "./reducers/uiReducer";
import dictionariesReducer from "./reducers/dictionariesReducer";
import messagesReducer from "./reducers/messagesReducer";
import dataRoomReducer from "./reducers/dataRoomReducer";
import reportsReducer from "./reducers/reportsReducer";
import offersReducer from "./reducers/offersReducer";

export default combineReducers({
  ui: uiReducer,
  dictionaries: dictionariesReducer,
  registration: registrationReducer,
  user: userReducer,
  users: usersReducer,
  tags: tagsReducer,
  notes: notesReducer,
  business: businessReducer,
  messages: messagesReducer,
  dataRoom: dataRoomReducer,
  reports: reportsReducer,
  offers: offersReducer,
});
