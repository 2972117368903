import {
  GET_BUSINESS_START, GET_BUSINESS_SUCCESS, GET_BUSINESS_FAIL,
  REMOVE_CURRENT_BUSINESS, SET_BUSINESS_FILTER,
  GET_BUSINESSES_START, GET_BUSINESSES_SUCCESS, GET_BUSINESSES_FAIL,
  GET_PURSUING_BUSINESSES_START, GET_PURSUING_BUSINESSES_SUCCESS, GET_PURSUING_BUSINESSES_FAIL,
  GET_BUSINESSES_BY_NAME_START, GET_BUSINESSES_BY_NAME_SUCCESS, GET_BUSINESSES_BY_NAME_FAIL,
  SET_SAVED_START, SET_SAVED_SUCCESS, SET_SAVED_FAIL,
  SET_INTERESTED_START, SET_INTERESTED_SUCCESS, SET_INTERESTED_FAIL,
  READY_TO_COMMIT_START, READY_TO_COMMIT_SUCCESS, READY_TO_COMMIT_FAIL,
  READY_TO_CLOSE_START, READY_TO_CLOSE_SUCCESS, READY_TO_CLOSE_FAIL,
  READY_TO_START_START, READY_TO_START_SUCCESS, READY_TO_START_FAIL,
  SEARCH_BUSINESS_USERS_START, SEARCH_BUSINESS_USERS_SUCCESS, SEARCH_BUSINESS_USERS_FAIL,
  ALLOW_COMMIT_START, ALLOW_COMMIT_SUCCESS, ALLOW_COMMIT_FAIL,
  ALLOW_CLOSE_START, ALLOW_CLOSE_SUCCESS, ALLOW_CLOSE_FAIL,
  CLOSE_START, CLOSE_SUCCESS, CLOSE_FAIL,
  REOPEN_START, REOPEN_SUCCESS, REOPEN_FAIL,
  SET_ACTIVE_BUSINESS_TAB_KEY, RESET_BUSINESS_FILTER,
  SET_INVESTMENT_INFO_START, SET_INVESTMENT_INFO_SUCCESS, SET_INVESTMENT_INFO_FAIL,
  SET_BUSINESS_EXPIRED_START, SET_BUSINESS_EXPIRED_SUCCESS, SET_BUSINESS_EXPIRED_FAIL, GET_BUSINESSES_SUMMARY_START, GET_BUSINESSESS_SUMMARY_SUCCESS, GET_BUSINESSESS_SUMMARY_FAIL, SET_PEGATE_SHAREHOLDING_START, SET_PEGATE_SHAREHOLDING_SUCCESS, SET_PEGATE_SHAREHOLDING_FAIL,
  REMOVE_BUSINESS_INVESTOR_START, REMOVE_BUSINESS_INVESTOR_SUCCESS, REMOVE_BUSINESS_INVESTOR_FAIL, RESET_BUSINESS_LOADING, SEARCH_BUSINESS_USERS_BIDS_START, SEARCH_BUSINESS_USERS_BIDS_SUCCESS, SEARCH_BUSINESS_USERS_BIDS_FAIL,
  SEARCH_BUSINESS_INVOLVE_REQUESTS_SUCCESS,
  SEARCH_BUSINESS_INVOLVE_REQUESTS_FAIL,
  SEARCH_BUSINESS_INVOLVE_REQUESTS_START,
  ADD_INVOLVE_REQUESTS_START,
  ADD_INVOLVE_REQUESTS_SUCCESS,
  ADD_INVOLVE_REQUESTS_FAIL,
  GET_BUSINESS_INVOLVE_REQUEST_SUCCESS,
  GET_BUSINESS_INVOLVE_REQUEST_FAIL,
  GET_BUSINESS_INVOLVE_REQUEST_START,
  ACCEPT_INVOLVE_REQUEST_START,
  ACCEPT_INVOLVE_REQUEST_SUCCESS,
  ACCEPT_INVOLVE_REQUEST_FAIL,
  REJECT_INVOLVE_REQUEST_START,
  REJECT_INVOLVE_REQUEST_SUCCESS,
  REJECT_INVOLVE_REQUEST_FAIL,
  RESET_INVOLVE_REQUEST_DATA,
} from "../../constants";

export const resetBusinessLoading = () => ({
  type: RESET_BUSINESS_LOADING,
});

export const getBusinessStart = () => ({
  type: GET_BUSINESS_START
});

export const getBusinessSuccess = payload => ({
  type: GET_BUSINESS_SUCCESS,
  payload
});

export const getBusinessFail = payload => ({
  type: GET_BUSINESS_FAIL,
  payload
});

export const getBusinessInvolveRequestStart = () => ({
  type: GET_BUSINESS_INVOLVE_REQUEST_START
});

export const getBusinessInvolveRequestSuccess = payload => ({
  type: GET_BUSINESS_INVOLVE_REQUEST_SUCCESS,
  payload
});

export const getBusinessInvolveRequestFail = payload => ({
  type: GET_BUSINESS_INVOLVE_REQUEST_FAIL,
  payload
});

export const getPursuingBusinessesStart = () => ({
  type: GET_PURSUING_BUSINESSES_START,
});

export const resetInvolveRequestData = () => ({
  type: RESET_INVOLVE_REQUEST_DATA,
});

export const getPursuingBusinessesSuccess = (payload) => ({
  type: GET_PURSUING_BUSINESSES_SUCCESS,
  payload,
});

export const getPursuingBusinessesFail = (payload) => ({
  type: GET_PURSUING_BUSINESSES_FAIL,
  payload,
});

export const removeCurrentBusiness = () => ({
  type: REMOVE_CURRENT_BUSINESS
});

export const resetBusinessFilter = () => ({ type: RESET_BUSINESS_FILTER });

export const setBusinessFilter = (payload) => ({
  type: SET_BUSINESS_FILTER,
  payload,
});

export const getBusinessesStart = () => ({
  type: GET_BUSINESSES_START
});

export const getBusinessesSuccess = payload => ({
  type: GET_BUSINESSES_SUCCESS,
  payload
});

export const getBusinessesFail = payload => ({
  type: GET_BUSINESSES_FAIL,
  payload
});

export const getBusinessesSummaryStart = () => ({
  type: GET_BUSINESSES_SUMMARY_START
});

export const getBusinessesSummarySuccess = payload => ({
  type: GET_BUSINESSESS_SUMMARY_SUCCESS,
  payload
});

export const getBusinessesSummaryFail = payload => ({
  type: GET_BUSINESSESS_SUMMARY_FAIL,
  payload
});

export const getBusinessesByNameStart = () => ({
  type: GET_BUSINESSES_BY_NAME_START
});

export const getBusinessesByNameSuccess = payload => ({
  type: GET_BUSINESSES_BY_NAME_SUCCESS,
  payload
});

export const getBusinessesByNameFail = payload => ({
  type: GET_BUSINESSES_BY_NAME_FAIL,
  payload
});

export const setSavedStart = () => ({
  type: SET_SAVED_START,
});

export const setSavedSuccess = (payload) => ({
  type: SET_SAVED_SUCCESS,
  payload,
});

export const setSavedFail = (payload) => ({
  type: SET_SAVED_FAIL,
  payload,
});

export const setInterestedStart = () => ({
  type: SET_INTERESTED_START
});

export const setInterestedSuccess = payload => ({
  type: SET_INTERESTED_SUCCESS,
  payload
});

export const setInterestedFail = payload => ({
  type: SET_INTERESTED_FAIL,
  payload
});

export const readyToStartStart = () => ({
  type: READY_TO_START_START
});

export const readyToStartSuccess = payload => ({
  type: READY_TO_START_SUCCESS,
  payload
});

export const readyToStartFail = payload => ({
  type: READY_TO_START_FAIL,
  payload
});

export const readyToCommitStart = () => ({
  type: READY_TO_COMMIT_START
});

export const readyToCommitSuccess = payload => ({
  type: READY_TO_COMMIT_SUCCESS,
  payload
});

export const readyToCommitFail = payload => ({
  type: READY_TO_COMMIT_FAIL,
  payload
});

export const readyToCloseStart = () => ({
  type: READY_TO_CLOSE_START
});

export const readyToCloseSuccess = payload => ({
  type: READY_TO_CLOSE_SUCCESS,
  payload
});

export const readyToCloseFail = payload => ({
  type: READY_TO_CLOSE_FAIL,
  payload
});

export const searchBusinessUsersStart = () => ({
  type: SEARCH_BUSINESS_USERS_START
});

export const searchBusinessUsersSuccess = payload => ({
  type: SEARCH_BUSINESS_USERS_SUCCESS,
  payload
});

export const searchBusinessUsersFail = payload => ({
  type: SEARCH_BUSINESS_USERS_FAIL,
  payload
});

export const searchBusinessInvolveRequestsStart = () => ({
  type: SEARCH_BUSINESS_INVOLVE_REQUESTS_START
});

export const searchBusinessInvolveRequestsSuccess = payload => ({
  type: SEARCH_BUSINESS_INVOLVE_REQUESTS_SUCCESS,
  payload
});

export const searchBusinessInvolveRequestsFail = payload => ({
  type: SEARCH_BUSINESS_INVOLVE_REQUESTS_FAIL,
  payload
});

export const addInvolveRequestStart = () => ({
  type: ADD_INVOLVE_REQUESTS_START
});

export const addInvolveRequestSuccess = payload => ({
  type: ADD_INVOLVE_REQUESTS_SUCCESS,
  payload
});

export const addInvolveRequestFail = payload => ({
  type: ADD_INVOLVE_REQUESTS_FAIL,
  payload
});

export const acceptInvolveRequestStart = () => ({
  type: ACCEPT_INVOLVE_REQUEST_START
});

export const acceptInvolveRequestSuccess = payload => ({
  type: ACCEPT_INVOLVE_REQUEST_SUCCESS,
  payload
});

export const acceptInvolveRequestFail = payload => ({
  type: ACCEPT_INVOLVE_REQUEST_FAIL,
  payload
});

export const rejectInvolveRequestStart = () => ({
  type: REJECT_INVOLVE_REQUEST_START
});

export const rejectInvolveRequestSuccess = payload => ({
  type: REJECT_INVOLVE_REQUEST_SUCCESS,
  payload
});

export const rejectInvolveRequestFail = payload => ({
  type: REJECT_INVOLVE_REQUEST_FAIL,
  payload
});

export const allowCommitStart = () => ({
  type: ALLOW_COMMIT_START
});

export const allowCommitSuccess = payload => ({
  type: ALLOW_COMMIT_SUCCESS,
  payload
});

export const allowCommitFail = payload => ({
  type: ALLOW_COMMIT_FAIL,
  payload
});

export const allowCloseStart = () => ({
  type: ALLOW_CLOSE_START
});

export const allowCloseSuccess = payload => ({
  type: ALLOW_CLOSE_SUCCESS,
  payload
});

export const allowCloseFail = payload => ({
  type: ALLOW_CLOSE_FAIL,
  payload
});

export const closeStart = () => ({
  type: CLOSE_START
});

export const closeSuccess = payload => ({
  type: CLOSE_SUCCESS,
  payload
});

export const closeFail = payload => ({
  type: CLOSE_FAIL,
  payload
});

export const reopenStart = () => ({ type: REOPEN_START });

export const reopenSuccess = payload => ({
  type: REOPEN_SUCCESS,
  payload,
});

export const reopenFail = payload => ({
  type: REOPEN_FAIL,
  payload,
});

export const setActiveBusinessTabKey = payload => ({
  type: SET_ACTIVE_BUSINESS_TAB_KEY,
  payload
});

export const setInvestmentInfoStart = () => ({
  type: SET_INVESTMENT_INFO_START,
});

export const setInvestmentInfoSuccess = (payload) => ({
  type: SET_INVESTMENT_INFO_SUCCESS,
  payload,
});

export const setInvestmentInfoFail = (payload) => ({
  type: SET_INVESTMENT_INFO_FAIL,
  payload,
});

export const SetPegateShareholdingStart = () => ({
  type: SET_PEGATE_SHAREHOLDING_START
});

export const SetPegateShareholdingSuccess = payload => ({
  type: SET_PEGATE_SHAREHOLDING_SUCCESS,
  payload
});

export const SetPegateShareholdingFail = payload => ({
  type: SET_PEGATE_SHAREHOLDING_FAIL,
  payload
});

export const removeBusinessInvestorStart = () => ({
  type: REMOVE_BUSINESS_INVESTOR_START
});

export const removeBusinessInvestorSuccess = payload => ({
  type: REMOVE_BUSINESS_INVESTOR_SUCCESS,
  payload
});

export const removeBusinessInvestorFail = payload => ({
  type: REMOVE_BUSINESS_INVESTOR_FAIL,
  payload
});

export const searchBusinessUserBidsStart = () => ({
  type: SEARCH_BUSINESS_USERS_BIDS_START
});

export const searchBusinessUserBidsSuccess = payload => ({
  type: SEARCH_BUSINESS_USERS_BIDS_SUCCESS,
  payload
});

export const searchBusinessUserBidsFail = payload => ({
  type: SEARCH_BUSINESS_USERS_BIDS_FAIL,
  payload
});

export const setBusinessExpiredStart = () => ({ type: SET_BUSINESS_EXPIRED_START });

export const setBusinessExpiredSuccess = (payload) => ({ type: SET_BUSINESS_EXPIRED_SUCCESS, payload });

export const setBusinessExpiredFail = (payload) => ({ type: SET_BUSINESS_EXPIRED_FAIL, payload });
