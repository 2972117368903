import {
  getReportStart, getReportSuccess, getReportFail,
  getNewReportStart, getNewReportSuccess, getNewReportFail,
  getClosedReportsStart, getClosedReportsSuccess, getClosedReportsFail,
  addReportStart, addReportSuccess, addReportFail,
  addStatsStart, addStatsSuccess, addStatsFail, getBusinessReportsSuccess, getBusinessReportsFail, getBusinessReportsStart,
  getTransactionsStart, getTransactionsFail, getTransactionsSuccess,
} from "../actions/reportsActions";
import CommonUtils from "../../../utils/CommonUtils";

export const getReport = (investor, currency) => ((dispatch, getState) => {
  dispatch(getReportStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `shared/getStats/${investor}/${currency}`, {}, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(getReportSuccess({
          reports: response.data,
          selectedCurrency: currency,
          isFromCurrency: currency !== 'USD',
        }));
      }
      else {
        dispatch(getReportFail(response.data.error));
      }
    })
    .catch(() => {
      dispatch(getReportFail("Error"));
    });
});

export const getBusinessReport = (investor, currency, businessID) => ((dispatch, getState) => {
  dispatch(getBusinessReportsStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `shared/getStats/${investor}/${currency}`, {}, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(getBusinessReportsSuccess({
          businessReports: response.data.find((i) => +i.id === +businessID).reports,
        }));
      }
      else {
        dispatch(getBusinessReportsFail(response.data.error));
      }
    })
    .catch(() => {
      dispatch(getBusinessReportsFail("Error"));
    });
});

export const getNewReport = (business, currency) => ((dispatch, getState) => {
  dispatch(getNewReportStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `shared/getReport/${business}/${currency}`, {}, config)
    .then(response => {
      if (Object.entries(response.data).length) {
        dispatch(getNewReportSuccess({
          newReports: response.data,
        }));
      } else {
        dispatch(getNewReportFail("Error"));
      }
    })
    .catch(() => dispatch(getNewReportFail("Error")));
});

export const getClosedReports = (currency) => ((dispatch, getState) => {
  dispatch(getClosedReportsStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `investor/getReports/${currency}`, {}, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(getClosedReportsSuccess({
          closedReports: response.data,
        }));
      } else {
        dispatch(getClosedReportsFail("Error"));
      }
    })
    .catch(() => dispatch(getClosedReportsFail("Error")));
});

export const getTransactions = (params) => ((dispatch, getState) => {
  dispatch(getTransactionsStart());

  const {xID, xTOKEN} = getState().user;
  const defaultPageSize = 10;
  const current = params?.current * defaultPageSize || 0;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `investor/getTransactions/0/${defaultPageSize}?cnt`, {}, config)
    .then(cntResponse => {
      if (cntResponse.data.data !== null) {
        CommonUtils.apiCall("get", "private", `investor/getTransactions/${current}/${current + defaultPageSize}`, {}, config)
          .then(response => {
            if (Array.isArray(response.data)) {
              dispatch(getTransactionsSuccess({
                transactions: response.data,
                transactionsCount: cntResponse.data
              }));
            } else {
              dispatch(getTransactionsFail("Error"));
            }
          })
          .catch(() => dispatch(getTransactionsFail("Error")));
      } else {
        dispatch(getTransactionsFail("Error"));
      }
    })
    .catch(() => dispatch(getTransactionsFail("Error")));  
});

export const addReport = (params, currency) => ((dispatch, getState) => {
  dispatch(addReportStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "shared/addReport", params, config)
    .then(response => {
      if (response.data.success) {
        CommonUtils.apiCall("get", "private", `shared/getReport/${params.business}/${currency}`, {}, config)
          .then(reportsResponse => {
            if (Object.entries(reportsResponse.data).length !== 0) {
              dispatch(addReportSuccess({
                reports: reportsResponse.data,
              }));
            }
            else {
              dispatch(addReportFail("Error"));
            }
          })
          .catch(() => dispatch(addReportFail("Error")));
      }
    })
    .catch(() => dispatch(addReportFail("Error")));
});

export const addStats = (params, currency) => ((dispatch, getState) => {
  dispatch(addStatsStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "shared/addStats", params, config)
    .then(response => {
      if (response.data.success) {
        CommonUtils.apiCall("get", "private", `shared/getStats/${params.investor}/${currency}`, {}, config)
          .then(statsResponse => {
            if (Array.isArray(statsResponse.data)) {
              dispatch(addStatsSuccess({
                reports: statsResponse.data,
              }));
            } else {
              dispatch(addStatsFail("Error"));
            }
          })
          .catch(() => dispatch(addStatsFail("Error")));
      }
    })
    .catch(() => dispatch(addStatsFail("Error")));
});
