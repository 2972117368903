import {
  SET_DATA_ROOM_FILTER, RESET_DATA_ROOM_FILTER, REMOVE_DATA_ROOM_ITEMS,
  GET_VIRTUAL_DATA_ROOM_ITEMS_START, GET_VIRTUAL_DATA_ROOM_ITEMS_SUCCESS, GET_VIRTUAL_DATA_ROOM_ITEMS_FAIL,
  GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_START, GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_SUCCESS, GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_FAIL,
  GET_DATA_ROOM_ITEMS_START, GET_DATA_ROOM_ITEMS_SUCCESS, GET_DATA_ROOM_ITEMS_FAIL,
  GET_DATA_ROOM_INNER_ITEMS_START, GET_DATA_ROOM_INNER_ITEMS_SUCCESS, GET_DATA_ROOM_INNER_ITEMS_FAIL,
  ADD_DATA_ROOM_ITEM_START, ADD_DATA_ROOM_ITEM_SUCCESS, ADD_DATA_ROOM_ITEM_FAIL,
  SET_DATA_ROOM_ITEM_ACCESS_TYPE_START, SET_DATA_ROOM_ITEM_ACCESS_TYPE_SUCCESS, SET_DATA_ROOM_ITEM_ACCESS_TYPE_FAIL,
  REMOVE_DATA_ROOM_ITEM_START, REMOVE_DATA_ROOM_ITEM_SUCCESS, REMOVE_DATA_ROOM_ITEM_FAIL,
  RENAME_DATA_ROOM_ITEM_START,RENAME_DATA_ROOM_ITEM_SUCCESS, RENAME_DATA_ROOM_ITEM_FAIL,
  DOWNLOAD_DATA_ROOM_ITEM_START, DOWNLOAD_DATA_ROOM_ITEM_SUCCESS, DOWNLOAD_DATA_ROOM_ITEM_FAIL,
  ADD_ACCESS_TO_DATA_ROOM_ITEM_START,ADD_ACCESS_TO_DATA_ROOM_ITEM_SUCCESS, ADD_ACCESS_TO_DATA_ROOM_ITEM_FAIL,
  REMOVE_ACCESS_TO_DATA_ROOM_ITEM_START, REMOVE_ACCESS_TO_DATA_ROOM_ITEM_SUCCESS, REMOVE_ACCESS_TO_DATA_ROOM_ITEM_FAIL,
  SEARCH_BUSINESS_ROOM_USERS_START, SEARCH_BUSINESS_ROOM_USERS_SUCCESS, SEARCH_BUSINESS_ROOM_USERS_FAIL,
  SEARCH_ROOM_USERS_START, SEARCH_ROOM_USERS_SUCCESS, SEARCH_ROOM_USERS_FAIL, SET_USERS_SEARCH_ROOM_PAGINATION,
  GET_SHARED_DATA_ROOM_ITEMS_START, GET_SHARED_DATA_ROOM_ITEMS_SUCCESS, GET_SHARED_DATA_ROOM_ITEMS_FAIL,
} from "../../constants";

export const getVirtualDataRoomItemsStart = () => ({
  type: GET_VIRTUAL_DATA_ROOM_ITEMS_START,
});

export const getVirtualDataRoomItemsSuccess = (payload) => ({
  type: GET_VIRTUAL_DATA_ROOM_ITEMS_SUCCESS,
  payload,
});

export const getVirtualDataRoomItemsFail = (payload) => ({
  type: GET_VIRTUAL_DATA_ROOM_ITEMS_FAIL,
  payload,
});

export const getVirtualDataRoomItemsDashboardStart = () => ({ type: GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_START });

export const getVirtualDataRoomItemsDashboardSuccess = (payload) => ({ type: GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_SUCCESS, payload });

export const getVirtualDataRoomItemsDashboardFail = (payload) => ({ type: GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_FAIL, payload });

export const setDataRoomFilter = (payload) => ({
  type: SET_DATA_ROOM_FILTER,
  payload,
});

export const resetDataRoomFilter = () => ({ type: RESET_DATA_ROOM_FILTER });

export const removeDataRoomItems = () => ({ type: REMOVE_DATA_ROOM_ITEMS });

export const getDataRoomItemsStart = () => ({
  type: GET_DATA_ROOM_ITEMS_START
});

export const getDataRoomItemsSuccess = payload => ({
  type: GET_DATA_ROOM_ITEMS_SUCCESS,
  payload
});

export const getDataRoomItemsFail = payload => ({
  type: GET_DATA_ROOM_ITEMS_FAIL,
  payload
});

export const getDataRoomInnerItemsStart = () => ({
  type: GET_DATA_ROOM_INNER_ITEMS_START
});

export const getDataRoomInnerItemsSuccess = payload => ({
  type: GET_DATA_ROOM_INNER_ITEMS_SUCCESS,
  payload
});

export const getDataRoomInnerItemsFail = payload => ({
  type: GET_DATA_ROOM_INNER_ITEMS_FAIL,
  payload
});

export const addDataRoomItemStart = () => ({
  type: ADD_DATA_ROOM_ITEM_START
});

export const addDataRoomItemSuccess = payload => ({
  type: ADD_DATA_ROOM_ITEM_SUCCESS,
  payload
});

export const addDataRoomItemFail = payload => ({
  type: ADD_DATA_ROOM_ITEM_FAIL,
  payload
});

export const setDataRoomItemAccessTypeStart = () => ({
  type: SET_DATA_ROOM_ITEM_ACCESS_TYPE_START,
});

export const setDataRoomItemAccessTypeSuccess = payload => ({
  type: SET_DATA_ROOM_ITEM_ACCESS_TYPE_SUCCESS,
  payload,
});

export const setDataRoomItemAccessTypeFail = payload => ({
  type: SET_DATA_ROOM_ITEM_ACCESS_TYPE_FAIL,
  payload,
});

export const removeDataRoomItemStart = () => ({
  type: REMOVE_DATA_ROOM_ITEM_START
});

export const removeDataRoomItemSuccess = payload => ({
  type: REMOVE_DATA_ROOM_ITEM_SUCCESS,
  payload
});

export const removeDataRoomItemFail = payload => ({
  type: REMOVE_DATA_ROOM_ITEM_FAIL,
  payload
});

export const renameDataRoomItemStart = () => ({
  type: RENAME_DATA_ROOM_ITEM_START
});

export const renameDataRoomItemSuccess = payload => ({
  type: RENAME_DATA_ROOM_ITEM_SUCCESS,
  payload
});

export const renameDataRoomItemFail = payload => ({
  type: RENAME_DATA_ROOM_ITEM_FAIL,
  payload
});

export const downloadDataRoomItemStart = () => ({
  type: DOWNLOAD_DATA_ROOM_ITEM_START
});

export const downloadDataRoomItemSuccess = payload => ({
  type: DOWNLOAD_DATA_ROOM_ITEM_SUCCESS,
  payload
});

export const downloadDataRoomItemFail = payload => ({
  type: DOWNLOAD_DATA_ROOM_ITEM_FAIL,
  payload
});

export const addAccessToDataRoomItemStart = () => ({
  type: ADD_ACCESS_TO_DATA_ROOM_ITEM_START
});

export const addAccessToDataRoomItemSuccess = payload => ({
  type: ADD_ACCESS_TO_DATA_ROOM_ITEM_SUCCESS,
  payload
});

export const addAccessToDataRoomItemFail = payload => ({
  type: ADD_ACCESS_TO_DATA_ROOM_ITEM_FAIL,
  payload
});

export const removeAccessToDataRoomItemStart = () => ({
  type: REMOVE_ACCESS_TO_DATA_ROOM_ITEM_START
});

export const removeAccessToDataRoomItemSuccess = payload => ({
  type: REMOVE_ACCESS_TO_DATA_ROOM_ITEM_SUCCESS,
  payload
});

export const removeAccessToDataRoomItemFail = payload => ({
  type: REMOVE_ACCESS_TO_DATA_ROOM_ITEM_FAIL,
  payload
});

export const searchBusinessRoomUsersStart = () => ({
  type: SEARCH_BUSINESS_ROOM_USERS_START
});

export const searchBusinessRoomUsersSuccess = payload => ({
  type: SEARCH_BUSINESS_ROOM_USERS_SUCCESS,
  payload
});

export const searchBusinessRoomUsersFail = payload => ({
  type: SEARCH_BUSINESS_ROOM_USERS_FAIL,
  payload
});

export const searchRoomUsersStart = () => ({
  type: SEARCH_ROOM_USERS_START
});

export const searchRoomUsersSuccess = payload => ({
  type: SEARCH_ROOM_USERS_SUCCESS,
  payload
});

export const searchRoomUsersFail = payload => ({
  type: SEARCH_ROOM_USERS_FAIL,
  payload
});

export const setUsersSearchRoomPagination = payload => ({
  type: SET_USERS_SEARCH_ROOM_PAGINATION,
  payload,
});

export const getSharedDataRoomItemsStart = () => ({ type: GET_SHARED_DATA_ROOM_ITEMS_START });

export const getSharedDataRoomItemsSuccess = payload => ({
  type: GET_SHARED_DATA_ROOM_ITEMS_SUCCESS,
  payload,
});

export const getSharedDataRoomItemsFail = payload => ({
  type: GET_SHARED_DATA_ROOM_ITEMS_FAIL,
  payload,
});
