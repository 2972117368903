import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Dropdown,
  Form,
  Input,
  Modal,
  Table
} from "antd";

import { getNotes, addNote, removeNote, updateNote } from '../../../redux/notes/thunks/notesThunks';
import { goBack } from '../../../utils/goBack';
import { formatDate } from "../../../utils/formatDate";
import { handleLoading } from "../../../utils/handleLoading";

import Icon from '../../../components/Icon/Icon';
import Button from '../../../components/Button/Button';
import PopUp from "../../../components/PopUp/PopUp";

import './style.scss';

const numberWithZero = (number) => {
  if(number < 10) {
    return '0' + number;
  }

  return number;
}

class AdminNotes extends Component {
  constructor(props) {
    super(props);
    this.notesColumns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: '10%',
        render: (text) => (
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Date:
            </span>
            {text}
          </div>
        ),
      },
      {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        width: '10%',
        render: (text) => (
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Time:
            </span>
            {text}
          </div>
        ),
      },
      {
        title: 'Note',
        dataIndex: 'note',
        key: 'note',
        render: (text) => (
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Note:
            </span>
            {text}
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: '10%',
        render: ({ id, text }) => {
          return (
            <div>
              <Dropdown
                overlay={
                  <div className="bg-white radius-4 shadow-layout drop_menu">
                    <div
                      className="drop_menu_item cursor"
                      onClick={() => this.togglePopupVisible('edit', text, id)}
                    >
                      <i className="icon icon-pen" />
                      <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                        Edit note
                      </span>
                    </div>
                    <div className="drop_menu_item cursor" onClick={() => this.removeNoteOnClick(id)} >
                      <i className="icon icon-cancel" />
                      <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                        Delete note
                      </span>
                    </div>
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
              >
                <div className="icon_btn">
                  <Button
                    title={<Icon type="tools" style={{fontSize: "19px"}}/>}
                    size="lg"
                    theme="lightblue"
                    className="radius-10"
                  />
                </div>
              </Dropdown>
            </div>
          )
        }
      },
    ];

    this.state = {
      isPopupVisible: false,
      popupMode: '',
      openedNoteID: null,
    };
  }

  getNotesData = () => {
    const { notes } = this.props;
    const returningData = [];

    // eslint-disable-next-line array-callback-return
    notes && notes.map((noteItem) => {
      const date = new Date(noteItem.date);
      let hour = date.getHours();
      let minute = date.getMinutes();
      let seconds = date.getSeconds();

      if (hour <= 9) {
        hour = `0${hour}`;
      }
      if (minute <= 9) {
        minute = `0${minute}`;
      }
      if (seconds <= 9) {
        seconds = `0${seconds}`;
      }

      returningData.push({
        date: formatDate(noteItem.date),
        time: `${hour}:${minute}:${seconds}`,
        note: noteItem.text,
        actions: {
          id: noteItem.id,
          text: noteItem.text,
        },
      });
    });

    return returningData;
  };

  getNewNotes = (currentPage) => {
    const { getNotes, match } = this.props;
    const userID = match.params.id;

    getNotes(currentPage, userID);
  };

  togglePopupVisible = (mode, noteText = null, openedNoteID = null) => {
    this.setState(prevState => ({
      isPopupVisible: !prevState.isPopupVisible,
      popupMode: mode,
      openedNoteID,
    }), () => {
      if (!this.state.isPopupVisible) {
        this.props.form.resetFields();
      } else {
        window.scroll(0, 0);
        if (noteText) {
          this.props.form.setFieldsValue({
            text: noteText,
          });
        }
      }
    });
  };

  removeNoteOnClick = (noteID) => {
    const { removeNote, match } = this.props;
    const userID = match.params.id;

    Modal.confirm({
      title: 'Are you sure you want to delete this note?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => removeNote({ id: noteID }, userID),
    });
  };

  onSubmit = (event) => {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { addNote, match, updateNote } = this.props;
        const userID = match.params.id;
        const { popupMode, openedNoteID } = this.state;

        const today = new Date();
        let date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        date += ` ${numberWithZero(today.getHours())}:${numberWithZero(today.getMinutes())}:${numberWithZero(today.getSeconds())}`;

        if (popupMode === 'edit') {
          updateNote({
            id: openedNoteID,
            text: values.text,
          },
          userID
        );
        } else {
          addNote({
            date,
            user: userID,
            text: values.text,
          });
        }
      }
    });
  };

  componentDidMount() {
    this.getNewNotes(1);
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading, errorMessage, currentPage } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    // const errorType = errorMessage.type;
    const errorText = errorMessage.message;

    if (!errorText && !loadingState && prevProps.loading.state && (loadingType === 'AddNote' || loadingType === 'UpdateNote')) {
      this.setState({
        isPopupVisible: false,
      }, () => this.props.form.resetFields());
    }

    if (currentPage !== prevProps.currentPage && loadingType === 'RemoveNote') {
      this.getNewNotes(currentPage);
    }
  }

  render() {
    const notesData = this.getNotesData();
    const {
      currentPage,
      notesCount,
      form,
      loading,
    } = this.props;
    const { getFieldDecorator } = form;
    const { isPopupVisible, popupMode } = this.state;

    return (
      <>
        <div className='relative fluid-y screen_wrapper' >
          <div className='row' >
            <div className='column small-12' >
              <div className='page_top page_top-tall' >
                <div className='flex-container align-middle' >
                  <Button
                    title={<Icon type="key-left" size={16} />}
                    size="md"
                    theme="lightblue"
                    className="radius-10 back_btn"
                    onClick={() => goBack()}
                  />
                  <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                    <h1>Notes</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row' >
            <div className='column small-12' >
              <div className="flex-container align-right" >
                <Button
                  type="button"
                  title="Add new note"
                  size="lg"
                  theme="lightblue"
                  mode="bordered"
                  className="radius-8 mb_25"
                  style={{fontSize: 18}}
                  onClick={() => this.togglePopupVisible('new')}
                />
              </div>
  
              <div className="table_wrapper table_wrapper_scrollable bg-white radius-10 shadow-layout expand_sm">
                <Table
                  columns={this.notesColumns}
                  dataSource={notesData}
                  className="c-table c-table-bordered stretch_table notes_table"
                  pagination={{
                    current: currentPage,
                    total: notesCount,
                  }}
                  onChange={(event) => this.getNewNotes(event.current)}
                />
              </div>
            </div>
          </div>
        </div>

        <PopUp
          className="relative_parent"
          visible={isPopupVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div
            onClick={() => this.togglePopupVisible('')}
            className="popup_close color-grey-700"
          >
            <Icon type="cancel"/>
          </div>
          <div className="text-center">
            <Form onSubmit={this.onSubmit} >
              <div className="form-item mb_50">
                <div className="form-item-label text-left text text-height-20 font-semibold color-grey">
                  Note
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator('text', {
                    initialValue: '',
                    rules: [{required: true, message: 'Please fill out this field'}],
                  })(
                    <Input autoComplete='off' className="fluid-x" />
                  )}
                </Form.Item>
              </div>

              <Button
                title={popupMode === 'edit' ? 'Edit' : 'Add'}
                size="xl"
                theme="blue"
                className="btn-shadow radius-8"
                type="submit"
                loading={handleLoading(loading, "AddNote") || handleLoading(loading, "UpdateNote")}
              />
            </Form>
          </div>
        </PopUp>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.notes.loading,
  errorMessage: state.notes.errorMessage,
  currentPage: state.notes.currentPage,
  notesCount: state.notes.notesCount,
  notes: state.notes.notes,
});

const mapDispatchToProps = (dispatch) => ({
  getNotes: (currentPage, userID) => dispatch(getNotes(currentPage, userID)),
  addNote: (params) => dispatch(addNote(params)),
  removeNote: (params, userID) => dispatch(removeNote(params, userID)),
  updateNote: (params, userID) => dispatch(updateNote(params, userID)),
});

const AdminNotesScreen = Form.create()(AdminNotes);

export default connect(mapStateToProps, mapDispatchToProps)(AdminNotesScreen);
