
import React, { Component } from "react";
import { connect } from "react-redux";
import { DatePicker, Form} from "antd";
import moment from "moment";

import Button from "../../../../components/Button/Button";
import Icon from "../../../../components/Icon/Icon";
import { handleLoading } from "../../../../utils/handleLoading";
import { convertToUTC } from "../../../../utils/convertToUTC";
import NumericInput from "../../../../components/NumericInput/NumericInput";

class ExternalOffer extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  onSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      const start = convertToUTC(values.start)
      const end = convertToUTC(values.end)
      if (!err) this.props.submitExternalPublish({...values, start, end });
    });
  };

  disabledDates = (current) =>{
    let customDate = moment().format("YYYY-MM-DD");
    return current && current < moment(customDate, "YYYY-MM-DD");
  }
  shouldComponentUpdate() {
    if (this.props.modalVisible) return true;
    else return false;
  }
  
  render() {
    const { getFieldDecorator } = this.props.form;
    let { changePopUpView, loading } = this.props;

    return (
      <>
        <div className="popup_close color-grey-700" onClick={() => changePopUpView('externalModalVisible')}>
          <Icon type="cancel" />
        </div>
        <Form onSubmit={this.onSubmit}>
          <div className="flex-container align-center text-center">
            <div className="text text-28 text-height-33 font-heavy font-spacing-02 color-grey font-uppercase large-7 mb_35">
              Publish for All investors
            </div>
          </div>
          <div>
            <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Bid Increments
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("step", {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <NumericInput className="fluid-x" />
                )}
              </Form.Item>
            </div>
            {/* <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                PE-GATE Fees
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("pegate_fees", {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <NumericInput className="fluid-x" />
                )}
              </Form.Item>
            </div>
            <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Legal Fees
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("legal_fees", {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <NumericInput className="fluid-x" />
                )}
              </Form.Item>
            </div>
            <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Deposit
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator('deposit', {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <NumericInput className="fluid-x" />
                )}
              </Form.Item>
            </div> */}
            <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Start Date
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("start", {
                  initialValue: null,
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <DatePicker onChange={this.calcEndTime} showTime disabledDate={this.disabledDates} />
                )}
              </Form.Item>
            </div>
            <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                End Date
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("end", {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <DatePicker showTime disabledDate={this.disabledDates} />
                )}
              </Form.Item>
            </div>
          </div>
          <div className="btn_group medium-flex-container align-middle align-right">
            <Form.Item className="mb_0">
              <Button
                title="Cancel"
                size="lg"
                theme="default"
                className="btn-link radius-8 font-uppercase"
                type="button"
                onClick={() => changePopUpView('externalModalVisible')}
              />
            </Form.Item>
            <Form.Item className="mb_0">
              <Button
                title="Save"
                type="submit"
                size="xl"
                theme="blue"
                prefix={<Icon type="checked" size={18} style={{ marginRight: 11 }} />}
                className="radius-8"
                style={{ fontSize: 18 }}
                loading={handleLoading(loading, "ExternalPublish")}
              />
            </Form.Item>
          </div>
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.offers.loading,
  errorMessage: state.offers.errorMessage,
});

const ExternalForm = Form.create({ name: "externalOfferPublish" })(ExternalOffer);

export default connect(mapStateToProps)(ExternalForm);

