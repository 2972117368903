import React from "react";
import ProgressStep from "../../../../components/ProgressStep/ProgressStep";
import { formatNumber } from "../../../../utils/formatNumber";

const InvestmentActions = ({
  interested,
  nda_signed,
  ready_to_start,
  not_interested,
  allocation_amount,
  diligence_amount,
  internal_cost,
  internal_prize,
  internal_sales,
  internal_income,
  external_cost,
  external_prize,
  external_sales,
  external_income,
  initial_amount,
  currency,
  real_diligence,
  final_amount,
  closed,
  isProgressStepActiveData,
  isOpportunityExpired,
  renderOpportunityActionButtons,
  totalSharesQuantity,
  totalReceived,
  involveRequest,
}) => {
  return <>
    <div className="flex-container align-middle align-justify progress_steps">
      {
        new Array(5).fill(null).map((item, index) => (
          <ProgressStep
          key={index}
            className={`flex-child-auto ${isProgressStepActiveData(index + 1).active ? 'active' : ''}`}
            tooltip={isProgressStepActiveData(index + 1).tooltip}
          />
        ))
      }
    </div>

    <div className="bg-white radius-10 text-center project_actions">
      {
        isOpportunityExpired ?
          <div className="text text-20 font-semibold font-uppercase color-grey project_actions__title" >
            This deal is closed
          </div>
          :
          null
      }

      {
        !involveRequest ?
          (!interested || (interested && !nda_signed)) || !closed && ready_to_start
          && (interested || not_interested) && nda_signed && (allocation_amount || diligence_amount)
            ? (
              <div className="text text-20 font-semibold font-uppercase color-grey project_actions__title">
                {
                  !closed && ready_to_start && (interested || not_interested) && nda_signed && (allocation_amount || diligence_amount) ?
                    'CONFIRM PE-GATE ALLOCATION'
                    :
                    (!interested || (interested && !nda_signed)) ? 'INTERESTED IN THIS OPPORTUNITY?' : ''
                }
              </div>
            ) : null
          : <div className='project_actions__title'>
              <div className="text text-20 font-semibold font-uppercase color-grey mb_10">Allocation Review</div>
              <div className="text text-16 font-semibold color-grey project_actions__title">Please review the below allocation for this opportunity and Accept or Reject accordingly.</div>
            </div>
      }

      {
        // !isOpportunityExpired ?
          initial_amount
            ? (
              <div className="progress_data">
                <div className="flex-container align-justify progress_data_row dashed">
                  <div className="text font-medium color-grey text-left">
                    Your committed amount:
                  </div>
                  <div className="text font-bold color-grey">
                    {formatNumber(initial_amount)} {currency}
                  </div>
                </div>
              </div>
            ) : null
          // : null
      }

      {
        involveRequest ?
          <div>
            {/* <div className="text text-14 font-medium font-uppercase font-spacing-04 color-grey-900 text-left mb_20">
              Internal
            </div> */}
            <div className="progress_data">
              <div className="flex-container align-justify progress_data_row">
                <div className="text font-bold color-grey text-left">
                  TOTAL RECEIVED
                </div>
                <div className="text font-bold color-grey">
                  {formatNumber(involveRequest.final_amount)}
                </div>
              </div>
              <div className="flex-container align-justify progress_data_row">
                <div className="text font-bold color-grey text-left">
                  RETURNED AMOUNT
                </div>
                <div className="text font-bold color-grey">
                  {formatNumber(involveRequest.returned_amount)}
                </div>
              </div>
              <div className="flex-container align-justify progress_data_row">
                <div className="text font-bold color-grey text-left">
                  FMV
                </div>
                <div className="text font-bold color-grey">
                  {formatNumber(involveRequest.fmv)}
                </div>
              </div>
              <div className="flex-container align-justify progress_data_row">
                <div className="text font-bold color-grey text-left">
                  PROJECTED IRR
                </div>
                <div className="text font-bold color-grey">
                  {formatNumber(involveRequest.irr)}
                </div>
              </div>
              <div className="flex-container align-justify progress_data_row">
                <div className="text font-bold color-grey text-left">
                  DUE DILIGENCE
                </div>
                <div className="text font-bold color-grey">
                  {formatNumber(involveRequest.real_diligence)}
                </div>
              </div>
              <div className="flex-container align-justify progress_data_row">
                <div className="text font-bold color-grey text-left">
                  SHAREHOLDING
                </div>
                <div className="text font-bold color-grey">
                  {involveRequest.shareholding}
                </div>
              </div>
            </div>
          </div>
          : null
        }

      {
        // !isOpportunityExpired ?
          <>
            {
              allocation_amount ?
                <div>
                  <div className="text text-14 font-medium font-uppercase font-spacing-04 color-grey-900 text-left mb_20">
                    { !closed ? 'PE-GATE allocation' : 'Primary' }
                  </div>
                  <div className="progress_data">
                    <div className="flex-container align-justify progress_data_row">
                      <div className="text font-bold color-grey text-left">
                        Number of Shares:
                      </div>
                      <div className="text font-bold color-grey">
                        {
                          !closed ?
                            allocation_amount ? formatNumber(allocation_amount) : 0
                            :
                            final_amount ? formatNumber(final_amount) : 0
                        }
                      </div>
                    </div>
                    <div className="flex-container align-justify progress_data_row">
                      <div className="text font-bold color-grey text-left">
                        { !closed ? 'Allocated amount:' : 'You invested:' }
                      </div>
                      <div className="text font-bold color-grey">
                        {
                          !closed ?
                            allocation_amount ? formatNumber(allocation_amount) : 0
                            :
                            final_amount ? formatNumber(final_amount) : 0
                        }
                        {` ${currency}`}
                      </div>
                    </div>
                  </div>
                </div>
                : null
            }
            {
              internal_cost || internal_prize || internal_income || internal_sales ?
                <div>
                  <div className="text text-14 font-medium font-uppercase font-spacing-04 color-grey-900 text-left mb_20">
                    Secondary (Internal)
                  </div>
                  <div className="progress_data">
                    <div className="flex-container align-justify progress_data_row">
                      <div className="text font-bold color-grey text-left">
                        Shares Purchased:
                      </div>
                      <div className="text font-bold color-grey">
                        {formatNumber(internal_prize)}
                      </div>
                    </div>
                    <div className="flex-container align-justify progress_data_row separated">
                      <div className="text font-bold color-grey text-left">
                        Cost of Shares:
                      </div>
                      <div className="text font-bold color-grey">
                        {formatNumber(internal_cost)}
                        {` ${currency}`}
                      </div>
                    </div>
                    <div className="flex-container align-justify progress_data_row">
                      <div className="text font-bold color-grey text-left">
                        Shares Sold:
                      </div>
                      <div className="text font-bold color-grey">
                        {formatNumber(internal_sales)}
                      </div>
                    </div>
                    <div className="flex-container align-justify progress_data_row">
                      <div className="text font-bold color-grey text-left">
                        Proceeds from Sale:
                      </div>
                      <div className="text font-bold color-grey">
                        {formatNumber(internal_income)}
                        {` ${currency}`}
                      </div>
                    </div>
                  </div>
                </div>
                : null
            }
            {
              external_cost || external_prize || external_income || external_sales ?
                <div>
                  <div className="text text-14 font-medium font-uppercase font-spacing-04 color-grey-900 text-left mb_20">
                    Secondary (External)
                  </div>
                  <div className="progress_data">
                    <div className="flex-container align-justify progress_data_row">
                      <div className="text font-bold color-grey text-left">
                        Shares Purchased:
                      </div>
                      <div className="text font-bold color-grey">
                        {formatNumber(external_prize)}
                      </div>
                    </div>
                    <div className="flex-container align-justify progress_data_row separated">
                      <div className="text font-bold color-grey text-left">
                        Cost of Shares:
                      </div>
                      <div className="text font-bold color-grey">
                        {formatNumber(external_cost)}
                        {` ${currency}`}
                      </div>
                    </div>
                    <div className="flex-container align-justify progress_data_row">
                      <div className="text font-bold color-grey text-left">
                        Shares Sold:
                      </div>
                      <div className="text font-bold color-grey">
                        {formatNumber(external_sales)}
                      </div>
                    </div>
                    <div className="flex-container align-justify progress_data_row">
                      <div className="text font-bold color-grey text-left">
                        Proceeds from Sale:
                      </div>
                      <div className="text font-bold color-grey">
                        {formatNumber(external_income)}
                        {` ${currency}`}
                      </div>
                    </div>
                  </div>
                </div>
                : null
            }
            {
              totalSharesQuantity ?
                <div>
                  <div className="text text-14 font-medium font-uppercase font-spacing-04 color-grey-900 text-left mb_20">
                    Total
                  </div>
                  <div className="progress_data">
                    {/* <div className="flex-container align-justify progress_data_row">
                      <div className="text font-bold color-grey text-left">
                        Received
                      </div>
                      <div className="text font-bold color-grey">
                        {formatNumber(totalReceived)}
                        {` ${currency}`}
                      </div>
                    </div> */}
                    <div className="flex-container align-justify progress_data_row">
                      <div className="text font-bold color-grey text-left">
                        Total Shares Owned:
                      </div>
                      <div className="text font-bold color-grey">
                        {formatNumber(totalSharesQuantity)}
                      </div>
                    </div>
                  </div>
                </div>
                : null
              }
          </>
          // : null
      }

      {renderOpportunityActionButtons}
    </div>
  </>
}

export default InvestmentActions;
