import { isSafariBrowser } from "./isSafariBrowser";

export const convertFromUTC = (date) => {
    let dateWithUTC = date;

    if(isSafariBrowser()) {
        dateWithUTC = date.replace(" ", "T");
    }

    return new Date(dateWithUTC + "Z");
}
