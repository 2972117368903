import {
  UPDATE_PERMISSIONS_START, UPDATE_PERMISSIONS_SUCCESS, UPDATE_PERMISSIONS_FAIL,
  UPDATE_SETTINGS_START, UPDATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_FAIL,
  SET_ONBOARDED_START, SET_ONBOARDED_SUCCESS, SET_ONBOARDED_FAIL,
  UNSET_ONBOARDED_START, UNSET_ONBOARDED_SUCCESS, UNSET_ONBOARDED_FAIL,
  SEND_ACTIVATION_EMAIL_START, SEND_ACTIVATION_EMAIL_SUCCESS, SEND_ACTIVATION_EMAIL_FAIL,
  CREATE_ADMIN_START, CREATE_ADMIN_SUCCESS, CREATE_ADMIN_FAIL,
} from "../../constants";

export const updatePermissionsStart = () => ({
  type: UPDATE_PERMISSIONS_START
});

export const updatePermissionsSuccess = payload => ({
  type: UPDATE_PERMISSIONS_SUCCESS,
  payload
});

export const updatePermissionsFail = payload => ({
  type: UPDATE_PERMISSIONS_FAIL,
  payload
});

export const updateSettingsStart = () => ({ type: UPDATE_SETTINGS_START });

export const updateSettingsSuccess = payload => ({ type: UPDATE_SETTINGS_SUCCESS, payload });

export const updateSettingsFail = payload => ({ type: UPDATE_SETTINGS_FAIL, payload });

export const setOnboardedStart = () => ({
  type: SET_ONBOARDED_START
});

export const setOnboardedSuccess = payload => ({
  type: SET_ONBOARDED_SUCCESS,
  payload
});

export const setOnboardedFail = payload => ({
  type: SET_ONBOARDED_FAIL,
  payload
});

export const unsetOnboardedStart = () => ({
  type: UNSET_ONBOARDED_START
});

export const unsetOnboardedSuccess = payload => ({
  type: UNSET_ONBOARDED_SUCCESS,
  payload
});

export const unsetOnboardedFail = payload => ({
  type: UNSET_ONBOARDED_FAIL,
  payload
});

export const sendActivationEmailStart = () => ({
  type: SEND_ACTIVATION_EMAIL_START
});

export const sendActivationEmailSuccess = payload => ({
  type: SEND_ACTIVATION_EMAIL_SUCCESS,
  payload
});

export const sendActivationEmailFail = payload => ({
  type: SEND_ACTIVATION_EMAIL_FAIL,
  payload
});

export const createAdminStart = () => ({ type: CREATE_ADMIN_START });

export const createAdminSuccess = (payload) => ({
  type: CREATE_ADMIN_SUCCESS,
  payload,
});

export const createAdminFail = (payload) => ({
  type: CREATE_ADMIN_FAIL,
  payload,
});
