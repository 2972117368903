import React from "react";
import "./style.scss";

const AnalyticItem = (props) => {
  const {
    className,
    title,
    desc,
    style
  } = props;

  return (
    <div className={`analytic_card clip bg-white radius-10 shadow-layout ${className}`} style={style}>
      <div className="text text-14 font-medium color-grey-900 font-uppercase font-spacing-04 analytic_card__title">
        {title}
      </div>
      <div className="text text-28 font-heavy color-grey font-spacing-02 analytic_card__desc">
        {desc}
      </div>
    </div>
  )
};

export default AnalyticItem;