import {
  ADD_INTERNAL_BID_FAIL, ADD_INTERNAL_BID_START, ADD_INTERNAL_BID_SUCCESS,
  ADD_EXTERNAL_BID_FAIL, ADD_EXTERNAL_BID_START, ADD_EXTERNAL_BID_SUCCESS,
  CLEAR_OFFER,
  CLEAR_OFFERS,
  CLEAR_OFFER_FILTERS,
  CREATE_OFFER_FAIL, CREATE_OFFER_START,CREATE_OFFER_SUCCESS,
  EXTERNAL_PUBLISH_FAIL, EXTERNAL_PUBLISH_START, EXTERNAL_PUBLISH_SUCCESS,
  GET_BID_FAIL, GET_BID_START, GET_BID_SUCCESS,
  GET_OFFER_FAIL, GET_OFFER_START, GET_OFFER_SUCCESS,
  INTERNAL_PUBLISH_FAIL, INTERNAL_PUBLISH_START, INTERNAL_PUBLISH_SUCCESS,
  REMOVE_OFFER_ADMIN_FAIL, REMOVE_OFFER_ADMIN_START, REMOVE_OFFER_ADMIN_SUCCESS,
  REMOVE_OFFER_INVESTOR_FAIL, REMOVE_OFFER_INVESTOR_START, REMOVE_OFFER_INVESTOR_SUCCESS,
  REQUEST_EXTERNAL_SALE_FAIL, REQUEST_EXTERNAL_SALE_START, REQUEST_EXTERNAL_SALE_SUCCESS,
  SEARCH_BIDS_FAIL, SEARCH_BIDS_START, SEARCH_BIDS_SUCCESS,
  SEARCH_OFFERS_FAIL, SEARCH_OFFERS_START, SEARCH_OFFERS_SUCCESS, 
  SEARCH_OFFER_BIDS_FAIL, SEARCH_OFFER_BIDS_START, SEARCH_OFFER_BIDS_SUCCESS, 
  SET_ACTIVE_OFFERS_TAB_KEY, 
  SET_OFFER_FILTERS, 
  SET_WON_FAIL, SET_WON_START, SET_WON_SUCCESS, CLOSE_OFFER_START, CLOSE_OFFER_SUCCESS, CLOSE_OFFER_FAIL, MOVE_BID_START, MOVE_BID_SUCCESS, MOVE_BID_FAIL,
  ADD_EXTERNAL_OVERBID,
  SEARCH_OFFER_BID_REQUESTS_START,
  SEARCH_OFFER_BID_REQUESTS_SUCCESS,
  SEARCH_OFFER_BID_REQUESTS_FAIL,
  ACCEPT_BID_REQUEST_START,
  ACCEPT_BID_REQUEST_SUCCESS,
  ACCEPT_BID_REQUEST_FAIL,
  REJECT_BID_REQUEST_START,
  REJECT_BID_REQUEST_SUCCESS,
  REJECT_BID_REQUEST_FAIL,
  GET_OFFER_BID_REQUEST_START,
  GET_OFFER_BID_REQUEST_SUCCESS,
  GET_OFFER_BID_REQUEST_FAIL,
  RESET_OFFER_BID_REQUEST
} from "../../constants";

// _____________________________ SHARED ______________________________

// searchOffers //

export const searchOffersStart = () => ({
  type: SEARCH_OFFERS_START
});

export const searchOffersSuccess = (payload) => ({
  type: SEARCH_OFFERS_SUCCESS,
  payload,
});

export const searchOffersFail = payload => ({
  type: SEARCH_OFFERS_FAIL,
  payload
});

export const setOfferFilters = payload => ({
  type: SET_OFFER_FILTERS,
  payload
});

export const clearOfferFilters = payload => ({
  type: CLEAR_OFFER_FILTERS,
  payload
});

export const clearOffers = payload => ({
  type: CLEAR_OFFERS,
  payload
});

export const setActiveOffersTabKey = payload => ({
  type: SET_ACTIVE_OFFERS_TAB_KEY,
  payload
});

// searchOfferBids //

export const searchOfferBidsStart = () => ({
  type: SEARCH_OFFER_BIDS_START
});

export const searchOfferBidsSuccess = payload => ({
  type: SEARCH_OFFER_BIDS_SUCCESS,
  payload
});

export const searchOfferBidsFail = payload => ({
  type: SEARCH_OFFER_BIDS_FAIL,
  payload
});

export const searchOfferBidRequestsStart = () => ({
  type: SEARCH_OFFER_BID_REQUESTS_START
});

export const searchOfferBidRequestsSuccess = payload => ({
  type: SEARCH_OFFER_BID_REQUESTS_SUCCESS,
  payload
});

export const searchOfferBidRequestsFail = payload => ({
  type: SEARCH_OFFER_BID_REQUESTS_FAIL,
  payload
});

export const getOfferBidRequestStart = () => ({
  type: GET_OFFER_BID_REQUEST_START
});

export const getOfferBidRequestSuccess = payload => ({
  type: GET_OFFER_BID_REQUEST_SUCCESS,
  payload
});

export const resetOfferBidRequest = () => ({
  type: RESET_OFFER_BID_REQUEST,
});

export const getOfferBidRequestFail = payload => ({
  type: GET_OFFER_BID_REQUEST_FAIL,
  payload
});

export const acceptBidRequestStart = () => ({
  type: ACCEPT_BID_REQUEST_START
});

export const acceptBidRequestSuccess = payload => ({
  type: ACCEPT_BID_REQUEST_SUCCESS,
  payload
});

export const acceptBidRequestFail = payload => ({
  type: ACCEPT_BID_REQUEST_FAIL,
  payload
});

export const rejectBidRequestStart = () => ({
  type: REJECT_BID_REQUEST_START
});

export const rejectBidRequestSuccess = payload => ({
  type: REJECT_BID_REQUEST_SUCCESS,
  payload
});

export const rejectBidRequestFail = payload => ({
  type: REJECT_BID_REQUEST_FAIL,
  payload
});

// getOffer //

export const getOfferStart = () => ({
  type: GET_OFFER_START
});

export const getOfferSuccess = payload => ({
  type: GET_OFFER_SUCCESS,
  payload
});

export const getOfferFail = payload => ({
  type: GET_OFFER_FAIL,
  payload
});

// clearGetOffer // 

export const clearOffer = payload => ({
  type: CLEAR_OFFER,
  payload
});

// getBid //

export const getBidStart = () => ({
  type: GET_BID_START
});

export const getBidSuccess = payload => ({
  type: GET_BID_SUCCESS,
  payload
});

export const getBidFail = payload => ({
  type: GET_BID_FAIL,
  payload
});

// _____________________________ INVESTOR ______________________________

// createOffer (postForSale) //

export const createOfferStart = () => ({
  type: CREATE_OFFER_START
});

export const createOfferSuccess = payload => ({
  type: CREATE_OFFER_SUCCESS,
  payload
});

export const createOfferFail = payload => ({
  type: CREATE_OFFER_FAIL,
  payload
});

// requestExternalSale //

export const requestExternalSaleStart = () => ({
  type: REQUEST_EXTERNAL_SALE_START
});

export const requestExternalSaleSuccess = payload => ({
  type: REQUEST_EXTERNAL_SALE_SUCCESS,
  payload
});

export const requestExternalSaleFail = payload => ({
  type: REQUEST_EXTERNAL_SALE_FAIL,
  payload
});

// InternalBid //

export const addInternalBidStart = () => ({
  type: ADD_INTERNAL_BID_START
});

export const addInternalBidSuccess = payload => ({
  type: ADD_INTERNAL_BID_SUCCESS,
  payload
});

export const addInternalBidFail = payload => ({
  type: ADD_INTERNAL_BID_FAIL,
  payload
});

// ExternalBid //
export const addExternalBidStart = () => ({
  type: ADD_EXTERNAL_BID_START
});

export const addExternalBidSuccess = payload => ({
  type: ADD_EXTERNAL_BID_SUCCESS,
  payload
});

export const addExternalOverBid = payload => ({
  type: ADD_EXTERNAL_OVERBID,
  payload
});

export const addExternalBidFail = payload => ({
  type: ADD_EXTERNAL_BID_FAIL,
  payload
});

// searchBids // 

export const searchBidsStart = () => ({
  type: SEARCH_BIDS_START
});

export const searchBidsSuccess = payload => ({
  type: SEARCH_BIDS_SUCCESS,
  payload
});

export const searchBidsFail = payload => ({
  type: SEARCH_BIDS_FAIL,
  payload
});

// remove offer (investor) //

export const removeOfferInvestorStart = () => ({
  type: REMOVE_OFFER_INVESTOR_START,
});

export const removeOfferInvestorSuccess = payload => ({
  type: REMOVE_OFFER_INVESTOR_SUCCESS,
  payload
});

export const removeOfferInvestorFail = payload => ({
  type: REMOVE_OFFER_INVESTOR_FAIL,
  payload
});

// _____________________________ ADMIN ______________________________

// internalPublish //

export const internalPublishStart = () => ({
  type: INTERNAL_PUBLISH_START
});

export const internalPublishSuccess = payload => ({
  type: INTERNAL_PUBLISH_SUCCESS,
  payload
});

export const internalPublishFail = payload => ({
  type: INTERNAL_PUBLISH_FAIL,
  payload
});

// externalPublish //

export const externalPublishStart = () => ({
  type: EXTERNAL_PUBLISH_START
});

export const externalPublishSuccess = (payload) => ({
  type: EXTERNAL_PUBLISH_SUCCESS,
  payload,
});

export const externalPublishFail = payload => ({
  type: EXTERNAL_PUBLISH_FAIL,
  payload
});

// closeOffer //

export const closeOfferStart = () => ({
  type: CLOSE_OFFER_START,
});

export const closeOfferSuccess = payload => ({
  type: CLOSE_OFFER_SUCCESS,
  payload
});

export const closeOfferFail = payload => ({
  type: CLOSE_OFFER_FAIL,
  payload
});

// moveBid //

export const moveBidStart = () => ({
  type: MOVE_BID_START,
});

export const moveBidSuccess = () => ({
  type: MOVE_BID_SUCCESS,
});

export const moveBidFail = payload => ({
  type: MOVE_BID_FAIL,
  payload
});

// remove offer (admin) //

export const removeOfferAdminStart = () => ({
  type: REMOVE_OFFER_ADMIN_START,
});

export const removeOfferAdminSuccess = payload => ({
  type: REMOVE_OFFER_ADMIN_SUCCESS,
  payload
});

export const removeOfferAdminFail = payload => ({
  type: REMOVE_OFFER_ADMIN_FAIL,
  payload
});

// setWon //

export const setWonStart = () => ({
  type: SET_WON_START,
});

export const setWonSuccess = payload => ({
  type: SET_WON_SUCCESS,
  payload
});

export const setWonFail = payload => ({
  type: SET_WON_FAIL,
  payload
});
