import React from "react";
import "./style.scss";

const ProgressStep = (props) => {
  const { tooltip, className } = props;

  return (
    <div className={`relative progress_step ${className}`} >
      <div className="progress_step__bar" />
      <div className="progress_step__tooltip flex-container align-middle align-center fluid-x text text-14 font-medium color-grey">
        <div className="text-center">
          {tooltip}
        </div>
      </div>
    </div>
  );
};

export default ProgressStep;