import React, {Component} from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
} from "antd";

import { getStates } from "../../../redux/dictionaries/thunks/dictionariesThunks";
import { getBusiness } from '../../../redux/business/thunks/businessThunk';
import {createBusiness, updateBusiness} from "../../../redux/business/thunks/newBusinessThunks";
import { findDictionaryByID } from "../../../utils/findDictionaryByID";
import { filterOption } from "../../../utils/filterOption";
import {getMediaFileFromServer} from "../../../utils/getMediaFileFromServer";
import {goBack} from "../../../utils/goBack";
import {MONTHS, ADMIN_MODE} from "../../../utils/constants";

import history from "../../../history";
import Button from "../../../components/Button/Button";
import Icon from "../../../components/Icon/Icon";
import PopUp from "../../../components/PopUp/PopUp";
import {handleLoading} from "../../../utils/handleLoading";
import {sortOptions} from "../../../utils/setOptions";
import NumericInput from "../../../components/NumericInput/NumericInput";
import "./style.scss";

const { Option } = Select;

const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

class Project extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: null,
      imageLoading: false,
      popupSuccess: {
        type: '',
        visible: false,
      },
      privacyPopupVisible: false,
      filePrivacy1: false,
      filePrivacy2: false,
      filePrivacy3: false,
      userShare: true,
      fileList: [
        {
          uid: "-1",
          name: "xxx.png",
          status: "done",
          url: "http://www.baidu.com/xxx.png"
        }
      ]
    }
  }

  handleChange = info => {
    if (info.file.status === "uploading") {
      this.setState({imageLoading: true});
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          imageLoading: false
        })
      );
    }
  };

  toggleSuccessModal = (type, status) => {
    this.setState({
      popupSuccess: {
        type,
        visible: true,
      }
    }, () => {
      if (status) {
        window.scrollTo(0, 0);
      } else {
        history.push("/opportunities");
      }
    });
  };

  togglePrivacyModal = status => {
    this.setState({
      privacyPopupVisible: status
    }, () => {
      if (status) {
        window.scrollTo(0, 0);
      }
    });
  };

  handleCountryOnChange = value => {
    const { getStates, form } = this.props;
    form.setFieldsValue({ state: "" });
    getStates(value);
  };

  fakeCustomRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  onSubmit = event => {
    event.preventDefault();
    const { form } = this.props;

    form.validateFields((err, values) => {
      if (!err && this.handleVideoValidation(values.video)) {
        const { imageUrl } = this.state;
        const { createBusiness, updateBusiness } = this.props;
        const { id } = this.getCurrentProjectData();

        delete values.filterCategory;
        delete values.userShare;
        values.deadline = values.deadline.format("YYYY-MM-DD");
        imageUrl && (values.photo = imageUrl);
        id && (values.id = id);

        if (id) {
          updateBusiness(values);
        } else {
          createBusiness(values);
        }
      }
    });
  };

  getCurrentProjectData = () => {
    const { currentOpenedBusiness } = this.props;
    
    let returningValues = {
      id: "",
      name: "",
      photo: "",
      video: "",
      description: "",
      industry: "",
      transaction_status: "",
      country: "",
      state: "",
      investment_type: "",
      currency: "",
      total_investment_from: "",
      total_investment_to: "",
      minimum_investment: "",
      shareholding_from: "",
      shareholding_to: "",
      deadline: "",
      diligence_costs: "",
      pegate_fees: "",
      pegate_involvement: "",
      pegate_lead: "",
      order: "",
      total_shares: "",
      fiscal_end: "",
      offer_mode: "",
      offer_duration: "",
    };

    if(currentOpenedBusiness) {
      return {
        ...currentOpenedBusiness,
        photo: currentOpenedBusiness.photo ? getMediaFileFromServer(currentOpenedBusiness.photo) : "",
      };
    }

    return returningValues;
  };

  disabledDate = current => {
    return current && current <= moment().add(-1, "days").endOf("day"); // disabling started from yesterday
  };

  handleVideoValidation = (value) => {
    const URLRegExp = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

    if (!URLRegExp.test(value)) {
      if (value && !value.startsWith("download/")) {
        this.props.form.setFields({
          video: {
            value: value,
            errors: [new Error("Video ID is invalid")],
          }
        });
        return false;
      }
    }
    return true;
  };

  onlyNumberValidation = (rule, value) => {
    const onlyNumberRegEx = /^-?(0|[1-9]\d*)?$/;
    return onlyNumberRegEx.test(value);
  };

  componentDidMount() {
    const { getBusiness, match } = this.props;
    const businessID = match.params.id;
    
    getBusiness(businessID);
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading, errorMessage, dictionaryLoading, dictionaryErrorMessage } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;
    const dictLoadingType = dictionaryLoading.type;
    const dictLoadingState = dictionaryLoading.state;
    const dictErrorText = dictionaryErrorMessage.message;

    if (!errorText && (loadingType === "CreateBusiness") && !loadingState && prevProps.loading.state) {
      this.toggleSuccessModal('created', true);
    }

    if (!errorText && (loadingType === "UpdateBusiness") && !loadingState && prevProps.loading.state) {
      this.toggleSuccessModal('updated', true);
    }

    if (!dictErrorText && dictLoadingType === "InitLoad" && !dictLoadingState && prevProps.dictionaryLoading.state) {
      const { getStates } = this.props;
      const { id, country } = this.getCurrentProjectData();
      id && (getStates(country));
    }
  }

  render() {
    const { imageUrl, popupSuccess } = this.state;
    let { match, form, opportunityGroups, loading } = this.props;
    const businessID = match.params.id;
    const {getFieldDecorator, getFieldValue} = form;
    const industryOptions = findDictionaryByID(5, opportunityGroups);
    const investmentTypeOptions = findDictionaryByID(3, opportunityGroups);
    const transactionStatusOptions = sortOptions(findDictionaryByID(4, opportunityGroups), [12, 197, 13, 14, 15, 16, 17, 18, 19, 20], 'id');
    const countries = this.props.countries ? this.props.countries : [];
    const states = this.props.states ? this.props.states : [];
    const {
      name, photo, video, description, industry,
      transaction_status, country, state, investment_type,
      currency, total_investment_from, total_investment_to, minimum_investment,
      shareholding_from, shareholding_to, deadline, diligence_costs, pegate_fees,
      pegate_involvement, pegate_lead, order, fiscal_end, offer_mode, offer_duration, total_shares,
    } = this.getCurrentProjectData();


    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="flex-container align-middle">
                <Button
                  title={<Icon type="key-left" size={16}/>}
                  size="md"
                  onClick={goBack}
                  theme="lightblue"
                  className="radius-10 back_btn"
                />
                <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                  <h1>{ businessID ? "edit" : "add new" } opportunity</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Form onSubmit={this.onSubmit}>
          <div className="row align-center mb_35">

            <div className="column small-12 medium-10 large-8">
              <div className="sign_out_form_card add_form bg-white radius-10 shadow-layout">
                <div className="base_dragger mb_20">
                  <Upload
                    showUploadList={false}
                    listType="picture-card"
                    customRequest={this.fakeCustomRequest}
                    onChange={this.handleChange}
                  >
                    <div className="base_dragger_img">
                      {
                        //if from db comes image we check him on a secondary level with if, cause after that you can upload a new image
                        imageUrl ?
                          <img src={imageUrl} style={{width: "100%"}} alt="" />
                          :
                          photo ?
                            <img src={photo} style={{width: "100%"}} alt="" />
                          :
                            <img
                              src={require("../../../assets/img/pic-icon.svg")}
                              style={{marginTop: "20px"}}
                              alt="Upload"
                            />
                      }

                      <div className="text font-semibold color-grey base_dragger_label">
                        Drag your Image here
                      </div>
                    </div>
                    <Button
                      title={<b>Upload an image</b>}
                      size="lg"
                      theme="lightblue"
                      style={{fontSize: 16}}
                      prefix={<Icon type="upload" size={18} style={{marginRight: 6}}/>}
                      className="btn-link uploader_trigger"
                      type="button"
                    />
                  </Upload>
                </div>

                <div className="row">
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        PROJECT NAME
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("name", {
                          initialValue: name,
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        Video ID
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("video", {
                          initialValue: video,
                          rules: [{required: false, message: "Please fill out this field"}]
                        })(
                          <Input
                            placeholder="Please insert a video id from DataRoom"
                            onBlur={(event) => this.handleVideoValidation(event.target.value)}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        DESCRIPTION
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("description", {
                          initialValue: description,
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        Industry
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("industry", {
                          initialValue: industry,
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <Select showSearch filterOption={filterOption}>
                            {
                              industryOptions.map((element, index) => (
                                <Option key={index} value={element.id}>{element.name}</Option>
                              ))
                            }
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        Investment type
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("investment_type", {
                          initialValue: investment_type,
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <Select showSearch filterOption={filterOption}>
                            {
                              investmentTypeOptions.map((element, index) => (
                                <Option key={index} value={element.id}>{element.name}</Option>
                              ))
                            }
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        COUNTRY
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("country", {
                          initialValue: country,
                          rules: [{required: true, message: 'Please fill out this field'}]
                        })(
                          <Select onChange={this.handleCountryOnChange} showSearch filterOption={filterOption}>
                            {
                              countries.map((element, index) => (
                                <Option key={index} value={element.id}>{element.name}</Option>
                              ))
                            }
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        STATE/PROVINCE
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("state", {
                          initialValue: state,
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <Select showSearch filterOption={filterOption}>
                            {
                              states.map((element, index) => (
                                <Option key={index} value={element.id}>{element.name}</Option>
                              ))
                            }
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        TRANSACTION STATUS
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("transaction_status", {
                          initialValue: transaction_status,
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <Select showSearch filterOption={filterOption}>
                            {
                              transactionStatusOptions.map((element, index) => (
                                <Option key={index} value={element.id}>{element.name}</Option>
                              ))
                            }
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </div>

                  <div className="column small-12">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        TOTAL INVESTMENT
                      </div>
                      <div className="flex-container align-middle align-spaced">
                        <Form.Item className="mb_0 column small-6">
                          {getFieldDecorator("total_investment_from", {
                            initialValue: total_investment_from,
                            rules: [
                              {required: true, message: "Please fill out this field"},
                              {
                                validator: async (rule, value) => {
                                  if (parseInt(value) === 0) {
                                    return Promise.reject("Minimum value can not be 0");
                                  }
                                  if (parseInt(value) > parseInt(getFieldValue("total_investment_to"))) {
                                    return Promise.reject("Minimum value can not be more than Maximum value");
                                  }
                                },
                              },
                            ],
                          })(
                            <NumericInput className="fluid-x" />
                          )}
                        </Form.Item>
                        <div className="form-item-separator">
                          -
                        </div>
                        <Form.Item className="mb_0 column small-6">
                          {getFieldDecorator("total_investment_to", {
                            initialValue: total_investment_to,
                            rules: [
                              {required: true, message: "Please fill out this field"},
                              {
                                validator: async (rule, value) => {
                                  if (parseInt(value) === 0) {
                                    return Promise.reject("Maximum value can not be 0");
                                  }
                                  if (parseInt(value) < parseInt(getFieldValue("total_investment_from"))) {
                                    return Promise.reject("Maximum value can not be less than Minimum value");
                                  }
                                },
                              },
                            ],
                          })(
                            <NumericInput className="fluid-x" />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        MINIMUM INVESTMENT
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("minimum_investment", {
                          initialValue: minimum_investment,
                          rules: [
                            {required: true, message: "Please fill out this field"},
                            {
                              validator: async (rule, value) => {
                                if (parseInt(value) === 0) {
                                  return Promise.reject("Minimum investment can not be 0");
                                }
                                if (parseInt(value) > parseInt(getFieldValue("total_investment_to"))) {
                                  return Promise.reject("Minimum Investment can not be more then Total Investment Maximum");
                                }
                              },
                            },
                          ],
                        })(
                          <NumericInput className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        SHAREHOLDING%
                      </div>
                      <div className="flex-container">
                        <Form.Item className="mb_0">
                          {getFieldDecorator("shareholding_from", {
                            initialValue: shareholding_from,
                            rules: [
                              {required: true, message: "Please fill out this field"},
                              {
                                validator: async (rule, value) => {
                                  if (parseInt(value) > parseInt(getFieldValue("shareholding_to"))) {
                                    return Promise.reject("Minimum shareholding can not be more than Maximum shareholding");
                                  } else if(value < 0.01) {
                                    return Promise.reject("Minimum shareholding can not be less than 0.01");
                                  } else if(value > 100) {
                                    return Promise.reject("Minimum shareholding can not be more than 100");
                                  }
                                },
                              },
                            ]
                          })(
                            <NumericInput className="fluid-x" />
                          )}
                        </Form.Item>
                        <div className="form-item-separator">
                          -
                        </div>
                        <Form.Item className="mb_0">
                          {getFieldDecorator("shareholding_to", {
                            initialValue: shareholding_to,
                            rules: [
                              {required: true, message: "Please fill out this field"},
                              {
                                validator: async (rule, value) => {
                                  if (parseInt(value) < parseInt(getFieldValue("shareholding_from"))) {
                                    return Promise.reject("Maximum shareholding can not be less than Minimum shareholding");
                                  }
                                  else if(value > 100) {
                                    return Promise.reject("Maximum shareholding can not be more than 100");
                                  }
                                },
                              },
                            ]
                          })(
                            <NumericInput className="fluid-x" />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        TRANSACTION COSTS
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("diligence_costs", {
                          initialValue: diligence_costs,
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <NumericInput className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        PE-GATE FEES
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("pegate_fees", {
                          initialValue: pegate_fees,
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        PE-GATE INVOLVEMENT
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("pegate_involvement", {
                          initialValue: pegate_involvement,
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <Input className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        PE-GATE LEAD
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("pegate_lead", {
                          initialValue: pegate_lead,
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <Input className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        CURRENCY
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("currency", {
                          initialValue: currency,
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <Select showSearch filterOption={filterOption} className="fluid-x">
                            <Option value="USD">USD</Option>
                            <Option value="CAD">CAD</Option>
                            <Option value="EUR">EUR</Option>
                            <Option value="GBP">GBP</Option>
                            <Option value="AMD">AMD</Option>
                            <Option value="AED">AED</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        DEADLINE TO RESPOND
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("deadline", {
                          initialValue: deadline,
                          getValueProps: (i) => ({value: i ? moment(i) : null}),
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <DatePicker disabledDate={this.disabledDate} />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        ORDER
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("order", {
                          initialValue: order,
                          rules: [
                            { required: false },
                            {
                              validator: this.onlyNumberValidation,
                              message: 'Order must contain only numbers',
                            },
                          ]
                        })(
                          <Input className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        YEAR END
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("fiscal_end", {
                          initialValue: fiscal_end.toString(),
                          rules: [{required: true, message: "Please fill out this field"}]
                        })(
                          <Select showSearch filterOption={filterOption} className="fluid-x">
                            { Object.keys(MONTHS).map((month, index) => <Option key={index} value={month}>{MONTHS[month]}</Option>) }
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        TOTAL SHARES
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("total_shares", {
                          initialValue: total_shares,
                          rules: [
                            { required: true, message: "Please fill out this field" },
                            {
                              validator: this.onlyNumberValidation,
                              message: 'Total shares must contain only numbers',
                            },
                          ]
                        })(
                          <NumericInput className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  {/* <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        PRIMARY DEAL DURATION (DAYS)
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("offer_duration", {
                          initialValue: offer_duration,
                          rules: [
                            { required: false },
                            {
                              validator: this.onlyNumberValidation,
                              message: 'Order must contain only numbers',
                            },
                          ]
                        })(
                          <Input className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        SECONDARY AUCTION STYLE
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("offer_mode", {
                          initialValue: offer_mode,
                          rules: [{required: false}]
                        })(
                          <Select filterOption={filterOption} className="fluid-x">
                            { ADMIN_MODE.map((i) => <Option value={i}>{i}</Option>) }
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </div> */}
                </div>
                <div className="form_submit">
                  <Form.Item className="mb_0">
                    <Button
                      title={`${businessID ? "Edit" : "Finish Creating new"} opportunity`}
                      prefix={<Icon type="checked" style={{fontSize: 18, marginRight: 10}}/>}
                      size="xl"
                      theme="blue"
                      className="btn-shadow radius-8 font-capitalize"
                      type="submit"
                      loading={handleLoading(loading, "CreateBusiness") || handleLoading(loading, "UpdateBusiness")}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </Form>

        <PopUp
          className="relative_parent"
          visible={popupSuccess.visible}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div className="popup_icon">
            <img src={require("../../../assets/img/completed.svg")} alt=""/>
          </div>
          <div className="text-center">
            <div className="text text-28 font-heavy font-spacing-02 color-grey font-uppercase mb_25">
              Success!
            </div>
            <div className="flex-container flex-wrap align-center">
              <div className="text text-20 text-height-30 font-medium color-grey mb_45 large-11">
                You have {popupSuccess.type} {popupSuccess.type === 'created' ? 'a new' : 'the'} Investment Opportunity
              </div>
            </div>

            <Button
              title="OK"
              size="xl"
              theme="blue"
              className="radius-8"
              style={{fontSize: 18}}
              onClick={() => this.toggleSuccessModal('', false)}
            />
          </div>
        </PopUp>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dictionaryLoading: state.dictionaries.loading,
  dictionaryErrorMessage: state.dictionaries.errorMessage,
  loading: state.business.loading,
  errorMessage: state.business.errorMessage,
  opportunityGroups: state.dictionaries.opportunityGroups,
  countries: state.dictionaries.countries,
  states: state.dictionaries.states,
  businesses: state.business.businesses,
  currentOpenedBusiness: state.business.currentOpenedBusiness,
});

const mapDispatchToProps = dispatch => ({
  getStates: countryID => dispatch(getStates(countryID)),
  createBusiness: params => dispatch(createBusiness(params)),
  updateBusiness: params => dispatch(updateBusiness(params)),
  getBusiness: (businessID) => dispatch(getBusiness(businessID)),
});

const ProjectScreen = Form.create({name: "investAmount"})(Project);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectScreen);
