import {
  SIGN_NDA_START, SIGN_NDA_SUCCESS, SIGN_NDA_FAIL,
  GET_NDA_START, GET_NDA_SUCCESS, GET_NDA_FAIL
} from "../../constants";

export const signNDAStart = () => ({
  type: SIGN_NDA_START
});

export const signNDASuccess = payload => ({
  type: SIGN_NDA_SUCCESS,
  payload
});

export const signNDAFail = payload => ({
  type: SIGN_NDA_FAIL,
  payload
});

export const getNDAStart = () => ({
  type: GET_NDA_START
});

export const getNDASuccess = payload => ({
  type: GET_NDA_SUCCESS,
  payload
});

export const getNDAFail = payload => ({
  type: GET_NDA_FAIL,
  payload
});