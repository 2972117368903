
import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";

import Button from "../../../../components/Button/Button";
import Icon from "../../../../components/Icon/Icon";
import { handleLoading } from "../../../../utils/handleLoading";

class DeleteOffer extends Component {
  constructor(props) {
    super(props);

    this.state = { }
  }

  onSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) this.props.removeOfferAdmin({ reason: values.reason, id: this.props.offerID });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let { changePopUpView, loading } = this.props;

    return (
      <>
        <div className="popup_close color-grey-700" onClick={() => changePopUpView('removeOfferModalVisible')}>
          <Icon type="cancel" />
        </div>
        <Form onSubmit={this.onSubmit}>
          <div className="flex-container align-center text-center">
            <div className="text text-28 text-height-33 font-heavy font-spacing-02 color-grey font-uppercase large-7 mb_35">
              Reject
            </div>
          </div>
          <div>
            <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Reason
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("reason", {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <TextArea />
                )}
              </Form.Item>
            </div>
          </div>
          <div className="btn_group medium-flex-container align-middle align-right">
            <Form.Item className="mb_0">
              <Button
                title="Cancel"
                size="lg"
                theme="default"
                className="btn-link radius-8 font-uppercase"
                type="button"
                onClick={() => changePopUpView('removeOfferModalVisible')}
              />
            </Form.Item>
            <Form.Item className="mb_0">
              <Button
                title="Save"
                type="submit"
                size="xl"
                theme="blue"
                prefix={<Icon type="checked" size={18} style={{ marginRight: 11 }} />}
                className="radius-8"
                style={{ fontSize: 18 }}
                loading={handleLoading(loading, "RemoveOfferAdmin")}
              />
            </Form.Item>
          </div>
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.offers.loading,
  errorMessage: state.offers.errorMessage,
});

const DeleteOfferForm = Form.create({ name: "deleteOffer" })(DeleteOffer);

export default connect(mapStateToProps)(DeleteOfferForm);

