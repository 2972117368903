import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Collapse,
  message,
} from "antd";

import { changePassword } from "../../redux/user/thunks/passwordThunk";
import { sendRecoveryLink } from "../../redux/user/thunks/recoverPasswordThunk";
import { handleLoading } from "../../utils/handleLoading";
import { handleErrorMessage } from "../../utils/handleErrorMessage";
import {specialCharacterRegExp, upperCaseCharacterRegExp} from "../../utils/regexps";

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import "./style.scss";

const { Panel } = Collapse;

class SettingsPasswordUi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation8Chars: {
        isFilled: false,
        isValid: false,
      },
      validationSpecialCharacter: {
        isFilled: false,
        isValid: false,
      },
      validationUpperCase: {
        isFilled: false,
        isValid: false,
      },
    };
  }

  handleChangePassword = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { old_password, new_password, repeat_new_password } = values;

        if (new_password === repeat_new_password) {
          const {changePassword} = this.props;
          changePassword(old_password, new_password);
        } else {
          message.error("Passwords are not identical");
        }
      }
    });
  };

  handleRecoveryLinkOnClick = () => {
    const { userInfo, sendRecoveryLink } = this.props;

    if (userInfo) {
      const {email} = userInfo;
      sendRecoveryLink(email);
    }
  };

  passwordValidator = async (rule, value) => {
    if (value) {
      let isValid = true;
      if (value.length < 8) {
        this.setState({
          validation8Chars: {
            isFilled: true,
            isValid: false,
          },
        });
        isValid = false;
      } else {
        this.setState({
          validation8Chars: {
            isFilled: true,
            isValid: true,
          },
        });
      }
      if (!specialCharacterRegExp.test(value)) {
        this.setState({
          validationSpecialCharacter: {
            isFilled: true,
            isValid: false,
          },
        });
        isValid = false;
      } else {
        this.setState({
          validationSpecialCharacter: {
            isFilled: true,
            isValid: true,
          },
        });
      }
      if (!upperCaseCharacterRegExp.test(value)) {
        this.setState({
          validationUpperCase: {
            isFilled: true,
            isValid: false,
          },
        });
        isValid = false;
      } else {
        this.setState({
          validationUpperCase: {
            isFilled: true,
            isValid: true,
          },
        });
      }

      if (!isValid) {
        return Promise.reject('');
      }
    } else {
      this.setState({
        validation8Chars: {
          isFilled: false,
          isValid: false,
        },
        validationSpecialCharacter: {
          isFilled: false,
          isValid: false,
        },
        validationUpperCase: {
          isFilled: false,
          isValid: false,
        },
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { loading, errorMessage } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;
    const errorType = errorMessage.type;

    if (!errorText && loadingType === "ChangePassword" && !loadingState && prevProps.loading.state) {
      message.success("Password changed successfully");
      this.props.form.resetFields();
    }

    if (!errorText && loadingType === "SendRecoveryLink" && !loadingState && prevProps.loading.state) {
      message.success("Please, check your email to recover your password");
    }

    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "ChangePassword")
      || handleErrorMessage(errorMessage, prevProps.errorMessage, "SendRecoveryLink")) {
      message.error(errorText);
      errorType === "ChangePassword" && (this.props.form.resetFields());
    }
  }

  render() {
    const { form, loading } = this.props;
    const { getFieldDecorator } = form;
    const { validation8Chars, validationSpecialCharacter, validationUpperCase } = this.state;

    return(
      <Panel
        {...this.props}
        header={
          <div className="flex-container medium-align-middle align-justify">
            <div className="flex-container align-middle">
              <div className="flex-container align-middle text text-14 font-medium font-spacing-04 font-uppercase color-grey-800 collapse_label">
                <Icon type="lock" size={14} style={{marginRight: 10}}/>
                Change password
              </div>
            </div>

            <div className="collapse_icon">
              <Icon type="edit" size={18} className="color-grey"/>
            </div>
          </div>
        }
      >
        <div className="row">
          <div className="column small-12 medeium-9 large-6">
            <div className="form-item mb_30">
              <div className="form-item-label text text-14 text-height-20 font-medium font-spacing-04 font-uppercase color-grey">
                Old password
              </div>
              <Form.Item>
                {getFieldDecorator("old_password", {
                  initialValue: "",
                  rules: [
                    {required: true, message: "Please fill out this field"},
                    {
                      min: 8,
                      message: "Password should contain minimum 8 characters"
                    }
                  ],
                  validateTrigger: "onBlur"
                })(
                  <Input.Password />
                )}
              </Form.Item>
            </div>
            <div className="form-item mb_30">
              <div className="form-item-label text text-14 text-height-20 font-medium font-spacing-04 font-uppercase color-grey">
                new password
              </div>
              <Form.Item>
                {getFieldDecorator("new_password", {
                  initialValue: "",
                  rules: [
                    {required: true, message: ""},
                    {validator: this.passwordValidator},
                  ],
                  validateTrigger: "onBlur"
                })(
                  <Input.Password />
                )}
              </Form.Item>
              <div className="validators-wrapper" >
                <p
                  className="mb_0"
                  style={validation8Chars.isFilled && validation8Chars.isValid ? { color: 'green' } : null}
                >
                  <Icon
                    type='checked'
                    style={validation8Chars.isFilled && validation8Chars.isValid ? { color: 'green' } : null}
                  />
                  At least 8 character
                </p>
                <p
                  className="mb_0"
                  style={validationSpecialCharacter.isFilled && validationSpecialCharacter.isValid ? { color: 'green' } : null}
                >
                  <Icon
                    type='checked'
                    style={validationSpecialCharacter.isFilled && validationSpecialCharacter.isValid ? { color: 'green' } : null}
                  />
                  At least 1 special character
                </p>
                <p
                  className="mb_0"
                  style={validationUpperCase.isFilled && validationUpperCase.isValid ? { color: 'green' } : null}
                >
                  <Icon
                    type='checked'
                    style={validationUpperCase.isFilled && validationUpperCase.isValid ? { color: 'green' } : null}
                  />
                  At least 1 upper case character
                </p>
              </div>
            </div>
            <div className="form-item mb_30">
              <div className="form-item-label text text-14 text-height-20 font-medium font-spacing-04 font-uppercase color-grey">
                repeat new password
              </div>
              <Form.Item>
                {getFieldDecorator("repeat_new_password", {
                  initialValue: "",
                  rules: [
                    {required: true, message: "Please fill out this field"},
                    {
                      min: 8,
                      message: "Password should contain minimum 8 characters"
                    },
                    {
                      validator: async (rules, value) => {
                        const newPassword = this.props.form.getFieldValue('new_password');
                        if (value && newPassword !== value) {
                          return Promise.reject('Two passwords that you entered do not match!');
                        }
                      },
                    },
                  ],
                  validateTrigger: "onBlur"
                })(
                  <Input.Password />
                )}
              </Form.Item>
            </div>

            <Form.Item className="mb_0">
              <Button
                title="Save changes"
                size="lg"
                theme="blue"
                className="btn-shadow radius-8"
                type="submit"
                onClick={this.handleChangePassword}
                loading={handleLoading(loading, "ChangePassword")}
              />
            </Form.Item>
          </div>
        </div>
      </Panel>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  errorMessage: state.user.errorMessage,
  userInfo: state.user.userInfo
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (old_password, new_password) => dispatch(changePassword(old_password, new_password)),
  sendRecoveryLink: email => dispatch(sendRecoveryLink(email))
});

const SettingsPassword = Form.create({ name: "change_login" })(SettingsPasswordUi);

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPassword);
