import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Breadcrumb,
  message,
  Dropdown,
  Spin,
  Form,
  Input, Empty,
} from 'antd';

import history from "../../history";
import {
  addDataRoomItem,
  downloadDataRoomItem,
  getDataRoomInnerItems,
  getDataRoomItems,
} from "../../redux/dataRoom/thunks/dataRoomThunks";
import { getTags } from "../../redux/tags/thunks/tagsThunks";
import { getDataRoomItemsByType } from "../../utils/getDataRoomItemsByType";
import { handleErrorMessage } from "../../utils/handleErrorMessage";
import { getDataRoomIconFromFileExtension } from "../../utils/getDataRoomIconFromFileExtension";
import { handleLoading } from "../../utils/handleLoading";

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import PopUp from "../../components/PopUp/PopUp";
import FileCard from "../../components/FileCard/FileCard";
import FileUpload from "../../components/FileUpload/FileUpload";
import './style.scss';

class DataRoomInner extends Component {
  constructor(props) {
    super(props);
    const { id, folderName, isBusiness } = this.props.match.params;
    const path = !isBusiness ? `/data-room/${id}/${folderName}` : `/data-room/${id}/${folderName}/true`;

    this.state = {
      popupFileVisible: false,
      popupFolderVisible: false,
      routes: [
        {
          path: '/data-room',
          breadcrumbName: 'Data Room',
        },
        {
          path,
          breadcrumbName: folderName,
        }
      ],
    }
  }

  toggleFileModal = showStatus => {
    this.setState({
      popupFileVisible: showStatus
    }, () => {
      if (showStatus) {
        window.scroll(0, 0);
      }
    });
  };

  toggleFolderModal = (showStatus) => {
    this.setState({
      popupFolderVisible: showStatus,
    }, () => {
      if (!showStatus) {
        this.props.form.resetFields();
      } else {
        window.scroll(0, 0);
      }
    });
  };

  addNewFolder = () => {
    const { match, form } = this.props;
    const { id, isBusiness, business } = match.params;

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { addDataRoomItem } = this.props;
        addDataRoomItem(id, values.folderName, 'directory', null, isBusiness ? business : 0, true);
      }
    });
  };

  setInnerFolder = (folderName, id) => {
    const oldRoutes = JSON.parse(localStorage.getItem('routes'));
    const { isBusiness, business } = this.props.match.params;
    const path = !isBusiness ? `/data-room/${id}/${folderName}` : `/data-room/${id}/${folderName}/true/${business}`;

    oldRoutes.push({ path: path, breadcrumbName: folderName });
    localStorage.setItem('routes', JSON.stringify(oldRoutes));

    const { getDataRoomInnerItems } = this.props;
    history.push(path);
    getDataRoomInnerItems(id, business, true);
  };

  getFolderNameFromID = (id, businessName) => {
    const { dataRoomItems } = this.props;
    let folderName = "";

    if (businessName) {
      folderName = businessName;
    } else if (dataRoomItems) {
      const foundItem = dataRoomItems.find(element => parseInt(element.id) === parseInt(id));
      folderName = foundItem ? foundItem.name : "";
    }

    return folderName;
  };

  handleFileDownload = (fileItem) => {
    const { downloadDataRoomItem } = this.props;
    const {
      token,
      year,
      month,
      day,
      id,
      name,
    } = fileItem;

    downloadDataRoomItem(token, year, month, day, id, name);
  };

  hasAccessToCreateFile = () => {
    const { dataRoomItems, userID, role } = this.props;
    if (role === "ADMIN") {
      return true;
    }

    const routes = JSON.parse(localStorage.getItem('routes'));
    const parentID = routes[1].path.split('/')[2]; // parent id is always storing at local storage's routes item, 2nd element in array

    if (dataRoomItems) {
      const foundFolder = dataRoomItems.find(element => parseInt(element.id) === parseInt(parentID));
      if (foundFolder) {
        return parseInt(foundFolder.creator) === parseInt(userID);
      } else {
        return false;
      }
    }

    return false;
  };

  loadMoreInnerItems = () => {
    const { getDataRoomInnerItems, match } = this.props;
    const { id, business } = match.params;
    getDataRoomInnerItems(id, business);
  };

  isLoadMoreButtonDisabled = () => {
    const { dataRoomInnerItemsCount, currentInnerPage } = this.props;
    const roundedCount = Math.ceil(dataRoomInnerItemsCount / 10) * 10;
    const checkingNumber = currentInnerPage * 10;

    return roundedCount < checkingNumber;
  };

  isActionsDisabled = (creatorID) => {
    const { role, userID } = this.props;

    if (role !== "ADMIN") {
      if (parseInt(creatorID) !== parseInt(userID)) {
        return true;
      }
    }

    return false;
  };

  handleBreadcrumbRouteOnClick = (path) => {
    const storedRoutes = JSON.parse(localStorage.getItem('routes'));
    const index = storedRoutes.findIndex((item) => item.path === path);
    storedRoutes.length = index + 1; // setting array's length is much more faster to delete array items, then Array.splice
    localStorage.setItem('routes', JSON.stringify(storedRoutes));
    history.push(path);
    const pathArray = path.split("/");
    const id = pathArray[2]
    const business = pathArray[5]
    
    if(id !== undefined && business !== undefined) {
      const { getDataRoomInnerItems } = this.props;
      getDataRoomInnerItems(id, business, true);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      errorMessage,
      loading,
      match,
      getDataRoomInnerItems,
    } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;
    const { id, isBusiness, business } = match.params;

    if (!errorText && !loadingState && prevProps.loading.state && loadingType === "AddDataRoomItem") {
      this.setState({ popupFileVisible: false });
    }

    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "AddDataRoomItem")) {
      message.error(errorText);
    }

    if (!errorText && !loadingState && prevProps.loading.state) {
      if (loadingType === "GetDataRoomItems") {
        getDataRoomInnerItems(id, isBusiness ? business : 0, true);
      }
    }
  }

  componentDidMount() {
    const { getDataRoomItems, role, getTags } = this.props;

    getDataRoomItems();
    if (role === 'ADMIN') {
      getTags();
    }
  }

  render() {
    const {
      dataRoomInnerItems,
      match,
      loading,
      form,
    } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const { getFieldDecorator } = form;
    const {popupFileVisible, popupFolderVisible} = this.state;
    const { id, isBusiness, business } = match.params;

    const folders = getDataRoomItemsByType(dataRoomInnerItems, "DIRECTORY");
    const files = getDataRoomItemsByType(dataRoomInnerItems, "FILE");
    const routes = JSON.parse(localStorage.getItem('routes'));

    const itemRender = (route, params, routes) => {
      const first = routes.indexOf(route) === 0;
      const last = routes.indexOf(route) === routes.length - 1;
      return last ? (
        <span className="text text-20 font-semibold color-grey breadcrumb_item">
          <Icon type="folder-file" size={24} className="inline-middle" />
          {route.breadcrumbName}
        </span>
      ) : (
        <span
          onClick={() => this.handleBreadcrumbRouteOnClick(route.path)}
          className="text text-20 font-semibold color-grey breadcrumb_item breadcrumb_item_active"
        >
          <Icon type={first ? 'home' : 'folder-file'} size={24} className="inline-middle" />
          {route.breadcrumbName}
        </span>
      );
    };

    return (
      <Spin spinning={handleLoading(loading, 'GetDataRoomInnerItems')} wrapperClassName="fluid-y" >
        <div className="relative fluid-y screen_wrapper">
          <div className="row">
            <div className="column small-12">
              <div className="page_top page_top-tall">
                <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                  <h1>Data room</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="column small-12">
              <div className="medium-flex-container align-justify mb_30">
                <Breadcrumb
                  separator={<Icon type="key-right" />}
                  itemRender={itemRender}
                  routes={routes}
                />

                <div className="data_actions">
                  <Dropdown
                    placement="topRight"
                    trigger={['click']}
                    overlay={
                      <div className="bg-white radius-4 shadow-layout drop_menu">
                        {
                          this.hasAccessToCreateFile()
                            ? (
                              <div className="drop_menu_item" onClick={() => this.toggleFileModal(true)} >
                                <Icon type="upload" />
                                <span className="text text-15 font-medium font-uppercase font-spacing-04 color-grey" >
                                  Upload a file
                                </span>
                              </div>
                            ) : null
                        }
                        <div className="drop_menu_item" onClick={() => this.toggleFolderModal(true)} >
                          <Icon type="plus" />
                          <span className="text text-15 font-medium font-uppercase font-spacing-04 color-grey" >
                            New folder
                          </span>
                        </div>
                      </div>
                    }
                  >
                    <Button
                      title="New Message"
                      prefix={<Icon type="plus" size={18} style={{ marginRight: 10 }} />}
                      size="lg"
                      theme="blue"
                      className="btn-shadow radius-8 float_button"
                      type="button"
                      onClick={this.handleMessageWindowActive}
                    />
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>

          { ( !files.length && !folders.length)
            ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span className='color-grey' >
                    You don't have any files and folders yet.
                  </span>
                }
              />
            ) : null
          }

          {
            folders.length
              ? (
                <div className="data_room_list">
                  <div className="row">
                    <div className="column small-12">
                      <div className="custom-headline text text-22 font-bold color-grey mb_20">
                        <h2>Folders</h2>
                      </div>
                    </div>

                    {folders.map((element, index) => (
                      <div key={index} className="column small-12 medium-4 large-3">
                        <FileCard
                          id={element.id}
                          type='folder'
                          folderID={0}
                          businessID={isBusiness ? business : null}
                          isBusiness={isBusiness}
                          hasAccessToShare
                          title={element.name}
                          img={<Icon type="folder-file" size={27} />}
                          actionsDisabled={this.isActionsDisabled(element.creator)}
                          isPrivate={element.private}
                          onClick={() => this.setInnerFolder(element.name, element.id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : null
          }

          {
            files.length
              ? (
                <div className="data_room_list">
                  <div className="row">
                    <div className="column small-12">
                      <div className="custom-headline text text-22 font-bold color-grey mb_20">
                        <h2>My Files</h2>
                      </div>
                    </div>

                    {files.map((element, index) => {
                      const fileIcon = getDataRoomIconFromFileExtension(element.name);
                      return (
                        <div key={index} className="column small-12 medium-4 large-3">
                          <FileCard
                            id={element.id}
                            type='file'
                            isPrivate={element.private}
                            onClick={() => this.handleFileDownload(element)}
                            folderID={id}
                            title={element.name}
                            img={
                              <img alt="" src={require(`../../assets/img/${fileIcon}`)} />
                            }
                            hasAccessToShare
                            isBusiness={isBusiness}
                            businessID={isBusiness ? business : null}
                            actionsDisabled={this.isActionsDisabled(element.creator)}
                            fileSrc={{
                              token: element.token,
                              year: element.year,
                              month: element.month,
                              day: element.day,
                            }}
                          />
                        </div>
                      )
                    })}
                  </div>

                  <div className="columns small-12 text-center mt_20">
                    <Button
                      title="Load more"
                      size="xl"
                      theme="lightblue"
                      mode="bordered"
                      className="radius-8"
                      type="button"
                      onClick={this.loadMoreInnerItems}
                      disabled={this.isLoadMoreButtonDisabled()}
                    />
                  </div>
                </div>
              ) : (
                this.hasAccessToCreateFile()
                  ? (
                    <div className="row align-center">
                      <div className="column small-12 medium-9 large-6">
                        <FileUpload folderID={id} businessID={isBusiness ? business : 0} />
                      </div>
                    </div>
                  ) : null
              )
          }

          {
            popupFileVisible &&
              <PopUp
                visible
                className="relative_parent"
                innerClassNames="shadow-layout bg-white radius-10 relative md base tall"
              >
                <div className="flex-container align-middle align-justify mb_30">
                  <div className="text text-20 font-bold color-grey">Upload a file</div>
                  <div
                    onClick={() => this.toggleFileModal(false)}
                    className="popup_close static color-grey-700"
                  >
                    <Icon type="cancel"/>
                  </div>
                </div>
  
                <FileUpload folderID={id} businessID={isBusiness ? business : 0} />
              </PopUp>
          }

          <PopUp
            className="relative_parent"
            visible={popupFolderVisible}
            innerClassNames="shadow-layout bg-white radius-10 relative md base tall"
          >
            <Spin spinning={loadingState && loadingType === "AddDataRoomItem"} >
              <div className="flex-container align-middle align-justify mb_30">
                <div className="text text-20 font-bold color-grey">New Folder</div>
                <div className="popup_close static color-grey-700" onClick={() => this.toggleFolderModal(false)}>
                  <Icon type="cancel"/>
                </div>
              </div>

              <div className="form-item mb_40">
                <Form.Item>
                  {getFieldDecorator("folderName", {
                    rules: [{required: true, message: "required"}]
                  })(
                    <Input autoComplete="off" placeholder="NO NAME" />
                  )}
                </Form.Item>
              </div>

              <div className="btn_group text-right">
                <Button
                  title="Cancel"
                  size="lg"
                  theme="default"
                  className="btn-link font-uppercase"
                  onClick={() => this.toggleFolderModal(false)}
                />
                <Button
                  title="Create"
                  size="lg"
                  theme="blue"
                  className="radius-8"
                  onClick={this.addNewFolder}
                />
              </div>
            </Spin>
          </PopUp>

        </div>
      </Spin>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.dataRoom.loading,
  errorMessage: state.dataRoom.errorMessage,
  dataRoomItems: state.dataRoom.dataRoomItems,
  dataRoomInnerItems: state.dataRoom.dataRoomInnerItems,
  dataRoomInnerItemsCount: state.dataRoom.dataRoomInnerItemsCount,
  currentInnerPage: state.dataRoom.currentInnerPage,
  userID: state.user.xID,
  role: state.user.role
});

const mapDispatchToProps = dispatch => ({
  getDataRoomItems: () => dispatch(getDataRoomItems()),
  getDataRoomInnerItems: (parent, business, isNeededToStartFromBeginning) => dispatch(getDataRoomInnerItems(parent, business, isNeededToStartFromBeginning)),
  downloadDataRoomItem: (token, year, month, day, id, fileName) => dispatch(downloadDataRoomItem(token, year, month, day, id, fileName)),
  getTags: () => dispatch(getTags()),
  addDataRoomItem: (parent, name, type, file, business, isPrivate) => dispatch(addDataRoomItem(parent, name, type, file, business, isPrivate)),
});

const DataRoomInnerScreen = Form.create()(DataRoomInner);

export default connect(mapStateToProps, mapDispatchToProps)(DataRoomInnerScreen);
