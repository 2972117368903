import {
  changePasswordStart, changePasswordSuccess, changePasswordFail
} from "../actions/passwordActions";
import CommonUtils from "../../../utils/CommonUtils";

export const changePassword = (old_password, new_password) => ((dispatch, getState) => {
  dispatch(changePasswordStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  const params = {
    old_password,
    new_password
  };

  CommonUtils.apiCall("post", "private", "shared/changePassword", params, config)
    .then(response => {
      if (response.data.success) {
        localStorage.setItem('xTOKEN', response.data.success);
        dispatch(changePasswordSuccess({
          newXToken: response.data.success,
        }));
      }
      else {
        dispatch(changePasswordFail(response.data.error));
      }
    })
    .catch(error => {
      dispatch(changePasswordFail("Error"));
    });
});