import React from "react";
import "./_style.scss";
import Button from "../../components/Button/Button";

const NotFound = () => (
  <div className="not-found-wrapper">
    <h1>404</h1>
    <h2>PAGE NOT FOUND</h2>
    <p>The page you are looking for might been removed had its name changed or is temporarily unavailable.</p>
    <Button
      title="Homepage"
      size="lg"
      theme="lightblue"
      className="radius-10 m_0"
      linkTo="/"
    />
  </div>
);

export default NotFound;