import React, {Component} from "react";
import {connect} from "react-redux";
import {Spin} from "antd";

import {activateUserRegistration} from "../../redux/registration/thunks/registrationActivationThunk";
import RegistrationSuccess from "../Registration/RegistrationSuccess";

class Activation extends Component {
  componentDidMount() {
    const {activateUserRegistration} = this.props;
    const activationCode = this.props.match.params.code;
    activateUserRegistration(activationCode);
  }

  render() {
    const {loading, errorMessage} = this.props;

    return (
      loading.state && loading.type === "ActivateUserRegistration" ?
        <Spin spinning />
      :
      errorMessage.message && errorMessage.type === "ActivateUserRegistration" ?
        <div className="site_text__title text text-28 text-height-40 font-heavy font-uppercase color-grey font-spacing-02">
          {errorMessage.message}
        </div>
      :
        <RegistrationSuccess from="activation"/>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.registration.loading,
  errorMessage: state.registration.errorMessage
});

const mapDispatchToProps = dispatch => ({
  activateUserRegistration: (activationCode) => dispatch(activateUserRegistration(activationCode))
});

export default connect(mapStateToProps, mapDispatchToProps)(Activation);