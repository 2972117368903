import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Breadcrumb,
  Input,
  Spin,
  Form,
  message,
  Dropdown,
  Empty,
} from 'antd';

import history from "../../history";
import {
  addDataRoomItem,
  downloadDataRoomItem,
  getDataRoomItems,
  getVirtualDataRoomItems
} from "../../redux/dataRoom/thunks/dataRoomThunks";
import { getTags } from "../../redux/tags/thunks/tagsThunks";
import { getBusinesses } from "../../redux/business/thunks/businessThunk";
import { removeDataRoomItems, resetDataRoomFilter } from "../../redux/dataRoom/actions/dataRoomActions";
import { getDataRoomIconFromFileExtension } from "../../utils/getDataRoomIconFromFileExtension";
import { getDataRoomItemsByType } from "../../utils/getDataRoomItemsByType";
import { handleErrorMessage } from "../../utils/handleErrorMessage";
import { handleLoading } from "../../utils/handleLoading";

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import PopUp from "../../components/PopUp/PopUp";
import FileCard from "../../components/FileCard/FileCard";
import FileUpload from "../../components/FileUpload/FileUpload";
import './style.scss';

class DataRoom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupFolderVisible: false,
      popupFileVisible: false
    }
  }

  toggleFolderModal = (showStatus) => {
    this.setState({
      popupFolderVisible: showStatus
    }, () => {
      if (!showStatus) {
        this.props.form.resetFields();
      } else {
        window.scroll(0 ,0);
      }
    })
  };

  toggleFileModal = (showStatus) => {
    this.setState({
      popupFileVisible: showStatus
    }, () => {
      if (showStatus) {
        window.scroll(0 ,0);
      }
    });
  };

  addNewFolder = () => {
    const {addDataRoomItem} = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        addDataRoomItem(0, values.folderName, "directory", 0, 0, false);
      }
    });
  };

  handleFileDownload = (fileItem) => {
    const { downloadDataRoomItem } = this.props;
    const {
      token,
      year,
      month,
      day,
      id,
      name
    } = fileItem;

    downloadDataRoomItem(token, year, month, day, id, name);
  };

  loadMoreOnClick = () => {
    const { getDataRoomItems } = this.props;
    getDataRoomItems();
  };

  loadMoreBusinessOnClick = () => {
    const { getVirtualDataRoomItems } = this.props;
    getVirtualDataRoomItems();
  };

  isLoadMoreBusinessDisabled = () => {
    const { virtualDataRoomItemsCurrentPage, virtualDataRoomItemsCount } = this.props;
    const roundedCount = Math.ceil(virtualDataRoomItemsCount / 10) * 10;
    const checkingNumber = virtualDataRoomItemsCurrentPage * 10;

    if (roundedCount >= checkingNumber) { // for avoiding of calling endless getBusinesses() thunk
      return false;
    }

    return true;
  };

  isLoadMoreButtonDisabled = () => {
    const { currentPage, dataRoomItemsCount } = this.props;
    const roundedCount = Math.ceil(dataRoomItemsCount / 10) * 10;
    const checkingNumber = currentPage * 10;

    return roundedCount < checkingNumber;
  };

  isActionsDisabled = (creatorID) => {
    const { role, userID } = this.props;

    if (role !== "ADMIN") {
      if (parseInt(creatorID) !== parseInt(userID)) {
        return true;
      }
    }

    return false;
  };

  getBusinessFolders = () => {
    const { virtualDataRoomItems } = this.props;
    let businessesList = [];

    virtualDataRoomItems && virtualDataRoomItems.map(element => {
      return businessesList.push({
        id: element.id,
        parent: 0,
        creator: element.creator,
        business: element.id,
        type: "DIRECTORY",
        source: element.source,
        private: 0,
        name: element.name,
        created: element.created,
        updated: null
      });
    });

    return businessesList;
  };

  handleFolderOnClick = (folderItem) => {
    const path = folderItem.business ? `/data-room/0/${folderItem.name}/true/${folderItem.id}` : `/data-room/${folderItem.id}/${folderItem.name}`;
    const oldRoutes = JSON.parse(localStorage.getItem('routes'));
    oldRoutes.push({
      path: path,
      breadcrumbName: folderItem.name,
    });
    localStorage.setItem('routes', JSON.stringify(oldRoutes));
    history.push(path);
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      loading,
      errorMessage,
      form,
      dataRoomItems,
    } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;

    if (loadingType === "AddDataRoomItem" || errorMessage.type === "AddDataRoomItem") {
      if (!loadingState && (prevProps.loading.type === "AddDataRoomItem" && prevProps.loading.state)) {
        this.setState({
          popupFolderVisible: false,
          popupFileVisible: false
        }, () => {
          form.resetFields();
        })
      }
    }

    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "GetDataRoomItems")
      || handleErrorMessage(errorMessage, prevProps.errorMessage, "AddDataRoomItem")
      || handleErrorMessage(errorMessage, prevProps.errorMessage, "RemoveDataRoomItem")
      || handleErrorMessage(errorMessage, prevProps.errorMessage, "RenameDataRoomItem")) {
      message.error(errorMessage.message);
      if (errorMessage.message === "ACCESS FILES") {
        history.push("/");
      }
    }

    if ((dataRoomItems && prevProps.dataRoomItems) && (dataRoomItems.length > prevProps.dataRoomItems.length)) { //handling if new file was added
      this.setState({
        popupFileVisible: false
      })
    }
  }

  componentWillUnmount() {
    const { resetDataRoomFilter, removeDataRoomItems } = this.props;
    resetDataRoomFilter();
    removeDataRoomItems();
  }

  componentDidMount() {
    const {getDataRoomItems, getVirtualDataRoomItems, role, getTags} = this.props;
    const routes = [
      {
        path: '/data-room',
        breadcrumbName: 'Data Room',
      }
    ];

    getDataRoomItems();
    if (role === 'ADMIN') {
      getTags();
    }

    localStorage.setItem('routes', JSON.stringify(routes));
    getDataRoomItems(1);
    getVirtualDataRoomItems(1);
  }

  render() {
    const { dataRoomItems, loading, role } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { popupFolderVisible, popupFileVisible } = this.state;
    const dataRoomControlledItems = dataRoomItems ? dataRoomItems : [];
    const businessFolders = this.getBusinessFolders();

    const files = getDataRoomItemsByType(dataRoomControlledItems, "FILE");
    const folders = getDataRoomItemsByType(dataRoomControlledItems, "DIRECTORY");
    const routes = JSON.parse(localStorage.getItem('routes'));

    const itemRender = (route) => (
      <span className="text text-20 font-semibold color-grey breadcrumb_item">
        <Icon type="folder-file" size={24} className="inline-middle" />
        {route.breadcrumbName}
      </span>
    );

    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                <h1>Data room</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="column small-12">
            <div className="medium-flex-container align-justify mb_15">
              <Breadcrumb
                separator={<Icon type="key-right" />}
                itemRender={itemRender}
                routes={routes}
                className="mb_25"
              />

              <div className="data_actions">
                <Dropdown
                  placement="topRight"
                  trigger={['click']}
                  overlay={
                    <div className="bg-white radius-4 shadow-layout drop_menu">
                      <div className="drop_menu_item" onClick={() => this.toggleFileModal(true)}>
                        <Icon type="upload" />
                        <span className="text text-15 font-medium font-uppercase font-spacing-04 color-grey">
                          Upload a file
                        </span>
                      </div>
                      <div className="drop_menu_item" onClick={() => this.toggleFolderModal(true)}>
                        <Icon type="plus" />
                        <span className="text text-15 font-medium font-uppercase font-spacing-04 color-grey">
                          New folder
                        </span>
                      </div>
                    </div>
                  }
                >
                  <Button
                    title="New Message"
                    prefix={<Icon type="plus" size={18} style={{ marginRight: 10 }} />}
                    size="lg"
                    theme="blue"
                    className="btn-shadow radius-8 float_button"
                    type="button"
                    onClick={this.handleMessageWindowActive}
                  />
                </Dropdown>
              </div>
            </div>
          </div>
        </div>

        { (!businessFolders.length && !files.length && !folders.length)
          ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span className='color-grey' >
                  You don't have any files and folders yet. Click on the 'Plus' button to add
                </span>
              }
            />
          ) : null
        }

        {
          businessFolders.length
            ? (
              <div className="data_room_list">
                <div className="row">
                  <div className="column small-12">
                    <div className="custom-headline text text-22 font-bold color-grey mb_20">
                      <h2>Business Folders</h2>
                    </div>
                  </div>

                  {businessFolders.map((element, index) => (
                    <div key={index} className="column small-12 medium-4 large-3">
                      <FileCard
                        id={element.id}
                        isBusiness
                        type='folder'
                        folderID={0}
                        title={element.name}
                        img={<Icon type="folder-file" size={27} />}
                        actionsDisabled={role !== "ADMIN"}
                        isPrivate={element.private}
                        onClick={() => this.handleFolderOnClick(element)}
                      />
                    </div>
                  ))}

                  <div className="columns small-12 text-center mt_20">
                    <Button
                      title="Load more business folders"
                      size="xl"
                      theme="lightblue"
                      mode="bordered"
                      className="radius-8 mb_15"
                      type="button"
                      onClick={this.loadMoreBusinessOnClick}
                      loading={handleLoading(loading, "GetVirtualDataRoomItems")}
                      disabled={this.isLoadMoreBusinessDisabled()}
                    />
                  </div>
                </div>
              </div>
            ) : null
        }

        {
          files.length
            ? (
              <div className="data_room_list">
                <div className="row">
                  <div className="column small-12">
                    <div className="custom-headline text text-22 font-bold color-grey mb_20">
                      <h2>My Files</h2>
                    </div>
                  </div>

                  {files.map((element, index) => {
                    const fileIcon = getDataRoomIconFromFileExtension(element.name);
                    return (
                      <div key={index} className="column small-12 medium-4 large-3">
                        <FileCard
                          id={element.id}
                          type='file'
                          isPrivate={element.private}
                          onClick={() => this.handleFileDownload(element)}
                          folderID={0}
                          title={element.name}
                          hasAccessToShare
                          img={<img alt="" src={require(`../../assets/img/${fileIcon}`)} />}
                          actionsDisabled={this.isActionsDisabled(element.creator)}
                          fileSrc={{
                            token: element.token,
                            year: element.year,
                            month: element.month,
                            day: element.day,
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : null
        }

        {
          folders.length
            ? (
              <div className="data_room_list">
                <div className="row">
                  <div className="column small-12">
                    <div className="custom-headline text text-22 font-bold color-grey mb_20">
                      <h2>Folders</h2>
                    </div>
                  </div>

                  {folders.map((element, index) => (
                    <div key={index} className="column small-12 medium-4 large-3">
                      <FileCard
                        id={element.id}
                        type='folder'
                        folderID={0}
                        title={element.name}
                        hasAccessToShare
                        img={<Icon type="folder-file" size={27} />}
                        actionsDisabled={this.isActionsDisabled(element.creator)}
                        isPrivate={element.private}
                        onClick={() => this.handleFolderOnClick(element)}
                      />
                    </div>
                  ))}

                  <div className="columns small-12 text-center mt_20">
                    <Button
                      title="Load more"
                      size="xl"
                      theme="lightblue"
                      mode="bordered"
                      className="radius-8"
                      type="button"
                      onClick={this.loadMoreOnClick}
                      disabled={this.isLoadMoreButtonDisabled()}
                    />
                  </div>
                </div>
              </div>
            ) : null
        }

        <PopUp
          className="relative_parent"
          visible={popupFolderVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative md base tall"
        >
          <Spin spinning={handleLoading(loading, "AddDataRoomItem")} >
            <div className="flex-container align-middle align-justify mb_30">
              <div className="text text-20 font-bold color-grey">New Folder</div>
              <div className="popup_close static color-grey-700" onClick={() => this.toggleFolderModal(false)}>
                <Icon type="cancel"/>
              </div>
            </div>

            <div className="form-item mb_40">
              <Form.Item>
                {getFieldDecorator("folderName", {
                  rules: [{required: true, message: "required"}]
                })(
                  <Input autoComplete="off" placeholder="NO NAME" />
                )}
              </Form.Item>
            </div>

            <div className="btn_group text-right">
              <Button
                title="Cancel"
                size="lg"
                theme="default"
                className="btn-link font-uppercase"
                onClick={() => this.toggleFolderModal(false)}
              />
              <Button
                title="Create"
                size="lg"
                theme="blue"
                className="radius-8"
                onClick={this.addNewFolder}
              />
            </div>
          </Spin>
        </PopUp>

        {
          popupFileVisible
            ? (
              <PopUp
                visible
                className="relative_parent"
                innerClassNames="shadow-layout bg-white radius-10 relative md base tall"
              >
                <div className="flex-container align-middle align-justify mb_30">
                  <div className="text text-20 font-bold color-grey">Upload a file</div>
                  <div
                    onClick={() => this.toggleFileModal(false)}
                    className="popup_close static color-grey-700"
                  >
                    <Icon type="cancel"/>
                  </div>
                </div>

                <FileUpload  />
              </PopUp>
            ) : null
        }

      </div>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.dataRoom.loading,
  errorMessage: state.dataRoom.errorMessage,
  dataRoomItems: state.dataRoom.dataRoomItems,
  currentPage: state.dataRoom.currentPage,
  dataRoomItemsCount: state.dataRoom.dataRoomItemsCount,
  role: state.user.role,
  userID: state.user.xID,
  businesses: state.business.businesses,
  dataRoomVirtualItems: state.business.dataRoomVirtualItems,
  virtualDataRoomItems: state.dataRoom.virtualDataRoomItems,
  virtualDataRoomItemsCount: state.dataRoom.virtualDataRoomItemsCount,
  virtualDataRoomItemsCurrentPage: state.dataRoom.virtualDataRoomItemsCurrentPage,
  currentDataRoomVirtualItemsPage: state.business.currentDataRoomVirtualItemsPage,
  dataRoomVirtualItemsCount: state.business.dataRoomVirtualItemsCount,
  rootFilter: state.dataRoom.rootFilter,
});

const mapDispatchToProps = dispatch => ({
  getBusinesses: () => dispatch(getBusinesses()),
  getDataRoomItems: (currentPage) => dispatch(getDataRoomItems(currentPage)),
  removeDataRoomItems: () => dispatch(removeDataRoomItems()),
  addDataRoomItem: (parent, name, type, file, business, isPrivate) => dispatch(addDataRoomItem(parent, name, type, file, business, isPrivate)),
  downloadDataRoomItem: (token, year, month, day, id, fileName) => dispatch(downloadDataRoomItem(token, year, month, day, id, fileName)),
  getVirtualDataRoomItems: (hardCodePage) => dispatch(getVirtualDataRoomItems(hardCodePage)),
  resetDataRoomFilter: () => dispatch(resetDataRoomFilter()),
  getTags: () => dispatch(getTags()),
});

const DataRoomScreen = Form.create()(DataRoom);

export default connect(mapStateToProps, mapDispatchToProps)(DataRoomScreen);