import {
  SEARCH_USERS_START, SEARCH_USERS_SUCCESS, SEARCH_USERS_FAIL,
  SEARCH_ALL_USERS_START, SEARCH_ALL_USERS_SUCCESS, SEARCH_ALL_USERS_FAIL,
  SEARCH_USERS_FILTER_START, SEARCH_USERS_FILTER_SUCCESS, SEARCH_USERS_FILTER_FAIL,
  SET_USERS_FILTER, RESET_USERS_FILTER,
  SEARCH_STATISTICS_USERS_START, SEARCH_STATISTICS_USERS_SUCCESS, SEARCH_STATISTICS_USERS_FAIL, RESET_USERS_LIST,
  GET_NOTIFY_ENABLED_START, GET_NOTIFY_ENABLED_SUCCESS, GET_NOTIFY_ENABLED_FAIL,
  SET_NOTIFY_ENABLED_START, SET_NOTIFY_ENABLED_SUCCESS, SET_NOTIFY_ENABLED_FAIL,
  SEARCH_NOT_INVOLVED_USERS_START,
  SEARCH_NOT_INVOLVED_USERS_SUCCESS,
  SEARCH_NOT_INVOLVED_USERS_FAIL,
} from "../../constants";

export const searchUsersStart = () => ({
  type: SEARCH_USERS_START
});

export const searchUsersSuccess = payload => ({
  type: SEARCH_USERS_SUCCESS,
  payload
});

export const searchUsersFail = payload => ({
  type: SEARCH_USERS_FAIL,
  payload
});

export const searchAllUsersStart = () => ({
  type: SEARCH_ALL_USERS_START
});

export const searchAllUsersSuccess = payload => ({
  type: SEARCH_ALL_USERS_SUCCESS,
  payload
});

export const searchAllUsersFail = payload => ({
  type: SEARCH_ALL_USERS_FAIL,
  payload
});

export const searchNotInvolvedUsersStart = () => ({
  type: SEARCH_NOT_INVOLVED_USERS_START
});

export const searchNotInvolvedUsersSuccess = payload => ({
  type: SEARCH_NOT_INVOLVED_USERS_SUCCESS,
  payload
});

export const searchNotInvolvedUsersFail = payload => ({
  type: SEARCH_NOT_INVOLVED_USERS_FAIL,
  payload
});

export const searchUsersFilterStart = () => ({
  type: SEARCH_USERS_FILTER_START
});

export const searchUsersFilterSuccess = payload => ({
  type: SEARCH_USERS_FILTER_SUCCESS,
  payload
});

export const searchUsersFilterFail = payload => ({
  type: SEARCH_USERS_FILTER_FAIL,
  payload
});

export const getNotifyEnabledStart = () => ({
  type: GET_NOTIFY_ENABLED_START
});

export const getNotifyEnabledSuccess = payload => ({
  type: GET_NOTIFY_ENABLED_SUCCESS,
  payload
});

export const getNotifyEnabledFail = payload => ({
  type: GET_NOTIFY_ENABLED_FAIL,
  payload
});

export const setNotifyEnabledStart = () => ({
  type: SET_NOTIFY_ENABLED_START
});

export const setNotifyEnabledSuccess = payload => ({
  type: SET_NOTIFY_ENABLED_SUCCESS,
  payload
});

export const setNotifyEnabledFail = payload => ({
  type: SET_NOTIFY_ENABLED_FAIL,
  payload
});

export const setUsersFilter = payload => ({
  type: SET_USERS_FILTER,
  payload,
});

export const resetUsersFilter = () => ({ type: RESET_USERS_FILTER });
export const resetUsersList = () => ({ type: RESET_USERS_LIST });

export const searchStatisticsUsersStart = () => ({ type: SEARCH_STATISTICS_USERS_START });

export const searchStatisticsUsersSuccess = payload => ({
  type: SEARCH_STATISTICS_USERS_SUCCESS,
  payload,
});

export const searchStatisticsUsersFail = payload => ({
  type: SEARCH_STATISTICS_USERS_FAIL,
  payload,
});
