import React, {Component} from 'react';
import RadioApp from "./Radio";
import Inputs from "./Inputs";
import Button from "../components/Button/Button";
import {Link, NavLink} from "react-router-dom";
import Icon from "../components/Icon/Icon";
import ListItem from "../components/ListItem/ListItem";

export default class Tests extends Component {
  render() {
    return (
      <>
      {/*<Multiselect/>*/}
      {/*<RadioApp/>*/}
      {/*<Checkboxes/>*/}
      <div className="row">
        <div className="column large-10">
          <Inputs/>
          <br/><br/>
          <RadioApp/>
          <Link to="/" className="btn_proto btn_link">
            Link
          </Link>
          <NavLink to="/tests" className="btn_proto btn_link">
            Link
          </NavLink>

          <br/><br/>

          <Button title="Button" size="lg" theme="blue" className="btn-shadow" type="button" disabled />
          <Button title="Button" size="lg" theme="lightblue" mode="bordered" className="btn-shadow" />
          <Button title="Button" size="lg" theme="white" mode="bordered" className="btn-shadow" />

          <Button title={<Icon type="key-left" size="16" />} size="md" theme="lightblue" />

          <Button title="Button" size="lg" theme="default" mode="bordered" className="btn-shadow" />

          <Button title="Upload an image" prefix={<Icon type="upload" />} size="lg" theme="white" className="btn-link" linkTo="/" />

          <br/><br/>
        </div>
        <div className="column small-12 large-4">
          <ListItem title="PROJECT HEAT #1494"
                    desc="Industrial services business with high margins"
                    img={require("../../src/assets/img/joel-filipe-RFDP7_80v5A-unsplash.png")}
                    tag="new"
                    footer={<>
                    <div className="font-medium">
                      Deadline
                    </div>
                    <div className="font-bold">
                      FEB 13, 2020
                    </div>
                    </>} />
        </div>
      </div>

      {/*<Button title="Button" size="btn-lg" className="radius-10" />*/}
      </>
  );
  }
}
