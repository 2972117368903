import {
  SET_MESSAGES_FILTER, RESET_MESSAGES_FILTER,
  GET_MESSAGES_START, GET_MESSAGES_SUCCESS, GET_MESSAGES_FAIL,
  GET_MESSAGE_START, GET_MESSAGE_SUCCESS, GET_MESSAGE_FAIL,
  ADD_MESSAGE_START, ADD_MESSAGE_SUCCESS, ADD_MESSAGE_FAIL
} from "../../constants";

export const resetMessagesFilter = () => ({ type: RESET_MESSAGES_FILTER });

export const setMessagesFilter = (payload) => ({
  type: SET_MESSAGES_FILTER,
  payload,
});

export const getMessagesStart = () => ({
  type: GET_MESSAGES_START
});

export const getMessagesSuccess = payload => ({
  type: GET_MESSAGES_SUCCESS,
  payload
});

export const getMessagesFail = payload => ({
  type: GET_MESSAGES_FAIL,
  payload
});

export const getMessageStart = () => ({
  type: GET_MESSAGE_START
});

export const getMessageSuccess = payload => ({
  type: GET_MESSAGE_SUCCESS,
  payload
});

export const getMessageFail = payload => ({
  type: GET_MESSAGE_FAIL,
  payload
});

export const addMessageStart = () => ({
  type: ADD_MESSAGE_START
});

export const addMessageSuccess = payload => ({
  type: ADD_MESSAGE_SUCCESS,
  payload
});

export const addMessageFail = payload => ({
  type: ADD_MESSAGE_FAIL,
  payload
});