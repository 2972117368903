import {
  ADD_NOTE_START, ADD_NOTE_SUCCESS, ADD_NOTE_FAIL,
  UPDATE_NOTE_START, UPDATE_NOTE_SUCCESS, UPDATE_NOTE_FAIL,
  REMOVE_NOTE_START, REMOVE_NOTE_SUCCESS, REMOVE_NOTE_FAIL,
  GET_NOTES_START, GET_NOTES_SUCCESS, GET_NOTES_FAIL,
} from '../../constants';

export const addNoteStart = () => ({ type: ADD_NOTE_START });

export const addNoteSuccess = payload => ({ type: ADD_NOTE_SUCCESS, payload });

export const addNoteFail = payload => ({ type: ADD_NOTE_FAIL, payload });

export const updateNoteStart = () => ({ type: UPDATE_NOTE_START });

export const updateNoteSuccess = payload => ({ type: UPDATE_NOTE_SUCCESS, payload });

export const updateNoteFail = payload => ({ type: UPDATE_NOTE_FAIL, payload });

export const removeNoteStart = () => ({ type: REMOVE_NOTE_START });

export const removeNoteSuccess = payload => ({ type: REMOVE_NOTE_SUCCESS, payload });

export const removeNoteFail = payload => ({ type: REMOVE_NOTE_FAIL, payload });

export const getNotesStart = () => ({ type: GET_NOTES_START });

export const getNotesSuccess = payload => ({ type: GET_NOTES_SUCCESS, payload });

export const getNotesFail = payload => ({ type: GET_NOTES_FAIL, payload });
