import {
  registerUserStart, registerUserSuccess, registerUserFail,
  setOnboardingDataStart, setOnboardingDataSuccess, setOnboardingDataFail,
  getOnboardingStart, getOnboardingSuccess, getOnboardingFail
} from "../actions/registrationActions";
import CommonUtils from "../../../utils/CommonUtils";

export const registerUser = (userType, param) => ((dispatch, getState) => {
  // userType can be - professional, advisor, investor, owner
  dispatch(registerUserStart());

  CommonUtils.apiCall("post", "public", `${userType}/register`, param, null)
    .then((response) => {
      if (response.data.success) {
        dispatch(registerUserSuccess({
          userInfo: param,
          isEmailSent: response.data.success.checkMail,
        }));
      }
      else {
        dispatch(registerUserFail({message: response.data.error, userInfo: param}));
      }
    })
    .catch((error) => {
      dispatch(registerUserFail("Some error occurred"));
    })
});

export const setOnboardingData = (param, callback) => ((dispatch, getState) => {
  dispatch(setOnboardingDataStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "investor/setOnboardingData", param, config)
    .then(response => {
      if (response.data.success) {
        dispatch(setOnboardingDataSuccess());
        if (callback) {
          callback();
        }
      }
      else {
        dispatch(setOnboardingDataFail(response.data.error));
      }
    })
    .catch(() => dispatch(setOnboardingDataFail("Error")));
});

export const getOnboarding = () => ((dispatch, getState) => {
  dispatch(getOnboardingStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", "investor/getOnboarding", {}, config)
    .then(response => {
      if (response.data.success) {
        dispatch(getOnboardingSuccess({
          onboardingData: response.data.success
        }));
      }
      else {
        dispatch(getOnboardingFail(response.data.error));
      }
    })
    .catch(() => dispatch(getOnboardingFail("Error")));
});