import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { Form, Table } from 'antd';

import { formatNumber } from '../../../../utils/formatNumber';
import { useSelector } from 'react-redux';
import { handleLoading } from '../../../../utils/handleLoading';
import { compareTimes } from '../../../../utils/calculateTime';
import CompletedImage from '../../../../assets/img/completed.svg';
import MedalImage from '../../../../assets/img/medal.png';
import Icon from "../../../../components/Icon/Icon";
import NumericInput from "../../../../components/NumericInput/NumericInput";
import Button from "../../../../components/Button/Button";
import './style.scss';

const AuctionActions = ({
  form, offer, offerLeftTime, isShareholder, sharesPrice, offerBidRequest,
  usersLastBid, lastBid, offerCalls, currency, step, ndaSigned, showBidPopup, hideBidPopup,
  scope, offer_closed, getWonBid, internal, external, sharesQty, isFailed, showNdaPopup, success_qty,
}) => {
  const { getFieldDecorator } = form;
  const isExternal = scope === "EXTERNAL";

  const startPrice = isExternal ? external.min : internal.min;
  const internalFailMinimum = internal?.stats?.amount < success_qty;

  const [addBidInterval, setAddBidInterval] = useState(false)
  const [internalConfirmed, setInternalConfirmed] = useState(false)
  const [internalConfirmBtnsDisabled, setInternalConfirmBtnsDisabled] = useState(false)
  const [addBidDisabled, setAddBidDisabled] = useState(false)
  const loading = useSelector((state) => state.offers.loading)
  const priceVal = useRef(null)
  const errorMessage = useSelector((state) => state.offers.errorMessage)
  const isWon = useCallback(
    () => getWonBid(),
    [getWonBid],
  );

  const pageTitles = useMemo(() => ({
    auctionPrice: isExternal ? "Starting Bid:" : "Auction starting shares quantity:",
  }), [isExternal]);

  useEffect(() => {
    if(usersLastBid) {
      setInternalConfirmed(true);
    }
  }, [usersLastBid]);

  useEffect(() => {
    console.log("AFTER", loading.type);
    if (loading.type === 'AddBid' && !loading.state && !errorMessage.message) {
      console.log("BEFORE", loading.type);
      hideBidPopup();
      priceVal.current = ''
      setAddBidInterval(true);
      offerCalls()
      setTimeout(() => {
        setAddBidInterval(false);
      }, 5000);
    }

    if(loading.type === 'AddOverbid') {
      hideBidPopup();
      priceVal.current = '';
    }
  }, [loading, errorMessage, offerCalls]);

  const startingPriceClassNames = useMemo(() => {
    if (!lastBid) {
      return {
        title: 'text-16 font-medium color-grey',
        value: 'text-16 font-bold color-grey price',
      };
    }
    const defaultClasses = 'text-14 font-medium color-grey-700';
    return {
      title: defaultClasses,
      value: `${defaultClasses} price`,
    };
  }, [lastBid]);

  const placeBidOnClick = () => {
    form.validateFields((err, values) => {
      if (isExternal && (err || (startPrice > values.bid || (lastBid && lastBid?.amount + step > values.bid)))) {
        let errorMessage = 'Invalid amount';

        if(startPrice > values.bid) {
          errorMessage = `Bid can't be less than ${formatNumber(startPrice)} ${currency}`;
        }
        if(lastBid && lastBid?.amount + step > values.bid) {
          errorMessage = `Bid can't be less than ${formatNumber(lastBid?.amount + step)} ${currency}`;
        }

        form.setFields({
          bid: {
            value: values.bid,
            errors: [new Error(errorMessage)],
          },
        });
        return;
      }

      if(!isExternal && (err || (values.bid > sharesQty || values.bid < startPrice))) {
        let errorMessage = 'Invalid amount';

        if(values.bid > sharesQty) {
          errorMessage = `Bid can't be more than ${formatNumber(sharesQty)}`;
        }
        if(values.bid < startPrice) {
          errorMessage = `Bid can't be less than ${formatNumber(startPrice)}`;
        }

        form.setFields({
          bid: {
            value: values.bid,
            errors: [new Error(errorMessage)],
          },
        });
        return;
      }

      showBidPopup(values.bid);
    })
  };

  const getAuctionCurrency = () => {
    return isExternal ? currency : '';
  }

  const isNotWonAndExpired = !lastBid?.won && !usersLastBid?.won && !offer_closed && !offerLeftTime?.valid;
  const isBidWinning = isExternal && (lastBid?.user === usersLastBid?.user);
  const isBidPlacedAndWinning = usersLastBid && isBidWinning;
  const isAuctionFinished = lastBid?.won || (!isExternal && usersLastBid?.won) || offer_closed;

  return scope ?
    isAuctionFinished ? (
      <div className='bg-white radius-10 flex-container align-middle align-justify project_direct_card'>
        {/* <div className='flex-container align-middle project_direct_card__title'>
          {
            isWon() ? (
              <img style={{ marginRight: 8 }} src={MedalImage} alt='medal' />
            ) : <Icon type='sold' size={32} style={{ marginRight: 8 }} className='color-grey' />
          }
          <div className="text text-22 font-heavy color-grey">
            {isWon() ? `You won for ${formatNumber(isWon()?.prize ? isWon()?.prize : isWon()?.amount || 0)} ${getAuctionCurrency()}` : 'The auction ended'}
          </div>
        </div> */}
        {
          isFailed ? 
          <div className='flex-container align-middle project_direct_card__title'>
            <div className="text text-18 font-heavy color-grey">
              This deal was failed as it didn’t reached the minimum offer amount.
            </div>
          </div> :
          <>
            <div className='flex-container align-middle project_direct_card__title'>
              {
              isWon() ? (
                  <img style={{ marginRight: 8 }} src={MedalImage} alt='medal' />
                ) : <Icon type='sold' size={32} style={{ marginRight: 8 }} className='color-grey' />
              }
              <div className="text text-18 font-heavy color-grey">
                {isWon() ? isWon().scope === "EXTERNAL" ? 'You won this auction' : 'You won' : 'Auction has now closed. Your bid was exceeded by another investor.'}
              </div>
            </div>
            <div className='sm-text-center'>
              {
                isWon() ?
                <>
                  <div className='text text-20 font-heavy color-grey price mb_10'>
                    Shares: {formatNumber(isWon().amount)}
                  </div>
                  <div className='text text-20 font-heavy color-grey price'>
                    Value: {formatNumber(isWon().cost)} {currency}
                  </div>
                </>
                : null
              }
            </div>
          </>
        }
      </div>
    ) :
      !isExternal && !isNotWonAndExpired && !internalConfirmed
      ? <div className='bg-white radius-10 auction_actions'>
          <div className="text text-20 font-semibold font-uppercase color-grey project_actions__title">
            Are you interested in submitting a bid?
          </div>
          <div className="medium-flex-container align-middle align-center project_actions__buttons">
            <Button
              title="Yes"
              size="xl"
              theme="blue"
              mode="bordered"
              className="radius-8 fluid-x"
              type="button"
              disabled={internalConfirmBtnsDisabled}
              onClick={() => setInternalConfirmed(true)}
            />
            {/* <Button
              title="No"
              size="xl"
              theme="lightblue"
              mode="bordered"
              className="radius-8 fluid-x"
              type="button"
              disabled={internalConfirmBtnsDisabled}
              onClick={() => setInternalConfirmBtnsDisabled(true)}
            /> */}
          </div>
        </div>
      :
      isExternal && !isNotWonAndExpired && !internalConfirmed && !ndaSigned
      ? <div className='bg-white radius-10 auction_actions'>
          <div className="text text-20 font-semibold font-uppercase color-grey project_actions__title">
            INTERESTED IN THIS OPPORTUNITY?
          </div>
          <div className="medium-flex-container align-middle align-center project_actions__buttons">
            <Button
              title="Yes"
              size="xl"
              theme="blue"
              mode="bordered"
              className="radius-8 fluid-x"
              type="button"
              disabled={internalConfirmBtnsDisabled}
              onClick={() => showNdaPopup()}
            />
            {/* <Button
              title="No"
              size="xl"
              theme="lightblue"
              mode="bordered"
              className="radius-8 fluid-x"
              type="button"
              disabled={internalConfirmBtnsDisabled}
              onClick={() => setInternalConfirmBtnsDisabled(true)}
            /> */}
          </div>
        </div>
      :
      !compareTimes(internal.end) && usersLastBid && usersLastBid.scope === "INTERNAL" ?
        offer_closed && !external.requested ? 
          <div className='bg-white radius-10 flex-container align-middle align-justify project_direct_card'>
            <div className='flex-container align-middle project_direct_card__title'>
              <div className="text text-18 font-heavy color-grey">
                {`You Won ${sharesQty} shares`}
              </div>
            </div>
          </div> :
          <div className='bg-white radius-10 flex-container align-middle align-justify project_direct_card'>
            <div className='flex-container align-middle project_direct_card__title'>
              <div className="text text-18 font-heavy color-grey">
                {usersLastBid?.won ? 
                  `You won ${usersLastBid?.amount} shares. The deal is pending review.` :
                    internalFailMinimum ?
                    `The minimum to close was not reached Internally. The offering is now available to External Investors.` :
                    `You have bid ${usersLastBid?.amount} shares. You will be notified of the outcome shortly after the auction closes.`
                }
              </div>
            </div>
          </div>
        :
      isNotWonAndExpired
      ? offer_closed && !external.requested ? 
        <div className='bg-white radius-10 flex-container align-middle align-justify project_direct_card'>
          <div className='flex-container align-middle project_direct_card__title'>
            <div className="text text-18 font-heavy color-grey">
              {`You Won ${sharesQty} shares`}
            </div>
          </div>
        </div> :
        !isExternal ? 
          <div className='bg-white radius-10 flex-container align-middle align-justify project_direct_card'>
            <div className='flex-container align-middle project_direct_card__title'>
              <div className="text text-18 font-heavy color-grey">
                {usersLastBid?.won ? 
                  `You won ${usersLastBid?.amount} shares. The deal is pending review.` :
                  `You have bid ${usersLastBid?.amount} shares. You will be notified of the outcome shortly after the auction closes.`
                }
              </div>
            </div>
          </div> : 
          <></>
      : <div className='bg-white radius-10 auction_actions'>
        {addBidInterval ? (
          <div className='text-center'>
            <img className='success-icon' src={CompletedImage} alt='success-icon' />
            <div className='text text-20 font-semibold text-height-30 color-grey' >Your bid is placed!</div>
          </div>
        )
        : <>
            {
              // isAuctionFinished ? (
              //   <div className='bg-white radius-10 flex-container align-middle align-justify project_direct_card'>
              //     <div className='flex-container align-middle project_direct_card__title'>
              //       {
              //         (lastBid?.amount === usersLastBid?.amount) || !isExternal ? (
              //           <img style={{ marginRight: 8 }} src={MedalImage} alt='medal' />
              //         ) : <Icon type='sold' size={32} style={{ marginRight: 8 }} className='color-grey' />
              //       }
              //       <div className="text text-18 font-heavy color-grey">
              //         {(lastBid?.amount === usersLastBid?.amount) || !isExternal ? 'You won this auction' : 'Sold for'}
              //       </div>
              //     </div>
              //     <div className='flex-container align-bottom'>
              //       {(lastBid?.amount === usersLastBid?.amount) || !isExternal ? <div className='text text-16 font-medium color-grey-700'>for {!isExternal && "total"}</div> : null}
              //       <div className='text text-22 font-heavy color-grey price'>
              //         {formatNumber(isExternal ?lastBid?.amount : usersLastBid?.amount * sharesPrice)} {currency}
              //         </div>
              //     </div>
              //   </div>
              // ) :
              isBidPlacedAndWinning ? (
                <> <div className='flex-container align-middle align-justify my-win--container'>
                  <div className='flex-child-shrink'>
                    <div className='flex-container align-bottom'>
                      <div className={`text text-16 font-medium text-height-30 color-grey`}>Shares Offered: </div>
                      <div className={`text text-16 font-bold text-height-30 color-grey price`}>
                        {formatNumber(isExternal ? sharesQty - internal.stats.amount : sharesQty)}
                      </div>
                    </div>
                    <div className='flex-container align-middle flex-wrap my-win-panel'>
                      <div className='text text-16 text-height-30 font-medium color-grey'>Your bid:</div>
                      <div className='text text-16 text-height-30 font-heavy color-green my-win--amount'>{formatNumber(usersLastBid?.amount)} {getAuctionCurrency()}</div>
                      {isExternal && <div className='text text-14 text-height-15 font-normal color-green'>You are currently the highest bidder</div>}
                    </div>
                    <div className='flex-container align-bottom'>
                      <div className={`text ${startingPriceClassNames.title}`}>{pageTitles.auctionPrice}</div>
                      <div className={`text ${startingPriceClassNames.value}`}>{formatNumber(startPrice)} {getAuctionCurrency()}</div>
                    </div>
                  </div>
                  {isExternal && <img src={MedalImage} alt='medal' className='shrink' />}
                </div>
                  {
                    isExternal && offerBidRequest ? 
                    <>
                      <div className='flex-container align-bottom'>
                        <div className={`text text-16 font-medium text-height-30 color-grey`}>Bid Requested: </div>
                        <div className={`text text-16 font-bold text-height-30 color-grey price`}>
                          {formatNumber(offerBidRequest.amount)} {getAuctionCurrency()}
                        </div>
                      </div>
                      <div className='flex-container align-bottom mb_10'>
                        <div className={`text text-12 font-bold text-height-20 color-grey`}>
                          Thank you for your bid. This bid has been flagged and will need to be approved by Admin. We will contact you shortly.
                        </div>
                      </div>
                    </>
                      : null
                  }
                </>
              )
              : <>
                <div className='starting-price' >
                  <div>
                    {
                      isExternal ?
                      <>
                        <div className='flex-container align-bottom'>
                          <div className={`text text-16 font-medium text-height-30 color-grey`}>
                            Shares Offered:
                            <span className={`text text-16 font-bold text-height-30 color-grey price`}>{formatNumber(isExternal ? sharesQty - internal.stats.amount : sharesQty)}</span>
                          </div>
                        </div>
                        <div className='flex-container align-bottom'>
                          <div className={`text text-16 font-medium text-height-30 color-grey`}>
                            {pageTitles.auctionPrice}
                            <span className={`text text-16 font-bold text-height-30 color-grey price`}>{formatNumber(startPrice)} {getAuctionCurrency()}</span>
                          </div>
                        </div>
                        <div className='flex-container align-bottom mb_10'>
                          <div className='text text-16 font-medium text-height-30 color-grey'>
                            Current Bid:
                            <span className='text text-16 font-bold text-height-30 color-grey price'>
                              {lastBid && isExternal ? formatNumber(lastBid.amount) : 'No bids received yet '} {(lastBid && isExternal) && getAuctionCurrency()}
                            </span>
                          </div>
                        </div>
                      </> :
                      <>
                        <div className='flex-container align-bottom'>
                          <div className={`text text-16 font-medium text-height-30 color-grey`}>
                            Price per share: 
                            <span className={`text text-16 font-bold text-height-30 color-grey price`}>{sharesPrice.toFixed(2)} {currency}</span>  
                          </div>
                        </div>

                        <Table
                          className='auction-info-table'
                          columns={[
                            { title: '', dataIndex: 'name', key: 'name', },
                            { title: 'Shares', dataIndex: 'shares', key: 'shares',},
                            { title: currency, dataIndex: 'value',key: 'value',},
                          ]}
                          dataSource={[
                            { key: '1', name: 'Offered', shares: formatNumber(sharesQty), value: formatNumber(sharesQty * sharesPrice),},
                            { key: '2', name: 'Min Investor Bid', shares: formatNumber(startPrice), value: formatNumber(startPrice * sharesPrice),},
                            { key: '3',name: 'Minimum To Close', shares: formatNumber(success_qty),value: formatNumber(success_qty * sharesPrice),},
                          ]}
                          pagination={false}
                        />
                      </>
                    }
                  </div>
                  {
                    (usersLastBid && scope === usersLastBid.scope) &&
                    <div className='flex-container align-middle flex-wrap my-win-panel'>
                      <div className='text text-16 text-height-30 font-medium color-grey'>Your bid:</div>
                      <div className={`text text-16 text-height-30 font-heavy my-win--amount ${isExternal && 'color-red'}`}>{formatNumber(usersLastBid?.amount)} {getAuctionCurrency()}</div>
                      {isExternal && <div className='text text-14 text-height-15 font-normal color-red'>You’ve been outbid. Enter a higher bid if interested</div>}
                    </div>
                  }
                  {
                    isExternal && offerBidRequest ?
                    <>
                      <div className='flex-container align-bottom'>
                        <div className={`text text-16 font-medium text-height-30 color-grey`}>Bid Requested: </div>
                        <div className={`text text-16 font-bold text-height-30 color-grey price`}>
                          {formatNumber(offerBidRequest.amount)} {getAuctionCurrency()}
                        </div>
                      </div>
                      <div className='flex-container align-bottom'>
                        <div className={`text text-12 font-bold text-height-20 color-grey`}>
                          Thank you for your bid. This bid has been flagged and will need to be approved by Admin. We will contact you shortly.
                        </div>
                      </div>
                    </>
                        : null
                  }
                </div>
              </>
            }
            {
              !isAuctionFinished && 
              <>
                <div className='flex-container align-justify bid'>
                  <Form.Item className='form-item form-item-white lg m_0 flex-child-grow'>
                    {getFieldDecorator('bid', {
                      initialValue: priceVal.current,
                      rules: [
                        { required: true, message: 'Please fill out this field' },
                      ],
                    })(
                      <NumericInput
                        onChange={(val) => {
                          priceVal.current = val
                          setAddBidDisabled(val === usersLastBid?.amount);
                        }}
                        className='fluid-x bid--input'
                        placeholder={!isExternal ? 'Input shares quantity' : ''}
                        size="lg"
                        suffix={isExternal && <div className='text text-20 font-semibold color-blue-2' >{currency}</div>}
                      />
                    )}
                    {lastBid && isExternal && <div className='text text-14 font-medium color-grey-700 form-item-hint'>Minimum next bid {formatNumber(lastBid?.amount + step)} {currency}</div>}
                    {
                      (!isExternal && usersLastBid) && <div className='text text-14 font-medium color-grey-700 form-item-hint'>
                      You have offered to buy {formatNumber(usersLastBid?.amount)} shares at {formatNumber(sharesPrice)} {currency} per
                      share for a value of {formatNumber(usersLastBid?.amount * sharesPrice)} {currency}
                      </div>
                    }
                  </Form.Item>
                  <div className='button-wrapper'>
                    <Button
                      title={!isExternal &&  usersLastBid && scope === usersLastBid.scope ? "Change Bid" : 'Add Bid'}
                      type="button"
                      size="xl"
                      theme="blue"
                      className="radius-8"
                      style={{ fontSize: 18 }}
                      onClick={placeBidOnClick}
                      disabled={addBidDisabled}
                      loading={handleLoading(loading, "AddBid")}
                    />
                  </div>
                </div>
                <div className='flex-container align-justify'>
                  <div className='flex-container align-middle'>
                    <Icon type='time' size={20} className='time-left-icon' />
                    <div className='text text-16 font-medium color-grey'>
                      Time left:
                      {offerLeftTime?.days ? ` ${offerLeftTime?.days}d ` : null}
                      {offerLeftTime?.hours ? ` ${offerLeftTime?.hours}h ` : null}
                      {offerLeftTime?.minutes ? ` ${offerLeftTime?.minutes}m ` : ` 1m `}
                      | {offerLeftTime?.fullDate}
                    </div>
                  </div>
                </div>
              </>
            }
        </>
        }
      </div>
    : null
}

const AuctionActionsComponent = Form.create()(AuctionActions);

export default AuctionActionsComponent;
