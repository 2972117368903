import React, { Component } from 'react';
import Highcharts from "highcharts";
import PieChart from "highcharts-react-official";

class InvestmentMixChart extends Component{
  render() {
    const { pieChartOptions, pieChartInfo } = this.props;

    return (
      <div className="row">
        <div className="column small-12 medium-4">
          <div className="chart_wrapper pie_height">
            <PieChart highcharts={Highcharts} options={pieChartOptions}/>
          </div>
        </div>

        <div className="column small-12 medium-8">
          <div>
            {
              pieChartInfo.map((element, index) => (
                <div className="flex-container align-justify project_data_row dark" key={index}>
                  <div className="text font-semibold color-grey project_data_col">
                    { element.name }
                  </div>
                  <div
                    className="text text-20 font-heavy color-grey project_data_col"
                    style={{color: element.color}}
                  >
                    {element.amount}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

export default InvestmentMixChart;
