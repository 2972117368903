import moment from "moment";

// exceli arjeqic 10~20% tarber
export const IRRCalc = CArray => {
  let min = 0.0;
  let max = 1.0;
  let c = 0;
  let guest;
  let NPV = 0;

  do {
    guest = (min + max) / 2;
    NPV = 0;
    for (let j = 0; j < CArray.length; j++) {
      NPV += CArray[j] / Math.pow((1+guest),j);
    }
    if (NPV > 0) {
      min = guest;
      c++;
    }
    else {
      max = guest;
      c++;
    }
    if(c>=15){ return guest*100; }
  } while(Math.abs(NPV) > 0.000001);

  return guest*100;
};

export const XIRR = (values, dates, guess) => {
  // Credits: algorithm inspired by Apache OpenOffice

  // Calculates the resulting amount
  var irrResult = function(values, dates, rate) {
    var r = rate + 1;
    var result = values[0];
    for (var i = 1; i < values.length; i++) {
      result += values[i] / Math.pow(r, moment(dates[i]).diff(moment(dates[0]), 'days') / 365);
    }
    return result;
  }

  // Calculates the first derivation
  var irrResultDeriv = function(values, dates, rate) {
    var r = rate + 1;
    var result = 0;
    for (var i = 1; i < values.length; i++) {
      var frac = moment(dates[i]).diff(moment(dates[0]), 'days') / 365;
      result -= frac * values[i] / Math.pow(r, frac + 1);
    }
    return result;
  }

  // Check that values contains at least one positive value and one negative value
  var positive = false;
  var negative = false;
  for (var i = 0; i < values.length; i++) {
    if (values[i] > 0) positive = true;
    if (values[i] < 0) negative = true;
  }

  // Return error if values does not contain at least one positive value and one negative value
  if (!positive || !negative) return 0;

  // Initialize guess and resultRate
  var guessValid = (typeof guess === 'undefined') ? 0.1 : guess;
  var resultRate = guessValid;

  // Set maximum epsilon for end of iteration
  var epsMax = 1e-10;

  // Set maximum number of iterations
  var iterMax = 50;

  // Implement Newton's method
  var newRate, epsRate, resultValue;
  var iteration = 0;
  var contLoop = true;
  do {
    resultValue = irrResult(values, dates, resultRate);
    newRate = resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
    epsRate = Math.abs(newRate - resultRate);
    resultRate = newRate;
    contLoop = (epsRate > epsMax) && (Math.abs(resultValue) > epsMax);
  } while(contLoop && (++iteration < iterMax));

  if(contLoop) return 0;

  // Return internal rate of return
  return parseFloat(resultRate);
}