import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, message, Empty } from 'antd';

import history from "../../history";
import {
  addDataRoomItem,
  downloadDataRoomItem,
  getDataRoomItems,
  getSharedDataRoomItems,
} from "../../redux/dataRoom/thunks/dataRoomThunks";
import { getBusinesses } from "../../redux/business/thunks/businessThunk";
import { removeDataRoomItems } from "../../redux/dataRoom/actions/dataRoomActions";
import { getDataRoomIconFromFileExtension } from "../../utils/getDataRoomIconFromFileExtension";
import { getDataRoomItemsByType } from "../../utils/getDataRoomItemsByType";
import { handleErrorMessage } from "../../utils/handleErrorMessage";
import { handleLoading } from "../../utils/handleLoading";

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import FileCard from "../../components/FileCard/FileCard";
import './style.scss';

class DataRoomShared extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupFolderVisible: false,
      popupFileVisible: false,
    }
  }

  toggleFolderModal = (showStatus) => {
    this.setState({
      popupFolderVisible: showStatus,
    }, () => {
      if (!showStatus) {
        this.props.form.resetFields();
      } else {
        window.scroll(0 ,0);
      }
    });
  };

  toggleFileModal = (showStatus) => {
    this.setState({
      popupFileVisible: showStatus,
    }, () => {
      if (showStatus) {
        window.scroll(0 ,0);
      }
    });
  };

  addNewFolder = () => {
    const { addDataRoomItem } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        addDataRoomItem(0, values.folderName, "directory", 0, 0, false);
      }
    });
  };

  handleFileDownload = (fileItem) => {
    const { downloadDataRoomItem } = this.props;
    const {
      token,
      year,
      month,
      day,
      id,
      name,
    } = fileItem;

    downloadDataRoomItem(token, year, month, day, id, name);
  };

  loadMoreOnClick = () => {
    const { getDataRoomItems } = this.props;
    getDataRoomItems();
  };

  loadMoreBusinessOnClick = () => {
    const { getSharedDataRoomItems, match } = this.props;
    const { userID } = match.params;
    getSharedDataRoomItems(userID);
  };

  isLoadMoreBusinessDisabled = () => {
    const { sharedItemsCount, sharedItemsCurrentPage } = this.props;
    const roundedCount = Math.ceil(sharedItemsCount / 10) * 10;
    const checkingNumber = sharedItemsCurrentPage * 10;

    if (roundedCount >= checkingNumber) { // for avoiding of calling endless getBusinesses() thunk
      return false;
    }

    return true;
  };

  isLoadMoreButtonDisabled = () => {
    const { currentPage, dataRoomItemsCount } = this.props;
    const roundedCount = Math.ceil(dataRoomItemsCount / 10) * 10;
    const checkingNumber = currentPage * 10;

    return roundedCount < checkingNumber;
  };

  isActionsDisabled = (creatorID) => {
    const { role, userID } = this.props;

    if (role !== "ADMIN") {
      if (parseInt(creatorID) !== parseInt(userID)) {
        return true;
      }
    }

    return false;
  };

  getBusinessFolders = () => {
    const { virtualDataRoomItems } = this.props;
    const businessesList = [];

    // eslint-disable-next-line array-callback-return
    virtualDataRoomItems && virtualDataRoomItems.map(element => {
      businessesList.push({
        id: element.id,
        parent: 0,
        creator: element.creator,
        business: element.id,
        type: "DIRECTORY",
        source: element.source,
        private: 0,
        name: element.name,
        created: element.created,
        updated: null
      });
    });

    return businessesList;
  };

  handleFolderOnClick = (folderItem) => {
    const path = folderItem.business ? `/data-room/0/${folderItem.name}/true/${folderItem.id}` : `/data-room/${folderItem.id}/${folderItem.name}`;
    const oldRoutes = JSON.parse(localStorage.getItem('routes'));
    oldRoutes.push({ path: path, breadcrumbName: folderItem.name });
    localStorage.setItem('routes', JSON.stringify(oldRoutes));
    history.push(path);
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      loading,
      errorMessage,
      form,
      dataRoomItems,
    } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;

    if (loadingType === "AddDataRoomItem" || errorMessage.type === "AddDataRoomItem") {
      if (!loadingState && (prevProps.loading.type === "AddDataRoomItem" && prevProps.loading.state)) {
        this.setState({
          popupFolderVisible: false,
          popupFileVisible: false
        }, () => {
          form.resetFields();
        })
      }
    }

    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "GetDataRoomItems")
      || handleErrorMessage(errorMessage, prevProps.errorMessage, "AddDataRoomItem")
      || handleErrorMessage(errorMessage, prevProps.errorMessage, "RemoveDataRoomItem")
      || handleErrorMessage(errorMessage, prevProps.errorMessage, "RenameDataRoomItem")) {
      message.error(errorMessage.message);
      if (errorMessage.message === "ACCESS FILES") {
        history.push("/");
      }
    }

    if ((dataRoomItems && prevProps.dataRoomItems) && (dataRoomItems.length > prevProps.dataRoomItems.length)) { //handling if new file was added
      this.setState({ popupFileVisible: false });
    }
  }

  componentWillUnmount() {
    this.props.removeDataRoomItems();
  }

  componentDidMount() {
    const { getSharedDataRoomItems, match } = this.props;
    const { userID } = match.params;
    const routes = [{ path: '/data-room', breadcrumbName: 'Data Room' }];

    localStorage.setItem('routes', JSON.stringify(routes));
    getSharedDataRoomItems(userID);
  }

  render() {
    const { loading, sharedItems } = this.props;
    const sharedControlledItems = sharedItems ? sharedItems : [];
    const files = getDataRoomItemsByType(sharedControlledItems, "FILE");
    const businessFolders = getDataRoomItemsByType(sharedControlledItems, "DIRECTORY");

    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                <h1>Shared Items</h1>
              </div>
            </div>
          </div>
        </div>

        {
          businessFolders.length
            ? (
              <div className="data_room_list">
                <div className="row">
                  <div className="column small-12">
                    <div className="custom-headline text text-22 font-bold color-grey mb_20">
                      <h2>Business Folders</h2>
                    </div>
                  </div>

                  {businessFolders.map((element, index) => (
                    <div key={index} className="column small-12 medium-4 large-3">
                      <FileCard
                        id={element.id}
                        isBusiness
                        type='folder'
                        folderID={0}
                        title={element.name}
                        img={<Icon type="folder-file" size={27} />}
                        actionsDisabled
                        isPrivate={element.private}
                        onClick={() => this.handleFolderOnClick(element)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : null
        }

        {
          files.length
            ? (
              <div className="data_room_list">
                <div className="row">
                  <div className="column small-12">
                    <div className="custom-headline text text-22 font-bold color-grey mb_20">
                      <h2>My Files</h2>
                    </div>
                  </div>

                  {files.map((element, index) => {
                    const fileIcon = getDataRoomIconFromFileExtension(element.name);
                    return (
                      <div key={index} className="column small-12 medium-4 large-3">
                        <FileCard
                          id={element.id}
                          type='file'
                          isPrivate={element.private}
                          onClick={() => this.handleFileDownload(element)}
                          folderID={0}
                          title={element.name}
                          img={<img alt="" src={require(`../../assets/img/${fileIcon}`)} />}
                          actionsDisabled
                          fileSrc={{
                            token: element.token,
                            year: element.year,
                            month: element.month,
                            day: element.day,
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : null
        }

        { businessFolders.length === 0 && files.length === 0 ? <Empty /> : null }

        {
          businessFolders.length !== 0 || files.length !== 0
            ? (
              <div className="columns small-12 text-center mt_20">
                <Button
                  title="Load more items"
                  size="xl"
                  theme="lightblue"
                  mode="bordered"
                  className="radius-8 mb_15"
                  type="button"
                  onClick={this.loadMoreBusinessOnClick}
                  loading={handleLoading(loading, "GetSharedDataRoomItems")}
                  disabled={this.isLoadMoreBusinessDisabled()}
                />
              </div>
            ) : null
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.dataRoom.loading,
  errorMessage: state.dataRoom.errorMessage,
  dataRoomItems: state.dataRoom.dataRoomItems,
  currentPage: state.dataRoom.currentPage,
  dataRoomItemsCount: state.dataRoom.dataRoomItemsCount,
  role: state.user.role,
  userID: state.user.xID,
  businesses: state.business.businesses,
  dataRoomVirtualItems: state.business.dataRoomVirtualItems,
  virtualDataRoomItems: state.dataRoom.virtualDataRoomItems,
  virtualDataRoomItemsCount: state.dataRoom.virtualDataRoomItemsCount,
  virtualDataRoomItemsCurrentPage: state.dataRoom.virtualDataRoomItemsCurrentPage,
  currentDataRoomVirtualItemsPage: state.business.currentDataRoomVirtualItemsPage,
  dataRoomVirtualItemsCount: state.business.dataRoomVirtualItemsCount,
  rootFilter: state.dataRoom.rootFilter,
  sharedItems: state.dataRoom.sharedItems,
  sharedItemsCount: state.dataRoom.sharedItemsCount,
  sharedItemsCurrentPage: state.dataRoom.sharedItemsCurrentPage,
});

const mapDispatchToProps = dispatch => ({
  getBusinesses: () => dispatch(getBusinesses()),
  getDataRoomItems: (currentPage) => dispatch(getDataRoomItems(currentPage)),
  removeDataRoomItems: () => dispatch(removeDataRoomItems()),
  addDataRoomItem: (parent, name, type, file, business, isPrivate) => dispatch(addDataRoomItem(parent, name, type, file, business, isPrivate)),
  downloadDataRoomItem: (token, year, month, day, id, fileName) => dispatch(downloadDataRoomItem(token, year, month, day, id, fileName)),
  getSharedDataRoomItems: (userID, hardCodePage) => dispatch(getSharedDataRoomItems(userID, hardCodePage)),
});

const DataRoomSharedScreen = Form.create()(DataRoomShared);

export default connect(mapStateToProps, mapDispatchToProps)(DataRoomSharedScreen);
