import {
  GET_PROFILE_START, GET_PROFILE_SUCCESS, GET_PROFILE_FAIL,
  INIT_LOAD_START, INIT_LOAD_SUCCESS, INIT_LOAD_FAIL
} from "../../constants";

export const getProfileStart = () => ({
  type: GET_PROFILE_START
});

export const getProfileSuccess = payload => ({
  type: GET_PROFILE_SUCCESS,
  payload
});

export const getProfileFail = payload => ({
  type: GET_PROFILE_FAIL,
  payload
});

export const initLoadStart = () => ({
  type: INIT_LOAD_START
});

export const initLoadSuccess = payload => ({
  type: INIT_LOAD_SUCCESS,
  payload
});

export const initLoadFail = payload => ({
  type: INIT_LOAD_FAIL,
  payload
});