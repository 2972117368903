import {
  activateUserRegistrationStart, activateUserRegistrationSuccess, activateUserRegistrationFail
} from "../actions/registrationActivationActions";
import CommonUtils from "../../../utils/CommonUtils";

export const activateUserRegistration = (activationCode) => ((dispatch, getState) => {
  dispatch(activateUserRegistrationStart());
  const param = {
    code: activationCode
  };

  CommonUtils.apiCall("post", "public", "shared/activate", param, null)
    .then((response) => {
      if (response.data.success) {
        dispatch(activateUserRegistrationSuccess());
      }
      else {
        dispatch(activateUserRegistrationFail());
      }
    })
    .catch((error) => {
      dispatch(activateUserRegistrationFail());
    });
});