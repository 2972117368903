import {
  FILL_USER_REGISTRATION_INFO, RESET_USER_REGISTRATION_INFO,
  REGISTER_USER_START, REGISTER_USER_SUCCESS, REGISTER_USER_FAIL,
  SET_ONBOARDING_DATA_START, SET_ONBOARDING_DATA_SUCCESS, SET_ONBOARDING_DATA_FAIL,
  GET_ONBOARDING_START, GET_ONBOARDING_SUCCESS, GET_ONBOARDING_FAIL
} from "../../constants";

export const fillUserRegistrationInfo = (payload) => ({
  type: FILL_USER_REGISTRATION_INFO,
  payload
});

export const resetUserRegistrationInfo = () => ({
  type: RESET_USER_REGISTRATION_INFO
});

export const registerUserStart = () => ({
  type: REGISTER_USER_START
});

export const registerUserSuccess = (payload) => ({
  type: REGISTER_USER_SUCCESS,
  payload
});

export const registerUserFail = (payload) => ({
  type: REGISTER_USER_FAIL,
  payload
});

export const setOnboardingDataStart = () => ({
  type: SET_ONBOARDING_DATA_START
});

export const setOnboardingDataSuccess = payload => ({
  type: SET_ONBOARDING_DATA_SUCCESS,
  payload
});

export const setOnboardingDataFail = payload => ({
  type: SET_ONBOARDING_DATA_FAIL,
  payload
});

export const getOnboardingStart = () => ({
  type: GET_ONBOARDING_START
});

export const getOnboardingSuccess = payload => ({
  type: GET_ONBOARDING_SUCCESS,
  payload
});

export const getOnboardingFail = payload => ({
  type: GET_ONBOARDING_FAIL,
  payload
});