export const bidsHistoryColumns = [
  {
    title: "Bid",
    dataIndex: "bid",
    key: "bid",
  },
  {
    title: "Scope",
    dataIndex: "scope",
    key: "scope",
  },
  {
    title: "created",
    dataIndex: "created",
    key: "created",
  },
];
