import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../history";

import { setBusinessFilter } from "../../redux/business/actions/businessActions";
import { setMessagesFilter } from "../../redux/messages/actions/messagesActions";
import { setDataRoomFilter } from "../../redux/dataRoom/actions/dataRoomActions";
import { getBusinesses } from "../../redux/business/thunks/businessThunk";
import { getMessages } from "../../redux/messages/thunks/messagesThunks";
import { searchUsers } from "../../redux/users/thunks/searchUsersThunk";
import { logout } from "../../redux/user/thunks/logoutThunk";
import { getMediaFileFromServer } from "../../utils/getMediaFileFromServer";

import { Form, Input, Dropdown, Select } from 'antd';
import Icon from "../../components/Icon/Icon";
import Button from "../../components/Button/Button";

import { detectUserAccess } from "../../utils/detectUserAccess";
import {
  getDataRoomItems,
  getVirtualDataRoomItems
} from "../../redux/dataRoom/thunks/dataRoomThunks";
import { setUsersFilter } from "../../redux/users/actions/searchUsersActions";
import './style.scss';
import { clearOfferFilters, clearOffers, setOfferFilters } from "../../redux/business/actions/offersAction";
import { searchOffers } from "../../redux/business/thunks/offersThunk";

const { Option } = Select;

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      regEntity: false,
      activeClass: '',
      showSearchBarSm: '',
      notificationsOpenedCount: 0,
    }
  }

  isOnboarded = () => {
    const { userInfo } = this.props;
    return userInfo && parseInt(userInfo.onboarded) === 1;
  };

  isAdmin = () => {
    const { role } = this.props;
    return role === "ADMIN";
  };

  isNotificationEmpty = () => {
    if (this.getUnreadMessagesList().length === 0) {
      return true;
    }
    return !((!this.isAdmin() && !this.isOnboarded()) || (!this.isAdmin() && this.isOnboarded()));
  };

  handleNotificationsCountOnClick = () => {
    this.setState(prevState => ({
      notificationsOpenedCount: prevState.notificationsOpenedCount + 1
    }), () => {
      if (this.state.notificationsOpenedCount === 1) {
        localStorage.setItem("isNotificationOpened", "1");
      }
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const {
      form,
      getBusinesses,
      getMessages,
      messagesFilter,
      getDataRoomItems,
      getVirtualDataRoomItems,
      usersFilter,
      searchOffers,
      searchUsers,
      clearOffers,
      offerFilters,
    } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        switch (values.search_where) {
          case 'opportunities':
            if (window.location.pathname === '/opportunities') {
              getBusinesses(0);
            } else {
              history.push('/opportunities');
            }
            break;
          case 'offers':
            console.log(values);
            clearOffers();
            // setOfferFilters({...offerFilters, name: values.search_input});
            if (window.location.pathname === '/offers') {
              searchOffers({ offerFilters: { ...offerFilters, name: values.search_input } }, 0);
            } else {
              // setOfferFilters({ ...offerFilters, name: values.search_input });
              history.push('/offers');
            }
            break;
          case 'data_room':
            if (window.location.pathname === '/data-room') {
              getDataRoomItems(1);
              getVirtualDataRoomItems(1);
            } else {
              history.push('/data-room');
            }
            break;
          case 'contact_center':
            if (window.location.pathname === '/contact-center') {
              getMessages("inbox", 1, messagesFilter);
              getMessages("sent", 1, messagesFilter);
            } else {
              history.push('/contact-center');
            }
            break;
          case 'users':
            if (window.location.pathname === '/admin-users') {
              searchUsers(1, usersFilter);
            } else {
              history.push('/admin-users');
            }
            break;
          default:
        }
      }
    });
  };

  searchInputOnChange = (value) => {
    const {
      form,
      setBusinessFilter,
      setMessagesFilter,
      getBusinesses,
      getMessages,
      messagesFilter,
      setDataRoomFilter,
      getDataRoomItems,
      getVirtualDataRoomItems,
      setUsersFilter,
      searchUsers,
      offerFilters,
      setOfferFilters,
    } = this.props;

    switch (form.getFieldValue('search_where')) {
      case 'opportunities':
        setBusinessFilter({
          filterType: 'name',
          filterValue: value,
        });
        if (!value) {
          getBusinesses(0);
        }
        break;
      case 'offers':
        console.log(offerFilters, value);
        setOfferFilters({ ...offerFilters, name: value });
        // if (!value) {
        //   console.log('ppppppp');
        //   // clearOffers();
        //   // setOfferFilters({...offerFilters, name: ''});
        //   searchOffers({ offerFilters: {...offerFilters, name: ' '}, query: "cnt" });        
        // }
        break;
      case 'data_room':
        setDataRoomFilter({
          filterName: 'rootFilter',
          filterType: 'name',
          filterValue: value,
        });
        if (!value) {
          getDataRoomItems(1);
          getVirtualDataRoomItems(1);
        }
        break;
      case 'contact_center':
        setMessagesFilter({
          filterType: 'name',
          filterValue: value,
        });
        if (!value) {
          getMessages("inbox", 1, { ...messagesFilter, name: '' });
          getMessages("sent", 1, { ...messagesFilter, name: '' });
        }
        break;
      case 'users':
        let [first_name, last_name] = value.split(' ');
        first_name = first_name ? first_name : '';
        last_name = last_name ? last_name : '';
        setUsersFilter({ first_name, last_name });
        if (!value) {
          searchUsers(1);
        }
        break;
      default:
    }
  };

  handleSearchSectionOptionOnChange = (value) => {
    const {
      form,
      setBusinessFilter,
      setMessagesFilter,
      setDataRoomFilter,
      setUsersFilter,
    } = this.props;
    const { getFieldValue } = form;
    const filterType = 'name';
    const filterValue = getFieldValue('search_input') ? getFieldValue('search_input') : '';

    switch (value) {
      case 'opportunities':
        setBusinessFilter({ filterType, filterValue });
        setMessagesFilter({ filterType: '', filterValue: '' });
        setDataRoomFilter({ filterName: 'rootFilter', filterType: '', filterValue: '' });
        setUsersFilter({ first_name: '', last_name: '' });
        break;
      case 'offers':
        setBusinessFilter({ filterType: '', filterValue: '' });
        setMessagesFilter({ filterType: '', filterValue: '' });
        setDataRoomFilter({ filterName: 'rootFilter', filterType: '', filterValue: '' });
        setUsersFilter({ first_name: '', last_name: '' });
        break;
      case 'data_room':
        setDataRoomFilter({ filterName: 'rootFilter', filterType, filterValue });
        setBusinessFilter({ filterType: '', filterValue: '' });
        setMessagesFilter({ filterType: '', filterValue: '' });
        setUsersFilter({ first_name: '', last_name: '' });
        break;
      case 'contact_center':
        setMessagesFilter({ filterType, filterValue });
        setBusinessFilter({ filterType: '', filterValue: '' });
        setDataRoomFilter({ filterName: 'rootFilter', filterType: '', filterValue: '' });
        setUsersFilter({ first_name: '', last_name: '' });
        break;
      case 'users':
        let [first_name, last_name] = filterValue.split(' ');
        first_name = first_name ? first_name : '';
        last_name = last_name ? last_name : '';
        setUsersFilter({ first_name, last_name });
        setBusinessFilter({ filterType: '', filterValue: '' });
        setDataRoomFilter({ filterName: 'rootFilter', filterType: '', filterValue: '' });
        setMessagesFilter({ filterType: '', filterValue: '' });
        break;
      default:
    }
  };

  getUnreadMessagesList = () => {
    const { inboxMessages } = this.props;
    let unreadMessagesList = [];

    if (inboxMessages) {
      inboxMessages.map(element => {
        if (!element.seen) {
          unreadMessagesList.push(element);
        }

        return "";
      });

      return unreadMessagesList;
    }

    return unreadMessagesList;
  };

  showSearchBar = () => {
    this.setState({
      showSearchBarSm: "search-active"
    })
  };

  getUserAvatar = () => {
    const { userInfo } = this.props;

    if (userInfo && userInfo.avatar) {
      return getMediaFileFromServer(userInfo.avatar);
    }

    return require("../../assets/img/no_profile.png");
  };

  hideSearchBar = () => this.setState({ showSearchBarSm: '' });

  handleOnScroll = () => {
    let activeClass = 'fixed';

    if (window.scrollY === 0) {
      activeClass = '';
    }
    this.setState({ activeClass });
  };

  handleLogout = () => {
    const { logout, xID } = this.props;

    logout(xID);
    // localStorage.clear();
    // window.location.replace("/"); 
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleOnScroll);
  }

  render() {
    const { form, settingsPopupShow, userInfo } = this.props;
    const { activeClass, showSearchBarSm, email } = this.state;
    const { getFieldDecorator, getFieldValue } = form;

    return (
      <>
        <div className={`header flex-container align-middle large-align-top align-right fluid-x ${activeClass} ${showSearchBarSm}`}>
          <div className="small-12 large-5 header_search">
            <Form onSubmit={this.onSubmit} >
              <Icon type="search" className="color-grey-800 hide-md hide-lg show-sm" style={{ fontSize: 17 }} onClick={this.showSearchBar} />
              <div className={`form-item form-item-white mb_0 header_search_wrapper ${showSearchBarSm}`}>
                <div className="flex-container">
                  <Form.Item className="mb_0 small-4 large-5 header_search_field">
                    {getFieldDecorator("search_where", {
                      rules: [{ required: true, message: "Please fill out this field" }],
                    })(
                      <Select
                        className="shadow-layout unborder"
                        placeholder="Search in section"
                        onChange={this.handleSearchSectionOptionOnChange}
                      >
                        {
                          detectUserAccess("access_investments", userInfo) ?
                            <Option value="opportunities"> Primary deals </Option>
                            : null
                        }
                        {
                          detectUserAccess("access_investments", userInfo) ?
                            <Option value="offers"> Secondary deals </Option>
                            : null
                        }
                        {
                          detectUserAccess('access_files', userInfo) ?
                            <Option value="data_room"> Data room </Option>
                            : null
                        }
                        {
                          detectUserAccess("access_messages", userInfo) ?
                            <Option value="contact_center"> Contact center </Option>
                            : null
                        }
                        {
                          detectUserAccess("access_accounts", userInfo) ?
                            <Option value="users"> Users </Option>
                            : null
                        }
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item className="mb_0 small-8 large-7 search_input_field">
                    {getFieldDecorator('search_input', {
                      initialValue: email,
                      rules: [{ required: false, message: 'Please fill out this field' }],
                    })(
                      <Input
                        onChange={(event) => this.searchInputOnChange(event.target.value)}
                        autoComplete='off'
                        className="shadow-layout unborder"
                        style={{ border: 0 }}
                        suffix={
                          <Button
                            type="submit"
                            theme={getFieldValue('search_input') ? `blue` : ""}
                            className={getFieldValue('search_input') ? `btn-md radius-8` : `btn-initial`}
                            title={
                              <Icon
                                type="search"
                                className={getFieldValue('search_input') ? `color-white` : `color-grey-800`}
                                style={{ fontSize: 17 }}
                              />
                            }
                          />
                        }
                      />
                    )}
                  </Form.Item>
                </div>
                <Icon
                  type="close"
                  className="color-grey-800 hide-md hide-lg show-sm close_search_bar"
                  style={{ fontSize: 17 }}
                  onClick={this.hideSearchBar}
                />
              </div>
            </Form>
          </div>

          <div className="hide-lg show-md header_menu">
            <Dropdown
              placement="bottomRight"
              trigger={['click']}
              overlay={
                <div className="bg-white radius-8 shadow-layout dropdown_menu dropdown_menu-dark">
                  <div
                    className="flex-container align-middle color-white header_menu_item"
                    onClick={settingsPopupShow}
                  >
                    <Icon type="pen" />
                    <div className="text text-14 font-semibold">
                      Edit profile
                    </div>
                  </div>
                  <div className="flex-container align-middle color-white header_menu_item">
                    <a href="https://www.pe-gate.com/privacy-policy" target="_blank" className="text text-11 text-height-17 color-white-08" rel="noopener noreferrer">
                      Our Privacy Policy
                    </a>
                  </div>
                  <div className="flex-container align-middle color-white header_menu_item">
                    <a href="https://www.pe-gate.com/terms-of-use" target="_blank" className="text text-11 text-height-17 color-white-08" rel="noopener noreferrer">
                      Our Terms of Use
                    </a>
                  </div>
                  <div className="flex-container align-middle color-white header_menu_item">
                    <a href="https://static1.squarespace.com/static/5a4aeb9af43b5576b2948693/t/60da2a9afba11d2707f2b2e1/1624910490599/CLIENT+RELATIONSHIP++DISCLOSURE+INFORMATION+-+June+2021.pdf" target="_blank" className="text text-11 text-height-17 color-white-08" rel="noopener noreferrer">
                      Client Relationship Disclosure Information
                    </a>
                  </div>
                  <div
                    className="flex-container align-middle color-white header_menu_item"
                    onClick={this.handleLogout}
                  >
                    <Icon type="logout" />
                    <div className="text text-14 font-semibold">
                      Log out
                    </div>
                  </div>
                </div>
              }
            >
              <div className="radius-circle clip header_user">
                <img src={this.getUserAvatar()} alt="" />
              </div>
            </Dropdown>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  role: state.user.role,
  offerFilters: state.offers.offerFilters,
  xID: state.user.xID,
  userInfo: state.user.userInfo,
  inboxMessages: state.messages.inboxMessages,
  messagesFilter: state.messages.filter,
  usersFilter: state.users.filter,
});

const mapDispatchToProps = dispatch => ({
  logout: (id) => dispatch(logout(id)),
  setBusinessFilter: (payload) => dispatch(setBusinessFilter(payload)),
  setMessagesFilter: (payload) => dispatch(setMessagesFilter(payload)),
  setDataRoomFilter: (payload) => dispatch(setDataRoomFilter(payload)),
  setUsersFilter: (payload) => dispatch(setUsersFilter(payload)),
  getBusinesses: (hardCodeOffset) => dispatch(getBusinesses(hardCodeOffset)),
  getMessages: (type, currentPage, params) => dispatch(getMessages(type, currentPage, params)),
  getDataRoomItems: (currentPage) => dispatch(getDataRoomItems(currentPage)),
  getVirtualDataRoomItems: (hardCodePage) => dispatch(getVirtualDataRoomItems(hardCodePage)),
  searchUsers: (currentPage, filter) => dispatch(searchUsers(currentPage, filter)),
  searchOffers: (currentPage, filter) => dispatch(searchOffers(currentPage, filter)),
  clearOfferFilters: () => dispatch(clearOfferFilters()),
  clearOffers: () => dispatch(clearOffers()),
  setOfferFilters: (value) => dispatch(setOfferFilters(value)),
});

const HeaderAndSearch = Form.create({ name: 'search' })(Header);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAndSearch);
