import {
  getDictionariesStart, getDictionariesSuccess, getDictionariesFail,
  getOpportunityGroupsWithOptionsStart, getOpportunityGroupsWithOptionsSuccess, getOpportunityGroupsWithOptionsFail,
  getCountriesStart, getCountriesSuccess, getCountriesFail,
  getStatesStart, getStatesSuccess, getStatesFail,
  getCitiesStart, getCitiesSuccess, getCitiesFail
} from "../actions/dictionariesActions";
import CommonUtils from "../../../utils/CommonUtils";
import {reorderCountriesDictionary} from "../../../utils/reorderCountriesDictionary";
import {detectNotEnabledOptions} from "../../../utils/detectNotEnabledOptions";

export const getDictionaries = () => ((dispatch, getState) => {
  dispatch(getDictionariesStart());

  let apiCalls = [];
  const {opportunityGroups, countries} = getState().dictionaries;

  if (!opportunityGroups) {
    apiCalls.push(CommonUtils.apiCall("get", "public", "shared/getOptgroupsWithOptions", {}, null));
  }
  if (!countries) {
    apiCalls.push(CommonUtils.apiCall("get", "public", "shared/getCountries", {}, null));
  }

  if (apiCalls) {
    Promise.all(apiCalls)
      .then((responses) => {
        let loadedData = {};
        for (let i = 0; i < responses.length; i++) {
          if (responses[i].data) {
            const apiConfigUrlArr = responses[i].config.url.split("/");
            const keyWord = apiConfigUrlArr.find(element => element === "public" || element === "private");
            const indexOfKeyWord = apiConfigUrlArr.indexOf(keyWord);
            const callName = apiConfigUrlArr[indexOfKeyWord + 2]; // if we find public or private keywords, after that element callName is far with 2 indexes

            switch (callName) {
              case "getOptgroupsWithOptions":
                loadedData.opportunities = detectNotEnabledOptions(responses[i].data);
                break;
              case "getCountries":
                loadedData.countries = reorderCountriesDictionary(responses[i].data);
                break;
              default:
            }
          }
          else {
            loadedData = {}; // maybe one of the calls will store in loadedData, after that it would not have access in statement if (loadedData)
            dispatch(getDictionariesFail("Something went wrong, please try again"));
          }
        }

        dispatch(getDictionariesSuccess(loadedData));
      })
      .catch((error) => {
        dispatch(getDictionariesFail("Something went wrong, please try again"));
      })
  }
  else {
    dispatch(getDictionariesSuccess({}));
  }
});

export const getOpportunityGroupsWithOptions = () => ((dispatch, getState) => {
  const {opportunityGroups} = getState().dictionaries;

  if (!opportunityGroups) { //check if dictionary does not exist -> call the dictionary
    dispatch(getOpportunityGroupsWithOptionsStart());

    CommonUtils.apiCall("get", "public", "shared/getOptgroupsWithOptions", {}, null)
      .then((response) => {
        if (response.data) {
          dispatch(getOpportunityGroupsWithOptionsSuccess({opportunities: response.data}));
        }
        else {
          dispatch(getOpportunityGroupsWithOptionsFail("Nothing was not found"));
        }
      })
      .catch((error) => {
        dispatch(getOpportunityGroupsWithOptionsFail("Some error occurred"));
      });
  }
});

export const getCountries = () => ((dispatch, getState) => {
  const {countries} = getState().dictionaries;

  if (!countries) {
    dispatch(getCountriesStart());

    CommonUtils.apiCall("get", "public", "shared/getCountries", {}, null)
      .then((response) => {
        if (response.data) {
          dispatch(getCountriesSuccess({
            countries: reorderCountriesDictionary(response.data),
          }));
        }
        else {
          dispatch(getCountriesFail("Nothing was not found"));
        }
      })
      .catch((error) => {
        dispatch(getCountriesFail("Some error occurred"));
      })
  }
});

export const getStates = (countryID) => ((dispatch, getState) => {
  dispatch(getStatesStart());

  CommonUtils.apiCall("get", "public", `shared/getStates/${countryID}`, {}, null)
    .then((response) => {
      if (response.data) {
        dispatch(getStatesSuccess({states: response.data}));
      }
      else {
        dispatch(getStatesFail("Nothing was not found"));
      }
    })
    .catch((error) => {
      dispatch(getStatesFail("Some error occurred"));
    })
});

export const getCities = (stateID) => ((dispatch, getState) => {
  dispatch(getCitiesStart());

  CommonUtils.apiCall("get", "public", `shared/getCities/${stateID}`, {}, null)
    .then((response) => {
      if (response.data) {
        dispatch(getCitiesSuccess({cities: response.data}));
      }
      else {
        dispatch(getCitiesFail("Nothing was not found"));
      }
    })
    .catch((error) => {
      dispatch(getCitiesFail("Some error occurred"));
    })
});