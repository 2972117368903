import {
  getMessagesStart, getMessagesSuccess, getMessagesFail,
  getMessageStart, getMessageSuccess, getMessageFail,
  addMessageStart, addMessageSuccess, addMessageFail
} from "../actions/messagesActions";
import CommonUtils from "../../../utils/CommonUtils";

export const getMessages = (type, currentPage, params) => ((dispatch, getState) => {
  dispatch(getMessagesStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", "shared/getMessages/0/0/inbox?cnt&new", params, config)
    .then(unreadMessagesCountResponse => {
      if (unreadMessagesCountResponse.data != null) {
        const unreadMessagesCount = unreadMessagesCountResponse.data;

        CommonUtils.apiCall("get", "private", `shared/getMessages/0/0/${type}?cnt`, params, config)
          .then(messagesCountResponse => {
            if (messagesCountResponse.data != null && parseInt(messagesCountResponse.data) === 0) {
              dispatch(getMessagesSuccess({
                [type === "inbox" ? "inboxMessages" : "sentMessages"]: [],
                [type === "inbox" ? "inboxMessagesCount" : "sentMessagesCount"]: 0,
                filter: params,
                unreadMessagesCount,
                [type === "inbox" ? "inboxCurrentPage" : "sentCurrentPage"]: 1,
              }));
            }
            else if (messagesCountResponse.data != null) {
              const perPage = 10;
              const offset = (currentPage - 1) * perPage;
              const messagesCount = Math.round(messagesCountResponse.data / perPage);

              CommonUtils.apiCall("get", "private", `shared/getMessages/${offset}/${perPage}/${type}?sort="created"&desc`, params, config)
                .then(messagesListResponse => {
                  if (Array.isArray(messagesListResponse.data)) {
                    dispatch(getMessagesSuccess({
                      [type === "inbox" ? "inboxMessages" : "sentMessages"]: messagesListResponse.data,
                      [type === "inbox" ? "inboxMessagesCount" : "sentMessagesCount"]: messagesCount,
                      filter: params,
                      unreadMessagesCount,
                      [type === "inbox" ? "inboxCurrentPage" : "sentCurrentPage"]: currentPage,
                    }));
                  }
                  else {
                    dispatch(getMessagesFail("Error"));
                  }
                })
                .catch(() => dispatch(getMessagesFail("Error")));
            }
            else {
              dispatch(getMessagesFail("Error"));
            }
          })
          .catch(() => {
            dispatch(getMessagesFail("Error"))
          });
      }
      else {
        dispatch(getMessagesFail("Error"));
      }
    })
    .catch(() => dispatch(getMessagesFail("Error")));
});

export const getMessage = id => ((dispatch, getState) => {
  dispatch(getMessageStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `shared/getMessage/${id}`, {}, config)
    .then(response => {
      if (response.data.success) {
        dispatch(getMessageSuccess({
          currentOpenedMessage: response.data.success
        }))
      }
      else {
        dispatch(getMessageFail("Error"));
      }
    })
    .catch(() => {
      dispatch(getMessageFail("Error"));
    })
});

export const addMessage = (to, business, subject, body, priority, files, type) => ((dispatch, getState) => {
  dispatch(addMessageStart());

  const {xID, xTOKEN, role} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  const params = {
    to,
    business,
    subject,
    body,
    priority,
    type, // type can be either USER or GROUP
  };

  if (files) {
    params.files = files;
  }

  if (role === "ADMIN") {
    params.visible = 1;
  }

  CommonUtils.apiCall("post", "private", "shared/addMessage", params, config)
    .then(addMessageResponse => {
      if (addMessageResponse.data.success) {
        dispatch(addMessageSuccess());
        /*CommonUtils.apiCall("get", "private", `shared/getMessages/0/0/sent?cnt`, {}, config)
          .then(messagesCountResponse => {
            if (messagesCountResponse.data != null && parseInt(messagesCountResponse.data) === 0) {
              dispatch(addMessageSuccess({
                sentMessages: []
              }));
            }
            else if(messagesCountResponse.data != null) {
              const messagesCount = messagesCountResponse.data;
              CommonUtils.apiCall("get", "private", `shared/getMessages/0/${messagesCount}/sent`, {}, config)
                .then(getMessagesResponse => {
                  if (Array.isArray(getMessagesResponse.data)) {
                    dispatch(addMessageSuccess({
                      sentMessages: getMessagesResponse.data
                    }));
                  }
                  else {
                    dispatch(addMessageFail("Error"));
                  }
                })
                .catch(() => {
                  dispatch(addMessageFail("Error"));
                });
            }
            else {
              dispatch(addMessageFail("Error"));
            }
          })
          .catch(() => {
            dispatch(addMessageFail("Error"));
          });*/
      }
      else {
        dispatch(addMessageFail(addMessageResponse.data.error.body));
      }
    })
    .catch(() => {
      dispatch(addMessageFail("Error"));
    });
});