import axios from "axios";
import {SERVER_URL} from "./constants";

class CommonUtils {
  static apiUrl = SERVER_URL;

  static apiCall(method, scope, callName, params={}, config) {
    const apiUrl = `${this.apiUrl}/${scope}/${callName}`;
    let response;
    let options = {};

    switch (method) {
      case "get":
        if (scope === "private") {
          options = {
            headers: {
              "X-Token": config.token,
              "X-ID": config.id
            },
            params
          };
        }
        else {
          options = {
            params
          }
        }
        response = axios.get(apiUrl, options);
        break;
      case "post":
        if (scope === "private") {
          options = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-Token": config.token,
              "X-ID": config.id
            }
          };
        }
        else {
          options = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          };
        }

        let data = new FormData();
        for (let key in params) {
          if (params[key] && Array.isArray(params[key])){
            for (let i = 0; i < params[key].length; i++) {
              data.append(key+'[]', (params[key][i]));
            }
          } else{
            data.append(key, params[key]);
          }
        }

        response = axios.post(apiUrl, data, options);
        break;
      default:
    }

    return response;
  }
}

export default CommonUtils;