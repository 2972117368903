import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {
  removeDataRoomItem,
  renameDataRoomItem,
  addAccessToDataRoomItem,
  setDataRoomItemAccessType,
  removeAccessToDataRoomItem,
  searchBusinessRoomUsers,
  searchRoomUsers
} from "../../redux/dataRoom/thunks/dataRoomThunks";

import {
  Checkbox,
  Dropdown,
  Form,
  Input,
  Modal,
  Spin,
} from 'antd';
import {Link} from "react-router-dom";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import PopUp from "../PopUp/PopUp";
import {getMediaFileFromServer} from "../../utils/getMediaFileFromServer";
import {searchUsers, searchUsersFilter} from "../../redux/users/thunks/searchUsersThunk";
import {SERVER_URL} from "../../utils/constants";
import "./style.scss";
import { handleLoading } from "../../utils/handleLoading";

const FileCard = (props) => {
  const {
    title,
    desc,
    className,
    style,
    img,
    id,
    type,
    onClick,
    linkTo,
    form,
    loading,
    folderID,
    showFileActions,
    usersSearchRoomList,
    usersSearchRoomPagination,
    totalUsersSearchRoom,
    tagsList,
    fileSrc,
    isPrivate,
    isBusiness,
  } = props;
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [sharedGroups, setSharedGroups] = useState([]);
  const {getFieldDecorator} = form;
  const loadingState = loading.state;
  const loadingType = loading.type;
  const { getFieldValue } = form;
  const fileExtension = type === 'file' ? title.substr(title.lastIndexOf('.')) : '';

  const handleRemoveActionOnClick = () => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        const {removeDataRoomItem} = props;
        removeDataRoomItem(id, folderID);
      }
    });
  };

  const handleShareActionsOnClick = status => {
    setShareModalVisible(status);
    setSharedGroups([]);
    if (status && isBusiness) {
      // only business file can be shared
      const { searchBusinessRoomUsers, businessID, id } = props;
      searchBusinessRoomUsers(businessID, id);
    } else if(status && !isBusiness) {
      const { searchRoomUsers, id } = props;
      searchRoomUsers(1, id);
    }
  };

  const infiniteScroll = (e) => {
    const target = e.currentTarget;
    const targetInner = target.children[0];
    const targetInnerStyle = targetInner.currentStyle || window.getComputedStyle(targetInner);
    const margins = parseInt(targetInnerStyle.marginTop) + parseInt(targetInnerStyle.marginBottom);

    if (((Math.ceil(window.innerHeight + target.scrollTop) - margins)) >= targetInner.offsetHeight) {
      const { currentPage } = usersSearchRoomPagination;
      const roundedCount = Math.ceil(totalUsersSearchRoom / 100) * 100;
      const checkingNumber = currentPage * 100;

      if (roundedCount >= checkingNumber) {
        const { searchRoomUsers } = props;
        searchRoomUsers(currentPage, id);
      }
    }
  };

  const handleEditActionOnClick = () => {
    setEditModalVisible(true);
  };

  const handleOnRename = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        const {renameDataRoomItem} = props;
        values.folderName = `${values.folderName}${fileExtension}`;
        renameDataRoomItem(id, values.folderName, folderID);
      }
    })
  };

  const toggleFolderModal = status => {
    setEditModalVisible(status);
  };

  const renderFileCard = () => (
    <>
      <div className="file_card__icon">{img}</div>
      <div className="file_card__caption">
        <div className="custom-headline text font-heavy color-grey">
          {/* <Tooltip title={title} > */}
          <h3>{title}</h3>
          {/* </Tooltip> */}
        </div>
        <div className="text text-14 font-medium color-grey-900">
          {desc}
        </div>
      </div>
    </>
  );

  const isItemVideo = () => {
    const getExtension = (filename) => {
      let parts = filename.split('.');
      return parts[parts.length - 1];
    };
    const extension = getExtension(title);

    // eslint-disable-next-line default-case
    switch (extension.toLowerCase()) {
      case 'mp4':
      case 'mov':
      case 'wmv':
      case 'flv':
      case 'avi':
      case 'avchd':
      case 'webm':
      case 'mkv':
      case 'm4v':
      case 'mpg':
        // etc
        return true;
    }
    return false;
  };

  const copyVideoLink = () => {
    const {
      token, year,
      month, day,
    } = fileSrc;
    const el = document.createElement('input');
    el.value = `download/${token}/${year}/${month}/${day}/${id}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const renderFileCardAction = () => {
    const {actionsDisabled, role, hasAccessToShare} = props;

    return (
      <div className="file_card__actions">
        {
          showFileActions !== false ?
            !actionsDisabled ?
              <Dropdown
                trigger={["click"]}
                overlay={
                  <div className="bg-white radius-4 shadow-layout drop_menu">
                    {
                      type === 'file' ?
                        <div onClick={onClick} className="drop_menu_item">
                          <i className="icon icon-download"/>
                          <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                            Download
                          </span>
                        </div>
                      : null
                    }
                    <div onClick={handleEditActionOnClick} className="drop_menu_item">
                      <i className="icon icon-pen"/>
                      <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                        Edit
                      </span>
                    </div>
                    {
                      role === "ADMIN" && hasAccessToShare ?
                        <div onClick={() => handleShareActionsOnClick(true)} className="drop_menu_item">
                          <i className="icon icon-share"/>
                          <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                            share
                          </span>
                        </div>
                      : null
                    }
                    {
                      // admin can share only item connected with opportunity
                      role === "ADMIN" && hasAccessToShare && isItemVideo() ?
                        <div onClick={copyVideoLink} className="drop_menu_item">
                          <i className="icon icon-copy"/>
                          <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                            copy video
                          </span>
                        </div>
                      : null
                    }
                    <div onClick={handleRemoveActionOnClick} className="drop_menu_item">
                      <i className="icon icon-bin"/>
                      <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                        delete
                      </span>
                    </div>
                  </div>
                }
              >
                <div className="file_card__actions_icon color-grey-700">
                  <i className="icon icon-menu"/>
                </div>
              </Dropdown>
              :
              type === 'file' ?
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <div className="bg-white radius-4 shadow-layout drop_menu">
                      {
                        type === 'file' ?
                          <div onClick={onClick} className="drop_menu_item">
                            <i className="icon icon-download"/>
                            <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                              Download
                            </span>
                          </div>
                        : null
                      }
                    </div>
                  }
                >
                  <div className="file_card__actions_icon color-grey-700">
                    <i className="icon icon-menu"/>
                  </div>
                </Dropdown>
              : null
            :
            type === 'file' ?
              <Dropdown
                trigger={["click"]}
                overlay={
                  <div className="bg-white radius-4 shadow-layout drop_menu">
                    {
                      type === 'file' ?
                        <div onClick={onClick} className="drop_menu_item">
                          <i className="icon icon-download"/>
                          <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                            Download
                          </span>
                        </div>
                      : null
                    }
                  </div>
                }
              >
                <div className="file_card__actions_icon color-grey-700">
                  <i className="icon icon-menu"/>
                </div>
              </Dropdown>
            : null
        }
      </div>
    )
  };

  const toggleLastUserOnChange = (userID, checkedState) => {
    if (checkedState) {
      const { addAccessToDataRoomItem } = props;
      addAccessToDataRoomItem({
        id,
        to: userID,
        type: "USER", // can be either USER or GROUP
        recursive: form.getFieldValue('isAccessRecursive') ? 1 : 0,
      });
    }
    else {
      const { removeAccessToDataRoomItem } = props;
      removeAccessToDataRoomItem({
        id,
        to: userID,
        recursive: form.getFieldValue('isAccessRecursive') ? 1 : 0,
      })
    }
  };

  const toggleGroupOnChange = (groupID, checkedState) => {
    if (checkedState) {
      const { addAccessToDataRoomItem } = props;
      addAccessToDataRoomItem({
        id,
        to: groupID,
        type: "GROUP", // can be either USER or GROUP
        recursive: form.getFieldValue('isAccessRecursive') ? 1 : 0,
      });
      setSharedGroups([...sharedGroups, groupID]);
    }
    else {
      const { removeAccessToDataRoomItem } = props;
      removeAccessToDataRoomItem({
        id,
        to: groupID,
        recursive: form.getFieldValue('isAccessRecursive') ? 1 : 0,
      })
      setSharedGroups([...sharedGroups.filter(id => id !== groupID)]);
    }
  };

  const toggleItemAccessType = (status) => {
    const type = status ? 'private' : 'public';
    props.setDataRoomItemAccessType({
      id,
      type,
      recursive: form.getFieldValue('isAccessRecursive') ? 1 : 0,
    }, folderID, props.businessID);
  };

  const getLinkOfPreviewFile = () => {
    if (type === 'file' && (fileExtension === '.pdf' || fileExtension === '.png' || fileExtension === '.jpg' || fileExtension === '.jpeg')) {
      return `${SERVER_URL}/public/shared/download/${fileSrc.token}/${fileSrc.year}/${fileSrc.month}/${fileSrc.day}/${id}`;
    }
    return '';
  };

  useEffect(() => {
    if (!loadingState && loadingType === "RenameDataRoomItem") {
      setEditModalVisible(false);
    }
  }, [loadingState, loadingType]);

  return (
    <>
      {
        linkTo ?
          <div className="file-card-wrapper">
            <Link
              to={linkTo}
              className={`flex-container align-middle relative bg-white radius-10 shadow-layout file_card ${className || ''}`}
              style={style}
            >
              {renderFileCard()}
            </Link>
            {renderFileCardAction()}
          </div>
        :
          getLinkOfPreviewFile() ?
            <div className="file-card-wrapper">
              <a
                href={getLinkOfPreviewFile()}
                target="_blank"
                rel="noopener noreferrer"
                className={`flex-container align-middle relative bg-white radius-10 shadow-layout file_card ${className || ''}`}
                style={style}
                onDoubleClick={getLinkOfPreviewFile() ? null : onClick}
              >
                {renderFileCard()}
              </a>
              {renderFileCardAction()}
            </div>
          :
            <div className="file-card-wrapper">
              <div
                className={`flex-container align-middle relative bg-white radius-10 shadow-layout file_card ${className || ''}`}
                style={style}
                // onDoubleClick={!isBusiness ? onClick : null}
                onClick={onClick}
              >
                {renderFileCard()}
              </div>
              {renderFileCardAction()}
            </div>
      }

      {
        editModalVisible &&
          <PopUp
            visible
            className="relative_parent"
            innerClassNames="shadow-layout bg-white radius-10 relative md base tall"
          >
            <Spin spinning={loadingState && loadingType === "RenameDataRoomItem"}>
              <div className="flex-container align-middle align-justify mb_30">
                <div className="text text-20 font-bold color-grey">
                  Edit {type === 'folder' ? "Folder" : `File (${fileExtension})`}
                </div>
                <div
                  className="popup_close static color-grey-700"
                  onClick={() => toggleFolderModal(false)}
                >
                  <Icon type="cancel"/>
                </div>
              </div>

              <div className="form-item mb_40">
                <Form.Item>
                  {getFieldDecorator("folderName", {
                    rules: [{required: true, message: "required"}],
                    initialValue: type === 'file' ? title.split('.').slice(0, -1).join('.') : title,
                  })(
                    <Input autoComplete="off" placeholder="NO NAME"/>
                  )}
                </Form.Item>
              </div>

              <div className="btn_group text-right">
                <Button
                  title="Cancel"
                  size="lg"
                  theme="default"
                  className="btn-link font-uppercase"
                  onClick={() => toggleFolderModal(false)}
                />
                <Button
                  title="Rename"
                  size="lg"
                  theme="blue"
                  className="radius-8"
                  onClick={handleOnRename}
                />
              </div>
            </Spin>
          </PopUp>
      }

      {
        shareModalVisible &&
          <PopUp
            className="share-popup"
            visible
            innerClassNames="shadow-layout bg-white radius-10 relative lg base"
            onScroll={(e) => infiniteScroll(e)}
          >
            <div className="popup_close color-grey-700" onClick={() => handleShareActionsOnClick(false)}>
              <Icon type="close" size={24}/>
            </div>
            <div className="popup-form">
              <div className="flex-container align-center text-center">
                <div className="text text-28 text-height-33 font-heavy font-spacing-02 color-grey font-uppercase large-7 mb_45">
                  who do you want to share with?
                </div>
              </div>

              <Form.Item className="mb_25 shrink">
                {getFieldDecorator('isFilePrivate', {
                  valuePropName: 'checked',
                  initialValue: parseInt(isPrivate) === 1,
                  rules: [{required: false, message: 'Please fill out this field'}],
                  onChange: (event) => toggleItemAccessType(event.target.checked),
                })(
                  <Checkbox className="c-checkbox-switcher color-grey">
                    {getFieldValue('isFilePrivate') ? 'Private' : 'Public'}
                  </Checkbox>
                )}
              </Form.Item>

              {
                type === 'folder' ?
                <Form.Item className="mb_25 shrink">
                  {getFieldDecorator('isAccessRecursive', {
                    valuePropName: 'checked',
                    initialValue: 0,
                    rules: [{required: false, message: 'Please fill out this field'}],
                    onChange: (event) => console.log(event),
                  })(
                    <Checkbox className="c-checkbox-switcher color-grey">
                      {getFieldValue('isAccessRecursive') ? 'Share All' : 'Share this folder only'}
                    </Checkbox>
                  )}
                </Form.Item> : null
              }

              {
                getFieldValue('isFilePrivate') ?
                  <>
                  {tagsList ?
                    <div className="mb_50">
                      <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_20">
                        GROUPS
                      </div>
                      <div>
                        {
                          tagsList.map((element, index) => (
                            <div key={index} className="flex-container align-middle align-justify user_item_panel">
                              <div className="flex-container align-middle user_item">
                                <div className="radius-circle clip bg-lightblue flex-container align-middle align-center user_item__img">
                                  <div className="text text-20 font-bold color-blue">{element.name.charAt(0)}</div>
                                </div>

                                <div className="text font-semibold color-grey">
                                  {element.name}
                                </div>
                              </div>
                              <Form.Item className="mb_0 shrink form-item-lg">
                                {getFieldDecorator(`user_share_group_${element.id}`, {
                                  valuePropName: "checked",
                                  rules: [{required: false, message: "Please fill out this field"}]
                                })(
                                  <Checkbox
                                    disabled={handleLoading(loading, "AddAccessToDataRoomItem") || handleLoading(loading, "RemoveAccessToDataRoomItem")}
                                    onChange={() => (
                                      toggleGroupOnChange(element.id, !getFieldValue(`user_share_group_${element.id}`))
                                    )}
                                  />
                                )}
                              </Form.Item>
                              {
                                sharedGroups.includes(element.id) && !(handleLoading(loading, "AddAccessToDataRoomItem") || handleLoading(loading, "RemoveAccessToDataRoomItem"))
                                ? <div className="user_item_panel_tooltip">
                                    Shared!
                                  </div>
                                : null
                              }
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    : null}

                  {usersSearchRoomList && usersSearchRoomList.length ?
                    <div className="mb_50">
                      <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_20">
                        USERS
                      </div>
                      <div>
                        {
                          usersSearchRoomList.map((element, index) => (
                            <div key={index} className="flex-container align-middle align-justify user_item_panel">
                              <div className="flex-container align-middle user_item">
                                {
                                  element.avatar ?
                                    <div className="radius-circle clip bg-lightblue flex-container align-middle align-center user_item__img">
                                      <img src={getMediaFileFromServer(element.avatar)} alt=""/>
                                    </div>
                                  :
                                    <div className="radius-circle clip bg-lightblue flex-container align-middle align-center user_item__img">
                                      <div className="text text-20 font-bold color-blue">{element.first_name.charAt(0)}</div>
                                    </div>
                                }

                                <div className="text font-semibold color-grey">
                                  {element.first_name} {element.last_name} <span className="text-12" >({element.email})</span>
                                </div>
                              </div>
                              <Form.Item className="mb_0 shrink form-item-lg">
                                {getFieldDecorator(`user_share_${element.id}`, {
                                  valuePropName: "checked",
                                  initialValue: element.hasAccess,
                                  rules: [{required: false, message: "Please fill out this field"}]
                                })(
                                  <Checkbox
                                    onChange={() => (
                                      toggleLastUserOnChange(element.id, !getFieldValue(`user_share_${element.id}`))
                                    )}
                                  />
                                )}
                              </Form.Item>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  : null}
                  </>
                : null
              }

              <div className="text-right">
                <Button
                  title="CLOSE"
                  size="xl"
                  theme="blue"
                  className="radius-8"
                  style={{fontSize: 18}}
                  onClick={() => handleShareActionsOnClick(false)}
                />
              </div>
            </div>
          </PopUp>
      }
    </>
  )
};

const mapStateToProps = state => ({
  loading: state.dataRoom.loading,
  role: state.user.role,
  usersList: state.users.usersList,
  usersSearchList: state.users.usersSearchList,
  usersSearchRoomList: state.dataRoom.usersSearchRoomList,
  totalUsersSearchRoom: state.dataRoom.totalUsersSearchRoom,
  usersSearchRoomPagination: state.dataRoom.usersSearchRoomPagination,
  tagsList: state.tags.tagsList,
});

const mapDispatchToProps = dispatch => ({
  removeDataRoomItem: (id, parent) => dispatch(removeDataRoomItem(id, parent)),
  renameDataRoomItem: (id, name, parent) => dispatch(renameDataRoomItem(id, name, parent)),
  searchUsersFilter: params => dispatch(searchUsersFilter(params)),
  searchUsers: currentPage => dispatch(searchUsers(currentPage)),
  addAccessToDataRoomItem: params => dispatch(addAccessToDataRoomItem(params)),
  setDataRoomItemAccessType: (params, parent, business) => dispatch(setDataRoomItemAccessType(params, parent, business)),
  removeAccessToDataRoomItem: params => dispatch(removeAccessToDataRoomItem(params)),
  searchBusinessRoomUsers: (business, room) => dispatch(searchBusinessRoomUsers(business, room)),
  searchRoomUsers: (currentPage, room) => dispatch(searchRoomUsers(currentPage, room))
});

const FileCardScreen = Form.create()(FileCard);

export default connect(mapStateToProps, mapDispatchToProps)(FileCardScreen);