import React from 'react'
import Button from '../../components/Button/Button';
import CompletedImage from '../../assets/img/completed.svg';

export const bidsColumns = (isAdmin) => {
  let columns = [
    {
      title: "Bid (Shares)",
      dataIndex: "bid_shares",
      key: "bid_shares",
    },
    {
      title: "Bid (Value)",
      dataIndex: "bid_value",
      key: "bid_value",
    },
    {
      title: "Scope",
      dataIndex: "scope",
      key: "scope",
    },
    {
      title: "Final Allocation",
      dataIndex: "prize",
      key: "prize",
    },
    {
      title: "Max allocation",
      dataIndex: "maxPrize",
      key: "maxPrize",
    },
    {
      title: "created",
      dataIndex: "created",
      key: "created",
    },
  ];

  if(isAdmin) {
    columns = [
      {
        title: "name",
        dataIndex: "first_name",
        key: "first_name",
      },
      {
        title: "surname",
        dataIndex: "last_name",
        key: "last_name",
      },
      {
        title: "Mail",
        dataIndex: "email",
        key: "email",
      },
      ...columns,
      {
        title: "",
        dataIndex: "move_bid",
        key: "move_bid",
        render: ({ handleMoveBid, bidMoved, offerClosed, won }, data) => offerClosed && won ?
          !bidMoved ? <Button
            title="Transfer Shares"
            type="button"
            size="xl"
            theme="blue"
            className="radius-8"
            style={{ fontSize: 18, minWidth: 'max-content' }}
            onClick={(e) => {
              e.stopPropagation();
              handleMoveBid()
            }}
          /> : <div className='flex-container align-center align-middle'>
                  <img width={28} height={28} src={CompletedImage} alt='success-icon' />
                  <div style={{marginLeft: "8px"}}>Moved</div>
                </div>
        : null
      },
    ]
  }

  return columns;
}


export const bidRequestsColumns = () => ([
    {
      title: "name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "surname",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Bid",
      dataIndex: "bid",
      key: "bid",
    },
    {
      title: "created",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "",
      dataIndex: "accept_bid",
      key: "accept_bid",
      render: ({ handleAcceptBid, handleRejectBid, handleAcceptLoading, acceptDisabled, handleRejectLoading, rejectDisabled }) =>
        <div className='flex-container align-middle btn_group small-align-right'>
          <Button
            title="Accept"
            type="button"
            size="xl"
            theme="blue"
            className="radius-8 text-18"
            onClick={(e) => {
              e.stopPropagation();
              handleAcceptBid()
            }}
            loading={handleAcceptLoading}
            disabled={acceptDisabled}
          />
          <Button
            title="Reject"
            type="button"
            size="xl"
            theme="red"
            className="radius-8 text-18"
            onClick={(e) => {
              e.stopPropagation();
              handleRejectBid()
            }}
            loading={handleRejectLoading}
            disabled={rejectDisabled}
          />
        </div>
    },
]);
