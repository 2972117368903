import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Empty, Spin, Table } from "antd";

import history from "../../history";
import { setActiveBusinessTabKey } from "../../redux/business/actions/businessActions";
import { downloadDataRoomItem, getVirtualDataRoomItemsDashboard } from "../../redux/dataRoom/thunks/dataRoomThunks";
import { getOnboarding } from "../../redux/registration/thunks/registrationThunks";
import { setActivePortfolioTabKey } from "../../redux/reports/actions/reportsActions";
import { formatDate } from "../../utils/formatDate";
import { getDataRoomItemsByType } from "../../utils/getDataRoomItemsByType";
import { getDataRoomIconFromFileExtension } from "../../utils/getDataRoomIconFromFileExtension";
import { cutString } from "../../utils/cutString";
import { handleLoading } from "../../utils/handleLoading";
import { detectUserAccess } from "../../utils/detectUserAccess";
import { priorityColorsDetector } from "../../utils/priorityColorsDetector";

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import AnalyticItemsWrapper from "../../components/AnalyticItemsWrapper/AnalyticItemsWrapper";
import './style.scss';

const contactColumns = [
  {
    title: "From",
    dataIndex: "from",
    key: "from",
    render: (text) => (
      <div className="chat_from">
        <i className="icon icon-message-opened" />
        {text}
      </div>
    ),
  },
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Subject",
    dataIndex: "subject",
    key: "subject",
  },
  {
    title: "Importance",
    dataIndex: "importance",
    key: "importance",
    render: (text) => {
      const priorityTextLowerCased = text.toLowerCase();
      const priorityColor = priorityColorsDetector(priorityTextLowerCased);

      return <div className={`imp_tag imp_tag_${priorityColor} font-uppercase`}>{text}</div>;
    },
  },
  {
    title: "Investment",
    dataIndex: "investment",
    key: "investment",
    render: text => text && <div className="invest_tag font-uppercase">{text}</div>,
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
    render: text => text.length > 20 ? `${cutString(text, 0, 20, true)}...` : text,
  },
];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.roomColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        render: ({ file, folder }) => {
          if (file) {
            return (
              <div target="_blank" className="table_file text color-grey font-semibold">
                <img src={file.fileIconSrc} alt="" />
                {file.fileName}
              </div>
            );
          } else if (folder) {
            return (
              <div className="flex-container align-middle table_file text color-grey font-semibold dashboard-data-room-folder-info">
                <Icon type="folder-file" size={20} />
                <div className='dashboard-folder-name' >
                  {folder.name}
                </div>
              </div>
            );
          }
          return null;
        },
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
    ]
  }

  getMessagesList = () => {
    let { inboxMessages } = this.props;
    let messagesList = [];
    inboxMessages = inboxMessages ? inboxMessages : [];

    inboxMessages.map((element) => {
      return messagesList.push({
        key: element.id,
        from: `${element.first_name} ${element.last_name}`,
        date: formatDate(element.created),
        subject: element.subject,
        importance: element.priority,
        investment: element.business_name,
        message: element.body,
      });
    });

    return messagesList;
  };

  sliceArray = (array, elementsSize) => {
    let slicedArray = [];
    if (Array.isArray(array)) {
      if (array.length < elementsSize) {
        slicedArray = array;
      } else {
        slicedArray = array.slice(0, elementsSize).map(i => i);
      }
    }
    return slicedArray;
  };

  getDataRoomTableValues = (dataRoomItems) => {
    let dataRoomTableValuesList = [];
    const { dashboardScreenVirtualDataRoomItems } = this.props;

    dataRoomItems.map((element) => {
      const fileIcon = getDataRoomIconFromFileExtension(element.name);

      return dataRoomTableValuesList.push({
        key: element.id,
        date: formatDate(element.created),
        name: {
          file: {
            fileName: element.name,
            fileIconSrc: require(`../../assets/img/${fileIcon}`),
            token: element.token,
            year: element.year,
            month: element.month,
            day: element.day,
            id: element.id,
          },
        },
      });
    });

    dashboardScreenVirtualDataRoomItems && dashboardScreenVirtualDataRoomItems.map(element => {
      return dataRoomTableValuesList.push({
        key: element.id,
        date: formatDate(element.created),
        name: {
          folder: {
            name: element.name,
            link: `data-room/0/${element.name}/true/${element.id}`,
          },
        },
      });
    });

    return dataRoomTableValuesList;
  };

  isAdmin = () => {
    const { role } = this.props;
    return role === "ADMIN";
  };

  isOnboarded = () => {
    const { userInfo } = this.props;
    return userInfo && parseInt(userInfo.onboarded) === 1;
  };

  isOnboardingFormFilled = () => {
    const { onboardingData } = this.props;
    return onboardingData && onboardingData.other_person_interest != null && onboardingData.other_person_interest !== "";
  };

  handleOnRowClick = (messageItemKey) => {
    history.push(`/contact-center-inner/inbox/${messageItemKey}`);
  };

  handleOpportunityExploreOnClick = (opportunityType) => {
    const { setActiveBusinessTabKey } = this.props;
    setActiveBusinessTabKey(opportunityType);
  };

  handleDownloadFile = ({
    token,
    year,
    month,
    day,
    id,
    fileName,
  }) => {
    const { downloadDataRoomItem } = this.props;
    downloadDataRoomItem(token, year, month, day, id, fileName);
  };

  seeAllAnalyticsOnClick = () => {
    const { setActivePortfolioTabKey } = this.props;
    setActivePortfolioTabKey('analytics');
    history.push('/portfolio');
  };

  handleBusinessFolderOnClick = (folderItem) => {
    localStorage.removeItem('routes');
    const newRoutes = [
      {
        path: '/data-room',
        breadcrumbName: 'Data Room',
      },
      {
        path: folderItem.link,
        breadcrumbName: folderItem.name,
      },
    ];
    localStorage.setItem('routes', JSON.stringify(newRoutes));
    history.push(folderItem.link);
  };

  componentDidMount() {
    const { role, getOnboarding, getVirtualDataRoomItemsDashboard } = this.props;

    if (role === "ADMIN") {
      history.push("/opportunities");
    } else {
      getOnboarding();
      getVirtualDataRoomItemsDashboard();
    }
  }

  render() {
    let {
      dataRoomItems,
      loading,
      likedBusinessItemsCount,
      pursuingBusinessItemsCount,
      closedBusinessItemsCount,
      userInfo,
    } = this.props;
    dataRoomItems = dataRoomItems ? getDataRoomItemsByType(dataRoomItems, "FILE") : [];
    const messagesList = this.getMessagesList();
    const slicedMessagesList = this.sliceArray(messagesList, 3);
    const slicedDataRoomItemsList = this.getDataRoomTableValues(this.sliceArray(dataRoomItems, 3));

    return (
      <Spin spinning={handleLoading(loading, "InitLoad")}>
        <div className="row screen_wrapper">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                <h1>Dashboard</h1>
              </div>
            </div>

            <div className="dashboard_section">
              <div className="flex-container align-middle align-justify section_top">
                <div className="section_title custom-headline text text-22 text-height-34 font-bold font-uppercase color-grey flex-container align-middle">
                  <i className="icon icon-flag" />
                  <h2>Primary deals</h2>
                </div>

                <div className="hide-sm">
                  <Button
                    title="See all opportunities"
                    suffix={<Icon type="lines-right" size={16} style={{marginLeft: 5}} />}
                    size="lg"
                    theme="white"
                    className="btn-link text text-16"
                    onClick={() => this.handleOpportunityExploreOnClick("all")}
                    linkTo="/opportunities"
                  />
                </div>
              </div>

              <div className="row">
                <div className="column small-12 medium-4">
                  <Link
                    to="/opportunities"
                    className="bg-white shadow-layout base_card display-block"
                    onClick={() => this.handleOpportunityExploreOnClick("interested")}
                    style={{ color: '#36C1A3' }}
                  >
                    <div className="text font-medium font-uppercase color-grey-800 base_card__title">
                      Interested opportunities
                    </div>
                    <div className="flex-container align-bottom align-justify">
                      <div>
                        <div className="text text-28 text-height-48 font-heavy font-uppercase font-spacing-02 color-grey ">
                          {likedBusinessItemsCount}
                        </div>
                        <Button
                          title="Explore"
                          suffix={<Icon type="lines-right" size={16} style={{ marginLeft: 5 }} />}
                          size="lg"
                          theme="default"
                          className="btn-link text text-16"
                        />
                      </div>

                      <div className="radius-8 flex-container align-middle align-center relative clip base_card_icon">
                        <Icon type="star" size={20} />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="column small-12 medium-4">
                  <Link
                    to="/opportunities"
                    className="bg-white shadow-layout base_card display-block"
                    onClick={() => this.handleOpportunityExploreOnClick("pursuing")}
                    style={{ color: '#F5C65B' }}
                  >
                    <div className="text font-medium font-uppercase color-grey-800 base_card__title">
                      Pursuing Opportunities
                    </div>
                    <div className="flex-container align-bottom align-justify">
                      <div>
                        <div className="text text-28 text-height-48 font-heavy font-uppercase font-spacing-02 color-grey ">
                          {pursuingBusinessItemsCount}
                        </div>
                        <Button
                          title="Explore"
                          suffix={<Icon type="lines-right" size={16} style={{ marginLeft: 5 }} />}
                          size="lg"
                          theme="default"
                          className="btn-link text text-16"
                        />
                      </div>

                      <div className="radius-8 flex-container align-middle align-center relative clip base_card_icon">
                        <Icon type="lamp" size={20} />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="column small-12 medium-4">
                  <Link
                    to="/opportunities"
                    className="bg-white shadow-layout base_card display-block"
                    onClick={() => this.handleOpportunityExploreOnClick("closed")}
                    style={{ color: '#FF551F' }}
                  >
                    <div className="text font-medium font-uppercase color-grey-800 base_card__title">
                      Closed deals
                    </div>
                    <div className="flex-container align-bottom align-justify">
                      <div>
                        <div className="text text-28 text-height-48 font-heavy font-uppercase font-spacing-02 color-grey ">
                          {closedBusinessItemsCount}
                        </div>
                        <Button
                          title="Explore"
                          suffix={<Icon type="lines-right" size={16} style={{ marginLeft: 5 }} />}
                          size="lg"
                          theme="default"
                          className="btn-link text text-16"
                        />
                      </div>

                      <div className="radius-8 flex-container align-middle align-center relative clip base_card_icon">
                        <Icon type="checked" size={20} />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="hide-lg hide-md show-sm">
                <Button
                  title="See all opportunities"
                  suffix={<Icon type="lines-right" size={16} style={{ marginLeft: 5 }} />}
                  size="lg"
                  theme="white"
                  className="btn-link text text-16"
                  onClick={() => this.handleOpportunityExploreOnClick("all")}
                  linkTo="/opportunities"
                />
              </div>
            </div>

            {
              detectUserAccess('access_portfolio', userInfo) &&
                <div className="dashboard_section">
                  <div className="flex-container align-middle align-justify section_top">
                    <div className="section_title custom-headline text text-22 text-height-34 font-bold font-uppercase color-grey flex-container align-middle">
                      <i className="icon icon-portfolio" />
                      <h2>MY PORTFOLIO</h2>
                    </div>

                    <div className="hide-sm">
                      <Button
                        title="See all analytics"
                        suffix={<Icon type="lines-right" size={16} style={{ marginLeft: 5 }} />}
                        size="lg"
                        theme="white"
                        className="btn-link text text-16 hide-sm"
                        onClick={this.seeAllAnalyticsOnClick}
                      />
                    </div>
                  </div>

                  <AnalyticItemsWrapper />
                </div>
            }

            <div className="dashboard_section">
              <div className="flex-container align-middle align-justify section_top">
                <div className="section_title custom-headline text text-22 text-height-34 font-bold font-uppercase color-grey flex-container align-middle">
                  <i className="icon icon-chat" />
                  <h2>CONTACT CENTER</h2>
                </div>

                <div className="hide-sm">
                  <Button
                    title="See all messages"
                    suffix={<Icon type="lines-right" size={16} style={{ marginLeft: 5 }} />}
                    size="lg"
                    theme="white"
                    className="btn-link text text-16"
                    linkTo="/contact-center"
                  />
                </div>
              </div>

              <div className="table_wrapper bg-white radius-10 shadow-layout expand_sm">
                <Table
                  dataSource={slicedMessagesList}
                  columns={contactColumns}
                  className="c-table c-table-contacts hide_paging dashboard_contact"
                  onRow={(messageItem) => ({onClick: () => this.handleOnRowClick(messageItem.key)})}
                />
              </div>

              <div className="hide-lg hide-md show-sm mt_20">
                <Button
                  title="See all messages"
                  suffix={<Icon type="lines-right" size={16} style={{ marginLeft: 5 }} />}
                  size="lg"
                  theme="white"
                  className="btn-link text text-16"
                  linkTo="/contact-center"
                />
              </div>
            </div>

            {
              detectUserAccess('access_files', userInfo) ?
                <div className="dashboard_section">
                  <div className="flex-container align-middle align-justify section_top">
                    <div className="section_title custom-headline text text-22 text-height-34 font-bold font-uppercase color-grey flex-container align-middle">
                      <i className="icon icon-folders" />
                      <h2>DATA ROOM</h2>
                    </div>

                    <div className="hide-sm">
                      <Button
                        title="See all files"
                        suffix={<Icon type="lines-right" size={16} style={{ marginLeft: 5 }} />}
                        size="lg"
                        theme="white"
                        className="btn-link text text-16"
                        linkTo="/data-room"
                      />
                    </div>
                  </div>

                  <div className="table_wrapper bg-white radius-10 shadow-layout expand_sm">
                    <Table
                      dataSource={slicedDataRoomItemsList}
                      locale={{ emptyText: <Empty description="You haven't uploaded files yet" image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
                      columns={this.roomColumns}
                      className="c-table c-table-bordered hide_paging dashboard_data table-row-cursor"
                      onRow={
                        (record, rowIndex) => ({
                          onClick: (event) => {
                            const { file, folder } = record.name;

                            if(file) {
                              this.handleDownloadFile(file);
                            } else if(folder) {
                              this.handleBusinessFolderOnClick(folder)
                            }
                          },
                        })
                      }
                    />
                  </div>

                  <div className="hide-lg hide-md show-sm mt_20">
                    <Button
                      title="See all files"
                      suffix={<Icon type="lines-right" size={16} style={{ marginLeft: 5 }} />}
                      size="lg"
                      theme="white"
                      className="btn-link text text-16"
                      linkTo="/data-room"
                    />
                  </div>
                </div>
              : null
            }
          </div>

          <div className="column small-12">
            <div className="dashboard_section" />
          </div>
        </div>
        {
          (!this.isAdmin() && !this.isOnboarded()) &&
            <div className="bg-white flex-container align-middle align-center c_toast pined-bottom">
              <div className="medium-flex-container align-middle align-center x-large-8 c_toast__content">
                <div className="c_toast__content_icon">
                  <Icon type="info-circle" className="color-yellow" size={24} />
                </div>
                <div className="text text-height-22 color-grey-900">
                  {
                    !this.isOnboardingFormFilled() ?
                      <>
                        In order to become an Onboarded user and get access
                        to the Data Room and invest in Opportunities,
                        <Link
                          to="/onboarding1"
                          className="font-medium color-yellow"
                          style={{ marginLeft: 5 }}
                        >
                          please continue the registration
                        </Link>
                      </>
                    : <>Your Onboarding application was successfully submitted.  We will be in touch with you shortly.</>
                  }
                </div>
              </div>
            </div>
        }
      </Spin>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.user.loading,
  role: state.user.role,
  onboardingData: state.registration.onboardingData,
  inboxMessages: state.messages.inboxMessages,
  businesses: state.business.businesses,
  dashboardScreenBusinesses: state.business.dashboardScreenBusinesses,
  savedBusinesses: state.business.savedBusinesses,
  pursuingBusinesses: state.business.pursuingBusinesses,
  closedBusinesses: state.business.closedBusinesses,
  dataRoomItems: state.dataRoom.dashboardScreenDataRoomItems,
  dashboardScreenVirtualDataRoomItems: state.dataRoom.dashboardScreenVirtualDataRoomItems,
  userInfo: state.user.userInfo,
  likedBusinessItemsCount: state.business.likedBusinessItemsCount,
  pursuingBusinessItemsCount: state.business.pursuingBusinessItemsCount,
  closedBusinessItemsCount: state.business.closedBusinessItemsCount,
});

const mapDispatchToProps = dispatch => ({
  setActiveBusinessTabKey: (activeTabKey) => dispatch(setActiveBusinessTabKey(activeTabKey)),
  downloadDataRoomItem: (token, year, month, day, id, fileName) => dispatch(downloadDataRoomItem(token, year, month, day, id, fileName)),
  getOnboarding: () => dispatch(getOnboarding()),
  getVirtualDataRoomItemsDashboard: () => dispatch(getVirtualDataRoomItemsDashboard()),
  setActivePortfolioTabKey: (tabKey) => dispatch(setActivePortfolioTabKey(tabKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
