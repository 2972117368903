import produce from 'immer';
import moment from "moment";
import {
  GET_PROFILE_START, GET_PROFILE_SUCCESS, GET_PROFILE_FAIL,
  INIT_LOAD_START, INIT_LOAD_SUCCESS, INIT_LOAD_FAIL,
  CREATE_BUSINESS_START, CREATE_BUSINESS_SUCCESS, CREATE_BUSINESS_FAIL,
  UPDATE_BUSINESS_START, UPDATE_BUSINESS_SUCCESS, UPDATE_BUSINESS_FAIL,
  GET_BUSINESS_START, GET_BUSINESS_SUCCESS, GET_BUSINESS_FAIL,
  GET_PURSUING_BUSINESSES_START, GET_PURSUING_BUSINESSES_SUCCESS, GET_PURSUING_BUSINESSES_FAIL,
  REMOVE_CURRENT_BUSINESS, SET_BUSINESS_FILTER,
  GET_BUSINESSES_START, GET_BUSINESSES_SUCCESS, GET_BUSINESSES_FAIL,
  GET_BUSINESSES_BY_NAME_START, GET_BUSINESSES_BY_NAME_SUCCESS, GET_BUSINESSES_BY_NAME_FAIL,
  SET_SAVED_START, SET_SAVED_SUCCESS, SET_SAVED_FAIL,
  SET_INTERESTED_START, SET_INTERESTED_SUCCESS, SET_INTERESTED_FAIL,
  SIGN_NDA_START, SIGN_NDA_SUCCESS, SIGN_NDA_FAIL,
  GET_NDA_START, GET_NDA_SUCCESS, GET_NDA_FAIL,
  READY_TO_START_START, READY_TO_START_SUCCESS, READY_TO_START_FAIL,
  READY_TO_COMMIT_START, READY_TO_COMMIT_SUCCESS, READY_TO_COMMIT_FAIL,
  READY_TO_CLOSE_START, READY_TO_CLOSE_SUCCESS, READY_TO_CLOSE_FAIL,
  SEARCH_BUSINESS_USERS_START, SEARCH_BUSINESS_USERS_SUCCESS, SEARCH_BUSINESS_USERS_FAIL,
  ALLOW_COMMIT_START, ALLOW_COMMIT_SUCCESS, ALLOW_COMMIT_FAIL,
  ALLOW_CLOSE_START, ALLOW_CLOSE_SUCCESS, ALLOW_CLOSE_FAIL,
  CLOSE_START, CLOSE_SUCCESS, CLOSE_FAIL,
  REOPEN_START, REOPEN_SUCCESS, REOPEN_FAIL,
  SET_ACTIVE_BUSINESS_TAB_KEY, RESET_BUSINESS_FILTER,
  SET_INVESTMENT_INFO_START, SET_INVESTMENT_INFO_SUCCESS, SET_INVESTMENT_INFO_FAIL,
  ENABLE_BUSINESS_START, ENABLE_BUSINESS_SUCCESS, ENABLE_BUSINESS_FAIL,
  DISABLE_BUSINESS_START, DISABLE_BUSINESS_SUCCESS, DISABLE_BUSINESS_FAIL,
  SET_BUSINESS_EXPIRED_START, SET_BUSINESS_EXPIRED_SUCCESS, SET_BUSINESS_EXPIRED_FAIL, GET_BUSINESSESS_SUMMARY_FAIL, GET_BUSINESSESS_SUMMARY_SUCCESS, GET_BUSINESSES_SUMMARY_START, SET_PEGATE_SHAREHOLDING_START, SET_PEGATE_SHAREHOLDING_SUCCESS, SET_PEGATE_SHAREHOLDING_FAIL, REMOVE_BUSINESS_INVESTOR_SUCCESS, REMOVE_BUSINESS_INVESTOR_START, REMOVE_BUSINESS_INVESTOR_FAIL, RESET_BUSINESS_LOADING, SEARCH_BUSINESS_USERS_BIDS_START, SEARCH_BUSINESS_USERS_BIDS_SUCCESS, SEARCH_BUSINESS_USERS_BIDS_FAIL,
  SEARCH_BUSINESS_INVOLVE_REQUESTS_START,
  SEARCH_BUSINESS_INVOLVE_REQUESTS_SUCCESS,
  SEARCH_BUSINESS_INVOLVE_REQUESTS_FAIL,
  ADD_INVOLVE_REQUESTS_START,
  ADD_INVOLVE_REQUESTS_SUCCESS,
  ADD_INVOLVE_REQUESTS_FAIL,
  GET_BUSINESS_INVOLVE_REQUEST_START,
  GET_BUSINESS_INVOLVE_REQUEST_SUCCESS,
  GET_BUSINESS_INVOLVE_REQUEST_FAIL,
  ACCEPT_INVOLVE_REQUEST_START,
  ACCEPT_INVOLVE_REQUEST_SUCCESS,
  ACCEPT_INVOLVE_REQUEST_FAIL,
  REJECT_INVOLVE_REQUEST_START,
  REJECT_INVOLVE_REQUEST_SUCCESS,
  REJECT_INVOLVE_REQUEST_FAIL,
  RESET_INVOLVE_REQUEST_DATA,
} from "../constants";

const initialState = {
  loading: {
    type: "",
    state: false
  },
  errorMessage: {
    type: "",
    message: "",
  },
  currentPage: 1,
  businessesCount: 0,
  likedBusinessItemsCount: 0,
  notInterestedBusinessItemsCount: 0,
  pursuingBusinessItemsCount: 0,
  closedBusinessItemsCount: 0,
  newBusinessTypeItemsCount: 0,
  ignoredBusinessTypeItemsCount: 0,

  dataRoomVirtualItemsCount: 0,
  dataRoomVirtualItems: null,
  currentDataRoomVirtualItemsPage: 1,

  likedBusinessItems: null,
  notInterestedBusinessItems: null,
  pursuingBusinessItems: null,
  closedBusinessItems: null,
  newBusinessTypeItems: null,
  ignoredBusinessTypeItems: null,

  activeTabKey: "all",

  businessesSearchList: null,
  currentOpenedBusiness: null,
  businessSummary: null,
  businesses: null,
  dashboardScreenBusinesses: null,
  savedBusinesses: null,
  pursuingBusinesses: null,
  closedBusinesses: null,
  businessUsers: null,
  businessInvolveRequests: null,
  currentUserWinningBids: null,
  involveRequest: null,
  filter: {
    // filterCategory: [],
    industry: [],
    transaction_status: [],
    country: [],
    state: [],
    investment_type: [],
    filterMinInvest: [],
    name: '',
  }
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_LOAD_START:
      return produce(state, draft => {
        draft.loading.type = "InitLoad";
        draft.loading.state = true;
      });
    case INIT_LOAD_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        if (action.payload.hasOwnProperty("businesses")) {
          draft.businesses = action.payload.businesses;
          draft.dashboardScreenBusinesses = action.payload.businesses;
        }
        action.payload.hasOwnProperty("savedBusinesses") && (draft.savedBusinesses = action.payload.likedBusinessItems);
        action.payload.hasOwnProperty("pursuingBusinesses") && (draft.pursuingBusinesses = action.payload.pursuingBusinessItems);
        action.payload.hasOwnProperty("closedBusinesses") && (draft.closedBusinesses = action.payload.closedBusinessItems);
        action.payload.hasOwnProperty("businessesCount") && (draft.businessesCount = action.payload.businessesCount);
        action.payload.hasOwnProperty("likedBusinessItemsCount") && (draft.likedBusinessItemsCount = action.payload.likedBusinessItemsCount);
        action.payload.hasOwnProperty("pursuingBusinessItemsCount") && (draft.pursuingBusinessItemsCount = action.payload.pursuingBusinessItemsCount);
        action.payload.hasOwnProperty("closedBusinessItemsCount") && (draft.closedBusinessItemsCount = action.payload.closedBusinessItemsCount);
        action.payload.hasOwnProperty("ignoredBusinessTypeItemsCount") && (draft.ignoredBusinessTypeItemsCount = action.payload.ignoredBusinessTypeItemsCount);
        action.payload.hasOwnProperty("likedBusinessItems") && (draft.likedBusinessItems = action.payload.likedBusinessItems);
        action.payload.hasOwnProperty("pursuingBusinessItems") && (draft.pursuingBusinessItems = action.payload.pursuingBusinessItems);
        action.payload.hasOwnProperty("closedBusinessItems") && (draft.closedBusinessItems = action.payload.closedBusinessItems);
      });
    case RESET_BUSINESS_LOADING:
      return produce(state, draft => {
        draft.loading = initialState.loading;
      });
    case INIT_LOAD_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case GET_PROFILE_START:
      return produce(state, draft => {

      });
    case GET_PROFILE_SUCCESS:
      return produce(state, draft => {
        action.payload.hasOwnProperty("businesses") && (draft.businesses = action.payload.businesses);
      });
    case GET_PROFILE_FAIL:
      return produce(state, draft => {

      });
    case CREATE_BUSINESS_START:
      return produce(state, draft => {
        draft.loading.type = "CreateBusiness";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case CREATE_BUSINESS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.businesses = action.payload.businesses;
        draft.currentPage = action.payload.currentPage;
        draft.businessesCount = action.payload.businessesCount;
      });
    case CREATE_BUSINESS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "CreateBusiness";
        draft.errorMessage.message = action.payload;
      });
    case UPDATE_BUSINESS_START:
      return produce(state, draft => {
        draft.loading.type = "UpdateBusiness";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case UPDATE_BUSINESS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.businesses = action.payload.businesses;
        draft.currentPage = action.payload.currentPage;
      });
    case UPDATE_BUSINESS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "UpdateBusiness";
        draft.errorMessage.message = action.payload;
      });
    case GET_BUSINESS_START:
      return produce(state, draft => {
        draft.loading.type = "GetBusiness";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_BUSINESS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        // ^^^
        draft.currentOpenedBusiness = action.payload.currentOpenedBusiness.closed ? {...action.payload.currentOpenedBusiness, closed: moment(action.payload.currentOpenedBusiness.closed).subtract(6, 'months').format('YYYY-MM-DD hh:mm:ss')} : action.payload.currentOpenedBusiness;
        draft.loading.type = "GetBusiness";
      });
    case GET_BUSINESS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetBusiness";
        draft.errorMessage.message = action.payload;
      });
    case GET_BUSINESS_INVOLVE_REQUEST_START:
      return produce(state, draft => {
        draft.loading.type = "GetBusinessInvolveRequest";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_BUSINESS_INVOLVE_REQUEST_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        // ^^^
        draft.involveRequest = action.payload.involveRequest;
        draft.loading.type = "GetBusinessInvolveRequest";
      });
    case GET_BUSINESS_INVOLVE_REQUEST_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetBusinessInvolveRequest";
        draft.errorMessage.message = action.payload;
      });
    case RESET_INVOLVE_REQUEST_DATA:
      return produce(state, draft => {
        draft.involveRequest = null;
      });
    case GET_BUSINESSES_SUMMARY_START:
      return produce(state, draft => {
        draft.loading.type = "BusinessSummary";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_BUSINESSESS_SUMMARY_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.businessSummary = action.payload;
      });
    case GET_BUSINESSESS_SUMMARY_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "BusinessSummary";
        draft.errorMessage.message = action.payload;
      });
    case GET_PURSUING_BUSINESSES_START:
      return produce(state, draft => {
        draft.loading.type = "GetPursuingBusiness";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_PURSUING_BUSINESSES_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.dataRoomVirtualItems = action.payload.dataRoomVirtualItems;
        draft.currentDataRoomVirtualItemsPage = action.payload.currentDataRoomVirtualItemsPage;
        draft.dataRoomVirtualItemsCount = action.payload.dataRoomVirtualItemsCount;
      });
    case GET_PURSUING_BUSINESSES_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetPursuingBusiness";
        draft.errorMessage.message = action.payload;
      });
    case REMOVE_CURRENT_BUSINESS:
      return produce(state, draft => {
        draft.currentOpenedBusiness = null;
      });
    case SET_BUSINESS_FILTER:
      return produce(state, draft => {
        draft.filter[action.payload.filterType] = action.payload.filterValue;
      });
    case RESET_BUSINESS_FILTER:
      return produce(state, draft => {
        draft.filter = {
          // filterCategory: [],
          industry: [],
          transaction_status: [],
          country: [],
          state: [],
          investment_type: [],
          filterMinInvest: [],
          name: '',
        };
      });
    case GET_BUSINESSES_START:
      return produce(state, draft => {
        draft.loading.type = "GetBusiness";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_BUSINESSES_SUCCESS:
      return produce(state, draft => {
        const payloadFilter = action.payload.filter;
        if (payloadFilter.minInvestmentFrom && payloadFilter.minInvestmentTo) {
          payloadFilter.filterMinInvest = [payloadFilter.minInvestmentFrom, payloadFilter.minInvestmentTo];
          delete payloadFilter.minInvestmentFrom;
          delete payloadFilter.minInvestmentTo;
        }

        draft.loading.state = false;
        draft.businesses = action.payload.businesses;
        draft.dashboardScreenBusinesses = action.payload.businesses;
        draft.savedBusinesses = action.payload.likedBusinessItems;
        draft.pursuingBusinesses = action.payload.pursuingBusinessItems;
        draft.closedBusinesses = action.payload.closedBusinessItems;
        draft.filter = payloadFilter;
        draft.businessesCount = action.payload.businessesCount;
        draft.currentPage = action.payload.currentPage;

        draft.likedBusinessItemsCount = action.payload.likedBusinessItemsCount;
        draft.notInterestedBusinessItemsCount = action.payload.notInterestedBusinessItemsCount;
        draft.pursuingBusinessItemsCount = action.payload.pursuingBusinessItemsCount;
        draft.closedBusinessItemsCount = action.payload.closedBusinessItemsCount;
        draft.newBusinessTypeItemsCount = action.payload.newBusinessTypeItemsCount;
        draft.ignoredBusinessTypeItemsCount = action.payload.ignoredBusinessTypeItemsCount;

        draft.likedBusinessItems = action.payload.likedBusinessItems;
        draft.notInterestedBusinessItems = action.payload.notInterestedBusinessItems;
        draft.pursuingBusinessItems = action.payload.pursuingBusinessItems;
        draft.closedBusinessItems = action.payload.closedBusinessItems;
        draft.newBusinessTypeItems = action.payload.newBusinessTypeItems;
        draft.ignoredBusinessTypeItems = action.payload.ignoredBusinessTypeItems;
      });
    case GET_BUSINESSES_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetBusiness";
        draft.errorMessage.message = action.payload;
      });
    case GET_BUSINESSES_BY_NAME_START:
      return produce(state, draft => {
        draft.loading.type = "GetBusinessesByName";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_BUSINESSES_BY_NAME_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.businessesSearchList = action.payload.businessesSearchList;
      });
    case GET_BUSINESSES_BY_NAME_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetBusinessesByName";
        draft.errorMessage.message = action.payload;
      });
    case SET_SAVED_START:
      return produce(state, draft => {
        draft.loading.type = "SetSaved";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SET_SAVED_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentOpenedBusiness = action.payload.currentOpenedBusiness;
      });
    case SET_SAVED_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SetSaved";
        draft.errorMessage.message = action.payload;
      });
    case SET_INTERESTED_START:
      return produce(state, draft => {
        draft.loading.type = "SetInterested";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SET_INTERESTED_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentOpenedBusiness = action.payload.currentOpenedBusiness;
        /*draft.businesses = action.payload.businesses;
        draft.dashboardScreenBusinesses = action.payload.businesses;
        draft.savedBusinesses = action.payload.savedBusinesses;
        draft.pursuingBusinesses = action.payload.pursuingBusinesses;
        draft.closedBusinesses = action.payload.closedBusinesses;*/
      });
    case SET_INTERESTED_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SetInterested";
        draft.errorMessage.message = action.payload;
      });
    case SIGN_NDA_START:
      return produce(state, draft => {
        draft.loading.type = "SignNDA";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SIGN_NDA_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentOpenedBusiness = action.payload.currentOpenedBusiness;
        /*draft.businesses = action.payload.businesses;
        draft.savedBusinesses = action.payload.savedBusinesses;
        draft.pursuingBusinesses = action.payload.pursuingBusinesses;
        draft.closedBusinesses = action.payload.closedBusinesses;*/
      });
    case SIGN_NDA_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SignNDA";
        draft.errorMessage.message = action.payload;
      });
    case GET_NDA_START:
      return produce(state, draft => {
        draft.loading.type = "GetNDA";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_NDA_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case GET_NDA_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetNDA";
        draft.errorMessage.message = "";
      });
    case READY_TO_START_START:
      return produce(state, draft => {
        draft.loading.type = "ReadyToStart";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case READY_TO_START_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentOpenedBusiness = action.payload.currentOpenedBusiness;
        /*draft.businesses = action.payload.businesses;
        draft.savedBusinesses = action.payload.savedBusinesses;
        draft.pursuingBusinesses = action.payload.pursuingBusinesses;
        draft.closedBusinesses = action.payload.closedBusinesses;*/
      });
    case READY_TO_START_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "ReadyToStart";
        draft.errorMessage.message = action.payload;
      });
    case READY_TO_COMMIT_START:
      return produce(state, draft => {
        draft.loading.type = "ReadyToCommit";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case READY_TO_COMMIT_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentOpenedBusiness = action.payload.currentOpenedBusiness;
        /*draft.businesses = action.payload.businesses;
        draft.savedBusinesses = action.payload.savedBusinesses;
        draft.pursuingBusinesses = action.payload.pursuingBusinesses;
        draft.closedBusinesses = action.payload.closedBusinesses;*/
      });
    case READY_TO_COMMIT_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "ReadyToCommit";
        draft.errorMessage.message = action.payload;
      });
    case READY_TO_CLOSE_START:
      return produce(state, draft => {
        draft.loading.type = "ReadyToClose";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case READY_TO_CLOSE_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentOpenedBusiness = action.payload.currentOpenedBusiness;
        /*draft.businesses = action.payload.businesses;
        draft.savedBusinesses = action.payload.savedBusinesses;
        draft.pursuingBusinesses = action.payload.pursuingBusinesses;
        draft.closedBusinesses = action.payload.closedBusinesses;*/
      });
    case READY_TO_CLOSE_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "ReadyToClose";
        draft.errorMessage.message = action.payload;
      });
    case SEARCH_BUSINESS_USERS_START:
      return produce(state, draft => {
        draft.loading.type = "SearchBusinessUsers";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEARCH_BUSINESS_USERS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.businessUsers = action.payload.businessUsers;
      });
    case SEARCH_BUSINESS_USERS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SearchBusinessUsers";
        draft.errorMessage.message = action.payload;
      });
    case SEARCH_BUSINESS_INVOLVE_REQUESTS_START:
      return produce(state, draft => {
        draft.loading.type = "SearchBusinessInvolveRequests";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEARCH_BUSINESS_INVOLVE_REQUESTS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.businessInvolveRequests = action.payload.businessInvolveRequests;
      });
    case SEARCH_BUSINESS_INVOLVE_REQUESTS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SearchBusinessInvolveRequests";
        draft.errorMessage.message = action.payload;
      });
    case ADD_INVOLVE_REQUESTS_START:
      return produce(state, draft => {
        draft.loading.type = "AddInvolveRequest";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ADD_INVOLVE_REQUESTS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case ADD_INVOLVE_REQUESTS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "AddInvolveRequest";
        draft.errorMessage.message = action.payload;
      });
    case ACCEPT_INVOLVE_REQUEST_START:
      return produce(state, draft => {
        draft.loading.type = "AcceptInvolveRequest";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ACCEPT_INVOLVE_REQUEST_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case ACCEPT_INVOLVE_REQUEST_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "AcceptInvolveRequest";
        draft.errorMessage.message = action.payload;
      });
    case REJECT_INVOLVE_REQUEST_START:
      return produce(state, draft => {
        draft.loading.type = "RejectInvolveRequest";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case REJECT_INVOLVE_REQUEST_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case REJECT_INVOLVE_REQUEST_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "RejectInvolveRequest";
        draft.errorMessage.message = action.payload;
      });
    case ALLOW_COMMIT_START:
      return produce(state, draft => {
        draft.loading.type = "AllowCommit";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ALLOW_COMMIT_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;

      });
    case ALLOW_COMMIT_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "AllowCommit";
        draft.errorMessage.message = action.payload;
      });
    case ALLOW_CLOSE_START:
      return produce(state, draft => {
        draft.loading.type = "AllowClose";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ALLOW_CLOSE_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;

      });
    case ALLOW_CLOSE_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "AllowClose";
        draft.errorMessage.message = action.payload;
      });
    case CLOSE_START:
      return produce(state, draft => {
        draft.loading.type = "Close";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case CLOSE_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;

      });
    case CLOSE_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "Close";
        draft.errorMessage.message = action.payload;
      });
    case REOPEN_START:
      return produce(state, draft => {
        draft.loading.type = "Reopen";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case REOPEN_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case REOPEN_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "Reopen";
        draft.errorMessage.message = action.payload;
      });
    case SET_ACTIVE_BUSINESS_TAB_KEY:
      return produce(state, draft => {
        draft.activeTabKey = action.payload;
      });
    case SET_INVESTMENT_INFO_START:
      return produce(state, draft => {
        draft.loading.type = "SetInvestmentInfo";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SET_INVESTMENT_INFO_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.businessUsers = action.payload.businessUsers;
      });
    case SET_INVESTMENT_INFO_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SetInvestmentInfo";
        draft.errorMessage.message = action.payload;
      });
    case ENABLE_BUSINESS_START:
      return produce(state, draft => {
        draft.loading.type = "EnableBusiness";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ENABLE_BUSINESS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentOpenedBusiness = action.payload.currentOpenedBusiness;
      });
    case ENABLE_BUSINESS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "EnableBusiness";
        draft.errorMessage.message = action.payload;
      });
    case DISABLE_BUSINESS_START:
      return produce(state, draft => {
        draft.loading.type = "DisableBusiness";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case DISABLE_BUSINESS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentOpenedBusiness = action.payload.currentOpenedBusiness;
      });
    case DISABLE_BUSINESS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "DisableBusiness";
        draft.errorMessage.message = action.payload;
      });
    case SET_BUSINESS_EXPIRED_START:
      return produce(state, draft => {
        draft.loading.type = "SetBusinessExpired";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SET_BUSINESS_EXPIRED_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentOpenedBusiness = action.payload.currentOpenedBusiness;
      });
    case SET_BUSINESS_EXPIRED_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SetBusinessExpired";
        draft.errorMessage.message = action.payload;
      });
      case SET_PEGATE_SHAREHOLDING_START:
        return produce(state, draft => {
          draft.loading.type = "SetPegateShareholding";
          draft.loading.state = true;
          draft.errorMessage.type = "";
          draft.errorMessage.message = "";
        });
      case SET_PEGATE_SHAREHOLDING_SUCCESS:
        return produce(state, draft => {
          draft.loading.state = false;
        });
      case SET_PEGATE_SHAREHOLDING_FAIL:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.errorMessage.type = "SetPegateShareholding";
          draft.errorMessage.message = action.payload;
        });
      case REMOVE_BUSINESS_INVESTOR_START:
        return produce(state, draft => {
          draft.loading.type = "RemoveBusinessInvestor";
          draft.loading.state = true;
          draft.errorMessage.type = "";
          draft.errorMessage.message = "";
        });
      case REMOVE_BUSINESS_INVESTOR_SUCCESS:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.loading.type = "RemoveBusinessInvestor";
        });
      case REMOVE_BUSINESS_INVESTOR_FAIL:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.errorMessage.type = "RemoveBusinessInvestor";
          draft.errorMessage.message = action.payload;
        });
      case SEARCH_BUSINESS_USERS_BIDS_START:
        return produce(state, draft => {
          draft.loading.type = "searchBusinessUserBids";
          draft.loading.state = true;
          draft.errorMessage.type = "";
          draft.errorMessage.message = "";
        });
      case SEARCH_BUSINESS_USERS_BIDS_SUCCESS:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.loading.type = "searchBusinessUserBids";
          console.log(action);
          draft.currentUserWinningBids = action.payload.currentUserWinningBids;
        });
      case SEARCH_BUSINESS_USERS_BIDS_FAIL:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.errorMessage.type = "searchBusinessUserBids";
          draft.errorMessage.message = action.payload;
        });
    default:
      return state;
  }
};

export default businessReducer;
