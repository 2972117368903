import React, {Component} from "react";
import { connect } from "react-redux";
import {
  AutoComplete,
  Form,
  Input,
  message,
  Select,
  Spin,
  Table,
} from 'antd';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { searchStatisticsUsers } from "../../../redux/users/thunks/searchUsersThunk";
import {addReport, getNewReport, getReport} from "../../../redux/reports/thunks/reportsThunk";
import {removeReports} from "../../../redux/reports/actions/reportsActions";
import {getMediaFileFromServer} from "../../../utils/getMediaFileFromServer";
import {formatNumber} from "../../../utils/formatNumber";
import {handleLoading} from "../../../utils/handleLoading";
import {isQuarterOptionDisabled} from "../../../utils/isQuarterOptionDisabled";
import {goBack} from "../../../utils/goBack";

import PortfolioInner from "./AdminCharts";
import Button from "../../../components/Button/Button";
import Icon from "../../../components/Icon/Icon";
import PopUp from "../../../components/PopUp/PopUp";
import NumericInput from "../../../components/NumericInput/NumericInput";
import './style.scss';

const {Option} = AutoComplete;

let yearsList = [];
const currentYear = new Date().getFullYear();

for (let i = currentYear - 4; i < currentYear; i++) {
  yearsList.push(i);
}

yearsList.push(currentYear);

for (let i = currentYear + 1; i < currentYear + 5; i++) {
  yearsList.push(i);
}

const setPerformanceCategories = (year) => {
  return [`Jan ${year}`, `Mar ${year}`, `Jun ${year}`, `Sep ${year}`];
};

class AdminStatistics extends Component {
  constructor(props) {
    super(props);

    this.reportsTableColumns = [
      {
        title: 'year',
        dataIndex: 'year',
        key: 'year',
      },
      {
        title: 'quarter',
        dataIndex: 'quarter',
        key: 'quarter',
      },
      {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'revenue',
        dataIndex: 'revenue',
        key: 'revenue',
        render: (text) => <span>{formatNumber(text)}</span>,
      },
      {
        title: 'gross profit',
        dataIndex: 'gross_profit',
        key: 'gross_profit',
        render: (text) => <span>{formatNumber(text)}</span>,
      },
      {
        title: 'Net profit',
        dataIndex: 'net_profit',
        key: 'net_profit',
        render: (text) => <span>{formatNumber(text)}</span>,
      },
      {
        title: 'ebitda',
        dataIndex: 'ebitda',
        key: 'ebitda',
        render: (text) => <span>{formatNumber(text)}</span>,
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (payload) => (
          <div className="icon_btn">
            <Button
              title={<Icon type="pen" style={{fontSize: "17px"}}/>}
              size="lg"
              theme="blue"
              className="radius-10"
              onClick={() => this.toggleReportModal(true, true, payload)}
            />
          </div>
        ),
      },
    ];

    this.state = {
      selectedUserID: null,
      popupVisible: false,
      isEditMode: false,
      year: '',
      quarter: '',
      dividend: '',
      revenue: '',
      gross_profit: '',
      net_profit: '',
      ebitda: '',
      hoverData: null,
      performanceActiveQuarter: 'total',
      performanceActivePeriod: currentYear,
      annualPerformancePeriodFrom: currentYear,
      waterfallChartOptions: {
        type: 'column',
        animation: true,
        credits: {
          enabled: false
        },
        xAxis: {
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            },
          },
          tickmarkPlacement: 'between',
        },
        yAxis: {
          type: 'logarithmic',
          allowDecimals: true,
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            }
          }
        },
        series: [],
        legend: {
          align: 'left',
          verticalAlign: 'bottom',
          labelFormat: '<span class="text text-14 font-medium font-uppercase font-spacing-04" style="color:{color}">{name}</span>'
        },
        plotOptions: {
          point: {
            events: {
              mouseOver: this.setHoverData.bind(this)
            }
          },
          series: {
            point: {
              events: {
                mouseOver: this.setHoverData.bind(this)
              }
            },
            marker: {
              symbol: '<span class="text text-14 font-medium font-spacing-04">O</span>'
            }
          },
          column: {
            stacking: 'normal',
          }
        },
        tooltip: {
          backgroundColor: '#3d3d3d',
          borderRadius: 8,
          borderWidth: 0,
          padding: 22,
          shadow: false,
          formatter: function () {
            return '<div class="chart_tooltip text">' +
              '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
              '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + formatNumber(this.y) + '</span></div>' +
              '</div>';
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 767
            },
            chartOptions: {
              xAxis: {
                labels: {
                  rotation: -45,
                  style: {
                    fontSize: '12px'
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontSize: '12px',
                  }
                }
              },
              tooltip: {
                padding: 12
              },
            }
          }]
        }
      },
      performanceChartOptions: {
        type: 'column',
        animation: true,
        xAxis: {
          categories: setPerformanceCategories(currentYear),
          labels: {
            autoRotation: false,
            overflow: 'allow',
            style: {
              fontSize: '12px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            },
          },
          tickmarkPlacement: 'between',
        },
        yAxis: {
          type: 'logarithmic',
          allowDecimals: true,
          labels: {
            style: {
              fontSize: '12px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            }
          }
        },
        series: [],
        legend: {
          align: 'left',
          verticalAlign: 'bottom',
          labelFormat: '<span class="text text-14 font-medium font-uppercase font-spacing-04" style="color:{color}">{name}</span>'
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: this.setHoverData.bind(this)
              }
            },
            marker: {
              symbol: '<span class="text text-14 font-medium font-spacing-04">O</span>'
            }
          }
        },
        tooltip: {
          backgroundColor: '#3d3d3d',
          borderRadius: 8,
          borderWidth: 0,
          padding: 22,
          shadow: false,
          formatter: function () {
            return '<div class="chart_tooltip text">' +
              '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
              '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + formatNumber(this.y) + '</span></div>' +
              '</div>';
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 767
            },
            chartOptions: {
              xAxis: {
                labels: {
                  rotation: -45,
                  style: {
                    fontSize: '12px'
                  },
                },
              },
              yAxis: {
                labels: {
                  //rotation: -45,
                  style: {
                    fontSize: '12px',
                  }
                }
              },
              tooltip: {
                padding: 12
              },
            }
          }]
        }
      },
      annualPerformanceBarChartOptions: {
        type: 'column',
        animation: true,
        xAxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            },
          },
          tickmarkPlacement: 'between',
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            }
          }
        },
        series: [/*
          {
            name: "revenue", color: "#F5C65B", type: 'column',
            data: [70000, 40000, 30000, 50000]
          },
          {
            name: "gross profit", color: "#FF551F", type: 'column',
            data: [20000, 60000, 50000, 10000]
          },
          {
            name: "net profit", color: "#36C1A3", type: 'column',
            data: [80000, 45000, 36000, 25000]
          },
          {
            name: "ebitda", color: "#3665C1", type: 'column',
            data: [80000, 45000, 36000, 25000]
          },
        */],
        legend: {
          align: 'left',
          verticalAlign: 'bottom',
          labelFormat: '<span class="text text-14 font-medium font-spacing-04" style="color:{color}">{name}</span>'
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: this.setHoverData.bind(this)
              }
            },
            marker: {
              symbol: '<span class="text text-14 font-medium font-spacing-04">O</span>'
            }
          }
        },
        tooltip: {
          backgroundColor: '#3d3d3d',
          borderRadius: 8,
          borderWidth: 0,
          padding: 22,
          shadow: false,
          formatter: function () {
            return '<div class="chart_tooltip text">' +
              '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
              '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + formatNumber(Math.round(this.y)) + '</span></div>' +
              '</div>';
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 767
            },
            chartOptions: {
              xAxis: {
                labels: {
                  //rotation: -45,
                  style: {
                    fontSize: '12px'
                  },
                },
              },
              yAxis: {
                labels: {
                  //rotation: -45,
                  style: {
                    fontSize: '12px',
                  }
                }
              },
              tooltip: {
                padding: 12
              },
            }
          }]
        }
      },
      barOptions: {
        type: 'column',
        animation: true,
        xAxis: {
          categories: ['Q1', 'Q2', 'Q3', 'Q4'],
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            },
          },
          tickmarkPlacement: 'between',
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            }
          }
        },
        series: [],
        legend: {
          align: 'left',
          verticalAlign: 'bottom',
          labelFormat: '<span class="text text-14 font-medium font-spacing-04" style="color:{color}">{name}</span>'
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: this.setHoverData.bind(this)
              }
            },
            marker: {
              symbol: '<span class="text text-14 font-medium font-spacing-04">O</span>'
            }
          }
        },
        tooltip: {
          backgroundColor: '#3d3d3d',
          borderRadius: 8,
          borderWidth: 0,
          padding: 22,
          shadow: false,
          formatter: function () {
            return '<div class="chart_tooltip text">' +
              '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
              '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + formatNumber(Math.round(this.y)) + '</span></div>' +
              '</div>';
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 767
            },
            chartOptions: {
              xAxis: {
                labels: {
                  style: {
                    fontSize: '12px'
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontSize: '12px',
                  }
                }
              },
              tooltip: {
                padding: 12
              },
            }
          }]
        }
      },
    }
  }

  setHoverData = (e) => {
    // The chart is not updated because `chartOptions` has not changed.
    this.setState({ hoverData: e.target.category });
  };

  handleUserNameOnChange = (inputValue) => {
    const { searchStatisticsUsers, match } = this.props;
    const { business } = match.params;
    const inputValueArray = inputValue.split(/(\s+)/); // 0 index is name, 2 index is surname
    const params = {
      first_name: inputValueArray[0] ? inputValueArray[0] : "",
      last_name: inputValueArray[2] ? inputValueArray[2] : "",
      final: "",
    };

    if (!inputValue) {
      this.setState({
        selectedUserID: null,
      });
    }

    searchStatisticsUsers(business, params);
  };

  handleUserNameOnSelect = (selected) => {
    const { statisticsUsers, getReport, form, match } = this.props;
    const { currency } = match.params;

    this.setState({
      selectedUserID: selected,
    }, () => {
      statisticsUsers.map((element) => {
        if (parseInt(element.id) === parseInt(selected)) {
          getReport(element.id, currency);
          form.setFieldsValue({
            investor: `${element.first_name} ${element.middle_name ? element.middle_name : ""} ${element.last_name} (${element.email})`,
          });
        }

        return "";
      });
    });
  };

  toggleReportModal = (status, isEditMode, inputData) => {
    this.setState({
      popupVisible: status,
      isEditMode,
      year: inputData ? inputData.year : '',
      quarter: inputData ? inputData.quarter : '',
      dividend: inputData ? inputData.dividend : '',
      revenue: inputData ? inputData.revenue : '',
      gross_profit: inputData ? inputData.gross_profit : '',
      net_profit: inputData ? inputData.net_profit : '',
      ebitda: inputData ? inputData.ebitda : '',
    }, () => {
      if (status) {
        window.scroll(0, 0);
      }
    });
  };

  getReportTableSource = () => {
    const { newReports } = this.props;
    let returningReportsData = [];

    if (newReports && newReports.reports) {
      newReports.reports.map((childElement) => {
        const tableData = {
          key: newReports.id,
          name: newReports.name,
          year: newReports.reports ? childElement.year : '',
          quarter: childElement.quarter ? childElement.quarter : '',
          revenue: childElement.revenue,
          gross_profit: childElement.gross_profit,
          net_profit: childElement.net_profit,
          ebitda: childElement.ebitda,
        };
        return returningReportsData.push({
          ...tableData,
          action: {
            ...tableData,
          },
        });
        
      });
    }

    return returningReportsData;
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { form, match } = this.props;
    const { validateFields } = form;
    const { currency } = match.params;

    validateFields((err, values) => {
      if (!err) {
        const { addReport, match } = this.props;
        values.business = match.params.business;
        values.revenue = parseInt(values.revenue);
        values.gross_profit = parseInt(values.gross_profit);
        values.net_profit = parseInt(values.net_profit);
        values.ebitda = parseInt(values.ebitda);

        addReport(values, currency);
      }
    });
  };

  getPerformanceChartData = () => {
    const { newReports, match } = this.props;
    const { currency } = match.params;
    const { performanceActivePeriod, performanceActiveQuarter } = this.state;
    const loopingData = ['revenue', 'gross_profit', 'net_profit', 'ebitda'];
    let series = [
      {
        name: "Revenue", color: "#F5C65B", type: 'spline',
        data: []
      },
      {
        name: "Gross profit", color: "#FF551F", type: 'spline',
        data: []
      },
      {
        name: "Net profit", color: "#36C1A3", type: 'spline',
        data: []
      },
      {
        name: "EBidta", color: "#3665C1", type: 'spline',
        data: []
      },
    ];
    let tmpBarOptionSeries = [
      {
        name: "revenue", color: "#F5C65B", type: 'column',
        data: [null, null, null, null],
      },
      {
        name: "gross profit", color: "#FF551F", type: 'column',
        data: [null, null, null, null],
      },
      {
        name: "net profit", color: "#36C1A3", type: 'column',
        data: [null, null, null, null],
      },
      {
        name: "ebitda", color: "#3665C1", type: 'column',
        data: [null, null, null, null],
      },
    ];
    let revenueReportsSumQ1 = 0;
    let revenueReportsSumQ2 = 0;
    let revenueReportsSumQ3 = 0;
    let revenueReportsSumQ4 = 0;
    let grossProfitReportsSumQ1 = 0;
    let grossProfitReportsSumQ2 = 0;
    let grossProfitReportsSumQ3 = 0;
    let grossProfitReportsSumQ4 = 0;
    let netProfitReportsSumQ1 = 0;
    let netProfitReportsSumQ2 = 0;
    let netProfitReportsSumQ3 = 0;
    let netProfitReportsSumQ4 = 0;
    let ebitdaReportsSumQ1 = 0;
    let ebitdaReportsSumQ2 = 0;
    let ebitdaReportsSumQ3 = 0;
    let ebitdaReportsSumQ4 = 0;

    newReports && newReports.reports && newReports.reports.map((element) => {
      if (performanceActiveQuarter !== 'total') {
        if (parseInt(element.year) === parseInt(performanceActivePeriod) && parseInt(element.quarter) === parseInt(performanceActiveQuarter)) {
          Object.keys(element).map(keyItem => {
            return loopingData.map(loopingDataItem => {
              if (keyItem === loopingDataItem) {
                switch (keyItem) {
                  case 'revenue':
                    series[0].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  case 'gross_profit':
                    series[1].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  case 'net_profit':
                    series[2].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  case 'ebitda':
                    series[3].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  default:
                }
              }

              return "";
            });
          });
          switch (parseInt(performanceActiveQuarter)) {
            case 1:
              revenueReportsSumQ1 += element.revenue;
              grossProfitReportsSumQ1 += element.gross_profit;
              netProfitReportsSumQ1 += element.net_profit;
              ebitdaReportsSumQ1 += element.ebitda;
              break;
            case 2:
              revenueReportsSumQ2 += element.revenue;
              grossProfitReportsSumQ2 += element.gross_profit;
              netProfitReportsSumQ2 += element.net_profit;
              ebitdaReportsSumQ2 += element.ebitda;
              break;
            case 3:
              revenueReportsSumQ3 += element.revenue;
              grossProfitReportsSumQ3 += element.gross_profit;
              netProfitReportsSumQ3 += element.net_profit;
              ebitdaReportsSumQ3 += element.ebitda;
              break;
            case 4:
              revenueReportsSumQ4 += element.revenue;
              grossProfitReportsSumQ4 += element.gross_profit;
              netProfitReportsSumQ4 += element.net_profit;
              ebitdaReportsSumQ4 += element.ebitda;
              break;
            default:
          }
        }
      } else {
        if (parseInt(element.year) === parseInt(performanceActivePeriod)) {
          Object.keys(element).map(keyItem => {
            return loopingData.map(loopingDataItem => {
              if (keyItem === loopingDataItem) {
                switch (keyItem) {
                  case 'revenue':
                    series[0].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  case 'gross_profit':
                    series[1].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  case 'net_profit':
                    series[2].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  case 'ebitda':
                    series[3].data.push({
                      x: element.quarter - 1,
                      y: element[keyItem],
                    });
                    break;
                  default:
                }
              }

              return "";
            });
          });
          switch (parseInt(element.quarter)) {
            case 1:
              revenueReportsSumQ1 += element.revenue;
              grossProfitReportsSumQ1 += element.gross_profit;
              netProfitReportsSumQ1 += element.net_profit;
              ebitdaReportsSumQ1 += element.ebitda;
              break;
            case 2:
              revenueReportsSumQ2 += element.revenue;
              grossProfitReportsSumQ2 += element.gross_profit;
              netProfitReportsSumQ2 += element.net_profit;
              ebitdaReportsSumQ2 += element.ebitda;
              break;
            case 3:
              revenueReportsSumQ3 += element.revenue;
              grossProfitReportsSumQ3 += element.gross_profit;
              netProfitReportsSumQ3 += element.net_profit;
              ebitdaReportsSumQ3 += element.ebitda;
              break;
            case 4:
              revenueReportsSumQ4 += element.revenue;
              grossProfitReportsSumQ4 += element.gross_profit;
              netProfitReportsSumQ4 += element.net_profit;
              ebitdaReportsSumQ4 += element.ebitda;
              break;
            default:
          }
        }
      }

      return "";
    });

    tmpBarOptionSeries[0].data[0] = revenueReportsSumQ1;
    tmpBarOptionSeries[0].data[1] = revenueReportsSumQ2;
    tmpBarOptionSeries[0].data[2] = revenueReportsSumQ3;
    tmpBarOptionSeries[0].data[3] = revenueReportsSumQ4;
    tmpBarOptionSeries[1].data[0] = grossProfitReportsSumQ1;
    tmpBarOptionSeries[1].data[1] = grossProfitReportsSumQ2;
    tmpBarOptionSeries[1].data[2] = grossProfitReportsSumQ3;
    tmpBarOptionSeries[1].data[3] = grossProfitReportsSumQ4;
    tmpBarOptionSeries[2].data[0] = netProfitReportsSumQ1;
    tmpBarOptionSeries[2].data[1] = netProfitReportsSumQ2;
    tmpBarOptionSeries[2].data[2] = netProfitReportsSumQ3;
    tmpBarOptionSeries[2].data[3] = netProfitReportsSumQ4;
    tmpBarOptionSeries[3].data[0] = ebitdaReportsSumQ1;
    tmpBarOptionSeries[3].data[1] = ebitdaReportsSumQ2;
    tmpBarOptionSeries[3].data[2] = ebitdaReportsSumQ3;
    tmpBarOptionSeries[3].data[3] = ebitdaReportsSumQ4;

    this.setState(prevState => {
      let performanceChartOptions = Object.assign({}, prevState.performanceChartOptions);
      let barOptions = Object.assign({}, prevState.barOptions);
      barOptions.series = tmpBarOptionSeries;
      performanceChartOptions.series = series;
      performanceChartOptions.xAxis.categories = setPerformanceCategories(performanceActivePeriod);
      barOptions.tooltip.formatter = function () {
        return '<div class="chart_tooltip text">' +
          '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
          '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + currency + ' ' + formatNumber(Math.round(this.y)) + '</span></div>' +
          '</div>';
      };
      return { performanceChartOptions, barOptions }
    });
  };

  handlePerformanceActivePeriodOnChange = (value) => {
    this.setState({
      performanceActivePeriod: value,
    }, () => {
      this.getPerformanceChartData();
    });
  };

  handleAnnualPerformanceActivePeriodOnChange = (value) => {
    this.setState({
      annualPerformancePeriodFrom: value,
    }, () => {
      this.getAnnualPerformance();
    });
  };

  toggleQuarter = (chartType, quarter) => {
    this.setState({
      [chartType]: quarter,
    }, () => {
      if (chartType === 'performanceActiveQuarter') {
        this.getPerformanceChartData();
      }
    });
  };

  getQuarterBtnColor = (stateName, quarter) => {
    return this.state[stateName] === quarter ? 'blue' : 'grey';
  };

  getAnnualPerformance = () => {
    const { newReports, match } = this.props;
    const { currency } = match.params;
    let tmpBarOptionSeries = [
      {
        name: "revenue", color: "#F5C65B", type: 'column',
        data: new Array(this.getValidYearsOfAnnualPerformance().length).fill(null),
      },
      {
        name: "gross profit", color: "#FF551F", type: 'column',
        data: new Array(this.getValidYearsOfAnnualPerformance().length).fill(null),
      },
      {
        name: "net profit", color: "#36C1A3", type: 'column',
        data: new Array(this.getValidYearsOfAnnualPerformance().length).fill(null),
      },
      {
        name: "ebitda", color: "#3665C1", type: 'column',
        data: new Array(this.getValidYearsOfAnnualPerformance().length).fill(null),
      },
    ];

    this.getValidYearsOfAnnualPerformance().map((validYearItem, index) => {
      let currentYearRevenue = 0;
      let currentYearGrossProfit = 0;
      let currentYearNetProfit = 0;
      let currentYearEbitda = 0;
      newReports && newReports.reports && newReports.reports.map((element) => {
        if (parseInt(validYearItem) === element.year) {
          currentYearRevenue += element.revenue;
          currentYearGrossProfit += element.gross_profit;
          currentYearNetProfit += element.net_profit;
          currentYearEbitda += element.ebitda;
        }

        return "";
      });
      tmpBarOptionSeries[0].data[index] = currentYearRevenue;
      tmpBarOptionSeries[1].data[index] = currentYearGrossProfit;
      tmpBarOptionSeries[2].data[index] = currentYearNetProfit;
      tmpBarOptionSeries[3].data[index] = currentYearEbitda;

      return "";
    });

    this.setState(prevState => {
      let annualPerformanceBarChartOptions = Object.assign({}, prevState.annualPerformanceBarChartOptions);
      annualPerformanceBarChartOptions.series = tmpBarOptionSeries;
      annualPerformanceBarChartOptions.xAxis.categories = this.getValidYearsOfAnnualPerformance();
      annualPerformanceBarChartOptions.tooltip.formatter = function () {
        return '<div class="chart_tooltip text">' +
          '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
          '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + currency + ' ' + formatNumber(Math.round(this.y)) + '</span></div>' +
          '</div>';
      };
      return { annualPerformanceBarChartOptions }
    });
  };

  getValidYearsOfAnnualPerformance = () => {
    const { newReports } = this.props;
    let allYears = [];

    newReports && newReports.reports && newReports.reports.map((element) => {
      return allYears.push(element.year);
    });

    const seen = new Set();
    // removing repeating elements from array, saving only 1 item per repeating
    const filteredArr = allYears.filter(el => {
      const duplicate = seen.has(el);
      seen.add(el);
      return !duplicate;
    });
    return filteredArr;
  };

  handleQuarterOptionsDisabled = (quarter) => {
    const { newReports } = this.props;
    const { reports } = newReports;
    const inputtedYear = this.props.form.getFieldValue("year");

    return isQuarterOptionDisabled(reports, inputtedYear, quarter);
  };

  componentDidUpdate(prevProps, prevState) {
    const { loading, errorMessage } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;

    if (!errorText && !loadingState && prevProps.loading.state && loadingType === "AddReport") {
      message.success('Report has been added successfully');
      this.toggleReportModal(false);
    }

    if ((loadingType === 'GetNewReport' || loadingType === 'AddReport') && !loadingState && prevProps.loading.state && !errorText) {
      this.getPerformanceChartData();
      this.getAnnualPerformance();
    }
  }

  componentWillUnmount() {
    const { removeReports } = this.props;
    removeReports();
  }

  componentDidMount() {
    const { match, getNewReport } = this.props;
    const { business, currency } = match.params;
    getNewReport(business, currency);
  }

  render() {
    const { form, statisticsUsers, loading, match } = this.props;
    const { business, currency } = match.params;
    const { getFieldDecorator } = form;
    const {
      popupVisible, selectedUserID, isEditMode,
      year, quarter, revenue,
      gross_profit, net_profit, ebitda,
      performanceActivePeriod, annualPerformanceBarChartOptions,
      barOptions,
    } = this.state;
    const reportsTableSource = this.getReportTableSource();
    const { businessName } = match.params;
    this.getValidYearsOfAnnualPerformance();

    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="flex-container align-middle">
                <Button
                  title={<Icon type="key-left" size={16}/>}
                  size="md"
                  theme="lightblue"
                  className="radius-10 back_btn"
                  onClick={() => goBack()}
                />
                <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                  <h1>Statistics - {businessName}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb_45">
          <div className="column small-12 medium-9 large-7">
            <div className="bg-white shadow-layout radius-10 form-item-card">
              <div className="form-item mb_0">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  SELECT INVESTOR
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator('investor', {
                    initialValue: "",
                    rules: [{required: false, message: 'Please fill out this field'}],
                  })(
                    <AutoComplete
                      style={{width: '100%'}}
                      onChange={this.handleUserNameOnChange}
                      onSelect={this.handleUserNameOnSelect}
                    >
                      {
                        statisticsUsers && statisticsUsers.map((element) => (
                          <Option key={element.id}>
                            <span className="radius-circle clip option_ava">
                              <img
                                style={{width: "24px", height: "24px"}}
                                src={element.avatar ? getMediaFileFromServer(element.avatar) : require("../../../assets/img/no_profile.png")}
                                alt="avatar"
                              />
                            </span>
                            <span className="font-semibold color-grey">
                              {element.first_name} {element.middle_name ? element.middle_name : ""} {element.last_name} ({element.email})
                            </span>
                          </Option>
                        ))
                      }
                    </AutoComplete>
                  )}
                </Form.Item>
              </div>
            </div>
          </div>
        </div>

        {
          selectedUserID ?
            <Spin spinning={handleLoading(loading, "GetReport")}>
              <PortfolioInner investor={selectedUserID} currency={currency} businessID={business} />
            </Spin>
          :
            <div className="row portfolio_section">
              <div className="column small-12 large-6 mb_25 fluid_sm">
                <div className="bg-white radius-10 shadow-layout portfolio_card fluid-y">
                  <div className="text text-22 font-heavy color-grey portfolio_card__title">
                    Financial Performance
                  </div>

                  <Form className="relative">
                    <div className="chart_filter medium-flex-container align-top align-justify">
                      <div className="chart_filter_section medium-flex-container align-justify">
                        <div className="medium-flex-container align-middle">
                          <div className="text text-14 color-grey-900 font-medium font-uppercase font-spacing-04 linear_label">
                            period
                          </div>
                          <div className="form-item form-item-sm mb_0 filter_form_item">
                            <Form.Item className="mb_0">
                              {getFieldDecorator('performanceActivePeriod', {
                                initialValue: performanceActivePeriod,
                                rules: [{required: false, message: 'Please fill out this field'}],
                              })(
                                <Select
                                  className="fluid-x min_width"
                                  onChange={this.handlePerformanceActivePeriodOnChange}
                                >
                                  {
                                    yearsList.map(element => (
                                      <Option value={element}>{element}</Option>
                                    ))
                                  }
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>

                  <div className="chart_wrapper">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={barOptions}
                    />
                  </div>
                </div>
              </div>

              <div className="column small-12 large-6 mb_25 fluid_sm">
                <div className="bg-white radius-10 shadow-layout portfolio_card fluid-y">
                  <div className="text text-22 font-heavy color-grey portfolio_card__title">
                    Annual Performance
                  </div>

                  <div className="chart_wrapper">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={annualPerformanceBarChartOptions}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="column small-12 medium-9 large-6">
                  <div className="text text-22 font-heavy color-grey mb_25">
                    Annual Performance
                  </div>
                  <Table
                    dataSource={reportsTableSource}
                    columns={this.reportsTableColumns}
                    className="c-table c-table-contacts c-table-sm c-table-expand mb_35"
                    pagination={{ hideOnSinglePage: true }}
                  />
                  <Button
                    title="Add New Year"
                    prefix={<Icon type="plus" size={12} style={{marginRight: 13}}/>}
                    size="lg"
                    theme="lightblue"
                    className="btn-bordered radius-8"
                    type="button"
                    onClick={() => this.toggleReportModal(true)}
                  />
                </div>
              </div>
            </div>
        }

        {
          popupVisible ?
            <PopUp
              visible
              className="relative_parent"
              innerClassNames="shadow-layout bg-white radius-10 relative x-lg base"
            >
              <Form onSubmit={this.onSubmit} >
                <div className="popup_close color-grey-700" onClick={() => this.toggleReportModal(false)}>
                  <Icon type="cancel" size={24}/>
                </div>
                <div>
                  <div className="flex-container align-center text-center">
                    <div className="text text-28 text-height-33 font-heavy font-spacing-02 color-grey font-uppercase large-7 mb_35">
                      { !isEditMode ? "Add new Year" : "Edit the Year" } ({currency})
                    </div>
                  </div>
                  <div>
                    <div className="form-item mb_50">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        YEAR
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator('year', {
                          initialValue: year,
                          rules: [{required: true, message: 'Please fill out this field'}],
                        })(
                          <Input disabled={isEditMode} className="short_input"/>
                        )}
                      </Form.Item>
                    </div>

                    <div className="mb_30">
                      <div className="text text-22 font-heavy color-grey mb_35">
                        Waterfall table
                      </div>
                      <div className="flex-container flex-wrap data_table data_table-5">
                        <div className="data_table_col">
                          <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_10 nowrap">
                            Quarter count
                          </div>
                          <div className="form-item">
                            <Form.Item className="mb_0">
                              {getFieldDecorator('quarter', {
                                initialValue: quarter,
                                rules: [{required: true, message: 'Please fill out this field'}],
                              })(
                                <Select disabled={isEditMode} >
                                  {
                                    new Array(4).fill(null).map((element, index) => (
                                      <Select.Option
                                        value={index + 1}
                                        disabled={this.handleQuarterOptionsDisabled(index + 1)}
                                      >
                                        { index + 1 }
                                      </Select.Option>
                                    ))
                                  }
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="data_table_col">
                          <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_10">
                            revenue
                          </div>
                          <div className="form-item">
                            <Form.Item className="mb_0">
                              {getFieldDecorator('revenue', {
                                initialValue: revenue,
                                rules: [{required: true, message: 'Please fill out this field'}],
                              })(
                                <NumericInput className="fluid-x" />
                              )}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="data_table_col">
                          <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_10">
                            Gross profit
                          </div>
                          <div className="form-item">
                            <Form.Item className="mb_0">
                              {getFieldDecorator('gross_profit', {
                                initialValue: gross_profit,
                                rules: [{required: true, message: 'Please fill out this field'}],
                              })(
                                <NumericInput className="fluid-x" />
                              )}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="data_table_col">
                          <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_10">
                            NEt profit
                          </div>
                          <div className="form-item">
                            <Form.Item className="mb_0">
                              {getFieldDecorator('net_profit', {
                                initialValue: net_profit,
                                rules: [{required: true, message: 'Please fill out this field'}],
                              })(
                                <NumericInput className="fluid-x" />
                              )}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="data_table_col">
                          <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_10">
                            Ebidta
                          </div>
                          <div className="form-item">
                            <Form.Item className="mb_0">
                              {getFieldDecorator('ebitda', {
                                initialValue: ebitda,
                                rules: [{required: true, message: 'Please fill out this field'}],
                              })(
                                <NumericInput className="fluid-x" />
                              )}
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn_group medium-flex-container align-middle align-right">
                    <Form.Item className="mb_0">
                      <Button
                        title="Cancel"
                        size="lg"
                        theme="default"
                        className="btn-link radius-8 font-uppercase"
                        type="button"
                        onClick={() => this.toggleReportModal(false)}
                      />
                    </Form.Item>
                    <Form.Item className="mb_0">
                      <Button
                        title="Save"
                        type="submit"
                        size="xl"
                        theme="blue"
                        prefix={<Icon type="checked" size={18} style={{marginRight: 11}}/>}
                        className="radius-8"
                        style={{fontSize: 18}}
                        loading={handleLoading(loading, "AddReport")}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </PopUp>
          : null
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  statisticsUsers: state.users.statisticsUsers,
  portfolioReports: state.reports.reports,
  newReports: state.reports.newReports,
  loading: state.reports.loading,
  errorMessage: state.reports.errorMessage,
});

const mapDispatchToProps = dispatch => ({
  searchStatisticsUsers: (businessID, params) => dispatch(searchStatisticsUsers(businessID, params)),
  getReport: (investor, currency) => dispatch(getReport(investor, currency)),
  getNewReport: (business, currency) => dispatch(getNewReport(business, currency)),
  addReport: (params, currency) => dispatch(addReport(params, currency)),
  removeReports: () => dispatch(removeReports()),
});

const AdminStatisticsScreen = Form.create({name: 'adminStatistics'})(AdminStatistics);

export default connect(mapStateToProps, mapDispatchToProps)(AdminStatisticsScreen);