import React, { Component } from "react";
import { connect } from "react-redux";
import { AutoComplete, Form, Select } from "antd";
import { searchUsersFilter } from "../../redux/users/thunks/searchUsersThunk";
import { getBusinessesByName } from "../../redux/business/thunks/businessThunk";
import { getMediaFileFromServer } from "../../utils/getMediaFileFromServer";

import FilterBaseComponent from "../../layouts/Filter/Filter";
import Button from "../../components/Button/Button";
import { getMessages } from "../../redux/messages/thunks/messagesThunks";

const { Option } = AutoComplete;

class FilterComponent extends Component {
  state = {
    selectedUserID: null
  };

  handleResetAllFilters = () => {
    const { form, getMessages, filter } = this.props;
    const { resetFields } = form;
    let params = {};
    if (filter.name) {
      params.name = filter.name;
    }

    resetFields();
    getMessages('inbox', 1, params);
    getMessages('sent', 1, params);
  };

  handleUserNameOnChange = (inputValue) => {
    const { searchUsersFilter } = this.props;
    const inputValueArray = inputValue.split(/(\s+)/); // 0 index is name, 2 index is surname
    const params = {
      first_name: inputValueArray[0] ? inputValueArray[0] : "",
      last_name: inputValueArray[2] ? inputValueArray[2] : ""
    };

    if (!inputValue) {
      this.setState({
        selectedUserID: null
      })
    } else {
      searchUsersFilter(params);
    }
  };

  handleBusinessOnChange = (inputValue) => {
    const { getBusinessesByName } = this.props;
    getBusinessesByName(inputValue);
  };

  handleSelectUserName = (selected) => {
    const { usersSearchList, form } = this.props;

    this.setState({
      selectedUserID: selected
    }, () => {
      usersSearchList.map((element) => {
        if (parseInt(element.id) === parseInt(selected)) {
          form.setFieldsValue({
            user_id: `${element.first_name} ${element.middle_name ? element.middle_name : ""} ${element.last_name}`,
          });
        }

        return "";
      })
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { form } = this.props;
    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        const { getMessages, filter } = this.props;
        const { selectedUserID } = this.state;

        values.user_id = selectedUserID;
        let params = {};
        if (selectedUserID || values.business_id || values.priority) {
          params = {
            user_id: selectedUserID ? selectedUserID : null,
            business_id: values.business_id ? values.business_id : null,
            priority: values.priority ? values.priority : null,
          };
        }
        if (filter.name) {
          params.name = filter.name;
        }
        !params.user_id && delete params.user_id;
        !params.business_id && delete params.business_id;
        !params.priority && delete params.priority;

        getMessages('inbox', 1, params);
        getMessages('sent', 1, params);
      }
    });
  };

  render() {
    const {
      form,
      role,
      usersSearchList,
      businessesSearchList,
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <FilterBaseComponent >
        <Form onSubmit={this.onSubmit} className="row expanded" >
          {
            role === 'ADMIN'
              ? (
                <div className="column small-12 large-4 x-large-3 form-item form-item-white">
                  <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                    USER NAME
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("user_id", {
                      initialValue: "",
                      rules: [{required: false, message: "Please fill out this field"}],
                    })(
                      <AutoComplete
                        placeholder='Start typing the name of a user'
                        onChange={this.handleUserNameOnChange}
                        onSelect={this.handleSelectUserName}
                      >
                        {
                          usersSearchList && usersSearchList.map(element => (
                            <Option key={element.id} value={element.id.toString()} >
                              <span className="radius-circle clip option_ava">
                                <img
                                  alt="avatar"
                                  style={{ height: "24px", width: "24px" }}
                                  src={element.avatar ? getMediaFileFromServer(element.avatar) : require("../../assets/img/no_profile.png")}
                                />
                              </span>
                              <span className="font-semibold color-grey">
                                {element.first_name} {element.middle_name ? element.middle_name : ""} {element.last_name}
                              </span>
                            </Option>
                          ))
                        }
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
              ) : null
          }

          <div className="column small-12 large-4 x-large-3 form-item form-item-white">
            <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
              BUSINESS NAME
            </div>
            <Form.Item className="mb_0">
              {getFieldDecorator("business_id", {
                initialValue: "",
                rules: [{required: false, message: "Please fill out this field"}],
              })(
                <AutoComplete
                  placeholder="Start typing the name of a business"
                  onChange={this.handleBusinessOnChange}
                >
                  {
                    businessesSearchList && businessesSearchList.map(element => (
                      <Option key={element.id} value={element.id.toString()} >
                        {element.name}
                      </Option>
                    ))
                  }
                </AutoComplete>
              )}
            </Form.Item>
          </div>

          <div className="column small-12 large-4 x-large-3 form-item form-item-white">
            <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
              PRIORITY
            </div>
            <Form.Item className="mb_0">
              {getFieldDecorator("priority", {
                initialValue: "",
                rules: [{required: false, message: "Please fill out this field"}],
              })(
                <Select>
                  <Select.Option value="">None</Select.Option>
                  <Select.Option value="HIGH">HIGH</Select.Option>
                  <Select.Option value="MEDIUM">MEDIUM</Select.Option>
                  <Select.Option value="LOW">LOW</Select.Option>
                </Select>
              )}
            </Form.Item>
          </div>

          <div className="column small-12">
            <div className="flex-container align-middle filter_submit">
              <Form.Item className="mb_0">
                <Button
                  title="Apply filter"
                  size="lg"
                  theme="blue"
                  className="btn-shadow radius-8"
                  type="submit"
                />
              </Form.Item>
              <Form.Item className="mb_0">
                <Button
                  title="Reset all filters"
                  size="lg"
                  theme="default"
                  className="btn-link font-uppercase"
                  type="button"
                  onClick={this.handleResetAllFilters}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </FilterBaseComponent>
    );
  }
}

const mapStateToProps = state => ({
  role: state.user.role,
  usersSearchList: state.users.usersSearchList,
  businessesSearchList: state.business.businessesSearchList,
  filter: state.messages.filter,
});

const mapDispatchToProps = dispatch => ({
  searchUsersFilter: (params) => dispatch(searchUsersFilter(params)),
  getBusinessesByName: (businessName) => dispatch(getBusinessesByName(businessName)),
  getMessages: (type, currentPage, params) => dispatch(getMessages(type, currentPage, params)),
});

const FilterComponentScreen = Form.create()(FilterComponent);

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponentScreen);