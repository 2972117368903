export const findValueFromDictionary = (id, options) => {
  if (Array.isArray(options) && options.length) {
    const foundElement = options.find(element => parseInt(element.id) === parseInt(id));
    return foundElement ? foundElement.name : "";
  }

  return "";
};

export const findCountryFromDictionary = (id, options) => {
  if (Array.isArray(options) && options.length) {
    const foundElement = options.find(element => +element.id === +id);
    return foundElement || {};
  }

  return {};
};
