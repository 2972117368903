import produce from 'immer';
import moment from "moment";
import {
  CREATE_OFFER_START, CREATE_OFFER_SUCCESS, CREATE_OFFER_FAIL,
  REQUEST_EXTERNAL_SALE_START, REQUEST_EXTERNAL_SALE_SUCCESS, REQUEST_EXTERNAL_SALE_FAIL,
  EXTERNAL_PUBLISH_START, EXTERNAL_PUBLISH_SUCCESS, EXTERNAL_PUBLISH_FAIL,
  INTERNAL_PUBLISH_START, INTERNAL_PUBLISH_SUCCESS, INTERNAL_PUBLISH_FAIL,
  SEARCH_OFFERS_START, SEARCH_OFFERS_SUCCESS, SEARCH_OFFERS_FAIL,
  GET_OFFER_START, GET_OFFER_SUCCESS, GET_OFFER_FAIL,
  GET_BID_START, GET_BID_SUCCESS, GET_BID_FAIL,
  SEARCH_OFFER_BIDS_START, SEARCH_OFFER_BIDS_SUCCESS, SEARCH_OFFER_BIDS_FAIL,
  SEARCH_BIDS_START, SEARCH_BIDS_FAIL, SEARCH_BIDS_SUCCESS,
  REMOVE_OFFER_ADMIN_START, REMOVE_OFFER_ADMIN_SUCCESS, REMOVE_OFFER_ADMIN_FAIL,
  SET_WON_START, SET_WON_SUCCESS, SET_WON_FAIL,
  REMOVE_OFFER_INVESTOR_FAIL, REMOVE_OFFER_INVESTOR_START, REMOVE_OFFER_INVESTOR_SUCCESS, CLEAR_OFFERS, SET_OFFER_FILTERS, CLEAR_OFFER_FILTERS, CLEAR_OFFER,
  SET_ACTIVE_OFFERS_TAB_KEY,
  ADD_INTERNAL_BID_START, ADD_INTERNAL_BID_SUCCESS, ADD_INTERNAL_BID_FAIL,
  ADD_EXTERNAL_BID_START, ADD_EXTERNAL_BID_SUCCESS, ADD_EXTERNAL_BID_FAIL, CLOSE_OFFER_START, CLOSE_OFFER_SUCCESS, CLOSE_OFFER_FAIL, MOVE_BID_START, MOVE_BID_SUCCESS, MOVE_BID_FAIL,
  ADD_EXTERNAL_OVERBID,
  SEARCH_OFFER_BID_REQUESTS_START,
  SEARCH_OFFER_BID_REQUESTS_SUCCESS,
  SEARCH_OFFER_BID_REQUESTS_FAIL,
  ACCEPT_BID_REQUEST_START,
  ACCEPT_BID_REQUEST_SUCCESS,
  ACCEPT_BID_REQUEST_FAIL,
  REJECT_BID_REQUEST_START,
  REJECT_BID_REQUEST_SUCCESS,
  REJECT_BID_REQUEST_FAIL,
  GET_OFFER_BID_REQUEST_START,
  GET_OFFER_BID_REQUEST_SUCCESS,
  GET_OFFER_BID_REQUEST_FAIL,
  RESET_OFFER_BID_REQUEST,
} from "../constants";

const initialState = {
  loading: {
    type: "",
    state: false
  },
  errorMessage: {
    type: "",
    message: "",
  },
  //
  offerBids: [],
  internalOfferBids: [],
  externalOfferBids: [],
  bidRequests: [],
  offerBidsCount: 0,
  internalOfferBidsCount: 0,
  externalOfferBidsCount: 0,
  bidRequestsCount: 0,
  usersLastBid: [],

  offersList: [],
  offersCount: 0,
  reviewOffersCount: 0,
  ongoingOffersCount: 0,
  closedOffersCount: 0,
  failedOffersCount: 0,
  ownOffersCount: 0,
  currentPage: 0,

  activeTabKey: "all",

  reviewOffers: null,
  ongoingOffers: null,
  closedOffers: null,
  ownOffers: null,
  offerBidRequest: null,

  offerFilters: {},
  //
  selectedBid: null,
  selectedOffer: null,
  currentOpenedOffer: null,
  //
};

const offersReducer = (state = initialState, action) => {
  switch (action.type) {
    // _____________________________ SHARED ______________________________

    // searchOffers //

    case SEARCH_OFFERS_START:
      return produce(state, draft => {
        draft.loading.type = "SearchOffers";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEARCH_OFFERS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;

        draft.offersCount = action.payload.offersCount;
        draft.ongoingOffersCount = action.payload.ongoingOffersCount;
        draft.closedOffersCount = action.payload.closedOffersCount;
        draft.failedOffersCount = action.payload.failedOffersCount;

        draft.offersList = action.payload.offersList;
        draft.ongoingOffers = action.payload.ongoingOffers;
        draft.closedOffers = action.payload.closedOffers;
        draft.failedOffers = action.payload.failedOffers;
        draft.currentPage = state.currentPage + 1;

        if (action.payload.reviewOffersCount) {
          draft.reviewOffersCount = action.payload.reviewOffersCount;
        }
        if (action.payload.reviewOffers) {
          draft.reviewOffers = action.payload.reviewOffers;
        }
        if (action.payload.ownOffersCount) {
          draft.ownOffersCount = action.payload.ownOffersCount;
        }
        if (action.payload.ownOffers) {
          draft.ownOffers = action.payload.ownOffers;
        }
      });
    case SEARCH_OFFERS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SearchOffers";
        draft.errorMessage.message = action.payload;
      });

    case SET_OFFER_FILTERS:
      return produce(state, draft => {
        draft.offerFilters = action.payload;
      });

    case CLEAR_OFFER_FILTERS:
      return produce(state, draft => {
        draft.offerFilters = {};
      });

    case CLEAR_OFFERS:
      return produce(state, draft => {
        draft.offersList = [];
        draft.currentPage = 0;
        draft.offersCount = 0;
      });
    case SET_ACTIVE_OFFERS_TAB_KEY:
      return produce(state, draft => {
        draft.activeTabKey = action.payload;
      });

    // searchOfferBids //

    case SEARCH_OFFER_BIDS_START:
      return produce(state, draft => {
        draft.loading.type = "SearchOfferBids";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEARCH_OFFER_BIDS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "";
        if(action.payload.data) {
          if (typeof action.payload.data === 'number') {
            draft.offerBidsCount = action.payload.data;
          } else {
            draft.offerBids = action.payload.data;
          }
        } else {
          draft.offerBidsCount = action.payload.allBidsCount;
          draft.internalOfferBidsCount = action.payload.internalBidsCount;
          draft.externalOfferBidsCount = action.payload.externalBidsCount;
          draft.offerBids = action.payload.allBids;
          draft.internalOfferBids = action.payload.internalBids;
          draft.externalOfferBids = action.payload.externalBids;
        }
      });
    case SEARCH_OFFER_BIDS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SearchOfferBids";
        draft.errorMessage.message = action.payload;
      });
    case SEARCH_OFFER_BID_REQUESTS_START:
      return produce(state, draft => {
        draft.loading.type = "SearchOfferBidRequests";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEARCH_OFFER_BID_REQUESTS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "";
        
        if(action.payload.data) {
          if (typeof action.payload.data === 'number') {
            draft.bidRequestsCount = action.payload.data;
          } else {
            draft.bidRequests = action.payload.data;
          }
        } else {
          draft.bidRequestsCount = action.payload.bidRequestsCount;
          draft.bidRequests = action.payload.bidRequests;
        }
      });
    case SEARCH_OFFER_BID_REQUESTS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SearchOfferBidRequests";
        draft.errorMessage.message = action.payload;
      });

      case GET_OFFER_BID_REQUEST_START:
        return produce(state, draft => {
          draft.loading.type = "GetOfferBidRequest";
          draft.loading.state = true;
          draft.errorMessage.type = "";
          draft.errorMessage.message = "";
        });
      case GET_OFFER_BID_REQUEST_SUCCESS:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.loading.type = "GetOfferBidRequest";
          draft.offerBidRequest = action.payload.data;
        });
      case RESET_OFFER_BID_REQUEST:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.loading.type = "ResetOfferBidRequest";
          draft.offerBidRequest = null;
        });
      case GET_OFFER_BID_REQUEST_FAIL:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.errorMessage.type = "GetOfferBidRequest";
          draft.errorMessage.message = action.payload;
          if(action.payload === "Missing"){
            draft.offerBidRequest = null;
          }
        });
        
      case ACCEPT_BID_REQUEST_START:
        return produce(state, draft => {
          draft.loading.type = "AcceptBidRequest";
          draft.loading.state = true;
          draft.errorMessage.type = "";
          draft.errorMessage.message = "";
        });
      case ACCEPT_BID_REQUEST_SUCCESS:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.selectedBid = action.payload;
          draft.loading.type = "AcceptBidRequest";
  
        });
      case ACCEPT_BID_REQUEST_FAIL:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.errorMessage.type = "AcceptBidRequest";
          draft.errorMessage.message = action.payload;
        });

      case REJECT_BID_REQUEST_START:
        return produce(state, draft => {
          draft.loading.type = "RejectBidRequest";
          draft.loading.state = true;
          draft.errorMessage.type = "";
          draft.errorMessage.message = "";
        });
      case REJECT_BID_REQUEST_SUCCESS:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.selectedBid = action.payload;
          draft.loading.type = "RejectBidRequest";
  
        });
      case REJECT_BID_REQUEST_FAIL:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.errorMessage.type = "RejectBidRequest";
          draft.errorMessage.message = action.payload;
        });

    // getOffer //

    case GET_OFFER_START:
      return produce(state, draft => {
        draft.loading.type = "GetOffer";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_OFFER_SUCCESS:
      return produce(state, draft => {
        draft.loading.type = "GetOffer";
        draft.loading.state = false;
        // ^^^
        draft.selectedOffer = action.payload.ownership.closed
        ? {
            ...action.payload,
            ownership: {
              ...action.payload.ownership,
              closed: moment(action.payload.ownership.closed).subtract(6, 'months').format('YYYY-MM-DD hh:mm:ss')
            }
          }
          : action.payload;
      });
    case GET_OFFER_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetOffer";
        draft.loading.type = "GetOffer";
        draft.errorMessage.message = action.payload;
      });

    // clear getOffer //

    case CLEAR_OFFER:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "ClearGetOffer";
        draft.selectedOffer = null;
      });

    // getBid //

    case GET_BID_START:
      return produce(state, draft => {
        draft.loading.type = "GetBid";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_BID_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.selectedBid = action.payload;
        draft.loading.type = "";

      });
    case GET_BID_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetBid";
        draft.errorMessage.message = action.payload;
      });

    // _____________________________ INVESTOR ______________________________

    // createOffer (postForSale) //

    case CREATE_OFFER_START:
      return produce(state, draft => {
        draft.loading.type = "CreateOffers";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case CREATE_OFFER_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "CreateOffers";
      });
    case CREATE_OFFER_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "CreateOffers";
        draft.errorMessage.message = action.payload;
      });

    // requestExternalSale //

    case REQUEST_EXTERNAL_SALE_START:
      return produce(state, draft => {
        draft.loading.type = "ExternalSale";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case REQUEST_EXTERNAL_SALE_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "ExternalSale";
        draft.selectedOffer.external_requested = true;
      });
    case REQUEST_EXTERNAL_SALE_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "ExternalSale";
        draft.errorMessage.message = action.payload;
      });

    // AddInternalBid //

    case ADD_INTERNAL_BID_START:
      return produce(state, draft => {
        draft.loading.type = "AddBid";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ADD_INTERNAL_BID_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "AddBid";
      });
    case ADD_INTERNAL_BID_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "AddBid";
        draft.errorMessage.message = action.payload;
      });

      
    // AddInternalBid //
    case ADD_EXTERNAL_BID_START:
      return produce(state, draft => {
        draft.loading.type = "AddBid";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ADD_EXTERNAL_BID_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "AddBid";
      });
      case ADD_EXTERNAL_BID_FAIL:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.errorMessage.type = "AddBid";
          draft.errorMessage.message = action.payload;
        });
      case ADD_EXTERNAL_OVERBID:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.loading.type = "AddOverbid";
        });

    // searchBids

    case SEARCH_BIDS_START:
      return produce(state, draft => {
        draft.loading.type = "SearchBids";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEARCH_BIDS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "";
        draft.usersLastBid = action.payload;
      });
    case SEARCH_BIDS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SearchBids";
        draft.errorMessage.message = action.payload;
      });

    // remove offer (investor) //

    case REMOVE_OFFER_INVESTOR_START:
      return produce(state, draft => {
        draft.loading.type = "RemoveOfferInvestor";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case REMOVE_OFFER_INVESTOR_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "RemoveOfferInvestor";
      });
    case REMOVE_OFFER_INVESTOR_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "RemoveOfferInvestor";
        draft.errorMessage.message = action.payload;
      });

    // _____________________________ ADMIN ______________________________

    // internalPublish //

    case INTERNAL_PUBLISH_START:
      return produce(state, draft => {
        draft.loading.type = "InternalPublish";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case INTERNAL_PUBLISH_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "InternalPublish";
        draft.selectedOffer.internal_approved = true;
        draft.selectedOffer.start = action.payload.start
        draft.selectedOffer.end = action.payload.end
      });
    case INTERNAL_PUBLISH_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "InternalPublish";
        draft.errorMessage.message = action.payload;
      });

    // externalPublish //

    case EXTERNAL_PUBLISH_START:
      return produce(state, draft => {
        draft.loading.type = "ExternalPublish";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case EXTERNAL_PUBLISH_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "ExternalPublish";
        draft.selectedOffer.external_approved = true;
        draft.selectedOffer.start = action.payload.start
        draft.selectedOffer.end = action.payload.end
      });
    case EXTERNAL_PUBLISH_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "ExternalPublish";
        draft.errorMessage.message = action.payload;
      });
    case CLOSE_OFFER_START:
      return produce(state, draft => {
        draft.loading.type = "CloseOffer";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case CLOSE_OFFER_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "CloseOffer";
      });
    case CLOSE_OFFER_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "CloseOffer";
        draft.errorMessage.message = action.payload;
      });
    case MOVE_BID_START:
      return produce(state, draft => {
        draft.loading.type = "MoveBid";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case MOVE_BID_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "MoveBid";
      });
    case MOVE_BID_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "MoveBid";
        draft.errorMessage.message = action.payload;
      });

    // remove offer (admin) //

    case REMOVE_OFFER_ADMIN_START:
      return produce(state, draft => {
        draft.loading.type = "RemoveOfferAdmin";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case REMOVE_OFFER_ADMIN_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "RemoveOfferAdmin";
      });
    case REMOVE_OFFER_ADMIN_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "RemoveOfferAdmin";
        draft.errorMessage.message = action.payload;
      });

    // setWon //

    case SET_WON_START:
      return produce(state, draft => {
        draft.loading.type = "SetWon";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SET_WON_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.loading.type = "SetWon";
        draft.offerBids.find((i) => +i.id === +action.payload.id).won = true;
      });
    case SET_WON_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SetWon";
        draft.errorMessage.message = action.payload;
      });

    default:
      return state;
  }
};

export default offersReducer;
