import {
  CREATE_BUSINESS_START, CREATE_BUSINESS_SUCCESS, CREATE_BUSINESS_FAIL,
  UPDATE_BUSINESS_START, UPDATE_BUSINESS_SUCCESS, UPDATE_BUSINESS_FAIL,
  ENABLE_BUSINESS_START, ENABLE_BUSINESS_SUCCESS, ENABLE_BUSINESS_FAIL,
  DISABLE_BUSINESS_START, DISABLE_BUSINESS_SUCCESS, DISABLE_BUSINESS_FAIL,
} from "../../constants";

export const createBusinessStart = () => ({
  type: CREATE_BUSINESS_START
});

export const createBusinessSuccess = payload => ({
  type: CREATE_BUSINESS_SUCCESS,
  payload
});

export const createBusinessFail = payload => ({
  type: CREATE_BUSINESS_FAIL,
  payload
});

export const updateBusinessStart = () => ({
  type: UPDATE_BUSINESS_START
});

export const updateBusinessSuccess = payload => ({
  type: UPDATE_BUSINESS_SUCCESS,
  payload
});

export const updateBusinessFail = payload => ({
  type: UPDATE_BUSINESS_FAIL,
  payload
});

export const enableBusinessStart = () => ({ type: ENABLE_BUSINESS_START });

export const enableBusinessSuccess = payload => ({ type: ENABLE_BUSINESS_SUCCESS, payload });

export const enableBusinessFail = payload => ({ type: ENABLE_BUSINESS_FAIL, payload });

export const disableBusinessStart = () => ({ type: DISABLE_BUSINESS_START });

export const disableBusinessSuccess = payload => ({ type: DISABLE_BUSINESS_SUCCESS, payload });

export const disableBusinessFail = payload => ({ type: DISABLE_BUSINESS_FAIL, payload });
