
import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Select } from "antd";

import Button from "../../../../components/Button/Button";
import Icon from "../../../../components/Icon/Icon";
import { handleLoading } from "../../../../utils/handleLoading";
import NumericInput from "../../../../components/NumericInput/NumericInput";
import { INVESTOR_MODE } from "../../../../utils/constants";

const { Option } = Select;

class RequestExternalPublish extends Component {
  constructor(props) {
    super(props);

    this.state = {
      endDateVal: null,
    }
  }

  onSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) this.props.requestExternalPublish(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let { loading } = this.props;

    return (
      <>
        <div className="popup_close color-grey-700" onClick={() => this.props.changePopUpView('requestExternalModalVisible')}>
          <Icon type="cancel" />
        </div>
        <Form onSubmit={this.onSubmit}>
          <div className="flex-container align-center text-center">
            <div className="text text-28 text-height-33 font-heavy font-spacing-02 color-grey font-uppercase large-7 mb_35">
              Request publishing for all investors
            </div>
          </div>
          <div>
            <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Deal duration (days)
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator("external_duration", {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <NumericInput className="fluid-x" />
                )}
              </Form.Item>
            </div>
            {/* <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Secondary Auction Style
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator('mode', {
                  rules: [{ required: true, message: "Please fill out this field" }]
                })(
                  <Select className="fluid-x" >
                    {INVESTOR_MODE.map((i, index) => <Option key={index} value={i}>{i}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </div> */}
            <div className="form-item mb_40">
              <div className="form-item-label text text-height-20 font-semibold color-grey">
                Minimum offer price
              </div>
              <Form.Item className="mb_0">
                {getFieldDecorator('external_max_price')(
                  <NumericInput className="fluid-x" />
                )}
              </Form.Item>
            </div>
          </div>
          <div className="btn_group medium-flex-container align-middle align-right">
            <Form.Item className="mb_0">
              <Button
                title="Cancel"
                size="lg"
                theme="default"
                className="btn-link radius-8 font-uppercase"
                type="button"
                onClick={() => this.props.changePopUpView('requestExternalModalVisible')}
              />
            </Form.Item>
            <Form.Item className="mb_0">
              <Button
                title="Save"
                type="submit"
                size="xl"
                theme="blue"
                prefix={<Icon type="checked" size={18} style={{ marginRight: 11 }} />}
                className="radius-8"
                style={{ fontSize: 18 }}
                loading={handleLoading(loading, "ExternalSale")}
              />
            </Form.Item>
          </div>
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.offers.loading,
  errorMessage: state.offers.errorMessage,
});

const RequestExternalPublishScreen = Form.create({ name: "RequestExternalPublish" })(RequestExternalPublish);

export default connect(mapStateToProps)(RequestExternalPublishScreen);

