import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Input, message } from "antd";

import history from "../../history";
import { recoverPassword } from "../../redux/user/thunks/recoverPasswordThunk";
import { handleErrorMessage } from "../../utils/handleErrorMessage";
import { handleLoading } from "../../utils/handleLoading";
import { specialCharacterRegExp, upperCaseCharacterRegExp } from "../../utils/regexps";
import Icon from "../../components/Icon/Icon";
import Button from "../../components/Button/Button";

class RecoverPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPopupVisible: false,
      validation8Chars: {
        isFilled: false,
        isValid: false,
      },
      validationSpecialCharacter: {
        isFilled: false,
        isValid: false,
      },
      validationUpperCase: {
        isFilled: false,
        isValid: false,
      },
    }
  }

  onSubmit = (event) => {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.password === values.repeat_password) {
          const {recoverPassword} = this.props;
          const code = this.props.match.params.code;

          const param = {
            code,
            password: values.password
          };

          recoverPassword(param);
        } else {
          message.error("Passwords are not identical");
        }
      }
    });
  };

  passwordValidator = async (rule, value) => {
    if (value) {
      let isValid = true;
      if (value.length < 8) {
        this.setState({
          validation8Chars: {
            isFilled: true,
            isValid: false,
          },
        });
        isValid = false;
      } else {
        this.setState({
          validation8Chars: {
            isFilled: true,
            isValid: true,
          },
        });
      }
      if (!specialCharacterRegExp.test(value)) {
        this.setState({
          validationSpecialCharacter: {
            isFilled: true,
            isValid: false,
          },
        });
        isValid = false;
      } else {
        this.setState({
          validationSpecialCharacter: {
            isFilled: true,
            isValid: true,
          },
        });
      }
      if (!upperCaseCharacterRegExp.test(value)) {
        this.setState({
          validationUpperCase: {
            isFilled: true,
            isValid: false,
          },
        });
        isValid = false;
      } else {
        this.setState({
          validationUpperCase: {
            isFilled: true,
            isValid: true,
          },
        });
      }

      if (!isValid) {
        return Promise.reject('');
      }
    } else {
      this.setState({
        validation8Chars: {
          isFilled: false,
          isValid: false,
        },
        validationSpecialCharacter: {
          isFilled: false,
          isValid: false,
        },
        validationUpperCase: {
          isFilled: false,
          isValid: false,
        },
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { loading, errorMessage } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;

    if (!errorText && loadingType === "RecoverPassword" && !loadingState && prevProps.loading.state) {
      message.success("Password changed successfully");
      history.push("/");
    }

    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "RecoverPassword")) {
      message.error(errorText);
    }
  }

  render() {
    const { form, loading } = this.props;
    const { getFieldDecorator } = form;
    const { validation8Chars, validationSpecialCharacter, validationUpperCase } = this.state;

    return (
      <div className="row">
        <div className="column small-12 x-large-10">
          <Form
            name="log-in"
            onSubmit={this.onSubmit}
            className="sign_out_form_card login_form bg-white radius-10 shadow-layout"
          >
            <div className="column small-12 mb_45">
              <div className="text-center custom-headline text text-34 font-heavy color-grey">
                <h1>Recover Password</h1>
              </div>
            </div>
            <div className="mb_45">
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  NEW PASSWORD
                </div>
                <Form.Item>
                  {getFieldDecorator("password", {
                    initialValue: "",
                    rules: [
                      {required: true, message: "Please fill out this field"},
                      {validator: this.passwordValidator},
                    ],
                    validateTrigger: "onBlur"
                  })(
                    <Input.Password />
                  )}
                </Form.Item>
                <div className="validators-wrapper" >
                  <p
                    className="mb_0"
                    style={validation8Chars.isFilled && validation8Chars.isValid ? { color: 'green' } : null}
                  >
                    <Icon
                      type='checked'
                      style={validation8Chars.isFilled && validation8Chars.isValid ? { color: 'green' } : null}
                    />
                    At least 8 character
                  </p>
                  <p
                    className="mb_0"
                    style={validationSpecialCharacter.isFilled && validationSpecialCharacter.isValid ? { color: 'green' } : null}
                  >
                    <Icon
                      type='checked'
                      style={validationSpecialCharacter.isFilled && validationSpecialCharacter.isValid ? { color: 'green' } : null}
                    />
                    At least 1 special character
                  </p>
                  <p
                    className="mb_0"
                    style={validationUpperCase.isFilled && validationUpperCase.isValid ? { color: 'green' } : null}
                  >
                    <Icon
                      type='checked'
                      style={validationUpperCase.isFilled && validationUpperCase.isValid ? { color: 'green' } : null}
                    />
                    At least 1 upper case character
                  </p>
                </div>
              </div>
            </div>
            <div className="mb_45">
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  REPEAT NEW PASSWORD
                </div>
                <Form.Item>
                  {getFieldDecorator("repeat_password", {
                    initialValue: "",
                    rules: [
                      {required: true, message: "Please fill out this field"},
                      {
                        min: 8,
                        message: "Password should contain minimum 8 characters"
                      }
                    ],
                    validateTrigger: "onBlur"
                  })(
                    <Input.Password />
                  )}
                </Form.Item>
              </div>
            </div>
            <div className="text-center form_submit">
              <Form.Item className="mb_0">
                <Button
                  title="Reset"
                  prefix={<Icon type="login" style={{fontSize: 18, marginRight: 10}} />}
                  size="xl"
                  theme="blue"
                  className="btn-shadow radius-8"
                  type="submit"
                  loading={handleLoading(loading, "RecoverPassword")}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.user.loading,
  errorMessage: state.user.errorMessage
});

const mapDispatchToProps = dispatch => ({
  recoverPassword: param => dispatch(recoverPassword(param))
});

const RecoverPasswordScreen = Form.create()(RecoverPassword);

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordScreen);
