import React, {Component} from "react";
import {connect} from "react-redux";
import {addDataRoomItem} from "../../redux/dataRoom/thunks/dataRoomThunks";
import {searchUsers, searchUsersFilter} from "../../redux/users/thunks/searchUsersThunk";

import {Spin, Upload, Form, Checkbox, AutoComplete} from "antd";
import Button from "../Button/Button";
import PopUp from "../PopUp/PopUp";
import Icon from "../Icon/Icon";
import {getMediaFileFromServer} from "../../utils/getMediaFileFromServer";

const {Dragger} = Upload;
const { Option } = AutoComplete;

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFileUploaded: false,
      privacyPopupVisible: false,
      usersIDsForSharing: []
    };
  }

  onCustomRequest = ({ file }) => {
    this.setState({
      isFileUploaded: true
    }, () => {
      const {addDataRoomItem, folderID, businessID, role, form} = this.props;
      const { getFieldValue } = form;

      if (role !== "ADMIN") {
        addDataRoomItem(folderID, file.name, "file", file, 0, false, null);
      }
      else {
        const usersArray = this.state.usersIDsForSharing;
        usersArray.push(getFieldValue("userName"));
        addDataRoomItem(folderID, file.name, "file", file, businessID, true, usersArray);
      }
    });
  };

  togglePrivacyModal = status => {
    this.setState({
      privacyPopupVisible: status
    }, () => {
      if (status) {
        const { searchUsers } = this.props;
        searchUsers(1);
      }
    });
  };

  sliceArray = (array, elementsSize) => {
    let slicedArray = [];
    if (Array.isArray(array)) {
      if (array.length < elementsSize) {
        slicedArray = array;
      }
      else {
        slicedArray = array.slice(0, elementsSize).map(i => i);
      }
    }
    return slicedArray;
  };

  toggleLastUserOnChange = (userID, checkedState) => {
    if (checkedState) {
      this.setState(prevState => ({
        usersIDsForSharing: [...prevState.usersIDsForSharing, userID]
      }));
    }
    else {
      const currentArray = [...this.state.usersIDsForSharing];
      const index = currentArray.indexOf(userID);

      if (index !== -1) {
        currentArray.splice(index, 1);
        this.setState({
          usersIDsForSharing: currentArray
        })
      }
    }
  };

  handleUserNameOnChange = inputValue => {
    const { searchUsersFilter } = this.props;
    const inputValueArray = inputValue.split(/(\s+)/); // 0 index is name, 2 index is surname
    const params = {
      first_name: inputValueArray[0] ? inputValueArray[0] : "",
      last_name: inputValueArray[2] ? inputValueArray[2] : ""
    };

    searchUsersFilter(params);
  };

  render() {
    const { isFileUploaded, privacyPopupVisible } = this.state;
    const { form, usersSearchList } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    let usersList = this.props.usersList ? this.props.usersList : [];

    return (
      <>
        <Spin spinning={isFileUploaded} >
          <div className="drag_card">
            <Dragger
              customRequest={this.onCustomRequest}
              fileList={[]}
              multiple
            >
              <div className="ant-upload-drag-icon drag_card__icon">
                <img src={require("../../assets/img/drag-files.svg")} alt=""/>
              </div>
              <div className="drag_card__title">
                <div className="text text-height-20 font-semibold color-grey">
                  Drag your files here
                </div>
                <div className="text text-14 font-semibold color-grey-700">
                  or
                </div>
              </div>
              <div className="drag_card__desc">
                <Button
                  title="Browse files"
                  size="lg"
                  theme="lightblue"
                  mode="bordered"
                  className="radius-8"
                />
              </div>
            </Dragger>
          </div>
        </Spin>
        <PopUp
          className="relative_parent"
          visible={privacyPopupVisible}
          innerClassNames="shadow-layout bg-white radius-10 relative lg base"
        >
          <div className="popup_close color-grey-700" onClick={() => this.togglePrivacyModal(false)}>
            <Icon type="close" size={24}/>
          </div>
          <div className="popup-form">
            <div className="flex-container align-center text-center">
              <div className="text text-28 text-height-33 font-heavy font-spacing-02 color-grey font-uppercase large-7 mb_45">
                who do you want to share with?
              </div>
            </div>
            <div>
              <div className="form-item mb_35">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  SEARCH GROUP OR INVESTOR
                </div>
                <Form.Item className="mb_0 message_input relative dotted-bottom">
                  {getFieldDecorator("userName", {
                    initialValue: [],
                    rules: [{required: false, message: "Please fill out this field"}]
                  })(
                    <AutoComplete onChange={this.handleUserNameOnChange} >
                      {
                        usersSearchList && usersSearchList.map(element => (
                          <Option key={element.id} value={element.id.toString()} >
                            <img src={getMediaFileFromServer(element.avatar)} alt=""/>
                            {element.first_name} {element.last_name}
                          </Option>
                        ))
                      }
                    </AutoComplete>
                  )}
                </Form.Item>
              </div>
            </div>
            <div className="mb_50">
              <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_20">
                LAST users
              </div>
              <div>
                {
                  this.sliceArray(usersList, 4).map((element) => (
                    <div className="flex-container align-middle align-justify user_item_panel">
                      <div className="flex-container align-middle user_item">
                        {
                          element.avatar ?
                            <div className="radius-circle clip bg-lightblue flex-container align-middle align-center user_item__img">
                              <img src={getMediaFileFromServer(element.avatar)} alt=""/>
                            </div>
                          :
                            <div className="radius-circle clip bg-lightblue flex-container align-middle align-center user_item__img">
                              <div className="text text-20 font-bold color-blue">{element.first_name.charAt(0)}</div>
                            </div>
                        }

                        <div className="text font-semibold color-grey">
                          {element.first_name} {element.last_name}
                        </div>
                      </div>
                      <Form.Item className="mb_0 shrink form-item-lg">
                        {getFieldDecorator(`user_share_${element.id}`, {
                          valuePropName: "checked",
                          initialValue: false,
                          rules: [{required: false, message: "Please fill out this field"}]
                        })(
                          <Checkbox
                            onChange={() => (
                              this.toggleLastUserOnChange(element.id, !getFieldValue(`user_share_${element.id}`))
                            )}
                          />
                        )}
                      </Form.Item>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="text-right">
              <Button
                title="OK"
                size="xl"
                theme="blue"
                className="radius-8"
                style={{fontSize: 18}}
                onClick={() => this.togglePrivacyModal(false)}
              />
            </div>
          </div>
        </PopUp>
      </>
    )
  }
}

const mapStateToProps = state => ({
  role: state.user.role,
  usersList: state.users.usersList,
  usersSearchList: state.users.usersSearchList
});

const mapDispatchToProps = dispatch => ({
  addDataRoomItem: (parent, name, type, file, business, isPrivate, accessIDs) => dispatch(addDataRoomItem(parent, name, type, file, business, isPrivate, accessIDs)),
  searchUsers: currentPage => dispatch(searchUsers(currentPage)),
  searchUsersFilter: params => dispatch(searchUsersFilter(params)),
});

const FileUploadComponent = Form.create()(FileUpload);

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadComponent);