export const getDataRoomIconFromFileExtension = fileName => {
  const extension = fileName.substr(fileName.lastIndexOf('.') + 1);
  let iconName = "";
  switch (extension) {
    case "doc":
    case "docx":
      iconName = "file-doc.svg";
      break;
    case "xls":
    case "xlsx":
      iconName = "file-excel.svg";
      break;
    case "pdf":
      iconName = "file-pdf.svg";
      break;
    default:
      iconName = "file-not-found.svg";
      break;
  }

  return iconName;
};