import {
  createBusinessStart, createBusinessSuccess, createBusinessFail,
  updateBusinessStart, updateBusinessSuccess, updateBusinessFail,
  enableBusinessStart, enableBusinessSuccess, enableBusinessFail,
  disableBusinessStart, disableBusinessSuccess, disableBusinessFail,
} from "../actions/newBusinessActions";
import CommonUtils from "../../../utils/CommonUtils";

export const createBusiness = params => ((dispatch, getState) => {
  dispatch(createBusinessStart());

  const { filter } = getState().business;
  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  const perPage = 10;

  CommonUtils.apiCall("post", "private", "shared/createBusiness", params, config)
    .then(response => {
      if (response.data.success) {
        CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/${perPage}?cnt&enabled`, filter, config)
          .then(businessesItemsCount => {
            if (businessesItemsCount.data != null) {
              const businessesCount = businessesItemsCount.data;
              CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/${perPage}?enabled`, filter, config)
                .then(businessesResponse => {
                  if (Array.isArray(businessesResponse.data)) {
                    dispatch(createBusinessSuccess({
                      businesses: businessesResponse.data,
                      currentPage: 1,
                      businessesCount
                    }))
                  }
                  else {
                    dispatch(createBusinessFail(response.data.error));
                  }
                })
                .catch(() => dispatch(createBusinessFail("Error")));
            }
            else {
              dispatch(createBusinessFail("Error"));
            }
          })
          .catch(() => dispatch(createBusinessFail("Error")));
      }
      else {
        dispatch(createBusinessFail(response.data.error));
      }
    })
    .catch(() => dispatch(createBusinessFail("Error")));
});

export const updateBusiness = params => ((dispatch, getState) => {
  dispatch(updateBusinessStart());

  const { filter } = getState().business;
  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  const perPage = 10;

  CommonUtils.apiCall("post", "private", "shared/updateBusiness", params, config)
    .then(updateBusinessResponse => {
      if (updateBusinessResponse.data.success) {
        CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/${perPage}?enabled`, filter, config)
          .then(businessesResponse => {
            if (Array.isArray(businessesResponse.data)) {
              dispatch(updateBusinessSuccess({
                businesses: businessesResponse.data,
                currentPage: 1
              }));
            }
            else {
              dispatch(updateBusinessFail(businessesResponse.data.error));
            }
          })
          .catch(() => dispatch(updateBusinessFail("Error")));
      }
      else {
        dispatch(updateBusinessFail(updateBusinessResponse.data.error));
      }
    })
    .catch(() => dispatch(updateBusinessFail("Error")));
});

export const enableBusiness = businessID => ((dispatch, getState) => {
  dispatch(enableBusinessStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "admin/enableBusiness", { id: businessID }, config)
    .then((response) => {
      if (response.data.success) {
        CommonUtils.apiCall("get", "private", `shared/getBusiness/${businessID}`, {}, config)
          .then((currentBusinessResponse) => {
            if (currentBusinessResponse.data.success) {
              dispatch(enableBusinessSuccess({
                currentOpenedBusiness: {
                  id: businessID,
                  ...currentBusinessResponse.data.success,
                },
              }));
            } else {
              dispatch(enableBusinessFail("Error"));
            }
          })
          .catch(() => dispatch(enableBusinessFail("Error")));
      } else {
        dispatch(enableBusinessFail("Error"));
      }
    })
    .catch(() => dispatch(enableBusinessFail("Error")));
});

export const disableBusiness = businessID => ((dispatch, getState) => {
  dispatch(disableBusinessStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "admin/disableBusiness", { id: businessID }, config)
    .then((response) => {
      if (response.data.success) {
        CommonUtils.apiCall("get", "private", `shared/getBusiness/${businessID}`, {}, config)
          .then((currentBusinessResponse) => {
            if (currentBusinessResponse.data.success) {
              dispatch(disableBusinessSuccess({
                currentOpenedBusiness: {
                  id: businessID,
                  ...currentBusinessResponse.data.success,
                },
              }));
            } else {
              dispatch(disableBusinessFail("Error"));
            }
          })
          .catch(() => dispatch(disableBusinessFail("Error")));
      } else {
        dispatch(disableBusinessFail("Error"));
      }
    })
    .catch(() => dispatch(disableBusinessFail("Error")));
});
