import {
  getDataRoomItemsStart, getDataRoomItemsSuccess, getDataRoomItemsFail,
  getVirtualDataRoomItemsStart, getVirtualDataRoomItemsSuccess, getVirtualDataRoomItemsFail,
  getVirtualDataRoomItemsDashboardStart, getVirtualDataRoomItemsDashboardSuccess, getVirtualDataRoomItemsDashboardFail,
  getDataRoomInnerItemsStart, getDataRoomInnerItemsSuccess, getDataRoomInnerItemsFail,
  addDataRoomItemStart, addDataRoomItemFail,
  setDataRoomItemAccessTypeStart, setDataRoomItemAccessTypeFail,
  removeDataRoomItemStart, removeDataRoomItemFail,
  renameDataRoomItemStart, renameDataRoomItemFail,
  addAccessToDataRoomItemStart, addAccessToDataRoomItemSuccess, addAccessToDataRoomItemFail,
  removeAccessToDataRoomItemStart, removeAccessToDataRoomItemSuccess, removeAccessToDataRoomItemFail,
  searchBusinessRoomUsersStart, searchBusinessRoomUsersSuccess, searchBusinessRoomUsersFail,
  searchRoomUsersStart, searchRoomUsersSuccess, searchRoomUsersFail, setUsersSearchRoomPagination,
  getSharedDataRoomItemsStart, getSharedDataRoomItemsSuccess, getSharedDataRoomItemsFail,
} from "../actions/dataRoomActions";
import CommonUtils from "../../../utils/CommonUtils";
import {SERVER_URL} from "../../../utils/constants";
import axios from "axios";

export const getVirtualDataRoomItems = (hardCodePage) => ((dispatch, getState) => {
  dispatch(getVirtualDataRoomItemsStart());

  const { rootFilter } = getState().dataRoom;
  const { xID, xTOKEN, role } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  const currentPage = hardCodePage !== undefined ? hardCodePage : getState().dataRoom.virtualDataRoomItemsCurrentPage;
  const perPage = 12;
  const offset = (currentPage - 1) * perPage;

  const API_COUNT_ENDPOINT = role !== 'ADMIN' ? `shared/getBusinesses/${offset}/${perPage}?cnt&nda` : `shared/getBusinesses/${offset}/${perPage}?cnt`;
  const API_ENDPOINT = role !== 'ADMIN' ? `shared/getBusinesses/${offset}/${perPage}?nda` : `shared/getBusinesses/${offset}/${perPage}`;

  CommonUtils.apiCall("get", "private", API_COUNT_ENDPOINT, rootFilter, config)
    .then(virtualItemsCountResponse => {
      if (virtualItemsCountResponse.data != null) {
        const virtualItemsCount = virtualItemsCountResponse.data;
        CommonUtils.apiCall("get", "private", API_ENDPOINT, rootFilter, config)
          .then(virtualItemsResponse => {
            if (Array.isArray(virtualItemsResponse.data)) {
              const virtualDataRoomItems = virtualItemsResponse.data;
              const oldVirtualDataRoomItems = getState().dataRoom.virtualDataRoomItems ? getState().dataRoom.virtualDataRoomItems : [];
              const updatedVirtualDataRoomItems = hardCodePage ? virtualDataRoomItems : oldVirtualDataRoomItems.concat(virtualDataRoomItems);

              dispatch(getVirtualDataRoomItemsSuccess({
                virtualDataRoomItems: updatedVirtualDataRoomItems,
                virtualDataRoomItemsCount: virtualItemsCount,
                virtualDataRoomItemsCurrentPage: currentPage + 1,
              }));
            } else {
              dispatch(getVirtualDataRoomItemsFail("Error"));
            }
          })
          .catch(() => dispatch(getVirtualDataRoomItemsFail("Error")));
      } else {
        dispatch(getVirtualDataRoomItemsFail("Error"));
      }
    })
    .catch(() => dispatch(getVirtualDataRoomItemsFail("Error")));
});

export const getVirtualDataRoomItemsDashboard = () => ((dispatch, getState) => {
  dispatch(getVirtualDataRoomItemsDashboardStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall('get', 'private', 'shared/getBusinesses/0/3?pursuing', {}, config)
    .then((response) => {
      if (response.data && Array.isArray(response.data)) {
        dispatch(getVirtualDataRoomItemsDashboardSuccess({
          virtualDataRoomItemsDashboard: response.data,
        }));
      }
    })
    .catch(() => dispatch(getVirtualDataRoomItemsDashboardFail('Error')));
});

export const getDataRoomItems = (hardCodePage) => ((dispatch, getState) => {
  dispatch(getDataRoomItemsStart());

  const { rootFilter } = getState().dataRoom;
  let { currentPage } = getState().dataRoom;
  currentPage = hardCodePage ? hardCodePage : currentPage;
  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", "shared/getDataRoomItems/0/0/0/0?cnt", rootFilter, config)
    .then(roomItemsCountResponse => {
      if (roomItemsCountResponse.data != null && parseInt(roomItemsCountResponse.data) === 0) {
        dispatch(getDataRoomItemsSuccess({
          dataRoomItems: [],
          dataRoomItemsCount: 0,
          currentPage: 1
        }));
      }
      else if (roomItemsCountResponse.data != null && !roomItemsCountResponse.data.error) {
        const perPage = 10;
        const dataRoomItemsCount = roomItemsCountResponse.data;
        const offset = (currentPage - 1) * perPage;

        CommonUtils.apiCall("get", "private", `shared/getDataRoomItems/0/0/${offset}/${perPage}`, rootFilter, config)
          .then(response => {
            if (Array.isArray(response.data)) {
              // TODO handle if business is empty, load the data fom API
              // const { role } = getState().user;
              const oldDataRoomItems = getState().dataRoom.dataRoomItems;
              let newDataRoomItems = response.data;

              const updatedDataRoomItems = parseInt(hardCodePage) !== 1 && oldDataRoomItems ? oldDataRoomItems.concat(newDataRoomItems) : newDataRoomItems;
              /*const { pursuingBusinesses, businesses } = getState().business;
              const isAdmin = role.toUpperCase() === "ADMIN";
              const loopingArray = !isAdmin ? pursuingBusinesses : businesses;

              loopingArray && loopingArray.map(element => {
                dataRoomItems.push({
                  id: element.id,
                  parent: 0,
                  creator: element.creator,
                  business: element.id,
                  type: "DIRECTORY",
                  source: element.source,
                  private: 0,
                  name: element.name,
                  created: element.created,
                  updated: null
                });
              });*/

              dispatch(getDataRoomItemsSuccess({
                dataRoomItems: updatedDataRoomItems,
                dataRoomItemsCount,
                currentPage: currentPage + 1
              }));
            }
            else {
              dispatch(getDataRoomItemsFail(response.data.error));
            }
          })
          .catch(error => {
            dispatch(getDataRoomItemsFail("Error"));
          })
      }
      else {
        dispatch(getDataRoomItemsFail(roomItemsCountResponse.data.error));
      }
    })
    .catch(() => {
      dispatch(getDataRoomItemsFail("Error"));
    });
});

export const getDataRoomInnerItems = (parent, business = 0, isNeededToStartFromBeginning = false) => ((dispatch, getState) => {
  dispatch(getDataRoomInnerItemsStart());

  const { innerFilter } = getState().dataRoom;
  const currentInnerPage = isNeededToStartFromBeginning ? 1 : getState().dataRoom.currentInnerPage;
  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  // const {dataRoomItems} = getState().dataRoom;
  const callURL = offset => `shared/getDataRoomItems/${business}/${parent}/${offset}`;
  const perPage = 10;

  CommonUtils.apiCall("get", "private", `${callURL(0)}/${perPage}?cnt`, innerFilter, config)
    .then(innerItemsCountResponse => {
      if (innerItemsCountResponse.data != null && parseInt(innerItemsCountResponse.data) === 0) {
        dispatch(getDataRoomInnerItemsSuccess({
          dataRoomInnerItems: []
        }));
      }
      else if (innerItemsCountResponse.data != null && !innerItemsCountResponse.data.error) {
        const dataRoomInnerItemsCount = innerItemsCountResponse.data;
        const offset = (currentInnerPage - 1) * perPage;

        CommonUtils.apiCall("get", "private", `${callURL(offset)}/${perPage}`, innerFilter, config)
          .then(response => {
            if (Array.isArray(response.data)) {
              const oldDataRoomInnerItems = getState().dataRoom.dataRoomInnerItems;
              const newDataRoomItems = response.data;
              const updatedDataRoomItems = oldDataRoomInnerItems ? oldDataRoomInnerItems.concat(newDataRoomItems) : newDataRoomItems;

              dispatch(getDataRoomInnerItemsSuccess({
                dataRoomInnerItems: isNeededToStartFromBeginning ? response.data : updatedDataRoomItems,
                dataRoomInnerItemsCount,
                currentInnerPage: currentInnerPage + 1
              }));
            }
            else {
              dispatch(getDataRoomInnerItemsFail(response.data.error));
            }
          })
          .catch(() => dispatch(getDataRoomInnerItemsFail("Error")));
      }
      else {
        dispatch(getDataRoomInnerItemsFail(innerItemsCountResponse.data.error));
      }
    })
    .catch(() => dispatch(getDataRoomInnerItemsFail("Error")));

  /*if (dataRoomItems) { //if parent files exist -> we need call only inner files
    CommonUtils.apiCall("get", "private", `${callURL(0)}/${perPage}?cnt`, {}, config)
      .then(innerItemsCountResponse => {
        if (innerItemsCountResponse.data != null && parseInt(innerItemsCountResponse.data) === 0) {
          dispatch(getDataRoomInnerItemsSuccess({
            dataRoomInnerItems: []
          }));
        }
        else if (innerItemsCountResponse.data != null && !innerItemsCountResponse.data.error) {
          const dataRoomInnerItemsCount = innerItemsCountResponse.data;
          const offset = (currentInnerPage - 1) * perPage;

          CommonUtils.apiCall("get", "private", `${callURL(offset)}/${perPage}`, {}, config)
            .then(response => {
              if (Array.isArray(response.data)) {
                const oldDataRoomInnerItems = getState().dataRoom.dataRoomInnerItems;
                const newDataRoomItems = response.data;
                const updatedDataRoomItems = oldDataRoomInnerItems ? oldDataRoomInnerItems.concat(newDataRoomItems) : newDataRoomItems;

                dispatch(getDataRoomInnerItemsSuccess({
                  dataRoomInnerItems: updatedDataRoomItems,
                  dataRoomInnerItemsCount,
                  currentInnerPage: currentInnerPage + 1
                }));
              }
              else {
                dispatch(getDataRoomInnerItemsFail(response.data.error));
              }
            })
            .catch(error => dispatch(getDataRoomInnerItemsFail("Error")));
        }
        else {
          dispatch(getDataRoomInnerItemsFail(innerItemsCountResponse.data.error));
        }
      })
      .catch(() => dispatch(getDataRoomInnerItemsFail("Error")));
  }
  else {
    CommonUtils.apiCall("get", "private", "shared/getDataRoomItems/0/0/0/0?cnt", {}, config)
      .then(roomItemsCountResponse => {
        if (roomItemsCountResponse.data != null && parseInt(roomItemsCountResponse) === 0) { // if parent file count is empty -> inner files need to be empty too
          dispatch(getDataRoomInnerItemsSuccess({
            dataRoomItems: [],
            dataRoomInnerItems: [],
            dataRoomInnerItemsCount: 0,
            currentInnerPage: 1,
            dataRoomItemsCount: 0,
            currentPage: 1
          }));
        }
        else if (roomItemsCountResponse.data != null && !roomItemsCountResponse.data.error) {
          const roomItemsCount = roomItemsCountResponse.data;
          const perPage = 10;

          CommonUtils.apiCall("get", "private", `shared/getDataRoomItems/0/0/0/${perPage}`, {}, config) // from inner screen for root screen we load
            .then(dataRoomParentResponse => {
              if (Array.isArray(dataRoomParentResponse.data)) {
                CommonUtils.apiCall("get", "private", `${callURL(0)}/0?cnt`, {}, config)
                  .then(innerItemsCountResponse => {
                    if (innerItemsCountResponse.data != null && parseInt(innerItemsCountResponse) === 0) {
                      dispatch(getDataRoomInnerItemsSuccess({
                        dataRoomItems: dataRoomParentResponse.data,
                        dataRoomInnerItems: []
                      }));
                    }
                    else if (innerItemsCountResponse.data != null) {
                      const innerItemsCount = innerItemsCountResponse.data;
                      CommonUtils.apiCall("get", "private", `${callURL(0)}/${innerItemsCount}`, {}, config)
                        .then(dataRoomInnerResponse => {
                          if (Array.isArray(dataRoomInnerResponse.data)) {
                            dispatch(getDataRoomInnerItemsSuccess({
                              dataRoomItems: dataRoomParentResponse.data,
                              dataRoomInnerItems: dataRoomInnerResponse.data
                            }));
                          }
                          else {
                            dispatch(getDataRoomInnerItemsFail(dataRoomInnerResponse.data.error));
                          }
                        })
                        .catch(() => dispatch(getDataRoomInnerItemsFail("Error")));
                    }
                    else {
                      dispatch(getDataRoomInnerItemsFail("Error"));
                    }
                  })
                  .catch(() => dispatch(getDataRoomInnerItemsFail("Error")));
              }
              else {
                dispatch(getDataRoomInnerItemsFail(dataRoomParentResponse.data.error));
              }
            })
            .catch(() => dispatch(getDataRoomInnerItemsFail("Error")));
        }
        else {
          dispatch(getDataRoomInnerItemsFail(roomItemsCountResponse.data.error));
        }
      })
      .catch(() => dispatch(getDataRoomInnerItemsFail("Error")));
  }*/
});

export const addDataRoomItem = (parent, name, type, file, business, isPrivate, accessIDs) => ((dispatch, getState) => {
  dispatch(addDataRoomItemStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  const params = {
    parent: parent ? parent : 0,
    business: business ? business : 0,
    private: isPrivate ? 1 : 0,
    name,
    type
  };

  if (type === "file") {
    params.file = file;
  }

  CommonUtils.apiCall("post", "private", "shared/addDataRoomItem", params, config)
    .then(addNewItemResponse => {
      if (addNewItemResponse.data.success) {
        window.location.reload();
        /*if (accessIDs) {
          accessIDs.map(element => {
            dispatch(addAccessToDataRoomItem({
              id: addNewItemResponse.data.success,
              to: element
            }));
          });
          window.location.reload(); // reloading screen for not making mess in code
        }
        else {
          window.location.reload(); // reloading screen for not making mess in code
        }*/
        /*CommonUtils.apiCall("get", "private", `shared/getDataRoomItems/${params.business}/${params.parent}/0/0`, {}, config)
          .then(dataRoomItemResponse => {
            if (Array.isArray(dataRoomItemResponse.data)) {
              dispatch(addDataRoomItemSuccess({
                [params.parent ? "dataRoomItems" : "dataRoomInnerItems"]: dataRoomItemResponse.data
              }));
            }
            else {
              dispatch(addDataRoomItemFail(dataRoomItemResponse.data.error));
            }
          })
          .catch(() => {
            dispatch(addDataRoomItemFail("Error"));
          })*/
      }
      else {
        dispatch(addDataRoomItemFail(addNewItemResponse.data.error));
      }
    })
    .catch(() => {
      dispatch(addDataRoomItemFail("Error"));
    });
});

export const setDataRoomItemAccessType = (params, parent, business) => ((dispatch, getState) => {
  dispatch(setDataRoomItemAccessTypeStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "shared/setDataRoomItemAccessType", params, config)
    .then((accessResponse) => {
      if (accessResponse.data.success) {
        dispatch(getDataRoomInnerItems(parent, business, true));
      }
      else {
        dispatch(setDataRoomItemAccessTypeFail(accessResponse.data.error));
      }
    })
    .catch(() => dispatch(setDataRoomItemAccessTypeFail("Error")));
});

export const removeDataRoomItem = (id, parent) => ((dispatch, getState) => {
  dispatch(removeDataRoomItemStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "shared/removeDataRoomItem", {id}, config)
    .then(removeItemResponse => {
      if (removeItemResponse.data.success) {
        CommonUtils.apiCall("get", "private", `shared/getDataRoomItems/0/${parent}/0/0`, {}, config)
          .then(dataRoomItemResponse => {
            window.location.reload(); // reloading screen for not making mess in code
            /*if (Array.isArray(dataRoomItemResponse.data)) {
              dispatch(removeDataRoomItemSuccess({
                [parent === 0 ? "dataRoomItems" : "dataRoomInnerItems"]: dataRoomItemResponse.data
              }));
            }
            else {
              dispatch(removeDataRoomItemFail(dataRoomItemResponse.data.error));
            }*/
          })
          .catch(() => {
            dispatch(removeDataRoomItemFail("Error"));
          })
      }
      else {
        dispatch(removeDataRoomItemFail(removeItemResponse.data.error));
      }
    })
    .catch(() => {
      dispatch(removeDataRoomItemFail("Error"));
    })
});

export const renameDataRoomItem = (id, name, parent) => ((dispatch, getState) => {
  dispatch(renameDataRoomItemStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  const params = { id, name };

  CommonUtils.apiCall("post", "private", "shared/renameDataRoomItem", params, config)
    .then(renameDataRoomItemResponse => {
      if (renameDataRoomItemResponse.data.success) {
        window.location.reload(); // reloading screen for not making mess in code
        /*CommonUtils.apiCall("get", "private", `shared/getDataRoomItems/0/${parent}/0/0`, {}, config)
          .then(dataRoomItemResponse => {
            if (Array.isArray(dataRoomItemResponse.data)) {
              dispatch(renameDataRoomItemSuccess({
                [parent === 0 ? "dataRoomItems" : "dataRoomInnerItems"]: dataRoomItemResponse.data
              }));
            }
            else {
              dispatch(renameDataRoomItemFail(dataRoomItemResponse.data.error));
            }
          })
          .catch(() => {
            dispatch(renameDataRoomItemFail("Error"));
          })*/
      }
      else {
        dispatch(renameDataRoomItemFail(renameDataRoomItemResponse.data.error));
      }
    })
    .catch(() => {
      dispatch(renameDataRoomItemFail("Error"));
    })
});

export const downloadDataRoomItem = (token, year, month, day, id, fileName) => ((dispatch, getState) => {
  // dispatch(downloadDataRoomItemStart());
  axios({
    url: `${SERVER_URL}/public/shared/download/${token}/${year}/${month}/${day}/${id}`,
    method: 'GET',
    responseType: 'blob'
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  });

  /*CommonUtils.apiCall("get", "public", `shared/download/${token}/${year}/${month}/${day}/${id}`, {}, null)
    .then(response => {
      /!*const contentType = response.headers["content-type"];
      let element = document.createElement("a");

      element.setAttribute("href", `data:${contentType},${encodeURIComponent(response.data)}`);
      element.setAttribute("download", fileName);

      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);*!/

      /!*const blob = new Blob([response.data], {type: response.headers["content-type"]});
      const url = window.URL.createObjectURL(blob);
      window.open(url);*!/
      /!**!/
    })
    .catch(() => {

    })*/
});

export const addAccessToDataRoomItem = params => ((dispatch, getState) => {
  dispatch(addAccessToDataRoomItemStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "shared/addAccessToDataRoomItem", params, config)
    .then(response => {
      dispatch(addAccessToDataRoomItemSuccess());
    })
    .catch(() => dispatch(addAccessToDataRoomItemFail("Error")));
});

export const removeAccessToDataRoomItem = params => ((dispatch, getState) => {
  dispatch(removeAccessToDataRoomItemStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "shared/removeAccessToDataRoomItem", params, config)
    .then(response => {
      dispatch(removeAccessToDataRoomItemSuccess());
    })
    .catch(() => dispatch(removeAccessToDataRoomItemFail("Error")));
});

export const searchBusinessRoomUsers = (business, room) => ((dispatch, getState) => {
  dispatch(searchBusinessRoomUsersStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `admin/searchBusinessRoomUsers/${business}/${room}/0/100`, {}, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(searchBusinessRoomUsersSuccess({
          usersSearchRoomList: response.data
        }))
      }
      else {
        dispatch(searchBusinessRoomUsersFail(response.data.error));
      }
    })
    .catch(() => dispatch(searchBusinessRoomUsersFail("Error")));
});

export const searchRoomUsers = (currentPage, room) => ((dispatch, getState) => {
  dispatch(searchRoomUsersStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };


    CommonUtils.apiCall("get", "private", `admin/searchRoomUsers/${room}/0/100?cnt`, {},  config)
    .then(usersCountResponse => {
      if (usersCountResponse.data !== null && parseInt(usersCountResponse.data) === 0) {
        dispatch(searchRoomUsersSuccess({
          usersSearchRoomList: [],
          totalUsersSearchRoom: 0,
        }));
      }
      else if (usersCountResponse.data !== null && !usersCountResponse.data.error) {
        const perPage = 100;
        const offset = (currentPage - 1) * perPage;
        const totalUsersSearchRoom = usersCountResponse.data;

        CommonUtils.apiCall("get", "private", `admin/searchRoomUsers/${room}/${offset}/${perPage}`, {}, config)
          .then(response => {
            if (Array.isArray(response.data)) {
              const scrolledUsers = response.data;
              const oldUsers = getState().dataRoom.usersSearchRoomList ? getState().dataRoom.usersSearchRoomList : [];
              const updatedUsers = currentPage === 1 ? scrolledUsers : oldUsers.concat(scrolledUsers);

              dispatch(searchRoomUsersSuccess({
                usersSearchRoomList: updatedUsers,
                totalUsersSearchRoom,
              }));
              dispatch(setUsersSearchRoomPagination(currentPage + 1));
            }
            else {
              dispatch(searchRoomUsersFail(response.data.error));
            }
          })
          .catch(() => dispatch(searchRoomUsersFail("Error")));
      }
      else {
        dispatch(searchRoomUsersFail(usersCountResponse.data.error));
      }
    })
    .catch(() => dispatch(searchRoomUsersFail("Error")));
});

export const getSharedDataRoomItems = (userID, hardCodePage) => ((dispatch, getState) => {
  dispatch(getSharedDataRoomItemsStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };
  let { sharedItemsCurrentPage } = getState().dataRoom;

  CommonUtils.apiCall("get", "private", `shared/getDataRoomItems/0/0/0/0?cnt&sharedWith=${userID}`, {}, config)
    .then(roomSharedItemsCountResponse => {
      if (roomSharedItemsCountResponse.data != null && parseInt(roomSharedItemsCountResponse.data) === 0) {
        dispatch(getSharedDataRoomItemsSuccess({
          sharedItems: [],
          sharedItemsCount: 0,
          sharedItemsCurrentPage: 1,
        }));
      }
      else if (roomSharedItemsCountResponse.data != null && !roomSharedItemsCountResponse.data.error) {
        const perPage = 10;
        const sharedRoomItemsCount = roomSharedItemsCountResponse.data;
        const offset = (sharedItemsCurrentPage - 1) * perPage;

        CommonUtils.apiCall("get", "private", `shared/getDataRoomItems/0/0/${offset}/${perPage}?sharedWith=${userID}`, {}, config)
          .then(roomSharedItemsResponse => {
            if (Array.isArray(roomSharedItemsResponse.data)) {
              const roomSharedItems = roomSharedItemsResponse.data;
              const oldRoomSharedItems = getState().dataRoom.sharedItems ? getState().dataRoom.sharedItems : [];
              const updatedVirtualDataRoomItems = hardCodePage ? roomSharedItems : oldRoomSharedItems.concat(roomSharedItems);

              dispatch(getSharedDataRoomItemsSuccess({
                sharedItems: updatedVirtualDataRoomItems,
                sharedItemsCount: sharedRoomItemsCount,
                sharedItemsCurrentPage: sharedItemsCurrentPage + 1,
              }));
            } else {
              dispatch(getSharedDataRoomItemsFail(roomSharedItemsResponse.data.error));
            }
          })
          .catch(() => dispatch(getSharedDataRoomItemsFail("Error")));
      }
    })
    .catch(() => dispatch(getSharedDataRoomItemsFail("Error")));
});
