import {
  GET_USER_START, GET_USER_SUCCESS, GET_USER_FAIL,
  REMOVE_USER_DATA
} from "../../constants";

export const getUserStart = () => ({
  type: GET_USER_START
});

export const getUserSuccess = payload => ({
  type: GET_USER_SUCCESS,
  payload
});

export const getUserFail = payload => ({
  type: GET_USER_FAIL,
  payload
});

export const removeUserData = () => ({
  type: REMOVE_USER_DATA
});