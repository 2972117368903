import React, {Component} from "react";
import {connect} from "react-redux";
import {handleLoading} from "../../utils/handleLoading";
import {setOnboardingData} from "../../redux/registration/thunks/registrationThunks";

import history from "../../history";
import {Form} from "antd";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";

class OnboardingFooter extends Component{
  navigationBtnClick = (status) => {
    const { setOnboardingInfo, setOnboardingData, step } = this.props;
    if (step !== 5) {
      setOnboardingInfo(status, (values) => {
        setOnboardingData(values, () => {
          history.push(`/onboarding${status === 'next' ? step + 1 : step - 1}`);
        });
      });
    }
    else {
      history.push(`/onboarding${step - 1}`);
    }
  };

  render() {
    const { step, loading } = this.props;

    return (
      <div className="form_submit btn_group medium-flex-container">
        {
          step !== 1 &&
            <Button
              title="Previous step"
              prefix={<Icon type="key-left" size={12} style={{marginRight: 15}} />}
              size="xl"
              onClick={() => this.navigationBtnClick('prev')}
              theme="lightblue"
              className="btn-bordered radius-8"
              type="button"
            />
        }
        <Form.Item
          className="mb_0"
          style={{
            marginLeft: "24px",
          }}
        >
          <Button
            title={step !== 5 ? 'Save progress' : 'Submit'}
            prefix={<Icon type="checked" size={18} style={{marginRight: 11}} />}
            size="xl"
            theme="blue"
            className="btn-shadow radius-8"
            type="submit"
            loading={handleLoading(loading, "SetOnboardingData")}
          />
        </Form.Item>
        {
          step !== 5 &&
            <Button
              title="Next step"
              suffix={<Icon type="key-right" size={12} style={{marginLeft: 15}} />}
              size="xl"
              onClick={() => this.navigationBtnClick('next')}
              theme="blue"
              className="btn-shadow radius-8"
              type="button"
              disabled={false}
            />
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.registration.loading,
  onboardingData: state.registration.onboardingData,
  optionsList: state.dictionaries.optionsList,
});

const mapDispatchToProps = dispatch => ({
  setOnboardingData: (params, callback) => dispatch(setOnboardingData(params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingFooter);