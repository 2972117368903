import React from 'react';
import { formatNumber } from '../../../../utils/formatNumber';
import { formatDate } from '../../../../utils/formatDate';

const InvestmentData = ({
  isAdmin,
  industry,
  transaction_status,
  country,
  state,
  investment_type,
  total_investment_from,
  total_investment_to,
  currency,
  minimum_investment,
  shareholding,
  shareholding_from,
  shareholding_to,
  diligence_costs,
  pegate_fees,
  pegate_involvement,
  pegate_lead,
  deadline,
  external_cost,
  internal_cost,
  totalReceived,
  totalSharesQuantity,
}) => {
  const data = [
    {
      type: 'General Information',
      condition: true,
      items: [
        { title: 'Industry', condition: true, value: industry },
        { title: 'Country', condition: true, value: country },
        { title: 'Province / State', condition: true, value: state },
        { title: 'Investment type', condition: true, value: investment_type },
      ],
    },
    {
      type: 'Deal Information',
      condition: true,
      items: [
        { title: 'Transaction status', condition: true, value: transaction_status },
        {
          title: 'Total investment',
          condition: total_investment_from && total_investment_to && currency,
          value: `${formatNumber(total_investment_from)} - ${formatNumber(total_investment_to)} ${currency}`,
        },
        {
          title: 'Minimum investment',
          condition: minimum_investment && currency,
          value: `${formatNumber(minimum_investment)} ${currency}`,
        },
        {
          title: 'Shareholding %',
          condition: shareholding_from && shareholding_to,
          value: `${shareholding_from}% - ${shareholding_to}%`,
        },
        { title: 'Deadline to respond', condition: true, value: formatDate(deadline) },
      ],
    },
    {
      type: 'My Activity',
      condition: !isAdmin() && (totalReceived || shareholding || external_cost || internal_cost),
      items: [
        // {
        //   title: 'My total investment',
        //   condition: totalReceived,
        //   value: `${formatNumber(totalReceived)} ${currency}`,
        // },
        {
          title: 'My total investment (shares quantity)',
          condition: totalSharesQuantity,
          value: formatNumber(totalSharesQuantity),
        },
        {
          title: 'Shareholding %',
          condition: shareholding,
          value: `${shareholding}%`,
        },
        {
          title: 'Shares purchased in Secondary market',
          condition: external_cost || internal_cost,
          value: `${formatNumber(external_cost + internal_cost)} ${currency}`,
        },
      ],
    },
    {
      type: 'Transaction Information',
      condition: true,
      items: [
        {
          title: 'Transaction Costs',
          condition: diligence_costs,
          value: `${formatNumber(diligence_costs)} ${currency}`,
        },
        {
          title: 'PE-GATE Fees',
          condition: pegate_fees,
          value: isNaN(pegate_fees) ? pegate_fees : `${formatNumber(pegate_fees)} ${currency}`,
        },
        {
          title: 'PE-GATE Involvement',
          condition: pegate_involvement,
          value: isNaN(pegate_involvement) ? pegate_involvement : `${formatNumber(pegate_involvement)} ${currency}`,
        },
        {
          title: 'PE-GATE Lead',
          condition: pegate_lead,
          value: isNaN(pegate_lead) ? pegate_lead : `${formatNumber(pegate_lead)} ${currency}`,
        },
      ],
    },
  ];

  return (
    <div className='project_data' >
      {
        data.map((category, index) => (
          category.condition
            ? (
              <div key={index} className='project_category'>
                <div className='custom-headline text text-16 font-bold color-grey'>
                  <h3>{category.type}</h3>
                </div>
                {
                  category.items.map((item, index) => (
                    item.condition ?
                    <div key={index} className='medium-flex-container align-justify project_data_row' >
                      <div className='text text-14 font-medium color-grey project_data_col' >{item.title}</div>
                      <div className='text text-14 font-bold color-grey project_data_col' >{item.value}</div>
                    </div>
                    : null
                  ))
                }
              </div>
            )
            : null
        ))
      }
    </div>
  );
};

export default InvestmentData;
