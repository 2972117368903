import { convertToUTC } from "./convertToUTC"
import moment from "moment";

export const compareTimes = (time) => {
  const timeRightNow = moment(convertToUTC(new Date()))
  const endTime = moment(time)
  return timeRightNow.isBefore(endTime)
}

export const getLeftDays = (closedTime) => {
  const timeRightNow = moment(convertToUTC(new Date())).format("X")
  const endTime = moment(closedTime).format("X")
  const newDate = (timeRightNow - endTime) / 60 / 60 / 24;
  return newDate.toFixed(1); 
}

export const sixMonthDiff = (closedTime) => {
  if (closedTime) {
    const endTime = moment(closedTime).add(6, 'months')
    const timeRightNow = moment(convertToUTC(new Date()))
    return timeRightNow.isBefore(endTime)
  }
}

export const calculateTime = (time) => {
  const timeRightNow = moment(convertToUTC(new Date()))
  const endTime = moment(time)
  const duration = moment.duration(endTime.diff(timeRightNow));

  return ({
    days: Math.floor(duration.asDays()),
    hours: Math.floor(duration.asHours()) - Math.floor(duration.asDays()) * 24,
    minutes: Math.floor(duration.asMinutes()) - Math.floor(duration.asHours()) * 60,
    // seconds: Math.floor(duration.asSeconds()) - Math.floor(duration.asMinutes()) * 60,
    weekDay: moment.utc(time).local().format('dddd HH:mm'),
    fullDate: moment.utc(time).local().format('LLLL'),
    valid: timeRightNow.isBefore(endTime),
  })
}
