export const isOnboardingStepFilled = (onboardingData, step, optionsList) => {
  switch (step) {
    case 1:
      return true;
    case 2:
      if (onboardingData) {
        const {
          insider, voting_companies,
          officer_companies,
          investment_amount,
        } = onboardingData;

        if (optionsList) {
          const foundElement = optionsList.find((item) => parseInt(item.type) === 3);
          if (foundElement.options.length !== 0) {
            // if all this fields are not null, this will return true, otherwise -> false
            return insider != null && voting_companies != null && officer_companies != null && investment_amount != null;
          }
          return false;
        }
        return false;
      }
      return false;
    case 3:
      if (onboardingData) {
        const { invested_businesses, past_board_role, future_board_role } = onboardingData;

        if (optionsList) {
          const foundElement = optionsList.find((item) => parseInt(item.type) === 5);
          if (foundElement.options.length !== 0) {
            // if all this fields are not null, this will return true, otherwise -> false
            return invested_businesses != null && past_board_role != null && future_board_role != null;
          }
          return false;
        }
        return false;
      }
      return false;
    case 4:
      if (onboardingData) {
        if (optionsList) {
          const foundElement = optionsList.find((item) => parseInt(item.type) === 5);
          return foundElement.options.length !== 0;
        }
        return false;
      }
      return false;
    case 5:
      if (onboardingData) {
        const { accredited_investor } = onboardingData;

        // if all this fields are not null, this will return true, otherwise -> false
        return accredited_investor != null;
      }

      return false;
    case 6:
      if (onboardingData) {
        const { other_person_authorization, other_person_guarantee, other_person_interest } = onboardingData;

        // if all this fields are not null, this will return true, otherwise -> false
        return other_person_authorization != null && other_person_guarantee != null && other_person_interest != null;
      }
      return false;
    case 7:
      if (onboardingData) {
        const {
          client_id_1,
          document_number,
          issuing_country,
          expiry_date,
          verification_date_1,

          client_id_2,
          credit_file_source,
          reference_number,
          verification_date_2,

          name_birthday,
        } = onboardingData;

        // if all this fields are not null, this will return true, otherwise -> false
        return (client_id_1 != null && document_number != null && issuing_country != null && expiry_date != null && verification_date_1 != null)
          || (client_id_2 != null && credit_file_source != null && reference_number != null && verification_date_2 != null)
          || (name_birthday != null);
      }
      return false;
    default: return;
  }
};
