import CommonUtils from "../../../utils/CommonUtils";
import {getOpportunitiesByType} from "../../../utils/getOpportunitiesByType";
import {reorderCountriesDictionary} from "../../../utils/reorderCountriesDictionary";
import {detectNotEnabledOptions} from "../../../utils/detectNotEnabledOptions";

import {
  getProfileStart, getProfileSuccess, getProfileFail,
  initLoadStart, initLoadSuccess, initLoadFail
} from "../actions/userActions";

export const initLoad = () => ((dispatch, getState) => {
  dispatch(initLoadStart());
  const {xID, xTOKEN, role} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  const filter = role !== "ADMIN" ? { enabled: '' } : {};

  CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt", filter, config)
    .then(businessItemsCountResponse => {
      if (businessItemsCountResponse.data != null) {
        CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt&interested", filter, config)
          .then(likedBusinessItemsCountResponse => {
            if (likedBusinessItemsCountResponse.data != null) {
              const likedBusinessItemsCount = likedBusinessItemsCountResponse.data;
              CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt&pursuing", filter, config)
                .then(pursuingBusinessItemsCountResponse => {
                  if (pursuingBusinessItemsCountResponse.data != null) {
                    const pursuingBusinessItemsCount = pursuingBusinessItemsCountResponse.data;
                    CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt&closed", filter, config)
                      .then(closedBusinessItemsCountResponse => {
                        if (closedBusinessItemsCountResponse.data != null) {
                          CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt&ignored", filter, config)
                            .then(ignoredBusinessTypeItemsCountResponse => {
                              if (ignoredBusinessTypeItemsCountResponse.data != null) {
                                const ignoredBusinessTypeItemsCount = ignoredBusinessTypeItemsCountResponse.data;
                                const closedBusinessItemsCount = closedBusinessItemsCountResponse.data;
                                CommonUtils.apiCall("get", "private", "shared/getMessages/0/0/inbox?cnt&new&sort='created'&desc", {}, config)
                                  .then(unreadMessagesCountResponse => {
                                    if (unreadMessagesCountResponse.data != null) {
                                      CommonUtils.apiCall("get", "private", "shared/getDataRoomItems/0/0/0/0?cnt", {}, config)
                                        .then(dataRoomItemsCountResponse => {
                                          if (dataRoomItemsCountResponse.data != null) {
                                            // const dataRoomItemsCount = dataRoomItemsCountResponse.data;
                                            const unreadMessagesCount = unreadMessagesCountResponse.data;
                                            const businessItemsCount = businessItemsCountResponse.data;
                                            const getBusinessesParams = source => ({
                                              cnt: "",
                                              portfolio: "",
                                              source
                                            });

                                            const apiCalls = [
                                              CommonUtils.apiCall("get", "private", `${role.toLowerCase()}/getProfile`, {}, config),
                                              CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/10`, filter, config),
                                              CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/10`, { ...filter, liked: "" }, config),
                                              CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/10`, { ...filter, pursuing: "" }, config),
                                              CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/10`, {closed: ""}, config),
                                              CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/10`, {...filter, ...getBusinessesParams("PEGATE")}, config),
                                              CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/10`, { ...filter, ...getBusinessesParams("OTHER") }, config),
                                              CommonUtils.apiCall("get", "private", `shared/getStats/${xID}/CAD`, {}, config),
                                              CommonUtils.apiCall("get", "private", `shared/getMessages/0/10/inbox?sort="created"&desc`, {}, config),
                                              CommonUtils.apiCall("get", "private", "shared/getDataRoomItems/0/0/0/10", {}, config),
                                              CommonUtils.apiCall("get", "public", "shared/getOptgroupsWithOptions", {}, null),
                                              CommonUtils.apiCall("get", "public", "shared/getCountries", {}, null)
                                            ];

                                            if (role === "INVESTOR") {
                                              apiCalls.push(
                                                CommonUtils.apiCall("get", "private", `investor/getReports/CAD`, {}, config),
                                                CommonUtils.apiCall("get", "private", 'investor/getOnboarding', {}, config),
                                              );
                                            }

                                            Promise.all(apiCalls)
                                              .then((responses) => {
                                                let loadedData = {};
                                                let needToBreakLoop = false;

                                                for (let i = 0; i < responses.length; i++) {
                                                  const apiConfigUrlArr = responses[i].config.url.split("/");
                                                  const keyWord = apiConfigUrlArr.find(element => element === "public" || element === "private");
                                                  const indexOfKeyWord = apiConfigUrlArr.indexOf(keyWord);
                                                  const callName = apiConfigUrlArr[indexOfKeyWord + 2]; // if we find public or private keywords, after that element callName is far with 2 indexes

                                                  if (responses[i].data.error) {
                                                    switch (callName) {
                                                      // case "getBusinesses":
                                                      case "getProfile":
                                                      case "getOptgroupsWithOptions":
                                                      case "getCountries":
                                                        loadedData = {};
                                                        needToBreakLoop = true;
                                                        dispatch(initLoadFail(responses[i].data.error));
                                                        break;
                                                      case "getMessages":
                                                        loadedData.inboxMessages = [];
                                                        break;
                                                      case "getDataRoomItems":
                                                        loadedData.dashboardScreenDataRoomItems = [];
                                                        loadedData.accessToDataRoom = false;
                                                        break;
                                                      case "getStats":
                                                        loadedData.reports = [];
                                                        loadedData.accessToReports = false;
                                                        break;
                                                      case "getReports":
                                                        loadedData.closedReports = [];
                                                        loadedData.accessToReports = false;
                                                        break;
                                                      default:
                                                    }
                                                    if (needToBreakLoop) {
                                                      break;
                                                    }
                                                  }
                                                  else if (responses[i].data != null) {
                                                    switch (callName) {
                                                      case "getProfile":
                                                        loadedData.userInfo = responses[i].data.success;
                                                        break;
                                                      case "getBusinesses":
                                                        if (Object.entries(responses[i].config.params).length > 1) { // by default we are sending enabled param
                                                          const {source, liked, pursuing, closed} = responses[i].config.params;
                                                          if (source !== undefined) {
                                                            const lowerCasedSource = source.toLowerCase(); // source can be` PEGATE or OTHER
                                                            // loadedData[source === "PEGATE" ? "pegateBusinessesCount" : "otherBusinessesCount"] = responses[i].data;
                                                            loadedData[`${lowerCasedSource}BusinessesCount`] = responses[i].data; // short way
                                                          }
                                                          if (liked !== undefined) {
                                                            loadedData.likedBusinessItems = responses[i].data;
                                                          }
                                                          if (pursuing !== undefined) {
                                                            loadedData.pursuingBusinessItems = responses[i].data;
                                                          }
                                                          if (closed !== undefined) {
                                                            loadedData.closedBusinessItems = responses[i].data;
                                                          }
                                                        }
                                                        else {
                                                          const businesses = responses[i].data;
                                                          const savedBusinesses = getOpportunitiesByType(businesses, "saved");
                                                          const pursuingBusinesses = getOpportunitiesByType(businesses, "pursuing");
                                                          const closedBusinesses = getOpportunitiesByType(businesses, "closed");

                                                          loadedData.businesses = businesses;
                                                          loadedData.savedBusinesses = savedBusinesses;
                                                          loadedData.pursuingBusinesses = pursuingBusinesses;
                                                          loadedData.closedBusinesses = closedBusinesses;
                                                          loadedData.businessesCount = businessItemsCount;
                                                          loadedData.likedBusinessItemsCount = likedBusinessItemsCount;
                                                          loadedData.pursuingBusinessItemsCount = pursuingBusinessItemsCount;
                                                          loadedData.closedBusinessItemsCount = closedBusinessItemsCount;
                                                          loadedData.ignoredBusinessTypeItemsCount = ignoredBusinessTypeItemsCount;
                                                        }
                                                        break;
                                                      case "getOptgroupsWithOptions":
                                                        loadedData.opportunities = detectNotEnabledOptions(responses[i].data);
                                                        break;
                                                      case "getCountries":
                                                        loadedData.countries = reorderCountriesDictionary(responses[i].data);
                                                        break;
                                                      case "getMessages":
                                                        loadedData.inboxMessages = responses[i].data;
                                                        loadedData.unreadMessagesCount = unreadMessagesCount;
                                                        break;
                                                      case "getDataRoomItems":
                                                        loadedData.dashboardScreenDataRoomItems = responses[i].data;
                                                        break;
                                                      case "getStats":
                                                        loadedData.reports = responses[i].data;
                                                        break;
                                                      case "getReports":
                                                        loadedData.closedReports = responses[i].data;
                                                        break;
                                                      case "getOnboarding":
                                                        loadedData.onboardingData = responses[i].data.success;
                                                        break;
                                                      default:
                                                    }
                                                  }
                                                }

                                                if (Object.keys(loadedData).length) { // if loadedData is not empty
                                                  dispatch(initLoadSuccess(loadedData));
                                                }
                                              })
                                              .catch(() => dispatch(initLoadFail("Something went wrong, please try again")));
                                          }
                                          else {
                                            dispatch(initLoadFail("Error"));
                                          }
                                        })
                                        .catch(() => dispatch(initLoadFail("Error")));
                                    }
                                    else {
                                      dispatch(initLoadFail("Error"));
                                    }
                                  })
                                  .catch(() => dispatch(initLoadFail("Error")));
                              }
                              else {
                                dispatch(initLoadFail("Error"));
                              }
                            })
                            .catch(() => dispatch(initLoadFail("Error")));
                        }
                        else {
                          dispatch(initLoadFail("Error"));
                        }
                      })
                      .catch(() => dispatch(initLoadFail("Error")));
                  }
                  else {
                    dispatch(initLoadFail("Error"));
                  }
                })
                .catch(() => dispatch(initLoadFail("Error")));
            }
            else {
              dispatch(initLoadFail("Error"));
            }
          })
          .catch(() => dispatch(initLoadFail("Error")));
      }
      else {
        dispatch(initLoadFail("Error"));
      }
    })
    .catch(() => dispatch(initLoadFail("Error")));
});

export const getUserInfo = () => ((dispatch, getState) => {
  dispatch(getProfileStart());
  const {xID, xTOKEN, role} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `${role.toLowerCase()}/getProfile`, {}, config)
    .then((response) => {
      if (response.data.success) {
        dispatch(getProfileSuccess({userInfo: response.data.success}));
      }
      else {
        dispatch(getProfileFail("Something went wrong, please try again"));
      }
    })
    .catch((profileError) => {
      dispatch(getProfileFail("Something went wrong, please try again"));
    })
});
