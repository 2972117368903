import React from "react";
import {connect} from "react-redux";

import {resetUserRegistrationInfo} from "../../redux/registration/actions/registrationActions";

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import PopUp from "../../components/PopUp/PopUp";

const RegistrationSuccess = (props) => {
  const {from} = props;

  const handleLoginOnClick = () => {
    props.resetUserRegistrationInfo();
  };

  const printRegistrationMessage = () => {
    const { isEmailSent } = props;
    if (isEmailSent) {
      return {
        mainMessage: 'Please check your email to verify and activate your account.',
        secondaryMessage: 'You will not be able to Login until your account is verified.',
      };
    }

    return {
      mainMessage: 'Thank you for registering!',
      secondaryMessage: 'Our customer support will review your application and shortly send you a verification mail.',
    };
  };

  return (
    <PopUp
      className="relative_parent"
      visible
      innerClassNames="shadow-layout bg-white radius-10 relative md top"
    >
      <div className="popup_icon">
        <img src={require("../../assets/img/completed.svg")} alt=""/>
      </div>
      <div className="text-center">
        <div className="text text-28 font-heavy font-spacing-02 color-grey font-uppercase mb_25">
          { from === "activation" ? "Activation completed!" : "Registration completed!" }
        </div>
        {
          from === "registration" &&
            <>
              <div className="text text-20 font-medium color-grey mb_10">
                {printRegistrationMessage().mainMessage}
              </div>
              <div className="text text-20 font-medium color-grey mb_45">
                {printRegistrationMessage().secondaryMessage}
              </div>
            </>
        }
        <Button
          title={from === "activation" ? "Login" : "OK"}
          prefix={<Icon type="login" style={{fontSize: 18, marginRight: 10}} />}
          size="xl"
          theme="blue"
          className="radius-8"
          linkTo="/signin"
          style={{fontSize: 18}}
          onClick={handleLoginOnClick}
        />
      </div>
    </PopUp>
  );
};

const mapStateToProps = state => ({
  isEmailSent: state.registration.isEmailSent,
});

const mapDispatchToProps = dispatch => ({
  resetUserRegistrationInfo: () => dispatch(resetUserRegistrationInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationSuccess);