
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Input,
  message,
} from "antd";

import { goBack } from "../../../utils/goBack";

import Button from "../../../components/Button/Button";
import Icon from "../../../components/Icon/Icon";
import { handleLoading } from "../../../utils/handleLoading";
import { internalPublish } from "../../../redux/business/thunks/offersThunk";
import NumericInput from "../../../components/NumericInput/NumericInput";

class InternalOffer extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  onSubmit = event => {
    event.preventDefault();
    const offerID = this.props.match.params.id;
    this.props.form.validateFields((err, values) => {
      if (!err) this.props.internalPublish({ offer: offerID, ...values });
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.errorMessage.type === "InternalPublish" && (prevProps.errorMessage.message !== this.props.errorMessage.message && this.props.errorMessage.message)) {
      message.error(this.props.errorMessage.message);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let { match, loading } = this.props;
    const businessID = match.params.id;

    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="flex-container align-middle">
                <Button
                  title={<Icon type="key-left" size={16} />}
                  size="md"
                  onClick={goBack}
                  theme="lightblue"
                  className="radius-10 back_btn"
                />
                <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                  <h1>Publish for Co-Shareholders</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Form onSubmit={this.onSubmit}>
          <div className="row align-center mb_35">
            <div className="column small-12 medium-8">
              <div className="sign_out_form_card add_form bg-white radius-10 shadow-layout">
                <div className="row">
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        Bid Increments
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("step", {
                          rules: [{ required: true, message: "Please fill out this field" }]
                        })(
                          <Input className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                      operating company
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator('operating_company_percent', {
                          rules: [{ required: true, message: "Please fill out this field" }]
                        })(
                          <NumericInput className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                      carried interest
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("carried_interest", {
                          rules: [{ required: true, message: "Please fill out this field" }]
                        })(
                          <NumericInput className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                      pegate fees
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("pegate_fees", {
                          rules: [{ required: true, message: "Please fill out this field" }]
                        })(
                          <NumericInput className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                      legal fees
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("legal_fees", {
                          rules: [{ required: true, message: "Please fill out this field" }]
                        })(
                          <NumericInput className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                      pegate general partner
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("pegate_general_partner", {
                          rules: [{ required: true, message: "Please fill out this field" }]
                        })(
                          <Input className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                      pegate involvement
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("pegate_involvement", {
                          rules: [{ required: true, message: "Please fill out this field" }]
                        })(
                          <Input className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                      portfolio company
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator('portfolio_company', {
                          rules: [{ required: true, message: "Please fill out this field" }]
                        })(
                          <Input className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                      ceo manager
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator('ceo_manager', {
                          rules: [{ required: true, message: "Please fill out this field" }]
                        })(
                          <Input className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                      non pegate ownership
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator('non_pegate_ownership', {
                          rules: [{ required: true, message: "Please fill out this field" }]
                        })(
                          <Input className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12 medium-6">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                      deposit
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator('deposit', {
                          rules: [{ required: true, message: "Please fill out this field" }]
                        })(
                          <NumericInput className="fluid-x" />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="form_submit">
                  <Form.Item className="mb_0">
                    <Button
                      title={`${businessID ? "Edit" : "Finish Creating new"} opportunity`}
                      prefix={<Icon type="checked" style={{ fontSize: 18, marginRight: 10 }} />}
                      size="xl"
                      theme="blue"
                      className="btn-shadow radius-8 font-capitalize"
                      type="submit"
                      loading={handleLoading(loading, "CreateOffers")}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.business.loading,
  errorMessage: state.business.errorMessage,
  opportunityGroups: state.dictionaries.opportunityGroups,
});

const mapDispatchToProps = dispatch => ({
  internalPublish: params => dispatch(internalPublish(params)),
});

const InternalOfferScreen = Form.create({ name: "offerPublish" })(InternalOffer);

export default connect(mapStateToProps, mapDispatchToProps)(InternalOfferScreen);

