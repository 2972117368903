export const getIntShortVersion = (int) => {
    const stingValue = int.toString();

    if(stingValue.length > 4 && stingValue.length < 7) {
      return stingValue.slice(0, -3) + "K";
    } else if(stingValue.length > 6) {
      return stingValue.slice(0, -6) + "M";
    }

    return int;
}