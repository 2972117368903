import {
  emailExistsStart, emailExistsSuccess, emailExistsFail,
  phoneExistsStart, phoneExistsSuccess, phoneExistsFail
} from "../actions/registrationDataActions";
import CommonUtils from "../../../utils/CommonUtils";

export const emailExists = (email, callback) => ((dispatch, getState) => {
  dispatch(emailExistsStart());

  CommonUtils.apiCall("post", "public", "shared/emailExists", { email }, null)
    .then(response => {
      dispatch(emailExistsSuccess({
        emailExists: response.data.success
      }));
      if (callback) {
        callback(response.data.success);
      }
    })
    .catch(() => dispatch(emailExistsFail("Error")));
});

export const phoneExists = (phone, callback) => ((dispatch, getState) => {
  dispatch(phoneExistsStart());

  CommonUtils.apiCall("post", "public", "shared/phoneExists", { phone: phone.replace(/\D/g,'') }, null)
    .then(response => {
      dispatch(phoneExistsSuccess({
        phoneExists: response.data.success
      }));
      if (callback) {
        callback(response.data.success);
      }
    })
    .catch(() => dispatch(phoneExistsFail("Error")));
});
