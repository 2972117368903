import produce from 'immer';
import { TOGGLE_SETTINGS_POPUP } from '../constants';

const initialState = {
  settingsPopupVisible: false,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SETTINGS_POPUP:
      return produce(state, draft => {
        draft.settingsPopupVisible = action.status;
      });
    default:
      return state;
  }
};

export default uiReducer;
