import { getLeftDays } from "./calculateTime";

export const calcFieldsValue = ({
  totalReceived ,shareholding, finalAmount, businessReports, price = 0, businessClosedDate, sharesQty, shareholdingMain, originalSharePrice, investment,
}) => {
  const SPV = finalAmount ? (finalAmount / totalReceived) : 0;
  const operatingCompanyOwned = SPV * shareholding;

  const distributions = businessReports?.length ? businessReports?.reduce((accumulator, object) => {
    return accumulator + object.dividend;
  }, 0) : 0;
  const days = getLeftDays(businessClosedDate);
  const ROI = distributions / finalAmount;
  const ROIAnnualized = distributions ? (Math.pow(Math.abs(1 + ROI), 365 / days) - 1) : 0;
  const RDE = distributions ? (((price - originalSharePrice) + (distributions / finalAmount)) / originalSharePrice) : 0;
  // const RDE = distributions ? (distributions + (price - finalAmount)) / finalAmount : 0;
  const RDEAnnualized = distributions ? Math.pow(((((price - originalSharePrice) + (distributions / finalAmount)) / originalSharePrice) + 1), (365 / days)) - 1 : 0;
  // const RDEAnnualized = distributions ? Math.pow(Math.abs(1 + RDE), 365 / days) - 1 : 0;
  // const businessTotalAmount = (finalAmount * 100) / shareholdingMain;

  return {
    SPV: `${(SPV * 100).toFixed(2)} %`,
    operatingCompanyOwned: `${(operatingCompanyOwned).toFixed(2)} %`,
    finalAmount: finalAmount,
    investment: investment,
    originalSharePrice: 1,
    distributions: distributions,
    ROI: `${(ROI * 100).toFixed(2)} %`,
    ROIAnnualized: `${(ROIAnnualized * 100).toFixed(2)} %`, 
    RDE: `${(RDE * 100).toFixed(2)} %`,  // Return on distributions and expected proceeds
    InvestmentToBeSold: `${((sharesQty / finalAmount) * 100).toFixed(2)} %`,
    RDEAnnualized: `${(RDEAnnualized * 100).toFixed(2)} %`,
  };
};
