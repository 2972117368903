import React, {Component} from "react";
import {connect} from "react-redux";

import PersonalInformationForm from "./PersonalInformationForm";
import RegistrationSteps from "../../layouts/RegistrationSteps/RegistrationSteps";
import RegistrationSuccess from "./RegistrationSuccess";

class Entity2 extends Component {
  getFormInitialValues = () => {
    const {userInfo} = this.props;
    return {
      prefix: userInfo && userInfo.prefix ? userInfo.prefix : null,
      first_name: userInfo && userInfo.first_name ? userInfo.first_name : null,
      last_name: userInfo && userInfo.last_name ? userInfo.last_name : null,
      middle_name: userInfo && userInfo.middle_name ? userInfo.middle_name : null,
      email: userInfo && userInfo.email ? userInfo.email : null,
      linkedin: userInfo && userInfo.linkedin ? userInfo.linkedin : null,
      phone: userInfo && userInfo.phone ? userInfo.phone : null,
      password: userInfo && userInfo.password ? userInfo.password : null
    };
  };

  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { match, isRegisteredSuccessfully } = this.props;
    const { path } = match;
    const getFormInitialValues = this.getFormInitialValues();

    if (isRegisteredSuccessfully) {
      return (
        <RegistrationSuccess from="registration" />
      );
    }
    return (
      <>
        <RegistrationSteps regEntity path={path} />
        <PersonalInformationForm
          showStatusType={false}
          from="entity2"
          isEntity={1}
          initialValues={getFormInitialValues}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  isRegisteredSuccessfully: state.registration.isRegisteredSuccessfully,
  userInfo: state.registration.userInfo
});

export default connect(mapStateToProps)(Entity2);