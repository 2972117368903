import React, {Component} from "react";
import {connect} from "react-redux";
import {getNDA} from "../../redux/business/thunks/ndaThunk";
import {getOnboarding, setOnboardingData} from "../../redux/registration/thunks/registrationThunks";
import {clearOptions, getOptions, setOptions} from "../../redux/dictionaries/thunks/dictionariesOptionsThunk";
import {findDictionaryByID} from "../../utils/findDictionaryByID";
import {getMediaFileFromServer} from "../../utils/getMediaFileFromServer";

import moment from "moment";
import history from "../../history";
import {Form, Input, Radio, Checkbox, DatePicker, message} from "antd";
import TextArea from "antd/es/input/TextArea";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
// import FileCard from "../../components/FileCard/FileCard";
import PopUp from "../../components/PopUp/PopUp";
import SignaturePad from "react-signature-pad";
import OnboardingHeader from "./OnboardingHeader";
import OnboardingFooter from "./OnboardingFooter";

class OnboardingStep8 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNdaCheckboxChecked: false,
      popupNdaSignatureCrime: false,
      signature_crime: "",
      popupNdaSignature: false,
      signature_sanctions: "",
      popupNdaFinalSignature: false,
      final_signature: "",
      popupVisible: false,
    }
  }

  getInitialValues = () => {
    const { onboardingData, optionsList } = this.props;
    let returningData = {
      politic: "",
      other_person_authorization: "",
      other_person_guarantee: "",
      other_person_interest: "",
      certification: [],
      final_comments: "",
      final_name: "",
      final_date: "",
      signature_crime: "",
      signature_sanctions: "",
      final_signature: "",
    };

    if (onboardingData) {
      returningData = {
        politic: onboardingData.politic ? onboardingData.politic : 0,
        other_person_authorization: onboardingData.other_person_authorization ? onboardingData.other_person_authorization : 0,
        other_person_guarantee: onboardingData.other_person_guarantee ? onboardingData.other_person_guarantee : 0,
        other_person_interest: onboardingData.other_person_interest ? onboardingData.other_person_interest : 0,
        final_comments: onboardingData.final_comments ? onboardingData.final_comments : "",
        final_name: onboardingData.final_name ? onboardingData.final_name : "",
        final_date: onboardingData.final_date ? moment(onboardingData.final_date) : moment(),
        signature_crime: onboardingData.signature_crime ? onboardingData.signature_crime : "",
        signature_sanctions: onboardingData.signature_sanctions ? onboardingData.signature_sanctions : "",
        final_signature: onboardingData.final_signature ? onboardingData.final_signature : "",
      };

      if (optionsList && optionsList.length) {
        let accreditedInvestorCertificationArray = [];

        const accreditedInvestorFound = optionsList.find(element => parseInt(element.type) === 33);
        const accreditedInvestorOptions = accreditedInvestorFound ? accreditedInvestorFound.options : [];
        accreditedInvestorOptions.map(element => accreditedInvestorCertificationArray.push(parseInt(element.option)));
        returningData.certification = accreditedInvestorCertificationArray;
      }
    }

    return returningData;
  };

  handleSetOptions = (optionID, fieldName) => {
    const {form, opportunityGroups} = this.props;
    const {getFieldValue} = form;

    const fieldValue = getFieldValue(fieldName);
    const optionsArray = findDictionaryByID(optionID, opportunityGroups);

    if (fieldValue.length) {
      const { setOptions } = this.props;
      let param = {
        optgroup: optionID
      };

      fieldValue.map((element, index) => {
        const currentSelectedOptions = optionsArray.find(item => parseInt(item.id) === parseInt(element));

        param[`options[${index}][id]`] = currentSelectedOptions.id;
        param[`options[${index}][value]`] = 0;

        return currentSelectedOptions;
      });

      setOptions(param);
    }
    else {
      const {clearOptions} = this.props;
      clearOptions(optionID);
    }
  };

  setOnboardingInfo = (isFromSaveBtn = false, callback) => {
    const local_signature_crime = this.state.signature_crime;
    const local_signature_sanctions = this.state.signature_sanctions;
    const { signature_crime, signature_sanctions } = this.getInitialValues();

    if ((signature_crime !== "" && signature_sanctions !== "") || (local_signature_crime !== "" && local_signature_sanctions !== "")) {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const {signature_crime, signature_sanctions, final_signature} = this.state;

          delete values.certification;
          values.signature_crime = signature_crime;
          values.signature_sanctions = signature_sanctions;
          values.final_signature = final_signature;

          values.politic = values.politic ? values.politic : "";
          values.final_comments = values.final_comments ? values.final_comments : "";
          values.final_name = values.final_name ? values.final_name : "";
          values.final_date = values.final_date ? values.final_date.format("YYYY-MM-DD") : "";

          this.handleSetOptions(33, "certification");

          callback(values);
          if (isFromSaveBtn) {
            window.scroll(0, 0);
            this.toggleThankModal(true);
          }
        }
      }); 
    } else {
      message.error("Please set signatures");
    }
  };

  onSubmit = event => {
    event.preventDefault();
    const { setOnboardingData } = this.props;

    this.setOnboardingInfo(true,(values) => {
      setOnboardingData(values);
    });
  };

  handleNdaCheckboxOnChange = e => {
    this.setState({
      isNdaCheckboxChecked: e.target.checked
    })
  };

  showSignaturePopUp = signatureStateType => {
    this.setState({
      [signatureStateType]: true
    }, () => {
      const { signature } = this.refs;
      switch (signatureStateType) {
        case "popupNdaSignatureCrime":
          signature.fromDataURL(this.state.signature_crime);
          break;
        case "popupNdaSignature":
          signature.fromDataURL(this.state.signature_sanctions);
          break;
        case "popupNdaFinalSignature":
          signature.fromDataURL(this.state.final_signature);
          break;
        default:
      }
      window.scroll(0, 0);
    });
  };

  hideNdaPopUp = () => {
    this.setState({
      popupNdaSignatureCrime: false,
      popupNdaSignature: false,
      popupNdaFinalSignature: false
    }, () => {
      const { signature } = this.refs;
      signature.clear();
    })
  };

  getNDAOnClick = () => {
    const {getNDA} = this.props;
    getNDA();
  };

  signNdaOnClick = () => {
    const {popupNdaSignatureCrime, popupNdaSignature} = this.state;
    const { signature } = this.refs;
    const signatureImage = signature.toDataURL();

    const signatureType = popupNdaSignatureCrime ? "signature_crime" : popupNdaSignature ? "signature_sanctions" : "final_signature";
    const popupVisibilityStateName = signatureType === "signature_crime" ? "popupNdaSignatureCrime" :
                                    signatureType === "signature_sanctions" ?
                                    "popupNdaSignature" : "popupNdaFinalSignature";

    this.setState({
      [signatureType]: signatureImage,
      [popupVisibilityStateName]: false,
      isNdaCheckboxChecked: false
    }, () => {
      const { signature } = this.refs;
      signature.clear();
    });
  };

  toggleThankModal = status => {
    this.setState({
      popupVisible: status
    }, () => {
      if (!status) {
        history.push("/");
      }
    })
  };

  resizeCanvas() {

  }

  componentDidUpdate(prevProps, prevState) {
    const {loading, errorMessage} = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    // const errorType = errorMessage.type;
    const errorText = errorMessage.message;

    if (!errorText && !loadingState && prevProps.loading.state) {
      if (loadingType === "SetOnboardingData") {
        message.success("Saved");
      }
    }
  }

  componentDidMount() {
    window.scroll(0, 0);
    const {getOnboarding, /*getOptions */} = this.props;

    getOnboarding();
    // getOptions(33);

    window.addEventListener("resize", this.resizeCanvas);
  }

  componentWillUnmount() {
    window.remove("resize", this.resizeCanvas);
  }

  render() {
    const { form, opportunityGroups } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const {
      isNdaCheckboxChecked,
      popupNdaSignatureCrime,
      popupNdaSignature,
      popupNdaFinalSignature,
      popupVisible,
    } = this.state;
    const signature_crime_state = this.state.signature_crime;
    const signature_sanctions_state = this.state.signature_sanctions;
    const final_signature_state = this.state.final_signature;

    const certificationSelectedOptions = getFieldValue('certification') ? getFieldValue('certification') : [];
    const hasCertificationSpecificOptionSelected = certificationSelectedOptions.includes(179);

    const accreditedInvestorCertification = findDictionaryByID(33, opportunityGroups);
    const {
      politic,
      other_person_authorization,
      other_person_guarantee,
      other_person_interest,
      certification,
      final_comments,
      final_name,
      final_date,
      signature_crime,
      signature_sanctions,
      final_signature,
    } = this.getInitialValues();

    return (
      <OnboardingHeader step={5} >
        <div className="row">
          <div className="column small-12 x-large-10">
            <Form onSubmit={this.onSubmit}>
              <div className="form_card bg-white radius-10 shadow-layout mb_45">
                <div className="text text-height-30 font-medium color-grey mb_25">
                  <div className="text-20">
                    Anti-money Laundering and Anti-Terrorist financing (AML/ATF) Representations
                  </div>
                  <p>Please confirm the following by initialling where indicated:</p>
                </div>

                <div className="large-11 mb_50">
                  <div className="form-item mb_35">
                    <div className="form-item-label text text-height-22 color-grey mb_10 large-9">
                      To the best of your knowledge, after reasonable inquiry, your assets which may be invested in PE
                      Gate
                      Opportunities: (i) were not obtained or derived, directly or indirectly, as a result of illegal
                      activities,
                      (ii) are not intended to be used, directly or indirectly, in order to carry out a criminal
                      offence,
                      a terrorist activity or for the benefit of a terrorist group, (iii) are not owned or controlled by
                      a
                      terrorist group, and (iv) are not being tendered on behalf of a person or entity who has not been
                      identified to you.
                    </div>
                    <Form.Item>
                      <Button
                        title={!signature_crime_state && !signature_crime ? "Initial to Confirm" : "Signed"}
                        size="lg"
                        theme="blue"
                        className="radius-8"
                        style={{fontSize: 18}}
                        href={signature_crime ? getMediaFileFromServer(signature_crime) : null}
                        target="_blank"
                        onClick={!signature_crime ? () => this.showSignaturePopUp("popupNdaSignatureCrime") : null}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-item mb_35">
                    <div className="form-item-label text text-height-22 color-grey mb_10 large-9">
                      I confirm that I am not identified on a list established under section 83.05 of the Criminal Code (Canada) or
                      in any Regulations made under the United Nations Act (Canada), the Freezing Assets of Corrupt Foreign Officials Act
                      (Canada), the Justice for Victims of Corrupt Foreign Officials Act (Canada), the Special Economic Measures Act (Canada)
                      or any other Canadian statutes or regulations that take legislative measures against terrorist financing, and against
                      financial dealings with certain sanctioned individuals and entities.
                    </div>
                    <Form.Item>
                      <Button
                        title={!signature_sanctions_state && !signature_sanctions ? "Initial to Confirm" : "Signed"}
                        size="lg"
                        theme="blue"
                        className="radius-8"
                        style={{fontSize: 18}}
                        href={signature_sanctions ? getMediaFileFromServer(signature_sanctions) : null}
                        target="_blank"
                        onClick={!signature_sanctions ? () => this.showSignaturePopUp("popupNdaSignature") : null}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-item mb_35">
                    <div className="form-item-label text text-height-22 font-semibold color-grey mb_10 large-8">
                      Politically Exposed Persons (PEP): Are you or a member of your immediate family (i.e. your spouse,
                      child, parent or sibling; your spouse’s parent’s children or siblings) a politically exposed person,
                      a head of international organization or a close associate of a politically exposed person or
                      head of international organization
                    </div>
                    <Form.Item>
                      {getFieldDecorator("politic", {
                        initialValue: politic,
                        rules: [{required: false, message: "Please fill out this field"}]
                      })(
                        <Radio.Group>
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>

                  {/*<div className="form-item mb_35">
                    <div className="form-item-label text text-height-22 font-semibold color-grey mb_10 large-8">
                      Click to expand Appendix A - Politically Exposed Persons (PEP) definitions
                    </div>
                    <div className="small-12 medium-4 large-4">
                      <FileCard
                        className="shadow-layout"
                        title="PDF attachment"
                        desc=""
                        img={<img src={require("../../assets/img/file-pdf.svg")} alt=""/>}
                        downloadIcon
                      />
                    </div>
                  </div>*/}

                  <div className="text text-15 text-height-22 color-grey mb_25 large-10">
                    <div className="text-20 text-height-30 font-medium mb_10">
                      Third Party Determination Statement
                    </div>
                    <p>
                      <i>
                        I confirm that the information provided is correct as of the date set out below and
                        I agree to update this know your client information promptly in the event of a
                        material change of such information.
                      </i>
                    </p>
                  </div>

                  <div className="form-item mb_35">
                    <div className="form-item-label text text-height-22 font-semibold color-grey mb_10 large-8">
                      Will any other person have trading authorization on this account? *
                    </div>
                    <Form.Item>
                      {getFieldDecorator("other_person_authorization", {
                        initialValue: other_person_authorization,
                        rules: [{required: true, message: "Please fill out this field"}]
                      })(
                        <Radio.Group>
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>
                  <div className="form-item mb_35">
                    <div className="form-item-label text text-height-22 font-semibold color-grey mb_10 large-8">
                      Will any other person guarantee this account? *
                    </div>
                    <Form.Item>
                      {getFieldDecorator("other_person_guarantee", {
                        initialValue: other_person_guarantee,
                        rules: [{required: true, message: "Please fill out this field"}]
                      })(
                        <Radio.Group>
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>
                  <div className="form-item mb_35">
                    <div className="form-item-label text text-height-22 font-semibold color-grey mb_10 large-8">
                      Will any other person have a financial interest in this account? *
                    </div>
                    <Form.Item>
                      {getFieldDecorator("other_person_interest", {
                        initialValue: other_person_interest,
                        rules: [{required: true, message: "Please fill out this field"}]
                      })(
                        <Radio.Group>
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>
                </div>

                <div className="large-11 mb_35">
                  <div className="form-item">
                    <div className="form-item-label text text-height-22 font-semibold color-grey mb_10 large-10">
                      CERTIFICATION
                    </div>
                    <div className="text text-15 text-height-22 color-grey mb_25">
                      I certify that I am (select all that apply): *
                    </div>
                  </div>
                  <div className="form-item form-item-lg mb_15">
                    <Form.Item className="mb_0">
                      {getFieldDecorator("certification", {
                        initialValue: certification,
                        rules: [{required: true, message: "Please fill out this field"}]
                      })(
                        <Checkbox.Group
                          options={accreditedInvestorCertification.map(element => ({
                            label: element.name,
                            value: element.id,
                            disabled: hasCertificationSpecificOptionSelected && element.id !== 179 ? true : !hasCertificationSpecificOptionSelected && element.id === 179 && certificationSelectedOptions.length,
                          }))}
                        />
                      )}
                    </Form.Item>
                  </div>

                  <div className="text text-15 text-height-22 color-grey mb_25 large-10">
                    I confirm that the information provided is correct as of the date set out below and
                    I agree to update this know your client information promptly in the event of a
                    material change of such information.
                  </div>

                  <div className="large-6">
                    <div className="form-item">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        Any questions or comments:
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("final_comments", {
                          initialValue: final_comments,
                          rules: [{required: false, message: "Please fill out this field"}],
                        })(
                          <TextArea rows={10} />
                        )}
                      </Form.Item>
                    </div>
                    <div className="form-item">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        Your name
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("final_name", {
                          initialValue: final_name,
                          rules: [{required: false, message: "Please fill out this field"}],
                        })(
                          <Input/>
                        )}
                      </Form.Item>
                    </div>
                    <div className="form-item">
                      <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
                        Signature
                      </div>
                      <Form.Item>
                        <Button
                          title={!final_signature_state && !final_signature ? "Sign Here" : "Signed"}
                          size="lg"
                          theme="blue"
                          className="radius-8"
                          style={{fontSize: 18}}
                          href={final_signature ? getMediaFileFromServer(final_signature) : null}
                          target="_blank"
                          onClick={!final_signature ? () => this.showSignaturePopUp("popupNdaFinalSignature") : null}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-item">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        Date
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator("final_date", {
                          initialValue: final_date,
                          rules: [{required: false, message: "Please fill out this field"}],
                        })(
                          <DatePicker/>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              <OnboardingFooter
                step={5}
                setOnboardingInfo={(callback) => this.setOnboardingInfo(false, callback)}
              />
            </Form>

            <PopUp
              className="relative_parent"
              visible={popupNdaSignatureCrime || popupNdaSignature || popupNdaFinalSignature}
              innerClassNames="shadow-layout bg-white radius-10 relative lg base"
            >
              <div
                onClick={this.hideNdaPopUp}
                className="popup_close color-grey-700"
              >
                <Icon type="cancel"/>
              </div>
              <div className="popup_icon">
                <img src={require("../../assets/img/edit.svg")} alt=""/>
              </div>
              <div className="text-center">
                <div className="text text-28 font-heavy font-spacing-02 color-grey font-uppercase mb_25">
                  {!popupNdaSignatureCrime && !popupNdaSignature ? 'SIGN BELOW TO CONFIRM' : 'PLEASE SIGN TO PROCEED'}
                </div>
                {
                  !popupNdaSignatureCrime && !popupNdaSignature && !popupNdaFinalSignature ?
                    <div className="popup_icon_file">
                      <Button
                        title="Non disclosure agreement"
                        prefix={<Icon type="file" style={{marginRight: 10}} />}
                        size="lg"
                        theme="white"
                        className="btn-link"
                        onClick={this.getNDAOnClick}
                        // href={NDA_PDF_URL}
                        style={{fontSize: 18}}
                        // target="_blank"
                      />
                    </div>
                  : null
                }

                <div className="radius-8 nda_img">
                  <SignaturePad
                    ref="signature"
                    clearButton={true}
                  />
                  {/*<img src={require("../../assets/img/image-2.png")} alt=""/>*/}
                </div>

                <div className="flex-container align-center form-item-lg nda_checkbox">
                  <Checkbox
                    checked={isNdaCheckboxChecked}
                    className="flex-container align-middle"
                    onChange={this.handleNdaCheckboxOnChange}
                  >
                    <span className="text text-18 font-semibold color-grey">
                      {
                        !popupNdaSignatureCrime && !popupNdaSignature ?
                          'I confirm the information provided is accurate'
                        :
                          'I am ready to sign'
                      }
                    </span>
                  </Checkbox>
                </div>

                <Button
                  type="submit"
                  disabled={!isNdaCheckboxChecked}
                  title={!popupNdaSignatureCrime && !popupNdaSignature ? 'Submit' : 'Sign'}
                  size="xl"
                  theme="blue"
                  className="radius-8"
                  style={{fontSize: 18}}
                  onClick={this.signNdaOnClick}
                />
              </div>
            </PopUp>

            <PopUp
              className="relative_parent"
              visible={popupVisible}
              innerClassNames="shadow-layout bg-white radius-10 relative sm base"
            >
              <div className="popup_icon">
                <img src={require("../../assets/img/completed.svg")} alt=""/>
              </div>
              <div className="text-center">
                {/*<div className="text text-28 text-height-33 font-heavy font-spacing-02 color-grey font-uppercase mb_25">
                  Thank you!
                </div>*/}
                <div className="flex-container align-center">
                  <div className="text text-20 text-height-30 font-medium color-grey mb_45 large-10">
                    Thank you for submitting your Onboarding Investor Application. A PE Gate admin will review your application and reach out within the next 48 hours.
                  </div>
                </div>

                <Button
                  title="OK"
                  size="xl"
                  theme="blue"
                  className="radius-8"
                  style={{fontSize: 18}}
                  onClick={() => this.toggleThankModal(false)}
                />
              </div>
            </PopUp>
          </div>
        </div>
      </OnboardingHeader>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.registration.loading,
  errorMessage: state.registration.errorMessage,
  opportunityGroups: state.dictionaries.opportunityGroups,
  optionsList: state.dictionaries.optionsList,
  onboardingData: state.registration.onboardingData
});

const mapDispatchToProps = dispatch => ({
  getNDA: () => dispatch(getNDA()),
  setOnboardingData: params => dispatch(setOnboardingData(params)),
  getOnboarding: () => dispatch(getOnboarding()),
  setOptions: params => dispatch(setOptions(params)),
  getOptions: optionGroup => dispatch(getOptions(optionGroup)),
  clearOptions: optionGroup => dispatch(clearOptions(optionGroup))
});

const OnboardingStep8Screen = Form.create({ name: "OnboardingStep8" })(OnboardingStep8);

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingStep8Screen);