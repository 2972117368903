import produce from 'immer';
import {
  INIT_LOAD_SUCCESS, SET_MESSAGES_FILTER, RESET_MESSAGES_FILTER,
  GET_MESSAGES_START, GET_MESSAGES_SUCCESS, GET_MESSAGES_FAIL,
  GET_MESSAGE_START, GET_MESSAGE_SUCCESS, GET_MESSAGE_FAIL,
  ADD_MESSAGE_START, ADD_MESSAGE_SUCCESS, ADD_MESSAGE_FAIL,
  SET_MESSAGES_TABLE_PAGINATION, SET_MESSAGES_CURRENT_TAB
} from "../constants";

const initialState = {
  loading: {
    type: "",
    state: false
  },
  errorMessage: {
    type: "",
    message: ""
  },
  inboxMessages: null,
  sentMessages: null,
  inboxMessagesCount: 0,
  sentMessagesCount: 0,
  currentOpenedMessage: null,
  unreadMessagesCount: 0,
  filter: {},
  pagination: {
    inbox: {
      currentPage: 1
    },
    sent: {
      currentPage: 1
    }
  },
  currentMessageTab: "inbox"
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGES_FILTER:
      return produce(state, draft => {
        draft.filter[action.payload.filterType] = action.payload.filterValue;
      });
    case RESET_MESSAGES_FILTER:
      return produce(state, draft => {
        draft.filter = {};
      });
    case INIT_LOAD_SUCCESS:
      return produce(state, draft => {
        action.payload.hasOwnProperty("inboxMessages") && (draft.inboxMessages = action.payload.inboxMessages);
        action.payload.hasOwnProperty("unreadMessagesCount") && (draft.unreadMessagesCount = action.payload.unreadMessagesCount);
      });
    case GET_MESSAGES_START:
      return produce(state, draft => {
        draft.loading.type = "GetMessages";
        draft.loading.state = true;
      });
    case GET_MESSAGES_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        action.payload.hasOwnProperty("inboxMessages") && (draft.inboxMessages = action.payload.inboxMessages);
        action.payload.hasOwnProperty("inboxMessagesCount") && (draft.inboxMessagesCount = action.payload.inboxMessagesCount);
        action.payload.hasOwnProperty("sentMessages") && (draft.sentMessages = action.payload.sentMessages);
        action.payload.hasOwnProperty("sentMessagesCount") && (draft.sentMessagesCount = action.payload.sentMessagesCount);
        action.payload.hasOwnProperty("unreadMessagesCount") && (draft.unreadMessagesCount = action.payload.unreadMessagesCount);
        action.payload.hasOwnProperty("filter") && (draft.filter = action.payload.filter);
        action.payload.hasOwnProperty("inboxCurrentPage") && (draft.pagination.inbox.currentPage = action.payload.inboxCurrentPage);
        action.payload.hasOwnProperty("sentCurrentPage") && (draft.pagination.sent.currentPage = action.payload.sentCurrentPage);
      });
    case GET_MESSAGES_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case GET_MESSAGE_START:
      return produce(state, draft => {
        draft.loading.type = "GetMessage";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_MESSAGE_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentOpenedMessage = action.payload.currentOpenedMessage;
      });
    case GET_MESSAGE_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetMessage";
        draft.errorMessage.message = action.payload;
      });
    case ADD_MESSAGE_START:
      return produce(state, draft => {
        draft.loading.type = "AddMessage";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ADD_MESSAGE_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        // draft.sentMessages = action.payload.sentMessages;
      });
    case ADD_MESSAGE_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "AddMessage";
        draft.errorMessage.message = action.payload;
      });
    case SET_MESSAGES_TABLE_PAGINATION:
      return produce(state, draft => {
        draft.pagination[action.payload.messageType].currentPage = action.payload.currentPage;
      });
    case SET_MESSAGES_CURRENT_TAB:
      return produce(state, draft => {
        draft.currentMessageTab = action.payload;
      });
    default:
      return state;
  }
};

export default messagesReducer;