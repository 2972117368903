import { LOGOUT_START, LOGOUT_SUCCESS, LOGOUT_FAIL } from '../../constants';

export const logoutStart = () => ({ type: LOGOUT_START });

export const logoutSuccess = (payload) => ({
  type: LOGOUT_SUCCESS,
  payload,
});

export const logoutFail = (payload) => ({
  type: LOGOUT_FAIL,
  payload,
});
