import CommonUtils from "../../../utils/CommonUtils";
import { logoutStart, logoutSuccess, logoutFail } from "../actions/logoutActions";

export const logout = (id, isFromAdmin) => ((dispatch, getState) => {
  dispatch(logoutStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "shared/logout", { id }, config)
    .then((response) => {
      if (response.data.success) {
        if (!isFromAdmin) {
          localStorage.clear();
          window.location.replace("/"); 
        } else {
          dispatch(logoutSuccess({}));
        }
      } else {
        dispatch(logoutFail("Error"));
      }
    })
    .catch(() => dispatch(logoutFail("Error")));
});
