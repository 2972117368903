import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import {createStore, applyMiddleware} from "redux";
import reducer from "./redux/index";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from 'redux-thunk';
import {Router} from "react-router-dom";
import history from "./history";
import { EXPIRED_TOKEN_RESPONSE, INVALID_TOKEN_RESPONSE } from "./utils/constants";
import { logoutLocally } from "./utils/logoutLocally";

const tokenMiddleware = () => ((next) => (action) => {
  if (action.payload === EXPIRED_TOKEN_RESPONSE || action.payload === INVALID_TOKEN_RESPONSE) {
    logoutLocally();
  }
  return next(action);
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk, tokenMiddleware)));

ReactDOM.render(
  <Provider store={store} >
      <Router history={history}>
        <App />
      </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
