import React, {Component} from 'react';
import './App.scss';

import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

class App extends Component {

  isLoggedIn = () => {
    return localStorage.getItem("xID") && localStorage.getItem("xTOKEN");
  };

  render() {
    return (
      this.isLoggedIn() ?
        <PrivateRoutes />
        :
        <PublicRoutes />
    );
  }
}

export default App;
