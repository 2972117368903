export const sortOptions = (optionList, idList, idKey) => {
  let retArr = [];

  for(let i = 0; i < idList.length; i++) {
    for(let k = 0; k < optionList.length; k++)
    {
      if(optionList[k][idKey] === idList[i]) {
        retArr.push(optionList[k]);
        break;
      }
    }
  }

  for(let k = 0; k < optionList.length; k++) {
    if(!retArr.includes(optionList[k])) {
      retArr.push(optionList[k]);
    }
  }

  return retArr;
};
