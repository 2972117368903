import React from "react";
import {Link} from "react-router-dom";
import Icon from "../Icon/Icon";
import "./style.scss";

const Button = (props) => {
  const {
    type,
    className,
    theme,
    size,
    mode,
    title,
    disabled,
    onClick,
    style,
    prefix,
    suffix,
    href,
    rel,
    target,
    linkTo,
    loading
  } = props;

  let classNames = [
    "btn",
    className ? className : "",
    theme ? "btn-" + theme : "",
    size ? "btn-" + size : "",
    mode ? "btn-" + mode : "",
    loading ? "btn-loading" : "",
  ];

  return (
    type === 'submit' || type === 'button' ? (
      <button type={type}
              title={title}
              className={classNames.join(" ").trim()}
              onClick={onClick}
              style={style}
              disabled={disabled ? disabled : ""}>

        {
          prefix ? (
            prefix
          ) : null
        }

        {
          loading ? (
            <Icon type="loading" className="btn-spinner" />
          ) : (
            null
          )
        }

          <span>
            {title}
          </span>

        {
          suffix ? (
            suffix
          ) : (
            null
          )
        }
      </button>

    ) : href ? (
      <a href={href}
         type={type}
         title={title}
         className={classNames.join(" ").trim()}
         onClick={onClick}
         style={style}
         disabled={disabled ? disabled : ""}
         target={target}
         rel={rel}>

        {
          prefix ? (
            prefix
          ) : (
            null
          )
        }

        <span>
            {title}
          </span>

        {
          suffix ? (
            suffix
          ) : (
            null
          )
        }
      </a>
    ) : linkTo ? (
      <Link to={linkTo}
            className={classNames.join(" ").trim()}
            style={style}
            onClick={onClick}
      >

        {
          prefix ? (
            prefix
          ) : (
            null
          )
        }

        <span>
            {title}
          </span>

        {
          suffix ? (
            suffix
          ) : (
            null
          )
        }
      </Link>
    ) : (
      <div className={classNames.join(" ").trim()}
           onClick={onClick} style={style}>
        {
          prefix ? (
            prefix
          ) : (
            null
          )
        }

        <span>
            {title}
          </span>

        {
          suffix ? (
            suffix
          ) : (
            null
          )
        }
      </div>
    )
  )
};

export default Button;