import produce from 'immer';
import {
  INIT_LOAD_SUCCESS,
  FILL_USER_REGISTRATION_INFO, RESET_USER_REGISTRATION_INFO,
  REGISTER_USER_START, REGISTER_USER_SUCCESS, REGISTER_USER_FAIL,
  ACTIVATE_USER_REGISTRATION_START, ACTIVATE_USER_REGISTRATION_SUCCESS, ACTIVATE_USER_REGISTRATION_FAIL,
  SET_ONBOARDING_DATA_START, SET_ONBOARDING_DATA_SUCCESS, SET_ONBOARDING_DATA_FAIL,
  GET_ONBOARDING_START, GET_ONBOARDING_SUCCESS, GET_ONBOARDING_FAIL,
  EMAIL_EXISTS_START, EMAIL_EXISTS_SUCCESS, EMAIL_EXISTS_FAIL,
  PHONE_EXISTS_START, PHONE_EXISTS_SUCCESS, PHONE_EXISTS_FAIL
} from "../constants";

const initialState = {
  loading: {
    type: "",
    state: false
  },
  errorMessage: {
    type: "",
    message: "",
  },
  userInfo: {},
  onboardingData: null,
  isRegisteredSuccessfully: false,
  isEmailSent: false,
  emailExists: null,
  phoneExists: null
};

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_LOAD_SUCCESS:
      return produce(state, draft => {
        action.payload.hasOwnProperty('onboardingData') && (draft.onboardingData = action.payload.onboardingData);
      });
    case FILL_USER_REGISTRATION_INFO:
      return produce(state, draft => {
        draft.userInfo = {
          ...state.userInfo,
          ...action.payload
        }
      });
    case RESET_USER_REGISTRATION_INFO:
      return produce(state, draft => {
        draft.userInfo = {};
        draft.isRegisteredSuccessfully = false;
        draft.isEmailSent = false;
      });
    case REGISTER_USER_START:
      return produce(state, draft => {
        draft.loading.type = "RegisterUser";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case REGISTER_USER_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.userInfo = action.payload.userInfo;
        draft.isRegisteredSuccessfully = true;
        draft.isEmailSent = action.payload.isEmailSent;
      });
    case REGISTER_USER_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "RegisterUser";
        draft.errorMessage.message = action.payload.message;
        draft.userInfo = action.payload.userInfo;
      });
    case ACTIVATE_USER_REGISTRATION_START:
      return produce(state, draft => {
        draft.loading.type = "ActivateUserRegistration";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ACTIVATE_USER_REGISTRATION_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case ACTIVATE_USER_REGISTRATION_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "ActivateUserRegistration";
        draft.errorMessage.message = "Invalid code";
      });
    case SET_ONBOARDING_DATA_START:
      return produce(state, draft => {
        draft.loading.type = "SetOnboardingData";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SET_ONBOARDING_DATA_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case SET_ONBOARDING_DATA_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SetOnboardingData";
        draft.errorMessage.message = action.payload;
      });
    case GET_ONBOARDING_START:
      return produce(state, draft => {
        draft.loading.type = "GetOnboarding";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_ONBOARDING_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.onboardingData = action.payload.onboardingData;
      });
    case GET_ONBOARDING_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetOnboarding";
        draft.errorMessage.message = action.payload;
      });
    case EMAIL_EXISTS_START:
      return produce(state, draft => {
        draft.loading.type = "EmailExists";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case EMAIL_EXISTS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.emailExists = action.payload.emailExists;
      });
    case EMAIL_EXISTS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "EmailExists";
        draft.errorMessage.message = action.payload;
      });
    case PHONE_EXISTS_START:
      return produce(state, draft => {
        draft.loading.type = "PhoneExists";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case PHONE_EXISTS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.phoneExists = action.payload.phoneExists;
      });
    case PHONE_EXISTS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "PhoneExists";
        draft.errorMessage.message = action.payload;
      });
    default:
      return state;
  }
};

export default registrationReducer;