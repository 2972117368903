import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Select, Slider } from "antd";

import { findDictionaryByID } from "../../utils/findDictionaryByID";
import { filterOption } from "../../utils/filterOption";
import { handleLoading } from "../../utils/handleLoading";
import { getStates } from "../../redux/dictionaries/thunks/dictionariesThunks";
import { filterBusinesses } from "../../redux/business/thunks/businessThunk";

import Button from "../../components/Button/Button";
import Filter from "../../layouts/Filter/Filter";
import { getIntShortVersion } from "../../utils/getIntShortVersion";

const { Option } = Select;

const sliderOptions = {
  range: true,
  min: 10000,
  step: 10000,
  max: 500000,
  tipFormatter: function(value) {
    return getIntShortVersion(value);
  }
}

class FilterComponent extends Component {
  onSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {filterBusinesses, filter} = this.props;
        if (values.filterMinInvest.length) {
          values.minInvestmentFrom = values.filterMinInvest[0];
          values.minInvestmentTo = values.filterMinInvest[1];
          delete values.filterMinInvest;
        }
        values.name = filter.name;
        filterBusinesses(values);
      }
    })
  };

  handleCountryOnChange = (values) => {
    if (values[0]) {
      const {getStates} = this.props;
      getStates(values[0]);
    }
    this.props.form.setFieldsValue({ state: [] });
  };

  handleResetAllFilters = () => {
    const { form, filterBusinesses, filter } = this.props;
    const fieldsValues = form.getFieldsValue();
    let changedValuesCount = 0;

    Object.keys(fieldsValues).map(key => {
      if (fieldsValues[key].length) {
        changedValuesCount++;
      }

      return "";
    });

    if (changedValuesCount !== 0) {
      const emptyFilter = {
        industry: [],
        transaction_status: [],
        country: [],
        state: [],
        investment_type: [],
        filterMinInvest: [],
        name: filter.name,
      };
      filterBusinesses(emptyFilter);
      form.resetFields();
    }
  };

  getFilterInitialValues = () => {
    const { filter } = this.props;

    return {
      industry: filter.industry,
      transaction_status: filter.transaction_status,
      country: filter.country,
      state: filter.state,
      investment_type: filter.investment_type,
      filterMinInvest: filter.filterMinInvest
    }
  };

  render() {
    const { form, opportunityGroups, loading } = this.props;
    const { getFieldDecorator } = form;
    const countries = this.props.countries ? this.props.countries : [];
    const states = this.props.states ? this.props.states : [];
    const industries = findDictionaryByID(5, opportunityGroups);
    const transactionStatuses = findDictionaryByID(4, opportunityGroups);
    const investmentTypes = findDictionaryByID(3, opportunityGroups);
    const {
      industry,
      transaction_status,
      country,
      state,
      investment_type,
      filterMinInvest
    } = this.getFilterInitialValues();

    return (
        <Filter>
          <div className="row">
            <div className="column small-12 xx-large-11 xxx-large-9">
              <Form onSubmit={this.onSubmit} className="row expanded">
                <div className="column small-12 large-4 x-large-4 form-item form-item-white">
                  <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                    INDUSTRY
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("industry", {
                      initialValue: industry,
                      rules: [{required: false, message: "Please fill out this field"}],
                    })(
                      <Select
                        mode="multiple"
                        showSearch
                        filterOption={filterOption}
                        allowClear
                        style={{ width: "100%" }}
                      >
                        { industries.map((element) => <Option key={element.id} value={element.id}>{element.name}</Option>) }
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="column small-12 large-4 x-large-4 form-item form-item-white">
                  <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                    TRANSACTION STATUS
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("transaction_status", {
                      initialValue: transaction_status,
                      rules: [{required: false, message: "Please fill out this field"}],
                    })(
                      <Select
                        mode="multiple"
                        showSearch
                        filterOption={filterOption}
                        allowClear
                        style={{ width: "100%" }}
                      >
                        { transactionStatuses.map((element) => <Option key={element.id} value={element.id}>{element.name}</Option>) }
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="column small-12 large-4 x-large-4 form-item form-item-white">
                  <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                    COUNTRY
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("country", {
                      initialValue: country,
                      rules: [{required: false, message: "Please fill out this field"}],
                    })(
                      <Select
                        mode="multiple"
                        showSearch
                        filterOption={filterOption}
                        allowClear
                        style={{ width: '100%' }}
                        onChange={this.handleCountryOnChange}
                      >
                        { countries.map((element) => <Option key={element.id} value={element.id}>{element.name}</Option>) }
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="column small-12 large-4 x-large-4 form-item form-item-white">
                  <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                    PROVINCE / STATE
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("state", {
                      initialValue: state,
                      rules: [{required: false, message: 'Please fill out this field'}],
                    })(
                      <Select
                        mode="multiple"
                        showSearch
                        filterOption={filterOption}
                        allowClear
                        style={{ width: "Please fill out this field" }}
                      >
                        { states.map((element) => <Option key={element.id} value={element.id}>{element.name}</Option>) }
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="column small-12 large-4 x-large-4 form-item form-item-white">
                  <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                    INVESTMENT TYPE
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("investment_type", {
                      initialValue: investment_type,
                      rules: [{required: false, message: "Please fill out this field"}],
                    })(
                      <Select
                        mode="multiple"
                        showSearch
                        filterOption={filterOption}
                        allowClear
                        style={{ width: "100%" }}
                      >
                        { investmentTypes.map((element) => <Option key={element.id} value={element.id}>{element.name}</Option>) }
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="column small-12 large-4 x-large-4 form-item form-item-white">
                  <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                    MINIMUM INVESTMENT
                  </div>
                  <div className="range_slider_wrap">
                    <Form.Item className="mb_0">
                      {getFieldDecorator("filterMinInvest", {
                        initialValue: filterMinInvest,
                        rules: [{required: false, message: "Please fill out this field"}],
                      })(
                        <Slider {...sliderOptions} />
                      )}
                    </Form.Item>
                    <div className="flex-container align-middle align-justify">
                      <div className="text text-10 font-medium font-spacing-02 color-grey-900">
                        min $10K
                      </div>
                      <div className="text text-10 font-medium font-spacing-02 color-grey-900">
                        max $500K
                      </div>
                    </div>
                  </div>
                </div>

                <div className="column small-12">
                  <div className="flex-container align-middle filter_submit">
                    <Form.Item className="mb_0">
                      <Button
                        title="Apply filter"
                        size="lg"
                        theme="blue"
                        className="btn-shadow radius-8"
                        type="submit"
                        loading={handleLoading(loading, "GetBusiness")}
                      />
                    </Form.Item>
                    <Form.Item className="mb_0">
                      <Button
                        title="Reset all filters"
                        size="lg"
                        theme="default"
                        className="btn-link font-uppercase"
                        type="button"
                        onClick={this.handleResetAllFilters}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Filter>
    );
  }
}

const FilterComponentScreen = Form.create()(FilterComponent);

const mapStateToProps = state => ({
  loading: state.business.loading,
  opportunityGroups: state.dictionaries.opportunityGroups,
  countries: state.dictionaries.countries,
  states: state.dictionaries.states,
  filter: state.business.filter
});

const mapDispatchToProps = dispatch => ({
  getStates: countryID => dispatch(getStates(countryID)),
  filterBusinesses: (queryStringParams) => dispatch(filterBusinesses(queryStringParams))
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponentScreen);