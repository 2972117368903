import produce from "immer";
import {
  INIT_LOAD_SUCCESS,
  GET_REPORT_START, GET_REPORT_SUCCESS, GET_REPORT_FAIL,
  GET_NEW_REPORT_START, GET_NEW_REPORT_SUCCESS, GET_NEW_REPORT_FAIL,
  GET_CLOSED_REPORTS_START, GET_CLOSED_REPORTS_SUCCESS, GET_CLOSED_REPORTS_FAIL,
  ADD_REPORT_START, ADD_REPORT_SUCCESS, ADD_REPORT_FAIL,
  ADD_STATS_START, ADD_STATS_SUCCESS, ADD_STATS_FAIL,
  REMOVE_REPORTS, SET_ACTIVE_PORTFOLIO_TAB_KEY, SET_ACTIVE_INVESTMENT_ID, GET_BUSINESS_REPORTS_START, GET_BUSINESS_REPORTS_SUCCESS, GET_BUSINESS_REPORTS_FAIL,
  GET_TRANSACTIONS_START,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
} from "../constants";
import moment from "moment";
import {XIRR} from "../../utils/mathFunctions";

const getMonthViaQuarter = (quarter) => {
  switch (quarter) {
    case 0:
    case 1:
      return '01';
    case 2:
      return '03';
    case 3:
      return '06';
    case 4:
      return '09';
    default:
  }
};

const initialState = {
  loading: {
    type: "",
    state: false
  },
  errorMessage: {
    type: "",
    message: "",
  },
  selectedCurrency: "CAD",
  activeTabKey: "analytics",
  activeInvestmentId: null,
  pegateBusinessesCount: 0,
  otherBusinessesCount: 0,
  irr: [],
  irrSum: 0,
  reports: null,
  businessReports: null,
  newReports: null,
  closedReports: null,
  transactions: null,
  transactionsCount: 0,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_LOAD_SUCCESS:
      return produce(state, draft => {
        const { reports } = action.payload;
        let projectedXIRRList = [];

        if(reports) {
          for (let index = 0; index < reports.length; index++) {
            let money = [];
            let dates = [];
            const element = reports[index];
            const { final_amount, fmv, returned_amount } = element;

            if (parseInt(final_amount) !== 0) {
              element.reports && element.reports.map((reportItem, index) => {
                const investment = index === 0 ? parseInt(final_amount) * -1 : 0;
                let total = investment + parseInt(reportItem.dividend) + parseInt(returned_amount) + parseInt(fmv);
                const month = getMonthViaQuarter(parseInt(reportItem.quarter));
                const date = moment(`${reportItem.year}/${month}/01`, 'YYYY/MM/DD');
                if (index === 0) {
                  total *= -1;
                }

                money.push(total);
                dates.push(date);

                return "";
              });
              const XIIRResult = XIRR(money, dates) !== 0 ? XIRR(money, dates).toFixed(2) : 0;

              projectedXIRRList.push({
                title: element.name,
                IRR: XIIRResult,
              });
            }
          }
        }

        let irrSum = 0;
        projectedXIRRList.map((element) => {
          return irrSum += element.IRR;
        });

        draft.irrSum = parseFloat(irrSum).toFixed(2);
        draft.irr = projectedXIRRList;
        draft.reports = action.payload.reports;
        draft.closedReports = action.payload.closedReports;
        draft.pegateBusinessesCount = action.payload.pegateBusinessesCount;
        draft.otherBusinessesCount = action.payload.otherBusinessesCount;
      });
    case GET_REPORT_START:
      return produce(state, draft => {
        draft.loading.type = "GetReport";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_REPORT_SUCCESS:
      return produce(state, draft => {
        if (!action.payload.isFromCurrency) {
          const { reports } = action.payload;
          let projectedXIRRList = [];

          if(reports) {
            for (let index = 0; index < reports.length; index++) {
              let money = [];
              let dates = [];
              const element = reports[index];
              const { final_amount, fmv, returned_amount } = element;

              if (parseInt(final_amount) !== 0) {
                element.reports && element.reports.map((reportItem, index) => {
                  const investment = index === 0 ? parseInt(final_amount) * -1 : 0;
                  let total = investment + parseInt(reportItem.dividend) + parseInt(returned_amount) + parseInt(fmv);
                  const month = getMonthViaQuarter(parseInt(reportItem.quarter));
                  const date = moment(`${reportItem.year}/${month}/01`, 'YYYY/MM/DD');
                  if (index === 0) {
                    total *= -1;
                  }

                  money.push(total);
                  dates.push(date);
                  
                  return "";
                });
                const XIIRResult = XIRR(money, dates) !== 0 ? XIRR(money, dates).toFixed(2) : 0;

                projectedXIRRList.push({
                  title: element.name,
                  IRR: XIIRResult,
                });
              }
            }
          }

          let irrSum = 0;
          projectedXIRRList.map((element) => {
            return irrSum += element.IRR;
          });

          draft.irrSum = parseFloat(irrSum).toFixed(2);
          draft.irr = projectedXIRRList;
        }
        draft.loading.state = false;
        draft.reports = action.payload.reports;
        draft.selectedCurrency = action.payload.selectedCurrency;
      });
    case GET_REPORT_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetReport";
        draft.errorMessage.message = action.payload;
      });
    case GET_NEW_REPORT_START:
      return produce(state, draft => {
        draft.loading.type = "GetNewReport";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_NEW_REPORT_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.newReports = action.payload.newReports;
      });
    case GET_NEW_REPORT_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetNewReport";
        draft.errorMessage.message = action.payload;
      });
    case GET_CLOSED_REPORTS_START:
      return produce(state, draft => {
        draft.loading.type = "GetClosedReports";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_CLOSED_REPORTS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.closedReports = action.payload.closedReports;
      });
    case GET_CLOSED_REPORTS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetClosedReports";
        draft.errorMessage.message = action.payload;
      });
    case GET_TRANSACTIONS_START:
      return produce(state, draft => {
        draft.loading.type = "GetTransactions";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_TRANSACTIONS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.transactions = action.payload.transactions;
        draft.transactionsCount = action.payload.transactionsCount;
      });
    case GET_TRANSACTIONS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetTransactions";
        draft.errorMessage.message = action.payload;
      });
    case ADD_REPORT_START:
      return produce(state, draft => {
        draft.loading.type = "AddReport";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ADD_REPORT_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.newReports = action.payload.reports;
      });
    case ADD_REPORT_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "AddReport";
        draft.errorMessage.message = action.payload;
      });
    case ADD_STATS_START:
      return produce(state, draft => {
        draft.loading.type = "AddStats";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ADD_STATS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.reports = action.payload.reports;
      });
    case ADD_STATS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "AddStats";
        draft.errorMessage.message = action.payload;
      });
    case REMOVE_REPORTS:
      return produce(state, draft => {
        draft.pegateBusinessesCount = 0;
        draft.otherBusinessesCount = 0;
        draft.reports = null;
      });
    case SET_ACTIVE_PORTFOLIO_TAB_KEY:
      return produce(state, draft => {
        draft.activeTabKey = action.tabKey;
      });
    case SET_ACTIVE_INVESTMENT_ID:
      return produce(state, draft => {
        draft.activeInvestmentId = action.activeInvestmentId;
      });
      case GET_BUSINESS_REPORTS_START:
        return produce(state, draft => {
          draft.loading.type = "GetBusinessReport";
          draft.loading.state = true;
          draft.errorMessage.type = "";
          draft.errorMessage.message = "";
        });
      case GET_BUSINESS_REPORTS_SUCCESS:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.businessReports = action.payload.businessReports;
        });
      case GET_BUSINESS_REPORTS_FAIL:
        return produce(state, draft => {
          draft.loading.state = false;
          draft.errorMessage.type = "GetBusinessReport";
          draft.errorMessage.message = action.payload;
        });
    default:
      return state;
  }
};

export default reportsReducer;