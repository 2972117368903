import React, { useEffect }  from 'react';
import './style.scss';

const PopUp = (props) => {
  let classNames = [
    "c-popup",
    props.className ? props.className : "",
    props.visible ? "visible" : "",
    props.showCloseIcon ? "with-close" : "",
  ];

  useEffect(() => {
    if(props.visible) {
      window.scrollTo(0, 0);
    }
  }, [props.visible]);

  return(
    <div onScroll={props.onScroll} className={classNames.join(" ")} onClick={props.onClick ? props.onClick : ()=>{}} style={{...props.style}}>
      <div className={`c-popup-inner ${props.innerClassNames}`}>
        {props.children}
      </div>
    </div>
  )
};

export default PopUp;