import React, { Component } from "react";
import { connect } from "react-redux";
import { formatNumber } from "../../utils/formatNumber";
import { getCurrencySymbol } from "../../utils/getCurrencySymbol";
import { getMediaFileFromServer } from "../../utils/getMediaFileFromServer";

import ListItem from "../../components/ListItem/ListItem";

class InvestmentTab extends Component {
  getInvestmentData = (investmentObject) => {
    const { selectedCurrency } = this.props;

    let dividendSum = 0;
    investmentObject.reports.map((item) => {
      if (item.dividend > 0) {
        dividendSum += item.dividend;
      }

      return dividendSum;
    });

    let totalReturns = "0%";
    if (dividendSum && investmentObject.final_amount) { // without this statement totalReturns can be a NaN
      totalReturns = parseFloat(parseInt(dividendSum * 100) / investmentObject.final_amount).toFixed(2) + '%';
    }

    let totalInvested = investmentObject.final_amount;
    
    if(investmentObject.external_cost) {
      totalInvested += investmentObject.external_cost;
    }
    if(investmentObject.internal_cost) {
      totalInvested += investmentObject.internal_cost;
    }
    if(investmentObject.sold_cost) {
      totalInvested -= investmentObject.sold_cost;
    }
    
    return {
      totalInvested: `${getCurrencySymbol(selectedCurrency)} ${formatNumber(totalInvested)}`,
      totalReturns,
      shareholding: `${Math.round(investmentObject.shareholding * 10) / 10}%`,
      projectedIRR: `${parseFloat(investmentObject.irr.toFixed(2))}%`,
    };
  };

  render() {
    const { reports } = this.props;

    return (
      <div className="row">
        {
          reports ?
            reports.map((element) => {
              const {
                totalInvested,
                totalReturns,
                shareholding,
                projectedIRR,
              } = this.getInvestmentData(element);

              return (
                <div className="column small-12 large-4 x-large-3" key={element.id}>
                  <ListItem
                    id={element.id}
                    hideSaveIcon
                    title={element.name}
                    img={element.photo ? getMediaFileFromServer(element.photo) : require("../../assets/img/joel-filipe-RFDP7_80v5A-unsplash.png")}
                    href={`/project/${element.id}`}
                    footer={
                      <>
                        <ul className="text text-14 font-medium color-grey-900 flex-container flex-wrap align-middle list_item_ul">
                          <li>Total invested</li>
                          <li><b>{totalInvested}</b></li>
                        </ul>
                        <ul className="text text-14 font-medium color-grey-900 flex-container flex-wrap align-middle list_item_ul">
                          <li>Percentage returned</li>
                          <li><b>{totalReturns}</b></li>
                        </ul>
                        <ul className="text text-14 font-medium color-grey-900 flex-container flex-wrap align-middle list_item_ul">
                          <li>Shareholding</li>
                          <li><b>{shareholding}</b></li>
                        </ul>
                        <ul className="text text-14 font-medium color-grey-900 flex-container flex-wrap align-middle list_item_ul">
                          <li>Projected IRR</li>
                          <li><b>{projectedIRR}</b></li>
                        </ul>
                      </>
                    }
                  />
                </div>
              )
            })
          :
            null
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  reports: state.reports.reports,
  selectedCurrency: state.reports.selectedCurrency,
});

export default connect(mapStateToProps)(InvestmentTab);
