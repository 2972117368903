export const getOpportunitiesByType = (opportunitiesArray, type) => {
  let returningOpportunityList = [];

  opportunitiesArray.map(element => {
    switch (type) {
      case "saved":
        if (element.interested && !element.nda_signed) {
          returningOpportunityList.push(element);
        }
        break;
      case "pursuing":
        if (element.interested && element.nda_signed || element.ready_to_start && !element.closed || element.not_interested && element.nda_signed) {
          returningOpportunityList.push(element);
        }
        break;
      case "closed":
        if (element.closed) {
          returningOpportunityList.push(element);
        }
        break;
      default:
    }

    return "";
  });

  return returningOpportunityList;
};