export const winningBidsColumns = [
  {
    title: "Bid",
    dataIndex: "bid",
    key: "bid",
  },
  {
    title: "Scope",
    dataIndex: "scope",
    key: "scope",
  },
  {
    title: "Prize",
    dataIndex: "prize",
    key: "prize",
  },
  {
    title: "Won",
    dataIndex: "won",
    key: "won",
  },
  {
    title: "created",
    dataIndex: "created",
    key: "created",
  },
];
