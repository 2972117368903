import React from "react";

const TabPaneTitle = (props) => {
  const { title, length } = props;
  return (
    <div className="flex-container align-middle">
      {title}
      {
        length !== undefined &&
          <div className="tab_badge radius-circle text text-14 font-semibold color-grey-800">
            {length}
          </div>
      }
    </div>
  );
};

export default TabPaneTitle;
