import React, {Component} from "react";
import {connect} from "react-redux";
import {getOnboarding, setOnboardingData} from "../../redux/registration/thunks/registrationThunks";
import {findDictionaryByID} from "../../utils/findDictionaryByID";
import {clearOptions, getOptions, setOptions} from "../../redux/dictionaries/thunks/dictionariesOptionsThunk";

import "./style.scss";

import {Checkbox, Form, Input, message, Radio, Tooltip} from "antd";
import Icon from "../../components/Icon/Icon";
import NumericInput from "../../components/NumericInput/NumericInput";
import OnboardingHeader from "./OnboardingHeader";
import OnboardingFooter from "./OnboardingFooter";

const { TextArea } = Input;

class OnboardingStep3 extends Component {
  constructor(props) {
    super(props);

    this.optionGroup = 3;
    this.state = {
      provideInfo: 2,
      infoBanks: "",
      bankName: "",
      telNumber: "",
      email: "",
      provideIndividual: 2,
      advisoryFirmName: "",
      businessAdvisoryFirmName: "",
      refer: 2,
      referName: "",
      referSurame: "",
      referCompany: "",
      referMembership: 3,
      isInsider: 2,
      own: 2,
      ownCompanyDesc: "",
      director: 2,
      directorDesc: ""
    }
  }

  handleOnChange = (value, type) => {
    this.setState({
      [type]: value
    })
  };

  handleSelectChange = value => {
    
  };

  handleRadioChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  isCheckedOther = checkedArray => {
    if (Array.isArray(checkedArray)) {
      for (let i = 0; i < checkedArray.length; i++) {
        if (parseInt(checkedArray[i]) === 11) {
          return true;
        }
      }
    }

    return false;
  };

  handleSetOptions = () => {
    const {form, opportunityGroups} = this.props;
    const investmentAssetArray = form.getFieldValue("investment_asset");
    const investmentOpportunitiesOptions = findDictionaryByID(3, opportunityGroups);

    if (investmentAssetArray.length) {
      const { setOptions } = this.props;
      let param = {
        optgroup: this.optionGroup
      };

      investmentAssetArray.map((element, index) => {
        const selectedCurrentOption = investmentOpportunitiesOptions.find(item => parseInt(item.id) === parseInt(element));

        param[`options[${index}][id]`] = selectedCurrentOption.id;
        param[`options[${index}][value]`] = 0;

        return "";
      });

      setOptions(param);
    }
    else {
      const {clearOptions} = this.props;
      clearOptions(this.optionGroup);
    }
  };

  getInitialValues = () => {
    const {onboardingData, optionsList} = this.props;
    let returningInitialValues = {
      // banker_info: "",
      // banker_name: "",
      // banker_bank: "",
      // banker_phone: "",
      // banker_email: "",
      // banker_access: "",
      // banker_legal_contact: "",
      // banker_business_contact: "",
      // referer: "",
      // referer_name: "",
      // referer_surname: "",
      // referer_company: "",
      // referer_registered: "",
      insider: "",
      is_voting: "",
      voting_companies: "",
      is_officer: "",
      officer_companies: "",
      investment_asset: [],
      asset_source_other: "",
      investment_amount: "",
    };

    if (onboardingData) {
      returningInitialValues = {
        // banker_info: onboardingData.banker_info,
        // banker_name: onboardingData.banker_name,
        // banker_bank: onboardingData.banker_bank,
        // banker_phone: onboardingData.banker_phone,
        // banker_email: onboardingData.banker_email,
        // banker_access: onboardingData.banker_access,
        // banker_legal_contact: onboardingData.banker_legal_contact,
        // banker_business_contact: onboardingData.banker_business_contact,
        // referer: onboardingData.referer,
        // referer_name: onboardingData.referer_name,
        // referer_surname: onboardingData.referer_surname,
        // referer_company: onboardingData.referer_company,
        // referer_registered: onboardingData.referer_registered,
        insider: onboardingData.insider ? onboardingData.insider : 0,
        is_voting: onboardingData.voting_companies ? 1 : 0,
        voting_companies: onboardingData.voting_companies ? onboardingData.voting_companies : "",
        is_officer: onboardingData.officer_companies ? 1 : 0,
        officer_companies: onboardingData.officer_companies,
        asset_source_other: onboardingData.asset_source_other,
        investment_amount: onboardingData.investment_amount ? onboardingData.investment_amount : '',
      };

      if (optionsList && optionsList.length) {
        const foundElement = optionsList.find((item) => parseInt(item.type) === 3);
        let investmentAssetTmpArray = [];
        foundElement.options.map(element => investmentAssetTmpArray.push(parseInt(element.option)));
        returningInitialValues.investment_asset = investmentAssetTmpArray;
      }
    }

    return returningInitialValues;
  };

  setOnboardingInfo = (type, callback) => { // type can be prev, next or save
    if (type !== 'prev') {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          values.voting_companies = values.is_voting === 0 ? '' : values.voting_companies;
          values.officer_companies = values.is_officer === 0 ? '' : values.officer_companies;
          delete values.is_voting;
          delete values.is_officer;

          (!this.isCheckedOther(values.investment_asset)) && (values.asset_source_other = "");
          delete values.investment_asset;

          this.handleSetOptions();

          callback(values);
        }
      });
    }
    else {
      const values = this.props.form.getFieldsValue();

      values.voting_companies = values.is_voting === 0 ? '' : values.voting_companies;
      values.officer_companies = values.is_officer === 0 ? '' : values.officer_companies;
      delete values.is_voting;
      delete values.is_officer;

      (!this.isCheckedOther(values.investment_asset)) && (values.asset_source_other = "");
      delete values.investment_asset;

      this.handleSetOptions();

      callback(values);
    }
  };

  onSubmit = event => {
    event.preventDefault();
    const { setOnboardingData } = this.props;

    this.setOnboardingInfo('save',(values) => {
      setOnboardingData(values);
    })
  };

  investmentAmountOnBlur = (value) => {
    this.props.form.setFieldsValue({
      investment_amount: value.toString().replace(/\D/g,''),
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const {loading, errorMessage} = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    // const errorType = errorMessage.type;
    const errorText = errorMessage.message;

    if (!errorText && !loadingState && prevProps.loading.state) {
      if (loadingType === "SetOnboardingData") {
        message.success("Saved");
      }
    }
  }

  componentDidMount() {
    window.scroll(0, 0);
    const {getOnboarding} = this.props;
    getOnboarding();
    // getOptions(this.optionGroup);
  }

  render() {
    const { form, opportunityGroups } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const investmentOpportunitiesOptions = findDictionaryByID(3, opportunityGroups);
    const {
      // banker_info,
      // banker_name,
      // banker_bank,
      // banker_phone,
      // banker_email,
      // banker_access,
      // banker_legal_contact,
      // banker_business_contact,
      // referer,
      // referer_name,
      // referer_surname,
      // referer_company,
      // referer_registered,
      insider,
      is_voting,
      voting_companies,
      is_officer,
      officer_companies,
      investment_asset,
      asset_source_other,
      investment_amount,
    } = this.getInitialValues();

    return (
      <OnboardingHeader step={2} >
        <div className="row">
          <div className="column small-12 x-large-10">
            <Form onSubmit={this.onSubmit} >
              <div className="form_card bg-white radius-10 shadow-layout mb_45">
                <div className="mb_50">
                  {/*<div className="form-item">
                    <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
                      Do you want to provide information about your bankers, wealth managers, or advisers? *
                    </div>
                    <Form.Item className="mb_0">
                      {getFieldDecorator("banker_info", {
                        initialValue: banker_info,
                        rules: [{required: false, message: "Please fill out this field"}],
                        onChange: this.handleRadioChange
                      })(
                        <Radio.Group name="banker_info">
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>*/}

                  {/*{
                    form.getFieldValue("banker_info") === 1 &&
                      <div className="form_toggle_field">
                        <div className="form-item">
                          <div className="form-item-label text text-height-20 font-semibold color-grey">
                            Name of Primary Wealth Manager, Investment Adviser or Private Banker
                          </div>
                          <Form.Item className="mb_0">
                            {getFieldDecorator("banker_name", {
                              initialValue: banker_name,
                              rules: [{required: false, message: "Please fill out this field"}],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </div>

                        <div className="form-item">
                          <div className="form-item-label text text-height-20 font-semibold color-grey">
                            Name of firm or bank
                          </div>
                          <Form.Item className="mb_0">
                            {getFieldDecorator("banker_bank", {
                              initialValue: banker_bank,
                              rules: [{required: false, message: "Please fill out this field"}],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </div>

                        <div className="form-item">
                          <div className="form-item-label text text-height-20 font-semibold color-grey">
                            Telephone Number
                          </div>
                          <Form.Item className="mb_0">
                            {getFieldDecorator("banker_phone", {
                              initialValue: banker_phone,
                              rules: [{required: false, message: "Please fill out this field"}],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </div>

                        <div className="form-item">
                          <div className="form-item-label text text-height-20 font-semibold color-grey">
                            Email Address
                          </div>
                          <Form.Item className="mb_0">
                            {getFieldDecorator("banker_email", {
                              initialValue: banker_email,
                              rules: [{required: false, message: "Please fill out this field"}],
                            })(
                              <Input type="email" />
                            )}
                          </Form.Item>
                        </div>

                        <div className="form-item">
                          <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
                            Do you want to provide this individual with access to your information on the GATE?
                          </div>
                          <Form.Item className="mb_0">
                            {getFieldDecorator("banker_access", {
                              initialValue: banker_access,
                              rules: [{required: false, message: "Please fill out this field"}],
                              onChange: this.handleRadioChange
                            })(
                              <Radio.Group name="provideIndividual">
                                <Radio value={1}>Yes</Radio>
                                <Radio value={0}>No</Radio>
                              </Radio.Group>
                            )}
                          </Form.Item>
                        </div>

                        {
                          form.getFieldValue("banker_access") === 1 &&
                            <>
                              <div className="form-item">
                                <div className="form-item-label text text-height-20 font-semibold color-grey">
                                  Name of Legal Advisory Firm and contact name
                                </div>
                                <Form.Item className="mb_0">
                                  {getFieldDecorator("banker_legal_contact", {
                                    initialValue: banker_legal_contact,
                                    rules: [{required: false, message: "Please fill out this field"}],
                                  })(
                                    <Input/>
                                  )}
                                </Form.Item>
                              </div>
                              <div className="form-item">
                                <div className="form-item-label text text-height-20 font-semibold color-grey">
                                  Name of Business Advisory Firm and contact name
                                </div>
                                <Form.Item className="mb_0">
                                  {getFieldDecorator("banker_business_contact", {
                                    initialValue: banker_business_contact,
                                    rules: [{required: false, message: "Please fill out this field"}],
                                  })(
                                    <Input/>
                                  )}
                                </Form.Item>
                              </div>
                            </>
                        }
                    </div>
                  }*/}
                </div>

                {/*<div className="mb_50">
                  <div className="form-item">
                    <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
                      Did someone refer you to PE Gate? *
                    </div>
                    <Form.Item className="mb_0">
                      {getFieldDecorator("referer", {
                        initialValue: referer,
                        rules: [{required: false, message: "Please fill out this field"}],
                        onChange: this.handleRadioChange
                      })(
                        <Radio.Group name="referer">
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>

                  {
                    form.getFieldValue("referer") === 1 &&
                      <div className="form_toggle_field">
                        <div className="form-item">
                          <div className="form-item-label text text-height-20 font-semibold color-grey">
                            Name *
                          </div>
                          <Form.Item className="mb_0">
                            {getFieldDecorator("referer_name", {
                              initialValue: referer_name,
                              rules: [{required: false, message: "Please fill out this field"}],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </div>

                        <div className="form-item">
                          <div className="form-item-label text text-height-20 font-semibold color-grey">
                            Surname *
                          </div>
                          <Form.Item className="mb_0">
                            {getFieldDecorator("referer_surname", {
                              initialValue: referer_surname,
                              rules: [{required: false, message: "Please fill out this field"}],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </div>

                        <div className="form-item">
                          <div className="form-item-label text text-height-20 font-semibold color-grey">
                            Company *
                          </div>
                          <Form.Item className="mb_0">
                            {getFieldDecorator("referer_company", {
                              initialValue: referer_company,
                              rules: [{required: false, message: "Please fill out this field"}],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </div>

                        <div className="form-item">
                          <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
                            Is the person who referred you a member of PE GATE? *
                          </div>
                          <Form.Item className="mb_0">
                            {getFieldDecorator("referer_registered", {
                              initialValue: referer_registered,
                              rules: [{required: false, message: "Please fill out this field"}],
                              onChange: this.handleRadioChange
                            })(
                              <Radio.Group name="referMembership">
                                <Radio value={1}>Yes</Radio>
                                <Radio value={0}>No</Radio>
                                <Radio value={2}>Idk</Radio>
                              </Radio.Group>
                            )}
                          </Form.Item>
                        </div>
                      </div>
                  }
                </div>*/}

                <div className="mb_50">
                  <div className="form-item">
                    <div className="form-item-label text text-height-20 font-semibold color-grey mb_10 label-with-icon-wrapper">
                      <span className="label-icon-text">Are you an insider? *</span>
                      <Tooltip
                        placement="right"
                        title="Generally, a person or company is a reporting insider
                          of a publicly listed company if the person or company has
                          routine access to material undisclosed information
                          concerning the company and significant influence over the company."
                      >
                        <div className="align-self-top label-icon">
                          <Icon type="question" />
                        </div>
                      </Tooltip>
                    </div>
                    <Form.Item className="mb_0">
                      {getFieldDecorator("insider", {
                        initialValue: insider,
                        rules: [{required: true, message: "Please fill out this field"}],
                        onChange: this.handleRadioChange
                      })(
                        <Radio.Group name="insider">
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>
                </div>

                <div className="mb_50">
                  <div className="form-item">
                    <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
                      Do you own, either by yourself or as part of a group, 10% or more of the voting rights of a publicly traded company?*
                    </div>
                    <Form.Item className="mb_0">
                      {getFieldDecorator("is_voting", {
                        initialValue: is_voting,
                        rules: [{required: true, message: "Please fill out this field"}],
                        onChange: this.handleRadioChange
                      })(
                        <Radio.Group name="is_voting">
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>

                  {
                    form.getFieldValue("is_voting") === 1 &&
                      <div className="form_toggle_field">
                        <div className="form-item">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("voting_companies", {
                              initialValue: voting_companies,
                              rules: [{required: true, message: 'Please fill out this field'}],
                            })(
                              <TextArea placeholder="Please specify" />
                            )}
                          </Form.Item>
                        </div>
                      </div>
                  }
                </div>

                <div className="mb_50">
                  <div className="form-item">
                    <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
                      Are you a Director or Senior Officer of any publicly traded company? *
                    </div>
                    <Form.Item className="mb_0">
                      {getFieldDecorator("is_officer", {
                        initialValue: is_officer,
                        rules: [{required: true, message: "Please fill out this field"}],
                        onChange: this.handleRadioChange
                      })(
                        <Radio.Group name="is_officer">
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>

                  {
                    form.getFieldValue("is_officer") === 1 &&
                      <div className="form_toggle_field">
                        <div className="form-item">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("officer_companies", {
                              initialValue: officer_companies,
                              rules: [{required: true, message: "Please fill out this field"}],
                            })(
                              <TextArea placeholder="Please specify" />
                            )}
                          </Form.Item>
                        </div>
                      </div>
                  }
                </div>

                <div className="large-11 mb_30">
                  <div className="form-item">
                    <div className="form-item-label text text-height-20 font-semibold color-grey">
                      Source of investment assets: *
                    </div>
                  </div>
                  <div className="form-item form-item-lg mb_15">
                    <Form.Item className="mb_0">
                      {getFieldDecorator("investment_asset", {
                        initialValue: investment_asset,
                        rules: [{required: true, message: "Please fill out this field"}]
                      })(
                        <Checkbox.Group
                          className="flex-container align-top"
                          options={investmentOpportunitiesOptions.map(element => ({label: element.name, value: element.id}))}
                        />
                      )}
                    </Form.Item>
                  </div>
                </div>

                {
                  this.isCheckedOther(getFieldValue("investment_asset")) &&
                    <div className="form-item">
                      <Form.Item className="mb_0">
                        {getFieldDecorator("asset_source_other", {
                          initialValue: asset_source_other,
                          rules: [{required: false, message: "Please fill out this field"}],
                        })(
                          <TextArea placeholder="Please Specify" />
                        )}
                      </Form.Item>
                    </div>
                }

                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    Total amount you might consider investing in opportunities presented by PE Gate (in USD): *
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator("investment_amount", {
                      initialValue: investment_amount,
                      rules: [{required: true, message: "Please fill out this field"}],
                    })(
                      <NumericInput
                        onBlur={(event) => this.investmentAmountOnBlur(event.target.value)}
                        className="fluid-x"
                      />
                    )}
                  </Form.Item>
                </div>
              </div>

              <OnboardingFooter
                step={2}
                setOnboardingInfo={this.setOnboardingInfo}
              />
            </Form>
          </div>
        </div>
      </OnboardingHeader>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.registration.loading,
  errorMessage: state.registration.errorMessage,
  onboardingData: state.registration.onboardingData,
  optionsList: state.dictionaries.optionsList,
  opportunityGroups: state.dictionaries.opportunityGroups,
});

const mapDispatchToProps = dispatch => ({
  setOnboardingData: params => dispatch(setOnboardingData(params)),
  getOnboarding: () => dispatch(getOnboarding()),
  setOptions: params => dispatch(setOptions(params)),
  getOptions: optionGroup => dispatch(getOptions(optionGroup)),
  clearOptions: optionGroup => dispatch(clearOptions(optionGroup)),
});

const OnboardingScreenStep3 = Form.create({ name: "OnboardingStep3" })(OnboardingStep3);

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingScreenStep3);
