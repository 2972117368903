import {
  getTagsStart, getTagsSuccess, getTagsFail,
  tagUsersStart, tagUsersSuccess, tagUsersFail,
  untagUsersStart, untagUsersSuccess, untagUsersFail,
  addTagStart, addTagFail,
  renameTagStart, renameTagSuccess, renameTagFail,
  removeTagStart, removeTagSuccess, removeTagFail,
  getTagUsersStart, getTagUsersSuccess, getTagUsersFail, getAllTagUsersStart, getAllTagUsersSuccess, getAllTagUsersFail,
} from "../actions/tagsActions";
import CommonUtils from "../../../utils/CommonUtils";

const tagUserFunctionalPart = ({ params, config, fromAddTag }) => ((dispatch, getState) => {
  CommonUtils.apiCall("post", "private", "admin/tagUsers", params, config)
  .then((response) => {
    if (response.data.success) {
      dispatch(tagUsersSuccess());
      if (fromAddTag) dispatch(getTags())
    }
    else {
      dispatch(tagUsersFail(response.data.error));
    }
  })
  .catch(() => dispatch(tagUsersFail("Error")));
});

export const getTags = () => ((dispatch, getState) => {
  dispatch(getTagsStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", "admin/getTags", {}, config)
    .then((response) => {
      if (Array.isArray(response.data)) {
        dispatch(getTagsSuccess({
          tagsList: response.data,
        }));
      }
      else {
        dispatch(getTagsFail(response.data.error));
      }
    })
    .catch(() => dispatch(getTagsFail("Error")));
});

export const tagUsers = (params) => ((dispatch, getState) => {
  dispatch(tagUsersStart());

  const {xID, xTOKEN} = getState().user;
  const alreadyFilteredData = getState().users.filter;
  const config = {
    id: xID,
    token: xTOKEN
  };

  if (!params.users.length) {
    CommonUtils.apiCall("get", "private", "admin/searchUsers/0/0?all", alreadyFilteredData, config)
      .then((allUserIdsResponse) => {
        const userIds = allUserIdsResponse.data;
        dispatch(tagUserFunctionalPart({ params: { tag: params.tag, users: userIds }, config }));
      })
      .catch(() => dispatch(tagUsersFail("Error")));
  } else {
    dispatch(tagUserFunctionalPart({ params, config }));
  }
});

export const untagUsers = (params, currentPage = 1) => ((dispatch, getState) => {
  dispatch(untagUsersStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "admin/untagUsers", params, config)
    .then((response) => {
      if (response.data.success) {
        CommonUtils.apiCall("get", "private", `admin/searchUsers/0/0?cnt&tag=${params.tag}`, {}, config)
          .then(tagUsersCountResponse => {
            if (tagUsersCountResponse.data != null && parseInt(tagUsersCountResponse.data) === 0) {
              dispatch(untagUsersSuccess({
                tagUsersList: [],
                tagUsersCount: 0,
              }));
            }
            else if (tagUsersCountResponse.data != null && !tagUsersCountResponse.data.error) {
              const perPage = 10;
              const offset = (currentPage - 1) * perPage;
              const tagUsersCount = Math.ceil(tagUsersCountResponse.data / perPage);

              CommonUtils.apiCall("get", "private", `admin/searchUsers/${offset}/${perPage}?tag=${params.tag}`, {}, config)
                .then(tagUsersResponse => {
                  if (Array.isArray(tagUsersResponse.data)) {
                    dispatch(untagUsersSuccess({
                      tagUsersList: tagUsersResponse.data,
                      tagUsersCount,
                    }));
                  }
                  else {
                    dispatch(untagUsersFail(tagUsersResponse.data.error));
                  }
                })
                .catch(() => dispatch(untagUsersFail("Error")));
            }
          })
          .catch(() => dispatch(untagUsersFail("Error")));
      }
      else {
        dispatch(untagUsersFail(response.data.error));
      }
    })
    .catch(() => dispatch(untagUsersFail("Error")));
});

export const addTag = (name, users) => ((dispatch, getState) => {
  dispatch(addTagStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  const params = { name };

  CommonUtils.apiCall("post", "private", "admin/addTag", params, config)
    .then((response) => {
      if (response.data.success) {
        const addTagParam = {
          tag: response.data.success,
          users,
        };
        if (!users.length) {
          const alreadyFilteredData = getState().users.filter;
          CommonUtils.apiCall("get", "private", "admin/searchUsers/0/0?all", alreadyFilteredData, config)
            .then((allUserIdsResponse) => {
              const userIds = allUserIdsResponse.data;
              dispatch(tagUserFunctionalPart({ params: { tag: addTagParam.tag, users: userIds }, config, fromAddTag: true }));
            })
            .catch(() => dispatch(addTagFail("Error")));
        } else {
          dispatch(tagUserFunctionalPart({ params: addTagParam, config, fromAddTag: true }));
        }
      }
      else {
        dispatch(addTagFail("Error"));
      }
    })
    .catch(() => dispatch(addTagFail("Error")));
});

export const renameTag = (params) => ((dispatch, getState) => {
  dispatch(renameTagStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("post", "private", "admin/renameTag", params, config)
    .then((response) => {
      if (response.data.success) {
        CommonUtils.apiCall("get", "private", "admin/getTags", {}, config)
          .then((tagsResponse) => {
            if (Array.isArray(tagsResponse.data)) {
              dispatch(renameTagSuccess({
                tagsList: tagsResponse.data,
              }));
            }
            else {
              dispatch(renameTagFail(tagsResponse.data.error));
            }
          })
          .catch(() => dispatch(renameTagFail("Error")));
      }
      else {
        dispatch(renameTagFail(response.data.error));
      }
    })
    .catch(() => dispatch(renameTagFail("Error")));
});

export const removeTag = (id) => ((dispatch, getState) => {
  dispatch(removeTagStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  const params = { id };

  CommonUtils.apiCall("post", "private", "admin/removeTag", params, config)
    .then((response) => {
      if (response.data.success) {
        CommonUtils.apiCall("get", "private", "admin/getTags", {}, config)
          .then((tagsResponse) => {
            if (Array.isArray(tagsResponse.data)) {
              dispatch(removeTagSuccess({
                tagsList: tagsResponse.data,
              }));
            }
            else {
              dispatch(removeTagFail(tagsResponse.data.error));
            }
          })
          .catch(() => dispatch(removeTagFail("Error")));
      }
      else {
        dispatch(removeTagFail(response.data.error));
      }
    })
    .catch(() => dispatch(removeTagFail("Error")));
});

export const getTagUsers = (tagID, currentPage = 1) => ((dispatch, getState) => {
  dispatch(getTagUsersStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `admin/searchUsers/0/0?cnt&tag=${tagID}`, {}, config)
    .then(tagUsersCountResponse => {
      if (tagUsersCountResponse.data != null && parseInt(tagUsersCountResponse.data) === 0) {
        dispatch(getTagUsersSuccess({
          tagUsersList: [],
          tagUsersCount: 0,
          currentPage,
        }));
      }
      else if (tagUsersCountResponse.data != null && !tagUsersCountResponse.data.error) {
        const perPage = 10;
        const offset = (currentPage - 1) * perPage;
        const tagUsersCount = tagUsersCountResponse.data;

        CommonUtils.apiCall("get", "private", `admin/searchUsers/${offset}/${perPage}?tag=${tagID}`, {}, config)
          .then(tagUsersResponse => {
            if (Array.isArray(tagUsersResponse.data)) {
              const scrolledTagUsers = tagUsersResponse.data;
              const oldTagUsers = getState().tags.tagUsersList ? getState().tags.tagUsersList : [];
              const updatedTagUsers = currentPage === 1 ? scrolledTagUsers : oldTagUsers.concat(scrolledTagUsers);

              dispatch(getTagUsersSuccess({
                tagUsersList: updatedTagUsers,
                tagUsersCount,
                currentPage: currentPage + 1,
              }));
            }
            else {
              dispatch(getTagUsersFail(tagUsersResponse.data.error));
            }
          })
          .catch(() => dispatch(getTagUsersFail("Error")));
      }
    })
    .catch(() => dispatch(getTagUsersFail("Error")));
});

export const getAllTagUsers = (tagID) => ((dispatch, getState) => {
  dispatch(getAllTagUsersStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `admin/searchUsers/0/0?tag=${tagID}&full`, {}, config)
    .then(response => {
      if (Array.isArray(response.data)) {
        dispatch(getAllTagUsersSuccess({
          tagUsersList: response.data,
        }));
      }
      else {
        dispatch(getAllTagUsersFail("Error"));
      }
    })
    .catch(() => dispatch(getAllTagUsersFail("Error")));
});
