export const detectNotEnabledOptions = (array) => {
  let initialDictionary = array;

  for (let i = 0; i < initialDictionary.length; i++) {
    let { options } = initialDictionary[i];

    for (let j = 0; j < options.length; j++) {
      if (parseInt(options[j].enabled) === 0) {
        options.splice(j, 1);
        j--;
      }
    }
  }

  return initialDictionary;
};
