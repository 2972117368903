import {
  getUserStart, getUserSuccess, getUserFail
} from "../actions/getUserActions";
import CommonUtils from "../../../utils/CommonUtils";

export const getUser = userID => ((dispatch, getState) => {
  dispatch(getUserStart());

  const { xID, xTOKEN } = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", `admin/getUser/${userID}`, {}, config)
    .then(currentUserResponse => {
      if (currentUserResponse.data.success) {
        CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/0?user=${userID}&enabled`, {}, config)
          .then(userBusinessesResponse => {
            if (Array.isArray(userBusinessesResponse.data)) {
              if (currentUserResponse.data.success.user) { // if user onboarding form is filled -> get states and cities
                const countryId = currentUserResponse.data.success.country;
                if (countryId) {
                  CommonUtils.apiCall("get", "public", `shared/getStates/${countryId}`, {}, null)
                    .then(statesResponse => {
                      if (statesResponse.data) {
                        dispatch(getUserSuccess({
                          currentUser: currentUserResponse.data.success,
                          currentUserBusinesses: userBusinessesResponse.data,
                          states: statesResponse.data,
                        }));
                        return;
                        // const stateId = currentUserResponse.data.success.state;
                        // if (stateId) {
                        //   CommonUtils.apiCall("get", "public", `shared/getCities/${stateId}`, {}, null)
                        //     .then(citiesResponse => {
                        //       if (citiesResponse.data) {
                        //         dispatch(getUserSuccess({
                        //           currentUser: currentUserResponse.data.success,
                        //           currentUserBusinesses: userBusinessesResponse.data,
                        //           states: statesResponse.data,
                        //           cities: citiesResponse.data
                        //         }));
                        //       }
                        //       else {
                        //         dispatch(getUserFail("Error"))
                        //       }
                        //     })
                        //     .catch(() => dispatch(getUserFail("Error")));
                        // }
                        // else {
                        //   dispatch(getUserSuccess({
                        //     currentUser: currentUserResponse.data.success,
                        //     currentUserBusinesses: userBusinessesResponse.data,
                        //     states: statesResponse.data,
                        //     cities: []
                        //   }));
                        // }
                      }
                      else {
                        dispatch(getUserFail("Error"));
                      }
                    })
                    .catch(() => dispatch(getUserFail("Error")));
                }
                else {
                  dispatch(getUserSuccess({
                    currentUser: currentUserResponse.data.success,
                    currentUserBusinesses: userBusinessesResponse.data,
                    states: [],
                    cities: []
                  }));
                }
              }
              else {
                dispatch(getUserSuccess({
                  currentUser: currentUserResponse.data.success,
                  currentUserBusinesses: userBusinessesResponse.data
                }));
              }
            }
            else {
              dispatch(getUserFail(userBusinessesResponse.data.error));
            }
          })
          .catch(() => dispatch(getUserFail("Error")));
      }
      else {
        dispatch(getUserFail(currentUserResponse.data.error));
      }
    })
    .catch(() => dispatch(getUserFail("Error")));
});
