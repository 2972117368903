import produce from 'immer';
import {
  INIT_LOAD_SUCCESS, SET_DATA_ROOM_FILTER, REMOVE_DATA_ROOM_ITEMS,
  GET_VIRTUAL_DATA_ROOM_ITEMS_START, GET_VIRTUAL_DATA_ROOM_ITEMS_SUCCESS, GET_VIRTUAL_DATA_ROOM_ITEMS_FAIL,
  GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_START, GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_SUCCESS, GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_FAIL,
  GET_DATA_ROOM_ITEMS_START, GET_DATA_ROOM_ITEMS_SUCCESS, GET_DATA_ROOM_ITEMS_FAIL,
  GET_DATA_ROOM_INNER_ITEMS_START, GET_DATA_ROOM_INNER_ITEMS_SUCCESS, GET_DATA_ROOM_INNER_ITEMS_FAIL,
  ADD_DATA_ROOM_ITEM_START, ADD_DATA_ROOM_ITEM_SUCCESS, ADD_DATA_ROOM_ITEM_FAIL,
  REMOVE_DATA_ROOM_ITEM_START, REMOVE_DATA_ROOM_ITEM_SUCCESS, REMOVE_DATA_ROOM_ITEM_FAIL,
  RENAME_DATA_ROOM_ITEM_START, RENAME_DATA_ROOM_ITEM_SUCCESS, RENAME_DATA_ROOM_ITEM_FAIL,
  ADD_ACCESS_TO_DATA_ROOM_ITEM_START, ADD_ACCESS_TO_DATA_ROOM_ITEM_SUCCESS, ADD_ACCESS_TO_DATA_ROOM_ITEM_FAIL,
  REMOVE_ACCESS_TO_DATA_ROOM_ITEM_START, REMOVE_ACCESS_TO_DATA_ROOM_ITEM_SUCCESS, REMOVE_ACCESS_TO_DATA_ROOM_ITEM_FAIL,
  SEARCH_BUSINESS_ROOM_USERS_START, SEARCH_BUSINESS_ROOM_USERS_SUCCESS, SEARCH_BUSINESS_ROOM_USERS_FAIL,
  SEARCH_ROOM_USERS_START, SEARCH_ROOM_USERS_SUCCESS, SEARCH_ROOM_USERS_FAIL, SET_USERS_SEARCH_ROOM_PAGINATION,
  GET_SHARED_DATA_ROOM_ITEMS_START, GET_SHARED_DATA_ROOM_ITEMS_SUCCESS, GET_SHARED_DATA_ROOM_ITEMS_FAIL,
  RESET_DATA_ROOM_FILTER,
} from "../constants";

const initialState = {
  loading: {
    type: "",
    state: false
  },
  errorMessage: {
    type: "",
    message: "",
  },

  virtualDataRoomItems: null,
  virtualDataRoomItemsCount: 0,
  virtualDataRoomItemsCurrentPage: 1,

  usersSearchRoomList: null,
  totalUsersSearchRoom: 0,
  dataRoomItemsCount: 0,
  dataRoomInnerItemsCount: 0,
  currentPage: 1,
  currentInnerPage: 1,
  dashboardScreenDataRoomItems: null,
  dashboardScreenVirtualDataRoomItems: null,
  dataRoomItems: null,
  dataRoomInnerItems: null,
  usersSearchRoomPagination: {
    currentPage: 1
  },

  sharedItems: null,
  sharedItemsCount: 0,
  sharedItemsCurrentPage: 1,

  rootFilter: {
    name: '',
  },
  innerFilter: {
    name: '',
  },
};

const dataRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_LOAD_SUCCESS:
      return produce(state, draft => {
        draft.dashboardScreenDataRoomItems = action.payload.dashboardScreenDataRoomItems;
      });
    case GET_VIRTUAL_DATA_ROOM_ITEMS_START:
      return produce(state, draft => {
        draft.loading.type = "GetVirtualDataRoomItems";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_VIRTUAL_DATA_ROOM_ITEMS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.virtualDataRoomItems = action.payload.virtualDataRoomItems;
        draft.virtualDataRoomItemsCount = action.payload.virtualDataRoomItemsCount;
        draft.virtualDataRoomItemsCurrentPage = action.payload.virtualDataRoomItemsCurrentPage;
      });
    case GET_VIRTUAL_DATA_ROOM_ITEMS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetVirtualDataRoomItems";
        draft.errorMessage.message = action.payload;
      });
    case GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_START:
      return produce(state, draft => {
        draft.loading.type = "GetVirtualDataRoomItemsDashboard";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.dashboardScreenVirtualDataRoomItems = action.payload.virtualDataRoomItemsDashboard;
      });
    case GET_VIRTUAL_DATA_ROOM_ITEMS_DASHBOARD_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetVirtualDataRoomItemsDashboard";
        draft.errorMessage.message = action.payload;
      });
    case SET_DATA_ROOM_FILTER:
      return produce(state, draft => {
        // filterName can be either rootFilter or innerFilter
        draft[action.payload.filterName][action.payload.filterType] = action.payload.filterValue;
      });
    case RESET_DATA_ROOM_FILTER:
      return produce(state, draft => {
        draft.rootFilter = { name: '' };
        draft.innerFilter = { name: '' };
      });
    case REMOVE_DATA_ROOM_ITEMS:
      return produce(state, draft => {
        draft.dataRoomItems = null;
        draft.dataRoomItemsCount = 0;
      });
    case GET_DATA_ROOM_ITEMS_START:
      return produce(state, draft => {
        draft.loading.type = "GetDataRoomItems";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_DATA_ROOM_ITEMS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.dataRoomItems = action.payload.dataRoomItems;
        draft.dataRoomItemsCount = action.payload.dataRoomItemsCount;
        draft.currentPage = action.payload.currentPage;
        if (state.dataRoomInnerItems) {
          // if he comes from inner page to main page, we need to clear file items
          draft.dataRoomInnerItems = null;
        }
      });
    case GET_DATA_ROOM_ITEMS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetDataRoomItems";
        draft.errorMessage.message = action.payload;
      });
    case GET_DATA_ROOM_INNER_ITEMS_START:
      return produce(state, draft => {
        draft.loading.type = "GetDataRoomInnerItems";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_DATA_ROOM_INNER_ITEMS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.dataRoomInnerItems = action.payload.dataRoomInnerItems;
        action.payload.hasOwnProperty("dataRoomItems") && (draft.dataRoomItems = action.payload.dataRoomItems);
        action.payload.hasOwnProperty("dataRoomInnerItemsCount") && (draft.dataRoomInnerItemsCount = action.payload.dataRoomInnerItemsCount);
        action.payload.hasOwnProperty("currentInnerPage") && (draft.currentInnerPage = action.payload.currentInnerPage);
        /*action.payload.hasOwnProperty("dataRoomItemsCount") && (draft.dataRoomItemsCount = action.payload.dataRoomItemsCount);
        action.payload.hasOwnProperty("currentPage") && (draft.currentPage = action.payload.currentPage);*/
      });
    case GET_DATA_ROOM_INNER_ITEMS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetDataRoomInnerItems";
        draft.errorMessage.message = action.payload;
      });
    case ADD_DATA_ROOM_ITEM_START:
      return produce(state, draft => {
        draft.loading.type = "AddDataRoomItem";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ADD_DATA_ROOM_ITEM_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        action.payload.hasOwnProperty("dataRoomItems") && (draft.dataRoomItems = action.payload.dataRoomItems);
        action.payload.hasOwnProperty("dataRoomInnerItems") && (draft.dataRoomInnerItems = action.payload.dataRoomInnerItems);
      });
    case ADD_DATA_ROOM_ITEM_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "AddDataRoomItem";
        draft.errorMessage.message = action.payload;
      });
    case REMOVE_DATA_ROOM_ITEM_START:
      return produce(state, draft => {
        draft.loading.type = "RemoveDataRoomItem";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case REMOVE_DATA_ROOM_ITEM_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        action.payload.hasOwnProperty("dataRoomItems") && (draft.dataRoomItems = action.payload.dataRoomItems);
        action.payload.hasOwnProperty("dataRoomInnerItems") && (draft.dataRoomInnerItems = action.payload.dataRoomInnerItems);
      });
    case REMOVE_DATA_ROOM_ITEM_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "RemoveDataRoomItem";
        draft.errorMessage.message = action.payload;
      });
    case RENAME_DATA_ROOM_ITEM_START:
      return produce(state, draft => {
        draft.loading.type = "RenameDataRoomItem";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case RENAME_DATA_ROOM_ITEM_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        action.payload.hasOwnProperty("dataRoomItems") && (draft.dataRoomItems = action.payload.dataRoomItems);
        action.payload.hasOwnProperty("dataRoomInnerItems") && (draft.dataRoomInnerItems = action.payload.dataRoomInnerItems);
      });
    case RENAME_DATA_ROOM_ITEM_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "RenameDataRoomItem";
        draft.errorMessage.message = action.payload;
      });
    case ADD_ACCESS_TO_DATA_ROOM_ITEM_START:
      return produce(state, draft => {
        draft.loading.type = "AddAccessToDataRoomItem";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ADD_ACCESS_TO_DATA_ROOM_ITEM_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;

      });
    case ADD_ACCESS_TO_DATA_ROOM_ITEM_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "AddAccessToDataRoomItem";
        draft.errorMessage.message = action.payload;
      });
    case REMOVE_ACCESS_TO_DATA_ROOM_ITEM_START:
      return produce(state, draft => {
        draft.loading.type = "RemoveAccessToDataRoomItem";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case REMOVE_ACCESS_TO_DATA_ROOM_ITEM_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;

      });
    case REMOVE_ACCESS_TO_DATA_ROOM_ITEM_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "RemoveAccessToDataRoomItem";
        draft.errorMessage.message = action.payload;
      });
    case SEARCH_BUSINESS_ROOM_USERS_START:
      return produce(state, draft => {
        draft.loading.type = "SearchBusinessRoomUsers";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEARCH_BUSINESS_ROOM_USERS_SUCCESS:
      return produce(state, draft => {
        draft.loading.type = false;
        draft.usersSearchRoomList = action.payload.usersSearchRoomList;
      });
    case SEARCH_BUSINESS_ROOM_USERS_FAIL:
      return produce(state, draft => {
        draft.loading.type = false;
        draft.errorMessage.type = "SearchBusinessRoomUsers";
        draft.errorMessage.message = action.payload;
      });
    case SEARCH_ROOM_USERS_START:
      return produce(state, draft => {
        draft.loading.type = "SearchRoomUsers";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEARCH_ROOM_USERS_SUCCESS:
      return produce(state, draft => {
        draft.loading.type = false;
        draft.usersSearchRoomList = action.payload.usersSearchRoomList;
        draft.totalUsersSearchRoom = action.payload.totalUsersSearchRoom;
      });
    case SEARCH_ROOM_USERS_FAIL:
      return produce(state, draft => {
        draft.loading.type = false;
        draft.errorMessage.type = "SearchRoomUsers";
        draft.errorMessage.message = action.payload;
      });
    case SET_USERS_SEARCH_ROOM_PAGINATION:
      return produce(state, draft => {
        draft.usersSearchRoomPagination.currentPage = action.payload;
      });
    case GET_SHARED_DATA_ROOM_ITEMS_START:
      return produce(state, draft => {
        draft.loading.type = "GetSharedDataRoomItems";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_SHARED_DATA_ROOM_ITEMS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.sharedItems = action.payload.sharedItems;
        draft.sharedItemsCount = action.payload.sharedItemsCount;
        draft.sharedItemsCurrentPage = action.payload.sharedItemsCurrentPage;
      });
    case GET_SHARED_DATA_ROOM_ITEMS_FAIL:
      return produce(state, draft => {
        draft.loading.type = false;
        draft.errorMessage.type = "GetSharedDataRoomItems";
        draft.errorMessage.message = action.payload;
      });
    default:
      return state;
  }
};

export default dataRoomReducer;
