import {
  UPDATE_PROFILE_START, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL
} from "../../constants";

export const updateProfileStart = () => ({
  type: UPDATE_PROFILE_START
});

export const updateProfileSuccess = payload => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload
});

export const updateProfileFail = payload => ({
  type: UPDATE_PROFILE_FAIL,
  payload
});