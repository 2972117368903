import produce from 'immer';
import {
  GET_TAGS_START, GET_TAGS_SUCCESS, GET_TAGS_FAIL,
  TAG_USERS_START, TAG_USERS_SUCCESS, TAG_USERS_FAIL,
  UNTAG_USERS_START, UNTAG_USERS_SUCCESS, UNTAG_USERS_FAIL,
  ADD_TAG_START, ADD_TAG_SUCCESS, ADD_TAG_FAIL,
  RENAME_TAG_START, RENAME_TAG_SUCCESS, RENAME_TAG_FAIL,
  REMOVE_TAG_START, REMOVE_TAG_SUCCESS, REMOVE_TAG_FAIL,
  GET_TAG_USERS_START, GET_TAG_USERS_SUCCESS, GET_TAG_USERS_FAIL,
  GET_ALL_TAG_USERS_START, GET_ALL_TAG_USERS_SUCCESS, GET_ALL_TAG_USERS_FAIL,
} from "../constants";

const initialState = {
  loading: { type: "", state: false },
  errorMessage: { type: "", message: "" },
  tagsList: null,
  tagUsersList: null,
  tagUsersCount: 0,
  currentPage: 1,
};

const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAGS_START:
      return produce(state, draft => {
        draft.loading.type = "GetTags";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_TAGS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.tagsList = action.payload.tagsList;
      });
    case GET_TAGS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetTags";
        draft.errorMessage.message = action.payload;
      });
    case TAG_USERS_START:
      return produce(state, draft => {
        draft.loading.type = "TagUsers";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case TAG_USERS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        // do some other stuff
      });
    case TAG_USERS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "TagUsers";
        draft.errorMessage.message = action.payload;
      });
    case UNTAG_USERS_START:
      return produce(state, draft => {
        draft.loading.type = "UntagUsers";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case UNTAG_USERS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.tagUsersList = action.payload.tagUsersList;
        draft.tagUsersCount = action.payload.tagUsersCount;
      });
    case UNTAG_USERS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "UntagUsers";
        draft.errorMessage.message = action.payload;
      });
    case ADD_TAG_START:
      return produce(state, draft => {
        draft.loading.type = "AddTag";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case ADD_TAG_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.tagsList = action.payload.tagsList;
      });
    case ADD_TAG_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "AddTag";
        draft.errorMessage.message = action.payload;
      });
    case RENAME_TAG_START:
      return produce(state, draft => {
        draft.loading.type = "RenameTag";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case RENAME_TAG_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.tagsList = action.payload.tagsList;
      });
    case RENAME_TAG_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "RenameTag";
        draft.errorMessage.message = action.payload;
      });
    case REMOVE_TAG_START:
      return produce(state, draft => {
        draft.loading.type = "RemoveTag";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case REMOVE_TAG_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.tagsList = action.payload.tagsList;
      });
    case REMOVE_TAG_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "RemoveTag";
        draft.errorMessage.message = action.payload;
      });
    case GET_TAG_USERS_START:
      return produce(state, draft => {
        draft.loading.type = "GetTagUsers";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_TAG_USERS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.tagUsersList = action.payload.tagUsersList;
        draft.tagUsersCount = action.payload.tagUsersCount;
        draft.currentPage = action.payload.currentPage;
      });
    case GET_TAG_USERS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetTagUsers";
        draft.errorMessage.message = action.payload;
      });
    case GET_ALL_TAG_USERS_START:
      return produce(state, draft => {
        draft.loading.type = "GetAllTagUsers";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_ALL_TAG_USERS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.tagUsersList = action.payload.tagUsersList;
      });
    case GET_ALL_TAG_USERS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetAllTagUsers";
        draft.errorMessage.message = action.payload;
      });
    default:
      return state;
  }
};

export default tagsReducer;
