import React from "react";
import { SERVER_URL } from "../../utils/constants";
import "./_style.scss";

const WatchVideo = (props) => {
  const {
    token,
    year,
    month,
    day,
    id,
  } = props.match.params;
  const videoSrc = `${SERVER_URL}/public/shared/download/${token}/${year}/${month}/${day}/${id}`;

  return (
    <video className="video-item" autoPlay controls loop src={videoSrc} />
  )
};

export default WatchVideo;
