import React from 'react';
import { InputNumber } from 'antd';

import './style.scss';

/* IMPLEMENTATION
  <NumericInput
    formatter={value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => value.replace(/\$\s?|(,*)/g, '')}
    suffix={<Icon type="dram" size={13}/>}
    disabled={this.state.disable_dirty_money}
    onChange={this.handleMoneyOnChange}
    /*onBlur={this.salaryIncludedTaxBlur}
    onKeyDown={this.handleOnEnterKeyDown}
  />
*/

const NumericInput = React.forwardRef((props, ref) => {
  const { suffix } = props;

  if (suffix) {
    return (
      <div ref={ref} className={`input-number-wrapper ${suffix && 'has-suffix'}`} >
        <InputNumber
          {...props}
          formatter={value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
        />
        <div className="input-number-suffix-wrapper">
          {suffix}
        </div>
      </div>
    );
  } else {
    return (
      <InputNumber
        ref={ref}
        {...props}
        formatter={value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/\$\s?|(,*)/g, '')}
      />
    );
  }
});

export default NumericInput;