export const SERVER_URL = process.env.REACT_APP_SERVER_URL
export const NDA_PDF_URL = "https://pegate.gradarak.am/NDA_2020.pdf";
export const GOOGLE_CLIENT_ID = "197324876350-lotovoo0301fh6tvek390stsh20ce2ga.apps.googleusercontent.com";
export const CAPTCHA_CLIENT_ID = "6Ld90fQaAAAAAPkqQ2SmtkIczd2KDgXHU463S6XC";
export const INVALID_TOKEN_RESPONSE = "Invalid Token";
export const EXPIRED_TOKEN_RESPONSE = "Expired Token";
export const BID_REFRESH_TIME = 15;

export const MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const PREFORMENCE = [
  { title: 'LOW', value: 'POOR' },
  { title: 'AVERAGE', value: "AVERAGE" },
  { title: 'HIGH', value: 'GREAT' }
];
export const ADMIN_MODE = ['BLIND', 'OPEN', 'ALL'];
export const INVESTOR_MODE = ['BLIND', 'OPEN'];