import produce from "immer";
import {
  SEARCH_USERS_START, SEARCH_USERS_SUCCESS, SEARCH_USERS_FAIL,
  SEARCH_ALL_USERS_START, SEARCH_ALL_USERS_SUCCESS, SEARCH_ALL_USERS_FAIL,
  SEARCH_USERS_FILTER_START, SEARCH_USERS_FILTER_SUCCESS, SEARCH_USERS_FILTER_FAIL,
  GET_USER_START, GET_USER_SUCCESS, GET_USER_FAIL,
  REMOVE_USER_DATA,
  UPDATE_PERMISSIONS_START, UPDATE_PERMISSIONS_SUCCESS, UPDATE_PERMISSIONS_FAIL,
  UPDATE_SETTINGS_START, UPDATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_FAIL,
  SET_ONBOARDED_START, SET_ONBOARDED_SUCCESS, SET_ONBOARDED_FAIL,
  UNSET_ONBOARDED_START, UNSET_ONBOARDED_SUCCESS, UNSET_ONBOARDED_FAIL,
  SEND_ACTIVATION_EMAIL_START, SEND_ACTIVATION_EMAIL_SUCCESS, SEND_ACTIVATION_EMAIL_FAIL,
  SET_USERS_TABLE_PAGINATION,
  CREATE_ADMIN_START, CREATE_ADMIN_SUCCESS, CREATE_ADMIN_FAIL,
  SET_USERS_FILTER, RESET_USERS_FILTER,
  SEARCH_STATISTICS_USERS_START, SEARCH_STATISTICS_USERS_SUCCESS, SEARCH_STATISTICS_USERS_FAIL, RESET_USERS_LIST,
  GET_NOTIFY_ENABLED_START, GET_NOTIFY_ENABLED_SUCCESS, GET_NOTIFY_ENABLED_FAIL,
  SET_NOTIFY_ENABLED_START, SET_NOTIFY_ENABLED_SUCCESS, SET_NOTIFY_ENABLED_FAIL,
  SEARCH_NOT_INVOLVED_USERS_START,
  SEARCH_NOT_INVOLVED_USERS_SUCCESS,
  SEARCH_NOT_INVOLVED_USERS_FAIL,
} from "../constants";

const initialState = {
  loading: {
    type: "",
    state: false
  },
  errorMessage: {
    type: "",
    message: "",
  },
  usersList: null,
  notInvolvedUsers: null,
  usersSearchList: null,
  currentUser: null,
  totalPages: 0,
  totalUsers: 0,
  currentUserBusinesses: null,
  statisticsUsers: null,
  notifyEnabled: false,
  pagination: {
    currentPage: 1
  },
  filter: {
    first_name: '',
    last_name: '',
    country: '',
    investment_from: '',
    investment_to: '',
    future_board_role: '',
    options: '',
  },
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_USERS_START:
      return produce(state, draft => {
        draft.loading.type = "SearchUsers";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEARCH_USERS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        if (!action.payload.filter?.all) {
          draft.usersList = action.payload.usersList;
        }
        draft.totalPages = action.payload.totalPages;
        draft.totalUsers = action.payload.totalUsers;
        draft.filter = action.payload.filter;
        draft.pagination.currentPage = action.payload.currentPage;
      });
    case SEARCH_USERS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SearchUsers";
        draft.errorMessage.message = action.payload;
      });
    case SEARCH_ALL_USERS_START:
      return produce(state, draft => {
        draft.loading.type = "SearchAllUsers";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEARCH_ALL_USERS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        if (!action.payload.filter?.all) {
          draft.usersList = action.payload.usersList;
        }
      });
    case SEARCH_ALL_USERS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SearchAllUsers";
        draft.errorMessage.message = action.payload;
      });
    case SEARCH_NOT_INVOLVED_USERS_START:
      return produce(state, draft => {
        draft.loading.type = "SearchNotInvolvedUsers";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEARCH_NOT_INVOLVED_USERS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        if (!action.payload.filter?.all) {
          draft.notInvolvedUsers = action.payload.notInvolvedUsers;
        }
      });
    case SEARCH_NOT_INVOLVED_USERS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SearchNotInvolvedUsers";
        draft.errorMessage.message = action.payload;
      });
    case SEARCH_USERS_FILTER_START:
      return produce(state, draft => {
        draft.loading.type = "SearchUsersFilter";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEARCH_USERS_FILTER_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.usersSearchList = action.payload.usersSearchList;
      });
    case SEARCH_USERS_FILTER_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SearchUsersFilter";
        draft.errorMessage.message = action.payload;
      });
    case GET_USER_START:
      return produce(state, draft => {
        draft.loading.type = "GetUser";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_USER_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.currentUser = action.payload.currentUser;
        draft.currentUserBusinesses = action.payload.currentUserBusinesses;
      });
    case GET_USER_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetUser";
        draft.errorMessage.message = action.payload;
      });
    case REMOVE_USER_DATA:
      return produce(state, draft => {
        draft.currentUser = null;
        draft.currentUserBusinesses = null;
      });
    case UPDATE_PERMISSIONS_START:
      return produce(state, draft => {
        draft.loading.type = "UpdatePermissions";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case UPDATE_PERMISSIONS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case UPDATE_PERMISSIONS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "UpdatePermissions";
        draft.errorMessage.message = action.payload;
      });
    case UPDATE_SETTINGS_START:
      return produce(state, draft => {
        draft.loading.type = "UpdateSettingsStart";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case UPDATE_SETTINGS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case UPDATE_SETTINGS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "UpdatePermissions";
        draft.errorMessage.message = action.payload;
      });
    case SET_ONBOARDED_START:
      return produce(state, draft => {
        draft.loading.type = "SetOnboarded";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SET_ONBOARDED_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case SET_ONBOARDED_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SetOnboarded";
        draft.errorMessage.message = action.payload;
      });
    case UNSET_ONBOARDED_START:
      return produce(state, draft => {
        draft.loading.type = "UnsetOnboarded";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case UNSET_ONBOARDED_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case UNSET_ONBOARDED_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "UnsetOnboarded";
        draft.errorMessage.message = action.payload;
      });
    case SEND_ACTIVATION_EMAIL_START:
      return produce(state, draft => {
        draft.loading.type = "SendActivationEmail";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEND_ACTIVATION_EMAIL_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;

      });
    case SEND_ACTIVATION_EMAIL_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SendActivationEmail";
        draft.errorMessage.message = action.payload;
      });
    case SET_USERS_TABLE_PAGINATION:
      return produce(state, draft => {
        draft.pagination.currentPage = action.payload;
      });
    case CREATE_ADMIN_START:
      return produce(state, draft => {
        draft.loading.type = "CreateAdmin";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case CREATE_ADMIN_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.usersList = action.payload.usersList;
        draft.totalPages = action.payload.totalPages;
        draft.totalUsers = action.payload.totalUsers;
      });
    case CREATE_ADMIN_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "CreateAdmin";
        draft.errorMessage.message = action.payload;
      });
    case SET_USERS_FILTER:
      return produce(state, draft => {
        draft.filter = action.payload;
      });
    case RESET_USERS_FILTER:
      return produce(state, draft => {
        draft.filter = {
          first_name: '',
          last_name: '',
        };
      });
    case RESET_USERS_LIST:
      return produce(state, draft => {
        draft.usersList = [];
      });
    case SEARCH_STATISTICS_USERS_START:
      return produce(state, draft => {
        draft.loading.type = "SearchStatisticsUsers";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SEARCH_STATISTICS_USERS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.statisticsUsers = action.payload.statisticsUsers;
      });
    case SEARCH_STATISTICS_USERS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SearchStatisticsUsers";
        draft.errorMessage.message = action.payload;
      });
    case GET_NOTIFY_ENABLED_START:
      return produce(state, draft => {
        draft.loading.type = "getNotifyEnabled";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_NOTIFY_ENABLED_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.notifyEnabled = action.payload.notifyEnabled;
      });
    case GET_NOTIFY_ENABLED_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "getNotifyEnabled";
        draft.errorMessage.message = action.payload;
      });
    case SET_NOTIFY_ENABLED_START:
      return produce(state, draft => {
        draft.loading.type = "SetNotifyEnabled";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SET_NOTIFY_ENABLED_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.notifyEnabled = action.payload.notifyEnabled;
      });
    case SET_NOTIFY_ENABLED_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SetNotifyEnabled";
        draft.errorMessage.message = action.payload;
      });
    default:
      return state;
  }
};

export default usersReducer;
