import React from "react";
import { Collapse } from 'antd';

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import './style.scss';

const { Panel } = Collapse;

const FilterBtn = (props) => {
  return (
    <div className={`filter_btn ${props.className}`} style={{...props.style}}>
      <Button
        title="Filters"
        size="lg"
        theme="default"
        mode={props.isButton ? 'bordered' : null}
        prefix={<Icon type="filter" style={{fontSize: 18, marginRight: 10}} />}
        suffix={<Icon type="key-down" style={{fontSize: 14, marginLeft: 10}} />}
        className={`${!props.isButton ? 'btn-link' : 'radius-8' } flex-container align-middle color-grey`}
        style={{fontSize: 18, fontWeight: 'bold', background: props.isButton ? 'initial' : null}}
      />
    </div>
  );
};

const Filter = (props) => {
  return (
    <Collapse className={`filter_collapse ${props.className}`} style={{...props.style}}>
      <Panel header={<FilterBtn isButton={props.isButton} />} key="1">
        {props.children}
      </Panel>
    </Collapse>
  );
};

export default Filter;