import React, {Component} from 'react';
import { Radio } from 'antd';
import Icon from "../components/Icon/Icon";

export default class RadioApp extends Component {
  state = {
    value: 1,
  };

  onChange = e => {
    this.setState({
      value: e.target.value,
    });
  };


  render() {
    return (
      <Radio.Group onChange={this.onChange} value={this.state.value}>
        <div className="radio-template">
          <Radio value={1} id="1">Partnership</Radio>
          <Icon type="user" className="color-grey-800  pined-icon"/>
        </div>

        <div className="radio-template">
          <Radio value={2} id="2">Yes</Radio>
          <Icon type="portfolio" className="color-grey-800 pined-icon"/>
        </div>
      </Radio.Group>
    );
  }
}