import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Collapse } from 'antd';
import history from '../../history';

import { getMediaFileFromServer } from '../../utils/getMediaFileFromServer';
import { detectUserAccess } from '../../utils/detectUserAccess';
import { logout } from '../../redux/user/thunks/logoutThunk';
import { toggleSettingsPopup } from '../../redux/ui/actions/uiActions';
import Icon from '../../components/Icon/Icon';
import './style.scss';

const { Panel } = Collapse;

const Sidebar = (props) => {
  const PANEL_KEY = useMemo(() => 'opportunity-types-panel', []);
  const [isCollapseOpened, setCollapseOpened] = useState(false);

  const isOnboarded = () => {
    const { user, role } = props;
    if (role !== "ADMIN") {
      if (!user) {
        return true;
      }
      return parseInt(user.onboarded) === 1;
    }
    return true;
  };

  const isOnboardingFormFilled = () => {
    const { onboardingData } = props;
    return onboardingData && onboardingData.other_person_interest != null && onboardingData.other_person_interest !== "";
  };

  const printUserFullName = () => {
    const { user } = props;
    if (user) {
      const prefix = user.prefix ? user.prefix : "";
      const first_name = user.first_name ? user.first_name : "";
      const middle_name = user.middle_name ? user.middle_name : "";
      const last_name = user.last_name ? user.last_name : "";
      return `${prefix} ${first_name} ${middle_name} ${last_name}`;
    }
    return "";
  };

  const printUserStatusText = () => {
    const { role, user } = props;

    if (role === "INVESTOR") {
      if (user != null) {
        if (isOnboarded()) {
          return "Onboarded investor";
        }
        else {
          return "Potential investor";
        }
      }
      else {
        return "";
      }
    }
    else {
      const roleWithLowerCase = role.toLowerCase();
      return roleWithLowerCase.charAt(0).toUpperCase() + roleWithLowerCase.slice(1); // setting only first letter uppercase, cause roles come all letters with uppercase
    }
  };

  const getUserAvatar = () => {
    const { user } = props;

    if (user && user.avatar) {
      return getMediaFileFromServer(user.avatar);
    }

    return require("../../assets/img/no_profile.png");
  };

  const isAdmin = () => {
    const { role } = props;
    return role === "ADMIN";
  };

  const detectIsSidebarActive = (match, location, paths = []) => {
    if (match) {
      return true;
    }

    for (let i = 0; i < paths.length; i++) {
      if (location.pathname.includes(paths[i])) {
        return true;
      }
    }

    return false;
  };

  const handleLogout = () => {
    const { logout, xID } = props;
    logout(xID);
    localStorage.clear();
    window.location.replace("/");
  };

  const isCollapseInnerItemActive = () => {
    const pathName = history.location.pathname.substr(1, history.location.pathname.length);
    return pathName === '' || pathName.includes('signin/2fa') || pathName.includes('opportunities') || pathName.includes('project') || pathName.includes('offers') || pathName.includes('offer');
  };

  const menuItems = (hideClass = "") => {
    return (
      <>
        <NavLink
          onClick={() => menuItemOnClick(false)}
          to="/opportunities"
          className={`${hideClass} sidebar_menu_link flex-container align-middle color-grey-800 radius-8`}
          isActive={(match, location) => detectIsSidebarActive(match, location, ['project', 'user-statuses', 'admin-statistics'])}
        >
          <Icon type="crown-filled" size={25} />
          <span className="text text-height-20 font-semibold flex-child-auto">
            Primary deals
          </span>
          {!isAdmin() && (props.ignoredBusinessTypeItemsCount)
            ? (
              <span className="sidebar_menu_link__note radius-circle bg-orange text text-12 font-semibold">
                {props.ignoredBusinessTypeItemsCount}
              </span>
            ) : null}
        </NavLink><NavLink
          exact
          to="/offers"
          onClick={() => menuItemOnClick(false)}
          className={`${hideClass} sidebar_menu_link flex-container align-middle color-grey-800 radius-8`}
          isActive={(match, location) => detectIsSidebarActive(match, location, ['offer', 'bids'])}
        >
          <Icon type="star-filled" size={25} />
          <span className="text text-height-20 font-semibold flex-child-auto">
            Secondary deals
          </span>
        </NavLink></>
    )
  };

  const groupMenuItems = () => {
    return (
      <Collapse
        className='hide-md collapse-with-arrow'
        // defaultActiveKey={isCollapseInnerItemActive() ? PANEL_KEY : ''}
        defaultActiveKey={PANEL_KEY}
        onChange={(openedPanels) => setCollapseOpened(openedPanels.includes(PANEL_KEY))}
      >
        <Panel
          key={PANEL_KEY}
          disabled
          header={(
            <div
              className={`
              sidebar_menu_link sidebar_menu_link_nohover flex-container align-middle color-grey-800 radius-8
              ${isCollapseOpened === false && isCollapseInnerItemActive() ? 'active' : ''}
            `}
            >
              <Icon type="flag" size={25} />
              <span className="text text-height-20 font-semibold flex-child-auto">
                Investment opportunities
              </span>
            </div>
          )}
        >
          {menuItems()}
        </Panel>
      </Collapse>
    )
  };
  
  const { unreadMessagesCount, toggleSettingsPopup, user } = props;
  
  useEffect(() => {
    setCollapseOpened(isCollapseInnerItemActive());
  }, []);
  
  const menuItemOnClick = useCallback((needsToResetCollapse = true) => {
    toggleSettingsPopup(false);
    if (needsToResetCollapse) {
      setCollapseOpened(null);
    }
  }, [toggleSettingsPopup]);

  return (
    <aside className="sidebar bg-white fluid-y flex-container flex-dir-column">
      <div className="sidebar_logo text-center hide-md">
        <Link to="/opportunities">
          <img src={require("../../assets/img/pegate.png")} alt="" />
        </Link>
      </div>

      <div className="sidebar_user text-center hide-md">
        <div className="sidebar_user__img radius-circle clip" onClick={props.settingsPopupShow}>
          <img src={getUserAvatar()} alt="" />
        </div>
        <div className="sidebar_user__name text text-22 color-grey font-bold">
          {printUserFullName()}
        </div>
        <div className="sidebar_user__status text text-14 text-height-20 color-grey-900">
          {printUserStatusText()}
        </div>
        {
          !isOnboarded() && !isOnboardingFormFilled() ?
            <Link to='/onboarding1' className="font-medium color-yellow">
              Click here to be Onboarded
            </Link>
            : null
        }
      </div>

      <div className="sidebar_menu flex-child-grow">
        {
          detectUserAccess('access_investments', user) ?
            (<>
              {groupMenuItems()}
              {menuItems('hide-lg')}
            </>
            ) : null
        }

        {
          !isAdmin() && (
            <NavLink
              to="/"
              exact
              onClick={menuItemOnClick}
              className="sidebar_menu_link flex-container align-middle color-grey-800 radius-8"
            >
              <Icon type="dashboard" size={25} />
              <span className="text text-height-20 font-semibold flex-child-auto">
                Dashboard
              </span>
            </NavLink>
          )
        }

        {
          (!isAdmin() && detectUserAccess('access_portfolio', user)) ?
            <NavLink onClick={menuItemOnClick} to="/portfolio" className="sidebar_menu_link flex-container align-middle color-grey-800 radius-8">
              <Icon type="portfolio" size={25} />
              <span className="text text-height-20 font-semibold flex-child-auto">
                My portfolio
              </span>
            </NavLink>
            : null
        }

        {
          detectUserAccess('access_files', user) ?
            <NavLink onClick={menuItemOnClick} to="/data-room" className="sidebar_menu_link flex-container align-middle color-grey-800 radius-8">
              <Icon type="folders" size={25} />
              <span className="text text-height-20 font-semibold flex-child-auto">
                Data room
              </span>
            </NavLink>
            : null
        }

        {
          detectUserAccess('access_messages', user) ?
            <NavLink
              to="/contact-center"
              className="sidebar_menu_link flex-container align-middle color-grey-800 radius-8"
              isActive={(match, location) => detectIsSidebarActive(match, location, ['contact-center-inner'])}
              onClick={menuItemOnClick}
            >
              <Icon type="chat" size={22} />
              <span className="text text-height-20 font-semibold flex-child-auto">
                Contact <br /> center
              </span>
              {
                unreadMessagesCount !== 0 &&
                <span className="sidebar_menu_link__note radius-circle bg-orange text text-12 font-semibold unread">
                  {unreadMessagesCount}
                </span>
              }
            </NavLink>
            : null
        }

        {
          isAdmin() && detectUserAccess('access_accounts', user) ?
            <NavLink
              to="/admin-users"
              className="sidebar_menu_link flex-container align-middle color-grey-800 radius-8"
              isActive={(match, location) => detectIsSidebarActive(match, location, ['admin-users-inner', 'admin-group-users', 'notes'])}
              onClick={menuItemOnClick}
            >
              <Icon type="users" size={25} />
              <span className="text text-height-20 font-semibold flex-child-auto">
                Users
              </span>
            </NavLink>
            : null
        }
      </div>

      <div className="sidebar_footer hide-md">
        <button
          className="btn-initial cursor sidebar_footer_link flex-container align-middle color-grey-800 radius-8"
          onClick={handleLogout}
        >
          <Icon type="logout" size={25} />
          <span className="text text-height-20 font-semibold">
            Log out
          </span>
        </button>
      </div>
      <div className="sidebar_links flex-container hide-md">
        <a href="https://www.pe-gate.com/privacy-policy" target="_blank" className="sidebar_links_item text text-13 text-height-17" rel="noopener noreferrer">
          Our Privacy Policy
        </a>
        <a href="https://www.pe-gate.com/terms-of-use" target="_blank" className="sidebar_links_item text text-13 text-height-17" rel="noopener noreferrer">
          Our Terms of Use
        </a>
        <a href="https://static1.squarespace.com/static/5a4aeb9af43b5576b2948693/t/60da2a9afba11d2707f2b2e1/1624910490599/CLIENT+RELATIONSHIP++DISCLOSURE+INFORMATION+-+June+2021.pdf" target="_blank" className="sidebar_links_item text-13 text-height-17" rel="noopener noreferrer">
          Client Relationship Disclosure Information
        </a>
      </div>
    </aside>
  )
};

const mapStateToProps = state => ({
  user: state.user.userInfo,
  xID: state.user.xID,
  onboardingData: state.registration.onboardingData,
  role: state.user.role,
  businesses: state.business.businesses,
  inboxMessages: state.messages.inboxMessages,
  unreadMessagesCount: state.messages.unreadMessagesCount,
  ignoredBusinessTypeItemsCount: state.business.ignoredBusinessTypeItemsCount,
});

const mapDispatchToProps = dispatch => ({
  logout: (id) => dispatch(logout(id)),
  toggleSettingsPopup: (status) => dispatch(toggleSettingsPopup(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
