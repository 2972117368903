import React, { Component } from "react";
import { connect } from "react-redux";
import {
  message,
  Spin,
  Table,
  Tabs,
} from "antd";

import history from "../../history";
import { getMessages } from "../../redux/messages/thunks/messagesThunks";
import { resetMessagesFilter } from "../../redux/messages/actions/messagesActions";
import { setMessagesTablePagination, setMessagesCurrentTab } from "../../redux/messages/actions/tableActions";
import { searchUsers } from "../../redux/users/thunks/searchUsersThunk";
import { handleErrorMessage } from "../../utils/handleErrorMessage";
import { formatDate } from "../../utils/formatDate";
import { cutString } from "../../utils/cutString";
import { handleLoading } from "../../utils/handleLoading";
import { priorityColorsDetector } from "../../utils/priorityColorsDetector";

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import Filter from "./Filter";
import MessageWindow from "../../components/MessageWindow/MessageWindow";
import "./style.scss";

const {TabPane} = Tabs;

const inboxColumns = [
  {
    title: "From",
    dataIndex: "from",
    key: "from",
    render: (text) =>
      <div className="chat_from">
        <i className="icon icon-message-opened" />
        {text}
      </div>,
  },
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Subject",
    dataIndex: "subject",
    key: "subject",
  },
  {
    title: "Importance",
    dataIndex: "importance",
    key: "importance",
    render: (text) => {
      const priorityTextLowerCased = text.toLowerCase();
      const priorityColor = priorityColorsDetector(priorityTextLowerCased);

      return <div className={`imp_tag imp_tag_${priorityColor} font-uppercase`}>{text}</div>;
    },
  },
  {
    title: "Investment",
    dataIndex: "investment",
    key: "investment",
    render: (text) => text && <div className="invest_tag font-uppercase" title={text}>{text}</div>,
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
    render: text => text.length > 20 ? `${cutString(text, 0, 20, true)}...` : text,
  },
];
const sentColumns = [
  {
    title: "To",
    dataIndex: "to",
    key: "to",
    render: (text) =>
      <div className="chat_from">
        <Icon type="send" size={17}/>
        {text}
      </div>,
  },
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Subject",
    dataIndex: "subject",
    key: "subject",
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
    render: text => text.length > 20 ? `${cutString(text, 0, 20, true)}...` : text,
  },
];

class ContactCenter extends Component {
  constructor(props) {
    super(props);
    const { id, name } = this.props.match.params;

    this.state = {
      selectedRowKeysInbox: [],
      selectedRowKeysSent: [],
      messageWindow: id && name ? true : false,
    }
  }

  toggleMessageWindow = status => {
    this.setState({ messageWindow: status });
  };

  handleInboxRowSelection = () => {
    return {
      onChange: (selectedRowKeysInbox) => {
        this.setState({selectedRowKeysInbox});
      },
      getCheckboxProps: record => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };
  };

  handleOnRowClick = (type, messageItemKey) => {
    history.push(`/contact-center-inner/${type}/${messageItemKey}`);
  };

  renderInboxOperationSlot = () => {
    const { userInfo } = this.props;
    const hasAccessToWriteMessages = userInfo && userInfo.write_messages === 1;
    const handleNewMessageAccessErrorText = () => message.error("Access write messages");

    return (
      <div className="flex-container align-middle">
        <div onClick={!hasAccessToWriteMessages ? handleNewMessageAccessErrorText : null} >
          <Button
            title="New Message"
            prefix={<Icon type="plus" size={18} style={{marginRight: 10}} />}
            size="lg"
            theme="blue"
            className="btn-shadow radius-8 add_message sm_circle"
            type="button"
            disabled={!hasAccessToWriteMessages}
            onClick={() => this.toggleMessageWindow(true)}
          />
        </div>
      </div>
    );
  };

  getMessagesInboxList = () => {
    let { inboxMessages } = this.props;
    let messagesList = [];
    inboxMessages = inboxMessages ? inboxMessages : [];

    inboxMessages.map(element => {
      return messagesList.push({
        key: element.id ? element.id : "",
        from: (element.first_name && element.last_name) ? `${element.first_name} ${element.last_name}` : "",
        date: element.created ? formatDate(element.created) : "",
        subject: element.subject ? element.subject : "",
        importance: element.priority ? element.priority : "",
        investment: element.business_name ? element.business_name : "",
        message: element.body ? element.body : "",
        unread: !element.seen,
      });
    });

    return messagesList;
  };

  getMessagesSentList = () => {
    let { sentMessages } = this.props;
    let messagesList = [];
    sentMessages = sentMessages ? sentMessages : [];

    sentMessages.map(element => {
      return messagesList.push({
        key: element.id ? element.id : "",
        to: (element.first_name && element.last_name) ? `${element.first_name} ${element.last_name}` : "",
        date: element.created ? formatDate(element.created) : "",
        subject: element.subject ? element.subject : "",
        message: element.body ? element.body : ""
      });
    });

    return messagesList;
  };

  getOpenedUserName = () => {
    const { id, name } = this.props.match.params;
    return { id, name };
  };

  handleTabPaneOnChange = messageType => {
    const {
      getMessages,
      setMessagesCurrentTab,
      pagination,
      filter,
    } = this.props;
    const currentPage = pagination[messageType === "inbox" ? "inbox" : "sent"].currentPage;

    getMessages(messageType, currentPage, filter);
    setMessagesCurrentTab(messageType);
  };

  handleTablePagination = (event, messageType) => {
    const { setMessagesTablePagination, getMessages, filter } = this.props;

    getMessages(messageType, event.current, filter);
    setMessagesTablePagination({
      messageType,
      currentPage: event.current
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage } = this.props;

    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "AddMessage")) {
      message.error(errorMessage.message);
    }
  }

  componentWillUnmount() {
    this.props.resetMessagesFilter();
  }

  componentDidMount() {
    const {
      getMessages,
      usersList,
      searchUsers,
      role,
      pagination,
      filter,
    } = this.props;
    const inboxCurrentPage = pagination.inbox.currentPage;
    const sentCurrentPage = pagination.sent.currentPage;
    getMessages("inbox", inboxCurrentPage, filter);
    getMessages("sent", sentCurrentPage, filter);

    if ((!usersList || !usersList.length) && role === "ADMIN") {
      searchUsers(1);
    }
  }

  render() {
    const {
      pagination,
      currentMessageTab,
      inboxMessagesCount,
      sentMessagesCount,
      loading,
      match,
    } = this.props;
    const { type } = match.params;
    const { messageWindow } = this.state;
    const messagesInboxList = this.getMessagesInboxList();
    const messagesSentList = this.getMessagesSentList();

    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                <h1>Contact center</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='column small-12'>
            <Filter />
          </div>
        </div>
        <div className="row">
          <div className="column small-12">
            <Tabs
              defaultActiveKey={currentMessageTab}
              className="c-table c-table-contacts tab_default contact_tab tab_sticky"
              tabBarExtraContent={this.renderInboxOperationSlot()}
              onChange={this.handleTabPaneOnChange}
            >
              <TabPane
                tab={
                  <div className="flex-container align-middle">
                    <Icon type="inbox" size={25} style={{marginRight: 6}}/>
                    Inbox
                  </div>
                }
                key="inbox"
              >
                <Spin spinning={handleLoading(loading, "GetMessages")} >
                  <Table
                    className="contact_table"
                    columns={inboxColumns}
                    dataSource={messagesInboxList}
                    rowClassName={record => record.unread ? "unread-row" : ""}
                    onRow={(messageItem) => ({onClick: () =>  this.handleOnRowClick("inbox", messageItem.key)})}
                    onChange={event => this.handleTablePagination(event, "inbox")}
                    pagination={{
                      current: pagination.inbox.currentPage,
                      total: inboxMessagesCount * 10
                    }}
                  />
                </Spin>
              </TabPane>
              <TabPane
                tab={
                  <div className="flex-container align-middle">
                    <Icon type="sent" size={20} style={{marginRight: 9}}/>
                    Sent
                  </div>
                }
                key="sent"
              >
                <Spin spinning={handleLoading(loading, "GetMessages")} >
                  <Table
                    className="contact_table contact_table_sent"
                    columns={sentColumns}
                    dataSource={messagesSentList}
                    onRow={(messageItem) => ({onClick: () => this.handleOnRowClick("sent", messageItem.key)})}
                    onChange={event => this.handleTablePagination(event, "sent")}
                    pagination={{
                      current: pagination.sent.currentPage,
                      total: sentMessagesCount * 10
                    }}
                  />
                </Spin>
              </TabPane>
            </Tabs>
          </div>

          <div className="column small-12">
            <MessageWindow
              deactivate={() => this.toggleMessageWindow(false)}
              visibility={messageWindow}
              filledData={this.getOpenedUserName()}
              fillingDataType={type}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.messages.loading,
  errorMessage: state.messages.errorMessage,
  inboxMessages: state.messages.inboxMessages,
  sentMessages: state.messages.sentMessages,
  userInfo: state.user.userInfo,
  usersList: state.users.usersList,
  pagination: state.messages.pagination,
  currentMessageTab: state.messages.currentMessageTab,
  role: state.user.role,
  inboxMessagesCount: state.messages.inboxMessagesCount,
  sentMessagesCount: state.messages.sentMessagesCount,
  filter: state.messages.filter,
});

const mapDispatchToProps = dispatch => ({
  getMessages: (type, currentPage, params) => dispatch(getMessages(type, currentPage, params)),
  searchUsers: currentPage => dispatch(searchUsers(currentPage)),
  setMessagesTablePagination: payload => dispatch(setMessagesTablePagination(payload)),
  setMessagesCurrentTab: payload => dispatch(setMessagesCurrentTab(payload)),
  resetMessagesFilter: () => dispatch(resetMessagesFilter()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactCenter);