import React, {Component} from "react";
import {connect} from "react-redux";
import {
  Checkbox,
  Form,
  Input,
  Radio,
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import history from "../../history";

import {fillUserRegistrationInfo, resetUserRegistrationInfo} from "../../redux/registration/actions/registrationActions";
import {registerUser} from "../../redux/registration/thunks/registrationThunks";
import { emailExists, phoneExists } from "../../redux/registration/thunks/registrationDataThunks";
import {emailRegExp, specialCharacterRegExp, upperCaseCharacterRegExp} from "../../utils/regexps";

import Icon from "../../components/Icon/Icon";
import Button from "../../components/Button/Button";
import { CAPTCHA_CLIENT_ID } from "../../utils/constants";
import { handleLoading } from "../../utils/handleLoading";

class PersonalInformationForm extends Component {
  constructor(props) {
    super(props);
    this.registerActionsStarted = false;
    this.state = {
      validation8Chars: {
        isFilled: false,
        isValid: false,
      },
      validationSpecialCharacter: {
        isFilled: false,
        isValid: false,
      },
      validationUpperCase: {
        isFilled: false,
        isValid: false,
      },
      isTermsOfUseSelected: false,
      isPrivacyPolicySelected: false,
      captchaToken: null,
    };
  }

  emailOnBlur = (event) => {
    const { value } = event.target;
    const { emailExists, form } = this.props;

    if (value) {
      if (emailRegExp.test(value)) {
        emailExists(value);
      }
      else {
        form.setFields({
          email: {
            value,
            errors: [new Error("Invalid email")]
          }
        });
      }
    }
  };

  phoneOnBlur = (event) => {
    const { value } = event.target;
    const { phoneExists } = this.props;

    if (value) {
      phoneExists(value);
    }
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const {
          fillUserRegistrationInfo,
          emailExists,
          phoneExists,
          userInfo,
          isEntity,
          registerUser,
        } = this.props;
        const { captchaToken } = this.state;

        delete values.repeat_password;
        if (values.statusType) {
          delete values.statusType;
        }

        values.first_name = values.first_name ? values.first_name : "";
        values.last_name = values.last_name ? values.last_name : "";
        values.middle_name = values.middle_name ? values.middle_name : "";
        values.email = values.email ? values.email : "";
        values.linkedin = values.linkedin ? values.linkedin : "";
        values.phone = values.phone ? values.phone.replace(/\D/g,'') : "";
        values.password = values.password ? values.password : "";

        this.registerActionsStarted = true;
        emailExists(values.email, (doesEmailExists) => {
          phoneExists(values.phone, (doesPhoneExists) => {
            if (!doesEmailExists && !doesPhoneExists) {
              values.captcha = captchaToken;
              const param = {
                ...userInfo,
                ...values,
                is_entity: isEntity
              };
              fillUserRegistrationInfo(values);
              registerUser("investor", param);
            }
            else {
              if (doesEmailExists) {
                this.validateEmailField(true);
              }
              if (doesPhoneExists) {
                this.validatePhoneField(true);
              }
            }
          });
        });
      }
    })
  };

  userStatusTypeOnChange = () => {
    const {userInfo, resetUserRegistrationInfo} = this.props;

    if (Object.keys(userInfo).length) {
      resetUserRegistrationInfo();
    }
    history.push("/entity-information");
  };

  validateEmailField = (doesEmailExist) => {
    const { form } = this.props;

    if (doesEmailExist) {
      form.setFields({
        email: {
          value: form.getFieldValue("email"),
          errors: [new Error("Duplicate email")]
        }
      });
    }
  };

  validatePhoneField = (doesPhoneExist) => {
    const { form } = this.props;

    if (doesPhoneExist) {
      form.setFields({
        phone: {
          value: form.getFieldValue("phone"),
          errors: [new Error("Duplicate phone")]
        }
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { loading, errorMessage, doesEmailExist, doesPhoneExist } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;
    // const errorType = errorMessage.type;

    if (!errorText && !loadingState && prevProps.loading.state) {
      switch (loadingType) {
        case "EmailExists":
          this.validateEmailField(doesEmailExist);
          break;
        case "PhoneExists":
          this.validatePhoneField(doesPhoneExist);
          break;
        default:
      }
    }
  }

  passwordValidator = async (rule, value) => {
    if (value) {
      let isValid = true;
      if (value.length < 8) {
        this.setState({
          validation8Chars: {
            isFilled: true,
            isValid: false,
          },
        });
        isValid = false;
      }
      else {
        this.setState({
          validation8Chars: {
            isFilled: true,
            isValid: true,
          },
        });
      }
      if (!specialCharacterRegExp.test(value)) {
        this.setState({
          validationSpecialCharacter: {
            isFilled: true,
            isValid: false,
          },
        });
        isValid = false;
      }
      else {
        this.setState({
          validationSpecialCharacter: {
            isFilled: true,
            isValid: true,
          },
        });
      }
      if (!upperCaseCharacterRegExp.test(value)) {
        this.setState({
          validationUpperCase: {
            isFilled: true,
            isValid: false,
          },
        });
        isValid = false;
      }
      else {
        this.setState({
          validationUpperCase: {
            isFilled: true,
            isValid: true,
          },
        });
      }

      if (!isValid) {
        return Promise.reject('');
      }
    }
    else {
      this.setState({
        validation8Chars: {
          isFilled: false,
          isValid: false,
        },
        validationSpecialCharacter: {
          isFilled: false,
          isValid: false,
        },
        validationUpperCase: {
          isFilled: false,
          isValid: false,
        },
      });
    }
  };

  toggleCondition = (state) => {
    this.setState(prevState => ({
      [state]: !prevState[state],
    }));
  };

  captchaOnChange = (value) => this.setState({ captchaToken: value });

  render() {
    const {
      statusType,
      showStatusType,
      initialValues,
      loading,
    } = this.props;
    const {getFieldDecorator, getFieldValue} = this.props.form;
    const {
      validation8Chars,
      validationSpecialCharacter,
      validationUpperCase,
      isTermsOfUseSelected,
      isPrivacyPolicySelected,
      captchaToken,
    } = this.state;

    return (
      <div className="row">
        <div className="column small-12 x-large-10">
          <Form onSubmit={this.onSubmit} className="sign_out_form_card bg-white radius-10 shadow-layout">
            <div className="mb_45">
              {
                showStatusType &&
                <div className="form-item">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    SELECT YOUR STATUS
                  </div>
                  <Form.Item>
                    {getFieldDecorator("statusType", {
                      initialValue: statusType,
                      rules: [{required: false, message: "Please fill out this field"}],
                    })(
                      <Radio.Group onChange={this.userStatusTypeOnChange}>
                        <div className="row">
                          <div className="column small-12 large-6">
                            <div className="radio-template">
                              <Radio value={1} id="1">Individual</Radio>
                              <Icon type="user" className="color-grey-800 pined-icon"/>
                            </div>
                          </div>
                          <div className="column small-12 large-6">
                            <div className="radio-template">
                              <Radio value={2} id="2">Fund / Entity</Radio>
                              <Icon type="portfolio" className="color-grey-800 pined-icon"/>
                            </div>
                          </div>
                        </div>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>
              }

              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  FIRST NAME
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator("first_name", {
                    initialValue: initialValues.first_name,
                    rules: [{required: true, message: "Please fill out this field"}],
                  })(
                    <Input/>
                  )}
                </Form.Item>
              </div>
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  LAST NAME
                </div>
                <Form.Item>
                  {getFieldDecorator("last_name", {
                    initialValue: initialValues.last_name,
                    rules: [{required: true, message: "Please fill out this field"}],
                  })(
                    <Input/>
                  )}
                </Form.Item>
              </div>
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  MIDDLE NAME
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator("middle_name", {
                    initialValue: initialValues.middle_name,
                    rules: [{required: false, message: "Please fill out this field"}],
                  })(
                    <Input/>
                  )}
                </Form.Item>
                <div className="form-item-hint text text-12 font-medium font-spacing-02 color-grey-800">Optional</div>
              </div>
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  EMAIL ADDRESS
                </div>
                <Form.Item>
                  {getFieldDecorator("email", {
                    initialValue: initialValues.email,
                    rules: [{required: true, message: "Please fill out this field"}],
                  })(
                    <Input onBlur={this.emailOnBlur} type="email"/>
                  )}
                </Form.Item>
              </div>
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  LINKEDIN PROFILE LINK
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator("linkedin", {
                    initialValue: initialValues.linkedin,
                    rules: [
                      {required: false, message: "Please fill out this field"},
                      {min: 29, message: "LinkedIn profile link must contain more then 29 chars"}
                    ],
                  })(
                    <Input />
                  )}
                </Form.Item>
                <div className="form-item-hint text text-12 font-medium font-spacing-02 color-grey-800">Optional</div>
              </div>
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  MOBILE PHONE NUMBER
                </div>
                <Form.Item>
                  {getFieldDecorator("phone", {
                    initialValue: initialValues.phone,
                    rules: [{required: true, message: "Please fill out this field"}],
                  })(
                    <Input onBlur={this.phoneOnBlur} />
                  )}
                </Form.Item>
              </div>
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  PASSWORD
                </div>
                <Form.Item>
                  {getFieldDecorator("password", {
                    initialValue: initialValues.password,
                    rules: [
                      {required: true, message: "Please fill out this field"},
                      {validator: this.passwordValidator},
                    ],
                    validateTrigger: "onBlur"
                  })(
                    <Input.Password />
                  )}
                </Form.Item>
                <div className="validators-wrapper" >
                  <p
                    className="mb_0"
                    style={validation8Chars.isFilled && validation8Chars.isValid ? { color: 'green' } : null}
                  >
                    <Icon
                      type='checked'
                      style={validation8Chars.isFilled && validation8Chars.isValid ? { color: 'green' } : null}
                    />
                    At least 8 character
                  </p>
                  <p
                    className="mb_0"
                    style={validationSpecialCharacter.isFilled && validationSpecialCharacter.isValid ? { color: 'green' } : null}
                  >
                    <Icon
                      type='checked'
                      style={validationSpecialCharacter.isFilled && validationSpecialCharacter.isValid ? { color: 'green' } : null}
                    />
                    At least 1 special character
                  </p>
                  <p
                    className="mb_0"
                    style={validationUpperCase.isFilled && validationUpperCase.isValid ? { color: 'green' } : null}
                  >
                    <Icon
                      type='checked'
                      style={validationUpperCase.isFilled && validationUpperCase.isValid ? { color: 'green' } : null}
                    />
                    At least 1 upper case character
                  </p>
                </div>
              </div>
              <div className="form-item">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  REPEAT PASSWORD
                </div>
                <Form.Item>
                  {getFieldDecorator("repeat_password", {
                    dependencies: ['password'],
                    hasFeedback: true,
                    initialValue: initialValues.password,
                    rules: [
                      {required: true, message: "Please fill out this field"},
                      {
                        min: 8,
                        message: "Password should contain minimum 8 characters"
                      },
                      {
                        validator: async (rule, value) => {
                          if (value && getFieldValue("password") !== value && value.length >= 8) {
                            return Promise.reject('The two passwords that you entered do not match!');
                          }
                          return Promise.resolve();
                        }
                      }
                    ],
                    validateTrigger: "onBlur"
                  })(
                    <Input.Password />
                  )}
                </Form.Item>
              </div>
            </div>

            <div className="form-item form-item-lg" >
              <div className="mb_0">
                <Checkbox
                  className="flex-container align-middle"
                  onChange={() => this.toggleCondition("isTermsOfUseSelected")}
                >
                  I agree in to the <a target="_blank" rel="noopener noreferrer" href="https://www.pe-gate.com/terms-of-use">Terms of Use</a> of PE Gate Partners
                </Checkbox>
              </div>
            </div>

            <div className="form-item form-item-lg">
              <div className="mb_0">
                <Checkbox
                  className="flex-container align-middle"
                  onChange={() => this.toggleCondition('isPrivacyPolicySelected')}
                >
                  I agree in to the <a target="_blank" rel="noopener noreferrer" href="https://www.pe-gate.com/privacy-policy">Privacy Policy</a> of PE Gate Partners
                </Checkbox>
              </div>
            </div>

            {
              isTermsOfUseSelected && isPrivacyPolicySelected ?
                <div className="flex-container align-center mb_25" >
                  <ReCAPTCHA sitekey={CAPTCHA_CLIENT_ID} onChange={this.captchaOnChange} />
                </div>
                : null
            }

            <div className="text-center form_submit">
              <Form.Item className="mb_0">
                <Button
                  title="Continue"
                  size="xl"
                  theme="blue"
                  className="btn-shadow radius-8"
                  type="submit"
                  loading={this.registerActionsStarted && (handleLoading(loading, 'EmailExists') || handleLoading(loading, 'PhoneExists') || handleLoading(loading, "RegisterUser"))}
                  disabled={!(isTermsOfUseSelected && isPrivacyPolicySelected && captchaToken)}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

const PersonalInformationFormScreen = Form.create()(PersonalInformationForm);

const mapStateToProps = state => ({
  loading: state.registration.loading,
  errorMessage: state.registration.errorMessage,
  userInfo: state.registration.userInfo,
  doesEmailExist: state.registration.emailExists,
  doesPhoneExist: state.registration.phoneExists
});

const mapDispatchToProps = dispatch => ({
  fillUserRegistrationInfo: userInfo => dispatch(fillUserRegistrationInfo(userInfo)),
  resetUserRegistrationInfo: () => dispatch(resetUserRegistrationInfo()),
  emailExists: (email, callback) => dispatch(emailExists(email, callback)),
  phoneExists: (phone, callback) => dispatch(phoneExists(phone, callback)),
  registerUser: (userType, param) => dispatch(registerUser(userType, param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformationFormScreen);
