import {
  EMAIL_EXISTS_START, EMAIL_EXISTS_SUCCESS, EMAIL_EXISTS_FAIL,
  PHONE_EXISTS_START, PHONE_EXISTS_SUCCESS, PHONE_EXISTS_FAIL
} from "../../constants";

export const emailExistsStart = () => ({
  type: EMAIL_EXISTS_START
});

export const emailExistsSuccess = payload => ({
  type: EMAIL_EXISTS_SUCCESS,
  payload
});

export const emailExistsFail = payload => ({
  type: EMAIL_EXISTS_FAIL,
  payload
});

export const phoneExistsStart = () => ({
  type: PHONE_EXISTS_START
});

export const phoneExistsSuccess = payload => ({
  type: PHONE_EXISTS_SUCCESS,
  payload
});

export const phoneExistsFail = payload => ({
  type: PHONE_EXISTS_FAIL,
  payload
});