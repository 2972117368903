import React, {Component} from "react";
import {connect} from "react-redux";
import {setOnboardingData, getOnboarding} from "../../redux/registration/thunks/registrationThunks";
import {setOptions, getOptions, clearOptions} from "../../redux/dictionaries/thunks/dictionariesOptionsThunk";
import {findDictionaryByID} from "../../utils/findDictionaryByID";
import {handleErrorMessage} from "../../utils/handleErrorMessage";

import {Form, Radio, Checkbox, message, Tooltip, Row} from "antd";
import OnboardingHeader from "./OnboardingHeader";
import OnboardingFooter from "./OnboardingFooter";
import NumericInput from "../../components/NumericInput/NumericInput";
import Icon from "../../components/Icon/Icon";
import {sortOptionsAlphabetically} from "../../utils/sortOptionsAlphabetically";


class OnboardingStep5 extends Component {

  getInitialValues = () => {
    const {onboardingData, optionsList} = this.props;
    let returningData = {
      // income_personal: "",
      // income_family: "",
      invested_businesses: "",
      number_of_businesses: "",
      // past_investment_sectors: [],
      // preferred_countries: [],
      other_asset_classes: [],
      past_board_role: "",
      future_board_role: "",
      investor_sectors: [],
      // investing_experience: "",
      // investor_activism: "",
      investing_pb_knowledge: "",
      investing_fm_knowledge: ""
    };

    if (onboardingData) {
      returningData = {
        // income_personal: onboardingData.income_personal,
        // income_family: onboardingData.income_family,
        invested_businesses: onboardingData.invested_businesses ? onboardingData.invested_businesses : 0,
        number_of_businesses: onboardingData.number_of_businesses ? onboardingData.number_of_businesses : 0,
        past_board_role: onboardingData.past_board_role ? onboardingData.past_board_role : 0,
        future_board_role: onboardingData.future_board_role ? onboardingData.future_board_role : 0,
        // investing_experience: onboardingData.investing_experience,
        // investor_activism: onboardingData.investor_activism,
        investing_pb_knowledge: onboardingData.investing_pb_knowledge ? onboardingData.investing_pb_knowledge : 0,
        investing_fm_knowledge: onboardingData.investing_fm_knowledge ? onboardingData.investing_fm_knowledge : 0
      };

      if (optionsList && optionsList.length) {
        let pastInvestOptionsArray = [];
        let preferredInvestCountriesOptionsArray = [];
        let otherAssetClassesOptionsArray = [];
        let investorSectorsOptionsArray = [];

        const pastInvestFound = optionsList.find(element => parseInt(element.type) === 23);
        const preferredInvestCountriesFound = optionsList.find(element => parseInt(element.type) === 25);
        const otherAssetClassesFound = optionsList.find(element => parseInt(element.type) === 26);
        const investorSectorFound = optionsList.find(element => parseInt(element.type) === 5);

        const pastInvestOptions = pastInvestFound ? pastInvestFound.options : [];
        const preferredInvestCountriesOptions = preferredInvestCountriesFound ? preferredInvestCountriesFound.options : [];
        const otherAssetClassesOptions = otherAssetClassesFound ? otherAssetClassesFound.options : [];
        const investorSectorOptions = investorSectorFound ? investorSectorFound.options : [];

        pastInvestOptions.map(element => pastInvestOptionsArray.push(parseInt(element.option)));
        preferredInvestCountriesOptions.map(element => preferredInvestCountriesOptionsArray.push(parseInt(element.option)));
        otherAssetClassesOptions.map(element => otherAssetClassesOptionsArray.push(parseInt(element.option)));
        investorSectorOptions.map(element => investorSectorsOptionsArray.push(parseInt(element.option)));

        // returningData.past_investment_sectors = pastInvestOptionsArray;
        // returningData.preferred_countries = preferredInvestCountriesOptionsArray;
        returningData.other_asset_classes = otherAssetClassesOptionsArray;
        returningData.investor_sectors = investorSectorsOptionsArray;
      }
    }

    return returningData;
  };

  handleSetOptions = (optionID, fieldName) => {
    const {form, opportunityGroups} = this.props;
    const {getFieldValue} = form;

    const fieldValue = getFieldValue(fieldName);
    const optionsArray = findDictionaryByID(optionID, opportunityGroups);

    if (fieldValue.length) {
      const { setOptions } = this.props;
      let param = {
        optgroup: optionID
      };

      fieldValue.map((element, index) => {
        const currentSelectedOptions = optionsArray.find(item => parseInt(item.id) === parseInt(element));

        param[`options[${index}][id]`] = currentSelectedOptions.id;
        param[`options[${index}][value]`] = 0;

        return "";
      });

      setOptions(param);
    }
    else {
      const {clearOptions} = this.props;
      clearOptions(optionID);
    }
  };

  setOnboardingInfo = (type, callback) => { // type can be prev, next or save
    if (type !== 'prev') {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          (parseInt(values.invested_businesses) === 0) && (values.number_of_businesses = "");
          delete values.other_asset_classes;
          // delete values.past_investment_sectors;
          // delete values.preferred_countries;

          // this.handleSetOptions(23, "past_investment_sectors");
          // this.handleSetOptions(25, "preferred_countries");
          this.handleSetOptions(26, "other_asset_classes");
          this.handleSetOptions(5, "investor_sectors");

          if (!values.investing_pb_knowledge) {
            delete values.investing_pb_knowledge;
          }
          if (!values.investing_fm_knowledge) {
            delete values.investing_fm_knowledge;
          }
          callback(values);
        }
      });
    }
    else {
      const values = this.props.form.getFieldsValue();
      (parseInt(values.invested_businesses) === 0) && (values.number_of_businesses = "");
      delete values.other_asset_classes;
      // delete values.past_investment_sectors;
      // delete values.preferred_countries;

      // this.handleSetOptions(23, "past_investment_sectors");
      // this.handleSetOptions(25, "preferred_countries");
      this.handleSetOptions(26, "other_asset_classes");
      this.handleSetOptions(5, "investor_sectors");

      if (!values.investing_pb_knowledge) {
        delete values.investing_pb_knowledge;
      }
      if (!values.investing_fm_knowledge) {
        delete values.investing_fm_knowledge;
      }
      callback(values);
    }
  };

  onSubmit = event => {
    event.preventDefault();
    const { setOnboardingData } = this.props;

    this.setOnboardingInfo('save',(values) => {
      setOnboardingData(values);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const {loading, errorMessage} = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    // const errorType = errorMessage.type;
    const errorText = errorMessage.message;

    if (!errorText && !loadingState && prevProps.loading.state) {
      if (loadingType === "SetOnboardingData") {
        message.success("Saved");
      }
    }

    if (handleErrorMessage(errorMessage, prevProps.errorMessage, "SetOptions")) {
      message.error(errorText);
    }
  }

  componentDidMount() {
    window.scroll(0, 0);
    const {getOnboarding} = this.props;
    getOnboarding();
    // getOptions([23, 25, 26]);
  }

  render() {
    const { form, opportunityGroups } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    // const incomePersonalOpportunitiesOptions = findDictionaryByID(19, opportunityGroups);
    // const incomeFamilyOpportunitiesOptions = findDictionaryByID(20, opportunityGroups);
    // const pastInvestmentSectorsOptions = findDictionaryByID(23, opportunityGroups);
    // const preferredInvestmentCountriesOptions = findDictionaryByID(25, opportunityGroups);
    const otherAssetClassesOptions = sortOptionsAlphabetically(findDictionaryByID(26, opportunityGroups));
    const knowledgeInPrivateBusinessOptions = findDictionaryByID(27, opportunityGroups);
    const knowledgeInFinancialMarketsOptions = findDictionaryByID(28, opportunityGroups);
    const industriesOptions = sortOptionsAlphabetically(findDictionaryByID(5, opportunityGroups), true);
    const {
      // income_personal,
      // income_family,
      investor_sectors,
      invested_businesses,
      number_of_businesses,
      // past_investment_sectors,
      // preferred_countries,
      other_asset_classes,
      past_board_role,
      future_board_role,
      // investing_experience,
      // investor_activism,
      investing_pb_knowledge,
      investing_fm_knowledge
    } = this.getInitialValues();

    return (
      <OnboardingHeader step={3} >
        <div className="row">
          <div className="column small-12 x-large-10">
            <Form onSubmit={this.onSubmit} >
              <div className="form_card bg-white radius-10 shadow-layout mb_45">
                <div className="large-11 mb_50">
                  {/*<div className="form-item mb_35">
                    <div className="form-item-label text text-height-20 font-semibold color-grey mb_10 large-8">
                      Your average net income before tax ALONE for each of the past two calendar years and,
                      with reasonable expectations in the current year:
                    </div>
                    <Form.Item>
                      {getFieldDecorator("income_personal", {
                        initialValue: income_personal,
                        rules: [{required: false, message: "Please fill out this field"}]
                      })(
                        <Radio.Group name="netIncome">
                          {
                            incomePersonalOpportunitiesOptions.map(element => (
                              <Radio key={element.id} value={element.id}>{element.name}</Radio>
                            ))
                          }
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>*/}

                  {/*<div className="form-item mb_35">
                    <div className="form-item-label text text-height-20 font-semibold color-grey mb_10 large-8">
                      Your investable assets (cash/investments that can be readily converted into cash) alone or with a spouse: *
                    </div>
                    <Form.Item>
                      {getFieldDecorator("income_family", {
                        initialValue: income_family,
                        rules: [{required: false, message: "Please fill out this field"}]
                      })(
                        <Radio.Group>
                          {
                            incomeFamilyOpportunitiesOptions.map(element => (
                              <Radio key={element.id} value={element.id}>{element.name}</Radio>
                            ))
                          }
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>*/}

                  <div className="form-item mb_35">
                    <div className="form-item-label text text-height-20 font-semibold color-grey mb_10">
                      Have you ever invested in a private business: *
                    </div>
                    <Form.Item className="mb_0">
                      {getFieldDecorator("invested_businesses", {
                        initialValue: invested_businesses,
                        rules: [{required: true, message: "Please fill out this field"}]
                      })(
                        <Radio.Group name="investInPrivateBusiness">
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>

                  {
                    getFieldValue("invested_businesses") === 1 &&
                      <div className="form_toggle_field">
                        <div className="form-item">
                          <div className="form-item-label text text-height-20 font-semibold color-grey">
                            How many businesses? *
                          </div>
                          <Form.Item className="mb_0">
                            {getFieldDecorator("number_of_businesses", {
                              initialValue: number_of_businesses || "", // for avoiding to show 0
                              rules: [{required: true, message: "Please fill out this field"}],
                            })(
                              <NumericInput className="fluid-x" />
                            )}
                          </Form.Item>
                        </div>
                      </div>
                  }
                </div>

                {/*<div className="large-11 mb_35">
                  <div className="form-item">
                    <div className="form-item-label text text-height-20 font-semibold color-grey">
                      In which sectors were your investments (check all that apply)
                    </div>
                  </div>

                  <div className="form-item form-item-lg mb_15" >
                    <Form.Item className="mb_0" >
                      {getFieldDecorator("past_investment_sectors", {
                        initialValue: past_investment_sectors,
                        rules: [{required: false, message: "Please fill out this field"}]
                      })(
                        <Checkbox.Group
                          className="columns-2"
                          options={pastInvestmentSectorsOptions.map(element => ({label: element.name, value: element.id}))}
                        />
                      )}
                    </Form.Item>
                  </div>
                </div>*/}

                <div className="form-item mb_35">
                  <div className="form-item-label text text-height-20 font-semibold color-grey mb_10 large-8">
                    Have you held a board role in any of your investments? *
                  </div>
                  <Form.Item>
                    {getFieldDecorator("past_board_role", {
                      initialValue: past_board_role,
                      rules: [{required: true, message: "Please fill out this field"}]
                    })(
                      <Radio.Group name="boardRole">
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>

                <div className="form-item mb_35">
                  <div className="form-item-label text text-height-20 font-semibold color-grey mb_10 large-8">
                    Are you interested in an operating or board role in private companies? *
                  </div>
                  <Form.Item>
                    {getFieldDecorator("future_board_role", {
                      initialValue: future_board_role,
                      rules: [{required: true, message: "Please fill out this field"}]
                    })(
                      <Radio.Group name="boardRoleInPrivate">
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>

                {/*<div className="form-item mb_35">
                  <div className="form-item-label text text-height-20 font-semibold color-grey mb_10 large-8">
                    How was your overall experience in investing in private businesses? *
                  </div>
                  <Form.Item>
                    {getFieldDecorator("investing_experience", {
                      initialValue: investing_experience,
                      rules: [{required: false, message: "Please fill out this field"}]
                    })(
                      <Radio.Group name="privateBusinessInvestExperience">
                        <Radio value={1}>Positive</Radio>
                        <Radio value={0}>Negative</Radio>
                        <Radio value={2}>Neutral</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>*/}

                {/*<div className="form-item mb_35">
                  <div className="form-item-label text text-height-20 font-semibold color-grey mb_10 large-8">
                    Have you been involved in investor activism? *
                  </div>
                  <Form.Item>
                    {getFieldDecorator("investor_activism", {
                      initialValue: investor_activism,
                      rules: [{required: false, message: "Please fill out this field"}]
                    })(
                      <Radio.Group name="beenInvolvedInActivism">
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>*/}

                {/*<div className="large-11 mb_35">
                  <div className="form-item">
                    <div className="form-item-label text text-height-20 font-semibold color-grey">
                      In which countries would you consider investing (select all that apply)
                    </div>
                  </div>

                  <div className="form-item form-item-lg mb_15">
                    <Form.Item className="mb_0">
                      {getFieldDecorator("preferred_countries", {
                        initialValue: preferred_countries,
                        rules: [{required: false, message: "Please fill out this field"}]
                      })(
                        <Checkbox.Group
                          className="columns-2"
                          options={preferredInvestmentCountriesOptions.map(element => ({label: element.name, value: element.id}))}
                        />
                      )}
                    </Form.Item>
                  </div>
                </div>*/}

                <div className="large-11 mb_35">
                  <div className="form-item">
                    <div className="form-item-label text text-height-20 font-semibold color-grey">
                      In which other asset classes do you have experience in investing in (select all that apply)
                    </div>
                  </div>

                  <div className="form-item form-item-lg mb_15">
                    <Form.Item className="mb_0">
                        {getFieldDecorator("other_asset_classes", {
                            initialValue: other_asset_classes,
                            rules: [{required: false, message: "Please fill out this field"}]
                        })(
                          <Checkbox.Group className="columns-2" >
                            <Row className="flex-container flex-dir-column" >
                              {otherAssetClassesOptions.slice(0, otherAssetClassesOptions.length / 2 + 1).map((item, index) => (
                                <Checkbox key={index} value={item.id}> {item.name} </Checkbox>
                              ))}
                            </Row>
                            <Row className="flex-container flex-dir-column" >
                              {otherAssetClassesOptions.slice(otherAssetClassesOptions.length / 2 + 1, otherAssetClassesOptions.length).map((item, index) => (
                                <Checkbox key={index} value={item.id}> {item.name} </Checkbox>
                              ))}
                            </Row>
                          </Checkbox.Group>
                        )}
                    </Form.Item>
                  </div>
                </div>

                <div className="large-11 mb_35">
                  <div className="form-item">
                    <div className="form-item-label text text-height-22 font-semibold color-grey large-10">
                      Sector Preferences: Please indicate which of the following industry sectors is of interest to you
                      when making private equity investments (check all that apply): *
                    </div>
                  </div>
                  <div className="form-item form-item-lg mb_15">
                    <Form.Item className="mb_0">
                      {getFieldDecorator("investor_sectors", {
                        initialValue: investor_sectors,
                        rules: [{required: true, message: "Please fill out this field"}]
                      })(
                        <Checkbox.Group className="columns-2" >
                          <Row className="flex-container flex-dir-column" >
                            {industriesOptions.slice(0, industriesOptions.length / 2 + 1).map((item, index) => (
                              <Checkbox key={index} value={item.id}> {item.name} </Checkbox>
                            ))}
                          </Row>
                          <Row className="flex-container flex-dir-column" >
                            {industriesOptions.slice(industriesOptions.length / 2 + 1, industriesOptions.length).map((item, index) => (
                              <Checkbox key={index} value={item.id}> {item.name} </Checkbox>
                            ))}
                          </Row>
                        </Checkbox.Group>
                      )}
                    </Form.Item>
                  </div>
                </div>

                <div className="form-item mb_35">
                  <div className="form-item-label text text-height-20 font-semibold color-grey mb_10 large-7 label-with-icon-wrapper">
                    <span className="label-icon-text">
                      How would you rate your knowledge of investing in private businesses
                    </span>
                    <Tooltip
                      placement="rightTop"
                      title={
                        <>
                          Sophisticated: You follow the financial markets closely; you have invested in a private equity fund or a private business, are aware of and understand how private businesses or private equity funds operate; are aware of and understand a wide variety of operating strategies; and have an excellent grasp on the associated risks and potential rewards of investing in a private business.
                          <br/>
                          <br/>
                          Average: You follow the financial markets; have invested in or have some knowledge of investing in private businesses or private equity; and have an understanding of the associated risks and potential rewards.
                          <br/>
                          <br/>
                          Limited: You have some investment experience but do not follow the financial market; and have little understanding of the basic characteristics of the various types of investments and associated degree of risk.
                          <br/>
                          <br/>
                          Novice: You are unfamiliar with investments and the financial markets and have little or no investment experience.
                        </>
                      }
                    >
                      <div className="align-self-top">
                        <Icon type="question" />
                      </div>
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("investing_pb_knowledge", {
                      initialValue: investing_pb_knowledge,
                      rules: [{required: false, message: "Please fill out this field"}]
                    })(
                      <Radio.Group name="knowledgeInPrivateBusinessInvest">
                        {
                          knowledgeInPrivateBusinessOptions.map(element => (
                            <Radio key={element.id} value={element.id}>{element.name}</Radio>
                          ))
                        }
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>

                <div className="form-item mb_35">
                  <div className="form-item-label text text-height-20 font-semibold color-grey mb_10 large-8">
                    How would you rate your knowledge of Investing in financial markets
                  </div>
                  <Form.Item>
                    {getFieldDecorator("investing_fm_knowledge", {
                      initialValue: investing_fm_knowledge,
                      rules: [{required: false, message: "Please fill out this field"}]
                    })(
                      <Radio.Group name="knowledgeInvestInFinMarkets">
                        {
                          knowledgeInFinancialMarketsOptions.map(element => (
                            <Radio key={element.id} value={element.id}>{element.name}</Radio>
                          ))
                        }
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>
              </div>

              <OnboardingFooter
                step={3}
                setOnboardingInfo={this.setOnboardingInfo}
              />
            </Form>
          </div>
        </div>
      </OnboardingHeader>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.registration.loading,
  errorMessage: state.registration.errorMessage,
  onboardingData: state.registration.onboardingData,
  opportunityGroups: state.dictionaries.opportunityGroups,
  optionsList: state.dictionaries.optionsList
});

const mapDispatchToProps = dispatch => ({
  setOnboardingData: params => dispatch(setOnboardingData(params)),
  getOnboarding: () => dispatch(getOnboarding()),
  setOptions: params => dispatch(setOptions(params)),
  getOptions: optionGroup => dispatch(getOptions(optionGroup)),
  clearOptions: optionGroup => dispatch(clearOptions(optionGroup))
});

const OnboardingStep5Screen = Form.create({ name: "OnboardingStep5" })(OnboardingStep5);

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingStep5Screen);
