import {
  CHANGE_PASSWORD_START, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL
} from "../../constants";

export const changePasswordStart = () => ({
  type: CHANGE_PASSWORD_START
});

export const changePasswordSuccess = payload => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload
});

export const changePasswordFail = payload => ({
  type: CHANGE_PASSWORD_FAIL,
  payload
});