import React, {Component} from "react";
import {connect} from "react-redux";

import "./style.scss";

import {Link} from "react-router-dom";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import WizardStep from "../../components/WizardStep/WizardStep";
import {getOptions} from "../../redux/dictionaries/thunks/dictionariesOptionsThunk";
import {isOnboardingStepFilled} from "../../utils/isOnboardingStepFilled";

class OnboardingHeader extends Component {
  getOnboardingRegistrationStepTitle = () => {
    const { step } = this.props;
    switch (parseInt(step)) {
      case 1:
        return "Personal Information";
      case 2:
        return "Insider and Directorship";
      case 3:
        return "Investment Experience";
      case 4:
        return "Investment Objectives and Risk Tolerance";
      case 5:
        return "Accredited Investor Accreditation";
      case 6:
        return "Anti-money laundering and Anti-terrorist financing representation";
      case 7:
        return "Risk & Objectives";
      case 8:
        return "Accredited Investor Certification";
      case 9:
        return "Identification";
      default:
        return "";
    }
  };

  componentDidMount() {
    const { getOptions } = this.props;
    getOptions([3, 5, 23, 25, 26, 33]);
  }

  render() {
    const {userInfo, children, step, onboardingData, optionsList} = this.props;
    const first_name  = userInfo ? userInfo.first_name : "";

    return (
      <div className="relative fluid-y">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="flex-container align-middle">
                <Button
                  title={<Icon type="key-left" size={16} />}
                  size="md"
                  theme="lightblue"
                  className="radius-10 back_btn"
                  linkTo="/"
                />
                <div className="page_title visible-first custom-headline text text-24 text-height-48 font-heavy color-grey font-uppercase">
                  <h1>Onboarded user form</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="column small-12">
            <div className="text text-20 text-height-30 font-medium color-grey mb_65">
              <p>Dear {first_name},</p>
              <p>
                Only “Onboarded users” can apply to see investment opportunities details. <br/>
                <b>Please complete the following form to be upgraded to “Onboarded user” status</b>
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="column small-12 x-large-10">
            <div className="custom-headline text font-semibold color-grey mb_25">
              <h2>{this.getOnboardingRegistrationStepTitle()}</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="column small-12 x-large-10">
            <div className="flex-container align-middle wizard_steps">
              {
                Array(5).fill(0).map((element, index) => (
                  !isOnboardingStepFilled(onboardingData, index + 1, optionsList) ?
                    <div>
                      <WizardStep
                        number={`${index + 1}`}
                        name=""
                        className={`sm ${index + 1 === step ? "active" : index + 1 > step ? "" : "done"}`}
                      />
                    </div>
                  :
                    <Link to={`/onboarding${index + 1}`}>
                      <WizardStep
                        number={`${index + 1}`}
                        name=""
                        className={`sm ${index + 1 === step ? "active" : index + 1 > step ? "" : "done"}`}
                      />
                    </Link>
                ))
              }
            </div>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.user.userInfo,
  onboardingData: state.registration.onboardingData,
  optionsList: state.dictionaries.optionsList,
});

const mapDispatchToProps = dispatch => ({
  getOptions: optionGroup => dispatch(getOptions(optionGroup)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingHeader);
