import {
  GET_TAGS_START, GET_TAGS_SUCCESS, GET_TAGS_FAIL,
  TAG_USERS_START, TAG_USERS_SUCCESS, TAG_USERS_FAIL,
  UNTAG_USERS_START, UNTAG_USERS_SUCCESS, UNTAG_USERS_FAIL,
  ADD_TAG_START, ADD_TAG_SUCCESS, ADD_TAG_FAIL,
  RENAME_TAG_START, RENAME_TAG_SUCCESS, RENAME_TAG_FAIL,
  REMOVE_TAG_START, REMOVE_TAG_SUCCESS, REMOVE_TAG_FAIL,
  GET_TAG_USERS_START, GET_TAG_USERS_SUCCESS, GET_TAG_USERS_FAIL,
  GET_ALL_TAG_USERS_START, GET_ALL_TAG_USERS_SUCCESS, GET_ALL_TAG_USERS_FAIL,
} from '../../constants';

export const getTagsStart = () => ({
  type: GET_TAGS_START
});

export const getTagsSuccess = (payload) => ({
  type: GET_TAGS_SUCCESS,
  payload
});

export const getTagsFail = (payload) => ({
  type: GET_TAGS_FAIL,
  payload
});

export const tagUsersStart = () => ({
  type: TAG_USERS_START
});

export const tagUsersSuccess = (payload) => ({
  type: TAG_USERS_SUCCESS,
  payload
});

export const tagUsersFail = (payload) => ({
  type: TAG_USERS_FAIL,
  payload
});

export const untagUsersStart = () => ({
  type: UNTAG_USERS_START
});

export const untagUsersSuccess = (payload) => ({
  type: UNTAG_USERS_SUCCESS,
  payload
});

export const untagUsersFail = (payload) => ({
  type: UNTAG_USERS_FAIL,
  payload
});

export const addTagStart = () => ({
  type: ADD_TAG_START
});

export const addTagSuccess = (payload) => ({
  type: ADD_TAG_SUCCESS,
  payload
});

export const addTagFail = (payload) => ({
  type: ADD_TAG_FAIL,
  payload
});

export const renameTagStart = () => ({
  type: RENAME_TAG_START
});

export const renameTagSuccess = (payload) => ({
  type: RENAME_TAG_SUCCESS,
  payload
});

export const renameTagFail = (payload) => ({
  type: RENAME_TAG_FAIL,
  payload
});

export const removeTagStart = () => ({
  type: REMOVE_TAG_START
});

export const removeTagSuccess = (payload) => ({
  type: REMOVE_TAG_SUCCESS,
  payload
});

export const removeTagFail = (payload) => ({
  type: REMOVE_TAG_FAIL,
  payload
});

export const getTagUsersStart = () => ({
  type: GET_TAG_USERS_START
});

export const getTagUsersSuccess = (payload) => ({
  type: GET_TAG_USERS_SUCCESS,
  payload
});

export const getTagUsersFail = (payload) => ({
  type: GET_TAG_USERS_FAIL,
  payload
});

export const getAllTagUsersStart = () => ({
  type: GET_ALL_TAG_USERS_START
});

export const getAllTagUsersSuccess = (payload) => ({
  type: GET_ALL_TAG_USERS_SUCCESS,
  payload
});

export const getAllTagUsersFail = (payload) => ({
  type: GET_ALL_TAG_USERS_FAIL,
  payload
});
