import React from "react";
import { getMediaFileFromServer } from "../../utils/getMediaFileFromServer";
import { formatDate } from "../../utils/formatDate";
import ListItem from "../ListItem/ListItem";
import { compareTimes } from "../../utils/calculateTime";
import { convertFromUTC } from "../../utils/convertFromUTC";

const TabPaneContent = (props) => {
  const fromOffers = window.location.pathname === "/offers";
  const { type, businesses, isAdmin } = props;

  return (
    <div className="row">
      {
        businesses?.map((element, index) => {
          let localDeadline = element.deadline;
          let localClosed = element?.offerInfo?.close;
          if(element?.deadline?.split(" ").length > 1) {
            localDeadline = convertFromUTC(element.deadline)
          }
          if(element?.offerInfo?.close?.split(" ").length > 1) {
            localClosed = convertFromUTC(element?.offerInfo?.close);
          }
          const deadLine = formatDate(localDeadline, element?.offerInfo?.isSecondaryDeal);
          const closed = formatDate(localClosed, element?.offerInfo?.isSecondaryDeal);
          const isClosed = compareTimes(element.deadline)
          const imageUrl = element.photo

            ? getMediaFileFromServer(element.photo)
            : require("../../assets/img/joel-filipe-RFDP7_80v5A-unsplash.png");
          return (
            <div key={element.id} className="column small-12 medium-4 x-large-3" >
              <ListItem
                offerInfo={element.offerInfo}
                id={element.id}
                deadline={element.deadline}
                saved={element.saved}
                title={element.name}
                desc={element.description}
                img={imageUrl}
                tag={parseInt(element.enabled) === 0 ? 'Disabled' : (type === "all" && index === 0) ? "new" : null}
                orderTag={isAdmin ? element.order : null}
                href={`/${fromOffers ? 'offer' : 'project'}/${element.id}`}
                hideSaveIcon={fromOffers ? true : false}
                footer={
                  element?.offerInfo?.close ?
                    <>
                      <div className="font-medium opportunity-box-footer-deadline-wrapper">Closed</div>
                      <div className="font-bold">{closed}</div>
                    </> :
                  !element.deadline
                    ?
                    <div className="font-medium opportunity-box-footer-deadline-wrapper">In review</div>
                    :
                    <>
                      <div className="font-medium opportunity-box-footer-deadline-wrapper">{isClosed ? "Deadline" : "Closed"}</div>
                      <div className="font-bold">{deadLine}</div>
                    </>
                }
              />
            </div>
          );
        })
      }
    </div>
  )
};

export default TabPaneContent;
