import { Form, Input, message, Spin, Table, Tabs } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { acceptBidRequest, getBid, getOffer, moveBid, rejectBidRequest, searchOfferBidRequests, searchOfferBids, setWon } from "../../redux/business/thunks/offersThunk";
import { formatNumber } from "../../utils/formatNumber";
import { goBack } from "../../utils/goBack";
import TabPaneTitle from "../Opportunities/TabPaneTitle";
import { handleLoading } from "../../utils/handleLoading";
import { bidRequestsColumns, bidsColumns } from "./BidsColumn";
import moment from "moment";
import PopUp from "../../components/PopUp/PopUp";
import { bidsHistoryColumns } from "./BidsHistoryColumn";

const { TabPane } = Tabs;

const Bids = ({ match, form }) => {
  const dispatch = useDispatch();

  const offerBids = useSelector((state) => state.offers.offerBids)
  const internalOfferBids = useSelector((state) => state.offers.internalOfferBids)
  const externalOfferBids = useSelector((state) => state.offers.externalOfferBids)
  const bidRequests = useSelector((state) => state.offers.bidRequests)
  const offerBidsCount = useSelector((state) => state.offers.offerBidsCount)
  const internalOfferBidsCount = useSelector((state) => state.offers.internalOfferBidsCount)
  const externalOfferBidsCount = useSelector((state) => state.offers.externalOfferBidsCount)
  const bidRequestsCount = useSelector((state) => state.offers.bidRequestsCount)
  const loading = useSelector((state) => state.offers.loading)
  const errorMessage = useSelector((state) => state.offers.errorMessage)
  const currentOffer = useSelector((state) => state.offers.selectedOffer)
  const currency = currentOffer?.business?.currency
  const selectedBid = useSelector((state) => state.offers.selectedBid)
  const role = useSelector((state) => state.user.role);
  const isExternal = currentOffer?.scope === "EXTERNAL";
  const {getFieldDecorator, setFieldsValue} = form;

  const [offerBidsCurrentPage, setOfferBidsCurrentPage] = useState(1);
  const [internalOfferBidsCurrentPage, setInternalOfferBidsCurrentPage] = useState(1);
  const [externalOfferBidsCurrentPage, setExternalOfferBidsCurrentPage] = useState(1);
  const [bidRequestsCurrentPage, setBidRequestsCurrentPage] = useState(1);
  const [activeTabKey, setActiveTabKey] = useState("all");
  const [bidsHistoryPopupVisible, setBidsHistoryPopupVisible] = useState(false);
  const [rejectPopupVisible, setRejectPopupVisible] = useState(false);
  // const [moveBidPopupVisible, setMoveBidPopupVisible] = useState(false);
  const [currentBidUserId, setCurrentBidUserId] = useState(null);
  const [pendingBidRequestID, setPendingBidRequestID] = useState(null);
  // const selectedButtonLoading = useRef("")

  const { id } = match.params;

  const isAdmin = () => {
    return role === "ADMIN";
  };

  const bidsList = useCallback(
    (offerBids) => offerBids?.map((bid) => ({
      id: bid.id,
      first_name: bid.user?.first_name,
      last_name: bid.user?.last_name,
      email: bid.user?.email,
      bidder: bid.user?.id,
      bid_shares: formatNumber(bid.prize ? bid.prize: ''),
      bid_value: formatNumber(bid.scope === "EXTERNAL" ? bid.amount : bid.cost || bid.amount) + " " + currency,
      scope: bid.scope,
      created: moment.utc(bid.created).local().format('YYYY-MM-DD hh:mm:ss'),
      prize: formatNumber(bid.prize ? bid.prize: ''),
      maxPrize: formatNumber(bid.pro_rata_max ? bid.pro_rata_max: ''),
      move_bid: {
        handleMoveBid: () => {
          dispatch(moveBid({ offer: id, user: bid.user?.id }));
          // setMovedBidUserId(bid.user?.id);

          // const shareholding = ((bid.prize * bid.owner.shareholding) / ((bid.owner.internal_prize + bid.owner.external_prize + bid.owner.final_amount - bid.owner.external_sales) - bid.owner.internal_sales)).toFixed(2);
          // setFieldsValue({
          //   shareholding: shareholding ? shareholding : 0,
          // })
        },
        bidMoved: bid.moved !== null,
        offerClosed: currentOffer?.closed,
        won: bid.won,
      },
    })),
    [dispatch, offerBids, currentOffer],
  );

  
  const bidRequestsList = useCallback(
    (bidRequests) => bidRequests?.map((bid) => ({
      id: bid.id,
      first_name: bid.user?.first_name,
      last_name: bid.user?.last_name,
      email: bid.user?.email,
      bidder: bid.user?.id,
      bid: formatNumber(bid.amount),
      created: moment.utc(bid.created).local().format('YYYY-MM-DD hh:mm:ss'),
      accept_bid: {
        handleAcceptBid: () => {
          dispatch(acceptBidRequest({ offer: id, investor: bid.user?.id }));
          setPendingBidRequestID(bid.user?.id);
        },
        handleAcceptLoading: handleLoading(loading, "AcceptBidRequest") && pendingBidRequestID === +bid.user?.id,
        acceptDisabled: handleLoading(loading, "RejectBidRequest") || (handleLoading(loading, "AcceptBidRequest") && pendingBidRequestID !== +bid.user?.id),
        handleRejectBid: () => {
          setRejectPopupVisible(true);
          setCurrentBidUserId(bid.user?.id);
        },
        handleRejectLoading: handleLoading(loading, "RejectBidRequest") && pendingBidRequestID === +bid.user?.id,
        rejectDisabled: handleLoading(loading, "AcceptBidRequest") || (handleLoading(loading, "RejectBidRequest") && pendingBidRequestID !== +bid.user?.id),
      },
    })),
    [dispatch, loading, id, pendingBidRequestID],
  );

  const bidsHistoryList = useCallback(
    () => selectedBid?.success.history.map((bid) => ({
      id: bid.id,
      bid: formatNumber(bid.amount),
      scope: bid.scope,
      created: moment.utc(bid.created).local().format('YYYY-MM-DD hh:mm:ss'),
    })),
    [dispatch, loading, offerBids],
  );

  const getTabPanes = () => {
    let tabPanes = [
      {
        key: 'all',
        data: offerBids,
        currentPage: offerBidsCurrentPage,
        total: offerBidsCount,
        tab: <TabPaneTitle title='All' />,
        visible: true,
      },
      {
        key: 'INTERNAL',
        data: internalOfferBids,
        currentPage: internalOfferBidsCurrentPage,
        total: internalOfferBidsCount,
        tab: <TabPaneTitle title="Internal" length={internalOfferBidsCount} />,
        visible: true,
      },
      {
        key: 'EXTERNAL',
        data: externalOfferBids,
        currentPage: externalOfferBidsCurrentPage,
        total: externalOfferBidsCount,
        tab: <TabPaneTitle title="External" length={externalOfferBidsCount} />,
        visible: true,
      },
      {
        key: 'REQUESTS',
        data: bidRequests,
        currentPage: bidRequestsCurrentPage,
        total: bidRequestsCount,
        tab: <TabPaneTitle title="Bid requests" length={bidRequestsCount} />,
        visible: isAdmin(),
      },
    ];

    return tabPanes;
  };

  const getBidHistory = (offerId, bidder) => {
    if(isAdmin()) {
      setBidsHistoryPopupVisible(true);
      dispatch(getBid({ offerId, bidder }));
    }
  };

  const handleTablePagination = (props, scope) => {
    const scopeQuery = scope === 'all' ? {} : { scope };

    if(scope === 'all') {
      setOfferBidsCurrentPage(props.current);
    } else if(scope === "INTERNAL") {
      setInternalOfferBidsCurrentPage(props.current);
    } else {
      setExternalOfferBidsCurrentPage(props.current);
    }
    
    if(scope === 'REQUESTS') {
      setBidRequestsCurrentPage(props.current);
      dispatch(searchOfferBidRequests({ offer: id, current: props.current - 1 }))
    } else {
      dispatch(searchOfferBids({ offer: id, current: props.current - 1, query: { ...scopeQuery } }))
    }
  }

  const handleTabsPaneOnChange = (activeTabKey) => {
    setActiveTabKey(activeTabKey);
  };

  const onRejectSubmit = (e) => {
    e.preventDefault();

    form.validateFields((err, { reason }) => {
      if (!err) {
        dispatch(rejectBidRequest({ offer: id, investor: currentBidUserId, reason }));
        setPendingBidRequestID(currentBidUserId);
      }
    });
    
  }

  useEffect(() => {
    if (!loading.state && errorMessage.message && loading.type === "SetWon") {
      message.error(errorMessage.message)
    } else if (!loading.state && !errorMessage.message && loading.type === "SetWon") {
      message.success("operation succeeded");
    } else if (!loading.state && !errorMessage.message && loading.type === "MoveBid") {
      dispatch(searchOfferBids({ offer: id }));
      // setMoveBidPopupVisible(false);
      // setMovedBidUserId(null);
    }
    if(!loading.state && !errorMessage.message && (loading.type === "AcceptBidRequest" || loading.type === "RejectBidRequest")) {
      dispatch(searchOfferBidRequests({ offer: id }));
      setPendingBidRequestID(null);
      setCurrentBidUserId(null);
      setRejectPopupVisible(false);
    }
  }, [loading, errorMessage, id])

  useEffect(() => {
    dispatch(getOffer({ id }));
  }, [id, dispatch]);

  // useEffect(() => {
  //   if (offerBidsCount) dispatch(searchOfferBids({ offer: id, current: 0 }))
  // }, [dispatch, id, offerBidsCount])

  useEffect(() => {
    dispatch(searchOfferBids({ offer: id }));
    dispatch(searchOfferBidRequests({ offer: id }));
  }, [dispatch, id])


  return (
    <div className="relative fluid-y screen_wrapper">
      <div className="row">
        <div className="column small-12">
          <div className="page_top page_top-tall">
            <div className="flex-container align-middle">
              <Button
                title={<Icon type="key-left" size={16} />}
                size="md"
                theme="lightblue"
                className="radius-10 back_btn"
                onClick={() => goBack()}
              />
              <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                <h1>bids</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row contact_tab ant-table ">
        <div className="column small-12">
          <Spin className="contact_tables" spinning={handleLoading(loading, "SearchOfferBids")}>
            <Tabs
              onChange={handleTabsPaneOnChange}
              defaultActiveKey={activeTabKey}
              className="tab_default tab_sticky"
            >
              {getTabPanes().map(({ key, data, tab, total, currentPage, visible }) => (
                visible && 
                <TabPane tab={tab} key={key} >
                  <Table
                    columns={key === 'REQUESTS' ? bidRequestsColumns() : bidsColumns(isAdmin())}
                    rowKey={data?.key}
                    dataSource={key === 'REQUESTS' ? bidRequestsList(data) : bidsList(data)}
                    className={`c-table c-table-bordered users_table ${key === "REQUESTS" ? 'requests_table' : 'bids_table'} ${isAdmin() && key !== 'REQUESTS' ? 'clickable-rows' : ''}`}
                    onChange={(props) => handleTablePagination(props, key)}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (e) => getBidHistory(id, record.bidder)
                      };
                    }}
                    pagination={{
                      hideOnSinglePage: true,
                      current: currentPage,
                      total,
                    }}
                  />
                </TabPane>
              ))}
            </Tabs>
          </Spin>
        </div>
      </div>

      <PopUp
        className="relative_parent"
        visible={bidsHistoryPopupVisible}
        onCancel={() => setBidsHistoryPopupVisible(false)}
        innerClassNames="shadow-layout bg-white radius-10 relative lg base"
      >
        <div className="popup_close color-grey-700" onClick={() => setBidsHistoryPopupVisible(false)}>
          <Icon type="cancel" />
        </div>

        <div>
          <div className="custom-headline text text-22 font-bold color-grey project_info__title">
            <h2>Bid History</h2>
          </div>
          <Table
            columns={bidsHistoryColumns}
            dataSource={bidsHistoryList()}
            pagination={false}
            loading={handleLoading(loading, 'GetBid')}
            className="c-table c-table-bordered users_table"
          />
        </div>
      </PopUp>
      <PopUp
        className="relative_parent"
        visible={rejectPopupVisible}
        onCancel={() => setRejectPopupVisible(false)}
        innerClassNames="shadow-layout bg-white radius-10 relative lg base"
      >
        <div className="popup_close color-grey-700" onClick={() => setRejectPopupVisible(false)}>
          <Icon type="cancel" />
        </div>

        <div>
          <div className="custom-headline text text-22 font-bold color-grey project_info__title">
            <h2>Reject Reason</h2>
          </div>
          <Form onSubmit={onRejectSubmit}>
            
            <Form.Item className="mb_40" name="reason">
              {getFieldDecorator("reason", {
                rules: [{ required: true, message: "Please fill out this field" }]
              })(
                <Input type='text' className="fluid-x auto_populated" />
              )}
            </Form.Item>
            <Button 
              title='Send'
              type="submit"
              size="xl"
              theme="blue"
              className="radius-8 text-18"
              loading={handleLoading(loading, "RejectBidRequest")}
              disabled={handleLoading(loading, "AcceptBidRequest") || handleLoading(loading, "RejectBidRequest")}
            />
          </Form>
        </div>
      </PopUp>
        {/* <PopUp
          className="relative_parent"
          visible={moveBidPopupVisible}
          onCancel={() => {
            setMoveBidPopupVisible(false);
            // setMovedBidUserId(null);
          }}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div className="popup_close color-grey-700" onClick={() => {
            setMoveBidPopupVisible(false)
            // setMovedBidUserId(null);
          }}>
            <Icon type="cancel" />
          </div>

          <div>
            <div className="custom-headline text text-22 font-bold color-grey project_info__title">
              <h2>Shareholding to add</h2>
            </div>
            <Form name="shareholdingToAdd" onSubmit={moveBid} >
              <div className="form-item text-left mb_40">
                <Form.Item className="mb_20">
                  {getFieldDecorator("shareholding", {
                    rules: [
                      { required: true, message: "Please fill out this field" },
                      {
                        validator: async (rule, value) => {
                          if (parseFloat(value) <= 0) {
                            return Promise.reject('Shareholding need to be greater than 0');
                          }
                          if(parseFloat(value) > 100) {
                            return Promise.reject('Shareholding need to be smaller than 100');
                          }
                        },
                      },
                    ],
                  })(
                    <NumericInput
                      className="fluid-x percent-suffix"
                      suffix={<div className='text text-20 font-semibold color-blue-2'>{'%'}</div>}
                    />
                  )}
                </Form.Item>
              </div>
              <Form.Item className="mb_0 text-right">
                <Button
                  title="Move"
                  size="xl"
                  theme="blue"
                  type="submit"
                  className="radius-8"
                  style={{ fontSize: 18 }}
                  loading={handleLoading(loading, "MoveBid")}
                />
              </Form.Item>
            </Form>
          </div>
        </PopUp> */}
    </div>
  )
}

const BidsScreen = Form.create()(Bids);
export default BidsScreen;