import produce from 'immer';
import {
  GET_DICTIONARIES_START, GET_DICTIONARIES_SUCCESS, GET_DICTIONARIES_FAIL,
  GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_START, GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_SUCCESS, GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_FAIL,
  GET_COUNTRIES_START, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAIL,
  GET_STATES_START, GET_STATES_SUCCESS, GET_STATES_FAIL,
  REMOVE_STATES,
  GET_CITIES_START, GET_CITIES_SUCCESS, GET_CITIES_FAIL,
  INIT_LOAD_START, INIT_LOAD_SUCCESS, INIT_LOAD_FAIL,
  GET_ONBOARDING_SUCCESS,
  SET_OPTIONS_START, SET_OPTIONS_SUCCESS, SET_OPTIONS_FAIL,
  GET_OPTIONS_START, GET_OPTIONS_SUCCESS, GET_OPTIONS_FAIL,
  CLEAR_OPTIONS_START, CLEAR_OPTIONS_SUCCESS, CLEAR_OPTIONS_FAIL,
  GET_USER_SUCCESS
} from "../constants";

const initialState = {
  loading: {
    type: "",
    state: false
  },
  errorMessage: {
    type: "",
    message: ""
  },
  opportunityGroups: null,
  countries: null,
  states: null,
  cities: null,
  optionsList: null
};

const dictionariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_LOAD_START:
      return produce(state, draft => {
        draft.loading.type = "InitLoad";
        draft.loading.state = true;
      });
    case INIT_LOAD_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        action.payload.hasOwnProperty("opportunities") && (draft.opportunityGroups = action.payload.opportunities);
        action.payload.hasOwnProperty("countries") && (draft.countries = action.payload.countries);
      });
    case INIT_LOAD_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case GET_DICTIONARIES_START:
      return produce(state, draft => {
        draft.loading.type = "GetDictionaries";
        draft.loading.state = true;
      });
    case GET_DICTIONARIES_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        action.payload.hasOwnProperty("opportunities") && (draft.opportunityGroups = action.payload.opportunities);
        action.payload.hasOwnProperty("countries") && (draft.countries = action.payload.countries);
      });
    case GET_DICTIONARIES_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_START:
      return produce(state, draft => {
        draft.loading.type = "GetOpportunityGroupsWithOptions";
        draft.loading.state = true;
      });
    case GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.opportunityGroups = action.payload.opportunities;
      });
    case GET_OPPORTUNITY_GROUPS_WITH_OPTIONS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case GET_COUNTRIES_START:
      return produce(state, draft => {
        draft.loading.type = "GetCountries";
        draft.loading.state = true;
      });
    case GET_COUNTRIES_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.countries = action.payload.countries;
      });
    case GET_COUNTRIES_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case GET_STATES_START:
      return produce(state, draft => {
        draft.loading.type = "GetStates";
        draft.loading.state = true;
      });
    case GET_STATES_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.states = action.payload.states;
      });
    case GET_STATES_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case REMOVE_STATES:
      return produce(state, draft => {
        draft.states = null;
      });
    case GET_CITIES_START:
      return produce(state, draft => {
        draft.loading.type = "GetCities";
        draft.loading.state = true;
      });
    case GET_CITIES_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.cities = action.payload.cities;
      });
    case GET_CITIES_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case GET_ONBOARDING_SUCCESS:
      return produce(state, draft => {
        action.payload.hasOwnProperty("states") && (draft.states = action.payload.states);
        action.payload.hasOwnProperty("cities") && (draft.cities = action.payload.cities);
      });
    case SET_OPTIONS_START:
      return produce(state, draft => {
        draft.loading.type = "SetOptions";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case SET_OPTIONS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
      });
    case SET_OPTIONS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "SetOptions";
        draft.errorMessage.message = action.payload;
      });
    case GET_OPTIONS_START:
      return produce(state, draft => {
        draft.loading.type = "GetOptions";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case GET_OPTIONS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.optionsList = action.payload.optionsList;
      });
    case GET_OPTIONS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "GetOptions";
        draft.errorMessage.message = action.payload;
      });
    case CLEAR_OPTIONS_START:
      return produce(state, draft => {
        draft.loading.type = "ClearOptions";
        draft.loading.state = true;
        draft.errorMessage.type = "";
        draft.errorMessage.message = "";
      });
    case CLEAR_OPTIONS_SUCCESS:
      return produce(state, draft => {
        draft.loading.state = false;

      });
    case CLEAR_OPTIONS_FAIL:
      return produce(state, draft => {
        draft.loading.state = false;
        draft.errorMessage.type = "ClearOptions";
        draft.errorMessage.message = action.payload;
      });
    case GET_USER_SUCCESS:
      return produce(state, draft => {
        draft.states = action.payload.states;
        draft.cities = action.payload.cities;
      });
    default:
      return state;
  }
};

export default dictionariesReducer;