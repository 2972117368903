import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Dropdown,
  Form,
  Select,
  Tabs,
} from "antd";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import Slider from "react-slick";
import moment from "moment";

import { getClosedReports, getReport } from "../../redux/reports/thunks/reportsThunk";
import { findValueFromDictionary } from "../../utils/findValueFromDictionary";
import { findDictionaryByID } from "../../utils/findDictionaryByID";
import { getCurrencySymbol } from "../../utils/getCurrencySymbol";
import { randomString } from "../../utils/randomString";
import { formatNumber } from "../../utils/formatNumber";
import { MONTHS } from "../../utils/constants";

import AnalyticItemsWrapper from "../../components/AnalyticItemsWrapper/AnalyticItemsWrapper";
import Icon from "../../components/Icon/Icon";
import Button from "../../components/Button/Button";
import InvestmentMixChart from "./InvestmentMixChart";

const { TabPane } = Tabs;
const { Option } = Select;

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      }
    },
  ]
};

const setFinReportCategories = (year) => [`Jan ${year}`, `Mar ${year}`, `Jun ${year}`, `Sep ${year}`];

const colorsPlate = ['#F5C65B', '#FF551F', '#36C1A3', '#A0B95C', '#57A36F', '#F9B69F', '#82AFE1'];
let yearsList = [];
const currentYear = new Date().getFullYear();

for (let i = currentYear - 4; i < currentYear; i++) {
  yearsList.push(i);
}

yearsList.push(currentYear);

for (let i = currentYear + 1; i < currentYear + 5; i++) {
  yearsList.push(i);
}

class AnalyticsTab extends Component {
  constructor(props) {
    super(props);

    this.neededTypesOfInvestmentReturns = ['Revenue', 'Ebitda'];
    this.investReturnColumns = [
      {
        title: '',
        dataIndex: 'name',
        key: 'name',
        render: (text) => (
          <div className="">
            <div className="text font-semibold color-grey">
              {text[0]}
            </div>
            <div className="text text-14 color-grey-800 text-height-20">
              {text[1]}
            </div>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'summa',
        key: 'summa',
        render: (text) => (
          <div className="text text-22 font-heavy color-grey text-right">
            {text[1]} {text[0]}
          </div>
        ),
      }
    ];
    this.state = {
      chartOptions: {
        type: 'column',
        animation: true,
        xAxis: {
          categories: setFinReportCategories(currentYear),
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            },
          },
          tickmarkPlacement: 'between',
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            }
          }
        },
        series: [],
        legend: {
          align: 'left',
          verticalAlign: 'bottom',
          labelFormat: '<span class="text text-14 font-medium font-spacing-04" style="color:{color}">{name}</span>'
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: this.setHoverData.bind(this)
              }
            },
            marker: {
              symbol: '<span class="text text-14 font-medium font-spacing-04">O</span>'
            }
          }
        },
        tooltip: {
          backgroundColor: '#3d3d3d',
          borderRadius: 8,
          borderWidth: 0,
          padding: 22,
          shadow: false,
          formatter: function () {
            const { point, series } = this;
            const { index } = point;
            const { tooltipData } = series.userOptions.data[index];
            const { data } = tooltipData;
            const tooltipValue = data.value;
            let tooltipLabel = data.label;
            tooltipLabel = tooltipLabel.replace(/_/g, ' ');
            tooltipLabel = tooltipLabel.charAt(0).toUpperCase() + tooltipLabel.slice(1);

            return '<div class="chart_tooltip text">' +
              '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
              '<div dy="8"><span class="chart_tooltip__label">'+ tooltipLabel +'</span>: <span class="chart_tooltip__val" dx="5">' + formatNumber(tooltipValue) + '</span></div><br/>' +
              '</div>';
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 767
            },
            chartOptions: {
              xAxis: {
                labels: {
                  style: {
                    fontSize: '12px'
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontSize: '12px',
                  }
                }
              },
              tooltip: {
                padding: 12
              },
            }
          }]
        }
      },
      barOptions: {
        type: 'column',
        animation: true,
        xAxis: {
          categories: [`Q1`, `Q2`, `Q3`, `Q4`],
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            },
          },
          tickmarkPlacement: 'between',
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            }
          }
        },
        series: [],
        legend: {
          align: 'left',
          verticalAlign: 'bottom',
          labelFormat: '<span class="text text-14 font-medium font-spacing-04" style="color:{color}">{name}</span>'
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: this.setHoverData.bind(this)
              }
            },
            marker: {
              symbol: '<span class="text text-14 font-medium font-spacing-04">O</span>'
            }
          }
        },
        tooltip: {
          backgroundColor: '#3d3d3d',
          borderRadius: 8,
          borderWidth: 0,
          padding: 22,
          shadow: false,
          formatter: function () {
            return '<div class="chart_tooltip text">' +
              '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
              '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + formatNumber(Math.round(this.y)) + '</span></div>' +
              '</div>';
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 767
            },
            chartOptions: {
              xAxis: {
                labels: {
                  style: {
                    fontSize: '12px'
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontSize: '12px',
                  }
                }
              },
              tooltip: {
                padding: 12
              },
            }
          }]
        }
      },
      annualPerformanceBarOptions: {
        type: 'column',
        animation: true,
        xAxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            },
          },
          tickmarkPlacement: 'between',
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            }
          }
        },
        series: [],
        legend: {
          align: 'left',
          verticalAlign: 'bottom',
          labelFormat: '<span class="text text-14 font-medium font-spacing-04" style="color:{color}">{name}</span>'
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: this.setHoverData.bind(this)
              }
            },
            marker: {
              symbol: '<span class="text text-14 font-medium font-spacing-04">O</span>'
            }
          }
        },
        tooltip: {
          backgroundColor: '#3d3d3d',
          borderRadius: 8,
          borderWidth: 0,
          padding: 22,
          shadow: false,
          formatter: function () {
            return '<div class="chart_tooltip text">' +
              '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
              '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + formatNumber(Math.round(this.y)) + '</span></div>' +
              '</div>';
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 767
            },
            chartOptions: {
              xAxis: {
                labels: {
                  style: {
                    fontSize: '12px'
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontSize: '12px',
                  }
                }
              },
              tooltip: {
                padding: 12
              },
            }
          }]
        },
      },
      pieChartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          margin: [0, 0, 0, 0],
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
          height: '100%',
        },
        tooltip: {
          backgroundColor: '#3d3d3d',
          borderRadius: 8,
          borderWidth: 0,
          padding: 22,
          shadow: false,
          formatter: function () {
            return '<div class="chart_tooltip text">' +
              '<div>' +
              '<span class="chart_tooltip__label">' + this.point.name + '</span>:' +
              ' <span class="chart_tooltip__val" dx="5">' + Math.round(this.point.percentage) + '%</span>' +
              '</div>' +
              '</div>';
          }
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            innerSize: '45%',
            size: '100%',
            allowPointSelect: true,
            shadow: false,
            borderWidth: 8,
            borderColor: '#fff',
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            states: {
              inactive: {
                opacity: 0.9
              },
              hover: {
                enabled: false,
                halo: {
                  size: 0
                }
              }
            },
          }
        },
        series: [],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 767
            },
            chartOptions: {
              tooltip: {
                padding: 12
              },
            }
          }]
        }
      },
      waterfallChartOptions: {
        type: 'column',
        animation: true,
        xAxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            },
          },
          tickmarkPlacement: 'between',
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Poppins',
              color: '#8F9CA4',
            }
          }
        },
        series: [],
        legend: {
          align: 'left',
          verticalAlign: 'bottom',
          labelFormatter: function () {
            return this.name.includes('distribution') ? 'distribution' : this.name;
          },
          itemStyle: {
            fontStyle: 'normal',
            fontVariant: '16px/1',
            fontFamily: '"Poppins",Arial,sans-serif',
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: '.04em',
            color: '#8F9CA4',
          },
        },
        plotOptions: {
          series: {
            events: {
              legendItemClick: function () {
                return false;
              },
            },
            states: {
              inactive: {
                opacity: 1
              },
            },
            point: {
              events: {
                mouseOver: this.setHoverData.bind(this)
              }
            },
            marker: {
              symbol: '<span class="text text-14 font-medium font-spacing-04">O</span>'
            }
          }
        },
        tooltip: {
          backgroundColor: '#3d3d3d',
          borderRadius: 8,
          borderWidth: 0,
          padding: 22,
          shadow: false,
          formatter: function () {
            return '<div class="chart_tooltip text">' +
              '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
              '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + formatNumber(this.y) + '</span></div>' +
              '</div>';
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 767
            },
            chartOptions: {
              xAxis: {
                labels: {
                  style: {
                    fontSize: '12px'
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontSize: '12px',
                  }
                }
              },
              tooltip: {
                padding: 12
              },
            }
          }]
        },
      },
      pieChartInfo: [],
      investmentReturnsData: {
        quarter1: [],
        quarter2: [],
        quarter3: [],
        quarter4: [],
        total: []
      },
      hoverData: null,
      annualPerformanceInvestment: '',
      annualPerformancePeriodFrom: currentYear,
      performanceActiveQuarter: 'total',
      toggleWrapperActive: '',
      activeMetricType: 'revenue',
      activeInvestmentType: 'all',
      activePeriod: currentYear,
      activeQuarter: 'total',
      activePerformanceMetricType: 'revenue',
      activePerformanceInvestmentType: 'all',
      activePerformancePeriodFrom: currentYear,
      activePerformancePeriodTo: currentYear,
      activeWaterfallInvestment: '',
      activePieTabType: 'country',
    };
  }

  setHoverData = (event) => {
    // The chart is not updated because `chartOptions` has not changed.
    this.setState({ hoverData: event.target.category });
  };

  handleToggleWrapper = () => {
    const css = (this.state.toggleWrapperActive === "active") ? "" : "active";
    this.setState({ toggleWrapperActive: css });
  };

  getLineReport = (reportType) => {
    const { activeInvestmentType, activePeriod, activeQuarter } = this.state;
    const { portfolioReports } = this.props;

    if (activeInvestmentType !== 'all') {
      this.getOneLineReport(reportType, activeInvestmentType);
    } else {
      let lineChartSeries = [];
      portfolioReports.map((element, index) => {
        let tmpObj = {
          name: element.name,
          color: colorsPlate[index], // getting random color from array
          type: 'spline',
          data: []
        };
        element.reports && element.reports.map(innerElement => {
          if (activeQuarter !== 'total') {
            if (parseInt(innerElement.year) === parseInt(activePeriod) && parseInt(innerElement.quarter) === parseInt(activeQuarter)) {
              tmpObj.data.push({
                x: innerElement.quarter - 1, // x property for quarter at line chart
                y: innerElement[reportType],
                tooltipData: {
                  data: {
                    label: reportType,
                    value: innerElement[reportType],
                  },
                  revenue: innerElement.revenue,
                  gross_profit: innerElement.gross_profit,
                  net_profit: innerElement.net_profit,
                  ebitda: innerElement.ebitda,
                }
              });
            }
          } else {
            if (parseInt(innerElement.year) === parseInt(activePeriod)) {
              tmpObj.data.push({
                x: innerElement.quarter - 1, // x property for quarter at line chart
                y: innerElement[reportType],
                tooltipData: {
                  data: {
                    label: reportType,
                    value: innerElement[reportType],
                  },
                  revenue: innerElement.revenue,
                  gross_profit: innerElement.gross_profit,
                  net_profit: innerElement.net_profit,
                  ebitda: innerElement.ebitda,
                }
              });
            }
          }

          return "";
        });
        return lineChartSeries.push(tmpObj);
      });

      this.setState((previousState) => {
        // changing only series property from state
        let chartOptions = Object.assign({}, previousState.chartOptions);
        chartOptions.series = lineChartSeries;
        chartOptions.xAxis.categories = setFinReportCategories(activePeriod);
        return {
          chartOptions,
          activeMetricType: reportType
        };
      });
    }
  };

  getOneLineReport = (reportType, reportID) => {
    const { portfolioReports } = this.props;
    const { activePeriod, activeQuarter } = this.state;
    const foundReport = portfolioReports.find(element => parseInt(element.id) === reportID);
    let obj = {
      name: foundReport.name,
      color: colorsPlate[0],
      type: 'spline',
      data: []
    };
    foundReport.reports && foundReport.reports.map(element => {
      if (activeQuarter !== 'total') {
        if (parseInt(element.year) === parseInt(activePeriod) && parseInt(element.quarter) === parseInt(activeQuarter)) {
          obj.data.push({
            x: element.quarter - 1, // x property for quarter at line chart
            y: element[reportType],
            tooltipData: {
              data: {
                label: reportType,
                value: element[reportType],
              },
              revenue: element.revenue,
              gross_profit: element.gross_profit,
              net_profit: element.net_profit,
              ebitda: element.ebitda,
            }
          });
        }
      } else {
        if (parseInt(element.year) === parseInt(activePeriod)) {
          obj.data.push({
            x: element.quarter - 1, // x property for quarter at line chart
            y: element[reportType],
            tooltipData: {
              data: {
                label: reportType,
                value: element[reportType],
              },
              revenue: element.revenue,
              gross_profit: element.gross_profit,
              net_profit: element.net_profit,
              ebitda: element.ebitda,
            }
          });
        }
      }

      return "";
    });

    this.setState((prevState) => {
      let chartOptions = Object.assign({}, prevState.chartOptions);
      chartOptions.series = [obj];
      chartOptions.xAxis.categories = setFinReportCategories(activePeriod);
      return { chartOptions, activeMetricType: reportType };
    });
  };

  getPieChartData = (pieTabType) => {
    const { closedReports, selectedCurrency } = this.props;
    let series = [{ colorByPoint: true, data: [] }];
    let pieChartInfo = [];

    const getFilterNameByTabType = (reportItem) => {
      const {opportunityGroups} = this.props;

      switch (pieTabType) {
        case 'country':
          return {
            key: 'country_name',
            label: reportItem.country_name,
          };
        case 'state':
          return {
            key: 'state_name',
            label: reportItem.state_name,
          };
        case 'sector':
          const investmentDictionary = findDictionaryByID(3, opportunityGroups);
          return {
            key: 'investment_type',
            label: findValueFromDictionary(reportItem.investment_type, investmentDictionary),
          };
        case 'assets':
          const industriesDictionary = findDictionaryByID(5, opportunityGroups);
          return {
            key: 'industry',
            label: findValueFromDictionary(reportItem.industry, industriesDictionary),
          };
        default:
      }
    };

    let result = closedReports ? closedReports.reduce((res, item) => {
      let filteringValue = item[getFilterNameByTabType(item).key];
      if (typeof res[filteringValue] == 'undefined') {
        res[filteringValue] = {
          name: getFilterNameByTabType(item).label,
          final_amount: 0
        };
      }
      res[filteringValue].final_amount += item.final_amount;
      return res;
    }, []) : {};

    result = Object.values(result);

    result && result.map((element, index) => {
      const color = colorsPlate[index];
      series[0].data.push({
        color,
        name: element.name,
        y: element.final_amount,
      });
      pieChartInfo.push({
        color,
        name: element.name,
        amount: `${getCurrencySymbol(selectedCurrency)} ${formatNumber(parseFloat(element.final_amount.toFixed(2)))}`,
      });

      return "";
    });

    this.setState((prevState) => {
      let pieChartOptions = Object.assign({}, prevState.pieChartOptions);
      pieChartOptions.series = series;
      return { pieChartOptions, pieChartInfo, activePieTabType: pieTabType };
    });
  };

  getInvestmentReturnData = (quarter) => {
    const { closedReports, selectedCurrency } = this.props;
    let returningData = [];
    closedReports && closedReports.map((parentElement) => {
      if (quarter !== 'total') {
        parentElement.reports && parentElement.reports.map((childElement) => {
          return this.neededTypesOfInvestmentReturns.map((neededItem) => {
            const numFromQuarterString = quarter.match(/\d+/)[0];
            if (parseInt(numFromQuarterString) === parseInt(childElement.quarter)) {
              let obj = {
                key: randomString(),
                name: [`${parentElement.name} - ${childElement.year}`, neededItem],
                summa: [formatNumber(childElement[neededItem.toLowerCase()]), getCurrencySymbol(selectedCurrency)],
              };
              returningData.push(obj);
            }

            return "";
          });
        });
      }
      else {
        let revenueTotal = 0;
        let ebitdaTotal = 0;
        parentElement.reports && parentElement.reports.map((reportItem) => {
          revenueTotal += parseInt(reportItem.revenue);
          ebitdaTotal += parseInt(reportItem.ebitda);

          return "";
        });

        const revenueTotalObj = {
          key: randomString(),
          name: [`${parentElement.name}`, 'Revenue'],
          summa: [formatNumber(revenueTotal), getCurrencySymbol(selectedCurrency)],
        };
        const ebitdaTotalObj = {
          key: randomString(),
          name: [`${parentElement.name}`, 'Ebitda'],
          summa: [formatNumber(ebitdaTotal), getCurrencySymbol(selectedCurrency)],
        };
        returningData.push(revenueTotalObj, ebitdaTotalObj);
      }

      return "";
    });

    this.setState(prevState => {
      let investmentReturnsData = Object.assign({}, prevState.investmentReturnsData);
      investmentReturnsData[quarter] = returningData;
      return { investmentReturnsData };
    });
  };

  getReportingMetricBtnColor = (metric) => this.state.activeMetricType === metric ? 'blue' : 'grey';

  getPerformanceReportingMetricColor = (metric) => this.state.activePerformanceMetricType === metric ? 'blue' : 'grey';

  getPerformanceBarChart = (reportType) => {
    const { closedReports, selectedCurrency } = this.props;
    const { activePerformanceInvestmentType, activePerformancePeriodFrom, performanceActiveQuarter } = this.state;
    let tmpBarOptionSeries = [
      {
        name: "revenue", color: "#F5C65B", type: 'column',
        data: [null, null, null, null],
      },
      {
        name: "gross profit", color: "#FF551F", type: 'column',
        data: [null, null, null, null],
      },
      {
        name: "net profit", color: "#36C1A3", type: 'column',
        data: [null, null, null, null],
      },
      {
        name: "ebitda", color: "#3665C1", type: 'column',
        data: [null, null, null, null],
      },
    ];
    let revenueReportsSumQ1 = 0;
    let revenueReportsSumQ2 = 0;
    let revenueReportsSumQ3 = 0;
    let revenueReportsSumQ4 = 0;
    let grossProfitReportsSumQ1 = 0;
    let grossProfitReportsSumQ2 = 0;
    let grossProfitReportsSumQ3 = 0;
    let grossProfitReportsSumQ4 = 0;
    let netProfitReportsSumQ1 = 0;
    let netProfitReportsSumQ2 = 0;
    let netProfitReportsSumQ3 = 0;
    let netProfitReportsSumQ4 = 0;
    let ebitdaReportsSumQ1 = 0;
    let ebitdaReportsSumQ2 = 0;
    let ebitdaReportsSumQ3 = 0;
    let ebitdaReportsSumQ4 = 0;

    if (activePerformanceInvestmentType === 'all') {
      closedReports && closedReports.map((closedReportsItem, index) => {
        closedReportsItem.reports.map(reportItem => {
          const reportItemQuarter = parseInt(reportItem.quarter);
          const reportItemYear = parseInt(reportItem.year);
          const periodFrom = parseInt(activePerformancePeriodFrom);
          switch (reportType) {
            case 'revenue':
              if (reportItemQuarter === 1 && periodFrom === reportItemYear) {
                revenueReportsSumQ1 += reportItem[reportType];
              }
              if (reportItemQuarter === 2 && periodFrom === reportItemYear) {
                revenueReportsSumQ2 += reportItem[reportType];
              }
              if (reportItemQuarter === 3 && periodFrom === reportItemYear) {
                revenueReportsSumQ3 += reportItem[reportType];
              }
              if (reportItemQuarter === 4 && periodFrom === reportItemYear) {
                revenueReportsSumQ4 += reportItem[reportType];
              }
              break;
            case 'gross_profit':
              if (reportItemQuarter === 1 && periodFrom === reportItemYear) {
                grossProfitReportsSumQ1 += reportItem[reportType];
              }
              if (reportItemQuarter === 2 && periodFrom === reportItemYear) {
                grossProfitReportsSumQ2 += reportItem[reportType];
              }
              if (reportItemQuarter === 3 && periodFrom === reportItemYear) {
                grossProfitReportsSumQ3 += reportItem[reportType];
              }
              if (reportItemQuarter === 4 && periodFrom === reportItemYear) {
                grossProfitReportsSumQ4 += reportItem[reportType];
              }
              break;
            case 'net_profit':
              if (reportItemQuarter === 1 && periodFrom === reportItemYear) {
                netProfitReportsSumQ1 += reportItem[reportType];
              }
              if (reportItemQuarter === 2 && periodFrom === reportItemYear) {
                netProfitReportsSumQ2 += reportItem[reportType];
              }
              if (reportItemQuarter === 3 && periodFrom === reportItemYear) {
                netProfitReportsSumQ3 += reportItem[reportType];
              }
              if (reportItemQuarter === 4 && periodFrom === reportItemYear) {
                netProfitReportsSumQ4 += reportItem[reportType];
              }
              break;
            case 'ebitda':
              if (reportItemQuarter === 1 && periodFrom === reportItemYear) {
                ebitdaReportsSumQ1 += reportItem[reportType];
              }
              if (reportItemQuarter === 2 && periodFrom === reportItemYear) {
                ebitdaReportsSumQ2 += reportItem[reportType];
              }
              if (reportItemQuarter === 3 && periodFrom === reportItemYear) {
                ebitdaReportsSumQ3 += reportItem[reportType];
              }
              if (reportItemQuarter === 4 && periodFrom === reportItemYear) {
                ebitdaReportsSumQ4 += reportItem[reportType];
              }
              break;
            default:
          }

          return "";
        });

        return "";
      });
    } else {
      const foundReportItem = closedReports.find(item => parseInt(item.id) === parseInt(activePerformanceInvestmentType));
      foundReportItem && foundReportItem.reports.map((reportItem, index) => {
        const reportItemQuarter = parseInt(reportItem.quarter);
        const reportItemYear = parseInt(reportItem.year);
        const periodFrom = parseInt(activePerformancePeriodFrom);
        switch (reportType) {
          case 'revenue':
            if (reportItemQuarter === 1 && periodFrom === reportItemYear) {
              revenueReportsSumQ1 += reportItem[reportType];
            }
            if (reportItemQuarter === 2 && periodFrom === reportItemYear) {
              revenueReportsSumQ2 += reportItem[reportType];
            }
            if (reportItemQuarter === 3 && periodFrom === reportItemYear) {
              revenueReportsSumQ3 += reportItem[reportType];
            }
            if (reportItemQuarter === 4 && periodFrom === reportItemYear) {
              revenueReportsSumQ4 += reportItem[reportType];
            }
            break;
          case 'gross_profit':
            if (reportItemQuarter === 1 && periodFrom === reportItemYear) {
              grossProfitReportsSumQ1 += reportItem[reportType];
            }
            if (reportItemQuarter === 2 && periodFrom === reportItemYear) {
              grossProfitReportsSumQ2 += reportItem[reportType];
            }
            if (reportItemQuarter === 3 && periodFrom === reportItemYear) {
              grossProfitReportsSumQ3 += reportItem[reportType];
            }
            if (reportItemQuarter === 4 && periodFrom === reportItemYear) {
              grossProfitReportsSumQ4 += reportItem[reportType];
            }
            break;
          case 'net_profit':
            if (reportItemQuarter === 1 && periodFrom === reportItemYear) {
              netProfitReportsSumQ1 += reportItem[reportType];
            }
            if (reportItemQuarter === 2 && periodFrom === reportItemYear) {
              netProfitReportsSumQ2 += reportItem[reportType];
            }
            if (reportItemQuarter === 3 && periodFrom === reportItemYear) {
              netProfitReportsSumQ3 += reportItem[reportType];
            }
            if (reportItemQuarter === 4 && periodFrom === reportItemYear) {
              netProfitReportsSumQ4 += reportItem[reportType];
            }
            break;
          case 'ebitda':
            if (reportItemQuarter === 1 && periodFrom === reportItemYear) {
              ebitdaReportsSumQ1 += reportItem[reportType];
            }
            if (reportItemQuarter === 2 && periodFrom === reportItemYear) {
              ebitdaReportsSumQ2 += reportItem[reportType];
            }
            if (reportItemQuarter === 3 && periodFrom === reportItemYear) {
              ebitdaReportsSumQ3 += reportItem[reportType];
            }
            if (reportItemQuarter === 4 && periodFrom === reportItemYear) {
              ebitdaReportsSumQ4 += reportItem[reportType];
            }
            break;
          case 'all':
            if (reportItemQuarter === 1 && periodFrom === reportItemYear) {
              revenueReportsSumQ1 += reportItem.revenue;
              grossProfitReportsSumQ1 += reportItem.gross_profit;
              netProfitReportsSumQ1 += reportItem.net_profit;
              ebitdaReportsSumQ1 += reportItem.ebitda;
            }
            if (reportItemQuarter === 2 && periodFrom === reportItemYear) {
              revenueReportsSumQ2 += reportItem.revenue;
              grossProfitReportsSumQ2 += reportItem.gross_profit;
              netProfitReportsSumQ2 += reportItem.net_profit;
              ebitdaReportsSumQ2 += reportItem.ebitda;
            }
            if (reportItemQuarter === 3 && periodFrom === reportItemYear) {
              revenueReportsSumQ3 += reportItem.revenue;
              grossProfitReportsSumQ3 += reportItem.gross_profit;
              netProfitReportsSumQ3 += reportItem.net_profit;
              ebitdaReportsSumQ3 += reportItem.ebitda;
            }
            if (reportItemQuarter === 4 && periodFrom === reportItemYear) {
              revenueReportsSumQ4 += reportItem.revenue;
              grossProfitReportsSumQ4 += reportItem.gross_profit;
              netProfitReportsSumQ4 += reportItem.net_profit;
              ebitdaReportsSumQ4 += reportItem.ebitda;
            }
            break;
          default:
        }

        return "";
      });
    }

    switch (reportType) {
      case 'revenue':
        if (performanceActiveQuarter !== 'total') {
          switch (parseInt(performanceActiveQuarter)) {
            case 1:
              // we are setting performanceActiveQuarter - 1, cause our quarter numbers starts with 1, but we need to set value in Array
              tmpBarOptionSeries[0].data[parseInt(performanceActiveQuarter) - 1] = revenueReportsSumQ1;
              break;
            case 2:
              tmpBarOptionSeries[0].data[parseInt(performanceActiveQuarter) - 1] = revenueReportsSumQ2;
              break;
            case 3:
              tmpBarOptionSeries[0].data[parseInt(performanceActiveQuarter) - 1] = revenueReportsSumQ3;
              break;
            case 4:
              tmpBarOptionSeries[0].data[parseInt(performanceActiveQuarter) - 1] = revenueReportsSumQ4;
              break;
            default:
          }
        } else {
          tmpBarOptionSeries[0].data[0] = revenueReportsSumQ1;
          tmpBarOptionSeries[0].data[1] = revenueReportsSumQ2;
          tmpBarOptionSeries[0].data[2] = revenueReportsSumQ3;
          tmpBarOptionSeries[0].data[3] = revenueReportsSumQ4;
        }
        break;
      case 'gross_profit':
        if (performanceActiveQuarter !== 'total') {
          switch (parseInt(performanceActiveQuarter)) {
            case 1:
              tmpBarOptionSeries[1].data[parseInt(performanceActiveQuarter) - 1] = grossProfitReportsSumQ1;
              break;
            case 2:
              tmpBarOptionSeries[1].data[parseInt(performanceActiveQuarter) - 1] = grossProfitReportsSumQ2;
              break;
            case 3:
              tmpBarOptionSeries[1].data[parseInt(performanceActiveQuarter) - 1] = grossProfitReportsSumQ3;
              break;
            case 4:
              tmpBarOptionSeries[1].data[parseInt(performanceActiveQuarter) - 1] = grossProfitReportsSumQ4;
              break;
            default:
          }
        } else {
          tmpBarOptionSeries[1].data[0] = grossProfitReportsSumQ1;
          tmpBarOptionSeries[1].data[1] = grossProfitReportsSumQ2;
          tmpBarOptionSeries[1].data[2] = grossProfitReportsSumQ3;
          tmpBarOptionSeries[1].data[3] = grossProfitReportsSumQ4;
        }
        break;
      case 'net_profit':
        if (performanceActiveQuarter !== 'total') {
          switch (parseInt(performanceActiveQuarter)) {
            case 1:
              tmpBarOptionSeries[2].data[parseInt(performanceActiveQuarter) - 1] = netProfitReportsSumQ1;
              break;
            case 2:
              tmpBarOptionSeries[2].data[parseInt(performanceActiveQuarter) - 1] = netProfitReportsSumQ2;
              break;
            case 3:
              tmpBarOptionSeries[2].data[parseInt(performanceActiveQuarter) - 1] = netProfitReportsSumQ3;
              break;
            case 4:
              tmpBarOptionSeries[2].data[parseInt(performanceActiveQuarter) - 1] = netProfitReportsSumQ4;
              break;
            default:
          }
        } else {
          tmpBarOptionSeries[2].data[0] = netProfitReportsSumQ1;
          tmpBarOptionSeries[2].data[1] = netProfitReportsSumQ2;
          tmpBarOptionSeries[2].data[2] = netProfitReportsSumQ3;
          tmpBarOptionSeries[2].data[3] = netProfitReportsSumQ4;
        }
        break;
      case 'ebitda':
        if (performanceActiveQuarter !== 'total') {
          switch (parseInt(performanceActiveQuarter)) {
            case 1:
              tmpBarOptionSeries[3].data[parseInt(performanceActiveQuarter) - 1] = ebitdaReportsSumQ1;
              break;
            case 2:
              tmpBarOptionSeries[3].data[parseInt(performanceActiveQuarter) - 1] = ebitdaReportsSumQ2;
              break;
            case 3:
              tmpBarOptionSeries[3].data[parseInt(performanceActiveQuarter) - 1] = ebitdaReportsSumQ3;
              break;
            case 4:
              tmpBarOptionSeries[3].data[parseInt(performanceActiveQuarter) - 1] = ebitdaReportsSumQ4;
              break;
            default:
          }
        } else {
          tmpBarOptionSeries[3].data[0] = ebitdaReportsSumQ1;
          tmpBarOptionSeries[3].data[1] = ebitdaReportsSumQ2;
          tmpBarOptionSeries[3].data[2] = ebitdaReportsSumQ3;
          tmpBarOptionSeries[3].data[3] = ebitdaReportsSumQ4;
        }
        break;
      case 'all':
        if (performanceActiveQuarter !== 'total') {
          switch (parseInt(performanceActiveQuarter)) {
            case 1:
              tmpBarOptionSeries[0].data[parseInt(performanceActiveQuarter) - 1] = revenueReportsSumQ1;
              tmpBarOptionSeries[1].data[parseInt(performanceActiveQuarter) - 1] = grossProfitReportsSumQ1;
              tmpBarOptionSeries[2].data[parseInt(performanceActiveQuarter) - 1] = netProfitReportsSumQ1;
              tmpBarOptionSeries[3].data[parseInt(performanceActiveQuarter) - 1] = ebitdaReportsSumQ1;
              break;
            case 2:
              tmpBarOptionSeries[0].data[parseInt(performanceActiveQuarter) - 1] = revenueReportsSumQ2;
              tmpBarOptionSeries[1].data[parseInt(performanceActiveQuarter) - 1] = grossProfitReportsSumQ2;
              tmpBarOptionSeries[2].data[parseInt(performanceActiveQuarter) - 1] = netProfitReportsSumQ2;
              tmpBarOptionSeries[3].data[parseInt(performanceActiveQuarter) - 1] = ebitdaReportsSumQ2;
              break;
            case 3:
              tmpBarOptionSeries[0].data[parseInt(performanceActiveQuarter) - 1] = revenueReportsSumQ3;
              tmpBarOptionSeries[1].data[parseInt(performanceActiveQuarter) - 1] = grossProfitReportsSumQ3;
              tmpBarOptionSeries[2].data[parseInt(performanceActiveQuarter) - 1] = netProfitReportsSumQ3;
              tmpBarOptionSeries[3].data[parseInt(performanceActiveQuarter) - 1] = ebitdaReportsSumQ3;
              break;
            case 4:
              tmpBarOptionSeries[0].data[parseInt(performanceActiveQuarter) - 1] = revenueReportsSumQ4;
              tmpBarOptionSeries[1].data[parseInt(performanceActiveQuarter) - 1] = grossProfitReportsSumQ4;
              tmpBarOptionSeries[2].data[parseInt(performanceActiveQuarter) - 1] = netProfitReportsSumQ4;
              tmpBarOptionSeries[3].data[parseInt(performanceActiveQuarter) - 1] = ebitdaReportsSumQ4;
              break;
            default:
          }
        } else {
          tmpBarOptionSeries[0].data[0] = revenueReportsSumQ1;
          tmpBarOptionSeries[0].data[1] = revenueReportsSumQ2;
          tmpBarOptionSeries[0].data[2] = revenueReportsSumQ3;
          tmpBarOptionSeries[0].data[3] = revenueReportsSumQ4;
          tmpBarOptionSeries[1].data[0] = grossProfitReportsSumQ1;
          tmpBarOptionSeries[1].data[1] = grossProfitReportsSumQ2;
          tmpBarOptionSeries[1].data[2] = grossProfitReportsSumQ3;
          tmpBarOptionSeries[1].data[3] = grossProfitReportsSumQ4;
          tmpBarOptionSeries[2].data[0] = netProfitReportsSumQ1;
          tmpBarOptionSeries[2].data[1] = netProfitReportsSumQ2;
          tmpBarOptionSeries[2].data[2] = netProfitReportsSumQ3;
          tmpBarOptionSeries[2].data[3] = netProfitReportsSumQ4;
          tmpBarOptionSeries[3].data[0] = ebitdaReportsSumQ1;
          tmpBarOptionSeries[3].data[1] = ebitdaReportsSumQ2;
          tmpBarOptionSeries[3].data[2] = ebitdaReportsSumQ3;
          tmpBarOptionSeries[3].data[3] = ebitdaReportsSumQ4;
        }
        break;
      default:
    }

    this.setState((prevState) => {
      let barChartOptions = Object.assign({}, prevState.barOptions);
      barChartOptions.series = tmpBarOptionSeries;
      barChartOptions.tooltip.formatter = function () {
        return '<div class="chart_tooltip text">' +
          '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
          '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + selectedCurrency + ' ' + formatNumber(Math.round(this.y)) + '</span></div>' +
          '</div>';
      };
      return {
        barOptions: barChartOptions,
        activePerformanceMetricType: reportType,
      }
    });
  };

  setPerformanceInvestmentType = (investmentType) => {
    this.setState({
      activePerformanceInvestmentType: investmentType,
    }, () => {
      this.getPerformanceBarChart(this.state.activePerformanceMetricType);
    });
  };

  setPerformanceActivePeriod = (type, period) => {
    this.setState({
      [type === 'from' ? 'activePerformancePeriodFrom' : 'activePerformancePeriodTo']: period,
    }, () => {
      this.getPerformanceBarChart(this.state.activePerformanceMetricType);
    });
  };

  getValidYearsOfWaterfallTable = () => {
    const { portfolioReports } = this.props;
    const { activeWaterfallInvestment } = this.state;
    let allYears = [];

    const foundReport = portfolioReports.find((element) => parseInt(element.id) === parseInt(activeWaterfallInvestment));

    foundReport && foundReport.reports && foundReport.reports.map((element) => {
      return allYears.push(element.year);
    });

    const seen = new Set();
    // removing repeating elements from array, saving only 1 item per repeating
    const filteredArr = allYears.filter(el => {
      const duplicate = seen.has(el);
      seen.add(el);
      return !duplicate;
    });
    return filteredArr;
  };

  getWaterfallChartData = () => {
    const { portfolioReports, selectedCurrency } = this.props;
    const { activeWaterfallInvestment } = this.state;
    let series = [
      {
        name: "investment", color: "#F5C65B", type: 'column', showInLegend: true,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "investment q1", color: "#F5C65B", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "distribution", color: "#36C1A3", type: 'column', showInLegend: true,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "investment q2", color: "#F5C65B", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "distribution q2", color: "#36C1A3", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "investment q3", color: "#F5C65B", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "distribution q3", color: "#36C1A3", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "investment q4", color: "#F5C65B", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "distribution q4", color: "#36C1A3", type: 'column', showInLegend: false,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill(null),
      },
      {
        name: "subtotal", color: "#FF551F", type: 'column', showInLegend: true,
        data: new Array(this.getValidYearsOfWaterfallTable().length).fill({ y: null, x: null }),
      },
    ];
    let categories = [];
    let subtotalSum = 0;

    const foundReport = portfolioReports.find((element) => parseInt(element.id) === parseInt(activeWaterfallInvestment));
    const finalDate = foundReport ? foundReport.final_date : "0000-01-01";
    const finalYear = finalDate ? moment(finalDate).year() : 0;
    const quarter = finalDate ? Math.ceil(finalDate.split("-")[1] / 3) : '';

    if (this.getValidYearsOfWaterfallTable().length) {
      this.getValidYearsOfWaterfallTable().map((validYearItem, yearIndex) => {
        let currentYearInitialInvestment = null;
        let currentYearInvestmentQ1 = null;
        let currentYearInvestmentQ2 = null;
        let currentYearInvestmentQ3 = null;
        let currentYearInvestmentQ4 = null;
        let currentYearDistributionQ1 = null;
        let currentYearDistributionQ2 = null;
        let currentYearDistributionQ3 = null;
        let currentYearDistributionQ4 = null;

        foundReport && foundReport.reports && foundReport.reports.map((element, index) => {
          if (parseInt(validYearItem) === parseInt(element.year)) {
            if (yearIndex === 0) {
              currentYearInitialInvestment = foundReport.final_amount * -1;
            }
            if (element.dividend < 0) {
              switch (parseInt(element.quarter)) {
                case 1:
                  currentYearInvestmentQ1 += element.dividend;
                  break;
                case 2:
                  currentYearInvestmentQ2 += element.dividend;
                  break;
                case 3:
                  currentYearInvestmentQ3 += element.dividend;
                  break;
                case 4:
                  currentYearInvestmentQ4 += element.dividend;
                  break;
                default:
              }
            }
            else {
              switch (parseInt(element.quarter)) {
                case 1:
                  currentYearDistributionQ1 += element.dividend;
                  break;
                case 2:
                  currentYearDistributionQ2 += element.dividend;
                  break;
                case 3:
                  currentYearDistributionQ3 += element.dividend;
                  break;
                case 4:
                  currentYearDistributionQ4 += element.dividend;
                  break;
                default:
              }
            }
          }

          return "";
        });
        let currentYearSubTotal = (currentYearDistributionQ1 + currentYearDistributionQ2 + currentYearDistributionQ3 + currentYearDistributionQ4) - Math.abs(currentYearInitialInvestment + currentYearInvestmentQ1 + currentYearInvestmentQ2 + currentYearInvestmentQ3 + currentYearInvestmentQ4);
        series[0].data[yearIndex] = {
          y: currentYearInitialInvestment,
          x: yearIndex,
        };
        series[1].data[yearIndex] = {
          y: currentYearInvestmentQ1,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[2].data[yearIndex] = {
          y: currentYearDistributionQ1,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[3].data[yearIndex] = {
          y: currentYearInvestmentQ2,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[4].data[yearIndex] = {
          y: currentYearDistributionQ2,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[5].data[yearIndex] = {
          y: currentYearInvestmentQ3,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[6].data[yearIndex] = {
          y: currentYearDistributionQ3,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[7].data[yearIndex] = {
          y: currentYearInvestmentQ4,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        series[8].data[yearIndex] = {
          y: currentYearDistributionQ4,
          x: !this.getValidYearsOfWaterfallTable().includes(finalYear) ? yearIndex + 1 : yearIndex,
        };
        if (currentYearDistributionQ2) {
          series[2].showInLegend = false;
          series[4].showInLegend = true;
          series[6].showInLegend = false;
          series[8].showInLegend = false;
        }
        if (currentYearDistributionQ3) {
          series[2].showInLegend = false;
          series[4].showInLegend = false;
          series[6].showInLegend = true;
          series[8].showInLegend = false;
        }
        if (currentYearDistributionQ4) {
          series[2].showInLegend = false;
          series[4].showInLegend = false;
          series[6].showInLegend = false;
          series[8].showInLegend = true;
        }
        subtotalSum += currentYearSubTotal;
        if (yearIndex === 0) {
          categories.push(finalYear);
          if (!this.getValidYearsOfWaterfallTable().includes(finalYear)) {
            categories.push(validYearItem);
          }
        } else {
          categories.push(validYearItem);
        }

        return "";
      });
      series[9].data[this.getValidYearsOfWaterfallTable().length] = {
        y: subtotalSum,
        x: this.getValidYearsOfWaterfallTable().length,
      };
      categories.push('Subtotal');
    } else {
      series[0].data[0] = {
        y: foundReport ? foundReport.final_amount * -1 : 0,
        x: 0,
      };
      series[1].data[0] = { y: 0, x: 0 };
      series[2].data[0] = { y: 0, x: 0 };
      series[3].data[0] = { y: 0, x: 0 };
      series[4].data[0] = { y: 0, x: 0 };
      series[5].data[0] = { y: 0, x: 0 };
      series[6].data[0] = { y: 0, x: 0 };
      series[7].data[0] = { y: 0, x: 0 };
      series[8].data[0] = { y: 0, x: 0 };
      series[9].data[0] = { y: 0, x: 0 };
      foundReport && categories.push(new Date(foundReport.closed).getFullYear());
    }

    const filteredSeries = [];
    for (let i = 0; i < series.length; i++) {
      const item = series[i];
      const filtered = item.data.filter((element) => {
        return element.y != null;
      });
      item.data = filtered.length > 0 ? filtered : null;
      if (item.data != null) filteredSeries.push(item);
    }

    let datasetCountList = [];
    filteredSeries.map((item) => datasetCountList.push(item.data.length));

    let maxDatasetCount = 0;
    for (let i = 0; i < datasetCountList.length; i++) {
      if (datasetCountList[i] > maxDatasetCount) {
        maxDatasetCount = datasetCountList[i];
      }
    }
    for (let i = 0; i <= maxDatasetCount - 2; i++) {
      filteredSeries[filteredSeries.length - 1].data.unshift(null);
    }

    this.setState(prevState => {
      let waterfallChartOptions = Object.assign({}, prevState.waterfallChartOptions);
      waterfallChartOptions.series = filteredSeries;
      waterfallChartOptions.xAxis.categories = categories;
      waterfallChartOptions.tooltip.formatter = function () {
        const tooltipTitle = this.series.name === 'distribution' ? 'distribution q1' :
        this.series.name === "investment" || this.series.name === "subtotal" ? this.series.name + " q" + quarter : this.series.name;

        return '<div class="chart_tooltip text">' +
          '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + tooltipTitle + '</div><br/>' +
          '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + selectedCurrency + ' ' + formatNumber(Math.round(this.y)) + '</span></div>' +
          '</div>';
      };
      return { waterfallChartOptions };
    });
  };

  setWaterfallActiveInvestment = (investment) => {
    this.setState({
      activeWaterfallInvestment: investment,
    }, () => {
      this.getWaterfallChartData(investment);
    });
  };

  getCurrencyList = () => {
    const currencyList = ['CAD', 'USD', 'EUR', 'GBP', 'AMD', 'AED'];

    return (
      <>
        {
          currencyList.map((element, index) => (
            <div
              key={index}
              className="flex-container align-middle color-grey dropdown_menu__item cursor"
              onClick={() => this.getReportViaCurrency(element)}
            >
              <img src={require("../../assets/img/usd.svg")} alt="" />
              <div className="text text-14 font-semibold">
                {element}
              </div>
            </div>
          ))
        }
      </>
    );
  };

  handleInvestmentsOnChange = (value) => {
    const { activeMetricType } = this.state;

    this.setState({
      activeInvestmentType: value,
    }, () => {
      if (value === 'all') {
        this.getLineReport(activeMetricType);
      } else {
        this.getOneLineReport(activeMetricType, value);
      }
    });
  };

  handlePeriodOnChange = (value) => {
    this.setState({
      activePeriod: value,
    }, () => {
      this.getLineReport(this.state.activeMetricType);
    });
  };

  handleQuarterBtnOnClick = (quarter) => {
    this.setState({
      activeQuarter: quarter
    }, () => {
      this.getLineReport(this.state.activeMetricType);
    })
  };

  getReportViaCurrency = (currency) => {
    const {
      getReport,
      xID,
      getClosedReports,
      form,
    } = this.props;

    form.resetFields();
    getReport(xID, currency);
    getClosedReports(currency);
  };

  toggleQuarter = (chartType, quarter) => {
    this.setState({
      [chartType]: quarter,
    }, () => {
      if (chartType === 'performanceActiveQuarter') {
        this.getPerformanceBarChart(this.state.activePerformanceMetricType);
      }
    });
  };

  getQuarterBtnColor = (stateName, quarter) => this.state[stateName] === quarter ? 'blue' : 'grey';

  getValidYearsOfAnnualPerformance = () => {
    const { closedReports } = this.props;
    const { annualPerformanceInvestment } = this.state;
    let allYears = [];

    const foundReport = closedReports.find((element) => parseInt(element.id) === parseInt(annualPerformanceInvestment));

    foundReport && foundReport.reports && foundReport.reports.map((element) => {
      return allYears.push(element.year);
    });

    const seen = new Set();
    // removing repeating elements from array, saving only 1 item per repeating
    const filteredArr = allYears.filter(el => {
      const duplicate = seen.has(el);
      seen.add(el);
      return !duplicate;
    });
    return filteredArr;
  };

  setAnnualPerformanceInvestment = (value) => {
    this.setState({
      annualPerformanceInvestment: value,
    }, () => {
      this.getAnnualPerformance();
    });
  };

  getAnnualPerformance = () => {
    const { closedReports, selectedCurrency } = this.props;
    const { annualPerformanceInvestment } = this.state;
    let tmpBarOptionSeries = [
      {
        name: "revenue", color: "#F5C65B", type: 'column',
        data: new Array(this.getValidYearsOfAnnualPerformance().length).fill(null),
      },
      {
        name: "gross profit", color: "#FF551F", type: 'column',
        data: new Array(this.getValidYearsOfAnnualPerformance().length).fill(null),
      },
      {
        name: "net profit", color: "#36C1A3", type: 'column',
        data: new Array(this.getValidYearsOfAnnualPerformance().length).fill(null),
      },
      {
        name: "ebitda", color: "#3665C1", type: 'column',
        data: new Array(this.getValidYearsOfAnnualPerformance().length).fill(null),
      },
    ];
    const foundReport = closedReports.find((element) => parseInt(element.id) === parseInt(annualPerformanceInvestment));

    this.getValidYearsOfAnnualPerformance().map((validYearItem, index) => {
      let currentYearRevenue = 0;
      let currentYearGrossProfit = 0;
      let currentYearNetProfit = 0;
      let currentYearEbitda = 0;
      foundReport && foundReport.reports && foundReport.reports.map((element) => {
        if (parseInt(validYearItem) === parseInt(element.year)) {
          currentYearRevenue += element.revenue;
          currentYearGrossProfit += element.gross_profit;
          currentYearNetProfit += element.net_profit;
          currentYearEbitda += element.ebitda;
        }

        return "";
      });
      tmpBarOptionSeries[0].data[index] = currentYearRevenue;
      tmpBarOptionSeries[1].data[index] = currentYearGrossProfit;
      tmpBarOptionSeries[2].data[index] = currentYearNetProfit;
      tmpBarOptionSeries[3].data[index] = currentYearEbitda;

      return "";
    });

    this.setState((prevState) => {
      let annualPerformanceBarOptions = Object.assign({}, prevState.annualPerformanceBarOptions);
      annualPerformanceBarOptions.series = tmpBarOptionSeries;
      annualPerformanceBarOptions.xAxis.categories = this.getValidYearsOfAnnualPerformance();
      annualPerformanceBarOptions.tooltip.formatter = function () {
        return '<div class="chart_tooltip text">' +
          '<div class="text text-14 font-medium font-uppercase font-spacing-04 chart_tooltip__title">' + this.series.name + '</div><br/>' +
          '<div dy="8"><span class="chart_tooltip__label">' + this.point.category + '</span>: <span class="chart_tooltip__val" dx="5">' + selectedCurrency + ' ' + formatNumber(Math.round(this.y)) + '</span></div>' +
          '</div>';
      };
      return { annualPerformanceBarOptions }
    });
  };

  findActiveFinancialPerformanceOpportunity = () => {
    const { portfolioReports } = this.props;
    const { activePerformanceInvestmentType } = this.state;
    if (portfolioReports && activePerformanceInvestmentType && activePerformanceInvestmentType !== 'all') {
      return portfolioReports.find((item) => parseInt(item.id) === parseInt(activePerformanceInvestmentType));
    }

    return null;
  };

  printYearEndSection = () => {
    if (this.findActiveFinancialPerformanceOpportunity()) {
      return (
        <div className='text portfolio-year-end-wrapper text-14 font-uppercase font-spacing-04' >
          Year end: <span className="color-grey-800" >{MONTHS[this.findActiveFinancialPerformanceOpportunity().fiscal_end]}</span>
        </div>
      );
    }

    return null;
  };

  componentDidUpdate(prevProps, prevState) {
    const { loading, errorMessage, closedReports, portfolioReports, selectedCurrency, activeInvestmentId } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;

    if (loadingType === 'GetReport' && !loadingState && prevProps.loading.state && !errorText) {
      this.getInvestmentReturnData('quarter1');
    }

    if (closedReports !== prevProps.closedReports) {
      this.setState({
        annualPerformanceInvestment: closedReports[0] ? closedReports[0].id : '',
        activePerformanceInvestmentType: activeInvestmentId ? +activeInvestmentId : closedReports[0] ? closedReports[0].id : '',
      }, () => {
        this.getPerformanceBarChart('all');
        this.getPieChartData('country');
        this.getAnnualPerformance();
      });
    }

    if (selectedCurrency !== prevProps.selectedCurrency) {
      const { activePerformanceMetricType, activePieTabType } = this.state;
      this.getPerformanceBarChart(activePerformanceMetricType);
      this.getWaterfallChartData();
      this.getAnnualPerformance();
      this.getPieChartData(activePieTabType);
    }

    if (portfolioReports !== prevProps.portfolioReports) {
      this.setState({
        activeWaterfallInvestment: portfolioReports[0] ? portfolioReports[0].id : '',
      }, () => {
        this.getWaterfallChartData();
      });
    }
  }

  componentDidMount() {
    const { closedReports, portfolioReports, activeInvestmentId } = this.props;

    if (closedReports) {
      this.setState({
        annualPerformanceInvestment: closedReports[0] ? closedReports[0].id : '',
        activePerformanceInvestmentType: activeInvestmentId ? +activeInvestmentId : closedReports[0] ? closedReports[0].id : '',
      }, () => {
        this.getPerformanceBarChart('revenue');
        this.getPieChartData('country');
        this.getAnnualPerformance();
      });
    }

    if (portfolioReports) {
      this.setState({
        activeWaterfallInvestment: portfolioReports[0] ? portfolioReports[0].id : '',
      }, () => {
        this.getWaterfallChartData();
      });
    }
  }

  render() {
    const {
      form,
      portfolioReports,
      selectedCurrency,
      closedReports,
    } = this.props;
    const { getFieldDecorator } = form;
    const {
      pieChartOptions, barOptions, waterfallChartOptions,
      toggleWrapperActive, pieChartInfo,
      activePerformanceMetricType, activePerformanceInvestmentType,
      activePerformancePeriodFrom, activeWaterfallInvestment,
      annualPerformanceBarOptions, annualPerformanceInvestment,
    } = this.state;

    return (
      <>
        <div className="flex-container medium-align-right">
          <div className="flex-container align-middle currency_control mb_20">
            <div className="text text-14 font-medium color-grey-900 font-uppercase font-spacing-04 currency_control__label" >
              Currency
            </div>
            <div className="">
              <Dropdown
                overlay={
                  <div className="bg-white radius-8 shadow-layout dropdown_menu">
                    {this.getCurrencyList()}
                  </div>
                }
                trigger={['click']}
              >
                <div className="flex-container align-middle color-grey cursor currency_control_selected">
                  <img src={require("../../assets/img/usd.svg")} alt=""/>
                  <span className="text text-18 font-bold">
                    { selectedCurrency }
                  </span>
                  <Icon type="key-down"/>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        <AnalyticItemsWrapper />
        <div className="row portfolio_section">
          <div className="column small-12">
            <div className="bg-white radius-10 shadow-layout portfolio_card">
              <Tabs
                defaultActiveKey="country"
                className="tab_default"
              >
                <TabPane
                  tab={
                    <div className="text text-18 font-semibold">
                      Financial performance
                    </div>
                  }
                  key="fin-performance"
                >
                  <div>
                    <Form className="relative">
                      <button
                        onClick={this.handleToggleWrapper}
                        className="hide-md hide-lg show-sm btn-initial color-blue toggle_btn"
                      >
                        <i className="icon icon-filter-toggle"/>
                      </button>
                      <div className={`toggle_wrapper ${toggleWrapperActive}`}>
                        <div className="chart_filter medium-flex-container align-justify">
                          <div className="chart_filter_section">
                            <div className="text text-14 color-grey-900 font-medium font-uppercase font-spacing-04 mb_25 linear_label">
                              metrics
                            </div>
                            <div className="flex-container align-middle flex-wrap chart_filter_buttons">
                              <Button
                                title="Revenue"
                                size="sm"
                                theme={this.getPerformanceReportingMetricColor('revenue')}
                                className="radius-4"
                                onClick={() => this.getPerformanceBarChart('revenue')}
                              />
                              <Button
                                title="Gross Profit"
                                size="sm"
                                theme={this.getPerformanceReportingMetricColor('gross_profit')}
                                className="radius-4"
                                onClick={() => this.getPerformanceBarChart('gross_profit')}
                              />
                              <Button
                                title="Net Profit"
                                size="sm"
                                theme={this.getPerformanceReportingMetricColor('net_profit')}
                                className="radius-4"
                                onClick={() => this.getPerformanceBarChart('net_profit')}
                              />
                              <Button
                                title="Ebitda"
                                size="sm"
                                theme={this.getPerformanceReportingMetricColor('ebitda')}
                                className="radius-4"
                                onClick={() => this.getPerformanceBarChart('ebitda')}
                              />
                              <Button
                                title="All"
                                size="sm"
                                theme={this.getPerformanceReportingMetricColor('all')}
                                className="radius-4"
                                type='button'
                                disabled={activePerformanceInvestmentType === 'all'}
                                onClick={() => this.getPerformanceBarChart('all')}
                              />
                            </div>
                          </div>

                          <div className="chart_filter_section">
                            <div className="text text-14 color-grey-900 font-medium font-uppercase font-spacing-04 mb_25 linear_label">
                              Investments
                            </div>
                            <div className="form-item form-item-sm mb_0 filter_form_item filter_form_item">
                              <Form.Item className="mb_0">
                                {getFieldDecorator('opportunities', {
                                  initialValue: activePerformanceInvestmentType,
                                  rules: [{
                                    required: false,
                                    message: "Please fill out this field"
                                  }],
                                })(
                                  <Select
                                    className="fluid-x default_width"
                                    onChange={this.setPerformanceInvestmentType}
                                  >
                                    <Option disabled={activePerformanceMetricType === 'all'} value="all">All</Option>
                                    {
                                      closedReports && closedReports.map(element => (
                                        <Option key={element.id} value={element.id} >
                                          {element.name}
                                        </Option>
                                      ))
                                    }
                                  </Select>
                                )}
                              </Form.Item>
                            </div>
                          </div>

                          <div className="chart_filter_section">
                            <div className="text text-14 color-grey-900 font-medium font-uppercase font-spacing-04 mb_25 linear_label">
                              period
                            </div>
                            <div className="medium-flex-container">
                              <div className="form-item form-item-sm mb_0 filter_form_item">
                                <Form.Item className="mb_0">
                                  {getFieldDecorator('period_from', {
                                    initialValue: activePerformancePeriodFrom,
                                    rules: [{
                                      required: false,
                                      message: "Please fill out this field"
                                    }],
                                  })(
                                    <Select
                                      className="fluid-x min_width"
                                      onChange={(value) => this.setPerformanceActivePeriod('from', value)}
                                    >
                                      {
                                        yearsList.map((element, index) => (
                                          <Option key={index} value={element}>{element}</Option>
                                        ))
                                      }
                                    </Select>
                                  )}
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div className="chart_filter_section flex-container align-bottom flex-wrap chart_filter_buttons" />
                        </div>
                      </div>
                    </Form>

                    <div className="chart_wrapper">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={barOptions}
                      />
                    </div>

                    { this.printYearEndSection() }

                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <div className="text text-18 font-semibold">
                      Annual performance
                    </div>
                  }
                  key="annual-performance"
                >
                  <div>
                    <Form className="relative">
                      <button
                        onClick={this.handleToggleWrapper}
                        className="hide-md hide-lg show-sm btn-initial color-blue toggle_btn"
                      >
                        <i className="icon icon-filter-toggle"/>
                      </button>
                      <div className={`toggle_wrapper ${toggleWrapperActive}`}>
                        <div className="chart_filter medium-flex-container align-justify">
                          <div className="chart_filter_section">
                            <div className="text text-14 color-grey-900 font-medium font-uppercase font-spacing-04 mb_25 linear_label">
                              Investments
                            </div>
                            <div className="form-item form-item-sm mb_0 filter_form_item filter_form_item">
                              <Form.Item className="mb_0">
                                {getFieldDecorator('annualPerformanceInvestment', {
                                  initialValue: annualPerformanceInvestment,
                                  rules: [{
                                    required: false,
                                    message: "Please fill out this field"
                                  }],
                                })(
                                  <Select
                                    className="fluid-x default_width"
                                    onChange={this.setAnnualPerformanceInvestment}
                                  >
                                    {
                                      closedReports && closedReports.map(element => (
                                        <Option key={element.id} value={element.id} >
                                          {element.name}
                                        </Option>
                                      ))
                                    }
                                  </Select>
                                )}
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>

                    <div className="chart_wrapper">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={annualPerformanceBarOptions}
                      />
                    </div>

                    { this.printYearEndSection() }
                  </div>
                </TabPane>
                <TabPane
                    tab={
                      <div className="text text-18 font-semibold">
                        Waterfall table
                      </div>
                    }
                    key="waterfall"
                >
                  <div>
                    <Form className="relative">
                      <button
                        onClick={this.handleToggleWrapper}
                        className="hide-md hide-lg show-sm btn-initial color-blue toggle_btn"
                      >
                        <i className="icon icon-filter-toggle"/>
                      </button>
                      <div className={`toggle_wrapper ${toggleWrapperActive}`}>
                        <div className="chart_filter medium-flex-container align-justify">
                          <div className="chart_filter_section">
                            <div className="text text-14 color-grey-900 font-medium font-uppercase font-spacing-04 mb_25 linear_label">
                              Investments
                            </div>
                            <div className="form-item form-item-sm mb_0 filter_form_item filter_form_item">
                              <Form.Item className="mb_0">
                                {getFieldDecorator('waterfall_opportunities', {
                                  initialValue: activeWaterfallInvestment,
                                  rules: [{
                                    required: false,
                                    message: "Please fill out this field"
                                  }],
                                })(
                                  <Select
                                    className="fluid-x default_width"
                                    onChange={this.setWaterfallActiveInvestment}
                                  >
                                    {
                                      portfolioReports && portfolioReports.map(element => (
                                        <Option key={element.id} value={element.id} >
                                          {element.name}
                                        </Option>
                                      ))
                                    }
                                  </Select>
                                )}
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>

                    <div className="chart_wrapper">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={waterfallChartOptions}
                      />
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="row portfolio_section">
          <div className="column small-12">
            {
              closedReports && closedReports.length
                ? (
                  <div className="bg-white radius-10 shadow-layout portfolio_card">
                    <div className="text text-22 font-heavy color-grey portfolio_card__title">
                      Projected IRR
                    </div>
                    <Slider {...settings} className="row">
                      {
                        closedReports.map(element => (
                          <div className="column" key={element.id}>
                            <div className="flex-container align-middle align-justify bg-grey ipr_card">
                              <div className="text text-18 text-height-22 color-grey font-bold font-uppercase large-5 ipr_card__title">
                                {element.name}
                              </div>
                              <div className="text text-28 color-grey font-heavy font-spacing-02">
                                {parseFloat(element.irr.toFixed(2))}%
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </Slider>
                  </div>
                ) : null
            }
          </div>
        </div>
        <div className="row portfolio_section">
          <div className="column small-12 large-8 mb_25">
            <div className="bg-white radius-10 shadow-layout portfolio_card fluid-y">
              <div className="text text-22 font-heavy color-grey portfolio_card__title">
                Investment Mix
              </div>

              <Tabs
                defaultActiveKey="country"
                className="tab_default"
                onChange={(tabKey) => this.getPieChartData(tabKey)}
              >
                <TabPane
                  tab={
                    <div className="text text-18 font-semibold">
                      Country
                    </div>
                  }
                  key="country"
                >
                  <InvestmentMixChart
                    pieChartOptions={pieChartOptions}
                    pieChartInfo={pieChartInfo}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <div className="text text-18 font-semibold">
                      State/Province
                    </div>
                  }
                  key="state"
                >
                  <InvestmentMixChart
                    pieChartOptions={pieChartOptions}
                    pieChartInfo={pieChartInfo}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <div className="text text-18 font-semibold">
                      Type
                    </div>
                  }
                  key="sector"
                >
                  <InvestmentMixChart
                    pieChartOptions={pieChartOptions}
                    pieChartInfo={pieChartInfo}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <div className="text text-18 font-semibold">
                      Sector
                    </div>
                  }
                  key="assets"
                >
                  <InvestmentMixChart
                    pieChartOptions={pieChartOptions}
                    pieChartInfo={pieChartInfo}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.reports.loading,
  xID: state.user.xID,
  errorMessage: state.reports.errorMessage,
  portfolioReports: state.reports.reports,
  closedReports: state.reports.closedReports,
  selectedCurrency: state.reports.selectedCurrency,
  activeInvestmentId: state.reports.activeInvestmentId,
  irr: state.reports.irr,
  opportunityGroups: state.dictionaries.opportunityGroups,
});

const mapDispatchToProps = dispatch => ({
  getReport: (investor, currency) => dispatch(getReport(investor, currency)),
  getClosedReports: (currency) => dispatch(getClosedReports(currency)),
});

const AnalyticsTabScreen = Form.create()(AnalyticsTab);

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsTabScreen);
