import {
  SET_OPTIONS_START, SET_OPTIONS_SUCCESS, SET_OPTIONS_FAIL,
  GET_OPTIONS_START, GET_OPTIONS_SUCCESS, GET_OPTIONS_FAIL,
  CLEAR_OPTIONS_START, CLEAR_OPTIONS_SUCCESS, CLEAR_OPTIONS_FAIL
} from "../../constants";

export const setOptionsStart = () => ({
  type: SET_OPTIONS_START
});

export const setOptionsSuccess = payload => ({
  type: SET_OPTIONS_SUCCESS,
  payload
});

export const setOptionsFail = payload => ({
  type: SET_OPTIONS_FAIL,
  payload
});

export const getOptionsStart = () => ({
  type: GET_OPTIONS_START
});

export const getOptionsSuccess = payload => ({
  type: GET_OPTIONS_SUCCESS,
  payload
});

export const getOptionsFail = payload => ({
  type: GET_OPTIONS_FAIL,
  payload
});

export const clearOptionsStart = () => ({
  type: CLEAR_OPTIONS_START
});

export const clearOptionsSuccess = payload => ({
  type: CLEAR_OPTIONS_SUCCESS,
  payload
});

export const clearOptionsFail = payload => ({
  type: CLEAR_OPTIONS_FAIL,
  payload
});