import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch, Route} from "react-router-dom";

import {initLoad} from "./redux/user/thunks/getUserInfoThunk";
import {toggleSettingsPopup} from "./redux/ui/actions/uiActions";

import Sidebar from "./layouts/Sidebar/Sidebar";
import Header from "./layouts/Header/Header";
import Opportunities from "./screens/Opportunities/Opportunities";
import Dashboard from "./screens/Dashboard/Dashboard";
import Project from "./screens/Project/Project";
import DataRoom from "./screens/DataRoom/DataRoom";
import DataRoomInner from "./screens/DataRoomInner/DataRoomInner";
import DataRoomShared from "./screens/DataRoomShared/DataRoomShared";
import ContactCenter from "./screens/ContactCenter/ContactCenter";
import ContactCenterInner from "./screens/ContactCenterInner/ContactCenterInner";
import Portfolio from "./screens/Portfolio/Portfolio";
import RecoverPasswordScreen from "./screens/RecoverPassword/RecoverPassword";
import OnboardingStep1 from "./screens/Onboarding/Step1";
import OnboardingStep3 from "./screens/Onboarding/Step3";
import OnboardingStep5 from "./screens/Onboarding/Step5";
import OnboardingStep6 from "./screens/Onboarding/Step6";
import OnboardingStep8 from "./screens/Onboarding/Step8";
import AccountSettings from "./screens/AccountSettings/AccountSettings";
import AddProject from "./screens/Admin/AdminProject/AdminProject";
import AddOffer from "./screens/AddOffer";
import AdminStatistics from "./screens/Admin/AdminStatistics/AdminStatistics";
import AdminUsers from "./screens/Admin/AdminUsers/AdminUsers";
import AdminGroupUsersScreen from "./screens/Admin/AdminGroupUsers/AdminGroupUsers";
import AdminUserInner from "./screens/Admin/AdminUserInner/AdminUserInner";
import AdminNotes from "./screens/Admin/AdminNotes/AdminNotes";
import Footer from "./layouts/Footer/Footer";
import WatchVideo from "./screens/WatchVideo/WatchVideo";
import Tests from "./tests/Tests";
import NotFound from "./screens/NotFound/NotFound";
import { message } from "antd";
import UserStatuses from "./screens/Admin/UserStatuses/UserStatuses";
import InternalOffer from "./screens/Admin/InternalOffer/InternalOffer";
import Offers from "./screens/Offers/Offers";
import Bids from "./screens/Bids";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { Redirect } from 'react-router-dom/cjs/react-router-dom';

class PrivateRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settingsPopupVisible: false
    };
  }

  toggleSettingPopupVisibility = showStatus => {
    this.setState({
      settingsPopupVisible: showStatus
    })
  };

  componentDidUpdate(prevProps, prevState) {
    const {errorMessage} = this.props;

    if (errorMessage.type === "InitLoad" && (prevProps.errorMessage.message !== errorMessage.message && errorMessage.message)) {
      message.error(errorMessage.message);
    }
  }

  componentDidMount() {
    const {initLoad} = this.props;
    // const screenPath = window.location.pathname.split("/")[1];
    initLoad();
  }

  render() {
    const { toggleSettingsPopup, settingsPopupVisible } = this.props;

    return (
      <div className="account flex-container fluid-y">
        <div className="account_left x-large-2">
          <Sidebar settingsPopupShow={() => toggleSettingsPopup(true)} />
        </div>
        <div className="account_right x-large-8 relative">
          <Header settingsPopupShow={() => toggleSettingsPopup(true)} />

          <ScrollToTop>
            <Switch>
              <Route path="/" component={ Dashboard } exact />
              {/*<Route path="/dashboard" component={ Dashboard } exact />*/}
              <Route path="/opportunities" component={ Opportunities } exact />
              <Route path="/project/:id" component={ Project } exact />
              <Route path="/offer/:id" component={ Project } exact />
              <Route path="/data-room/:id/:folderName/:isBusiness/:business" component={ DataRoomInner } /> {/* don't change this and below line of codes, with :id must be before without :id */}
              <Route path="/data-room/:id/:folderName" component={ DataRoomInner } /> {/* don't change this and below line of codes, with :id must be before without :id */}
              <Route path="/data-room" component={ DataRoom } />
              <Route path="/data-room-shared/:userID" component={ DataRoomShared } />
              <Route path="/contact-center-inner/:type/:id" component={ ContactCenterInner } />
              <Route path="/contact-center/:id/:name/:type" component={ ContactCenter } />
              <Route path="/contact-center" component={ ContactCenter } />
              <Route path="/portfolio" component={ Portfolio } />
              {/*<Route path="/settings" component={ Settings } />*/}
              <Route path="/recover/:code" component={RecoverPasswordScreen} />
              <Route path="/onboarding1" component={OnboardingStep1} />
              <Route path="/onboarding2" component={OnboardingStep3} />
              <Route path="/onboarding3" component={OnboardingStep5} />
              <Route path="/onboarding4" component={OnboardingStep6} />
              {/*<Route path="/onboarding5" component={OnboardingStep7} />*/}
              <Route path="/onboarding5" component={OnboardingStep8} />
              {/*<Route path="/onboarding7" component={OnboardingStep9} />*/}
              {/*<Route path="/onboarding8" component={OnboardingStep8} />*/}
              {/*<Route path="/onboarding9" component={OnboardingStep9} />*/}
              <Route path="/admin-project/:id" component={AddProject} />
              <Route path="/admin-project" component={AddProject} />

              <Route path="/offers" component={Offers} />
              <Route path="/add-offer/:id" component={AddOffer} />
              <Route path="/internal-publish/:id" component={InternalOffer} />
              <Route path="/bids/:id" component={Bids} />
              <Route path="/admin-statistics/:business/:businessName/:currency" component={AdminStatistics} />
              <Route path="/admin-users" component={AdminUsers} />
              <Route path="/admin-group-users/:groupID" component={AdminGroupUsersScreen} />
              <Route path="/admin-users-inner/:id" component={AdminUserInner} />
              <Route path="/notes/:id" component={AdminNotes} />
              <Route path="/user-statuses/:businessID" component={ UserStatuses } />
              <Route path="/watch-video/:token/:year/:month/:day/:id" component={ WatchVideo } />
              <Route path="/tests" component={ Tests } exact />
              <Route path="/signin" component={ () => <Redirect to={'/opportunities'} /> } exact />
              <Route path="/signin/2fa" component={ () => <Redirect to={'/opportunities'} /> } exact />
              <Route path="/personal-information" component={ () => <Redirect to={'/opportunities'} /> } exact />
              <Route path="*" component={ NotFound } />
            </Switch>
          </ScrollToTop>

          <AccountSettings
            popupVisible={settingsPopupVisible}
            modalHide={() => toggleSettingsPopup(false)}
          />

          <Footer/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  errorMessage: state.user.errorMessage,
  settingsPopupVisible: state.ui.settingsPopupVisible,
});

const mapDispatchToProps = dispatch => ({
  initLoad: () => dispatch(initLoad()),
  toggleSettingsPopup: (status) => dispatch(toggleSettingsPopup(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoutes);
