import {
  sendRecoveryLinkStart, sendRecoveryLinkSuccess, sendRecoveryLinkFail,
  recoverPasswordStart, recoverPasswordSuccess, recoverPasswordFail
} from "../actions/recoverPasswordActions";
import CommonUtils from "../../../utils/CommonUtils";

export const sendRecoveryLink = email => ((dispatch, getState) => {
  dispatch(sendRecoveryLinkStart());

  CommonUtils.apiCall("post", "public", "shared/sendRecoveryLink", {email}, null)
    .then(response => {
      if (response.data.success) {
        dispatch(sendRecoveryLinkSuccess());
      }
      else {
        dispatch(sendRecoveryLinkFail(response.data.error));
      }
    })
    .catch(() => {
      dispatch(sendRecoveryLinkFail("Error"));
    })
});

export const recoverPassword = param => ((dispatch, getState) => {
  dispatch(recoverPasswordStart());

  CommonUtils.apiCall("post", "public", "shared/recoverPassword", param, null)
    .then(response => {
      if (response.data.success) {
        dispatch(recoverPasswordSuccess());
      }
      else {
        dispatch(recoverPasswordFail(response.data.error));
      }
    })
    .catch(() => {
      dispatch(recoverPasswordFail("Error"));
    });
});