import React, {Component} from "react";
import './style.scss';

class Footer extends Component {
  render() {
    return (
      <div>

      </div>
    )
  }
}

export default Footer;