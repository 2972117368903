import React, {Component} from "react";
import {connect} from "react-redux";
import {Form, AutoComplete, Input, Upload, Select, Radio, message} from "antd";

import { getBusinessesByName } from "../../redux/business/thunks/businessThunk";
import {searchUsers, searchUsersFilter} from "../../redux/users/thunks/searchUsersThunk";
import {addMessage} from "../../redux/messages/thunks/messagesThunks";
import {handleLoading} from "../../utils/handleLoading";
import {getMediaFileFromServer} from "../../utils/getMediaFileFromServer";

import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import TextArea from "antd/es/input/TextArea";
import {getTags} from "../../redux/tags/thunks/tagsThunks";
import "./style.scss";

const { Option } = AutoComplete;
const groupSelectInitialValue = "Group";

class MessageWindowComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minimizeWindow: "",
      name: "",
      subject: "",
      messageText: "",
      fileList: [],
      selectedUserID: null,
    }
  }

  handleMinimizeWindow = () => {
    const minimize = (this.state.minimizeWindow === "") ? "minimize" : "";

    this.setState({
      minimizeWindow: minimize
    });
  };

  handleCloseWindow = () => {
    this.setState({
      minimizeWindow: "",
      fileList: [],
    }, () => {
      this.props.deactivate();
      this.props.form.resetFields();
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {
          addMessage,
          filledData,
          fillingDataType,
          userRole,
          businessesCount,
        } = this.props;
        const {fileList, selectedUserID} = this.state;
        const to = values.messageType !== "USER" ? values.groupID : fillingDataType === 'user' ? filledData.id : selectedUserID ? selectedUserID : 0;
        const business = fillingDataType === 'business' ? filledData.id : values.business ? values.business : 0;
        const type = values.messageType ? values.messageType : "USER";
        const sendingBusinessID = parseInt(business) <= parseInt(businessesCount) ? business : 0;

        if (type === "GROUP" && values.groupID === "Group") {
          message.error("Please select a group");
        }
        else {
          addMessage(userRole === "ADMIN" ? to : 0, sendingBusinessID, values.subject, values.body, values.priority, fileList, type);
        }
      }
    });
  };

  handleUserNameOnChange = inputValue => {
    const { searchUsersFilter } = this.props;
    const inputValueArray = inputValue.split(/(\s+)/); // 0 index is name, 2 index is surname
    const params = {
      first_name: inputValueArray[0] ? inputValueArray[0] : "",
      last_name: inputValueArray[2] ? inputValueArray[2] : ""
    };

    searchUsersFilter(params);
  };

  handleBusinessOnChange = inputValue => {
    const { getBusinessesByName } = this.props;
    getBusinessesByName(inputValue);
  };

  fakeCustomRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      this.setState(prevState => ({
        fileList: [...prevState.fileList, file] // pushing new element to a prevState
      }));
      onSuccess("ok");
    }, 0);
  };

  handleFileRemove = (removedFile) => {
    this.setState(prevState => ({
      fileList: [...prevState.fileList.filter(file => file.uid !== removedFile.uid)],
    }));
  }

  handleSelect = selected => {
    const { usersSearchList, form } = this.props;

    this.setState({
      selectedUserID: selected
    }, () => {
      // eslint-disable-next-line array-callback-return
      usersSearchList.map((element) => {
        if (parseInt(element.id) === parseInt(selected)) {
          form.setFieldsValue({
            to: `${element.first_name} ${element.middle_name ? element.middle_name : ""} ${element.last_name} (${element.email})`
          })
        }
      });
    });
  };

  componentDidMount() {
    const { tagsList, getTags } = this.props;

    if (!tagsList) {
      getTags();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage, loading } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;

    if (!errorText && !loadingState && prevProps.loading.state && loadingType === "AddMessage") {
      this.handleCloseWindow();
    }
  }

  render() {
    const { form, userRole, visibility, filledData, fillingDataType, loading, usersSearchList, businessesSearchList, tagsList } = this.props;
    const { minimizeWindow, fileList } = this.state;
    const { getFieldDecorator } = form;

    return(
      <div className={`bg-white message_window ${minimizeWindow} ${visibility ? 'active' : ""}`}>
        <div className="flex-container align-middle align-justify color-white message_window__top">
          <div className="text font-semibold">New message</div>
          <div className="flex-container message_window_actions">
            <div className="message_window_close" onClick={this.handleCloseWindow}>
              <Icon type="cancel" size={16}/>
            </div>
          </div>
        </div>
        <Form onSubmit={this.onSubmit} className={`bg-white message_window__main ${minimizeWindow}`}>
          <div className="message_window__main_content">
            {
              userRole.toUpperCase() === "ADMIN" &&
                <>
                  <Form.Item className="mb_5">
                    {getFieldDecorator('messageType', {
                      initialValue: "USER",
                      rules: [{required: false, message: 'Please fill out this field'}],
                    })(
                      <Radio.Group>
                        <div className="row">
                          <div className="column small-12 medium-6">
                            <div className="message-radio-btn radio-template">
                              <Radio value="USER">User</Radio>
                              <Icon type="user" className="color-grey-800 pined-icon"/>
                            </div>
                          </div>
                          <div className="column small-12 medium-6">
                            <div className="message-radio-btn radio-template">
                              <Radio value="GROUP">Group</Radio>
                              <Icon type="group" className="color-grey-800 pined-icon"/>
                            </div>
                          </div>
                        </div>
                      </Radio.Group>
                    )}
                  </Form.Item>

                  {
                    form.getFieldValue("messageType") === "USER" ?
                      <Form.Item className="mb_0 message_input relative dotted-bottom">
                        {getFieldDecorator("to", {
                          initialValue: fillingDataType === 'user' ? filledData.name : '',
                          rules: [{required: true, message: "The user can’t be empty"}],
                        })(
                          <AutoComplete
                            placeholder="Start typing the name of a user"
                            onChange={this.handleUserNameOnChange}
                            onSelect={this.handleSelect}
                          >
                            {
                              usersSearchList && usersSearchList.map(element => (
                                <Option key={element.id} value={element.id.toString()} >
                                  <span className="radius-circle clip option_ava">
                                    <img
                                      style={{height: "24px", width: "24px"}}
                                      src={element.avatar ? getMediaFileFromServer(element.avatar) : require("../../assets/img/no_profile.png")}
                                      alt="profile"
                                    />
                                  </span>
                                  <span className="font-semibold color-grey">
                                    {element.first_name} {element.middle_name ? element.middle_name : ""} {element.last_name} ({element.email})
                                  </span>
                                </Option>
                              ))
                            }
                          </AutoComplete>
                        )}
                      </Form.Item>
                    :
                      <Form.Item className="mb_0 message_input relative dotted-bottom">
                        {getFieldDecorator("groupID", {
                          initialValue: groupSelectInitialValue,
                          rules: [{required: false, message: "Please fill out this field"}],
                        })(
                          <Select>
                            <Select.Option value={null}>Group</Select.Option>
                            {
                              tagsList && tagsList.map(element => (
                                <Select.Option value={element.id} >{element.name}</Select.Option>
                              ))
                            }
                          </Select>
                        )}
                      </Form.Item>
                  }
                </>
            }

            <Form.Item className="mb_0 message_input relative dotted-bottom">
              {getFieldDecorator("business", {
                initialValue: fillingDataType === 'business' ? filledData.name : "",
                rules: [{required: false, message: "Please fill out this field"}],
              })(
                <AutoComplete
                  placeholder="Start typing the name of Primary deal"
                  onChange={this.handleBusinessOnChange}
                >
                  {
                    businessesSearchList && businessesSearchList.map(element => (
                      <Option key={element.id} value={element.id.toString()} >
                        {element.name}
                      </Option>
                    ))
                  }
                </AutoComplete>
              )}
            </Form.Item>

            <Form.Item className="mb_0 message_input relative dotted-bottom">
              {getFieldDecorator("subject", {
                initialValue: "",
                rules: [{required: true, message: "The subject can’t be empty"}],
              })(
                <Input autoComplete="off" placeholder="SUBJECT" />
              )}
            </Form.Item>

            <div className="priority-item-wrapper">
              <div className="form-item-label text text-12 text-height-20 font-medium color-grey-900 font-spacing-04">
                Importance
              </div>
              <Form.Item className="mb_0 fluid-x message_input relative dotted-bottom" >
                {getFieldDecorator("priority", {
                  initialValue: "LOW",
                  rules: [{required: false, message: "Please fill out this field"}],
                })(
                  <Select placeholder="Priority" >
                    <Select.Option value="HIGH" >HIGH</Select.Option>
                    <Select.Option value="MEDIUM" >MEDIUM</Select.Option>
                    <Select.Option value="LOW" >LOW</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </div>

            <Form.Item className="mb_0 message_input flex-child-grow">
              {getFieldDecorator("body", {
                initialValue: "",
                rules: [{required: true, message: "The body can’t be empty"}],
              })(
                <TextArea placeholder="Message" autoComplete="off" />
              )}
            </Form.Item>

            <div className="message_window_submit btn_group">
              <Button
                title="Send Message"
                prefix={<Icon type="send" style={{marginRight: 8}} />}
                size="lg"
                theme="blue"
                className="btn-shadow radius-8 sm_circle"
                type="submit"
                loading={handleLoading(loading, "AddMessage")}
              />
              <Upload
                multiple
                fileList={fileList}
                customRequest={this.fakeCustomRequest}
                onRemove={this.handleFileRemove}
              >
                <Button
                  title="Attach a file"
                  prefix={<Icon type="attach" size={16} style={{marginRight: 7}} />}
                  size="lg"
                  theme="default"
                  className="radius-8 btn-link"
                  style={{fontSize: 16}}
                />
              </Upload>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  errorMessage: state.messages.errorMessage,
  loading: state.messages.loading,
  userRole: state.user.role,
  sentMessages: state.messages.sentMessages,
  usersSearchList: state.users.usersSearchList,
  businessesSearchList: state.business.businessesSearchList,
  tagsList: state.tags.tagsList,
  businessesCount: state.business.businessesCount,
});

const mapDispatchToProps = dispatch => ({
  searchUsers: () => dispatch(searchUsers()),
  searchUsersFilter: params => dispatch(searchUsersFilter(params)),
  addMessage: (to, business, subject, body, priority, files, type) => dispatch(addMessage(to, business, subject, body, priority, files, type)),
  getBusinessesByName: businessName => dispatch(getBusinessesByName(businessName)),
  getTags: () => dispatch(getTags()),
});

const MessageWindow = Form.create({ name: 'Entity3' })(MessageWindowComponent);

export default connect(mapStateToProps, mapDispatchToProps)(MessageWindow);