import {
  addNoteStart, addNoteSuccess, addNoteFail,
  updateNoteStart, updateNoteSuccess, updateNoteFail,
  removeNoteStart, removeNoteSuccess, removeNoteFail,
  getNotesStart, getNotesSuccess, getNotesFail,
} from '../actions/notesActions';
import CommonUtils from "../../../utils/CommonUtils";

export const addNote = (params) => ((dispatch, getState) => {
  dispatch(addNoteStart());

  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };

  CommonUtils.apiCall("post", "private", "admin/addNote", params, config)
    .then((newNoteResponse) => {
      if (newNoteResponse.data.success) {
        const { currentPage } = getState().notes;

        CommonUtils.apiCall("get", "private", `admin/getNotes/0/0?cnt&user=${params.user}`, {}, config)
          .then((notesCountResponse) => {
            if (notesCountResponse.data != null && parseInt(notesCountResponse.data) === 0) {
              dispatch(addNoteSuccess({
                currentPage,
                notesCount: 0,
                notes: [],
              }));
            }
            else if (notesCountResponse.data != null && !notesCountResponse.data.error) {
              const perPage = 10;
              const offset = (currentPage - 1) * perPage;
              const notesCount = notesCountResponse.data;

              CommonUtils.apiCall("get", "private", `admin/getNotes/${offset}/${perPage}?user=${params.user}`, {}, config)
                .then((notesResponse) => {
                  if (Array.isArray(notesResponse.data)) {
                    dispatch(addNoteSuccess({
                      currentPage,
                      notesCount,
                      notes: notesResponse.data,
                    }));
                  }
                  else {
                    dispatch(addNoteFail("Error"));
                  }
                })
                .catch(() => dispatch(addNoteFail("Error")));
            }
          })
          .catch(() => dispatch(addNoteFail("Error")));
      }
    })
    .catch(() => dispatch(addNoteFail("Error")));
});

export const updateNote = (params, userID) => ((dispatch, getState) => {
  dispatch(updateNoteStart());

  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };

  CommonUtils.apiCall("post", "private", "admin/updateNote", params, config)
    .then((updateNoteResponse) => {
      if (updateNoteResponse.data.success) {
        const { currentPage } = getState().notes;

        CommonUtils.apiCall("get", "private", `admin/getNotes/0/0?cnt&user=${userID}`, {}, config)
          .then((notesCountResponse) => {
            if (notesCountResponse.data != null && parseInt(notesCountResponse.data) === 0) {
              dispatch(updateNoteSuccess({
                currentPage,
                notesCount: 0,
                notes: [],
              }));
            }
            else if (notesCountResponse.data != null && !notesCountResponse.data.error) {
              const perPage = 10;
              const offset = (currentPage - 1) * perPage;
              const notesCount = notesCountResponse.data;

              CommonUtils.apiCall("get", "private", `admin/getNotes/${offset}/${perPage}?user=${userID}`, {}, config)
                .then((notesResponse) => {
                  if (Array.isArray(notesResponse.data)) {
                    dispatch(updateNoteSuccess({
                      currentPage,
                      notesCount,
                      notes: notesResponse.data,
                    }));
                  }
                  else {
                    dispatch(updateNoteFail("Error"));
                  }
                })
                .catch(() => dispatch(updateNoteFail("Error")));
            }
          })
          .catch(() => dispatch(updateNoteFail("Error")));
      }
    })
    .catch(() => dispatch(updateNoteFail("Error")));
});

export const removeNote = (params, userID) => ((dispatch, getState) => {
  dispatch(removeNoteStart());

  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };

  CommonUtils.apiCall("post", "private", "admin/removeNote", params, config)
    .then((removeNoteResponse) => {
      if (removeNoteResponse.data.success) {
        const { currentPage } = getState().notes;

        CommonUtils.apiCall("get", "private", `admin/getNotes/0/0?cnt&user=${userID}`, {}, config)
          .then((notesCountResponse) => {
            if (notesCountResponse.data != null && parseInt(notesCountResponse.data) === 0) {
              dispatch(removeNoteSuccess({
                currentPage,
                notesCount: 0,
                notes: [],
              }));
            }
            else if (notesCountResponse.data != null && !notesCountResponse.data.error) {
              const perPage = 10;
              const offset = (currentPage - 1) * perPage;
              const notesCount = notesCountResponse.data;

              CommonUtils.apiCall("get", "private", `admin/getNotes/${offset}/${perPage}?user=${userID}`, {}, config)
                .then((notesResponse) => {
                  if (Array.isArray(notesResponse.data)) {
                    dispatch(removeNoteSuccess({
                      currentPage: notesResponse.data.length === 0 && currentPage !== 1 ? currentPage - 1 : currentPage,
                      notesCount,
                      notes: notesResponse.data,
                    }));
                  }
                  else {
                    dispatch(removeNoteFail("Error"));
                  }
                })
                .catch(() => dispatch(removeNoteFail("Error")));
            }
          })
          .catch(() => dispatch(removeNoteFail("Error")));
      }
    })
    .catch(() => dispatch(removeNoteFail("Error")));
});

export const getNotes = (currentPage, userID) => ((dispatch, getState) => {
  dispatch(getNotesStart());

  const { xID, xTOKEN } = getState().user;
  const config = { id: xID, token: xTOKEN };

  CommonUtils.apiCall("get", "private", `admin/getNotes/0/0?cnt&user=${userID}`, {}, config)
    .then((notesCountResponse) => {
      if (notesCountResponse.data != null && parseInt(notesCountResponse.data) === 0) {
        dispatch(getNotesSuccess({
          currentPage,
          notesCount: 0,
          notes: [],
        }));
      }
      else if (notesCountResponse.data != null && !notesCountResponse.data.error) {
        const perPage = 10;
        const offset = (currentPage - 1) * perPage;
        const notesCount = notesCountResponse.data;

        CommonUtils.apiCall("get", "private", `admin/getNotes/${offset}/${perPage}?user=${userID}`, {}, config)
          .then((notesResponse) => {
            if (Array.isArray(notesResponse.data)) {
              dispatch(getNotesSuccess({
                currentPage,
                notesCount,
                notes: notesResponse.data,
              }));
            }
            else {
              dispatch(getNotesFail("Error"));
            }
          })
          .catch(() => dispatch(getNotesFail("Error")));
      }
    })
    .catch(() => dispatch(getNotesFail("Error")));
});
