import {
  ACTIVATE_USER_REGISTRATION_START, ACTIVATE_USER_REGISTRATION_SUCCESS, ACTIVATE_USER_REGISTRATION_FAIL
} from "../../constants";

export const activateUserRegistrationStart = () => ({
  type: ACTIVATE_USER_REGISTRATION_START
});

export const activateUserRegistrationSuccess = payload => ({
  type: ACTIVATE_USER_REGISTRATION_SUCCESS,
  payload
});

export const activateUserRegistrationFail = payload => ({
  type: ACTIVATE_USER_REGISTRATION_FAIL,
  payload
});