import React from "react";
import "./style.scss";

const WizardStep = ({ number, className, name }) => (
  <div className={`flex-container align-middle wizard_step ${className}`}>
    <div className="wizard_step__number shrink text-center radius-circle text text-20 color-grey-700 font-bold">
      {number}
    </div>
    <div className="wizard_step__name text text-height-20 color-grey-700 font-semibold">
      {name}
    </div>
  </div>
);

export default WizardStep;