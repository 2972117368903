import {
  signNDAStart, signNDASuccess, signNDAFail,
  getNDAStart, getNDASuccess, getNDAFail
} from "../actions/ndaActions";
import CommonUtils from "../../../utils/CommonUtils";

export const signNDA = (business, signature) => ((dispatch, getState) => {
  dispatch(signNDAStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  const params = {
    business,
    signature,
    mode: 'TEXT',
  };

  CommonUtils.apiCall("post", "private", "investor/signNDA", params, config)
    .then(response => {
      if (response.data.success) {
        CommonUtils.apiCall("get", "private", `shared/getBusiness/${business}`, {}, config)
          .then(currentBusinessResponse => {
            if (typeof currentBusinessResponse.data.success === "object" && currentBusinessResponse.data.success != null) {
              const currentOpenedBusiness = currentBusinessResponse.data.success;
              currentOpenedBusiness.id = business;
              dispatch(signNDASuccess({
                currentOpenedBusiness
              }));
            }
            else {
              dispatch(signNDAFail("Error"))
            }
          })
          .catch(() => dispatch(signNDAFail("Error")));
        /*CommonUtils.apiCall("get", "private", "shared/getBusinesses/0/0?cnt", {}, config)
          .then(businessItemsCountResponse => {
            if (businessItemsCountResponse.data != null && parseInt(businessItemsCountResponse.data) === 0) {
              dispatch(signNDASuccess({
                businesses: [],
                savedBusinesses: []
              }));
            }
            else if (businessItemsCountResponse.data != null) {
              const businessItemsCount = businessItemsCountResponse.data;
              CommonUtils.apiCall("get", "private", `shared/getBusinesses/0/${businessItemsCount}`, {}, config)
                .then(response => {
                  if (Array.isArray(response.data)) {
                    const businesses = response.data;
                    const savedBusinesses = getOpportunitiesByType(businesses, "saved");
                    const pursuingBusinesses = getOpportunitiesByType(businesses, "pursuing");
                    const closedBusinesses = getOpportunitiesByType(businesses, "closed");

                    dispatch(signNDASuccess({
                      businesses: response.data,
                      savedBusinesses,
                      pursuingBusinesses,
                      closedBusinesses
                    }));
                  }
                })
                .catch(() => dispatch(signNDAFail("Error")));
            }
          })
          .catch(() => dispatch(signNDAFail("Error")));*/
      }
      else {
        dispatch(signNDAFail(response.data.error));
      }
    })
    .catch(() => dispatch(signNDAFail("Error")));
});

export const getNDA = () => ((dispatch, getState) => {
  dispatch(getNDAStart());

  const {xID, xTOKEN} = getState().user;
  const config = {
    id: xID,
    token: xTOKEN
  };

  CommonUtils.apiCall("get", "private", "shared/getNDA", {}, config)
    .then(response => {
      if (response.data) {
        dispatch(getNDASuccess());
        const winHtml = response.data;

        const winUrl = URL.createObjectURL(
          new Blob([winHtml], { type: "text/html" })
        );

        window.open(winUrl, "_blank");
      }
      else {
        dispatch(getNDAFail(response.data.error));
      }
    })
    .catch(() => dispatch(getNDAFail("Error")));
});